<div class="modal-header">
    <button type="button" class="close pull-right" aria-label="Close" (click)="bsModalRef.hide()">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="top-wrapper">
    <h4 class="modal-title text-center">ยกเลิกข้อมูล Booking</h4>
    <small class="are-you-sure">คุณแน่ใจหรือไม่ว่าจะยกเลิกข้อมูล Booking นี้?</small>
</div>
<div class="modal-body">
    <div class="booking-details">
        <p class="font-weight-bold">Booking ID : {{ booking.order.orderCode }}</p>
        <p>จองวันที่ {{ booking.createdAt | thaiMonthDateTime: true }}</p>
        <p>
            <ng-container *ngIf="booking.product.tourCode">
                {{ booking.productOwner.productCode }}-{{ booking.product.tourCode }}
            </ng-container>
            {{ booking.product.productName }}
        </p>
        <p>
            เวลาเดินทาง {{ booking.product.startAt | thaiDate }} -
            {{ booking.product.endAt | thaiDate }}
        </p>
        <p>จำนวน {{ booking.order.sumQuantity }} คน รวม {{ booking.order.sumTotal | number: '1.2-2' }}</p>
        <p class="mt-1" style="color: red">
            {{ getStatusString() }}
        </p>
    </div>
    <div class="customer-details">
        <p>
            <span class="font-weight-bold">ลูกค้า : </span>
            {{ booking.contact.contactName }}
        </p>
        <p>
            <span class="font-weight-bold">เบอร์โทร : </span>
            {{ booking.contact.phoneNumber || '-' | phoneNumber }}
        </p>
    </div>
</div>
<div class="modal-footer">
    <div class="button-container">
        <button type="button" class="btn btn-primary" (click)="submitButton()">ยืนยันยกเลิก Booking</button>
        <button type="button" class="btn btn-default" (click)="bsModalRef.hide()">ยกเลิก</button>
    </div>
</div>
