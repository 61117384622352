export enum ENTITY {
    AGENCY_ORDER_BALANCE = 'agency.order_balance',
    AGENCY_ORDER_BALANCE_RECEIPT = 'agency.order_balance_receipt',
}

export enum ACTION_TYPE {
    CONFIRM = 'confirm',
    DOWNLOAD_TRANSFER_DOCUMENT = 'download_transfer_doc',
    VIEW_RECEIPT_ITEM_LSIT = 'view_receipt_item_list',
    DOWNLOAD_COMMISSION_REPORT = 'download_commission_report',
    DOWNLOAD_RECEIPT_DOCUMENT = 'download_receipt_doc',
}
