import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { ENDPOINTS } from '../constants/endpoint.constant';
import { IGetSeoArticle } from '../modules/settings/models/seo-article/get-seo-article.model';
import { INewSeoArticle } from '../modules/settings/models/seo-article/new-seo-article.model';
import { ISeoArticleContentUpdate } from '../modules/settings/models/seo-article/seo-article-content-update.model';
import { ISeoArticleUpdate } from '../modules/settings/models/seo-article/seo-article-update.model';
import { ISeoArticle } from '../modules/settings/models/seo-article/seo-article.model';
import { HttpService } from './base/http.service';

@Injectable({
    providedIn: 'root',
})
export class SeoArticleService {
    constructor(private httpService: HttpService) {}

    public getAllSeoArticlesAsOptions(filter?: ISeoArticleOptionFilter): Observable<any> {
        const url = environment.apiProUrl + ENDPOINTS.SEO_ARTICLE.GET_ALL_AS_OPTIONS;

        let params = new HttpParams();
        if (filter) {
            if (filter.code) {
                params = params.set('code', filter.code);
            }

            if (filter.articleTypeIds) {
                params = params.set('article_type_ids', JSON.stringify(filter.articleTypeIds));
            }
        }
        return this.httpService.get(url + '?' + params.toString());
    }

    public getAllSeoArticles(filter?: ISeoArticleListFilter): Observable<any> {
        const url = environment.apiProUrl + ENDPOINTS.SEO_ARTICLE.GET_LIST;

        let params = new HttpParams();
        if (filter) {
            if (filter.code) {
                params = params.set('code', filter.code);
            }
            if (filter.targetPageTypeId) {
                params = params.set('target_page_type_id', filter.targetPageTypeId);
            }
            if (filter.rootIsChildOfHomePage !== null && filter.rootIsChildOfHomePage !== undefined) {
                params = params.set('root_is_child_of_home_page', filter.rootIsChildOfHomePage);
            }
            if (filter.countryId) {
                params = params.set('country_id', filter.countryId);
            }
            if (filter.countrySubUnitId) {
                params = params.set('country_sub_unit_id', filter.countrySubUnitId);
            }
            if (filter.provinceId) {
                params = params.set('province_id', filter.provinceId);
            }
            if (filter.productSubCategoryId) {
                params = params.set('product_sub_category_id', filter.productSubCategoryId);
            }
            if (filter.specialPageId) {
                params = params.set('special_page_id', filter.specialPageId);
            }
        }

        return this.httpService.get(url + '?' + params.toString());
    }

    public updateSeoArticleStatus(articleId: number, isActive: boolean): Observable<any> {
        const url = environment.apiProUrl + ENDPOINTS.SEO_ARTICLE.UPDATE_STATUS.replace('{article_id}', articleId);
        return this.httpService.put(url, { is_active: isActive });
    }

    public newSeoArticle(param: INewSeoArticle): Observable<any> {
        const url = environment.apiProUrl + ENDPOINTS.SEO_ARTICLE.NEW;
        return this.httpService.post(url, param);
    }

    public getSeoArticle(id: number): Observable<IGetSeoArticle> {
        const url = environment.apiProUrl + ENDPOINTS.SEO_ARTICLE.GET + '/' + id.toString();
        return this.httpService.get(url);
    }

    public updateSeoArticleContent(articleId: number, content: ISeoArticleContentUpdate): Observable<any> {
        const url = environment.apiProUrl + ENDPOINTS.SEO_ARTICLE.UPDATE_CONTENT.replace('{article_id}', articleId);
        return this.httpService.put(url, content);
    }

    public updateSeoArticle(articleId: number, content: ISeoArticleUpdate): Observable<any> {
        const url = environment.apiProUrl + ENDPOINTS.SEO_ARTICLE.UPDATE.replace('{article_id}', articleId);
        return this.httpService.put(url, content);
    }
}

export interface ISeoArticleOptionFilter {
    articleTypeIds?: number[];
    code?: string;
}

export interface ISeoArticleListFilter {
    code?: string;
    targetPageTypeId?: number;
    rootIsChildOfHomePage?: boolean;
    countryId?: number;
    countrySubUnitId?: number;
    provinceId?: number;
    productSubCategoryId?: number;
    specialPageId?: number;
}
