import { DateHelper } from 'src/app/helpers/date.helper';

export class CampaignPaymentPaginated implements ICampaignPaymentPaginated {
    page: number;
    pageEntries: number;
    total: number;
    result: ICampaignPaymentItem[];

    deserialize(source: any) {
        this.page = source.data.page;
        this.pageEntries = source.data.page_entries;
        this.total = source.data.total;
        this.result = source.data.result.map((item) => new CampaignPaymentItem().deserialize(item));
        return this;
    }
}

export class CampaignPaymentItem implements ICampaignPaymentItem {
    id: number;
    contactName: string;
    email: string;
    phoneNumber: string;
    usageDate: Date;
    serviceProviderRemark: string;
    sumCommission: number;
    sumTotal: number;
    hasUsableVoucherTickets: boolean;
    voucherTicketQuantity: IVoucherTicketQuantity;
    order: IOrder;
    campaign: ICampaign;
    voucher: IVoucher;
    productSubCategory: IProductSubCategory;
    chaophrayaCruiseBooking: IChaophrayaCruiseBooking;

    deserialize(source: any) {
        this.id = source.id;
        this.contactName = source.contact_name;
        this.email = source.email;
        this.phoneNumber = source.phone_number;
        this.usageDate = source.usage_date ? new Date(source.usage_date) : null;
        this.serviceProviderRemark = source.service_provider_remark;
        this.sumCommission = source.sum_commission;
        this.sumTotal = source.sum_total;
        this.hasUsableVoucherTickets = source.has_usable_voucher_tickets;

        this.voucherTicketQuantity = new VoucherTicketQuantity().deserialize(source.voucher_ticket_quantity);
        this.order = new Order().deserialize(source.order);
        this.campaign = new Campaign().deserialize(source.campaign);
        this.voucher = new Voucher().deserialize(source.voucher).updateVoucherStatus(this.voucherTicketQuantity);

        this.productSubCategory = source.product_sub_category
            ? new ProductSubCategory().deserialize(source.product_sub_category)
            : null;
        this.chaophrayaCruiseBooking = source.chaophraya_cruise_booking
            ? new ChaophrayaCruiseBooking().deserialize(source.chaophraya_cruise_booking)
            : null;

        return this;
    }
}

export class VoucherTicketQuantity implements IVoucherTicketQuantity {
    total: number;
    used: number;
    unused: number;
    expired: number;

    deserialize(source: any) {
        this.total = source.total;
        this.used = source.used;
        this.unused = source.unused;
        this.expired = source.expired;

        return this;
    }
}

export class Order implements IOrder {
    id: number;
    orderCode: string;
    createdAt: Date;

    deserialize(source: any) {
        this.id = source.id;
        this.orderCode = source.order_code;
        this.createdAt = source.created_at ? new Date(source.created_at) : null;

        return this;
    }
}

export class Partner implements IPartner {
    id: number;
    nameTh: string;
    nameEn: string;
    email: string;
    address: string;
    officeTelephoneNumber: string;
    additionalPhoneNumber: string;

    deserialize(source: any) {
        this.id = source.id;
        this.nameTh = source.name_th;
        this.nameEn = source.name_en;
        this.email = source.email;
        this.address = source.address;
        this.officeTelephoneNumber = source.office_telephone_number;
        this.additionalPhoneNumber = source.additional_phone_number;

        return this;
    }
}

export class Campaign implements ICampaign {
    id: number;
    campaignCode: string;
    name: string;
    shopName: string;
    officeTelephoneNumber: string;
    partner: IPartner;

    deserialize(source: any) {
        this.id = source.id;
        this.campaignCode = source.campaign_code;
        this.name = source.name;
        this.shopName = source.shop_name;
        this.officeTelephoneNumber = source.office_telephone_number;
        this.partner = new Partner().deserialize(source.partner);

        return this;
    }
}

export class Voucher implements IVoucher {
    id: number;
    name: string;
    price: number;
    voucherEndDate: Date;
    status: VoucherStatus;

    deserialize(source: any) {
        this.id = source.id;
        this.name = source.name;
        this.price = source.price;
        this.voucherEndDate = source.voucher_end_date ? new Date(source.voucher_end_date) : null;

        return this;
    }

    updateVoucherStatus(ticketQuantity: IVoucherTicketQuantity) {
        const today = DateHelper.getCurrentDateAtMidnight(true);
        const isExpired = today > this.voucherEndDate;
        if (isExpired) {
            this.status = VoucherStatus.expired;
        } else if (ticketQuantity.used === ticketQuantity.total) {
            this.status = VoucherStatus.unavailable;
        } else {
            this.status = VoucherStatus.available;
        }

        return this;
    }
}

export class ProductSubCategory implements IProductSubCategory {
    id: number;
    slug: string;
    name: string;

    deserialize(source: any) {
        this.id = source.id;
        this.name = source.name;
        this.slug = source.slug;

        return this;
    }
}

export class ChaophrayaCruiseBooking implements IChaophrayaCruiseBooking {
    id: number;
    confirmed: boolean;

    deserialize(source: any) {
        this.id = source.id;
        this.confirmed = source.confirmed;

        return this;
    }
}

export interface ICampaignPaymentPaginatedFilter {
    page: number;
    pageEntries: number;
    clientNamePhoneOrVoucherCode: string;
    hasUsableVoucherTickets: boolean;
    isLocalSearch: boolean;
}

export interface ICampaignPaymentPaginated {
    page: number;
    pageEntries: number;
    total: number;
    result: ICampaignPaymentItem[];
}

export interface ICampaignPaymentItem {
    id: number;
    contactName: string;
    email: string;
    phoneNumber: string;
    usageDate: Date;
    serviceProviderRemark: string;
    sumCommission: number;
    sumTotal: number;
    hasUsableVoucherTickets: boolean;
    voucherTicketQuantity: IVoucherTicketQuantity;
    order: IOrder;
    campaign: ICampaign;
    voucher: IVoucher;
    productSubCategory: IProductSubCategory;
    chaophrayaCruiseBooking: IChaophrayaCruiseBooking;
}

export interface IChaophrayaCruiseBooking {
    id: number;
    confirmed: boolean;
}

export interface IProductSubCategory {
    id: number;
    slug: string;
    name: string;
}

export interface IVoucherTicketQuantity {
    total: number;
    used: number;
    unused: number;
    expired: number;
}

export interface IOrder {
    id: number;
    orderCode: string;
    createdAt: Date;
}

export interface IPartner {
    id: number;
    nameTh: string;
    nameEn: string;
    email: string;
    address: string;
    officeTelephoneNumber: string;
    additionalPhoneNumber: string;
}

export interface ICampaign {
    id: number;
    campaignCode: string;
    name: string;
    shopName: string;
    officeTelephoneNumber: string;
    partner: IPartner;
}

export interface IVoucher {
    id: number;
    name: string;
    price: number;
    voucherEndDate: Date;
    status: VoucherStatus;
}

export enum VoucherStatus {
    unknown = 'unknown',
    available = 'available',
    unavailable = 'unavailable',
    expired = 'expired',
}
