import { Injectable } from '@angular/core';
import {
    ICategoryTransportReference,
    CATEGORY_TRANSPORT_REFERENCES,
} from 'src/app/constants/category-transport.constant';

@Injectable()
export class CategoryTransportIconService {
    private categoryTransportationReferenceBySlug: {
        [slug: string]: ICategoryTransportReference;
    } = {};

    constructor() {
        CATEGORY_TRANSPORT_REFERENCES.forEach((reference) => {
            this.categoryTransportationReferenceBySlug[reference.slug] = reference;
        });
    }

    public getReferenceFromSlug(slug: string): ICategoryTransportReference {
        const reference = this.categoryTransportationReferenceBySlug[slug];
        return reference ? reference : null;
    }

    public getSvgIconFilePathFromSlug(slug: string): string {
        const reference = this.categoryTransportationReferenceBySlug[slug];
        return reference ? reference.svgIconFilePath : null;
    }
}
