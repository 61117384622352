import { Injectable, Inject, PLATFORM_ID } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Router } from '@angular/router';
import { Observable, throwError } from 'rxjs';
import { switchMap, map, switchMapTo } from 'rxjs/operators';

import { HttpService } from './base/http.service';
import { ENDPOINTS } from '../constants/endpoint.constant';
import { environment } from 'src/environments/environment';
import { IProductDeleteResponse } from '../modules/product/models/product.model';
import { ProductListFilter, ProductListQuery } from '../models/request/queries/product-list-query';
import { ProductPoolListQuery } from '../models/request/queries/product-pool-list-query';
import * as moment from 'moment';

@Injectable({
    providedIn: 'root',
})
export class ProductCatalogService extends HttpService {
    constructor(
        @Inject(PLATFORM_ID) protected platformId: Object,
        protected http: HttpClient,
        protected router: Router
    ) {
        super(platformId, http, router);
    }

    public getProductCountOfAgency(): Observable<any> {
        const url = environment.apiProUrl + ENDPOINTS.PRODUCT.COUNT;
        return this.getRequestOptions(true).pipe(switchMap((headers) => this.http.get(url, headers)));
    }

    public getProductMonth(categorySubProductIds: number[], fromProductPool: boolean = false): Observable<any> {
        const url = fromProductPool
            ? environment.apiProUrl + ENDPOINTS.PRODUCT_POOL.MONTHS
            : environment.apiProUrl + ENDPOINTS.PRODUCT.MONTH;
        return this.getRequestOptions(true).pipe(
            switchMap((headers) => {
                let params = new HttpParams();
                params = params.append('category_sub_product_ids', JSON.stringify(categorySubProductIds));

                return this.http.get(url, {
                    headers: headers.headers,
                    params,
                });
            })
        );
    }

    public getProductView(criteria: { poolKey?: string; poolProduct?: boolean; product_id?: number }): Observable<any> {
        let url = '';
        if (criteria.poolProduct) {
            if (criteria.poolKey) {
                url = environment.apiProUrl + ENDPOINTS.PRODUCT.POOL_VIEW_BY_POOL_KEY;
                url = url.replace('{pool_key}', criteria.poolKey);
            } else {
                url = environment.apiProUrl + ENDPOINTS.PRODUCT.POOL_VIEW;
                url = url.replace('{product_id}', criteria.product_id.toString());
            }
        } else {
            url = environment.apiProUrl + ENDPOINTS.PRODUCT.VIEW;
            url = url.replace('{product_id}', criteria.product_id.toString());
        }

        return this.getRequestOptions(true).pipe(switchMap((headers) => this.http.get(url, headers)));
    }

    public getProductList(query: ProductListQuery): Observable<any> {
        let url = environment.apiProUrl + ENDPOINTS.PRODUCT.LISTING;
        if (query.fromProductPool) {
            url = environment.apiProUrl + ENDPOINTS.PRODUCT.POOL_LISTING;
        }

        let params: HttpParams;
        if (query.fromProductPool) {
            params = new ProductPoolListQuery().fillFromProductListQuery(query).getHttpParams();
        } else {
            params = query.getHttpParams();
        }

        return this.getRequestOptions(true).pipe(
            switchMap((headers) => {
                const options = {
                    headers: headers.headers,
                    params,
                };

                return this.http.get(url, options).pipe(
                    map((resp: any) => {
                        if (resp.status !== 'success') {
                            throwError(resp);
                        }
                        return resp.data;
                    })
                );
            })
        );
    }

    public getProductListNameList(filter: ProductListFilter, fromProductPool: boolean): Observable<any> {
        let url = environment.apiProUrl + ENDPOINTS.PRODUCT.NAME_LISTING;
        if (fromProductPool) {
            url = environment.apiProUrl + ENDPOINTS.PRODUCT.POOL_NAME_LISTING;
        }
        let params = new HttpParams();

        if (filter) {
            const submissionFilter: any = Object.assign({}, filter);

            if (filter.months) {
                submissionFilter.period_ranges = submissionFilter.months.map((month) => ({
                    min_date: month,
                    max_date: moment(month).endOf('month').format('YYYY-MM-DD'),
                }));
            }
            if (filter.price_ranges && fromProductPool) {
                submissionFilter.price_ranges = submissionFilter.price_ranges.map((priceRange) => {
                    const segments = priceRange.split('-');
                    return {
                        min_price: parseInt(segments[0]),
                        max_price: segments[1] ? parseInt(segments[1]) : null,
                    };
                });
            }

            const $json = JSON.stringify(submissionFilter);
            params = params.append('filters', $json);
        }

        return this.getRequestOptions(true).pipe(
            switchMap((headers) => {
                const options = {
                    headers: headers.headers,
                    params,
                };

                return this.http.get(url, options).pipe(
                    map((resp: any) => {
                        if (resp.status !== 'success') {
                            throwError(resp);
                        }
                        return resp.data;
                    })
                );
            })
        );
    }

    public updateJoinProduct(productId: number, body: any): Observable<any> {
        const url = environment.apiProUrl + ENDPOINTS.PRODUCT.UPDATE_JOIN.replace('{product_id}', productId);
        return this.getRequestOptions(true).pipe(
            switchMap((headers) => {
                const options = {
                    headers: headers.headers,
                };
                return this.http.put(url, body, options);
            })
        );
    }

    public createDomesticProduct(body: any): Observable<any> {
        const url = environment.apiProUrl + ENDPOINTS.PRODUCT.CREATE_DOMESTIC;
        return this.getRequestOptions(true).pipe(
            switchMap((headers) => {
                const options = {
                    headers: headers.headers,
                };
                return this.http.post(url, body, options);
            })
        );
    }

    public createPackageProduct(body: any): Observable<any> {
        const url = environment.apiProUrl + ENDPOINTS.PRODUCT.CREATE_PACKAGE;
        return this.getRequestOptions(true).pipe(
            switchMap((headers) => {
                const options = {
                    headers: headers.headers,
                };
                return this.http.post(url, body, options);
            })
        );
    }

    public updateDomesticProduct(productId: number, body: any): Observable<any> {
        const url = environment.apiProUrl + ENDPOINTS.PRODUCT.UPDATE_DOMESTIC.replace('{product_id}', productId);
        return this.getRequestOptions(true).pipe(
            switchMap((headers) => {
                const options = {
                    headers: headers.headers,
                };
                return this.http.put(url, body, options);
            })
        );
    }

    public updatePackageProduct(productId: number, body: any): Observable<any> {
        const url = environment.apiProUrl + ENDPOINTS.PRODUCT.UPDATE_PACKAGE.replace('{product_id}', productId);
        return this.getRequestOptions(true).pipe(
            switchMap((headers) => {
                const options = {
                    headers: headers.headers,
                };
                return this.http.put(url, body, options);
            })
        );
    }

    public deleteProduct(body: any): Observable<IProductDeleteResponse> {
        const url = environment.apiProUrl + ENDPOINTS.PRODUCT.DELETE;
        return this.getRequestOptions(true).pipe(
            switchMap((headers) => {
                return this.http.request('DELETE', url, { body, headers: headers.headers }).pipe(
                    map((response: any) => {
                        if (response.status !== 'success') {
                            throwError(response);
                        }
                        return response.data;
                    })
                );
            })
        );
    }

    public getProduct(productId: number): Observable<any> {
        const url = environment.apiProUrl + ENDPOINTS.PRODUCT.GET.replace('{product_id}', productId);
        return this.getRequestOptions(true).pipe(
            switchMap((headers) => {
                const options = {
                    headers: headers.headers,
                };
                return this.http.get(url, options);
            })
        );
    }

    public createJoinProduct(body: any): Observable<any> {
        const url = environment.apiProUrl + ENDPOINTS.PRODUCT.CREATE_JOIN;
        return this.getRequestOptions(true).pipe(
            switchMap((headers) => {
                const options = {
                    headers: headers.headers,
                };
                return this.http.post(url, body, options);
            })
        );
    }

    public updateProductPoolRecommendation(productPoolId: number, isRecommended: boolean): Observable<any> {
        const url =
            environment.apiProUrl + ENDPOINTS.PRODUCT.POOL_RECOMMENDATION.replace('{product_pool_id}', productPoolId);
        const body = {
            is_recommended: isRecommended,
        };
        return this.getRequestOptions(true).pipe(
            switchMap((headers) => {
                const options = {
                    headers: headers.headers,
                };
                return this.http.put(url, body, options);
            })
        );
    }

    public getProductPoolDataFromSearchString(searchData: string) {
        const url =
            environment.apiProUrl + ENDPOINTS.PRODUCT.POOL_DATA_FROM_SEARCH_STRING.replace('{search_data}', searchData);
        return this.getRequestOptions(true).pipe(
            switchMap((headers) => {
                const options = {
                    headers: headers.headers,
                };
                return this.http.get(url, options);
            })
        );
    }

    public getProductDocumentFileBase64(productId: number, isFromPool: boolean): Observable<any> {
        let url =
            environment.apiProUrl +
            ENDPOINTS.AGENCY_PRODUCT.GET_DOCUMENT_FILE_BASE_64.replace('{product_id}', productId);
        if (isFromPool) {
            url = null;
        }
        return this.getRequestOptions(true).pipe(
            switchMap((Headers) => {
                const options = {
                    headers: Headers.headers,
                };
                return this.http.get(url, options);
            })
        );
    }

    public getProductPdfFileBase64(productId: number, isFromPool: boolean): Observable<any> {
        let url =
            environment.apiProUrl + ENDPOINTS.AGENCY_PRODUCT.GET_PDF_FILE_BASE_64.replace('{product_id}', productId);
        if (isFromPool) {
            url = null;
        }
        return this.getRequestOptions(true).pipe(
            switchMap((Headers) => {
                const options = {
                    headers: Headers.headers,
                };
                return this.http.get(url, options);
            })
        );
    }
}
