import { createFeatureSelector, createSelector } from '@ngrx/store';
import * as fromProduct from './product.reducer';
import { ProductFileBase64 } from 'src/app/models/product-file-base64.model';

export const selectProductState = createFeatureSelector<fromProduct.ProductState>(fromProduct.productFeatureKey);

export const selectProductCountOfAgency = createSelector(
    selectProductState,
    (state: fromProduct.ProductState) => state.productCountOfAgency
);

export const selectProductMonth = createSelector(
    selectProductState,
    (state: fromProduct.ProductState) => state.productMonth
);

export const selectProductdelete = createSelector(
    selectProductState,
    (state: fromProduct.ProductState) => state.deleteProductResponse
);

export const selectProductList = createSelector(
    selectProductState,
    (state: fromProduct.ProductState) => state.productList
);

export const selectProductView = createSelector(
    selectProductState,
    (state: fromProduct.ProductState) => state.productView
);

export const selectProductListQuery = createSelector(
    selectProductState,
    (state: fromProduct.ProductState) => state.productListQuery
);

export const selectProductListCurrentPage = createSelector(
    selectProductState,
    (state: fromProduct.ProductState) => state.productListCurrentPage
);

export const selectProductListTotalRecord = createSelector(
    selectProductState,
    (state: fromProduct.ProductState) => state.productListTotalRecord
);

export const selectProduct = createSelector(selectProductState, (state: fromProduct.ProductState) => state.product);

export const selectCreateProductResponse = createSelector(
    selectProductState,
    (state: fromProduct.ProductState) => state.createProductResponse
);

export const selectUpdateProductResponse = createSelector(
    selectProductState,
    (state: fromProduct.ProductState) => state.updateProductResponse
);

export const selectUpdateProductPoolRecommendationResponse = createSelector(
    selectProductState,
    (state: fromProduct.ProductState) => state.updateProductPoolRecommendationResponse
);

export const selectGetProductDetailsFromSearchString = createSelector(
    selectProductState,
    (state: fromProduct.ProductState) => state.searchStringProductDetails
);

export const selectGetErrorFromSearchString = createSelector(
    selectProductState,
    (state: fromProduct.ProductState) => state.searchStringError
);

export const selectProductDocumentFileBase64 = createSelector(selectProductState, (state: fromProduct.ProductState) => {
    if (state.productDocumentFileBase64) {
        return new ProductFileBase64().deserialize(state.productDocumentFileBase64);
    }
    return null;
});

export const selectProductPdfFileBase64 = createSelector(selectProductState, (state: fromProduct.ProductState) => {
    if (state.productPdfFileBase64) {
        return new ProductFileBase64().deserialize(state.productPdfFileBase64);
    }
    return null;
});

export const selectProductNameList = createSelector(
    selectProductState,
    (state: fromProduct.ProductState) => state.productNameList
);

export const selectProductSortBy = createSelector(
    selectProductState,
    (state: fromProduct.ProductState) => state.sortBy
);

export const selectProductPdfDetailFile = createSelector(
    selectProductState,
    (state: fromProduct.ProductState) => state.productPdfDetailFile
);

export const selectProductOwner = createSelector(
    selectProductState,
    (state: fromProduct.ProductState) => state.productOwner
);

export const selectCampaignDetail = createSelector(
    selectProductState,
    (state: fromProduct.ProductState) => state.campaignDetail
);

export const selectError = createSelector(selectProductState, (state: fromProduct.ProductState) => state.error);
