<div class="gallery-image-uploader mt-3">
    <div class="gallery-uploader-wrapper">
        <div class="gallery-uploader">
            <div class="gallery-item d-flex justify-content-center mb-4">
                <button (click)="imagePreview ? deleteImg() : deleteComponent()"
                type="button" class="btn-bin">
                    <i class="icon icon-garbage"></i>
                </button>
                <div class="gallery-image" *ngIf="imagePreview">
                    <img [src]="imagePreview">
                </div>
            </div>
            <label class="w-100">
                <div class="d-inline-flex align-items-center justify-content-center flex-column w-100">
                    <img class="mb-2" src="assets/img/img-default.png" *ngIf="!imagePreview">
                    <label class="btn btn-upload" for="upload-image-{{index}}">อัพโหลดรูป</label>
                    <input
                        id="upload-image-{{index}}"
                        class="d-none" type="file"
                        accept="image/jpg,image/jpeg,image/png"
                        (change)="uploadImage($event)"
                    >
                    <span class="d-flex mt-1 mb-1 img-notice">
                        ขนาดรูป .Jpg หรือ .Png แนะนำความกว้าง 1600 x 900 px ไม่เกิน 1 MB
                    </span>
                </div>
            </label>
            <div class="input-img">
                <input class="form-control" placeholder="กรอกรายละเอียดเกี่ยวกับรูป" type="text" maxlength=200
                [(ngModel)]="imageDescription" (ngModelChange)="changeDescription($event)">
            </div>
        </div>
    </div>
</div>
