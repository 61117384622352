import { LEAD_TYPE_SLUG } from 'src/app/constants/communication-method.constant';
import { Agency } from 'src/app/models/agency';
import { NavigationEnd, Router } from '@angular/router';
import { LEAD_STATUS_SLUG } from '../../../../../constants/lead-status.constant';
import { Component, OnInit } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { Store } from '@ngrx/store';
import * as ReportActions from 'src/app/stores/report-store/report.actions';
import { State } from 'src/app/stores/reducers';
import { ILeadDetails } from '../../../models/lead-details.model';
import { selectAgencyInfo } from 'src/app/stores/user-store/user.selectors';

@Component({
    selector: 'app-cancel-lead',
    templateUrl: './cancel-lead.component.html',
    styleUrls: ['./cancel-lead.component.scss'],
})
export class CancelLeadComponent implements OnInit {
    private id: number;
    public lead: ILeadDetails;
    public agencyInfo: Agency;
    public period: string;
    public LEAD_TYPE_SLUG = LEAD_TYPE_SLUG;

    constructor(
        public bsModalRef: BsModalRef,
        public bsModalService: BsModalService,
        private store: Store<State>,
        private router: Router
    ) {}

    ngOnInit() {
        this.store.select(selectAgencyInfo).subscribe((agency) => (this.agencyInfo = agency));

        this.bsModalService.setDismissReason('undo');
        this.closeOnRouteChange();
    }

    public submitButton(): void {
        this.bsModalService.setDismissReason(this.id + '-confirm-cancel');
        this.store.dispatch(
            ReportActions.updateLeadStatus({
                id: this.id,
                statusSlug: 'cancel',
            })
        );
        this.bsModalRef.hide();
    }

    closeOnRouteChange(): void {
        this.router.events.subscribe((evt) => {
            if (!(evt instanceof NavigationEnd)) {
                return;
            }
            this.bsModalRef.hide();
        });
    }
}
