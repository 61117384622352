import { Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faDownload } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import saveAs from 'file-saver';
import { SafeUrl } from '@angular/platform-browser';

@Component({
    selector: 'app-pdf-preview-modal',
    standalone: true,
    imports: [CommonModule, FontAwesomeModule, PdfViewerModule],
    templateUrl: './pdf-preview-modal.component.html',
    styleUrls: ['./pdf-preview-modal.component.scss'],
})
export class PdfPreviewModalComponent implements OnInit {
    pdfName: string = 'receipt';
    pdfSrc!: SafeUrl;
    pdfBlob!: Blob;

    icons = {
        faDownload: faDownload as IconProp,
    };

    constructor(public bsModalRef: BsModalRef) {}

    ngOnInit(): void {
        this.pdfSrc = URL.createObjectURL(this.pdfBlob);
    }

    onDownloadPdf() {
        if (this.pdfBlob) {
            saveAs(this.pdfBlob, this.pdfName + '.pdf');
        }
    }
}
