import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { filter } from 'rxjs/operators';
import { IBreadcrumb } from 'src/app/models/breadcrumb.model';
import {
    IOrderBalancePeriod,
    IOrderBalancePeriodItem,
    OrderBalancePeriodItem,
} from 'src/app/models/order-balance-period.model';
import { IOrderBalanceReport, IOrderBalanceReportOverView } from 'src/app/models/order-balance-report.model';
import { State } from 'src/app/stores/reducers';
import {
    getOrderBalancePeriod,
    getOrderBalanceReport,
    getOrderBalanceReportItems,
} from 'src/app/stores/report-store/report.actions';
import {
    selectOrderBalancePeriod,
    selectOrderBalanceReport,
    selectOrderBalanceReportItems,
} from 'src/app/stores/report-store/report.selectors';
import { EnumOrderBalanceReportTab } from '../../components/order-balance-report-tab/order-balance-report-tab.enum';
import {
    IOrderBalanceReportCriteria,
    OrderBalanceReportCriteria,
} from '../../models/order-balance-report-criteria.model';

@Component({
    selector: 'app-order-balance-transaction-page',
    templateUrl: './order-balance-transaction.component.html',
    styleUrls: ['./order-balance-transaction.component.scss'],
})
export class OrderBalanceTransactionPageComponent implements OnInit {
    public defaultBalanceId: string;
    public breadcrumb: IBreadcrumb[];
    public activeTab: EnumOrderBalanceReportTab;
    public searchCriteria: IOrderBalanceReportCriteria;
    public reportOverView: IOrderBalanceReportOverView;
    public reportView: IOrderBalanceReport;
    public periodBalances: IOrderBalancePeriod;
    public yearNumbersView: Array<number>;
    public yearlyPeriodsView: Map<number, IOrderBalancePeriodItem[]>;
    public selectedPeriod: IOrderBalancePeriodItem;
    public periodInfoView: IOrderBalancePeriodItem;

    get showListingTab(): boolean {
        return this.activeTab === EnumOrderBalanceReportTab.Listing;
    }
    get showReceiptTab(): boolean {
        return this.activeTab === EnumOrderBalanceReportTab.Receipt;
    }

    get totalEndReportsOverview(): number {
        if (!this.reportOverView) {
            return 0;
        }
        return Math.min(this.reportOverView.total, this.reportOverView.data.length + this.reportOverView.pageEntries);
    }

    constructor(private store: Store<State>, private activatedRoute: ActivatedRoute) {}

    ngOnInit(): void {
        this.initSearchCriteria();
        this.setBreadcrumb();
        this.dispatchOrderBalancePeriod();
    }

    changeTab(currentTab: EnumOrderBalanceReportTab): void {
        this.activeTab = currentTab;
        this.fetchReportByActiveTab();
    }

    changeSortOrder(sortOrder: 'order_created_at_desc' | 'partner_name_asc'): void {
        this.searchCriteria.page = 1;
        this.searchCriteria.orderBy = sortOrder;
        this.fetchReportByActiveTab();
    }

    selectedPeriodFromFilter(period: IOrderBalancePeriodItem): void {
        this.selectedPeriod = period;
        if (!period) {
            this.selectedPeriod = this.periodBalances.data.find(
                (period) => period.orderBalance.id + '' === this.defaultBalanceId
            );
        }
        if (!this.periodInfoView) {
            this.periodInfoView = this.selectedPeriod;
        }
    }

    applySearchFromFilter(): void {
        this.searchCriteria.page = 1;
        this.searchCriteria.balanceId = this.selectedPeriod
            ? this.selectedPeriod.orderBalance.id.toString()
            : this.defaultBalanceId;
        this.periodInfoView = this.selectedPeriod;
        this.fetchReportByActiveTab();
    }

    loadMoreReports(): void {
        this.searchCriteria.page++;
        this.fetchReportByActiveTab(true);
    }

    private fetchReportByActiveTab(nextPage?: boolean) {
        switch (this.activeTab) {
            case EnumOrderBalanceReportTab.Listing:
                this.dispatchOrderBalanceReportItems(nextPage);
                break;
            case EnumOrderBalanceReportTab.Receipt:
                this.dispatchOrderBalanceReportOverview(nextPage);
                break;
        }
    }

    private initSearchCriteria() {
        this.defaultBalanceId = this.activatedRoute.snapshot.paramMap.get('id');
        this.searchCriteria = new OrderBalanceReportCriteria();
        this.searchCriteria.balanceId = this.defaultBalanceId;
    }

    private dispatchOrderBalancePeriod() {
        this.store.dispatch(getOrderBalancePeriod());

        this.store
            .pipe(
                select(selectOrderBalancePeriod),
                filter((f) => !!f)
            )
            .subscribe((v: IOrderBalancePeriod) => {
                this.periodBalances = v;
                this.yearlyPeriodsView = new OrderBalancePeriodItem().castFromPeriodViews(v.data);
                this.yearNumbersView = Array.from(this.yearlyPeriodsView.keys());
            });
    }

    private dispatchOrderBalanceReportOverview(nextPage?: boolean) {
        if (!nextPage) {
            this.searchCriteria.pageEntries = 10;
        }

        this.store.dispatch(
            getOrderBalanceReport({
                criteria: this.searchCriteria,
                nextPage: nextPage,
            })
        );

        this.store
            .pipe(
                select(selectOrderBalanceReport),
                filter((f) => !!f)
            )
            .subscribe((v: IOrderBalanceReportOverView) => {
                this.reportOverView = v;
            });
    }

    private dispatchOrderBalanceReportItems(nextPage?: boolean) {
        if (!nextPage) {
            this.searchCriteria.pageEntries = 50;
        }

        this.store.dispatch(
            getOrderBalanceReportItems({
                criteria: this.searchCriteria,
                nextPage: nextPage,
            })
        );

        this.store
            .pipe(
                select(selectOrderBalanceReportItems),
                filter((f) => !!f)
            )
            .subscribe((v: IOrderBalanceReport) => {
                this.reportView = v;
            });
    }

    private setBreadcrumb(): void {
        this.breadcrumb = [
            { name: 'หน้าแรก', link: '/dashboard' },
            { name: 'Order Balancing Report', link: `/report/order-balance-report/${this.searchCriteria.balanceId}` },
        ];
    }
}
