import { Component, OnInit } from '@angular/core';
import { IBreadcrumb } from 'src/app/models/breadcrumb.model';

@Component({
    selector: 'app-lead-booking-summary',
    templateUrl: './lead-booking-summary.component.html',
    styleUrls: ['./lead-booking-summary.component.scss'],
})
export class LeadBookingSummaryComponent implements OnInit {
    public breadcrumb: IBreadcrumb[];
    public showFilter: boolean = false;

    constructor() {}

    ngOnInit() {
        this.setBreadcrumb();
    }

    public setBreadcrumb(): void {
        this.breadcrumb = [
            { name: 'หน้าแรก', link: '/dashboard' },
            { name: 'รายงานสรุปข้อมูลใบจอง', link: '/report/lead-booking-summary' },
        ];
    }

    public toggleFilter(): void {
        if (this.showFilter) {
            this.showFilter = false;
        } else {
            this.showFilter = true;
        }
    }
}
