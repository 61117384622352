import { HttpClient } from '@angular/common/http';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { HttpService } from './base/http.service';
import { ENDPOINTS } from '../constants/endpoint.constant';

@Injectable({
    providedIn: 'root',
})
export class ProductOwnerService extends HttpService {
    constructor(
        @Inject(PLATFORM_ID) protected platformId: Object,
        protected http: HttpClient,
        protected router: Router
    ) {
        super(platformId, http, router);
    }

    public getProductOwnerDetail(ownerChannel: string, ownerId: number): Observable<any> {
        let url = environment.apiProUrl + ENDPOINTS.PRODUCT.OWNER;
        url = url.replace('{owner_channel}', ownerChannel);
        url = url.replace('{owner_id}', ownerId.toString());
        return this.getRequestOptions(true).pipe(switchMap((headers) => this.http.get(url, headers)));
    }
}
