import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'displayTransport',
})
export class DisplayTransportPipe implements PipeTransform {
    transform(value: any, ...args: any[]): any {
        if (value) {
            return `${value.name} (${value.code})`;
        }

        return null;
    }
}
