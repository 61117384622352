import { Component, Input } from '@angular/core';

import { IMenu } from 'src/app/models/menu.model';

@Component({
    selector: 'app-breadcrumb',
    templateUrl: './breadcrumb.component.html',
    styleUrls: ['./breadcrumb.component.scss'],
})
export class BreadcrumbComponent {
    @Input() items: IMenu[];

    constructor() {}
}
