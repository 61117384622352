<div>
    <h1 id="header">
        <i class="icon icon-add-booking-orange mr-1"></i>
        <span *ngIf="!paramOrderId">เพิ่ม Booking</span>
        <ng-container *ngIf="paramOrderId">
            <span>แก้ไข Booking</span>
            <span class="small ml-1">Order: {{ paramOrderId }}</span>
        </ng-container>
    </h1>

    <app-program-tours-filter-select
        #programFilter
        (programSelected)="program = $event"
        (periodSelected)="period = $event"
        (changeMode)="mode = $event"
        (formValid)="programTourFilterValid = $event"
        [order]="order"
        [mode]="mode"
    ></app-program-tours-filter-select>
    <app-sku-list-form
        [program]="program"
        [period]="period"
        [order]="order"
        [mode]="mode"
        (formValid)="skuListValid = $event"
        (skuChange)="skuFormChange($event)"
    ></app-sku-list-form>
    <app-customer-info-form
        #customerForm
        class="d-block mt-4"
        [order]="order"
        [period]="period"
        (formValid)="customerInfoValid = $event"
        (infoChange)="customerInfoFormChange($event)"
    ></app-customer-info-form>

    <!--  -->
    <div *ngIf="showPreviewLink || mode === MODE.VIEW" class="preview-link-wrapper">
        <a (click)="togglePreviewDisplay()">ดู PREVIEW</a>
    </div>
    <ng-container *ngIf="showPreview">
        <app-booking-detail-preview
            [skuList]="skuList"
            [quantity]="allSKUQuantity"
            [program]="program"
            [period]="period"
            [customerInfo]="customerInfo"
        ></app-booking-detail-preview>
    </ng-container>

    <div class="button-wrapper">
        <div *ngIf="!formValid && isSubmitted" class="validate-text mb-1">กรุณากรอกข้อมูลให้ครบถ้วน</div>
        <button (click)="onClickSubmit()" [ngClass]="{ disabled: disabledSubmitButton }" class="btn btn-primary">
            ยืนยันการจอง
        </button>
        <button (click)="onClickCancel()" class="btn btn-cancel">ยกเลิก</button>
    </div>
</div>
