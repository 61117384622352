import { HttpClient, HttpParams } from '@angular/common/http';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, throwError } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { ENDPOINTS } from '../constants/endpoint.constant';
import { IApiResponse } from '../models/interfaces/api-response.interface';
import { IPaginationResponse } from '../models/interfaces/pagination-response.interface';
import { PaginationResponse } from '../models/pagination-response.model';
import { IAddBookingB2c } from '../modules/order/models/add-booking-b2c.model';
import { IBookingListQueries } from '../modules/order/models/booking-list.model';
import { ICampaignPaymentPaginatedFilter } from '../modules/order/models/campaign-payment.model';
import { ICommissionReportListQueries } from '../modules/order/models/commission-report-list-queries';
import { IEditBookingB2c } from '../modules/order/models/edit-booking-b2c.model';
import { IGroupPlanListQueries } from '../modules/order/models/group-plan-list-queries';
import { ITwBookingListQueries } from '../modules/order/models/interfaces/tw-booking-interface/tw-booking-list-queries.interface';
import { ITwBooking } from '../modules/order/models/interfaces/tw-booking-interface/tw-booking.interface';
import { ISaleReportListPaginatedQueries, ISaleReportSummaryQueries } from '../modules/order/models/sale-report.model';
import { TwBooking } from '../modules/order/models/tw-booking-model/tw-booking.model';
import { IBookingReportDownloadSearch } from '../modules/report/models/booking-report-download-search.model';
import { ILeadReportDownloadSearch } from '../modules/report/models/lead-report-download-search.model';
import { IOrderSummarySearch } from './../modules/report/models/order-summary-search.model';
import { HttpService } from './base/http.service';

@Injectable({
    providedIn: 'root',
})
export class OrderService extends HttpService {
    constructor(
        @Inject(PLATFORM_ID) protected platformId: Object,
        protected http: HttpClient,
        protected router: Router
    ) {
        super(platformId, http, router);
    }

    public getOrderById(orderId: number) {
        const url = environment.apiProUrl + ENDPOINTS.ORDER.SINGLE_ITEM.replace('{order_id}', orderId + '');
        return this.getRequestOptions(true).pipe(
            switchMap((headers) => {
                const options = {
                    headers: headers.headers,
                };

                return this.http.get(url, options).pipe(
                    map((resp: any) => {
                        if (resp.status !== 'success') {
                            throwError(resp);
                        }
                        return resp;
                    })
                );
            })
        );
    }

    public createNewBookingOrder(body: IAddBookingB2c): Observable<any> {
        const url = environment.apiProUrl + ENDPOINTS.ORDER.CREATE_NEW_BOOKING_ORDER;

        return this.getRequestOptions(true).pipe(
            switchMap((headers) => {
                const options = {
                    headers: headers.headers,
                };
                return this.http.post(url, body, options);
            })
        );
    }

    public getBookingStatMonthly(criteria: ILeadReportDownloadSearch): Observable<any> {
        let params = new HttpParams();
        if (criteria) {
            params = params.append('filters', JSON.stringify(criteria));
        }

        const url = environment.apiProUrl + ENDPOINTS.ORDER.BOOKING_STAT_MONTHLY;

        return this.getRequestOptions(true).pipe(
            switchMap((headers) => {
                const options = {
                    headers: headers.headers,
                    params,
                };

                return this.http.get(url, options).pipe(
                    map((resp: any) => {
                        if (resp.status !== 'success') {
                            throwError(resp);
                        }
                        return resp;
                    })
                );
            })
        );
    }

    public getBookingStatMonthlyDetails(criteria: IBookingReportDownloadSearch): Observable<any> {
        let params = new HttpParams();
        if (criteria) {
            params = params.append('filters', JSON.stringify(criteria));
        }

        const url = environment.apiProUrl + ENDPOINTS.ORDER.BOOKING_STAT_MONTHLY_DETAILS;

        return this.getRequestOptions(true).pipe(
            switchMap((headers) => {
                const options = {
                    headers: headers.headers,
                    params,
                };

                return this.http.get(url, options).pipe(
                    map((resp: any) => {
                        if (resp.status !== 'success') {
                            throwError(resp);
                        }
                        return resp;
                    })
                );
            })
        );
    }

    public getBookingListPaginated(queries: IBookingListQueries): Observable<any> {
        const url = environment.apiProUrl + ENDPOINTS.ORDER.BOOKING_LIST_PAGINATED;

        let params = new HttpParams();
        if (queries) {
            if (queries.filters) {
                params = params.append('filters', JSON.stringify(queries.filters));
            }
            if (queries.criteria) {
                params = params.append('page', '' + queries.criteria.page);
                params = params.append('page_entries', '' + queries.criteria.pageEntries);
            }
            if (queries.sortBy) {
                params = params.append('sort_by', '' + queries.sortBy);
            }

            return this.getRequestOptions(true).pipe(
                switchMap((headers) => {
                    const options = {
                        headers: headers.headers,
                        params,
                    };

                    return this.http.get(url, options).pipe(
                        map((resp: any) => {
                            if (resp.status !== 'success') {
                                throwError(resp);
                            }
                            return resp;
                        })
                    );
                })
            );
        }
    }

    public getBookingStatusCount(dates: { min_date: string; max_date: string }): Observable<any> {
        const url = environment.apiProUrl + ENDPOINTS.ORDER.BOOKING_STATUS_COUNT;

        let params = new HttpParams();
        if (dates) {
            const filters = {
                created_at_between: dates,
            };
            params = params.append('filters', JSON.stringify(filters));
        }

        return this.getRequestOptions(true).pipe(
            switchMap((headers) => {
                const options = {
                    headers: headers.headers,
                    params,
                };

                return this.http.get(url, options).pipe(
                    map((resp: any) => {
                        if (resp.status !== 'success') {
                            throwError(resp);
                        }
                        return resp;
                    })
                );
            })
        );
    }

    public getOrderSummaries(criteria: IOrderSummarySearch): Observable<any> {
        let params = new HttpParams();
        if (criteria) {
            if (criteria.interval_direction) {
                params = params.append('interval_direction', criteria.interval_direction);
            }
            if (criteria.interval_limit) {
                params = params.append('interval_limit', criteria.interval_limit + '');
            }
            if (criteria.interval_type) {
                params = params.append('interval_type', criteria.interval_type);
            }
        }

        const url = environment.apiProUrl + ENDPOINTS.ORDER.ORDER_SUMMARIES;

        return this.getRequestOptions(true).pipe(
            switchMap((headers) => {
                const options = {
                    headers: headers.headers,
                    params,
                };

                return this.http.get(url, options).pipe(
                    map((resp: any) => {
                        if (resp.status !== 'success') {
                            throwError(resp);
                        }
                        return resp;
                    })
                );
            })
        );
    }

    public getOrderCommissionSummaries(criteria: IOrderSummarySearch): Observable<any> {
        let params = new HttpParams();
        if (criteria) {
            if (criteria.interval_direction) {
                params = params.append('interval_direction', criteria.interval_direction);
            }
            if (criteria.interval_limit) {
                params = params.append('interval_limit', criteria.interval_limit + '');
            }
            if (criteria.interval_type) {
                params = params.append('interval_type', criteria.interval_type);
            }
        }

        const url = environment.apiProUrl + ENDPOINTS.ORDER.ORDER_COMMISSION_SUMMARIES;

        return this.getRequestOptions(true).pipe(
            switchMap((headers) => {
                const options = {
                    headers: headers.headers,
                    params,
                };

                return this.http.get(url, options).pipe(
                    map((resp: any) => {
                        if (resp.status !== 'success') {
                            throwError(resp);
                        }
                        return resp;
                    })
                );
            })
        );
    }

    public removeBookingFile(bookingOrderId: number, fileId: number): Observable<any> {
        const url =
            environment.apiProUrl +
            ENDPOINTS.ORDER.DELETE_BOOKING_FILE.replace('{order_id}', bookingOrderId).replace(
                '{order_file_id}',
                fileId
            );

        return this.getRequestOptions(true).pipe(
            switchMap((headers) => {
                const options = {
                    headers: headers.headers,
                };

                return this.http.delete(url, options).pipe(
                    map((resp: any) => {
                        if (resp.status !== 'success') {
                            throwError(resp);
                        }
                        return resp;
                    })
                );
            })
        );
    }

    public modifyBookingStatus(bookingOrderId: number, status: number): Observable<any> {
        const url = environment.apiProUrl + ENDPOINTS.ORDER.BOOKING_MODIFY_STATUS.replace('{order_id}', bookingOrderId);

        const requestBody: { order_status: number } = {
            order_status: status,
        };

        return this.getRequestOptions(true).pipe(
            switchMap((headers) => {
                const options = {
                    headers: headers.headers,
                };

                return this.http.put(url, requestBody, options).pipe(
                    map((resp: any) => {
                        if (resp.status !== 'success') {
                            throwError(resp);
                        }
                        return resp;
                    })
                );
            })
        );
    }

    public bookingUploadFile(bookingOrderId: number, formData: FormData): Observable<any> {
        const url = environment.apiProUrl + ENDPOINTS.ORDER.BOOKING_UPLOAD_FILE.replace('{order_id}', bookingOrderId);

        return this.http
            .post(url, formData, {
                headers: {
                    Authorization: this.getToken(),
                },
            })
            .pipe(
                map((resp: any) => {
                    if (resp.status !== 'success') {
                        throwError(resp);
                    }
                    return resp;
                })
            );
    }

    public editExistingBooking(id: number, body: IEditBookingB2c): Observable<any> {
        const url = environment.apiProUrl + ENDPOINTS.ORDER.SINGLE_ITEM.replace('{order_id}', id);

        return this.getRequestOptions(true).pipe(
            switchMap((headers) => {
                const options = {
                    headers: headers.headers,
                };
                return this.http.put(url, body, options);
            })
        );
    }

    public getProductList(owner: { id: number; channel: 'ws' | 'ag' }): Observable<any> {
        const url = environment.apiProUrl + ENDPOINTS.ORDER.BOOKING_PRODUCT_LIST;
        let params = new HttpParams();
        const filters = {
            owner: owner,
        };
        params = params.append('filters', JSON.stringify(filters));

        return this.getRequestOptions(true).pipe(
            switchMap((headers) => {
                const options = {
                    headers: headers.headers,
                    params: params,
                };

                return this.http.get(url, options).pipe(
                    map((resp: any) => {
                        if (resp.status !== 'success') {
                            throwError(resp);
                        }
                        return resp;
                    })
                );
            })
        );
    }

    public getProductOwnerList(): Observable<any> {
        const url = environment.apiProUrl + ENDPOINTS.ORDER.BOOKING_PRODUCTOWNER_LIST;
        return this.getRequestOptions(true).pipe(
            switchMap((headers) => {
                const options = {
                    headers: headers.headers,
                };

                return this.http.get(url, options).pipe(
                    map((resp: any) => {
                        if (resp.status !== 'success') {
                            throwError(resp);
                        }
                        return resp;
                    })
                );
            })
        );
    }

    public getSaleReportSummary(query?: ISaleReportSummaryQueries): Observable<any> {
        const url = environment.apiProUrl + ENDPOINTS.ORDER.BOOKING_SALE_REPORT_SUMMARY;
        let params = new HttpParams();
        if (query) {
            if (query.intervalDirection) {
                params = params.append('interval_direction', query.intervalDirection);
            }
            if (query.intervalLimit) {
                params = params.append('interval_limit', query.intervalLimit + '');
            }
            if (query.intervalType) {
                params = params.append('interval_type', query.intervalType);
            }
        }
        return this.getRequestOptions(true).pipe(
            switchMap((headers) => {
                const options = {
                    headers: headers.headers,
                    params,
                };

                return this.http.get(url, options).pipe(
                    map((resp: any) => {
                        if (resp.status !== 'success') {
                            throwError(resp);
                        }
                        return resp;
                    })
                );
            })
        );
    }

    public getSaleReportListPaginated(query?: ISaleReportListPaginatedQueries): Observable<any> {
        const url = environment.apiProUrl + ENDPOINTS.ORDER.BOOKING_SALE_REPORT_LIST_PAGINATED;
        let params = new HttpParams();
        if (query) {
            if (query.criteria?.page) {
                params = params.append('page', query.criteria.page + '');
            }
            if (query.criteria?.pageEntries) {
                params = params.append('page_entries', query.criteria.pageEntries + '');
            }
            if (query.filters) {
                params = params.append('filters', JSON.stringify(query.filters));
            }
        }
        return this.getRequestOptions(true).pipe(
            switchMap((headers) => {
                const options = {
                    headers: headers.headers,
                    params,
                };

                return this.http.get(url, options).pipe(
                    map((resp: any) => {
                        if (resp.status !== 'success') {
                            throwError(resp);
                        }
                        return resp;
                    })
                );
            })
        );
    }

    public getGroupPlanListPaginated(queries: IGroupPlanListQueries): Observable<any> {
        const url = environment.apiProUrl + ENDPOINTS.ORDER.GROUP_PLAN_LIST_PAGINATED;

        let params = new HttpParams();
        if (queries) {
            if (queries.filters) {
                params = params.append('filters', JSON.stringify(queries.filters));
            }
            params = params.append('page', '' + queries.criteria.page);
            params = params.append('page_entries', '' + queries.criteria.pageEntries);

            return this.getRequestOptions(true).pipe(
                switchMap((headers) => {
                    const options = {
                        headers: headers.headers,
                        params,
                    };

                    return this.http.get(url, options).pipe(
                        map((resp: any) => {
                            if (resp.status !== 'success') {
                                throwError(resp);
                            }
                            return resp;
                        })
                    );
                })
            );
        }
    }

    public getCommissionPortListPaginated(queries: ICommissionReportListQueries): Observable<any> {
        const url = environment.apiProUrl + ENDPOINTS.ORDER.COMMISSION_REPORT_LIST_PAGINATED;

        let params = new HttpParams();
        if (queries) {
            if (queries.filters) {
                params = params.append('filters', JSON.stringify(queries.filters));
            }
            params = params.append('page', '' + queries.criteria.page);
            params = params.append('page_entries', '' + queries.criteria.pageEntries);

            return this.getRequestOptions(true).pipe(
                switchMap((headers) => {
                    const options = {
                        headers: headers.headers,
                        params,
                    };

                    return this.http.get(url, options).pipe(
                        map((resp: any) => {
                            if (resp.status !== 'success') {
                                throwError(resp);
                            }
                            return resp;
                        })
                    );
                })
            );
        }
    }

    public getCampaignPaymentPaginated(filter: ICampaignPaymentPaginatedFilter = null): Observable<any> {
        let params = new HttpParams();
        params = params.append('filters', this.serializeCampaignPaymentPaginatedFilter(filter));
        params = params.append('page', filter?.page?.toString());
        params = params.append('page_entries', filter?.pageEntries?.toString());

        const url = environment.apiProUrl + ENDPOINTS.CAMPAIGN_PAYMENT.GET_LIST_PAGINATED;

        return this.getRequestOptions(true).pipe(
            switchMap((headers) => {
                const options = {
                    headers: headers.headers,
                    params,
                };

                return this.http.get(url, options).pipe(
                    map((resp: any) => {
                        if (resp.status !== 'success') {
                            throwError(resp);
                        }
                        return resp;
                    })
                );
            })
        );
    }

    private serializeCampaignPaymentPaginatedFilter(filter: ICampaignPaymentPaginatedFilter): string {
        const webServicefilter = {
            search_string: filter?.clientNamePhoneOrVoucherCode,
            has_usable_voucher_tickets: filter?.hasUsableVoucherTickets,
        };

        return JSON.stringify(webServicefilter);
    }

    public getCampaignPaymentCount(): Observable<any> {
        const url = environment.apiProUrl + ENDPOINTS.CAMPAIGN_PAYMENT.GET_COUNT;
        return this.get(url);
    }
}
