import { ThaiMonthDatePipe } from 'src/app/pipes/thaidate.pipe';

export interface IProductDataFromSearchString {
    tour_code: string;
    pool_code: string;
    name: string;
    price: string;
    periods?: IPeriod[];
}

export interface IPeriod {
    id: number;
    date: string;
    price?: string;
}

export class ProductDataFromSearchString {
    private thaiMonthDatePipe: ThaiMonthDatePipe = new ThaiMonthDatePipe();

    public deserializeProductData(obj: any): any {
        const product: IProductDataFromSearchString = {
            tour_code: obj.tour_code,
            pool_code: obj.owner_channel + obj.category_sub_products_id + obj.id,
            name: obj.name,
            price: obj.price,
            periods: [],
        };

        if (obj.periods) {
            obj.periods.forEach((periodObj: any) => {
                const period: IPeriod = {
                    id: periodObj.id,
                    date: periodObj.start_at ? this.getDateRange(periodObj.start_at, periodObj.end_at) : null,
                    price: periodObj.price,
                };
                if (period.date) {
                    product.periods.push(period);
                }
            });
        }

        return product;
    }

    private getDateRange(startAt: string, endAt: string): string {
        let programPeriod = '';

        const startDate = new Date(startAt);
        const endDate = new Date(endAt);

        const currentDate = new Date();
        currentDate.setHours(0, 0, 0, 0);
        if (startDate >= currentDate) {
            programPeriod += startDate.getDate() + ' ';
            if (startDate.getMonth() !== endDate.getMonth()) {
                programPeriod += this.thaiMonthDatePipe.transform(startDate) + ' ';

                if (startDate.getFullYear() === endDate.getFullYear()) {
                    programPeriod = programPeriod.slice(0, -3);
                }
            }
            programPeriod += '- ' + endDate.getDate() + ' ' + this.thaiMonthDatePipe.transform(endDate);

            return programPeriod;
        } else {
            return null;
        }
    }
}
