import * as moment from 'moment';
import { CATEGORY_SUB_PRODUCTS } from 'src/app/constants/category-sub-product.constant';
import { IDeserializable } from 'src/app/models/interfaces/deserializable';

export class CountProductSubCategory implements IDeserializable {
    all: number;
    outbound: number;
    domestic: number;

    deserialize(source: any): this {
        this.all = source.all;
        this.outbound = source.outbound;
        this.domestic = source.domestic;

        return this;
    }
}

export class CountOrderStatus implements IDeserializable {
    waitingConfirm: number;
    waitingFullPaid: number;
    completeNotStarted: number;
    completeStarted: number;
    cancel: number;

    deserialize(source: any): this {
        this.waitingConfirm = source.waiting_confirm;
        this.waitingFullPaid = source.waiting_full_paid;
        this.completeNotStarted = source.complete_not_started;
        this.completeStarted = source.complete_started;
        this.cancel = source.cancel;

        return this;
    }
}

export class ProductOrderListPaginated implements IDeserializable {
    page: number;
    pageEntries: number;
    total: number;
    result: ProductOrderListItem[];

    deserialize(source: any): this {
        this.page = source.page;
        this.pageEntries = source.page_entries;
        this.total = source.total;
        this.result = source.result.map((r) => new ProductOrderListItem().deserialize(r));

        return this;
    }
}

export class ProductOrderListItem implements IDeserializable {
    id: number;
    orderCode: string;
    orderStatusSlug: 'waiting_full_paid' | 'complete_not_started' | 'complete_started';
    isOrderCompleted: boolean;
    sumAmount: number;
    sumCommission: number;
    sumCommissionSeller: number;
    sumCommissionCompany: number;
    quantity: number;
    fullyPaid: boolean;
    createdAt: Date;

    customerName: string;
    customerPhoneNumber: string;
    customerEmail: string;
    customerRemark: string;

    productSubCategoryId: number;
    productSubCategorySlug: string;
    productSubCategoryName: string;
    productSubCategoryNameTh: string;

    productName: string;
    productTourCode: string;
    productCommissionSeller: number;
    productCommissionCompany: number;

    productPeriodStartAt: Date;
    productPeriodEndAt: Date;

    productOwnerId: number;
    productOwnerChannel: 'ag' | 'ws';
    productOwnerNameEn: string;
    productOwnerNameTh: string;

    firstInstallment: ProductOrderInstallment;
    installments: ProductOrderInstallment[];
    totalInstallment: number;

    deserialize(source: any): this {
        this.id = source.id;
        this.orderCode = source.order_code;
        this.orderStatusSlug = source.order_status_slug;
        this.isOrderCompleted = this.orderStatusSlug === 'complete_started';
        this.sumAmount = source.sum_amount;
        this.sumCommission = source.sum_commission;
        this.sumCommissionSeller = source.sum_commission_seller;
        this.sumCommissionCompany = source.sum_commission_company;
        this.quantity = source.quantity;
        this.fullyPaid = source.fully_paid;
        this.createdAt = source.created_at ? moment(source.created_at).toDate() : null;

        if (source.customer) {
            this.customerName = source.customer.contact_name;
            this.customerPhoneNumber = source.customer.phone_number;
            this.customerEmail = source.customer.email;
            this.customerRemark = source.customer.customer_remark;
        }

        if (source.product_sub_category) {
            this.productSubCategoryId = source.product_sub_category.id;
            this.productSubCategorySlug = source.product_sub_category.slug;
            this.productSubCategoryName = source.product_sub_category.name;
            this.productSubCategoryNameTh = source.product_sub_category.name_th;
        }

        if (source.product) {
            this.productName = source.product.name;
            this.productTourCode = source.product.tour_code;
            this.productCommissionSeller = source.product.commission_seller;
            this.productCommissionCompany = source.product.commission_company;
        }

        if (source.product_period) {
            this.productPeriodStartAt = source.product_period.start_at
                ? moment(source.product_period.start_at).toDate()
                : null;
            this.productPeriodEndAt = source.product_period.end_at
                ? moment(source.product_period.end_at).toDate()
                : null;
        }

        if (source.product_owner) {
            this.productOwnerId = source.product_owner.id;
            this.productOwnerChannel = source.product_owner.channel;
            this.productOwnerNameEn = source.product_owner.name_en;
            this.productOwnerNameTh = source.product_owner.name_th;
        }

        this.installments = source.installments.map((x) => new ProductOrderInstallment().deserialize(x));
        this.totalInstallment = this.installments.length;
        if (this.totalInstallment) {
            this.firstInstallment = this.installments[0];
        }

        return this;
    }
}

export class ProductOrderInstallment implements IDeserializable {
    amount: number;
    paidAt: Date;

    deserialize(source: any): this {
        this.amount = source.amount;
        this.paidAt = source.paid_at ? moment(source.paid_at).toDate() : null;

        return this;
    }
}

export class ProductOrderListSearchCriteria {
    searchString: string;
    productSubCategorySlug: 'all' | 'outbound' | 'domestic';
    orderStatusSlug: 'waiting_confirm' | 'waiting_full_paid' | 'complete_started' | 'complete_not_started' | 'cancel';
    isLocalSearch: boolean;
}
