import { CategoryTransport } from '../category-transport.model';
import { IWholesaleProductPeriod } from '../interfaces/tourwow-order/wholesale-product-period.interface';
import { RoomType } from '../room-type.model';
import { Transportation } from '../transportation.model';

export class WholesaleProductPeriod {
    id: number;
    startAt: Date;
    endAt: Date;

    roomTypes: RoomType[];

    commissionCompany: number;
    commissionSeller: number;
    deposit: number;
    depositIsActive: boolean;

    quantityRemaining: number;
    sellStatusCode: number;
    sellStatusReason: string;
    remark: string;

    transportaionCategory: CategoryTransport;
    goTransportation: Transportation;
    backTransportation: Transportation;

    deserialize(data: IWholesaleProductPeriod): WholesaleProductPeriod | null {
        if (!data) {
            return null;
        }

        this.id = data.id;
        this.startAt = new Date(data.start_at);
        this.endAt = new Date(data.end_at);

        this.roomTypes = data.room_types.map((room) => new RoomType().deserialize(room));

        this.commissionCompany = data.commission_company;
        this.commissionSeller = data.commission_seller;
        this.deposit = data.deposit;

        this.depositIsActive = data.deposit_is_active;
        this.quantityRemaining = data.quantity_remaining;
        this.sellStatusCode = data.sell_status_code;
        this.sellStatusReason = data.sell_status_reason;
        this.remark = data.remark;
        this.transportaionCategory = data.transportaion_category
            ? new CategoryTransport().deserialize(data.transportaion_category)
            : null;
        this.goTransportation = data.go_transportation
            ? new Transportation().deserialize(data.go_transportation)
            : null;
        this.backTransportation = data.back_transportation
            ? new Transportation().deserialize(data.back_transportation)
            : null;

        return this;
    }
}
