export enum LEAD_STATUS_SLUG {
    waiting_contact = 'รอติดต่อกลับ',
    waiting_dicision = 'รอตัดสินใจ',
    booked = 'จองแล้ว',
    cancel = 'ยกเลิก',
}

export enum BOOKING_REQUEST_LEAD_STATUS_SLUG {
    pending = 'รอดำเนินการ',
    confirm = 'รับจองแล้ว',
    cancel = 'ยกเลิกใบจอง',
}
