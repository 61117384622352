import { Component, Input } from '@angular/core';
import { ProductOrderListPaginated } from '../../models/product-order-list.model';

@Component({
    selector: 'app-program-order-list-result',
    templateUrl: './program-order-list-result.component.html',
    styleUrls: ['./program-order-list-result.component.scss'],
})
export class ProgramOrderListResultComponent {
    @Input() productOrders: ProductOrderListPaginated;

    constructor() {}
}
