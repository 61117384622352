import {
    AfterViewInit,
    Component,
    ElementRef,
    EventEmitter,
    Input,
    OnChanges,
    OnDestroy,
    OnInit,
    Output,
    SimpleChanges,
    ViewChild,
} from '@angular/core';
import { combineLatest, fromEvent, Observable, Subject } from 'rxjs';
import { filter, map, take, takeUntil } from 'rxjs/operators';

import { select, Store } from '@ngrx/store';
import { selectAgencyInfo } from 'src/app/stores/user-store/user.selectors';

import {
    SearchableDropdownComponent,
    SearchableDropdownItem,
    SearchableDropdownSettings,
} from 'src/app/modules/shared/components/searchable-dropdown/searchable-dropdown.component';
import { ProgramPeriodNotFoundModalComponent } from '../../modals/program-period-not-found-modal/program-period-not-found-modal.component';

import { ThaiDateRangePipe } from 'src/app/pipes/thaidate.pipe';

// Services
import { CountryService } from 'src/app/services/country.service';
import { WholesaleProductService } from 'src/app/services/wholesale-product.service';

import { Province } from 'src/app/models/province';
import { WholesaleProductPeriod } from 'src/app/models/wholesale-products/wholesale-product-period.model';
import { WholesaleProduct } from 'src/app/models/wholesale-products/wholesale-product.model';
import { IResponseGetOrder } from 'src/app/models/interfaces/tourwow-order/response-get-order.interface';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { Agency } from 'src/app/models/agency';

enum MODE {
    ADD = 'add',
    EDIT = 'edit',
    VIEW = 'view',
}

@Component({
    selector: 'app-program-tours-filter-select',
    templateUrl: './program-tours-filter-select.component.html',
    styleUrls: ['./program-tours-filter-select.component.scss'],
})
export class ProgramToursFilterSelectComponent implements OnInit, OnChanges, OnDestroy, AfterViewInit {
    @ViewChild('periodDropdown') periodDropdown!: ElementRef;
    @ViewChild('countryDropdown') countryDropdown!: SearchableDropdownComponent;
    @ViewChild('programDropdown') programDropdown!: SearchableDropdownComponent;

    @Input() submitButton: boolean;
    @Input() order: IResponseGetOrder;
    @Input() mode: MODE = MODE.ADD;

    @Output() programSelected = new EventEmitter<WholesaleProduct>();
    @Output() periodSelected = new EventEmitter<WholesaleProductPeriod | null>();
    @Output() changeMode = new EventEmitter<MODE>();
    @Output() formValid = new EventEmitter<boolean>();

    MODE = MODE;

    countryDropdownSettings: SearchableDropdownSettings = new SearchableDropdownSettings();
    countryDropdownItems: SearchableDropdownItem[] = [];
    countryDropdownRequired = false;
    countrySelectedId = -1;

    programDropdownSettings: SearchableDropdownSettings = new SearchableDropdownSettings();
    programDropdownItems: SearchableDropdownItem[] = [];
    programDropdownRequired = false;
    programSelectedId = -1;

    programList: WholesaleProduct[] = [];
    periodList: WholesaleProductPeriod[] = [];

    program: WholesaleProduct = null; // Selected program from dropdownlist filter
    period: WholesaleProductPeriod = null; // Selected period from dropdownlist filter

    hidePeriodDropdownlist = true;

    private agency: Agency = null;
    private bsModalAlerteProgramPeriodNotFound: BsModalRef;
    private unsubscribe$: Subject<void> = new Subject<void>();

    constructor(
        private store: Store,
        private countryService: CountryService,
        private wsProductService: WholesaleProductService,
        private thaidateRangePipe: ThaiDateRangePipe,
        private modalService: BsModalService
    ) {}

    ngOnInit(): void {
        this.initialCountryDropdownItems();
        this.initialProgramDropdownItems();
        this.subscribeAgencyInfo();
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.order && changes.order.currentValue) {
            this.fillOrderData(changes.order.currentValue);
        }
    }

    ngOnDestroy(): void {
        //Called once, before the instance is destroyed.
        //Add 'implements OnDestroy' to the class.
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
    }

    ngAfterViewInit(): void {
        // Catch event document click outer period dropdownlist for close list.
        fromEvent(document, 'click').subscribe((e) => {
            if (
                this.periodDropdown &&
                !this.periodDropdown.nativeElement.contains(e.target) &&
                !this.hidePeriodDropdownlist
            ) {
                this.hidePeriodDropdownlist = true;
            }
        });
    }

    getPeriodTime(startDate: Date, endDate: Date): string {
        return this.thaidateRangePipe.transform(startDate, endDate);
    }

    private fillOrderData(order: IResponseGetOrder, skipCountrySelected = false): void {
        this.programDropdownItems = [
            new SearchableDropdownItem({
                id: order.product.id,
                name: order.product.name,
                value: order.product.id,
                isChecked: false,
            }),
        ];

        if (!skipCountrySelected) {
            this.countryDropdown.selectItem(
                this.countryDropdownItems.filter((c) => c.id === order.product.countries[0].id)[0]
            );
        }

        this.programDropdown.selectItem(this.programDropdownItems.filter((p) => p.id === order.product.id)[0]);

        this.program = new WholesaleProduct().deserialize(order.product);
        this.programSelected.emit(this.program);
        const periodItem = new WholesaleProductPeriod().deserialize(order.product_period);
        this.periodList = [periodItem];
        this.onPeriodSelected(periodItem);
    }

    private initialCountryDropdownItems(): void {
        // Initial settings
        this.countryDropdownSettings.closeAfterSelect = true;
        this.countryDropdownSettings.multipleSelection = false;
        this.countryDropdownSettings.dropdownListPlaceholder = 'เลือกประเทศ';
        this.countryDropdownSettings.searchBoxPlaceholder = 'กรอกชื่อประเทศ';

        this.countryDropdownSettings.styleSetting.fontSize = '14px';

        // Initial data
        this.getCountries()
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe((countries) => {
                countries.sort((a, b) => a.nameTh.localeCompare(b.nameTh));
                this.countryDropdownItems = countries.map((country) => {
                    return new SearchableDropdownItem({
                        id: country.id,
                        name: `${country.nameTh} (${country.nameEn})`,
                        value: country.id,
                        imageUrl: country.iconUrl,
                        isChecked:
                            this.countrySelectedId !== -1 && country.id === this.countrySelectedId ? true : false,
                    });
                });
            });
    }

    private getCountries(): Observable<Province[]> {
        return this.countryService.getAllCountries().pipe(
            map((response) => {
                if (response.status === 'success') {
                    return response.data.map((province) => new Province().deserialize(province));
                }

                return [];
            })
        );
    }

    private initialProgramDropdownItems(): void {
        // Initial settings
        this.programDropdownSettings.closeAfterSelect = true;
        this.programDropdownSettings.multipleSelection = false;
        this.programDropdownSettings.dropdownListPlaceholder = 'เลือกโปรแกรมทัวร์';
        this.programDropdownSettings.searchBoxPlaceholder = 'กรอกชื่อโปรแกรมทัวร์';

        this.programDropdownSettings.styleSetting.fontSize = '14px';
    }

    private resetProgramDropdownItems(wsProducts: WholesaleProduct[], keepPeriodState: boolean = false): void {
        this.program = null;
        if (!keepPeriodState) {
            this.period = null;
        }

        this.programDropdownItems = [];
        this.programDropdownItems = wsProducts.map((product) => {
            return new SearchableDropdownItem({
                id: product.id,
                name: `${this.agency.productCode}-${product.tourCode} ${product.name}`,
                value: product.id,
                isChecked: this.programSelectedId !== -1 && product.id === this.programSelectedId ? true : false,
            });
        });

        if (this.programSelectedId !== -1) {
            this.onProgramSelected([this.programSelectedId], keepPeriodState);
        }

        if (!keepPeriodState) {
            this.periodSelected.emit(null);
        }
    }

    private checkProgramAvailable(): void {
        const getProduct$ = this.wsProductService.getProducts(this.countrySelectedId);
        const getProductPeriod$ = this.wsProductService.getProductPeriods(this.programSelectedId);
        combineLatest(getProduct$, getProductPeriod$, (programs, periods) => {
            // Program was deleted.
            if (programs.filter((p) => p.id === this.programSelectedId).length === 0) {
                this.popupProgramPeriodNotFoundModal();
                this.programList = programs;
                this.programSelectedId = -1;
                this.resetProgramDropdownItems(programs);
                return;
            }

            // Period was deleted
            if (periods.filter((p) => p.id === this.order.product_period.id).length === 0) {
                this.periodList = periods;
                this.popupProgramPeriodNotFoundModal();
                return;
            }

            this.mode = MODE.EDIT;
            this.wsProductService.getProducts(this.countrySelectedId).subscribe((products) => {
                this.programList = products;
                this.resetProgramDropdownItems(products, true);
            });
            this.changeMode.emit(MODE.EDIT);
        }).subscribe();
    }

    private popupProgramPeriodNotFoundModal(): void {
        this.bsModalAlerteProgramPeriodNotFound = this.modalService.show(ProgramPeriodNotFoundModalComponent, {
            class: 'program-period-not-found-modal modal-lg',
            ignoreBackdropClick: true,
        });

        this.bsModalAlerteProgramPeriodNotFound.onHide.subscribe(() => {
            // On Hide Modal ProgramPeriodNotFoundModalComponent
            // Reset Form
            this.mode = MODE.EDIT;
            this.changeMode.emit(MODE.EDIT);
            this.period = null;
            this.periodSelected.emit(this.period);
        });
    }

    private subscribeAgencyInfo(): void {
        this.store
            .pipe(
                select(selectAgencyInfo),
                filter((agency) => !!agency),
                take(1)
            )
            .subscribe((agency) => {
                this.agency = agency;
            });
    }

    // Events Listener
    onCountrySelected(countryIds: number[]): void {
        this.countryDropdownRequired = false;

        const countryId = countryIds[0];
        this.countrySelectedId = countryId;
        this.programSelectedId = -1;

        if (this.mode !== MODE.VIEW) {
            this.wsProductService.getProducts(countryId).subscribe((products) => {
                this.programList = products;
                this.resetProgramDropdownItems(products);
            });
        }
    }

    onProgramSelected(programIds: number[], keepPeriodState: boolean = false): void {
        if (!keepPeriodState) {
            this.period = null;
            this.periodSelected.emit(this.period);
        }
        this.programDropdownRequired = false;

        const programId = programIds[0];
        this.programSelectedId = programId;
        this.program = this.programList.filter((p) => p.id === programId)[0];
        this.programSelected.emit(this.program);

        this.wsProductService.getProductPeriods(programId).subscribe((periods) => {
            this.periodList = periods;
        });

        this.submitForm();
    }

    onPeriodSelected(periodItem: WholesaleProductPeriod): void {
        this.period = periodItem;
        this.periodSelected.emit(this.period);
    }

    togglePeriodDropdownlist(): void {
        if (this.mode === MODE.VIEW) {
            return;
        }

        this.hidePeriodDropdownlist = !this.hidePeriodDropdownlist;
    }

    toggleEditMode(): void {
        if (this.mode === MODE.VIEW) {
            this.checkProgramAvailable();
        } else if (this.mode === MODE.EDIT) {
            this.mode = MODE.VIEW;
            this.changeMode.emit(MODE.VIEW);
            this.fillOrderData(this.order, true);
        }
    }

    submitForm(): void {
        // Checking Required Field Dropdownlist Filter Country and Program
        if (this.countrySelectedId === -1) {
            this.countryDropdownRequired = true;
            this.formValid.emit(false);
        }

        if (this.programSelectedId === -1) {
            this.programDropdownRequired = true;
            this.formValid.emit(false);
        }

        if (this.countrySelectedId > 0 && this.programSelectedId > 0) {
            this.formValid.emit(true);
        }
    }
}
