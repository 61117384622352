import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { reducer } from './setting.reducer';
import { SettingEffects } from './setting.effects';

export const FEATURE_NAME = 'setting';

@NgModule({
    imports: [CommonModule, StoreModule.forFeature(FEATURE_NAME, reducer), EffectsModule.forFeature([SettingEffects])],
    providers: [SettingEffects],
})
export class SettingStoreModule {}
