import * as moment from 'moment';

export class LeadStatMonthly {
    total: number;
    period: LeadStatPeriodMonthly[];

    public deserialize(input: any): any {
        this.total = input.total;
        this.period = input.periods.map((period) => new LeadStatPeriodMonthly().deserialize(period));
        return this;
    }
}

export class LeadStatPeriodMonthly {
    count: number;
    hasReport: boolean;
    yearMonth: Date;

    public deserialize(input: any): any {
        this.count = input.count;
        this.hasReport = this.count > 0;
        if (input.period) {
            this.yearMonth = moment(`${input.period?.year}-${input.period?.month}-01`).toDate();
        }

        return this;
    }
}
