import { Component } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { Customer } from 'src/app/modules/order/models/tw-booking-model/customer.model';

@Component({
    selector: 'app-customer-info-modal',
    templateUrl: './customer-info-modal.component.html',
    styleUrls: ['./customer-info-modal.component.scss'],
})
export class CustomerInfoModalComponent {
    usingLatestProductOwner: boolean = false;
    ownerChannel: 'ag' | 'ws' = null;
    ownerId: number = null;

    customerInfo: Customer;

    constructor(public bsModalRef: BsModalRef, public bsModalService: BsModalService) {}
}
