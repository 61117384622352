import { HttpService } from './base/http.service';

import { ENDPOINTS } from '../constants/endpoint.constant';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable()
export class CaterogyTransportService {
    constructor(private httpService: HttpService) {}

    getAllCategoryTransports(filters): Observable<any> {
        const filtersString = JSON.stringify(filters);
        const url = environment.apiProUrl + ENDPOINTS.CATEGORY_TRANSPORT.GET_ALL + '?filters=' + filtersString;
        return this.httpService.get(url, null);
    }
}
