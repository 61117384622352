export class ProductOwnerListOptional {
    public id: number;
    public nameTh: string;
    public nameEn: string;
    public channel: 'ag' | 'ws';

    public deserialize(obj: any): ProductOwnerListOptional {
        this.id = obj.id;
        this.nameTh = obj.name_th;
        this.nameEn = obj.name_en;
        this.channel = obj.channel;
        return this;
    }
}
