<div class="wrapper">
    <nav id="sidebar" class="sidebar" [ngClass]="{ active: isCollapseSidebar }">
        <div class="sidebar-header">
            <div>
                <a [routerLink]="['/dashboard']">
                    <img
                        src="assets/img/logo-tourwowMall-w.png"
                        id="logo-twm"
                        alt="Tourwow Pro Backoffice Agency: {{ appVersion }}"
                /></a>
            </div>
        </div>
        <div class="avt text-center">
            <figure class="img-circle"><img src="assets/img/avatars/photo-logo.png" /></figure>
            <div *ngIf="user$ | async as user">
                <p>{{ user.firstName }} {{ user.lastName }}</p>
                <p *ngIf="user.nickName">({{ user.nickName }})</p>
            </div>
        </div>

        <ul class="nav flex-column">
            <ng-container *ngFor="let menu of menus; let i = index">
                <li *ngIf="!menu.subMenu && !menu.isHidden" class="nav-item">
                    <a
                        [routerLink]="menu.link"
                        class="nav-link"
                        [class.active]="menu.link === router.url"
                        [class.lower-name]="menu.slug === 'booking-request-summary'"
                    >
                        <i *ngIf="menu.iconClass" class="icon {{ menu.iconClass }}" aria-hidden="true"></i>
                        <img
                            *ngIf="menu.svgIconFilePath"
                            [src]="menu.svgIconFilePath"
                            width="26"
                            style="margin-right: 10px"
                        />
                        <span class="pre-wrap">{{ menu.name }}</span>
                        <span *ngIf="menu.leadCountNotification === 'lead-count' && leadCount" class="notification">{{
                            leadCount
                        }}</span>
                        <span
                            *ngIf="menu.leadCountNotification === 'lead-booking-request' && leadBookingRequestCount"
                            class="notification"
                            >{{ leadBookingRequestCount }}</span
                        >
                    </a>
                </li>
                <li *ngIf="menu.subMenu && !menu.isHidden" class="nav-item">
                    <a
                        (click)="menu.collapsedSubMenu = !menu.collapsedSubMenu"
                        class="nav-link"
                        data-toggle="collapse"
                        role="button"
                    >
                        <i class="icon {{ menu.iconClass }}" aria-hidden="true"></i>{{ menu.name }}
                    </a>
                    <ul class="flex-column collapse" [collapse]="menu.collapsedSubMenu" [isAnimated]="true">
                        <ng-container *ngFor="let sub of menu.subMenu">
                            <li *ngIf="!sub.isHidden" class="nav-item">
                                <a [class.active]="sub.link === router.url" [routerLink]="sub.link" class="nav-link">{{
                                    sub.name
                                }}</a>
                            </li>
                        </ng-container>
                    </ul>
                </li>
            </ng-container>

            <li class="nav-item">
                <a (click)="logout()" class="nav-link"><i class="icon icon-logout" aria-hidden="true"></i>ออกจากระบบ</a>
            </li>
        </ul>
    </nav>

    <div id="content" [ngClass]="{ active: isCollapseSidebar }">
        <nav class="navbar">
            <div class="container-fluid">
                <button
                    (click)="isCollapseSidebar = !isCollapseSidebar"
                    type="button"
                    id="sidebarCollapse"
                    class="btn text-white"
                >
                    <i class="icon icon-bars"></i>
                </button>
                <span class="navbar-text mr-auto text-white">
                    <div *ngIf="user$ | async as user">
                        <span>{{ user.firstName }} {{ user.lastName }}</span>
                        <span *ngIf="user.nickName">({{ user.nickName }})</span>
                    </div>
                </span>
            </div>
        </nav>
        <main class="content">
            <div class="container-fluid p-0">
                <router-outlet></router-outlet>
                <!-- <h2 class="text-primary mb-4"><i class="icon icon-dashboard"></i>Dashboard</h2> -->
            </div>
        </main>
    </div>
</div>
