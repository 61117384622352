<div *ngIf="agencyInfo.displayCommission" class="commission-report-filter">
  <div class="commission-report-list">
    <div class="commission-report-item" [ngClass]="{'active': orderSummary.active}"
      *ngFor="let orderSummary of orderSummaries" (click)="onSelectCommission(orderSummary)">
      <div class="booking">
        <div class="date">{{orderSummary.dateRange.minDate | thaiMonth}}</div>
        <div class="amount">{{orderSummary.orderCount}} <span>Booking</span> </div>
      </div>
      <div class="commission">
        <span>คอมรวม</span> {{orderSummary.sumCommission | number}}
      </div>
    </div>
  </div>
</div>
