import { Component, Inject, Input, OnDestroy, PLATFORM_ID } from '@angular/core';
import { ACTION_TYPE, ENTITY } from 'src/app/constants/event-log.constant';
import { eventLog } from 'src/app/stores/utility-store/utility.actions';

import { Store } from '@ngrx/store';
import { Subject } from 'rxjs';
import { filter, take, takeUntil } from 'rxjs/operators';
import { IOrderBalanceReportOverViewItem } from 'src/app/models/order-balance-report.model';
import { State } from 'src/app/stores/reducers';
import { getBalanceReceiptUri, getBalanceReceiptUriSuccessful } from 'src/app/stores/report-store/report.actions';
import { Actions, ofType } from '@ngrx/effects';
import { isPlatformBrowser } from '@angular/common';

@Component({
    selector: 'app-order-balance-report-receipt',
    templateUrl: './order-balance-report-receipt.component.html',
    styleUrls: ['./order-balance-report-receipt.component.scss'],
})
export class OrderBalanceReportReceiptComponent implements OnDestroy {
    @Input() orderBalanceId: number = null;
    @Input() reportView: IOrderBalanceReportOverViewItem;

    isExpand = false;

    private unsubscribe$: Subject<void> = new Subject<void>();

    constructor(
        @Inject(PLATFORM_ID) protected platformId: Object,
        private store: Store<State>,
        private actions$: Actions
    ) {}

    ngOnDestroy(): void {
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
    }

    toggleExpandRows(): void {
        this.isExpand = !this.isExpand;
    }

    showAllReports(index: number): boolean {
        if (this.isExpand) {
            return true;
        }
        return index < 5;
    }

    private eventLog(): void {
        // Event Log
        this.store.dispatch(
            eventLog({
                entityName: ENTITY.AGENCY_ORDER_BALANCE_RECEIPT,
                actionType: ACTION_TYPE.DOWNLOAD_RECEIPT_DOCUMENT,
                content: {
                    agcy_order_balance_id: this.orderBalanceId,
                    agcy_order_balance_receipt_id: this.reportView.id,
                },
            })
        );
    }

    openFileUrl(fileUrl: string): void {
        if (fileUrl && isPlatformBrowser(this.platformId)) {
            window.open(fileUrl);
        }
    }

    downloadReceiptFile(balanceId: number, receiptId: number): void {
        this.store.dispatch(getBalanceReceiptUri({ balanceId: balanceId, receiptId: receiptId }));
        this.loadReceipt();
        this.eventLog();
    }

    private loadReceipt(): void {
        this.actions$
            .pipe(
                ofType(getBalanceReceiptUriSuccessful),
                filter((action) => action.balanceId === this.orderBalanceId && action.receiptId === this.reportView.id),
                take(1),
                takeUntil(this.unsubscribe$)
            )
            .subscribe((action) => {
                this.openFileUrl(action.receiptUri);
            });
    }
}
