import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'productdiscount',
})
export class ProductDiscountPipe implements PipeTransform {
    transform(normalPrice: string | number, discountedPrice: string | number): any {
        const diffPrice = +normalPrice - +discountedPrice;
        const discountPercentage = (diffPrice / +normalPrice) * 100;
        return Math.round(discountPercentage);
    }
}
