import * as dayjs from 'dayjs';

export class DateHelper {
    public static getYMDString(date: Date): string {
        if (date) {
            const dateWithCorrectTimeZone = new Date(date.getTime() - date.getTimezoneOffset() * 60000);
            return dateWithCorrectTimeZone.toISOString().substring(0, 10);
        }
        return null;
    }

    public static getCurrentDateAtMidnight(useThaiTimeZone: boolean = true): Date {
        const date = new Date();
        date.setHours(0, 0, 0, 0);
        if (useThaiTimeZone) {
            date.setHours(7);
        }
        return date;
    }

    /**
     * return null if dateString === null
     * a way around the fact that new Date(null) gives 1970-01-01T00:00:00.000Z
     */
    public static convertStringToDate(dateString: string): Date {
        return dateString ? new Date(dateString) : null;
    }

    public static convertDateStringFormat(
        dateString: string,
        options: any = { weekday: 'long', month: 'long', day: 'numeric' }
    ): string {
        const date = this.convertStringToDate(dateString);

        return new Intl.DateTimeFormat('th-TH', options).format(date);
    }

    public static convertDateToHttpPlayload(date: Date | string | null, isDateTime: boolean = false): string | null {
        if (date) {
            if (isDateTime) {
                return dayjs(date).format('YYYY-MM-DD HH:mm:ss');
            }
            return dayjs(date).format('YYYY-MM-DD');
        } else {
            return null;
        }
    }

    public static convertDateToHttpPlayloadTime(date: Date | string | null): string | null {
        if (date) {
            return dayjs(date).format('HH:mm:ss');
        } else {
            return null;
        }
    }
}
