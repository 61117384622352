import { SearchResultLabelHelper } from './../../helpers/search-result-label.helper';
import { ThaiDateRangePipe, ThaiDatePipe } from './../../../../pipes/thaidate.pipe';
import { BOOKING_LIST_SORT_BY, IBookingList } from '../../models/booking-list.model';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { filter, takeUntil } from 'rxjs/operators';
import { State } from 'src/app/stores/reducers';
import { Subject } from 'rxjs';
import * as OrderActions from 'src/app/stores/order-store/order.actions';
import * as SettingActions from 'src/app/stores/setting-store/setting.actions';
import { selectBookingList, selectBookingListQueries } from 'src/app/stores/order-store/order.selectors';
import { IBookingListQueries } from '../../models/booking-list.model';
import {
    SearchableDropdownSettings,
    SearchableDropdownItem,
} from 'src/app/modules/shared/components/searchable-dropdown/searchable-dropdown.component';
import { SuccessModalComponent } from 'src/app/modules/shared/modals/success-modal/success-modal.component';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import * as _ from 'lodash-es';
import { selectAgencyInfo } from 'src/app/stores/user-store/user.selectors';
import { Agency } from 'src/app/models/agency';

@Component({
    selector: 'app-booking-list-listing',
    templateUrl: './booking-list-listing.component.html',
    styleUrls: ['./booking-list-listing.component.scss'],
})
export class BookingListListingComponent implements OnInit, OnDestroy {
    private searchFilter: IBookingListQueries;
    private agency: Agency;
    bookingList: IBookingList;

    sortingDropdownSettings: SearchableDropdownSettings = new SearchableDropdownSettings();
    sortingDropdownItems: SearchableDropdownItem[];
    bsModalRef: BsModalRef;

    private unsubscribe$: Subject<void> = new Subject<void>();

    constructor(
        private store: Store<State>,
        public bsModalService: BsModalService,
        private thaiDateRangePipe: ThaiDateRangePipe,
        private thaiDatePipe: ThaiDatePipe
    ) {}

    ngOnInit(): void {
        this.initialiseSortingDropdown();
        this.subscriptions();
        this.subscribeToAddOrEditedBooking();
        this.loadInvoiceConfigs();
    }

    ngOnDestroy(): void {
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
    }

    private initialiseSortingDropdown(): void {
        this.sortingDropdownItems = [
            {
                id: BOOKING_LIST_SORT_BY.CREATED_BY_DESC,
                name: 'เรียงตามวันที่สร้าง Booking ล่าสุด',
                isChecked: true,
            },
            {
                id: BOOKING_LIST_SORT_BY.FIRST_PAYMENT_DUE_ASC,
                name: 'เรียงตามวันที่กำหนดชำระเงินงวดแรก',
            },
            {
                id: BOOKING_LIST_SORT_BY.SUM_TOTAL_ASC,
                name: 'เรียงตามยอดจอง น้อย -> มาก',
            },
            {
                id: BOOKING_LIST_SORT_BY.SUM_TOTAL_DESC,
                name: 'เรียงตามยอดจอง มาก -> น้อย',
            },
        ];
        this.sortingDropdownSettings.hideSearchBox = true;
        this.sortingDropdownSettings.multipleSelection = false;
        this.sortingDropdownSettings.closeAfterSelect = true;
        this.sortingDropdownSettings.unableToUncheck = true;
        this.sortingDropdownSettings.styleSetting.fontSize = '14px';
        this.sortingDropdownSettings.styleSetting.height = '30px';
    }

    private subscriptions(): void {
        this.store
            .pipe(
                select(selectAgencyInfo),
                filter((agency) => !!agency),
                takeUntil(this.unsubscribe$)
            )
            .subscribe((agency) => (this.agency = agency));

        this.store
            .pipe(
                select(selectBookingListQueries),
                filter((resp) => resp !== null),
                takeUntil(this.unsubscribe$)
            )
            .subscribe((queries: IBookingListQueries) => {
                this.searchFilter = _.cloneDeep(queries);
            });

        this.store
            .pipe(
                select(selectBookingListQueries),
                filter((resp) => resp !== null),
                takeUntil(this.unsubscribe$)
            )
            .subscribe((queries: IBookingListQueries) => {
                this.searchFilter = _.cloneDeep(queries);
            });

        this.store
            .pipe(
                select(selectBookingList),
                filter((resp) => resp !== null),
                takeUntil(this.unsubscribe$)
            )
            .subscribe((bookingList: IBookingList) => {
                const bookingListCopy: IBookingList = _.cloneDeep(bookingList);
                if (bookingListCopy.page > 1) {
                    this.bookingList.page = bookingListCopy.page;
                    this.bookingList.pageEntries = bookingListCopy.pageEntries;
                    this.bookingList.total = bookingListCopy.total;
                    this.bookingList.bookings.push(...bookingListCopy.bookings);
                } else {
                    this.bookingList = bookingListCopy;
                }
            });
    }

    private subscribeToAddOrEditedBooking(): void {
        this.bsModalService.onHide
            .pipe(
                filter((reason) => reason === 'submit-booking-b2c'),
                takeUntil(this.unsubscribe$)
            )
            .subscribe(() => {
                this.searchFilter.filters.clearCache = new Date().toISOString();
                this.searchFilter.criteria.page = 1;

                this.dispatchBookingList();
                const initialState = {
                    message: 'สร้าง Booking เรียบร้อยแล้ว',
                };
                this.bsModalRef = this.bsModalService.show(SuccessModalComponent, {
                    initialState,
                    class: 'success-modal',
                });
            });
    }

    private loadInvoiceConfigs(): void {
        this.store.dispatch(SettingActions.loadInvoiceConfigs());
    }

    onSort(sortBy: BOOKING_LIST_SORT_BY): void {
        if (this.searchFilter === undefined) {
            return;
        }

        this.searchFilter.sortBy = sortBy;

        this.dispatchBookingList();
    }

    private dispatchBookingList(): void {
        this.store.dispatch(
            OrderActions.getBookingListPaginated({
                queries: _.cloneDeep(this.searchFilter),
            })
        );
    }

    getDynamicTotalString(): string {
        return SearchResultLabelHelper.getDynamicTotalString(
            this.searchFilter,
            this.thaiDatePipe,
            this.thaiDateRangePipe,
            this.bookingList?.bookings[0]?.productOwner,
            this.bookingList?.bookings[0]?.sellerAgencyMember,
            this.agency?.productCode
        );
    }

    nextPage(): void {
        this.searchFilter.criteria.page++;
        this.dispatchBookingList();
    }

    showNextResultsValue(): number {
        const nextSearchBracket: number = (this.bookingList.page + 1) * this.bookingList.pageEntries;
        if (nextSearchBracket < this.bookingList.total) {
            return nextSearchBracket;
        }
        return this.bookingList.total;
    }
}
