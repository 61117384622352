<div class="lead-listing head-group">
    <div class="row">
        <div class="col-6">
            <strong class="title mb-0"
                >ใบจองจากลูกค้า{{ getDynamicTotalString() }}ทั้งหมด {{ listTotal }} รายการ</strong
            >
        </div>
        <div class="col-6 text-right">
            <a (click)="openAddBookingModal()" class="btn btn-add">
                <i class="icon icon-plus-circle mr-1"></i> เพิ่มข้อมูลใบจองจากลูกค้า
            </a>
        </div>
    </div>

    <div class="booking-table">
        <div class="row table-header">
            <div class="col-2">
                <p>Booking</p>
            </div>
            <div class="col-1 time-col">
                <p>วันที่ - เวลา</p>
            </div>
            <div class="col-3 col-md-5">
                <p>ชื่อโปรแกรม</p>
            </div>
            <div class="col-2 status-dd-col">
                <p>สถานะ</p>
            </div>
            <div class="col-1 pl-0"></div>
        </div>
        <div *ngFor="let lead of leadList; let i = index">
            <app-lead-details [lead]="lead" [ordinal]="i + 1"></app-lead-details>
        </div>
    </div>

    <div class="text-center show-more-results" *ngIf="pageDetails.currentPage < pageDetails.maxPage">
        <button class="btn btn-show-more" (click)="nextPage()">
            แสดงรายการที่ {{ pageDetails.currentPage * pageDetails.pageEntries + 1 }} -
            {{ showNextResultsValue() }}
        </button>
    </div>
</div>
