import * as moment from 'moment';

export class ChaophrayaCruiseBookingView {
    bookingId: number;
    usageDate: Date;
    remark: string;
    tickets: ChaophrayaCruiseBookingTicketView[];
    confirmedBy: string;
    confirmedAt: Date;

    public deserialize(obj: any): this {
        this.bookingId = obj.id;
        this.usageDate = obj.usage_date ? moment(obj.usage_date).toDate() : null;
        this.remark = obj.remark;
        this.tickets = obj.tickets.map((x) => new ChaophrayaCruiseBookingTicketView().deserialize(x));
        this.confirmedBy = obj.confirmed_by_partner_member
            ? obj.confirmed_by_partner_member.first_name +
              (obj.confirmed_by_partner_member.last_name ? ' ' + obj.confirmed_by_partner_member.last_name : '')
            : null;
        this.confirmedAt = obj.confirmed_at ? moment(obj.confirmed_at).toDate() : null;

        return this;
    }
}

export class ChaophrayaCruiseBookingTicketView {
    ticketCode: string;

    public deserialize(obj: any): this {
        this.ticketCode = obj.ticket_code;

        return this;
    }
}

export interface IChaophrayaCruiseBooking {
    paymentId: number;
    usageDate: Date;
    remark: string;
    tickets: string[];
}
