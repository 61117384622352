import { Component, OnInit } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { State } from 'src/app/stores/reducers';
import * as OrderActions from 'src/app/stores/order-store/order.actions';
import { selectSaleReportSummary } from 'src/app/stores/order-store/order.selectors';
import { filter, takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { ISaleReportListPaginatedQueries, SaleReportSummary } from '../../models/sale-report.model';
import { BOOKING_LIST_SORT_BY } from '../../models/booking-list.model';

@Component({
    selector: 'app-sale-report',
    templateUrl: './sale-report.component.html',
    styleUrls: ['./sale-report.component.scss'],
})
export class SaleReportComponent implements OnInit {
    saleReports: SaleReportSummary[];
    saleReportSelected: SaleReportSummary;

    saleReportListQuery: ISaleReportListPaginatedQueries;
    currentPage: number;

    private unsubscribe$: Subject<void> = new Subject<void>();

    constructor(private store: Store<State>) {}

    ngOnInit(): void {
        this.store.dispatch(
            OrderActions.getSaleReportSummary({
                query: {
                    intervalDirection: 'backward',
                    intervalLimit: 6,
                    intervalType: 'month',
                },
            })
        );

        this.store
            .pipe(
                select(selectSaleReportSummary),
                filter((resp) => resp !== null),
                takeUntil(this.unsubscribe$)
            )
            .subscribe((response) => {
                this.saleReports = response;
                if (this.saleReports.length) {
                    this.saleReportSelected = this.saleReports[0];
                    this.currentPage = 1;
                    this.mapSaleReportListQuery();
                    this.disPatchSaleReportList();
                }
            });
    }

    doFilter(report: SaleReportSummary): void {
        this.saleReportSelected = report;
        this.currentPage = 1;
        this.mapSaleReportListQuery();
        this.disPatchSaleReportList();
    }

    mapSaleReportListQuery(): void {
        this.saleReportListQuery = {
            criteria: {
                page: this.currentPage,
                pageEntries: 50,
            },
            filters: {
                created_at_between: {
                    min_date: this.saleReportSelected?.minDate,
                    max_date: this.saleReportSelected?.maxDate,
                },
                start_at_between: {
                    min_date: null,
                    max_date: null,
                },
                order_statuses: [
                    { status_code: 11, already_started: null },
                    { status_code: 21, already_started: null },
                    { status_code: 22, already_started: null },
                    { status_code: 31, already_started: false },
                    { status_code: 31, already_started: true },
                ],
            },
            sortBy: BOOKING_LIST_SORT_BY.CREATED_BY_DESC,
        };
    }

    disPatchSaleReportList(): void {
        this.store.dispatch(
            OrderActions.getSaleReportListPaginated({
                query: this.saleReportListQuery,
            })
        );
    }
}
