import { IAddNewPortfolioItem } from './../modules/settings/models/portfolio-add.model';
import { Injectable, Inject, PLATFORM_ID } from '@angular/core';
import { HttpService } from './base/http.service';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Router } from '@angular/router';
import { Observable, throwError } from 'rxjs';
import { environment } from 'src/environments/environment';
import { switchMap, map } from 'rxjs/operators';
import { ENDPOINTS } from '../constants/endpoint.constant';

@Injectable({
    providedIn: 'root',
})
export class PortfolioService extends HttpService {
    constructor(
        @Inject(PLATFORM_ID) protected platformId: Object,
        protected http: HttpClient,
        protected router: Router
    ) {
        super(platformId, http, router);
    }

    public getPortfolioListing(criteria: any): Observable<any> {
        let params = new HttpParams();
        params = params.append('page', criteria.page);
        params = params.append('page_entries', criteria.pageEntries);

        const url = environment.apiProUrl + ENDPOINTS.PORTFOLIO.LIST;

        return this.getRequestOptions(true).pipe(
            switchMap((headers) => {
                const options = {
                    headers: headers.headers,
                    params,
                };

                return this.http.get(url, options).pipe(
                    map((resp: any) => {
                        if (resp.status !== 'success') {
                            throwError(resp);
                        }
                        return resp;
                    })
                );
            })
        );
    }

    public getPortfolioSingle(id: number): Observable<any> {
        const url = environment.apiProUrl + ENDPOINTS.PORTFOLIO.SINGLE_ITEM.replace('{portfolio_id}', id);

        return this.getRequestOptions(true).pipe(
            switchMap((headers) => {
                const options = {
                    headers: headers.headers,
                };

                return this.http.get(url, options).pipe(
                    map((resp: any) => {
                        if (resp.status !== 'success') {
                            throwError(resp);
                        }
                        return resp;
                    })
                );
            })
        );
    }

    public addPortfolioItem(requestBody: IAddNewPortfolioItem): Observable<any> {
        const url = environment.apiProUrl + ENDPOINTS.PORTFOLIO.ADD_ITEM;

        return this.getRequestOptions(true).pipe(
            switchMap((headers) => {
                const options = {
                    headers: headers.headers,
                };
                return this.http.post(url, requestBody, options);
            })
        );
    }

    public editPortfolioItem(id: number, requestBody: IAddNewPortfolioItem): Observable<any> {
        const url = environment.apiProUrl + ENDPOINTS.PORTFOLIO.SINGLE_ITEM.replace('{portfolio_id}', id);

        return this.getRequestOptions(true).pipe(
            switchMap((headers) => {
                const options = {
                    headers: headers.headers,
                };
                return this.http.put(url, requestBody, options);
            })
        );
    }

    public deletePortfolioItem(id: number): Observable<any> {
        const url = environment.apiProUrl + ENDPOINTS.PORTFOLIO.SINGLE_ITEM.replace('{portfolio_id}', id);

        return this.getRequestOptions(true).pipe(
            switchMap((headers) => {
                const options = {
                    headers: headers.headers,
                };
                return this.http.delete(url, options);
            })
        );
    }

    public updateIncentiveImg(payload: FormData): Observable<any> {
        const url = environment.apiProUrl + ENDPOINTS.PORTFOLIO.INCENTIVES;

        return this.http.post(url, payload, {
            headers: {
                Authorization: this.getToken(),
            },
        });
    }

    public getIncentiveImg(): Observable<any> {
        const url = environment.apiProUrl + ENDPOINTS.PORTFOLIO.INCENTIVES;
        return this.getRequestOptions(true).pipe(
            switchMap((headers) => {
                const options = {
                    headers: headers.headers,
                };
                return this.http.get(url, options).pipe(
                    map((resp: any) => {
                        if (resp.status !== 'success') {
                            throwError(resp);
                        }
                        return resp;
                    })
                );
            })
        );
    }
}
