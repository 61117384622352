import { environment } from './../environments/environment';
import { Component, Inject, OnInit, PLATFORM_ID } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { State } from 'src/app/stores/reducers';
import { Store } from '@ngrx/store';
import { BsLocaleService } from 'ngx-bootstrap/datepicker';
import { isPlatformBrowser } from '@angular/common';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
    constructor(
        @Inject(PLATFORM_ID) protected platformId: Object,
        private router: Router,
        private store: Store<State>,
        private localeService: BsLocaleService
    ) {
        this.localeService.use('th-be');
    }

    ngOnInit(): void {
        this.scrollToTopOnRouteChange();
        this.initialiseMsClarity();
    }

    private scrollToTopOnRouteChange(): void {
        this.router.events.subscribe((evt) => {
            if (!(evt instanceof NavigationEnd)) {
                return;
            }
            if (isPlatformBrowser(this.platformId)) {
                window.scrollTo(0, 0);
            }
        });
    }

    private initialiseMsClarity(): void {
        if (environment.msClarityId) {
            const script = document.createElement('script');
            script.innerHTML = `(function(c,l,a,r,i,t,y){
                c[a]=c[a]||function(){(c[a].q=c[a].q||[]).push(arguments)};
                t=l.createElement(r);t.async=1;t.src="https://www.clarity.ms/tag/"+i;
                y=l.getElementsByTagName(r)[0];y.parentNode.insertBefore(t,y);
            })(window, document, "clarity", "script", "${environment.msClarityId}");`;
            script.type = 'text/javascript';
            script.async = true;
            document.getElementsByTagName('head')[0].appendChild(script);
        }
    }
}
