<div class="group-plan-filter">
  <label>เดินทาง</label>
  <app-searchable-dropdown #dateRangeDropdown [settings]="dropdownSettings" [items]="dropdownItems"
    (selected)="onSelectDateRange($event)">
  </app-searchable-dropdown>
  <div class="group-plan-list">

    <div class="group-plan-item" [ngClass]="{'active': orderSummary.active}" *ngFor="let orderSummary of orderSummaries"
      (click)="onSelectGroupPlan(orderSummary)">
      <div class="booking">
        <div class="date" [ngStyle]="{'font-size': filterType==='week'?'13px':'inherit'}">
          <ng-container *ngIf="filterType==='month'">
            {{orderSummary.dateRange.minDate | thaiMonth}}
          </ng-container>
          <ng-container *ngIf="filterType==='week'">
            {{orderSummary.dateRange.minDate | thaidaterange:orderSummary.dateRange.maxDate}}
          </ng-container>
        </div>
        <div class="amount">{{orderSummary.orderCount}} <span>Booking</span> </div>
      </div>
      <div class="passenger">
        เดินทาง {{orderSummary.sumQuantity | number}} ท่าน
      </div>
    </div>
  </div>

</div>
