<div class="campaign-orderlist-searchbox">
    <div class="row mb-3">
        <div class="col">
            <div class="position-relative campaign-orderlist-searchinput">
                <i class="icon icon-search position-absolute"></i>
                <input class="form-control bluey-grey-text" type="text"
                    placeholder="ค้นหาจาก ชื่อลูกค้า, เบอร์โทร, รหัส Order" 
                    [(ngModel)]="searchTerm"
                    (keyup)="onTypeSearchTerm($event)">
            </div>
        </div>
        <div class="col campaign-orderlist-searchbutton">
            <button class="btn btn-primary" (click)="onClickSearchButton()">ค้นหา</button>
        </div>
    </div>
    <div class="row d-inline-flex campaign-orderlist-statuses">
        <div class="col col-sm-2">
            <a href="javascript:void(0)" (click)="changeActivedTab(available)">
                <span
                    [ngClass]="{'active': activedTab === available}">รอใช้สิทธิ์ ({{ paymentCount.available | number:'1.0-0' }})
                </span>
            </a>
        </div>
        <div class="col col-sm-2">
            <a href="javascript:void(0)" (click)="changeActivedTab(all)">
                <span
                    [ngClass]="{'active': activedTab === all}">ทั้งหมด ({{ paymentCount.all | number:'1.0-0' }})
                </span>
            </a>
        </div>
    </div>
</div>