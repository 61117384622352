import { HttpService } from './base/http.service';

import { ENDPOINTS } from '../constants/endpoint.constant';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpParams } from '@angular/common/http';

@Injectable({
    providedIn: 'root',
})
export class ProductSubCategoryService {
    constructor(private httpService: HttpService) {}

    getAllProductSubCategories(productCategoryIds: number[]): Observable<any> {
        const url = environment.apiProUrl + ENDPOINTS.PRODUCT_SUB_CATEGORY.GET_LIST;

        let params = new HttpParams();
        if (productCategoryIds && productCategoryIds.length) {
            params = params.set('product_category_ids', JSON.stringify(productCategoryIds));
        }

        return this.httpService.get(url + '?' + params.toString(), false);
    }
}
