<div class="form-group calendar" [class.for-add-booking-modal]="forAddBookingModalStyling">
    <div class="">
        <input
            type="text"
            class="form-control hidden"
            [bsConfig]="bsConfig"
            [minDate]="minDate"
            [maxDate]="maxDate"
            [bsValue]="bsValue"
            (bsValueChange)="onChangeBsValue($event)"
            (keydown)="onKeydown($event)"
            (onShown)="onShown()"
            (onHidden)="onHidden()"
            bsDatepicker
            [placement]="placement"
            #dp="bsDatepicker"
        />
        <!-- [value]="(setDateValue()) + (bsValue | thaiMonthDate)" -->
        <input
            readonly
            onkeypress="return false;"
            type="text"
            class="form-control"
            [placeholder]="placeholder"
            (click)="dp.toggle()"
            [value]="bsValue | thaiMonthDate"
            *ngIf="showOnlyMonth"
            [ngClass]="{ 'my-red-border': !valid, disabled: disabled }"
            [disabled]="disabled"
        />
        <input
            readonly
            onkeypress="return false;"
            type="text"
            class="form-control"
            [placeholder]="placeholder"
            (click)="dp.toggle()"
            [value]="bsValue | thaiDate"
            *ngIf="!showOnlyMonth"
            [ngClass]="{ 'my-red-border': !valid, disabled: disabled }"
            [disabled]="disabled"
        />
        <img *ngIf="showIcon" class="icon" src="assets/img/icon/icon-calendar.svg" />
    </div>
</div>
