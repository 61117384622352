export class ProductNameList {
    public product_pool_id: number;
    public productPoolKey: string;
    public tourCode: string;
    public name: string;

    public deserialize(obj: any): ProductNameList {
        this.product_pool_id = obj.product_pool_id || obj.id;
        this.productPoolKey = obj.product_pool_key;
        this.tourCode = obj.tour_code;
        this.name = obj.name;

        return this;
    }
}
