import { IProduct } from '../interfaces/tw-booking-interface/product.interface';

export class Product {
    id: number;
    name: string;
    tourCode: string;
    categorySubProductId: number;

    public deserialize(data: IProduct): Product {
        this.id = data.id;
        this.name = data.name;
        this.tourCode = data.tour_code;
        this.categorySubProductId = data.category_sub_product_id;
        return this;
    }
}
