<div class="modal-header">
    <button type="button" class="close pull-right" aria-label="Close" (click)="bsModalRef.hide()">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="top-wrapper">
    <h4 class="modal-title text-center">ยกเลิก Order</h4>
    <small class="are-you-sure">คุณแน่ใจหรือไม่ว่าต้องการยกเลิก Order นี้?</small>
</div>
<div class="modal-body modal-lg">
    <div class="booking-details">
        <p class="font-weight-bold">
            รหัส Order : {{ booking?.orderCode }}
            <small>(จองวันที่ {{ booking?.createdAt | thaiMonthDateTime: true }})</small>
        </p>
        <p>
            <ng-container *ngIf="booking?.product?.tourCode">
                {{ booking?.product?.tourCode }}
            </ng-container>
            ชื่อโปรแกรม : {{ booking?.product?.name }}
        </p>
        <p>
            เดินทาง {{ booking?.productPeriod?.startAt | thaidaterange: booking?.productPeriod?.endAt }} จำนวน
            {{ booking?.quantity }} คน
        </p>
    </div>
    <div class="customer-details">
        <p>
            <span class="font-weight-bold"> ชื่อลูกค้า : </span>
            {{ booking?.customer?.name }}
        </p>
        <p>
            <span class="font-weight-bold">เบอร์โทร : </span>
            {{ booking?.customer?.phoneNumber || '-' | phoneNumber }}
        </p>
    </div>
</div>
<div class="modal-footer">
    <div class="button-container">
        <button type="button" class="btn btn-primary" (click)="submitButton()">ยืนยันยกเลิก Order</button>
        <button type="button" class="btn btn-default" (click)="bsModalRef.hide()">ยกเลิก</button>
    </div>
</div>
