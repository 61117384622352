import { ExportLeadExcelService } from 'src/app/services/export-lead-excel.service';
import { ThaiDateRangePipe, ThaiMonthDatePipe } from '../../../../pipes/thaidate.pipe';
import { IBookingReportDownloadSearch } from '../../models/booking-report-download-search.model';
import { Component, OnInit, ViewChild } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { IBreadcrumb } from 'src/app/models/breadcrumb.model';
import { IBookingMonthlyReport } from 'src/app/models/booking-monthly-report.model';
import { BookingStatMonthly, BookingStatPeriodMonthly } from 'src/app/models/booking-stat-monthly.model';
import {
    SearchableDropdownComponent,
    SearchableDropdownItem,
    SearchableDropdownSettings,
} from 'src/app/modules/shared/components/searchable-dropdown/searchable-dropdown.component';
import { State } from 'src/app/stores/reducers';
import * as OrderActions from 'src/app/stores/order-store/order.actions';
import * as UserActions from 'src/app/stores/user-store/user.actions';
import { selectBookingStatMonthly, selectBookingStatMonthlyDetails } from 'src/app/stores/order-store/order.selectors';
import { selectUser, selectUsersList } from 'src/app/stores/user-store/user.selectors';
import { filter, map, take } from 'rxjs/operators';
import { User } from 'src/app/models/user';

@Component({
    selector: 'app-booking-report-download',
    templateUrl: './booking-report-download.component.html',
    styleUrls: ['./booking-report-download.component.scss'],
})
export class BookingReportDownloadComponent implements OnInit {
    public breadcrumb: IBreadcrumb[];
    public salesDropdownSettings: SearchableDropdownSettings = new SearchableDropdownSettings();
    public salesDropdownItems: SearchableDropdownItem[] = [];

    public fieldStartDate: Date;
    public fieldEndDate: Date;
    public fieldSales: number[] = [];
    public bookingStatMonthly: BookingStatMonthly;
    private searchCriteria: IBookingReportDownloadSearch;

    public submittedStartDate: Date;
    public submittedEndDate: Date;

    public disableSalesDropdown: boolean = false;
    @ViewChild('salesDropdown') salesDropdown: SearchableDropdownComponent;

    constructor(
        private store: Store<State>,
        private exportExcelService: ExportLeadExcelService,
        private thaiDateRangePipe: ThaiDateRangePipe,
        public thaiMonthDatePipe: ThaiMonthDatePipe
    ) {}

    ngOnInit(): void {
        this.setBreadcrumb();
        this.setSalesDropdown();
        this.setBookingStatMonthly();
    }

    public onStartDateChange(date: Date): void {
        this.fieldStartDate = date;
    }

    public onEndDateChange(date: Date): void {
        this.fieldEndDate = date;
    }

    public onSelectedSales(id: string): void {
        if (Number(id)) {
            this.fieldSales = [parseInt(id)];
        } else {
            this.fieldSales = null;
        }
    }

    public searchBookingReport(): void {
        const minDate = this.fieldStartDate ? this.fieldStartDate.toISOString().substring(0, 10) : undefined;
        const maxDate = this.fieldEndDate
            ? new Date(this.fieldEndDate.getFullYear(), this.fieldEndDate.getMonth() + 1, +1)
                  .toISOString()
                  .substring(0, 10)
            : undefined;

        this.searchCriteria = {
            min_date: minDate,
            max_date: maxDate,
            sales_id: this.fieldSales,
        };
        this.store.dispatch(OrderActions.getBookingStatMonthly({ criteria: Object.assign({}, this.searchCriteria) }));

        this.submittedStartDate = this.fieldStartDate || null;
        this.submittedEndDate = this.fieldEndDate || null;
    }

    public resetSearchBookingReportFilters(): void {
        this.fieldStartDate = null;
        this.fieldEndDate = null;
        if (!this.disableSalesDropdown) {
            this.fieldSales = null;
            this.salesDropdown.selectAllItems(false);
        }
    }

    public downloadBookingReport(dateRange: string, count: number, period?: BookingStatPeriodMonthly): void {
        const searchCriteriaTemp: IBookingReportDownloadSearch = Object.assign({}, this.searchCriteria);
        if (period) {
            searchCriteriaTemp.min_date = new Date(period.yearMonth.getFullYear(), period.yearMonth.getMonth(), 1, 7)
                .toISOString()
                .substring(0, 10);
            searchCriteriaTemp.max_date = new Date(period.yearMonth.getFullYear(), period.yearMonth.getMonth() + 1, 1)
                .toISOString()
                .substring(0, 10);
        }
        searchCriteriaTemp.sales_id = searchCriteriaTemp.sales_id || [];
        this.store.dispatch(OrderActions.getBookingStatMonthlyDetails({ criteria: searchCriteriaTemp }));
        this.store
            .pipe(
                select(selectBookingStatMonthlyDetails),
                filter((resp) => !!resp),
                take(1)
            )
            .subscribe((reportModel: IBookingMonthlyReport[]) => {
                try {
                    this.exportExcelService.exportBookingMonthlyReport(dateRange, count, reportModel);
                } catch (error) {
                    console.error(error);
                }
                this.store.dispatch(
                    OrderActions.getBookingStatMonthlyDetailsSuccess({ bookingStatMonthlyDetails: null })
                );
            });
    }

    private setBreadcrumb(): void {
        this.breadcrumb = [
            { name: 'หน้าแรก', link: '/dashboard' },
            { name: 'รายงาน', link: '/report' },
            { name: 'ดาวน์โหลดข้อมูลสถานะการจอง', link: 'report/booking-report-download' },
        ];
    }

    private setSalesDropdown(): void {
        this.store.select(selectUser).subscribe((user: User) => {
            if (!user.roleSlug || user.roleSlug === 'sale') {
                let name = (user.firstName || '') + ' ' + (user.lastName || '');
                if (user.nickName) {
                    name += ' (' + user.nickName + ')';
                }
                const dropDownItem = new SearchableDropdownItem({
                    id: user.id,
                    name: name,
                    isChecked: true,
                });
                this.salesDropdownItems.push(dropDownItem);
                this.disableSalesDropdown = true;
            } else {
                this.store.dispatch(UserActions.getUsersList());
                this.store
                    .pipe(
                        select(selectUsersList),
                        filter((resp) => !!resp),
                        take(1),
                        map((usersList) => {
                            const dropDownItem = new SearchableDropdownItem({
                                id: null,
                                name: 'เซลล์บริษัท ทั้งหมด',
                            });
                            this.salesDropdownItems.push(dropDownItem);
                            return usersList.map((user) => {
                                const dropDownItem = new SearchableDropdownItem({
                                    id: user.id,
                                    name: user.nameString,
                                });
                                dropDownItem.isChecked = usersList.length === 1;
                                this.salesDropdownItems.push(dropDownItem);
                            });
                        })
                    )
                    .subscribe();
            }
        });

        this.salesDropdownSettings.dropdownListPlaceholder = 'เลือกเซลล์บริษัท';
        this.salesDropdownSettings.hideSearchBox = false;
        this.salesDropdownSettings.searchBoxPlaceholder = 'ค้นหาเซลล์บริษัท';
        this.salesDropdownSettings.customSearchIcon = '';
        this.salesDropdownSettings.multipleSelection = false;
        this.salesDropdownSettings.closeAfterSelect = true;
        this.salesDropdownSettings.styleSetting.fontSize = '14px';
    }

    private setBookingStatMonthly(): void {
        this.store.dispatch(OrderActions.getBookingStatMonthly({}));
        this.store.pipe(select(selectBookingStatMonthly)).subscribe((response) => {
            this.bookingStatMonthly = response;
        });
    }

    public getMonthRange(): string {
        if (this.bookingStatMonthly.period.length > 0) {
            return this.thaiDateRangePipe.transform(
                this.bookingStatMonthly.period[0].yearMonth.toDateString(),
                this.bookingStatMonthly.period[this.bookingStatMonthly.period.length - 1].yearMonth.toDateString(),
                false
            );
        }
        if (this.bookingStatMonthly.total === 0) {
            if (this.submittedStartDate && this.submittedEndDate) {
                return this.thaiDateRangePipe.transform(
                    this.submittedStartDate.toDateString(),
                    this.submittedEndDate.toDateString(),
                    false
                );
            } else {
                if (this.submittedStartDate) {
                    return this.thaiMonthDatePipe.transform(this.submittedStartDate);
                }
                if (this.submittedEndDate) {
                    return this.thaiMonthDatePipe.transform(this.submittedEndDate);
                }
            }
        }
        return '';
    }

    ngOnDestory() {
        this.store.dispatch(OrderActions.getBookingStatMonthlySuccess({ bookingStatMonthly: null }));
    }
}
