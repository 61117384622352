import { IDeserializable } from './interfaces/deserializable';

export class OrderBalanceReportOverView implements IOrderBalanceReportOverView, IDeserializable {
    data: IOrderBalanceReportOverViewItem[];
    total: number;
    pageEntries: number;

    deserialize(source: any): this {
        this.data = source.result.map((r) => new OrderBalanceReportOverViewItem().deserialize(r));
        this.total = source.total;
        this.pageEntries = source.page_entries;

        return this;
    }
}

export interface IOrderBalanceReportOverView {
    data: IOrderBalanceReportOverViewItem[];
    total: number;
    pageEntries: number;
}

export class OrderBalanceReportOverViewItem implements IOrderBalanceReportOverViewItem, IDeserializable {
    id: number;
    receiptItemQuantity: number;
    sumAmount: number;
    sumNetCommissionAgency: number;
    agencyUseVat: boolean;
    productOwnerReceiptTypeSlug: string;
    productOwnerPartner: IOrderBalanceProductOwnerPartnerOverView;
    items: IOrderBalanceReportItem[];

    requestedReceipt: boolean;
    hasWithHoldingTax: boolean;
    whTaxDocFileUrl: string;

    deserialize(source: any): this {
        this.id = source.id;
        this.receiptItemQuantity = source.receipt_item_quantity;
        this.sumAmount = source.sum_amount;
        this.sumNetCommissionAgency = source.sum_net_commission_agency;
        this.agencyUseVat = source.agency_use_vat;
        this.productOwnerReceiptTypeSlug = source.product_owner_receipt_type_slug;
        this.productOwnerPartner = new OrderBalanceProductOwnerPartnerOverView().deserialize(
            source.product_owner_partner
        );
        this.items = source.items.map((r) => new OrderBalanceReportItem().deserialize(r));

        this.requestedReceipt =
            this.productOwnerReceiptTypeSlug === 'receipt' || this.productOwnerReceiptTypeSlug === 'receipt_wh_tax';
        this.hasWithHoldingTax = this.productOwnerReceiptTypeSlug === 'receipt_wh_tax';
        this.whTaxDocFileUrl = source.wh_tax_doc_file_url;

        return this;
    }
}

export interface IOrderBalanceReportOverViewItem {
    id: number;
    receiptItemQuantity: number;
    sumAmount: number;
    sumNetCommissionAgency: number;
    agencyUseVat: boolean;
    productOwnerReceiptTypeSlug: string;
    productOwnerPartner: IOrderBalanceProductOwnerPartnerOverView;
    items: IOrderBalanceReportItem[];

    requestedReceipt: boolean;
    hasWithHoldingTax: boolean;
    whTaxDocFileUrl: string;
}

export class OrderBalanceProductOwnerPartnerOverView
    implements IOrderBalanceProductOwnerPartnerOverView, IDeserializable {
    id: number;
    nameEn: string;
    nameTh: string;
    receiptTypeSlug: string;

    deserialize(source: any): this {
        this.id = source.id;
        this.nameEn = source.name_en;
        this.nameTh = source.name_th;
        this.receiptTypeSlug = source.receipt_type_slug;

        return this;
    }
}

export interface IOrderBalanceProductOwnerPartnerOverView {
    id: number;
    nameEn: string;
    nameTh: string;
    receiptTypeSlug: string;
}

export class OrderBalanceReport implements IOrderBalanceReport, IDeserializable {
    data: IOrderBalanceReportItem[];
    total: number;
    pageEntries: number;

    deserialize(source: any): this {
        this.data = source.result.map((d) => new OrderBalanceReportItem().deserialize(d));
        this.total = source.total;
        this.pageEntries = source.page_entries;

        return this;
    }
}

export interface IOrderBalanceReport {
    data: IOrderBalanceReportItem[];
    total: number;
    pageEntries: number;
}

export class OrderBalanceReportItem implements IOrderBalanceReportItem, IDeserializable {
    id: number;
    itemQuantity: number;
    amount: string;
    commissionAgency: string;
    commissionAgencyVat: string;
    commissionAgencyWhTax: string;
    netCommissionAgency: string;
    order: IOrderBalanceOrder;
    campaign: IOrderBalanceCampaign;
    voucher: IOrderBalanceVoucher;
    productOwnerPartner: IOrderBalanceProductOwnerPartner;

    deserialize(source: any): this {
        this.id = source.id;
        this.itemQuantity = source.item_quantity;
        this.amount = source.amount;
        this.commissionAgency = source.commission_agency;
        this.commissionAgencyVat = source.commission_agency_vat;
        this.commissionAgencyWhTax = source.commission_agency_wh_tax;
        this.netCommissionAgency = source.net_commission_agency;

        this.order = new OrderBalanceOrder().deserialize(source.order);
        this.campaign = new OrderBalanceCampaign().deserialize(source.campaign);
        this.voucher = new OrderBalanceVoucher().deserialize(source.voucher);
        this.productOwnerPartner = new OrderBalanceProductOwnerPartner().deserialize(source.product_owner_partner);

        return this;
    }
}

export interface IOrderBalanceReportItem {
    id: number;
    itemQuantity: number;
    amount: string;
    commissionAgency: string;
    commissionAgencyVat: string;
    commissionAgencyWhTax: string;
    netCommissionAgency: string;
    order: IOrderBalanceOrder;
    campaign: IOrderBalanceCampaign;
    voucher: IOrderBalanceVoucher;
    productOwnerPartner: IOrderBalanceProductOwnerPartner;
}

export class OrderBalanceOrder implements IOrderBalanceOrder, IDeserializable {
    id: number;
    orderCode: string;
    contactName: string;
    phoneNumber: string;
    createdAt: string;

    deserialize(source: any): this {
        this.id = source.id;
        this.orderCode = source.order_code;
        this.contactName = source.contact_name;
        this.phoneNumber = source.phone_number;
        this.createdAt = source.created_at;

        return this;
    }
}

export interface IOrderBalanceOrder {
    id: number;
    orderCode: string;
    contactName: string;
    phoneNumber: string;
    createdAt: string;
}

export class OrderBalanceCampaign implements IOrderBalanceCampaign, IDeserializable {
    id: number;
    shopName: string;
    officeTelephoneNumber: string;

    deserialize(source: any): this {
        this.id = source.id;
        this.shopName = source.shop_name;
        this.officeTelephoneNumber = source.office_telephone_number;

        return this;
    }
}

export interface IOrderBalanceCampaign {
    id: number;
    shopName: string;
    officeTelephoneNumber: string;
}

export class OrderBalanceVoucher implements IOrderBalanceVoucher, IDeserializable {
    id: number;
    name: string;
    price: string;
    commissionAgency: string;

    deserialize(source: any): this {
        this.id = source.id;
        this.name = source.name;
        this.price = source.price;
        this.commissionAgency = source.commission_agency;

        return this;
    }
}

export interface IOrderBalanceVoucher {
    id: number;
    name: string;
    price: string;
    commissionAgency: string;
}

export class OrderBalanceProductOwnerPartner implements IOrderBalanceProductOwnerPartner, IDeserializable {
    id: number;
    nameEn: string;
    nameTh: string;
    officeTelephoneNumber: string;
    additionalPhoneNumber: string;
    email: string;
    address: string;

    deserialize(source: any): this {
        this.id = source.id;
        this.nameEn = source.name_en;
        this.nameTh = source.name_th;
        this.officeTelephoneNumber = source.office_telephone_number;
        this.additionalPhoneNumber = source.additional_phone_number;
        this.email = source.email;
        this.address = source.address;

        return this;
    }
}

export interface IOrderBalanceProductOwnerPartner {
    id: number;
    nameEn: string;
    nameTh: string;
    officeTelephoneNumber: string;
    additionalPhoneNumber: string;
    email: string;
    address: string;
}
