import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'paymentMethod',
})
export class PaymentMethodPipe implements PipeTransform {
    transform(paymentMethod: string | undefined | null): string {
        const mobileBankingMaps: Record<string, string> = {
            mobile_banking_kbank: 'Mobile Banking KBank',
            mobile_banking_bbl: 'Mobile Banking BBL',
            mobile_banking_bay: 'Mobile Banking BAY',
            mobile_banking_scb: 'Mobile Banking SCB',
        };

        if (!paymentMethod) {
            return '';
        } else if (paymentMethod === 'card') {
            return 'บัตรเครดิต/เดบิต';
        } else if (paymentMethod === 'promptpay') {
            return 'พร้อมเพย์';
        } else if (mobileBankingMaps[paymentMethod]) {
            return mobileBankingMaps[paymentMethod];
        } else if (paymentMethod === 'bank_transfer') {
            return 'โอนเงิน (กสิกรไทย 0358891543)';
        } else if (paymentMethod === 'cash') {
            return 'เงินสด';
        } else if (paymentMethod === 'cheque') {
            return 'เช็ก';
        } else if (paymentMethod === 'other') {
            return 'อื่นๆ';
        } else {
            return paymentMethod;
        }
    }
}
