<ng-container *ngIf="agencyInfo?.displayCommission && commissionReportList">
  <div class="commission-report-container">
    <div class="commission-report-header">
      <div class="title">
        คุณกำลังดูรายงานค่าคอม
        <ng-container *ngIf="!commissionListQueries?.filters?.clearCache">
          ของเดือน {{orderSummary?.dateRange?.minDate | thaiMonth}}
        </ng-container>
        <ng-container *ngIf="commissionListQueries?.filters?.clearCache">
          {{getDynamicTotalString()}}
        </ng-container>
         จำนวน
        {{commissionReportList.total | number}} Booking ยอดคอมรวม
        {{commissionReportList.stats.allOrderSumCommission | number}}
      </div>
    </div>
    <div class="commission-report-table">
      <div class="header">
        <div class="column column-01">Booking</div>
        <div class="column column-02">คอมบริษัท</div>
        <div class="column column-03">คอมเซลล์</div>
        <div class="column column-04">จำนวนผู้เดินทาง</div>
        <div class="column column-05">คอมรวม</div>
        <div class="column column-06"></div>
      </div>
      <div class="body">
        <div class="body-row " *ngFor="let commissionReport of commissionReportList.commissionReports; let i = index">
          <div class="column column-01">
            <p class="first-line">
              {{i+1}}.
              <span class="customer">
                <a (click)="openContactInfoModal(commissionReport)" class="name-field-link">
                  ลูกค้า : {{commissionReport.contactName}} <i class="icon icon-info"></i>
                </a>
              </span>

              จองวันที่ {{commissionReport.createdAt | thaiMonthDateTime: true}} ({{commissionReport.orderCode}})
            </p>
            <p class="second-line">
              {{CATEGORY_SUB_PRODUCTS_TO_STRING[commissionReport.proCategorySubProductsId]}}
              เดินทาง
              {{commissionReport.startAt | thaiDate}}&nbsp;-&nbsp;
              {{commissionReport.endAt | thaiDate}}&nbsp;
              <ng-container *ngIf="commissionReport.tourCode">
                {{agencyInfo.productCode}}-{{commissionReport.tourCode}}
              </ng-container>
              {{commissionReport.productName}}
            </p>
            <p class="third-line">
              <a (click)="openBusinessContactInfoModal(commissionReport.productOwner)" class="name-field-link">
                {{commissionReport.productOwner.nameEN}}({{commissionReport.productOwner.nameTH}})
                &nbsp;โทร.{{commissionReport.productOwner.phoneNumber | phoneNumber}}
                <i class="icon icon-info"></i>
              </a>
            </p>
            <p class="fourth-line">
              เซลล์ผู้ขาย : {{commissionReport.sellerAgencyMember.firstName}}
              {{commissionReport.sellerAgencyMember.lastName}}
              <span *ngIf="commissionReport.sellerAgencyMember.nickName">
                ({{commissionReport.sellerAgencyMember.nickName}})
              </span>
            </p>
          </div>
          <div class="column column-02">
            {{commissionReport.commissionCompany | number}}
          </div>
          <div class="column column-03">
            {{commissionReport.commissionSeller | number}}
          </div>
          <div class="column column-04">
            {{commissionReport.sumQuantity | number}}
          </div>
          <div class="column column-05">
            {{(commissionReport.commissionCompany+commissionReport.commissionSeller)*commissionReport.sumQuantity | number}}
          </div>
          <div class="column column-06">
            <button (click)="viewMoreDetail(commissionReport)" class="btn-see-more">ดูข้อมูล</button>
          </div>
        </div>
      </div>
      <button class="btn-paging" *ngIf="commissionReportList.total>commissionReportList.commissionReports.length"
        (click)="getMoreCommissionReport(commissionReportList.commissionReports.length)">แสดงรายการที่
        {{commissionReportList.commissionReports.length+1}} -
        {{commissionReportList.commissionReports.length+50}}</button>
    </div>

  </div>
</ng-container>
