<div class="payment-info">
    <div class="payment-info-title">
        <span>ข้อมูลการชำระเงิน</span>
    </div>
    <div class="d-flex mb-3">
        <div class="w-100 d-block">
            <p class="payment-info-label mb-1">ช่องทางการชำระเงิน</p>
            <span>{{ latestPaymentEvent.payment_method | paymentMethod }}</span>
        </div>
        <div class="w-100">
            <div class="payment-action">
                <div *ngIf="latestPaymentEvent.event_type === 'pending'">
                    <i class="pending-status"></i>
                    <span class="text-primary">รออนุมัติการชำระเงิน</span>
                </div>
                <div *ngIf="latestPaymentEvent.event_type === 'successful'">
                    <i class="paid-status"></i>
                    <span class="">อนุมัติชำระเงินแล้ว</span>
                    <div>
                        <span class="text-xs"
                            >(โดย {{ latestPaymentEvent.created_by_agency_member.nick_name }}
                            {{ latestPaymentEvent.created_at | thaiDate: 'DD MM YY HH:mm' }} )</span
                        >
                    </div>
                </div>
                <div>
                    <span
                        *ngIf="installment.status !== 'paid'"
                        (click)="cancelRequestPayment()"
                        class="cursor-pointer underline"
                        >ยกเลิกการชำระเงิน</span
                    >
                </div>
            </div>
            <div></div>
        </div>
    </div>
    <div class="row mb-3">
        <div class="col-4">
            <p class="payment-info-label mb-1">ยอดที่ชำระ</p>
            <span>{{ latestPaymentEvent.amount | currency: '':'':'1.2-2' }}</span>
        </div>
        <div class="col-4">
            <p class="payment-info-label mb-1">วันที่ชำระ</p>
            <span>{{ latestPaymentEvent.issued_at | thaiDate }}</span>
        </div>
        <div class="col-4">
            <p class="payment-info-label mb-1">เวลาที่ชำระ</p>
            <span>{{ latestPaymentEvent.issued_at | thaiDate: 'HH:mm' }}</span>
        </div>
    </div>
    <div class="payment-info-img mb-3">
        <p class="payment-info-label mb-1">หลักฐานการชำระเงิน</p>
        <div *ngIf="latestPaymentEvent?.event_type === 'pending'; else parentFileDisplay" class="row img-panel">
            <div *ngFor="let item of latestPaymentEvent?.files" class="col-4">
                <div class="img-cover">
                    <img width="100%" [src]="item.url" alt="" (click)="openImage(item.url)" />
                </div>
            </div>
        </div>
        <ng-template #parentFileDisplay>
            <div
                *ngIf="getPaymentEventById(latestPaymentEvent?.parent_payment_event_id) as parentEvent"
                class="row img-panel"
            >
                <div *ngFor="let item of parentEvent?.files" class="col-4">
                    <div class="img-cover">
                        <img width="100%" [src]="item.url" alt="" (click)="openImage(item.url)" />
                    </div>
                </div>
            </div>
        </ng-template>
    </div>
    <div *ngIf="latestPaymentEvent.remark" class="payment-info-note mb-3">
        <p class="payment-info-label mb-1">ข้อมูลเพิ่มเติม</p>
        <span>{{ latestPaymentEvent.remark }}</span>
    </div>
</div>
