export interface ICategoryTransport {
    id: number;
    slug: string;
    name: string;
}

export class CategoryTransport implements ICategoryTransport {
    id: number = 0;
    slug: string = null;
    name: string = null;

    public deserialize(obj: any): CategoryTransport {
        this.id = obj.id;
        this.slug = obj.slug;
        this.name = obj.name;
        return this;
    }
}
