<div *ngIf="installment" class="installment" [ngClass]="{ hide: installment.hide }">
    งวดที่ {{ installment.ordinal }} วันที่
    <input
        type="text"
        placeholder="เลือกวันที่ชำระ"
        class="form-control form-control-sm mx-2"
        [bsValue]="installment.date"
        [disabled]="disabled"
        [bsConfig]="{
            dateInputFormat: 'DD/MM/YYYY'
        }"
        (bsValueChange)="onValueChange($event)"
        bsDatepicker
    />
    จำนวนเงิน
    <input
        #input
        [(ngModel)]="installment.price"
        placeholder="กรอกจำนวน"
        class="form-control form-control-sm mx-2"
        [disabled]="!installment.enable || disabled"
        currencyMask
        [options]="{ prefix: '', max: installment.maxPrice, min: 0 }"
    />
    บาท
</div>
