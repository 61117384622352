export interface IBookingStatusCount {
    waitingConfirm: number;
    waitingFirstPaid: number;
    waitingFullPaid: number;
    completeNotStarted: number;
    completeStarted: number;
    cancel: number;
    total: number;
}

export class BookingStatusCount {
    public static deserialize(obj: any): IBookingStatusCount {
        return {
            waitingConfirm: obj.waiting_confirm,
            waitingFirstPaid: obj.waiting_first_paid,
            waitingFullPaid: obj.waiting_full_paid,
            completeNotStarted: obj.complete_not_started,
            completeStarted: obj.complete_started,
            cancel: obj.cancel,
            total: this.calculateTotal(obj),
        };
    }

    public static calculateTotal(obj: any): number {
        let total = 0;

        for (const property in obj) {
            total += obj[property];
        }

        return total;
    }
}
