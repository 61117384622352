import { ThaiDatePipe } from './../../pipes/thaidate.pipe';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ThaiDateRangePipe, ThaiMonthDatePipe } from 'src/app/pipes/thaidate.pipe';

// Pages
import { BookingListComponent } from './pages/booking-list/booking-list.component';
import { CampaignOrderListPageComponent } from './pages/campaign-order-list-page/campaign-order-list-page.component';
import { ProgramOrderListPageComponent } from './pages/program-order-list-page/program-order-list-page.component';
import { TwBookingListPageComponent } from './pages/tw-booking-list-page/tw-booking-list-page.component';
import { TwAddEditBookingPageComponent } from './pages/tw-add-edit-booking-page/tw-add-edit-booking-page.component';

const routes: Routes = [
    { path: 'booking-list', component: BookingListComponent },
    { path: 'campaign-list', component: CampaignOrderListPageComponent },
    { path: 'program-list', component: ProgramOrderListPageComponent },
    {
        path: 'tw-booking',
        children: [
            { path: 'list', component: TwBookingListPageComponent },
            { path: 'add', component: TwAddEditBookingPageComponent },
            { path: 'edit/:id', component: TwAddEditBookingPageComponent },
        ],
    },
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule],
    providers: [ThaiDateRangePipe, ThaiMonthDatePipe, ThaiDatePipe],
})
export class OrderRoutingModule {}
