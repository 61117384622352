<tr *ngIf="roomType" class="d-flex">
    <td class="col-6">
        <ng-container *ngIf="roomType.slug === 'adult_single'">
            <span class="room-icon"
                ><img style="width: 11px" src="assets/img/icon/icon-room-single-bed.svg" alt=""
            /></span>
            <span>ผู้ใหญ่ พักเดี่ยว</span>
        </ng-container>
        <ng-container *ngIf="roomType.slug === 'adult_double'">
            <span class="room-icon"
                ><img style="width: 23px" src="assets/img/icon/icon-room-double-bed.svg" alt=""
            /></span>
            <span>ผู้ใหญ่ พักคู่</span>
        </ng-container>
        <ng-container *ngIf="roomType.slug === 'adult_triple'">
            <span class="room-icon"><img src="assets/img/icon/icon-room-triple-bed.svg" alt="" /></span>
            <span>ผู้ใหญ่ พักสาม</span>
        </ng-container>
        <ng-container *ngIf="roomType.slug === 'child_bed'">
            <span class="room-icon"
                ><img style="width: 29px" src="assets/img/icon/icon-room-child-with-bed.png" alt=""
            /></span>
            <span>เด็กมีเตียง</span>
        </ng-container>
        <ng-container *ngIf="roomType.slug === 'child_no_bed'">
            <span class="room-icon"
                ><img style="width: 13px" src="assets/img/icon/icon-room-child-no-bed.png" alt=""
            /></span>
            <span>เด็กไม่มีเตียง</span>
        </ng-container>
        <ng-container *ngIf="roomType.slug === 'infant'">
            <span class="room-icon"><img style="width: 10px" src="assets/img/icon/icon-room-infant.png" alt="" /></span>
            <span>ทารก</span>
        </ng-container>
        <ng-container *ngIf="roomType.slug === 'join_land'">
            <span class="room-icon"></span>
            <span>จอยแลนด์</span>
        </ng-container>
    </td>
    <td class="col-2">{{ roomType.price | number }}</td>
    <td class="col-2">
        <app-quantity-textbox-v2
            class="quantity-box"
            [min]="0"
            [max]="99"
            [maxlength]="2"
            [disabled]="disabled"
            [(quantity)]="quantity"
            (quantityChange)="onChangeSkuQuantity()"
        >
        </app-quantity-textbox-v2>
    </td>
    <td class="col-2 text-right">{{ roomType.price * quantity | number: '1.2-2' }}</td>
</tr>
