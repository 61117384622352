<div class="lead-listing head-group">
    <div class="row">
        <div class="col-4 title-container">
            <strong class="title mb-0">โปรแกรมทัวร์ทั้งหมด {{listTotal}} รายการ</strong>
        </div>
        <div class="col-2">
            <div class="total-box">
                <label>ใบจองทั้งหมด</label>
                <p>{{countData ? (countData.total | number) : '-'}}</p>
            </div>
        </div>
        <div class="col-2">
            <div class="total-box">
                <label>รอดำเนินการ</label>
                <p>{{countData ? (countData.pending | number) : '-'}}</p>
            </div>
        </div>
        <div class="col-2">
            <div class="total-box">
                <label>รับจองแล้ว</label>
                <p>{{countData ? (countData.confirm | number) : '-'}}</p>
            </div>
        </div>
        <div class="col-2">
            <div class="total-box">
                <label>ยกเลิกใบจอง</label>
                <p>{{countData ? (countData.cancel | number) : '-'}}</p>
            </div>
        </div>
    </div>

    <div class="row product-summary-list">
        <div *ngFor="let lead of leadSummaryList" class="col-4">
            <app-lead-summary-details
                [lead]="lead"
                [leadStatistics]="getStatisticsForLead(lead.id)"
            ></app-lead-summary-details>
        </div>
    </div>

    <div class="text-center show-more-results" *ngIf="pageDetails.currentPage<pageDetails.maxPage">
        <button class="btn btn-show-more" (click)="nextPage()">
            แสดงรายการที่ {{(pageDetails.currentPage*pageDetails.pageEntries)+1}} - 
            {{showNextResultsValue()}}
        </button>
    </div>
</div>
