import { Component, EventEmitter, Input, Output } from '@angular/core';
import { RoomType } from 'src/app/models/room-type.model';

@Component({
    selector: 'app-sku-row',
    templateUrl: './sku-row.component.html',
    styleUrls: ['./sku-row.component.scss'],
})
export class SkuRowComponent {
    @Input() roomType: RoomType;
    @Input() quantity: number;
    @Input() disabled = false;

    @Output() changeQuantity = new EventEmitter<number>();

    constructor() {}

    // Events Listener
    onChangeSkuQuantity(): void {
        this.changeQuantity.emit(this.quantity);
    }
}
