<div class="main">
    <table class="table head">
        <thead>
            <tr class="d-flex">
                <th class="col-6" scope="col">รายการ</th>
                <th class="col-2" scope="col">ราคาต่อคน</th>
                <th class="col-2" scope="col">จำนวน</th>
                <th class="col-2 text-right" scope="col">จำนวนเงิน</th>
            </tr>
        </thead>
    </table>
    <div *ngIf="!period" class="program-selected-notify">กรุณาเลือกโปรแกรมทัวร์ และช่วงเวลาเดินทาง</div>
    <div *ngIf="period" class="list">
        <table class="table body">
            <tbody>
                <app-sku-row
                    [roomType]="adultDoubleBed"
                    [quantity]="sku.adultDoubleBed"
                    [disabled]="mode === MODE.VIEW"
                    (changeQuantity)="sku.adultDoubleBed = $event; onChangeSkuQuantity()"
                ></app-sku-row>
                <app-sku-row
                    [roomType]="adultSingleBed"
                    [quantity]="sku.adultSingleBed"
                    [disabled]="mode === MODE.VIEW"
                    (changeQuantity)="sku.adultSingleBed = $event; onChangeSkuQuantity()"
                ></app-sku-row>
                <app-sku-row
                    [roomType]="childNoBed"
                    [quantity]="sku.childNoBed"
                    [disabled]="mode === MODE.VIEW"
                    (changeQuantity)="sku.childNoBed = $event; onChangeSkuQuantity()"
                ></app-sku-row>
                <app-sku-row
                    [roomType]="adultTripleBed"
                    [quantity]="sku.adultTripleBed"
                    [disabled]="mode === MODE.VIEW"
                    (changeQuantity)="sku.adultTripleBed = $event; onChangeSkuQuantity()"
                ></app-sku-row>
                <app-sku-row
                    [roomType]="childWithBed"
                    [quantity]="sku.childWithBed"
                    [disabled]="mode === MODE.VIEW"
                    (changeQuantity)="sku.childWithBed = $event; onChangeSkuQuantity()"
                ></app-sku-row>
                <app-sku-row
                    [roomType]="infant"
                    [quantity]="sku.infant"
                    [disabled]="mode === MODE.VIEW"
                    (changeQuantity)="sku.infant = $event; onChangeSkuQuantity()"
                ></app-sku-row>
                <app-sku-row
                    [roomType]="joinLand"
                    [quantity]="sku.joinLand"
                    [disabled]="mode === MODE.VIEW"
                    (changeQuantity)="sku.joinLand = $event; onChangeSkuQuantity()"
                ></app-sku-row>
                <!--  -->
                <tr class="d-flex">
                    <td class="col-6">
                        <div>
                            <div class="form-inline">
                                <span>ขอส่วนลด (ถ้ามี)</span>
                                <input
                                    [(ngModel)]="discountPrice"
                                    [disabled]="mode === MODE.VIEW"
                                    (change)="onChangeSkuQuantity()"
                                    type="text"
                                    placeholder="กรอกส่วนลด"
                                    class="form-control form-control-sm mx-2"
                                    appMycurrency
                                />
                                <span>บาท</span>
                            </div>
                        </div>
                        <small
                            [ngClass]="{ 'd-none': discountPrice === null || (discountPrice * 100) / totalPrice <= 10 }"
                            class="warning-discount"
                            >ไม่สามารถกรอกส่วนลดได้เกิน 10%</small
                        >
                    </td>
                    <td class="col-2">
                        <div *ngIf="discountPrice" class="mb-2">จำนวนเงินรวมก่อนหักส่วนลด</div>
                        <div *ngIf="discountPrice">จำนวนเงินรวมหลังหักส่วนลด</div>
                    </td>
                    <td class="col-2"></td>
                    <td class="col-2 text-right">
                        <div *ngIf="discountPrice" class="mb-2">{{ totalPrice | number: '1.2-2' }}</div>
                        <div *ngIf="discountPrice">{{ totalPrice - discountPrice | number: '1.2-2' }}</div>
                    </td>
                </tr>
            </tbody>
            <tfoot>
                <tr class="summary d-flex">
                    <td class="col-6">
                        <div class="form-check form-check-inline">
                            <input
                                [(ngModel)]="requestVat"
                                [disabled]="mode === MODE.VIEW"
                                (change)="onChangeSkuQuantity()"
                                class="form-check-input"
                                type="checkbox"
                                id="request-txt-invoice"
                            />
                            <label class="form-check-label" for="request-txt-invoice">ขอใบกำกับภาษี</label>
                        </div>
                    </td>
                    <td class="col-2">
                        <div class="mb-2">จำนวนเงินรวม</div>
                        <div *ngIf="requestVat">ภาษีมูลค่าเพิ่ม {{ VAT_PERCENTAGE }}%</div>
                    </td>
                    <td class="col-2"></td>
                    <td class="col-2 text-right">
                        <div class="mb-2">{{ totalPrice - discountPrice | number: '1.2-2' }}</div>
                        <div *ngIf="requestVat">
                            {{ ((totalPrice - discountPrice) * VAT_PERCENTAGE) / 100 | number: '1.2-2' }}
                        </div>
                    </td>
                </tr>
                <tr class="total d-flex">
                    <td class="col-6"></td>
                    <td class="col-2">จำนวนเงินจ่ายสุทธิ (Total)</td>
                    <td class="col-2"></td>
                    <td class="col-2 text-right">{{ totalRealPaid | number: '1.2-2' }}</td>
                </tr>
            </tfoot>
        </table>
        <div class="text-right">
            <a (click)="popupWholesalePaymentDetail()" class="payment-wholesale-link"
                >ดูข้อมูลชำระเงินสำหรับ Wholesale</a
            >
        </div>
    </div>
</div>

<div *ngIf="period" class="secondary">
    <div class="d-flex justify-content-between">
        <div class="installment-selector">
            <div class="title mb-1">กรุณาเลือกงวดชำระเงิน</div>
            <div class="form-check" *ngFor="let item of [].constructor(3); let i = index">
                <input
                    [(ngModel)]="customerInstallmentSelector"
                    [disabled]="mode === MODE.VIEW"
                    (change)="onChangeCustomerInstallment()"
                    class="form-check-input"
                    type="radio"
                    name="customerInstallmentSelector"
                    id="customerInstallmentSelector{{ i + 1 }}"
                    [value]="i + 1"
                />
                <label class="form-check-label" for="customerInstallmentSelector{{ i + 1 }}"> {{ i + 1 }} งวด </label>
            </div>
        </div>
        <div *ngIf="customerInstallment" class="installment-form">
            <app-installment-item
                [(installment)]="customerInstallment.installments[0]"
                [disabled]="mode === MODE.VIEW"
                (changePrice)="reCalculateInstallment()"
            ></app-installment-item>
            <app-installment-item
                [(installment)]="customerInstallment.installments[1]"
                [disabled]="mode === MODE.VIEW"
                (changePrice)="reCalculateInstallment()"
            ></app-installment-item>
            <app-installment-item
                [(installment)]="customerInstallment.installments[2]"
                [disabled]="mode === MODE.VIEW"
                (changePrice)="reCalculateInstallment()"
            ></app-installment-item>
            <div class="total">
                รวม <span>{{ totalRealPaid | number: '1.2-2' }}</span> บาท
            </div>
        </div>
    </div>
</div>
