import { AddBookingB2cComponent } from '../add-booking-b2c/add-booking-b2c.component';
import { Component, OnDestroy } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { IAddBookingB2c } from '../../models/add-booking-b2c.model';

@Component({
    selector: 'app-close-add-booking-b2c-warning',
    templateUrl: './close-add-booking-b2c-warning.component.html',
    styleUrls: ['./close-add-booking-b2c-warning.component.scss'],
})
export class CloseAddBookingB2cWarningComponent implements OnDestroy {
    private confirmed: boolean = false;
    private id: number;

    addBookingB2c: IAddBookingB2c;

    constructor(public bsModalRef: BsModalRef, public bsModalService: BsModalService) {}

    confirm(): void {
        this.confirmed = true;
        this.bsModalRef.hide();
    }

    ngOnDestroy(): void {
        if (!this.confirmed) {
            const initialState = {
                id: this.id,
                addBookingB2c: this.addBookingB2c,
            };
            this.bsModalRef = this.bsModalService.show(AddBookingB2cComponent, {
                initialState,
                class: 'add-booking-b2c-modal',
            });
        }
    }
}
