import { Directive, ElementRef, Input, HostListener, OnInit } from '@angular/core';
import { MycurrencyPipe } from 'src/app/pipes/money.pipe';

@Directive({
    selector: '[appOnlyNumber]',
})
export class OnlyNumberDirective {
    constructor(private el: ElementRef) {}

    @HostListener('keydown', ['$event']) onKeyDown(e: KeyboardEvent) {
        let isKeyAllowed = true;
        if (e.key.length == 1) {
            if (e.metaKey || e.ctrlKey) {
                /** detecting cmd or ctrl */
                isKeyAllowed = true;
            } else if (new RegExp('^[0-9]$').test(e.key) === false) {
                isKeyAllowed = false;
            }
        }
        if (!isKeyAllowed) {
            e.preventDefault();
        }
    }

    @HostListener('paste', ['$event']) onPaste(e: ClipboardEvent) {
        const text = e.clipboardData.getData('text');
        if (new RegExp('^[0-9]+$').test(text) === false) {
            e.preventDefault();
        }
    }
}

@Directive({
    selector: '[appMycurrency]',
    providers: [MycurrencyPipe],
})
export class MycurrencyDirective implements OnInit {
    @Input() appMycurrency: IMycurrencyDirectiveSetting = { fractionSize: 2 };
    private el: any;

    constructor(private elementRef: ElementRef, private formatcurrencypipe: MycurrencyPipe) {
        this.el = this.elementRef.nativeElement;
    }
    ngOnInit() {
        this.el.value = this.el.value
            ? this.formatcurrencypipe.transform(this.el.value, this.appMycurrency.fractionSize)
            : null;
    }
    @HostListener('focus', ['$event.target.value', '$event'])
    onFocus(value, event) {
        this.el.value = this.formatcurrencypipe.parse(value, this.appMycurrency.fractionSize); // opossite of transform
        if (event.which == 9) {
            return false;
        }
        this.el.select();
    }

    @HostListener('blur', ['$event.target.value'])
    onBlur(value) {
        if (this.el.value) {
            this.el.value = this.formatcurrencypipe.transform(value, this.appMycurrency.fractionSize);
        }
    }
    @HostListener('keydown', ['$event']) onKeyDown(e: KeyboardEvent) {
        let isKeyAllowed = true;
        if (e.key.length == 1) {
            if (e.metaKey || e.ctrlKey) {
                /** detecting cmd or ctrl */
                isKeyAllowed = true;
            } else if (e.key === '.') {
                isKeyAllowed = true;
            } else if (new RegExp('^[0-9]$').test(e.key) === false) {
                isKeyAllowed = false;
            }
        }
        if (!isKeyAllowed) {
            e.preventDefault();
        }
    }

    @HostListener('paste', ['$event']) onPaste(e: ClipboardEvent) {
        const text = e.clipboardData.getData('text');
        if (new RegExp('^([0-9]+)(.[0-9]+)?$').test(text) === false) {
            e.preventDefault();
        }
    }
}

export class IMycurrencyDirectiveSetting {
    fractionSize: number;
}
