<div class="card card-search">
    <div class="card-body">
        <strong class="card-title">ค้นหาข้อมูลเพิ่มเติม</strong>
        <div class="search-data">
            <div *ngIf="activeList !== 'LEAD_BOOKING_SUMMARY'" class="form-row">
                <div class="col-6">
                    <div *ngIf="activeList === 'LEAD_LIST'" class="form-group row">
                        <label class="col-auto col-form-label large-label">ชื่อผู้ติดต่อ</label>
                        <div class="col">
                            <input type="text" [(ngModel)]="searchFilter.contact_name" class="form-control form-control-search" placeholder="กรอกชื่อผู้ติดต่อ">
                        </div>
                    </div>
                    <div *ngIf="activeList === 'LEAD_BOOKING_REQUEST'" class="form-group row">
                        <label class="col-auto col-form-label large-label">Agency</label>
                        <div class="col">
                            <app-searchable-dropdown
                                #agencyDropdown
                                [settings]="agencyDropdownSettings"
                                [items]="agencyDropdownItems"
                                (selected)="onSelectAgency($event)">
                            </app-searchable-dropdown> 
                        </div>
                    </div>
                </div>

                <div class="col-6">
                    <div class="form-group row">
                        <label class="col-auto col-form-label label-margin large-label">Tourcode</label>
                        <div class="col">
                        <input type="text" [(ngModel)]="searchFilter.tour_code" class="form-control form-control-search" placeholder="กรอกรหัส tourcode">
                        </div>
                    </div>
                </div>

                <div class="col-6">
                    <div class="form-group row">
                    <label class="col-auto col-form-label large-label">
                        {{activeList === "LEAD_LIST" ? 'ช่วงวันที่ติดต่อ' : 'ช่วงวันที่ส่งใบจอง'}}
                    </label>
                    <div class="col">
                        <div class="d-flex">
                            <div class="flex-grow-1">
                                <app-calendar-input
                                    class="rounded" 
                                    placeholder="เลือกวัน"
                                    [showDateInValue]=true
                                    [bsValue]="fieldStartDate"
                                    (bsValueChange)="onStartDateChange($event)"
                                    [bsConfig]="{
                                        dateInputFormat: 'DD MM YY',
                                        maxDate: fieldEndDate || ''
                                    }">
                                </app-calendar-input>
                            </div>
                            <div>
                                <label class="col-auto col-form-label small-label">ถึง</label>
                            </div>
                            <div class="flex-grow-1">
                                <app-calendar-input
                                    class="rounded" 
                                    placeholder="เลือกวัน"
                                    [showDateInValue]=true
                                    [bsValue]="fieldEndDate"
                                    (bsValueChange)="onEndDateChange($event)"
                                    [bsConfig]="{
                                        dateInputFormat: 'DD MM YY',
                                        minDate: fieldStartDate || ''
                                    }">
                                </app-calendar-input>
                            </div>
                            
                        </div>
                        
                    </div>
                    </div>
                </div>

                <div class="col-6">
                    <div *ngIf="activeList === 'LEAD_LIST'" class="form-group row">
                        <label class="col-auto col-form-label label-margin large-label">ช่องทางที่ติดต่อ</label>
                        <div class="col">
                            <app-searchable-dropdown
                                #communicationMethodDropdown
                                [settings]="communicationDropdownSettings"
                                [items]="communicationDropdownItems"
                                (selected)="onSelectCommunicationMethod($event)">
                            </app-searchable-dropdown> 
                        </div>
                    </div>
                    <div *ngIf="activeList === 'LEAD_BOOKING_REQUEST'" class="form-group row">
                        <label class="col-auto col-form-label label-margin large-label">ประเทศ</label>
                        <div class="col">
                            <app-searchable-dropdown
                                #countryDropdown
                                [settings]="countryDropdownSettings"
                                [items]="countryDropdownItems"
                                (selected)="onSelectCountry($event)">
                            </app-searchable-dropdown> 
                        </div>
                    </div>
                </div>
                
                <div class="col-10 bottom-columns">
                    <div class="form-group row">
                        <label class="col-auto col-form-label large-label">สถานะ</label>
                        <div class="col checkbox-container">
                            <div class="checkbox custom-control">
                                <input id="cbx1" type="checkbox" class="custom-control-input" [checked]="fieldStatusCheckbox.all" (change)="onCheckboxClick(true, 1)">
                                <label class="custom-control-label" for="cbx1">ทั้งหมด</label>
                            </div>
                            <div *ngIf="activeList === 'LEAD_LIST'" class="checkbox custom-control">
                                <input id="cbx2" type="checkbox" class="custom-control-input" [checked]="fieldStatusCheckbox.waiting_contact" (change)="onCheckboxClick(false, 2)">
                                <label class="custom-control-label" for="cbx2">รอติดต่อกลับ</label>
                            </div>
                            <div *ngIf="activeList === 'LEAD_LIST'" class="checkbox custom-control">
                                <input id="cbx3" type="checkbox" class="custom-control-input" [checked]="fieldStatusCheckbox.waiting_dicision" (change)="onCheckboxClick(false, 3)">
                                <label class="custom-control-label" for="cbx3">รอตัดสินใจ</label>
                            </div>
                            <div *ngIf="activeList === 'LEAD_BOOKING_REQUEST'" class="checkbox custom-control">
                                <input id="cbx3" type="checkbox" class="custom-control-input" [checked]="fieldStatusCheckbox.pending" (change)="onCheckboxClick(false, 4)">
                                <label class="custom-control-label" for="cbx3">รอดำเนินการ</label>
                            </div>
                            <div class="checkbox custom-control">
                                <input id="cbx4" type="checkbox" class="custom-control-input" [checked]="fieldStatusCheckbox.booked" (change)="onCheckboxClick(false, 5)">
                                <label class="custom-control-label" for="cbx4">{{activeList==="LEAD_LIST" ? 'จองแล้ว' : 'รับจองแล้ว'}}</label>
                            </div>
                            <div class="checkbox custom-control">
                                <input id="cbx5" type="checkbox" class="custom-control-input" [checked]="fieldStatusCheckbox.cancel" (change)="onCheckboxClick(false, 6)">
                                <label class="custom-control-label" for="cbx5">{{activeList==="LEAD_LIST" ? 'ยกเลิก' : 'ยกเลิกใบจอง'}}</label>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="col-2 bottom-columns">
                    <button class="col-8 btn btn-primary" (click)="submit()">ค้นหา</button>
                    <button class="col-4 btn btn-link" (click)="resetSearchFilter()">เริ่มใหม่</button>
                </div>
            </div>
            <div *ngIf="activeList === 'LEAD_BOOKING_SUMMARY'"  class="form-row mt-2">
                <div class="col-3 summary-page-start-date">
                    <div class="form-group row">
                        <label class="col-auto col-form-label large-label pt-0">
                            ช่วงวันที่ส่งใบจอง
                        </label>
                        <div class="col">
                            <app-calendar-input
                                class="rounded" 
                                placeholder="เลือกวัน"
                                [showDateInValue]=true
                                [bsValue]="fieldStartDate"
                                (bsValueChange)="onStartDateChange($event)"
                                [bsConfig]="{
                                    dateInputFormat: 'DD MM YY',
                                    maxDate: fieldEndDate || ''
                                }">
                            </app-calendar-input>
                        </div>
                    </div>
                </div>
                <div class="col-3 summary-page-end-date">
                    <div class="form-group row">
                        <label class="col-auto col-form-label small-label">ถึง</label>
                        <div class="col">
                            <app-calendar-input
                                class="rounded" 
                                placeholder="เลือกวัน"
                                [showDateInValue]=true
                                [bsValue]="fieldEndDate"
                                (bsValueChange)="onEndDateChange($event)"
                                [bsConfig]="{
                                    dateInputFormat: 'DD MM YY',
                                    minDate: fieldStartDate || ''
                                }">
                            </app-calendar-input>
                        </div>
                    </div>
                </div>
                <div class="col-6">
                    <div class="form-group row">
                        <label class="col-auto col-form-label label-margin large-label">Tourcode</label>
                        <div class="col">
                            <input type="text" [(ngModel)]="searchFilter.tour_code" class="form-control form-control-search" placeholder="กรอกรหัส tourcode">
                        </div>
                    </div>
                </div>
                <div class="col-6">
                    <div class="form-group row">
                        <label class="col-auto col-form-label large-label">ประเทศ</label>
                        <div class="col">
                            <app-searchable-dropdown
                                #countryDropdown
                                [settings]="countryDropdownSettings"
                                [items]="countryDropdownItems"
                                []
                                (selected)="onSelectCountry($event)">
                            </app-searchable-dropdown> 
                        </div>
                    </div>
                </div>
                <div [ngClass]="(activeList==='LEAD_BOOKING_SUMMARY')?'col-2':'col-4'"></div>
                <div [ngClass]="(activeList==='LEAD_BOOKING_SUMMARY')?'col-4':'col-2'" class="button-container mt-0">
                    <button class="col-8 btn btn-primary" (click)="submit()">ค้นหา</button>
                    <button class="col-4 btn btn-link" (click)="resetSearchFilter()">เริ่มใหม่</button>
                </div>
            </div>
        </div>
    </div>
</div>
