<div class="modal-header">
    <button type="button" class="close pull-right" aria-label="Close" (click)="bsModalRef.hide()">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body">
    <div class="product-owner-detail">
        <div class="title">รายละเอียดผู้ติดต่อ (เจ้าของโปรแกรมทัวร์)</div>
        <div class="company-detail">
            <div class="row">
                <div class="col-4">ชื่อบริษัท :</div>
                <div class="col-8">{{ productOwner?.name }}</div>
            </div>
            <div class="row">
                <div class="col-4">เบอร์โทรหลัก :</div>
                <div class="col-8">{{ productOwner?.telephoneNumber }}</div>
            </div>
            <div class="row">
                <div class="col-4">เบอร์โทรเพิ่มเติม :</div>
                <div class="col-8" style="white-space: pre" [innerHTML]="productOwner?.additionTelephoneNumber"></div>
            </div>
            <div class="row">
                <div class="col-4">อีเมล :</div>
                <div class="col-8">{{ productOwner?.email }}</div>
            </div>
            <div class="row">
                <div class="col-4">ที่อยู่ :</div>
                <div class="col-8">{{ productOwner?.address }}</div>
            </div>
            <div class="row">
                <div class="col-4">เลขที่ใบอนุญาตนำเที่ยว :</div>
                <div class="col-8">{{ productOwner?.licenseNumber }}</div>
            </div>
        </div>
    </div>
</div>
<div class="modal-footer">
    <div class="button-container">
        <button type="button" class="btn btn-secondary" (click)="bsModalRef.hide()">ปิด</button>
    </div>
</div>
