<div class="modal-header">
    <button type="button" class="close pull-right" aria-label="Close" (click)="bsModalRef.hide()">
        <span *ngIf="hasXButton" aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body">
    <div class="success-tick"></div>
    <h3>{{message || 'บันทึกข้อมูลเรียบร้อยแล้ว'}}</h3>
    <p class="description" *ngIf="description">
        {{description.text}}
        <a *ngIf="description.linkText" [routerLink]="[description.linkUrl || '/']">
            {{description.linkText}}
        </a>
    </p>
</div>
<div class="modal-footer">
    <button type="button" class="btn btn-default" (click)="bsModalRef.hide()">ปิด</button>
</div>