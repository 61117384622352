<div class="modal-header">
  <button type="button" class="close pull-right" aria-label="Close" (click)="bsModalRef.hide()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="text-center">
  <h4 class="modal-title">{{isEditBooking ? 'แก้ไขข้อมูล Booking' : 'เพิ่มข้อมูลใบจองจากลูกค้า'}}</h4>
  <small class="modal-title-detail" *ngIf="isEditBooking">(ข้อมูลภายในสำหรับ Agency เท่านั้น)</small>
</div>
<ng-container [formGroup]="addBookingForm">
  <div class="modal-body">
    <div class="row">
      <div class="col-6">
        <div class="form-group">
          <label class="col-auto col-form-label">
            ชื่อผู้ติดต่อ<span class="req">*</span>
          </label>
          <div class="col pr-0">
            <input type="text" class="form-control" [class.error]="validation.name" placeholder="กรุณากรอกชื่อผู้ติดต่อ"
              formControlName="name" maxlength="200">
          </div>
        </div>
      </div>
      <div class="col-6">
        <div class="form-group">
          <label class="col-form-label">
            เบอร์โทร<span class="req">*</span>
          </label>
          <div class="col pl-0">
            <input appOnlyNumber type="tel" class="form-control" [class.error]="validation.phone"
              placeholder="กรุณากรอกเบอร์โทร" formControlName="phone" maxlength="10">
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-6">
        <div class="form-group">
          <label class="col-auto col-form-label">
            ช่องทางการติดต่อ<span class="req">*</span>
          </label>
          <div class="dropdown-button-container col-auto pr-0">
            <div class="btn-group w-100" dropdown>
              <button id="button-basic" dropdownToggle type="button" class="btn btn-primary dropdown-toggle"
                aria-controls="dropdown-basic" [class.placeholder]="!fieldCommMethod"
                [class.error]="validation.commMethod">
                {{fieldCommMethod || "กรุณาเลือกช่องทาง"}} <span class="caret"></span>
              </button>
              <ul id="dropdown-basic" *dropdownMenu class="dropdown-menu w-100" role="menu"
                aria-labelledby="button-basic">
                <ng-container *ngFor="let method of COMM_METHODS">
                  <li role="menuitem"><span class="dropdown-item" (click)="setCommMethod(method)"
                      [class.active]="fieldCommMethod === method">
                      {{method}}
                    </span></li>
                </ng-container>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div class="col-6">
        <div class="form-group">
          <label class="col-form-label">
            อีเมล
          </label>
          <div class="col pl-0">
            <input type="email" class="form-control" [class.error]="validation.email" placeholder="กรุณากรอกอีเมล"
              formControlName="email" maxlength="100">
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <div class="form-group">
          <label class="col-auto col-form-label">
            ข้อมูลเพิ่มเติมจากลูกค้า
          </label>
          <div class="col">
            <textarea type="text" class="form-control" [class.error]="validation.additionalInfo"
              placeholder="กรุณากรอกข้อมูลเพิ่มเติม" formControlName="additionalInfo" maxlength="500">
                        </textarea>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <div class="form-group">
          <label class="col-auto col-form-label">
            Remark ภายใน
          </label>
          <div class="col">
            <textarea type="text" class="form-control" [class.error]="validation.remark"
              placeholder="กรุณากรอกข้อมูลเพิ่มเติม" formControlName="remark" maxlength="500">
                        </textarea>
          </div>
        </div>
      </div>
    </div>
    <hr class="dividing-line" [class.dashed]="leadStatus === 'confirm'" />
    <ng-container *ngIf="!isEditBooking">
      <div class="row">
        <div class="col-8 pr-0">
          <div class="form-group">
            <label class="col-auto col-form-label">
              โปรแกรมทัวร์ที่ลูกค้าสนใจ<span class="req">*</span>
            </label>
            <div class="col">
              <input type="text" class="form-control" [class.error]="validation.searchString"
                placeholder="กรอกรหัส tourcode" formControlName="searchString">
            </div>
          </div>
        </div>
        <div class="col-4 link-container pl-0">
          <a class="check-tour-link" (click)="checkTourButton()">ตรวจสอบข้อมูลทัวร์</a>
        </div>
      </div>
      <div *ngIf="tourProductData" class="tour-details col-auto">
        <p class="tour-code-id">
          <span>Tour code : </span>{{tourProductData.tour_code}}
        </p>
        <small class="font-weight-bold">{{tourProductData.name}}</small>
        <div class="row my-3">
          <div *ngIf="tourProductData.periods[0] && tourProductData.periods[0].date"
            class="col-6 dropdown-button-container long-dropdown">
            <div class="btn-group" dropdown>
              <button id="button-basic" dropdownToggle type="button" class="btn btn-primary dropdown-toggle"
                aria-controls="dropdown-basic" [class.placeholder]="!fieldTravelPeriod">
                {{fieldTravelPeriod || "เลือกพีเรียดที่สนใจ"}} <span class="caret"></span>
              </button>
              <ul id="dropdown-basic" *dropdownMenu class="dropdown-menu" role="menu" aria-labelledby="button-basic">
                <ng-container *ngFor="let period of tourProductData.periods">
                  <li role="menuitem"><span class="dropdown-item" (click)="setTravelPeriod(period)"
                      [class.active]="fieldTravelPeriod === period.date">
                      {{period.date}}
                    </span></li>
                </ng-container>
              </ul>
            </div>
          </div>
          <div class="col-6">
            <small class="starting-price">ราคาเริ่มต้น {{fieldProductPrice}} บ./คน</small>
          </div>
        </div>
      </div>
    </ng-container>
    <!-- <div *ngIf="leadStatus === 'confirm'" class="edit-booking-special-area">
      <div class="top-wrapper">
        <h4 class="title">ข้อมูล Booking สำหรับ Wholesale</h4>
        <small class="title-description">(ข้อมูลนี้จะถูกส่งไปให้ Wholesale)</small>
      </div>
      <div class="body">
        <div class="row">
          <div class="col-8">
            <div class="tour-details">
              <p class="title">Tour code :
                <span class="tourcode">
                  <span *ngIf="lead.tour_code">{{ agencyInfo.productCode | uppercase }}</span>
                  <span>-</span>
                  <span>{{lead.tour_code | uppercase}}</span>
                </span>
              </p>
              <p class="product-name">{{lead.product_name}}</p>
            </div>
            <div class="booking-details">
              <table>
                <tr>
                  <th>พีเรียด</th>
                  <th>ราคาเริ่มต้น/คน</th>
                  <th>{{lead.commissionLabel}}</th>
                  <th>จำนวนผู้เดินทาง</th>
                </tr>
                <tr>
                  <td>{{periodRange || '-'}}</td>
                  <td>{{(lead.price | number) || '-'}}</td>
                  <td>{{lead.commissionString || '-'}}</td>
                  <td>{{lead.number_of_passengers}}</td>
                </tr>
              </table>
              <div class="textarea-container">
                <label class="col-form-label">
                  ข้อมูลเพิ่มเติมที่ต้องการแจ้ง Wholesale
                </label>
                <p class="more-info-ws">
                  {{lead.more_info_for_ws || '-'}}
                </p>
              </div>
            </div>
          </div>
          <div class="col-4">
            <div class="owner-info">
              <p class="title">แจ้งการจองไปยัง :</p>
              <ng-container *ngIf="lead.owner; else noWsOwner">
                <p class="name name-en">{{lead.owner?.name_en}}</p>
                <p class="name name-th">({{lead.owner?.name_th}})</p>
              </ng-container>
              <ng-template #noWsOwner>
                <p class="name name-en">{{lead.agency_name_en}}</p>
                <p class="name name-th">({{lead.agency_name_th}})</p>
              </ng-template>

            </div>
            <div class="agency-info">
              <p class="agency-name"><span>ข้อมูลผู้แจ้ง : </span><span>{{agencyInfo.nameEN}}
                  ({{agencyInfo.nameTH}})</span></p>
              <p>เลขที่ใบอนุญาตนำเที่ยว {{agencyInfo.licenseNumber}}</p>
              <p>เซลล์ : {{user.firstName}} {{user.lastName}} {{user.nickName ? '(' + user.nickName + ")" : ''}}</p>
              <p>
                <span>โทร : {{agencyInfo.phoneNumber | phoneNumber}}</span>
                <span *ngIf="user.mobileNumber">, {{ user.mobileNumber }}</span>
              </p>
              <p class="agency-email">อีเมล : {{agencyInfo.email}}</p>
              <p class="agency-address"><span>ที่อยู่ : </span><span>{{agencyInfo.address}}</span></p>
            </div>
          </div>
        </div>
      </div>
    </div> -->
    <p class="error" *ngIf="validation.searchString">{{searchStringErrorMessage}}</p>
    <p class="error" *ngIf="validation.generalError">กรุณากรอกข้อมูลให้ครบถ้วน</p>
  </div>
  <div class="modal-footer">
    <div class="button-container">
      <button type="button" class="btn btn-primary" (click)="submitButton()">บันทึก </button>
      <button type="button" class="btn btn-default" (click)="bsModalRef.hide()">ยกเลิก</button>
    </div>
  </div>
</ng-container>
