<div class="modal-header">
    <button type="button" class="close pull-right" aria-label="Close" (click)="bsModalRef.hide()">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body">
    <div class="exclamation-mark"></div>
    <h3>กลับสู่หน้าการค้นหาเริ่มต้น</h3>
</div>
<div class="modal-footer">
    <div class="button-container">
        <button type="button" class="btn btn-default" (click)="confirm()">ยืนยัน</button>
        <button type="button" class="btn btn-default" (click)="bsModalRef.hide()">ยกเลิก</button>
    </div>
</div>