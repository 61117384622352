<div class="social-links">
    <button 
        (click)="popupLineShare()" 
        class="btn mr-1 py-1 line-link" 
        [style.font-size]="settings.fontSize"
        [style.width]="settings.buttonWidth"
        [style.height]="settings.buttonHeight"
        [style.padding-right]="settings.buttonXPadding"
        [style.padding-left]="settings.buttonXPadding"
    >
        <img 
            src="assets/img/icon/icon-line-white.svg" 
            [width]="settings.iconWidth" 
            [height]="settings.iconHeight"
        />
        <span class="ml-1">แชร์</span>
    </button>
    <button 
        (click)="popupFacebookShare()"
        class="btn mr-1 py-1 facebook-link"
        [style.font-size]="settings.fontSize"
        [style.width]="settings.buttonWidth"
        [style.height]="settings.buttonHeight"
        [style.padding-right]="settings.buttonXPadding"
        [style.padding-left]="settings.buttonXPadding"
    >
        <img 
            src="assets/img/icon/icon-facebook.jpg"
            [width]="settings.iconWidth" 
            [height]="settings.iconHeight"
        />
        <span class="ml-1">แชร์</span>
    </button>
    <button 
        (click)="copyLink()" 
        class="btn py-1 copy-link"
        [style.font-size]="settings.fontSize"
        [style.width]="settings.buttonWidth"
        [style.height]="settings.buttonHeight"
        [style.padding-right]="settings.buttonXPadding"
        [style.padding-left]="settings.buttonXPadding"
    >
        <img 
            src="assets/img/icon/icon-copylink.svg" 
            [width]="settings.iconWidth" 
            [height]="settings.iconHeight"
        />
        <span class="ml-1">Copy</span>
    </button>
</div>

<ng-template #CopyLinkSuccessModalTemplate>
    <div class="copy-link-success-modal">
        <button type="button" class="close" aria-label="Close" (click)="modalRef.hide()">
            <span aria-hidden="true">&times;</span>
        </button>
        <div class="p-5 text-center">
            <i class="icon-success"></i>
            <div class="my-3">Copy Link เรียบร้อยแล้ว</div>
            <button class="btn btn-outline btn-close" (click)="modalRef.hide()">ปิด</button>
        </div>
    </div>
</ng-template>