export interface IDateRange {
    minDate: string;
    maxDate: string;
}

export interface IOrderSummary {
    dateRange: IDateRange;
    sumQuantity: number;
    orderCount: number;
}

export interface IOrderCommissionSummary {
    dateRange: IDateRange;
    sumCommission: number;
    orderCount: number;
}

export class DateRange implements IDateRange {
    minDate: string;
    maxDate: string;

    deserialize(source: any) {
        this.minDate = source.min_date;
        this.maxDate = source.max_date;

        return this;
    }
}

export class OrderSummary implements IOrderSummary {
    dateRange: DateRange;
    sumQuantity: number;
    orderCount: number;

    deserialize(source: any) {
        this.dateRange = new DateRange();
        this.dateRange = this.dateRange.deserialize(source.date_range);
        this.sumQuantity = source.sum_quantity;
        this.orderCount = source.order_count;

        return this;
    }
}

export class OrderCommissionSummary implements IOrderCommissionSummary {
    dateRange: DateRange;
    sumCommission: number;
    orderCount: number;

    deserialize(source: any) {
        this.dateRange = new DateRange();
        this.dateRange = this.dateRange.deserialize(source.date_range);
        this.sumCommission = source.sum_commission;
        this.orderCount = source.order_count;
        return this;
    }
}
