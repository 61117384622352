<div class="modal-header">
    <button type="button" class="close pull-right" aria-label="Close" (click)="bsModalRef.hide()">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="top-wrapper">
        <h4 class="modal-title text-center">ยกเลิกข้อมูล Booking</h4>
        <small class="are-you-sure">คุณแน่ใจหรือไม่ว่าจะยกเลิกข้อมูล Booking นี้?</small>
</div>
<div class="modal-body">
    <div class="tour-details">
        <p class="desc">
            <ng-container *ngIf="lead.tour_code">
                <span>{{agencyInfo.productCode | uppercase}}</span>
                <span>-</span>
                <span>{{lead.tour_code | uppercase}}</span>
            </ng-container>
            {{lead.product_name}}
        </p>
        <p class="period-and-price">
            {{period}}
            <ng-container *ngIf="lead.price">ราคาเริ่มต้น {{this.lead.price}}</ng-container>
        </p>
        <p *ngIf="lead.commissionString">
            {{lead.commissionLabel}} {{lead.commissionString}}
        </p>
    </div>
    <div class="booking-details">
        <p class="customer-name">ลูกค้า : <span class="font-weight-bold">{{lead.customerDetails.contact_name || '-'}}</span></p>
        <p class="customer-name">จาก : <span class="font-weight-bold">
            {{LEAD_TYPE_SLUG[this.lead.lead_type_slug]}}
            <ng-container *ngIf="lead.number_of_passengers"> ({{lead.number_of_passengers}} คน)</ng-container>
            </span></p>
    </div>
</div>
<div class="modal-footer">
    <div class="button-container">
        <button type="button" class="btn btn-primary" (click)="submitButton()">ยืนยันยกเลิก Booking</button>
        <button type="button" class="btn btn-default" (click)="bsModalRef.hide()">ยกเลิก</button>
    </div>
</div>