<div class="modal-header">
    <button type="button" class="close pull-right" aria-label="Close" (click)="bsModalRef.hide()">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body">
    <div class="product-owner-detail">
        <div class="title">รายละเอียดลูกค้า</div>
        <div class="company-detail">
            <div class="row">
                <div class="col-5">ชื่อลูกค้า :</div>
                <div class="col-7">{{ customerInfo.name }}</div>
            </div>
            <div class="row">
                <div class="col-5">เบอร์โทร :</div>
                <div class="col-7">{{ customerInfo.phoneNumber }}</div>
            </div>
            <div class="row">
                <div class="col-5">อีเมล :</div>
                <div class="col-7">{{ customerInfo.email }}</div>
            </div>
            <div class="row">
                <div class="col-5">Facebook :</div>
                <div class="col-7">{{ customerInfo.facebook }}</div>
            </div>
            <div class="row">
                <div class="col-5">Line :</div>
                <div class="col-7">{{ customerInfo.line }}</div>
            </div>
            <div class="row">
                <div class="col-5">Instagram :</div>
                <div class="col-7">{{ customerInfo.instagram }}</div>
            </div>
            <div class="row">
                <div class="col-5">ความต้องการอื่น ๆ :</div>
                <div class="col-7">{{ customerInfo.remark }}</div>
            </div>
        </div>
    </div>
</div>
<div class="modal-footer">
    <div class="button-container">
        <button type="button" class="btn btn-secondary" (click)="bsModalRef.hide()">ปิด</button>
    </div>
</div>
