import { Component, Inject, OnDestroy, OnInit, PLATFORM_ID } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Observable } from 'rxjs/internal/Observable';
import { filter, take, takeUntil } from 'rxjs/operators';
import { IBreadcrumb } from 'src/app/models/breadcrumb.model';
import { State } from 'src/app/stores/reducers';
import { Order, OrderBalance } from '../../models/order-balance-report.model';
import * as ReportActions from 'src/app/stores/report-store/report.actions';
import { selectOrderBalance } from 'src/app/stores/report-store/report.selectors';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { ConfirmPaymentComponent } from '../../modals/order-balance/confirm-payment/confirm-payment.component';
import { ENTITY, ACTION_TYPE } from 'src/app/constants/event-log.constant';
import { eventLog } from 'src/app/stores/utility-store/utility.actions';
import { Router } from '@angular/router';
import { of, Subject } from 'rxjs';
import { SuccessModalComponent } from 'src/app/modules/shared/modals/success-modal/success-modal.component';
import { isPlatformBrowser } from '@angular/common';

@Component({
    selector: 'app-order-balance-report-page',
    templateUrl: './order-balance-report-page.component.html',
    styleUrls: ['./order-balance-report-page.component.scss'],
})
export class OrderBalanceReportPageComponent implements OnInit, OnDestroy {
    public breadcrumb: IBreadcrumb[];
    public commissionsPending$: Observable<any[]>;
    public commissionsComplete$: Observable<any[]>;
    public showFull = false;
    public orderBalance$: Observable<OrderBalance>;
    public bsModalRef: BsModalRef;
    private unsubscribe$: Subject<void> = new Subject<void>();

    constructor(
        @Inject(PLATFORM_ID) protected platformId: Object,
        private store: Store<State>,
        private modalService: BsModalService,
        private router: Router
    ) {}

    ngOnInit(): void {
        this.setBreadcrumb();
        this.getCommissions();
        this.store.dispatch(ReportActions.getOrderBalance());

        this.orderBalance$ = this.store.pipe(
            select(selectOrderBalance),
            filter((m) => !!m),
            takeUntil(this.unsubscribe$)
        );

        this.showConfirmPaymentModal();
    }

    ngOnDestroy(): void {
        this.store.dispatch(ReportActions.getOrderBalanceSuccess({ orderBalance: null }));
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
    }

    private showConfirmPaymentModal() {
        this.orderBalance$
            .pipe(
                filter((m) => !!m),
                takeUntil(this.unsubscribe$)
            )
            .subscribe((orderBalance) => {
                if (orderBalance.transfered.filter((m) => !m.confirmed).length >= 1) {
                    const initialState = {
                        order: orderBalance.transfered.filter((m) => !m.confirmed)[0],
                    };
                    this.bsModalRef = this.modalService.show(ConfirmPaymentComponent, {
                        initialState,
                        class: 'confirm-payment-modal',
                        ignoreBackdropClick: true,
                    });
                    this.modalService.onHidden.pipe(take(1), takeUntil(this.unsubscribe$)).subscribe((reason) => {
                        if (reason !== 'success') return;
                        const initialState = {
                            message: 'ยืนยันรับเงินสำเร็จ',
                            hasXButton: false,
                        };
                        this.bsModalRef = this.modalService.show(SuccessModalComponent, {
                            initialState,
                            class: 'success-modal',
                        });
                        this.modalService.onHidden.pipe(take(1), takeUntil(this.unsubscribe$)).subscribe(() => {
                            this.store.dispatch(ReportActions.getOrderBalance());
                        });
                    });
                }
            });
    }

    private getCommissions() {
        this.commissionsPending$ = of([{}]);

        this.commissionsComplete$ = of([{}]);
    }

    private setBreadcrumb(): void {
        this.breadcrumb = [
            { name: 'หน้าแรก', link: '/dashboard' },
            { name: 'สรุปรายการรับค่าคอม', link: '/report/order-balance-report' },
        ];
    }

    private eventLog(actionType: ACTION_TYPE, orderBalanceId: number): void {
        // Event Log
        this.store.dispatch(
            eventLog({
                entityName: ENTITY.AGENCY_ORDER_BALANCE,
                actionType: actionType,
                content: {
                    agcy_order_balance_id: orderBalanceId,
                },
            })
        );
    }

    public downloadDocument(order: Order): void {
        this.eventLog(ACTION_TYPE.DOWNLOAD_TRANSFER_DOCUMENT, order.id);
        if (isPlatformBrowser(this.platformId)) {
            window.open(order.transferDocFileUrl, '_blank');
        }
    }

    public linkToOrderList(linkString: string, order: Order): void {
        this.eventLog(ACTION_TYPE.VIEW_RECEIPT_ITEM_LSIT, order.id);
        this.router.navigate([linkString]);
    }
}
