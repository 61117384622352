export interface ILeadSummaryDetails {
    id: number;
    agencyProductCode: string;
    tourCode: string;
    name: string;
    imageUrl: string;
}

export class LeadSummaryDetails {
    private leadDetails: ILeadSummaryDetails[] = [];

    public deserialize(objList: any): ILeadSummaryDetails[] {
        objList.forEach((obj: any) => {
            this.leadDetails.push(this.deserializeCurrentLead(obj));
        });
        return this.leadDetails;
    }

    private deserializeCurrentLead(obj: any): ILeadSummaryDetails {
        const currentLead: ILeadSummaryDetails = {
            id: obj.id,
            agencyProductCode: obj.agency_product_code,
            tourCode: obj.tour_code,
            name: obj.name,
            imageUrl: obj.image_url,
        };

        return currentLead;
    }
}
