<div class="booking-list-component">
    <app-breadcrumb [items]="breadcrumb"></app-breadcrumb>

    <div class="d-flex justify-content-between">
        <h2 class="text-primary mb-4"><i class="icon icon-status-orange"></i>สถานะการจอง</h2>
        <a (click)="openModalForCreatingBooking()" class="btn btn-add">
            <i class="icon icon-plus-circle mr-1"></i> เพิ่มข้อมูล Booking
        </a>
    </div>

    <div class="filter-area">
        <div class="filter-body">
            <app-booking-list-tabs
                [activeTab]="activeTab"
                (activeTabEmitter)="changeActiveTab($event)"
                [(hideFilter)]="showAdvanceFilter"
            ></app-booking-list-tabs>
            <div *ngIf="showAdvanceFilter" class="m-4">
                <app-booking-advance-search
                    [presets]="advancedSearchPresets"
                    [activeTab]="activeTab"
                    (bookingSubmit)="doAdvanceSearch($event)"
                ></app-booking-advance-search>
            </div>
        </div>
        <div class="toggle-filter-link">
            <a [routerLink]="" (click)="toggleAdvanceFilter()">กดค้นหาข้อมูลเพิ่มเติม</a>
            <i class="icon" [ngClass]="showAdvanceFilter ? 'icon-arrow-up' : 'icon-arrow-down'"></i>
        </div>
    </div>
    <div class="listing-area">
        <ng-container *ngIf="activeTab === BOOKING_LIST_TABS.BOOKING_LIST">
            <app-booking-list-listing></app-booking-list-listing>
        </ng-container>
        <ng-container *ngIf="activeTab === BOOKING_LIST_TABS.SALES_REVENUE">
            <app-sale-report-listing
                (onClickViewMoreDetail)="focusedBookingListSearchFromSalesReportClick($event)"
            ></app-sale-report-listing>
        </ng-container>
        <ng-container *ngIf="activeTab === BOOKING_LIST_TABS.GROUP_PLAN">
            <app-group-plan (clickViewMoreDetail)="focusedBookingListSearchFromGroupPlanClick($event)"></app-group-plan>
        </ng-container>
        <ng-container *ngIf="activeTab === BOOKING_LIST_TABS.COMMISSION_REPORT">
            <app-commission-report
                (clickViewMoreDetail)="focusedBookingListSearchFromCommissionClick($event)"
            ></app-commission-report>
        </ng-container>
    </div>
</div>
