import { ISupplierOrderInstallment } from '../interfaces/tw-booking-interface/supplier-order-installment.interface';
import { InstallmentFile } from './Installment-file.model';

export class SupplierOrderInstallment {
    id!: number;
    ordinal!: number;
    amount!: number;
    dueDate!: Date;
    files!: InstallmentFile[];

    deserialize(data: ISupplierOrderInstallment): SupplierOrderInstallment {
        this.id = data.id;
        this.ordinal = data.ordinal;
        this.amount = data.amount;
        this.dueDate = new Date(data.due_date);
        this.files = data.files.map((file) => new InstallmentFile().deserialize(file, data.id, false));
        return this;
    }
}
