import { createFeatureSelector, createSelector } from '@ngrx/store';
import * as order from './order.reducer';
import { FEATURE_NAME } from './order-store.module';
import { BookingStatMonthly } from 'src/app/models/booking-stat-monthly.model';

export const OrderState = createFeatureSelector<order.OrderState>(FEATURE_NAME);

export const selectOrderDetail = createSelector(OrderState, (state: order.OrderState) => state.orderDetailResult);

export const selectNewBookingOrderId = createSelector(
    OrderState,
    (state: order.OrderState) => state.addBookingOrderNewId
);

export const selectBookingStatMonthly = createSelector(OrderState, (state: order.OrderState) =>
    state.bookingStatMonthly ? new BookingStatMonthly().deserialize(state.bookingStatMonthly) : null
);

export const selectBookingStatMonthlyDetails = createSelector(
    OrderState,
    (state: order.OrderState) => state.bookingStatMonthlyDetails
);

export const selectBookingListQueries = createSelector(
    OrderState,
    (state: order.OrderState) => state.bookingListQueries
);

export const selectBookingList = createSelector(OrderState, (state: order.OrderState) => state.bookingList);

export const selectBookingStatusCount = createSelector(
    OrderState,
    (state: order.OrderState) => state.bookingStatusCount
);

export const selectOrderSummaries = createSelector(OrderState, (state: order.OrderState) => state.orderSummaries);

export const selectOrderSummarySelect = createSelector(
    OrderState,
    (state: order.OrderState) => state.orderSummarySelect
);

export const selectOrderCommissionSummaries = createSelector(
    OrderState,
    (state: order.OrderState) => state.orderCommissionSummaries
);

export const selectOrderCommissionSummarySelect = createSelector(
    OrderState,
    (state: order.OrderState) => state.orderCommissionSummarySelect
);

export const selectGroupPlanListQueries = createSelector(
    OrderState,
    (state: order.OrderState) => state.groupPlanListQueries
);

export const selectGroupPlanList = createSelector(OrderState, (state: order.OrderState) => state.groupPlanList);

export const selectCommissionListQueries = createSelector(
    OrderState,
    (state: order.OrderState) => state.commissionReportListQueries
);

export const selectCommissionReportList = createSelector(
    OrderState,
    (state: order.OrderState) => state.commissionReportList
);

export const selectModifiedBookingId = createSelector(OrderState, (state: order.OrderState) => state.modifiedBookingId);

export const selectEditExistingBookingId = createSelector(
    OrderState,
    (state: order.OrderState) => state.editExistingBookingEditedId
);

export const selectBookingNewFile = createSelector(OrderState, (state: order.OrderState) => state.bookingNewFile);

export const selectProductList = createSelector(OrderState, (state: order.OrderState) => state.productList);

export const selectProductOwnerList = createSelector(OrderState, (state: order.OrderState) => state.productOwnerList);

export const selectSaleReportSummaryQueries = createSelector(
    OrderState,
    (state: order.OrderState) => state.saleReportSummaryQueries
);

export const selectSaleReportSummary = createSelector(OrderState, (state: order.OrderState) => state.saleReportSummary);

export const selectSaleReportListQueries = createSelector(
    OrderState,
    (state: order.OrderState) => state.saleReportListQueries
);

export const selectSaleReportListPaginated = createSelector(
    OrderState,
    (state: order.OrderState) => state.saleReportListPaginated
);

export const selectCampaignPaymentPaginated = createSelector(
    OrderState,
    (state: order.OrderState) => state.campaignPaymentPaginated
);

export const selectCampaignPaymentCount = createSelector(
    OrderState,
    (state: order.OrderState) => state.campaingPaymentCount
);

export const selectCountProductSubCategory = createSelector(
    OrderState,
    (state: order.OrderState) => state.countProductSubCategory
);

export const selectCountOrderStatus = createSelector(OrderState, (state: order.OrderState) => state.countOrderStatus);

export const selectProductOrderListPaginated = createSelector(
    OrderState,
    (state: order.OrderState) => state.productOrderListPaginated
);
