export const CONFIG_TAX = [
    {
        // ราคาสินค้าไม่รวมvat7% && ไม่ออกใบกำกับภาษี
        id: 1,
        name: '',
        isVat: false,
        isWithholdingTax: false,
        isTotalExcludeVat: false,
        isTotalIncludeWithholdingTax: false,
        canDisplayWithholdingTaxToInvoive: false,
    },
    {
        // ราคาสินค้าไม่รวมvat7% && ออกใบกำกับภาษีvat7%
        id: 2,
        name: 'ภาษีมูลค่าเพิ่ม 7%',
        isVat: true,
        isWithholdingTax: false,
        isTotalExcludeVat: false,
        isTotalIncludeWithholdingTax: false,
        canDisplayWithholdingTaxToInvoive: false,
    },
    {
        // ราคาสินค้าไม่รวมvat7% && ออกใบกำกับภาษีvat7%และหัก3%
        id: 3,
        name: 'ภาษีมูลค่าเพิ่ม 7% + ภาษี ณ ที่จ่าย 3%',
        isVat: true,
        isWithholdingTax: true,
        isTotalExcludeVat: false,
        isTotalIncludeWithholdingTax: true,
        canDisplayWithholdingTaxToInvoive: false,
    },
    {
        // ราคาสินค้ารวมvat7% && ออกใบกำกับภาษีvat7%
        id: 4,
        name: 'ราคารวมภาษีมูลค่าเพิ่ม 7%',
        isVat: true,
        isWithholdingTax: false,
        isTotalExcludeVat: true,
        isTotalIncludeWithholdingTax: false,
        canDisplayWithholdingTaxToInvoive: true,
    },
];
