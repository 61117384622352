import { ILeadSummaryStatistics } from '../../models/lead-summary-statistics.model';
import { ILeadSummaryDetails } from '../../models/lead-summary.model';
import { Component, OnInit } from '@angular/core';
import { ISearchFilter } from '../../models/search-filter.model';
import { Subject } from 'rxjs';
import { IListingPageDetails } from '../../models/listing-page-details.model';
import { Store, select } from '@ngrx/store';
import { State } from 'src/app/stores/reducers';
import {
    selectPageDetails,
    selectGetSearchFilter,
    selectReportLeadSummaryList,
    selectReportActiveProductCountData,
    selectReportLeadSummaryStatistics,
} from 'src/app/stores/report-store/report.selectors';
import { takeUntil, filter, take } from 'rxjs/operators';
import * as ReportActions from 'src/app/stores/report-store/report.actions';

@Component({
    selector: 'app-lead-summary-listing',
    templateUrl: './lead-summary-listing.component.html',
    styleUrls: ['./lead-summary-listing.component.scss'],
})
export class LeadSummaryListingComponent implements OnInit {
    private searchFilter: ISearchFilter;
    private unsubscribe$: Subject<void> = new Subject<void>();

    public leadSummaryList: ILeadSummaryDetails[];
    public leadSummaryStatistics: ILeadSummaryStatistics[];
    public countData: any;

    public listTotal: number = 0;
    public pageDetails: IListingPageDetails;

    constructor(private store: Store<State>) {}

    ngOnInit() {
        this.setInitialPageDetails();
        this.dispatchAndSelectGetLeadList();
        this.callInitialiseSelectors();
    }

    private dispatchAndSelectGetLeadList(nextPage?: boolean): void {
        if (!nextPage) {
            this.pageDetails.currentPage = 1;
        }
        this.store.dispatch(
            ReportActions.getLeadSummaryList({
                searchFilter: this.searchFilter,
                criteria: {
                    page: this.pageDetails.currentPage,
                    pageEntries: this.pageDetails.pageEntries,
                },
                nextPage: nextPage || null,
            })
        );

        this.store
            .pipe(
                select(selectReportLeadSummaryList),
                filter((m) => m.length > 0 && (!this.leadSummaryList || m.length != this.leadSummaryList.length)),
                takeUntil(this.unsubscribe$)
            )
            .subscribe((leadSummaryList) => {
                this.leadSummaryList = leadSummaryList;
                this.callSecondaryApis();
            });
    }

    private callSecondaryApis() {
        this.store.dispatch(
            ReportActions.getProductCountForSummary({
                searchFilter: this.searchFilter,
            })
        );

        this.store.dispatch(
            ReportActions.getStatisticsForProductSummary({
                idList: this.leadSummaryList.map((summary) => summary.id),
            })
        );

        this.store
            .pipe(
                select(selectReportActiveProductCountData),
                filter((m) => !!m),
                takeUntil(this.unsubscribe$)
            )
            .subscribe((countData) => {
                this.countData = countData;
            });

        this.store
            .pipe(
                select(selectReportLeadSummaryStatistics),
                filter((m) => !!m && m.length > 0),
                takeUntil(this.unsubscribe$)
            )
            .subscribe((leadSummaryStatistics) => {
                this.leadSummaryStatistics = leadSummaryStatistics;
            });
    }

    private callInitialiseSelectors(): void {
        this.store.pipe(select(selectPageDetails), takeUntil(this.unsubscribe$)).subscribe((pageDetails) => {
            const totalResults = pageDetails ? pageDetails.totalResults : 0;
            this.listTotal = totalResults || 0;
            this.pageDetails.maxPage = totalResults / this.pageDetails.pageEntries;
            this.pageDetails.currentPage = pageDetails ? pageDetails.page : 1;
        });

        this.store.pipe(select(selectGetSearchFilter), takeUntil(this.unsubscribe$)).subscribe((searchFilter) => {
            this.searchFilter = searchFilter;
        });
    }

    public setInitialPageDetails(): void {
        this.pageDetails = {
            currentPage: 1,
            maxPage: null,
            pageEntries: 15,
        };
    }

    nextPage(): void {
        this.pageDetails.currentPage++;
        this.dispatchAndSelectGetLeadList(true);
    }

    public getStatisticsForLead(id: number): ILeadSummaryStatistics {
        if (this.leadSummaryStatistics) {
            return this.leadSummaryStatistics.find((lead) => lead.id === id);
        }
        return null;
    }

    public showNextResultsValue(): number {
        const nextSearchBracket: number = (this.pageDetails.currentPage + 1) * this.pageDetails.pageEntries;
        if (nextSearchBracket < this.listTotal) {
            return nextSearchBracket;
        }
        return this.listTotal;
    }
}
