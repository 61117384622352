<div class="lead-listing head-group">
    <div class="row">
        <div class="col-6">
            <strong class="title mb-0">ใบจองจาก Agency ทั้งหมด {{listTotal}} รายการ</strong>
        </div>
    </div>

    <div class="booking-table">
        <div class="row table-header">
            <div class="col-2 d-flex">
                <div class="align-self-center">
                    <p>Agency</p>
                </div>
            </div>
            <div class="col-2 d-flex" style="white-space: pre;">
                <div class="align-self-center">
                    <p>วันที่ - เวลา</p>
                    <p>ส่งใบจอง</p>
                </div>
            </div>
            <div class="col-3 d-flex">
                <div class="align-self-center">
                    <p>ชื่อโปรแกรม</p>
                </div>
            </div>
            <div class="col-1 d-flex" style="white-space: pre;">
                <div class="align-self-center">
                    <p>จำนวน</p>
                    <p>ผู้เดินทาง</p>
                </div>
            </div>
            <div class="col-3 d-flex">
                <div class="align-self-center">
                    <p>สถานะใบจอง</p>
                </div>
            </div>
            <div class="col-1">
            </div>
        </div>
        <div *ngFor="let lead of leadList;let index = index">
            <app-lead-booking-request-details
                [lead]="lead"
                [index]="index + 1"
            ></app-lead-booking-request-details>
        </div>
    </div>

    <div class="text-center show-more-results" *ngIf="pageDetails.currentPage<pageDetails.maxPage">
        <button class="btn btn-show-more" (click)="nextPage()">
            แสดงรายการที่ {{(pageDetails.currentPage*pageDetails.pageEntries)+1}} - 
            {{showNextResultsValue()}}
        </button>
    </div>
</div>
