import { IInstallmentFile } from '../interfaces/tw-booking-interface/installment-file.interface';

export class InstallmentFile {
    id!: number;
    fileName!: string;
    fileType!: string;
    fileUrl!: string;
    createdAt!: Date;
    isCustomerInstallmentFile: boolean = false;
    installmentId!: number;

    public deserialize(
        data: IInstallmentFile,
        installmentId: number,
        isCustomerInstallmentFile: boolean = false
    ): InstallmentFile {
        this.id = data.id;
        this.fileName = data.file_name;
        this.fileType = data.file_type;
        this.fileUrl = data.file_url;
        this.createdAt = new Date(data.created_at);
        this.isCustomerInstallmentFile = isCustomerInstallmentFile;
        this.installmentId = installmentId;
        return this;
    }
}
