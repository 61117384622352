import { Component, EventEmitter, Input, Output } from '@angular/core';
import { IOrderBalanceReport } from 'src/app/models/order-balance-report.model';

@Component({
    selector: 'app-order-balance-report-listing',
    templateUrl: './order-balance-report-listing.component.html',
    styleUrls: ['./order-balance-report-listing.component.scss'],
})
export class OrderBalanceReportListingComponent {
    @Input() reportView: IOrderBalanceReport;
    @Output() requestMoreReports = new EventEmitter();

    get totalEndReports(): number {
        if (!this.reportView) {
            return 0;
        }
        return Math.min(this.reportView.total, this.reportView.data.length + this.reportView.pageEntries);
    }

    constructor() {}

    loadMoreReports(): void {
        this.requestMoreReports.emit();
    }
}
