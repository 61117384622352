import { HttpClient } from '@angular/common/http';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { Router } from '@angular/router';

import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';

// Models and Constants
import { WholesaleProduct } from '../models/wholesale-products/wholesale-product.model';
import { WholesaleProductPeriod } from '../models/wholesale-products/wholesale-product-period.model';
import { ENDPOINTS } from '../constants/endpoint.constant';

import { HttpService } from './base/http.service';

import { environment } from 'src/environments/environment';

@Injectable({
    providedIn: 'root',
})
export class WholesaleProductService extends HttpService {
    constructor(
        @Inject(PLATFORM_ID) protected platformId: Object,
        protected http: HttpClient,
        protected router: Router
    ) {
        super(platformId, http, router);
    }

    getProducts(countryId: number): Observable<WholesaleProduct[]> {
        const queryParams = '?' + this.getQueryString({ country_id: countryId });
        const url = environment.apiProUrl + ENDPOINTS.WS_PRODUCT.GET_PRODUCTS + queryParams;
        return this.get(url, true).pipe(
            map((apiResponse) => {
                if (apiResponse.status === 'success') {
                    return apiResponse.data.map((p) => new WholesaleProduct().deserialize(p));
                }

                return [];
            })
        );
    }

    getProductPeriods(productId: number): Observable<WholesaleProductPeriod[]> {
        const url = environment.apiProUrl + ENDPOINTS.WS_PRODUCT.GET_PRODUCT_PERIODS.replace('{product_id}', productId);
        return this.get(url, true).pipe(
            map((apiResponse) => {
                if (apiResponse.status === 'success') {
                    return apiResponse.data.map((p) => new WholesaleProductPeriod().deserialize(p));
                }

                return [];
            })
        );
    }
}
