export class ProductSubCategory {
    id: number;
    slug: string;
    nameTh: string;
    nameEn: string;

    public deserialize(data: any): this | null {
        if (!data) {
            return null;
        }

        this.id = +data.id;
        this.slug = data.slug;
        this.nameTh = data.name_th;
        this.nameEn = data.name_en;

        return this;
    }
}
