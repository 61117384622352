import { IAgencyMember } from '../interfaces/tw-booking-interface/agency-member.interface';

export class AgencyMember {
    id: number;
    firstName: string;
    nickName: string;

    public deserialize(data: IAgencyMember): AgencyMember {
        this.id = data.id;
        this.firstName = data.first_name;
        this.nickName = data.nick_name;
        return this;
    }
}
