import { Component, Inject, OnDestroy, OnInit, PLATFORM_ID } from '@angular/core';
import { Store } from '@ngrx/store';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { User } from 'src/app/models/user';
import { State } from 'src/app/stores/reducers';
import { selectUser } from 'src/app/stores/user-store/user.selectors';
import { Order } from '../../../models/order-balance-report.model';
import * as ReportActions from 'src/app/stores/report-store/report.actions';
import { filter, take, takeUntil } from 'rxjs/operators';
import { eventLog } from 'src/app/stores/utility-store/utility.actions';
import { ACTION_TYPE, ENTITY } from 'src/app/constants/event-log.constant';
import { NavigationEnd, Router } from '@angular/router';
import { Subject } from 'rxjs';
import { Actions, ofType } from '@ngrx/effects';
import { isPlatformBrowser } from '@angular/common';

@Component({
    selector: 'app-confirm-payment',
    templateUrl: './confirm-payment.component.html',
    styleUrls: ['./confirm-payment.component.scss'],
})
export class ConfirmPaymentComponent implements OnInit, OnDestroy {
    order: Order;
    isSeller: boolean;
    private unsubscribe$: Subject<void> = new Subject<void>();
    constructor(
        @Inject(PLATFORM_ID) protected platformId: Object,
        public bsModalRef: BsModalRef,
        public bsModalService: BsModalService,
        private store: Store<State>,
        private router: Router,
        private action$: Actions
    ) {}

    ngOnInit(): void {
        this.closeOnRouteChange();
        this.store.select(selectUser).subscribe((user: User) => {
            this.isSeller = user.roleSlug === 'sale' ? true : false;
        });
    }

    ngOnDestroy(): void {
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
    }

    private closeOnRouteChange(): void {
        this.router.events.pipe(takeUntil(this.unsubscribe$)).subscribe((evt) => {
            if (!(evt instanceof NavigationEnd)) {
                return;
            }
            this.bsModalRef.hide();
        });
    }

    public confirm(): void {
        if (this.isSeller) {
            alert('Sorry you are not authorize!!!');
            return;
        }

        this.store.dispatch(ReportActions.confirmOrderBalance({ orderId: this.order.id }));
        this.action$
            .pipe(
                ofType(ReportActions.confirmOrderBalanceSuccess),
                filter((action) => action.result),
                takeUntil(this.unsubscribe$)
            )
            .subscribe(() => {
                this.eventLog(ACTION_TYPE.CONFIRM);

                this.bsModalService.setDismissReason('success');
                this.bsModalRef.hide();
            });
    }

    public closeModal(): void {
        this.bsModalRef.hide();
    }

    private eventLog(actionType: ACTION_TYPE): void {
        // Event Log
        this.store.dispatch(
            eventLog({
                entityName: ENTITY.AGENCY_ORDER_BALANCE,
                actionType: actionType,
                content: {
                    agcy_order_balance_id: this.order.id,
                    agcy_order_balance_receipt_id: null,
                },
            })
        );
    }

    public downloadDocument(): void {
        this.eventLog(ACTION_TYPE.DOWNLOAD_TRANSFER_DOCUMENT);
        if (isPlatformBrowser(this.platformId)) {
            window.open(this.order.transferDocFileUrl, '_blank');
        }
    }

    public linkToOrderList(link: string): void {
        this.eventLog(ACTION_TYPE.VIEW_RECEIPT_ITEM_LSIT);
        this.router.navigate([link]);
    }
}
