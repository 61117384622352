import { Component, ViewChild } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { ViewManualPaymentComponent } from './templates/view-manual-payment/view-manual-payment.component';
import { FormManualPaymentComponent } from './templates/form-manual-payment/form-manual-payment.component';
import { TwBooking } from '../../models/tw-booking-model/tw-booking.model';
import { ThaiDatePipe, ThaiDateRangePipe } from 'src/app/pipes/thaidate.pipe';
import { CustomerOrderInstallment } from '../../models/tw-booking-model/customer-order-installment';
import { PaymentService } from 'src/app/services/payment.service';
import { IRequestPayloadPaymentNotification } from 'src/app/models/interfaces/request-payload-payment-notification.interfaace';
import { DateHelper } from 'src/app/helpers/date.helper';
import { Subject } from 'rxjs';

@Component({
    selector: 'app-manual-payment-modal',
    standalone: true,
    imports: [CommonModule, ViewManualPaymentComponent, FormManualPaymentComponent, ThaiDatePipe, ThaiDateRangePipe],
    templateUrl: './manual-payment-modal.component.html',
    styleUrls: ['./manual-payment-modal.component.scss'],
})
export class ManualPaymentModalComponent {
    @ViewChild('formManualPayment') formManualPayment: FormManualPaymentComponent;

    mode: 'form' | 'view' = 'form';
    booking!: TwBooking;
    installment!: CustomerOrderInstallment;
    isSubmit = false;
    formValid = false;

    isRequestPayloadNotificationComplete = new Subject<boolean>();
    isRequestCancelPaymentRequest = new Subject<number>();

    constructor(
        public bsModalRef: BsModalRef,
        public bsModalService: BsModalService,
        private paymentService: PaymentService
    ) {}

    onSubmit() {
        this.isSubmit = true;
        this.formValid = this.formManualPayment.paymentForm.valid;

        // Check validation attachment files.
        if (
            this.formManualPayment.f['method'].value === 'bank_transfer' &&
            this.formManualPayment.f['installments'].value.length === 0
        ) {
            this.formValid = false;
        }

        if (this.formValid) {
            let data = this.formManualPayment.paymentForm.getRawValue();
            let payload: IRequestPayloadPaymentNotification = {
                payment: {
                    method: data.method,
                    amount: +data.amount,
                },
                payment_event: {
                    issued_at: DateHelper.convertDateToHttpPlayload(data.paid_at, true), // Y-m-d H:i:s
                    remark: data.note,
                },
                event_file: {
                    ids: data.installments ? (data.installments as any[]).map((f) => f.id) : null,
                },
            };

            this.paymentService.postPaymentNotification(this.installment.id, payload).subscribe((response) => {
                if (response.status === 'success') {
                    this.isRequestPayloadNotificationComplete.next(true);
                    this.bsModalRef.hide();
                    return;
                }

                console.error(response.error);
            });
        }
    }

    onCancelPaymentRequest(id: number): void {
        this.isRequestCancelPaymentRequest.next(id);
        this.bsModalRef.hide();
    }
}
