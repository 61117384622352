import { Cell, Workbook, Worksheet } from 'exceljs';
import * as fs from 'file-saver';

export abstract class ExcelServiceBase {
    protected workbook: Workbook;
    protected rowIndex: number;

    protected readonly NumberFormat = '#,##0.00';

    //Create the workbook with first worksheet
    protected createWorksheet(worksheetName: string): Worksheet {
        this.workbook = new Workbook();
        const worksheet = this.workbook.addWorksheet(worksheetName);

        return worksheet;
    }

    //Generate & Save Excel File
    protected generateFile(filename: string): void {
        this.workbook.xlsx.writeBuffer().then((data) => {
            const blob = new Blob([data], {
                type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
            });
            fs.saveAs(blob, filename + '.xlsx');
        });
    }

    //Get cell by current row index
    protected getCell(worksheet: Worksheet, key: string): Cell {
        const currentRow = worksheet.getRow(this.rowIndex);
        return currentRow.getCell(key);
    }
}
