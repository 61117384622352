<div class="card mb-4">
    <div class="card-body">
        <div class="row">
            <div class="col-12 col-md-9">
                <div class="d-flex">
                    <div>
                        <label class="col-auto col-form-label small-label">ปี</label>
                    </div>
                    <div class="flex-1 mr-4">
                        <div class="w-auto" style="min-width: 172px;">
                            <app-searchable-dropdown
                                [settings]="yearDropdownSettings"
                                [items]="yearDropdownItems"
                                (selected)="selectedYearNumber = $event[0]; updatePeriodsView()"
                            ></app-searchable-dropdown>
                        </div>
                    </div>
                    <div>
                        <label class="col-auto col-form-label small-label">รอบการโอน</label>
                    </div>
                    <div class="flex-1">
                        <div class="w-auto" style="min-width: 232px;">
                            <app-searchable-dropdown
                                [settings]="periodDropdownSettings"
                                [items]="periodDropdownItems"
                                (selected)="selectedPeriodId = $event[0]; emitEventSelectedPeriod()"
                            ></app-searchable-dropdown>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-12 col-md-3">
                <div class="d-flex justify-content-end">
                    <button class="btn btn-primary mr-2" style="min-width: 179px" (click)="searchResults()">
                        ค้นหา
                    </button>
                </div>
            </div>
        </div>
    </div>
</div>