import { Component, EventEmitter, OnInit } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { IModalResponse } from '../../models/filters/product/interfaces/modal-response.interface';

@Component({
    selector: 'app-confirm-modal',
    templateUrl: './confirm-modal.component.html',
    styleUrls: ['./confirm-modal.component.scss'],
})
export class ConfirmModalComponent {
    title?: string;
    detail: string = '';
    closeBtnName?: string;
    confirmModalResponse: EventEmitter<IModalResponse<string>> = new EventEmitter();

    constructor(public bsModalRef: BsModalRef, public bsModalService: BsModalService) {}

    onSubmit() {
        this.confirmModalResponse.emit({ status: true });
        this.bsModalRef.hide();
    }

    onCancel() {
        this.confirmModalResponse.emit({ status: false });
        this.bsModalRef.hide();
    }
}
