import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { IOrderBalancePeriodItem } from 'src/app/models/order-balance-period.model';
import {
    SearchableDropdownItem,
    SearchableDropdownSettings,
} from 'src/app/modules/shared/components/searchable-dropdown/searchable-dropdown.component';
import { EnumOrderBalanceReportStatus } from './order-balance-report-status.enum';
import { EnumOrderBalanceReportTab } from './order-balance-report-tab.enum';
import * as moment from 'moment';

@Component({
    selector: 'app-order-balance-report-tab',
    templateUrl: './order-balance-report-tab.component.html',
    styleUrls: ['./order-balance-report-tab.component.scss'],
})
export class OrderBalanceReportTabComponent implements OnInit, OnChanges {
    activeTab = EnumOrderBalanceReportTab.Listing;
    tabs = EnumOrderBalanceReportTab;
    currentStatus = EnumOrderBalanceReportStatus.PendingTransfer;
    receiptStatus = EnumOrderBalanceReportStatus;
    daysUntilPayment: number = null;

    sortOptionDropdownSettings: SearchableDropdownSettings;
    sortOptionDropdownItems: SearchableDropdownItem[] = [];

    @Input() periodInfo: IOrderBalancePeriodItem;
    @Output() changeTab = new EventEmitter<EnumOrderBalanceReportTab>();
    @Output() changeSortOrder = new EventEmitter<string>();

    readonly EnumOrderBalanceReportStatus = EnumOrderBalanceReportStatus;

    get activeListingTab(): boolean {
        return this.activeTab === EnumOrderBalanceReportTab.Listing;
    }
    get activeReceiptTab(): boolean {
        return this.activeTab === EnumOrderBalanceReportTab.Receipt;
    }

    constructor() {}

    ngOnInit(): void {
        this.initDropdown();
        this.changeTab.emit(this.activeTab);
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.periodInfo.currentValue) {
            this.updateReceiptStatus();
        }
    }

    private initDropdown(): void {
        const sortOptionDropdownSettings = new SearchableDropdownSettings();
        sortOptionDropdownSettings.hideSearchBox = true;
        sortOptionDropdownSettings.customSearchIcon = '';
        sortOptionDropdownSettings.multipleSelection = false;
        sortOptionDropdownSettings.closeAfterSelect = true;
        sortOptionDropdownSettings.unableToUncheck = true;
        this.sortOptionDropdownSettings = sortOptionDropdownSettings;

        this.sortOptionDropdownItems = [
            new SearchableDropdownItem({
                id: 'partner_name_asc',
                name: 'เรียงตาม Seller',
                isChecked: false,
            }),
            new SearchableDropdownItem({
                id: 'order_created_at_desc',
                name: 'เรียงตาม Order ล่าสุด',
                isChecked: true,
            }),
        ];
    }

    onChangeTab(enumTab: EnumOrderBalanceReportTab): void {
        if (this.activeTab !== enumTab) {
            this.activeTab = enumTab;
            this.changeTab.emit(enumTab);
        }
    }

    emitSortOrderChange(): void {
        this.changeSortOrder.emit(this.sortOptionDropdownItems.find((item) => item.isChecked).id);
    }

    private updateReceiptStatus() {
        this.daysUntilPayment = null;
        if (this.periodInfo.orderBalance.confirmed) {
            this.currentStatus = EnumOrderBalanceReportStatus.TransferConfirmed;
        } else if (this.periodInfo.orderBalance.transfered) {
            this.currentStatus = EnumOrderBalanceReportStatus.TransferCompleted;
        } else {
            this.currentStatus = EnumOrderBalanceReportStatus.PendingTransfer;
            this.daysUntilPayment = moment(this.periodInfo.transferDueDate).diff(moment(), 'days') + 1;
        }
    }
}
