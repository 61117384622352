export class ProductListOptional {
    public name: string;
    public tourCode?: string;
    public isCustomProduct: boolean;
    public customProductOrderId?: number;
    public productPoolKey: string;

    public deserialize(obj: any): ProductListOptional {
        this.name = obj.name;
        this.tourCode = obj.tour_code;
        this.isCustomProduct = obj.is_custom_product;
        this.customProductOrderId = obj.custom_product_order_id;
        this.productPoolKey = obj.product_pool_key;
        return this;
    }
}
