<div class="card mb-4">
    <div class="card-body p-0">

        <div class="table-list-head">
            <p style="width: 20%">Order</p>
            <p style="width: 40%">Voucher</p>
            <p style="width: 10%">คอมรวม</p>
            <p style="width: 10%">Vat 7%</p>
            <p style="width: 10%" class="flex-column">
                <span>หัก ณ ที่จ่าย</span>
                <span class="text-xxs">(3% จากคอมรวม - Vat)</span>
            </p>
            <p style="width: 10%" class="flex-column align-items-start">
                <span>ค่าคอมที่ได้รับ</span>
                <span class="text-xxs">(คอมรวม - หัก ณ ที่จ่าย)</span>
            </p>
        </div>
        <div class="table-list-body">
            <app-order-balance-report-box *ngFor="let item of reportView?.data; let index = index"
                [runNumber]="index + 1" [item]="item"></app-order-balance-report-box>
        </div>

    </div>
</div>

<div class="text-center show-more-results" [hidden]="reportView?.total <= reportView?.data?.length">
    <button class="btn btn-show-more" (click)="loadMoreReports()">
        แสดงรายการที่ {{reportView?.data?.length + 1}} - {{ totalEndReports }}
    </button>
</div>
