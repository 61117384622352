import { ICommissionReportListQueries } from './../../modules/order/models/commission-report-list-queries';
import { IGroupPlanListQueries } from './../../modules/order/models/group-plan-list-queries';
import { IGroupPlanList } from '../../modules/order/models/group-plan-list.model';
import { Action, createReducer, on } from '@ngrx/store';
import { IBookingMonthlyReport } from 'src/app/models/booking-monthly-report.model';
import { BookingStatMonthly } from 'src/app/models/booking-stat-monthly.model';
import { DateRange, IOrderCommissionSummary, IOrderSummary } from 'src/app/models/order-summary.model';
import { OrderDetail } from 'src/app/models/order.model';
import { ProductListOptional } from 'src/app/modules/order/models/product-list.model';
import { ProductOwnerListOptional } from 'src/app/modules/order/models/product-owner-list.model';
import { IBookingListQueries, IBookingList } from 'src/app/modules/order/models/booking-list.model';
import { IBookingStatusCount } from 'src/app/modules/order/models/booking-status-count.model';
import {
    ISaleReportListPaginatedQueries,
    ISaleReportSummaryQueries,
    SaleReportListPaginated,
    SaleReportSummary,
} from 'src/app/modules/order/models/sale-report.model';
import * as OrderActions from './order.actions';
import { OrderState } from './order.selectors';
import { ICommissionReportList } from 'src/app/modules/order/models/commission-report-list.model';
import {
    ICampaignPaymentPaginated,
    ICampaignPaymentPaginatedFilter,
} from 'src/app/modules/order/models/campaign-payment.model';
import {
    CountOrderStatus,
    CountProductSubCategory,
    ProductOrderListPaginated,
} from 'src/app/modules/order/models/product-order-list.model';

export const orderFeatureKey = 'order';

export interface OrderState {
    orderDetailResult: OrderDetail;
    orderDetailError: any;
    addBookingOrderNewId: number;
    addBookingOrderError: any;
    bookingStatMonthly: BookingStatMonthly;
    bookingStatMonthlyError: any;
    bookingStatMonthlyDetails: IBookingMonthlyReport[];
    bookingStatMonthlyDetailsError: any;
    bookingListQueries: IBookingListQueries;
    bookingList: IBookingList;
    bookingListError: any;
    bookingStatusCount: IBookingStatusCount;
    bookingStatusCountError: any;
    deletedBookingFileId: number;
    deletedBookingFileError: any;
    modifiedBookingId: number;
    modifiedBookingError: any;
    bookingNewFile: { bookingId: number; id: number; url: string };
    bookingNewFileError: any;
    editExistingBookingEditedId: number;
    editExistingBookingError: any;
    productList: ProductListOptional[];
    productListError: any;
    productOwnerList: ProductOwnerListOptional[];
    productOwnerListError: any;
    saleReportSummaryQueries: ISaleReportSummaryQueries;
    saleReportSummary: SaleReportSummary[];
    saleReportListQueries: ISaleReportListPaginatedQueries;
    saleReportListPaginated: SaleReportListPaginated;
    orderSummaries: IOrderSummary[];
    orderSummariesError: any;
    orderSummarySelect: { filterType: string; dateRange: DateRange };
    groupPlanListQueries: IGroupPlanListQueries;
    groupPlanList: IGroupPlanList;
    groupPlanListError: any;
    orderCommissionSummaries: IOrderCommissionSummary[];
    orderCommissionSummariesError: any;
    orderCommissionSummarySelect: { orderCount: number; dateRange: DateRange };
    commissionReportListQueries: ICommissionReportListQueries;
    commissionReportList: ICommissionReportList;
    commissionReportListError: any;
    campaignPaymentPaginatedFilter: ICampaignPaymentPaginatedFilter;
    campaignPaymentPaginated: ICampaignPaymentPaginated;
    campaingPaymentCount: {
        all: number;
        available: number;
    };
    countProductSubCategory: CountProductSubCategory;
    countOrderStatus: CountOrderStatus;
    productOrderListPaginated: ProductOrderListPaginated;
}

export const initialState: OrderState = {
    orderDetailResult: null,
    orderDetailError: null,
    addBookingOrderNewId: null,
    addBookingOrderError: null,
    bookingStatMonthly: null,
    bookingStatMonthlyError: null,
    bookingStatMonthlyDetails: null,
    bookingStatMonthlyDetailsError: null,
    bookingListQueries: null,
    bookingList: null,
    bookingListError: null,
    bookingStatusCount: null,
    bookingStatusCountError: null,
    deletedBookingFileId: null,
    deletedBookingFileError: null,
    modifiedBookingId: null,
    modifiedBookingError: null,
    bookingNewFile: null,
    bookingNewFileError: null,
    editExistingBookingEditedId: null,
    editExistingBookingError: null,
    productList: null,
    productListError: null,
    productOwnerList: null,
    productOwnerListError: null,
    saleReportSummaryQueries: null,
    saleReportSummary: null,
    saleReportListQueries: null,
    saleReportListPaginated: null,
    orderSummaries: null,
    orderSummariesError: null,
    orderSummarySelect: null,
    groupPlanListQueries: null,
    groupPlanList: null,
    groupPlanListError: null,
    orderCommissionSummaries: null,
    orderCommissionSummariesError: null,
    orderCommissionSummarySelect: null,
    commissionReportListQueries: null,
    commissionReportList: null,
    commissionReportListError: null,
    campaignPaymentPaginatedFilter: null,
    campaignPaymentPaginated: null,
    campaingPaymentCount: null,
    countProductSubCategory: null,
    countOrderStatus: null,
    productOrderListPaginated: null,
};

export const orderReducer = createReducer(
    initialState,

    on(OrderActions.getOrderByIdSuccess, (state, { result }) => {
        return {
            ...state,
            orderDetailResult: result,
        };
    }),

    on(OrderActions.getOrderByIdError, (state, { error }) => {
        return {
            ...state,
            orderDetailError: error,
        };
    }),

    on(OrderActions.createNewBookingOrder, (state) => {
        return {
            ...state,
        };
    }),

    on(OrderActions.createNewBookingOrderSuccess, (state, { newOrderId }) => {
        return {
            ...state,
            addBookingOrderNewId: newOrderId,
        };
    }),

    on(OrderActions.createNewBookingOrderFailure, (state, { error }) => {
        return {
            ...state,
            addBookingOrderError: error,
        };
    }),

    on(OrderActions.getBookingStatMonthly, (state) => {
        return {
            ...state,
        };
    }),

    on(OrderActions.getBookingStatMonthlySuccess, (state, { bookingStatMonthly }) => {
        return {
            ...state,
            bookingStatMonthly: bookingStatMonthly,
        };
    }),

    on(OrderActions.getBookingStatMonthlyError, (state, { error }) => {
        return {
            ...state,
            bookingStatMonthlyError: error,
        };
    }),

    on(OrderActions.getBookingStatMonthlyDetails, (state) => {
        return {
            ...state,
        };
    }),

    on(OrderActions.getBookingStatMonthlyDetailsSuccess, (state, { bookingStatMonthlyDetails }) => {
        return {
            ...state,
            bookingStatMonthlyDetails: bookingStatMonthlyDetails,
        };
    }),

    on(OrderActions.getBookingStatMonthlyDetailsError, (state, { error }) => {
        return {
            ...state,
            bookingStatMonthlyDetailsError: error,
        };
    }),

    on(OrderActions.getBookingListPaginated, (state, { queries }) => {
        return {
            ...state,
            bookingListQueries: queries,
        };
    }),

    on(OrderActions.getBookingListPaginatedSuccess, (state, { bookingList }) => {
        return {
            ...state,
            bookingList,
        };
    }),

    on(OrderActions.getBookingListPaginatedError, (state, { error }) => {
        return {
            ...state,
            bookingListError: error,
        };
    }),

    on(OrderActions.getBookingStatusCount, (state) => {
        return {
            ...state,
        };
    }),

    on(OrderActions.getBookingStatusCountSuccess, (state, { bookingStatusCount }) => {
        return {
            ...state,
            bookingStatusCount,
        };
    }),

    on(OrderActions.getBookingStatusCountError, (state, { error }) => {
        return {
            ...state,
            bookingStatusCountError: error,
        };
    }),

    on(OrderActions.deleteBookingFile, (state) => {
        return {
            ...state,
        };
    }),

    on(OrderActions.deleteBookingFileSuccess, (state, { deletedBookingFile }) => {
        return {
            ...state,
            deletedBookingFileId: deletedBookingFile,
        };
    }),

    on(OrderActions.deleteBookingFileError, (state, { error }) => {
        return {
            ...state,
            deletedBookingFileError: error,
        };
    }),

    on(OrderActions.modifyBookingStatus, (state) => {
        return {
            ...state,
        };
    }),

    on(OrderActions.modifyBookingStatusSuccess, (state, { modifiedBookingId }) => {
        return {
            ...state,
            modifiedBookingId: modifiedBookingId,
        };
    }),

    on(OrderActions.modifyBookingStatusError, (state, { error }) => {
        return {
            ...state,
            modifiedBookingError: error,
        };
    }),

    on(OrderActions.bookingUploadFile, (state) => {
        return {
            ...state,
        };
    }),

    on(OrderActions.bookingUploadFileSuccess, (state, { bookingNewFile }) => {
        return {
            ...state,
            bookingNewFile,
        };
    }),

    on(OrderActions.bookingUploadFileError, (state, { error }) => {
        return {
            ...state,
            bookingNewFileError: error,
        };
    }),

    on(OrderActions.editExistingBooking, (state) => {
        return {
            ...state,
        };
    }),

    on(OrderActions.editExistingBookingSuccess, (state, { editedOrderId }) => {
        return {
            ...state,
            editExistingBookingEditedId: editedOrderId,
        };
    }),

    on(OrderActions.editExistingBookingFailure, (state, { error }) => {
        return {
            ...state,
            editExistingBookingError: error,
        };
    }),

    on(OrderActions.getProductList, (state) => {
        return {
            ...state,
        };
    }),

    on(OrderActions.getProductListSuccess, (state, { result }) => {
        return {
            ...state,
            productList: result,
        };
    }),

    on(OrderActions.getProductListError, (state, { error }) => {
        return {
            ...state,
            productListError: error,
        };
    }),

    on(OrderActions.getProductOwnerList, (state) => {
        return {
            ...state,
        };
    }),

    on(OrderActions.getProductOwnerListSuccess, (state, { result }) => {
        return {
            ...state,
            productOwnerList: result,
        };
    }),

    on(OrderActions.getProductOwnerListError, (state, { error }) => {
        return {
            ...state,
            productOwnerListError: error,
        };
    }),

    on(OrderActions.getSaleReportSummary, (state, { query }) => {
        return {
            ...state,
            saleReportSummaryQueries: query,
        };
    }),

    on(OrderActions.getSaleReportSummarySuccess, (state, { result }) => {
        return {
            ...state,
            saleReportSummary: result,
        };
    }),

    on(OrderActions.getSaleReportSummaryError, (state, { error }) => {
        return {
            ...state,
        };
    }),

    on(OrderActions.getSaleReportListPaginated, (state, { query }) => {
        return {
            ...state,
            saleReportListQueries: query,
        };
    }),

    on(OrderActions.getSaleReportListPaginatedSuccess, (state, { result }) => {
        return {
            ...state,
            saleReportListPaginated: result,
        };
    }),

    on(OrderActions.getSaleReportListPaginatedError, (state, { error }) => {
        return {
            ...state,
        };
    }),

    on(OrderActions.getOrderSummaries, (state) => {
        return {
            ...state,
        };
    }),

    on(OrderActions.getOrderSummariesSuccess, (state, { orderSummaries }) => {
        return {
            ...state,
            orderSummaries: orderSummaries,
        };
    }),

    on(OrderActions.getOrderSummariesFailure, (state, { error }) => {
        return {
            ...state,
            orderSummariesError: error,
        };
    }),

    on(OrderActions.selectOrderSummaries, (state, { orderSummarySelect }) => {
        return {
            ...state,
            orderSummarySelect: orderSummarySelect,
        };
    }),

    on(OrderActions.getGroupPlanListPaginated, (state, { groupPlanListQueries }) => {
        return {
            ...state,
            groupPlanListQueries: groupPlanListQueries,
        };
    }),

    on(OrderActions.getGroupPlanListPaginatedSuccess, (state, { groupPlanList, isNextPage }) => {
        let groupPlans = state.groupPlanList ? [...state.groupPlanList.groupPlans] : [];
        if (!isNextPage) {
            groupPlans = groupPlanList.groupPlans;
        } else {
            groupPlans.push(...groupPlanList.groupPlans);
        }

        return {
            ...state,
            groupPlanList: {
                page: groupPlanList.page,
                pageEntries: groupPlanList.pageEntries,
                total: groupPlanList.total,
                groupPlanStats: groupPlanList.groupPlanStats,
                groupPlans: groupPlans,
            },
        };
    }),

    on(OrderActions.getGroupPlanListPaginatedError, (state, { error }) => {
        return {
            ...state,
            groupPlanListError: error,
        };
    }),

    on(OrderActions.getOrderCommissionSummaries, (state) => {
        return {
            ...state,
        };
    }),

    on(OrderActions.getOrderCommissionSummariesSuccess, (state, { orderCommissionSummaries }) => {
        return {
            ...state,
            orderCommissionSummaries: orderCommissionSummaries,
        };
    }),

    on(OrderActions.getOrderCommissionSummariesFailure, (state, { error }) => {
        return {
            ...state,
            orderCommissionSummariesError: error,
        };
    }),

    on(OrderActions.selectOrderCommissionSummaries, (state, { orderCommissionSummarySelect }) => {
        return {
            ...state,
            orderCommissionSummarySelect: orderCommissionSummarySelect,
        };
    }),

    on(OrderActions.getCommissionReportListPaginated, (state, { commissionReportListQueries }) => {
        return {
            ...state,
            commissionReportListQueries: commissionReportListQueries,
        };
    }),

    on(OrderActions.getCommissionReportListPaginatedSuccess, (state, { commissionReportList, isNextPage }) => {
        let commissionReports = state.commissionReportList ? [...state.commissionReportList.commissionReports] : [];
        if (!isNextPage) {
            commissionReports = commissionReportList.commissionReports;
        } else {
            commissionReports.push(...commissionReportList.commissionReports);
        }

        return {
            ...state,
            commissionReportList: {
                page: commissionReportList.page,
                pageEntries: commissionReportList.pageEntries,
                total: commissionReportList.total,
                stats: commissionReportList.stats,
                commissionReports: commissionReports,
            },
        };
    }),

    on(OrderActions.getCommissionReportListPaginatedError, (state, { error }) => {
        return {
            ...state,
            commissionReportListError: error,
        };
    }),

    on(OrderActions.getCampaignPaymentPaginated, (state, { filter }) => {
        return {
            ...state,
            campaignPaymentPaginatedFilter: filter,
        };
    }),

    on(OrderActions.getCampaignPaymentPaginatedSuccess, (state, { paginated }) => {
        let items = state.campaignPaymentPaginated ? [...state.campaignPaymentPaginated.result] : [];
        if (paginated.page === 1) {
            items = paginated.result;
        } else {
            items.push(...paginated.result);
        }

        return {
            ...state,
            campaignPaymentPaginated: {
                page: paginated.page,
                pageEntries: paginated.pageEntries,
                total: paginated.total,
                result: items,
            },
        };
    }),

    on(OrderActions.getCampaignPaymentCountSuccess, (state, action) => {
        return {
            ...state,
            campaingPaymentCount: {
                all: action.all,
                available: action.available,
            },
        };
    }),

    on(OrderActions.getCountProductSubCategorySuccess, (state, action) => {
        return {
            ...state,
            countProductSubCategory: action.model,
        };
    }),

    on(OrderActions.getCountOrderStatusSuccess, (state, action) => {
        return {
            ...state,
            countOrderStatus: action.model,
        };
    }),

    on(OrderActions.getProductOrderListPaginatedSuccess, (state, action) => {
        let items = state.productOrderListPaginated ? [...state.productOrderListPaginated.result] : [];
        if (action.model.page === 1) {
            items = action.model.result;
        } else {
            items.push(...action.model.result);
        }

        action.model.result = items;

        return {
            ...state,
            productOrderListPaginated: action.model,
        };
    })
);

export function reducer(state: OrderState | undefined, action: Action) {
    return orderReducer(state, action);
}
