import { PhoneNumberPipe } from './../pipes/phone-number.pipe';
import { LEAD_STATUS_SLUG } from './../constants/lead-status.constant';
import { LEAD_TYPE_SLUG } from './../constants/communication-method.constant';
import * as moment from 'moment';
export interface ILeadMonthlyReport {
    runNumber: number;
    bookedDate: string;
    bookedTime: string;
    bookingChannelType: string;
    bookingStatus: string;
    tourCode: string;
    programName: string;
    periodStart: string;
    periodEnd: string;
    startAmount: number;
    companyCommission: number;
    salesCommission: number;
    productType: string;
    countryName: string;
    cityName: string;
    travelBy: string;
    airline: string;
    salesFullName: string;
    salesNickName: string;
    internalRemark: string;
    clientName: string;
    clientPhoneNumber: string;
    clientEmail: string;
    clientComment: string;
    companyName: string;
    companyPhoneNumber: string;
    revokedDate: string;
    revokedTime: string;
    revokedBy: string;
}

export class LeadMonthlyReport {
    private phoneNumberPipe: PhoneNumberPipe;

    public deserialize(obj: any[], phoneNumberPipe: PhoneNumberPipe): ILeadMonthlyReport[] {
        this.phoneNumberPipe = phoneNumberPipe;
        const leadMonthlyReport: ILeadMonthlyReport[] = [];
        obj.map((singleObj, index) => {
            leadMonthlyReport.push(this.deserializeSingle(singleObj, index + 1));
        });
        return leadMonthlyReport;
    }

    private deserializeSingle(obj: any, index: number): ILeadMonthlyReport {
        if (obj.lead_status_slug === 'confirm') {
            obj.lead_status_slug = 'booked';
        }
        const singleReport: ILeadMonthlyReport = {
            runNumber: index,
            bookedDate: obj.created_date,
            bookedTime: obj.created_time,
            bookingChannelType: LEAD_TYPE_SLUG[obj.lead_type_slug],
            bookingStatus: LEAD_STATUS_SLUG[obj.lead_status_slug],
            tourCode: obj.tour_code || '',
            programName: obj.program_name || '',
            periodStart: obj.periods ? moment(obj.periods.start_at).add(543, 'years').format('DD/MM/YYYY') : '',
            periodEnd: obj.periods ? moment(obj.periods.end_at).add(543, 'years').format('DD/MM/YYYY') : '',
            startAmount: obj.price ? parseInt(obj.price) : undefined,
            companyCommission: obj.commission_company ? parseInt(obj.commission_company) : undefined,
            salesCommission: obj.commission_seller ? parseInt(obj.commission_seller) : undefined,
            productType: CATEGORY_SUB_PRODUCTS[obj.subcategory_id],
            countryName:
                obj.subcategory_id && obj.subcategory_id !== 6 ? this.getLocationString(obj.destinations) : 'ไทย',
            cityName: obj.subcategory_id && obj.subcategory_id === 6 ? this.getLocationString(obj.destinations) : '',
            travelBy: obj.category_transport ? obj.category_transport.name : '',
            airline: obj.go_transport ? obj.go_transport.name_en + ' (' + obj.go_transport.code + ')' : '',
            salesFullName: this.getSalesFullName(obj.updated_by),
            salesNickName: obj.updated_by ? obj.updated_by.nick_name : '',
            internalRemark: obj.remark || '',
            clientName: obj.contact_name || '',
            clientPhoneNumber: obj.contact_number || '',
            clientEmail: obj.contact_email || '',
            clientComment: obj.contact_remarks || '',
            companyName: this.getCompanyName(obj.program_owner),
            companyPhoneNumber: this.getCompanyNumber(obj.program_owner),
            revokedDate: obj.lead_status_slug === 'cancel' ? obj.updated_date : '',
            revokedTime: obj.lead_status_slug === 'cancel' ? obj.updated_time : '',
            revokedBy: obj.lead_status_slug === 'cancel' ? this.getSalesFullName(obj.updated_by) : '',
        };

        return singleReport;
    }

    private getLocationString(destinations: any[]): string {
        let locationString = '';
        destinations.map((destination) => {
            if (locationString) {
                locationString += ',';
            }
            locationString += destination.name_th;
        });
        return locationString;
    }

    private getSalesFullName(updatedBy: any): string {
        if (updatedBy) {
            return (updatedBy.first_name || '') + ' ' + (updatedBy.last_name || '');
        } else {
            return '';
        }
    }

    private getCompanyName(programOwner: any): string {
        if (programOwner) {
            return (programOwner.name_en || '') + ' (' + (programOwner.name_th || '') + ')';
        } else {
            return '';
        }
    }

    private getCompanyNumber(programOwner: any): string {
        if (programOwner && Number(programOwner.office_telephone_number)) {
            if (Number(programOwner.office_telephone_number)) {
                return this.phoneNumberPipe.transform(programOwner.office_telephone_number);
            }
            return programOwner.office_telephone_number;
        } else {
            return '';
        }
    }
}

export const CATEGORY_SUB_PRODUCTS = {
    1: 'ทัวร์ต่างประเทศ (Join Tour)',
    2: 'แพ็กเกจทัวร์ต่างประเทศ (Package Tour)',
    6: 'ทัวร์ในประเทศ (Domestic)',
};
