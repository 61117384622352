<div class="campaign-orderlist-table">
    <table class="table campaign-orderlist-table">
        <thead class="campaign-orderlist-tableheader">
            <tr>
                <td class="campaign-orderlist-numberheader"></td>
                <td class="campaign-orderlist-orderheader">Order</td>
                <td class="campaign-orderlist-voucherheader">Voucher</td>
                <td class="campaign-orderlist-totalcommissionheader">คอมรวม</td>
                <td class="campaign-orderlist-quantityheader">จำนวน</td>
            </tr>
        </thead>
        <tbody class="campaign-orderlist-tablebody">
            <ng-container *ngFor="let item of paginated?.result; let i = index">
                <tr [ngClass]="{ emptyVoucher: item.voucher?.status !== 'available' }">
                    <td class="campaign-orderlist-orderbody1">{{ i + 1 }}.</td>
                    <td class="campaign-orderlist-orderbody2">
                        <div class="d-flex flex-column">
                            <strong>ลูกค้า : {{ item?.contactName }}</strong>
                            <span class="text-small">Tel : {{ item?.phoneNumber | phoneNumber }}</span>
                            <span class="text-small">
                                วันที่ซื้อ : {{ item?.order?.createdAt | thaiMonthDateTime: true }}
                            </span>
                            <span class="text-vv-small">(Order No. {{ item?.order?.orderCode }})</span>
                        </div>
                    </td>
                    <td class="campaign-orderlist-voucherbody">
                        <div class="d-flex flex-column">
                            <strong>{{ item?.voucher?.name }}</strong>
                            <strong>ราคา {{ item?.voucher?.price | mycurrency }} บาท / เวาเชอร์</strong>
                            <span class="text-small grey-text"
                                >{{ item?.campaign?.campaignCode }} {{ item?.campaign?.name }}</span
                            >
                            <span class="bluey-grey-text text-v-small">
                                วันหมดอายุเวาเชอร์
                                {{ item?.voucher?.voucherEndDate | thaiDate }}
                            </span>
                            <span class="bluey-grey-text text-v-small">
                                <span class="pointer" (click)="openPartnerContactModal(item)">
                                    <u>
                                        Seller : {{ item?.campaign?.shopName }} โทร.{{
                                            item?.campaign?.officeTelephoneNumber | phoneNumber
                                        }}
                                    </u>
                                    <i class="icon icon-info pl-1"></i>
                                </span>
                            </span>
                        </div>
                    </td>
                    <td class="campaign-orderlist-totalcommissionbody">
                        <strong>{{ item?.sumCommission | mycurrency }}</strong>
                    </td>
                    <td class="campaign-orderlist-quantitybody">
                        <div class="d-flex flex-column">
                            <strong>
                                <span>{{ item?.voucherTicketQuantity?.total }} เวาเชอร์ </span>
                                <span
                                    *ngIf="
                                        item.voucher?.status === 'available' &&
                                        item.hasUsableVoucherTickets &&
                                        item.voucherTicketQuantity.used
                                    "
                                >
                                    (เหลือ {{ item?.voucherTicketQuantity?.unused }})
                                </span>
                            </strong>
                            <strong class="text-small" *ngIf="item.voucher?.status === 'available'">
                                <span class="green-avaliable-text">รอใช้สิทธิ์</span>
                                <span *ngIf="item?.usageDate"> วันที่ {{ item?.usageDate | thaiDate }} </span>
                                <span *ngIf="!item?.usageDate">(ยังไม่ระบุวัน)</span>
                            </strong>
                            <strong class="text-small" *ngIf="item.voucher?.status === 'unavailable'"
                                >ใช้สิทธิ์ครบแล้ว</strong
                            >
                            <strong class="text-small" *ngIf="item.voucher?.status === 'expired'">หมดอายุ</strong>
                            <span class="text-small grey-text"
                                >ยอดรวม <strong>{{ item?.sumTotal | mycurrency }}</strong>
                            </span>
                            <span class="text-v-small mt-auto">
                                <span class="mr-2" *ngIf="item.voucher?.status === 'available'">
                                    <i class="icon icon-email mr-1"></i>
                                    <span class="bluey-grey-text">
                                        <a
                                            class="pointer"
                                            (click)="onPopupSendEmail(item?.id, item?.contactName, item?.email)"
                                            ><u>ส่งอีกครั้ง</u></a
                                        >
                                    </span>
                                </span>
                                <span
                                    class="mr-2"
                                    *ngIf="displayBookingDetailLink(item)"
                                    (click)="openCampaignCruiseBookingModal(item)"
                                >
                                    <span class="bluey-grey-text">
                                        <a class="pointer"><u>ระบุข้อมูลการจอง</u></a>
                                    </span>
                                </span>
                                <span
                                    class="mr-2"
                                    *ngIf="displayConfirmBookingLink(item)"
                                    (click)="openCampaignCruiseBookingInfoModal(item)"
                                >
                                    <i class="icon icon-clock mr-1 orange-text"></i>
                                    <span class="bluey-grey-text">
                                        <a class="pointer"><u class="orange-text">รอยืนยันการจอง</u></a>
                                    </span>
                                </span>
                                <span
                                    class="mr-2"
                                    *ngIf="displayBookingSuccessLink(item)"
                                    (click)="openCampaignCruiseBookingInfoModal(item)"
                                >
                                    <i class="icon icon-check-mark-green mr-1"></i>
                                    <span class="bluey-grey-text">
                                        <a class="pointer"><u class="green-avaliable-text">ยืนยันการจองสำเร็จ</u></a>
                                    </span>
                                </span>
                            </span>
                        </div>
                    </td>
                </tr>
            </ng-container>
        </tbody>
    </table>
</div>
