import { DateHelper } from 'src/app/helpers/date.helper';
import { Country } from 'src/app/models/country';
import { CountrySubUnit } from 'src/app/models/country-sub-unit.model';
import { ProductSubCategory } from 'src/app/models/product-sub-category.model';
import { Province } from 'src/app/models/province';
import { SeoArticleType } from './seo-article-type.model';
import { SpecialPage } from './special-page.model';
import { TargetPageType } from './target-page-type.model';
import * as _ from 'lodash-es';
import { SEO_TARGET_PAGE_TYPE_IDS } from 'src/app/constants/seo-target-page-types.constant';

export class SeoArticleListItem {
    id: number;
    code: string;
    name: string;
    isActive: boolean;
    hasContent: boolean;
    urlPath: string;
    primaryKeyword: string;
    secondaryKeyword: string;
    articleType: SeoArticleType;
    targetPageType: TargetPageType;
    country: Country;
    province: Province;
    countrySubUnit: CountrySubUnit;
    productSubCategory: ProductSubCategory;
    specialPage: SpecialPage;
    updatedAt: Date;
    updatedByAgencyMember: {
        id: number;
        firstName: string;
        lastName: string;
    };

    childArticles: SeoArticleListItem[];
    typeLabel: string;
    subTypeLabel: string;
    nodeInfo: INodeInfo = null;

    public deserialize(
        data: any,
        options: {
            maxNesting: number;
            nodeInfo: INodeInfo;
        }
    ): this {
        this.id = data.id;
        this.code = data.code;
        this.name = data.name;
        this.isActive = data.is_active;
        this.hasContent = data.has_content;
        this.urlPath = (data.url_path as string)?.replace(/^\//, '');
        this.primaryKeyword = data.primary_keyword;
        this.secondaryKeyword = data.secondary_keyword;
        this.articleType = new SeoArticleType().deserialize(data.article_type);
        this.targetPageType = data.target_page_type ? new TargetPageType().deserialize(data.target_page_type) : null;
        this.country = data.country ? new Country().deserialize(data.country) : null;
        this.province = data.province ? new Province().deserialize(data.province) : null;
        this.countrySubUnit = data.country_sub_unit ? new CountrySubUnit().deserialize(data.country_sub_unit) : null;
        this.productSubCategory = data.product_sub_category
            ? new ProductSubCategory().deserialize(data.product_sub_category)
            : null;
        this.specialPage = data.special_page ? new SpecialPage().deserialize(data.special_page) : null;
        this.updatedAt = DateHelper.convertStringToDate(data.updated_at);
        this.updatedByAgencyMember = {
            id: data.updated_by_agency_member.id,
            firstName: data.updated_by_agency_member.first_name,
            lastName: data.updated_by_agency_member.last_name,
        };

        if (this.targetPageType) {
            this.typeLabel = this.targetPageType.name;
        } else {
            this.typeLabel = options.nodeInfo.parentNodeInfo?.typeLabel;
        }

        switch (this.targetPageType?.id) {
            case SEO_TARGET_PAGE_TYPE_IDS.tour:
                this.subTypeLabel = this.country.nameTh;
                break;
            case SEO_TARGET_PAGE_TYPE_IDS.voucher:
                this.subTypeLabel = this.productSubCategory.nameTh;
                break;
            case SEO_TARGET_PAGE_TYPE_IDS.special_page:
                this.subTypeLabel = this.specialPage.name;
                break;
            case SEO_TARGET_PAGE_TYPE_IDS.chaophraya_cruise:
                this.subTypeLabel = 'ล่องเรือเจ้าพระยา';
                break;
            default:
                this.subTypeLabel = options.nodeInfo.parentNodeInfo?.subTypeLabel;
                break;
        }
        this.nodeInfo = { ...options.nodeInfo, typeLabel: this.typeLabel, subTypeLabel: this.subTypeLabel };

        if (options.maxNesting <= 0 || data.child_articles.length === 0) {
            this.childArticles = [];
            this.nodeInfo.hasChildren = false;
        } else {
            this.nodeInfo.hasChildren = true;
            this.childArticles = SeoArticleListItem.deserializeMany(data.child_articles, {
                maxNesting: options.maxNesting - 1,
                parentNodeInfo: this.nodeInfo,
            });
        }

        return this;
    }

    public static deserializeMany(
        dataList: any[],
        options: { maxNesting: number; parentNodeInfo?: INodeInfo }
    ): SeoArticleListItem[] {
        const articles = dataList.map((data, index) => {
            return new SeoArticleListItem().deserialize(data, {
                maxNesting: options.maxNesting,
                nodeInfo: {
                    isFirst: index === 0,
                    isLast: index === dataList.length - 1,
                    parentNodeInfo: options.parentNodeInfo,
                },
            });
        });
        return articles;
    }
}

export interface INodeInfo {
    isFirst: boolean;
    isLast: boolean;
    hasChildren?: boolean;
    typeLabel?: string;
    subTypeLabel?: string;
    parentNodeInfo?: INodeInfo;
}
