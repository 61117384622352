<div class="lead-summary-details">
    <div class="top-container">
        <img src="{{lead.imageUrl}}" alt="{{lead.name + ' (product image)'}}">
        <div class="right-side d-flex">
            <p class="product-name">{{lead.name}}</p>
            <div class="bottom">
                <div class="latest-booking">
                    <label>ใบจองล่าสุด</label>
                    <p>{{leadStatistics ? ((leadStatistics.lastCreatedAt | thaiMonthDateTime: true) || '-') : '-'}}</p>
                </div>
                <a class="cursor-pointer" (click)="redirectToBookingPage()">ดูข้อมูล</a>
            </div>
        </div>
    </div>
    <hr class="dividing-line" />
    <div class="bottom-container row">
        <div class="col-3 stat">
            <label>ทั้งหมด</label>
            <p>{{leadStatistics ? leadStatistics.totalCount : ""}}</p>
        </div>
        <div class="col-3 stat">
            <label>รอดำเนินการ</label>
            <p>{{leadStatistics ? leadStatistics.pendingCount : ""}}</p>
        </div>
        <div class="col-3 stat">
            <label>รับจองแล้ว</label>
            <p>{{leadStatistics ? leadStatistics.confirmCount : ""}}</p>
        </div>
        <div class="col-3 stat cancel">
            <label>ยกเลิก</label>
            <p>{{leadStatistics ? leadStatistics.cancelCount : ""}}</p>
        </div>
    </div>
</div>
