export const ENDPOINTS: any = {
    AUTHEN: {
        LOGIN: 'auth/login',
        LOGOUT: 'auth/logout',
    },
    AGENCY_DETAILS: {
        GET_INFO: 'agcy/info',
        GET_USERS_LIST: 'agcy/users',
        GET_PARTNERS_LIST: 'agcy/partners/options',
    },
    ABOUT_US: {
        DETAILS: 'agcy/about-us',
    },
    BANKS: {
        GET: 'banks/list',
    },
    CONFIG_BANNERS: {
        GET: 'agcy/configs/banners',
        PUT: 'agcy/configs/banners',
    },
    PRODUCT: {
        GET: 'agcy/products/{product_id}',
        UPDATE_JOIN: 'agcy/products/{product_id}/program_tours/join_tours',
        CREATE_JOIN: 'agcy/products/program_tours/join_tours',
        UPDATE_DOMESTIC: 'agcy/products/{product_id}/program_tours/package_domestic',
        CREATE_DOMESTIC: 'agcy/products/program_tours/package_domestic',
        UPDATE_PACKAGE: 'agcy/products/{product_id}/program_tours/package',
        CREATE_PACKAGE: 'agcy/products/program_tours/package',
        MONTH: 'agcy/months/options',
        LISTING: 'agcy/products/list',
        VIEW: 'agcy/products/{product_id}',
        DELETE: 'agcy/products/delete',
        COUNT: 'agcy/productpool/products/count',
        POOL_LISTING: 'agcy/productpool/products/list',
        POOL_VIEW: 'agcy/productpool/products/{product_id}',
        POOL_VIEW_BY_POOL_KEY: 'agcy/productpool/products/pool_key/{pool_key}',
        POOL_RECOMMENDATION: 'agcy/productpool/products/{product_pool_id}/recommendation',
        POOL_DATA_FROM_SEARCH_STRING: 'agcy/productpool/products/search/{search_data}',
        NAME_LISTING: 'agcy/products/list/optional',
        POOL_NAME_LISTING: 'agcy/productpool/products/list/optional',
        PDF_DETAIL_FILE: {
            URL: 'agcy/products/{product_id}/pdf_detail_file/url',
            BASE64: 'agcy/products/{product_id}/pdf_detail_file/base64',
        },
        OWNER: 'agcy/productpool/owners/{owner_channel}/{owner_id}',
    },
    WS_PRODUCT: {
        GET_PRODUCTS: 'agcy/ws_products',
        GET_PRODUCT_PERIODS: 'agcy/ws_products/{product_id}/periods',
    },
    PRODUCT_POOL: {
        MONTHS: 'agcy/productpool/products/months',
        PDF_DETAIL_FILE: 'agcy/productpool/products/{pool_key}/pdf_detail_file',
    },
    PRODUCT_SUB_CATEGORY: {
        GET_LIST: 'product_sub_categories',
    },
    AGENCY_PRODUCT: {
        GET_DOCUMENT_FILE_BASE_64: 'agcy/products/{product_id}/file/document',
        GET_PDF_FILE_BASE_64: 'agcy/products/{product_id}/file/pdf',
    },
    CATEGORY_TRANSPORT: {
        GET_ALL: 'category_transports',
    },
    COUNTRY: {
        GET_ALL: 'countries',
        GET: 'agcy/products/count',
    },
    CONTINENTS: {
        COUNTRIES: 'continents/groups/countries',
    },
    PROVINCE: {
        GET_ALL: 'provinces',
    },
    REGIONS: {
        PROVINCES: 'regions/provinces',
    },
    COUNTRY_SUB_UNITS: {
        GET_ALL: 'country_sub_units',
    },
    REPORT: {
        LEAD_LIST: 'agcy/leads/list/paginated',
        ADD_LEAD: 'agcy/leads',
        EDIT_LEAD: 'agcy/leads/{lead_id}',
        LEAD_COUNT: 'agcy/leads/count',
        LEAD_COUNT_BY_TYPE: 'agcy/leads/count/type',
        UPDATE_LEAD_STATUS: 'agcy/leads/{lead_id}/status',
        BOOKING_REQUEST_LEAD_LIST: 'agcy/leads/b2b/list/paginated',
        LEAD_BOOKING_REQUEST_COUNT: '/agcy/leads/b2b/count',
        UPDATE_BOOKING_REQUEST_LEAD_STATUS: 'agcy/leads/b2b/{lead_id}/status',
        ADD_BOOKING_REQUEST_LEAD: 'agcy/leads/b2b',
        BOOKING_REQUEST_LEAD_DETAILS: '/agcy/leads/b2b/{lead_id}',
        SUMMARY: {
            LEAD_COUNT_FOR_ACTIVE_PRODUCTS: 'agcy/leads/b2b/count/product_active',
            STATISTICS_FOR_PRODUCT_LIST: 'agcy/leads/b2b/statistic_for_product',
            PRODUCTS_LIST: 'agcy/products/list/paginated/summarised_for_lead',
        },
        LEAD_STAT_MONTHLY: 'agcy/leads/stats/monthly',
        LEAD_STAT_MONTHLY_DETAILS: 'agcy/leads/stats/monthly/details',
        ORDER_BALANCE_PERIOD: 'agcy/order_balances/periods',
        ORDER_BALANCE_REPORT: 'agcy/order_balances/{balance_id}/receipts/paginated',
        ORDER_BALANCE_REPORT_ITEMS: 'agcy/order_balances/{balance_id}/receipts/items/paginated',
        ORDER_BALANCE: 'agcy/order_balances',
        ORDER_BALANCE_CONFIRM: 'agcy/order_balances/{order_balance_id}/confirm',
        ORDER_BALANCE_COMMISSION_REPOT: '/agcy/order_balances/{balance_id}/commission_report',
        GET_BALANCE_RECEIPT_URI: '/agcy/order_balances/{balance_id}/receipts/{receipt_id}/receipt_doc',
    },
    GALLERY: {
        UPLOAD: 'agcy/galleries/file',
        GET_FILES: 'agcy/galleries/{galleries_id}/file',
        DELETE: 'agcy/galleries/{galleries_id}/file/{file_id}',
    },
    TRANSPORTATION: {
        GET_ALL: 'transportations',
    },
    CUSTOM_CONTENT: {
        GET: 'agcy/custom_contents/{content_id}',
        PUT: 'agcy/custom_contents/{content_id}',
    },
    PORTFOLIO: {
        LIST: 'agcy/portfolios/listing/paginated',
        SINGLE_ITEM: 'agcy/portfolios/{portfolio_id}',
        ADD_ITEM: 'agcy/portfolios',
        INCENTIVES: 'agcy/incentives',
    },
    BLOG: {
        LIST: 'agcy/blogs/listing/paginated',
        SINGLE_ITEM: 'agcy/blogs/{blog_id}',
        ADD_ITEM: 'agcy/blogs',
    },
    ORDER: {
        SINGLE_ITEM: 'agcy/orders/{order_id}',
        CREATE_NEW_BOOKING_ORDER: 'agcy/orders',
        BOOKING_LIST_PAGINATED: 'agcy/orders/list/paginated',
        BOOKING_STATUS_COUNT: 'agcy/orders/count',
        DELETE_BOOKING_FILE: 'agcy/orders/{order_id}/files/{order_file_id}',
        BOOKING_STAT_MONTHLY: 'agcy/orders/stats/monthly',
        BOOKING_STAT_MONTHLY_DETAILS: 'agcy/orders/stats/monthly/details',
        BOOKING_MODIFY_STATUS: 'agcy/orders/{order_id}/status',
        BOOKING_UPLOAD_FILE: 'agcy/orders/{order_id}/files',
        BOOKING_PRODUCT_LIST: 'agcy/orders/products/list/optional',
        BOOKING_PRODUCTOWNER_LIST: 'agcy/orders/products/owners/list/optional',
        BOOKING_SALE_REPORT_SUMMARY: 'agcy/orders/summaries/price',
        BOOKING_SALE_REPORT_LIST_PAGINATED: 'agcy/orders/list/paginated/format/sale_report',
        ORDER_SUMMARIES: 'agcy/orders/summaries/quantity',
        ORDER_COMMISSION_SUMMARIES: 'agcy/orders/summaries/commission',
        GROUP_PLAN_LIST_PAGINATED: 'agcy/orders/list/paginated/format/group_plan',
        COMMISSION_REPORT_LIST_PAGINATED: 'agcy/orders/list/paginated/format/commission_report',
    },
    INVOICE: {
        CONFIGS: 'agcy/invoice_configs',
    },
    CAMPAIGN: {
        GET: 'agcy/campaign_pool/campaigns/{campaign_id}',
        GET_LIST: 'agcy/campaign_pool/campaigns',
        GET_LIST_PAGINATED: 'agcy/campaign_pool/campaigns/paginated',
        GET_HILIGHT: 'agcy/campaign_pool/campaigns/{campaign_id}/highlight',
        DOWNLOAD_BANNER_IMAGE: 'agcy/campaigns/image_files/download',
    },
    CAMPAIGN_PAYMENT: {
        GET_LIST_PAGINATED: 'agcy/campaigns/payments/list/paginated',
        GET_COUNT: 'agcy/campaigns/payments/count',
        SEND_EMAIL: 'agcy/campaigns/payments/{payment_id}/send-email',
    },
    LOG: {
        WEB_EVENT_LOG: 'agcy/web_event_logs',
    },
    CHAOPHRAYA_CRUISE_BOOKINGS: {
        POST: 'agcy/chaophraya_cruise_bookings',
        GET: 'agcy/chaophraya_cruise_bookings/{booking_id}',
    },
    PRODUCT_ORDER: {
        GET_COUNT_PRODUCT_SUB_CATEGORY: 'agcy/products/orders/count/per_product_sub_category',
        GET_COUNT_ORDER_STATUS: 'agcy/products/orders/count/per_order_status',
        GET_ORDER_LIST_PAGINATED: 'agcy/products/orders/paginated',
    },
    SEO_ARTICLE: {
        GET_LIST: 'agcy/seo_articles',
        GET: 'agcy/seo_articles',
        NEW: 'agcy/seo_articles',
        UPDATE: 'agcy/seo_articles/{article_id}',
        UPDATE_CONTENT: '/agcy/seo_articles/{article_id}/content',
        UPDATE_STATUS: 'agcy/seo_articles/{article_id}/status',
        GET_ALL_AS_OPTIONS: 'agcy/seo_articles/options',
    },
    SEO_TARGET_PAGE_TYPE: {
        GET_LIST: 'agcy/seo_target_page_types',
    },
    SEO_SPECIAL_PAGE: {
        GET_LIST: 'agcy/seo_special_pages',
    },
    TOURWOW_ORDER: {
        NEW_ORDER: 'agcy/tw_orders',
        GET_ORDER: 'agcy/tw_orders/{order_code}',
        UPDATE_ORDER: 'agcy/tw_orders/{order_code}',
        UPDATE_ORDER_CUSTOMER_INFO: '/agcy/tw_orders/{order_code}/customer_info',
        UPDATE_ORDER_SUPPLIER_INSTALLMENT: '/agcy/tw_orders/{order_code}/supplier_order_installments',

        GET_LIST_PAGINATED: 'agcy/tw_orders/paginated',
        UPDATE_STATUS: 'agcy/tw_orders/{order_code}/status',
        UPDATE_PIN: 'agcy/tw_orders/{order_code}/pin',
        GET_NOTE: 'agcy/tw_orders/{order_code}/note',
        UPDATE_NOTE: 'agcy/tw_orders/{order_code}/note',
        UPLOAD_INVOICE_FILE: 'agcy/tw_orders/{order_code}/files',
        DELETE_INVOICE_FILE: 'agcy/tw_orders/{order_code}/files/{order_file_id}',
        UPLOAD_CUSTOMER_INSTALLMENT_FILE:
            'agcy/tw_orders/{order_code}/customer_order_installments/{customer_order_installment_id}/files',
        DELETE_CUSTOMER_INSTALLMENT_FILE:
            'agcy/tw_orders/{order_code}/customer_order_installments/{customer_order_installment_id}/files/{customer_order_installment_file_id}',
        DELETE_SUPPLIER_INSTALLMENT_FILE:
            'agcy/tw_orders/{order_code}/supplier_order_installments/{supplier_order_installment_id}/files/{supplier_order_installment_file_id}',
        GET_PDF: 'agcy/tw_orders/{order_code}/receipt',
    },
    WORK_LIST: {
        GET: 'agcy/tw_orders/{order_code}/tasks',
        PUT: 'agcy/tw_orders/{order_code}/tasks',
    },
    AIRPORT: {
        GET: 'airports',
    },
};
