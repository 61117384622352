import { IDeserializable } from 'src/app/models/interfaces/deserializable';

// start for implementation model
export class BannerConfigs implements IBannerConfigs, IDeserializable {
    domain: string;
    banner1: BannerInfo;
    banner2: BannerInfo;
    banner3: BannerInfo;

    constructor() {
        this.banner1 = new BannerInfo();
        this.banner2 = new BannerInfo();
        this.banner3 = new BannerInfo();
    }

    deserialize(source: any) {
        if (source) {
            this.domain = source.domain;
            source = source.banners;
            this.banner1 = new BannerInfo().deserialize({
                banner: source.advertise_image_1_url,
                bannerMobile: source.advertise_image_mobile_1_url,
                link: source.advertise_1_link_url,
            } as IBannerDeserializerResponse);
            this.banner2 = new BannerInfo().deserialize({
                banner: source.advertise_image_2_url,
                bannerMobile: source.advertise_image_mobile_2_url,
                link: source.advertise_2_link_url,
            } as IBannerDeserializerResponse);
            this.banner3 = new BannerInfo().deserialize({
                banner: source.advertise_image_3_url,
                bannerMobile: source.advertise_image_mobile_3_url,
                link: source.advertise_3_link_url,
            } as IBannerDeserializerResponse);
            return this;
        }
    }
}

export class BannerInfo implements IBannerInfo, IDeserializable {
    desktop: BannerImage;
    mobile: BannerImage;
    link: string;
    isValid: boolean;

    constructor() {
        this.desktop = new BannerImage();
        this.mobile = new BannerImage();
    }

    deserialize(source: IBannerDeserializerResponse) {
        this.desktop = new BannerImage().deserialize(source.banner);
        this.mobile = new BannerImage().deserialize(source.bannerMobile);
        this.link = source.link;
        return this;
    }
}

export class BannerImage implements IBannerImage, IDeserializable {
    base64: string;
    fileSizeAsMb: number;
    fileUrl: string;
    hasBanner: boolean;
    isValid: boolean;

    deserialize(source: any) {
        this.fileUrl = source;
        return this;
    }
}

export class BannerUpdaterRequest implements IBannerUpdaterRequest, IDeserializable {
    advertise_banner_1: IBannerInfoUpdaterRequest;
    advertise_banner_2: IBannerInfoUpdaterRequest;
    advertise_banner_3: IBannerInfoUpdaterRequest;

    deserialize(source: IBannerConfigs) {
        this.advertise_banner_1 = {
            desktop_image_file: {
                base64_data: source.banner1.desktop.base64,
                is_same_file: source.banner1.desktop.base64 == null && source.banner1.desktop.fileUrl != null,
            },
            mobile_image_file: {
                base64_data: source.banner1.mobile.base64,
                is_same_file: source.banner1.mobile.base64 == null && source.banner1.mobile.fileUrl != null,
            },
            link_url: source.banner1.link,
        };

        this.advertise_banner_2 = {
            desktop_image_file: {
                base64_data: source.banner2.desktop.base64,
                is_same_file: source.banner2.desktop.base64 == null && source.banner2.desktop.fileUrl != null,
            },
            mobile_image_file: {
                base64_data: source.banner2.mobile.base64,
                is_same_file: source.banner2.mobile.base64 == null && source.banner2.mobile.fileUrl != null,
            },
            link_url: source.banner2.link,
        };

        this.advertise_banner_3 = {
            desktop_image_file: {
                base64_data: source.banner3.desktop.base64,
                is_same_file: source.banner3.desktop.base64 == null && source.banner3.desktop.fileUrl != null,
            },
            mobile_image_file: {
                base64_data: source.banner3.mobile.base64,
                is_same_file: source.banner3.mobile.base64 == null && source.banner3.mobile.fileUrl != null,
            },
            link_url: source.banner3.link,
        };

        return this;
    }
}
// end for implementation model

// start for interface of view model
export interface IBannerConfigs {
    banner1: BannerInfo;
    banner2: BannerInfo;
    banner3: BannerInfo;
}

export interface IBannerInfo {
    desktop: BannerImage;
    mobile: BannerImage;
    link: string;
}

export interface IBannerImage {
    base64: string;
    fileSizeAsMb: number;
    fileUrl: string;
}
// end for interface of view model

// start for interface of http request model
export interface IBannerDeserializerResponse {
    banner: string;
    bannerMobile: string;
    link: string;
}

export interface IBannerUpdaterRequest {
    advertise_banner_1: IBannerInfoUpdaterRequest;
    advertise_banner_2: IBannerInfoUpdaterRequest;
    advertise_banner_3: IBannerInfoUpdaterRequest;
}

export interface IBannerInfoUpdaterRequest {
    desktop_image_file: IBannerImageUpdaterRequest;
    mobile_image_file: IBannerImageUpdaterRequest;
    link_url: string;
}

export interface IBannerImageUpdaterRequest {
    base64_data: string;
    is_same_file: boolean;
}
// end for interface of http request model
