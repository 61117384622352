<div class="modal-header">
    <h4 id="dialog-sizes-name2" class="modal-title pull-left">ข้อมูลชำระเงิน (Wholesale)</h4>
</div>
<div class="modal-body">
    <div class="payment-detail">
        <div class="head d-flex justify-content-between">
            <span>ข้อมูล Order</span>
            <span>สำหรับ Wholesale</span>
        </div>
        <!--  -->
        <div *ngIf="adultDoubleBed && sku.adultDoubleBed > 0" class="d-flex justify-content-between mt-2 px-4">
            <span class="sku">ผู้ใหญ่ พักคู่</span>
            <span>{{ adultDoubleBed.price | currency: 'THB':'' }} x {{ sku.adultDoubleBed }}</span>
        </div>
        <div *ngIf="adultSingleBed && sku.adultSingleBed > 0" class="d-flex justify-content-between mt-2 px-4">
            <span class="sku">ผู้ใหญ่ พักเดี่ยว</span>
            <span>{{ adultSingleBed.price | currency: 'THB':'' }} x {{ sku.adultSingleBed }}</span>
        </div>
        <div *ngIf="childNoBed && sku.childNoBed > 0" class="d-flex justify-content-between mt-2 px-4">
            <span class="sku">เด็กไม่มีเตียง</span>
            <span>{{ childNoBed.price | currency: 'THB':'' }} x {{ sku.childNoBed }}</span>
        </div>
        <div *ngIf="adultTripleBed && sku.adultTripleBed > 0" class="d-flex justify-content-between mt-2 px-4">
            <span class="sku">ผู้ใหญ่ พักสาม</span>
            <span>{{ adultTripleBed.price | currency: 'THB':'' }} x {{ sku.adultTripleBed }}</span>
        </div>
        <div *ngIf="childWithBed && sku.childWithBed > 0" class="d-flex justify-content-between mt-2 px-4">
            <span class="sku">เด็กมีเตียง</span>
            <span>{{ childWithBed.price | currency: 'THB':'' }} x {{ sku.childWithBed }}</span>
        </div>
        <div *ngIf="infant && sku.infant > 0" class="d-flex justify-content-between mt-2 px-4">
            <span class="sku">ทารก</span>
            <span>{{ infant.price | currency: 'THB':'' }} x {{ sku.infant }}</span>
        </div>
        <div *ngIf="joinLand && sku.joinLand > 0" class="d-flex justify-content-between mt-2 px-4">
            <span class="sku">จอยแลนด์</span>
            <span>{{ joinLand.price | currency: 'THB':'' }} x {{ sku.joinLand }}</span>
        </div>
        <!--  -->
        <div *ngIf="companyCommission" class="d-flex justify-content-between mt-2 px-4">
            <span class="sku">ค่าคอมบริษัท</span>
            <span>-{{ companyCommission | currency: 'THB':'' }} x {{ allSKUQuantity }}</span>
        </div>
        <div *ngIf="saleCommission" class="d-flex justify-content-between mt-2 px-4">
            <span class="sku">ค่าคอมเซลล์</span>
            <span>-{{ saleCommission | currency: 'THB':'' }} x {{ allSKUQuantity }}</span>
        </div>
        <!--  -->
        <div class="total d-flex justify-content-between">
            <span>จำนวนเงินจ่ายสุทธิ (Total)</span>
            <span>{{ installmentList.totalRealPaid | currency: 'THB':'' }}</span>
        </div>
    </div>
    <!--  -->
    <div class="payment-installments">
        <div class="title mb-2">ข้อมูลงวดชำระเงิน</div>
        <div class="d-flex justify-content-between">
            <div class="installments">
                <div class="title mb-1">กรุณาเลือกงวดชำระเงิน</div>
                <div class="form-check" *ngFor="let item of [].constructor(3); let i = index">
                    <input
                        [(ngModel)]="installmentSelector"
                        (change)="onChangeInstallment()"
                        class="form-check-input"
                        type="radio"
                        name="installmentSelector"
                        id="installmentSelector{{ i + 1 }}"
                        [value]="i + 1"
                    />
                    <label class="form-check-label" for="installmentSelector{{ i + 1 }}"> {{ i + 1 }} งวด </label>
                </div>
            </div>
            <div class="installments-data text-right">
                <app-installment-item
                    [(installment)]="installmentList.installments[0]"
                    (changePrice)="calculateInstallments()"
                ></app-installment-item>
                <app-installment-item
                    [(installment)]="installmentList.installments[1]"
                    (changePrice)="calculateInstallments()"
                ></app-installment-item>
                <app-installment-item
                    [(installment)]="installmentList.installments[2]"
                    (changePrice)="calculateInstallments()"
                ></app-installment-item>
                <div class="total">
                    รวม <span>{{ installmentList.totalRealPaid | number: '1.2-2' }}</span> บาท
                </div>
            </div>
        </div>
    </div>
    <!--  -->
    <div class="button-wrapper">
        <ng-container *ngIf="mode === MODE.ADD">
            <button (click)="onClickCloseButton()" class="btn btn-close">ปิด</button>
        </ng-container>
        <ng-container *ngIf="mode === MODE.VIEW || mode === MODE.EDIT">
            <button (click)="onClickUpdateButton()" class="btn btn-save">บันทึก</button>
            <button (click)="onClickCancelButton()" class="btn btn-cancel">ยกเลิก</button>
        </ng-container>
    </div>
</div>
