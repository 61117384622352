import { SearchableDropdownItem } from '../modules/shared/components/searchable-dropdown/searchable-dropdown.component';
import { IBankAcountType } from './bank-acount-type.model';
import { IBank } from './bank.model';
import { IDeserializable } from './interfaces/deserializable';

export class InvoiceConfigs implements IInvoiceConfigs, IDeserializable {
    documentName: string;
    companyNameEnglish: string;
    companyNameThai: string;
    address: string;
    phoneNumber: string;
    emailAddress: string;
    licenseNumber: string;
    approverName: string;
    paymentCondition: string;
    logoUrl: string;
    logoUpdate?: boolean = false;
    logoBase64: string;
    bankAccounts: InvoiceBankAccount[];

    public deserialize(source: any) {
        this.documentName = source.document_name;
        this.companyNameEnglish = source.company_name_en;
        this.companyNameThai = source.company_name_th;
        this.address = source.address;
        this.phoneNumber = source.phone_number;
        this.emailAddress = source.email;
        this.licenseNumber = source.license_number;
        this.approverName = source.approver_name;
        this.paymentCondition = source.payment_condition;
        this.logoUrl = source.logo_url;
        this.logoBase64 = source.logo_base64;
        this.bankAccounts = source.invoice_config_bank_accounts.map((x) => new InvoiceBankAccount().deserialize(x));
        return this;
    }

    public serialize(): any {
        return {
            document_name: this.documentName,
            company_name_en: this.companyNameEnglish,
            company_name_th: this.companyNameThai,
            address: this.address,
            phone_number: this.phoneNumber,
            email: this.emailAddress,
            license_number: this.licenseNumber,
            approver_name: this.approverName,
            payment_condition: this.paymentCondition,
            logo_file: {
                is_same_file: !this.logoUpdate,
                base64_data: this.logoBase64,
            },
            invoice_config_bank_accounts: this.bankAccounts.map((x: InvoiceBankAccount) => x.serialize()),
        };
    }

    public validate(): InvoiceConfigsValidity {
        const validity = new InvoiceConfigsValidity();
        validity.documentName = !!this.documentName && this.documentName.trim() !== '';
        validity.companyNameEnglish = !!this.companyNameEnglish && this.companyNameEnglish.trim() !== '';
        validity.companyNameThai = !!this.companyNameThai && this.companyNameThai.trim() !== '';
        validity.address = !!this.address && this.address.trim() !== '';
        validity.phoneNumber = !!this.phoneNumber && this.phoneNumber.trim() !== '';
        validity.emailAddress = !!this.emailAddress && this.emailAddress.trim() !== '';
        validity.licenseNumber = !!this.licenseNumber && this.licenseNumber.trim() !== '';
        validity.logo = !!this.logoUrl || !!this.logoBase64;
        return validity;
    }
}

export class InvoiceBankAccount implements IInvoiceBankAccount, IDeserializable {
    id: number = null;
    name: string = null;
    bankId: number = null;
    branch: string = null;
    accountName: string = null;
    accountNumber: string = null;
    accountTypeId: number = null;
    accountType: string = null;
    toUpdate?: boolean = false;

    banksDropdownItems: SearchableDropdownItem[] = [];
    banksAccountTypeDropdownItems: SearchableDropdownItem[] = [];

    public deserialize(source: any) {
        this.id = source.id;
        this.name = source.bank?.name;
        this.bankId = source.bank?.id;
        this.branch = source.branch;
        this.accountName = source.account_name;
        this.accountNumber = source.account_number;
        this.accountTypeId = source.account_type_id;
        this.accountType = this.getAccountType(source.account_type_id);
        return this;
    }

    public serialize(): any {
        return {
            id: this.id,
            bank_id: this.bankId,
            account_name: this.accountName,
            account_number: this.accountNumber,
            account_type_id: this.accountTypeId,
            branch: this.branch,
            to_update: this.toUpdate,
        };
    }

    public initBanksDropdownItems(banks: IBank[]): void {
        this.banksDropdownItems = banks.map((bank) => {
            return new SearchableDropdownItem({
                id: bank.id,
                value: bank.id,
                name: bank.name,
                isChecked: bank.id === this.bankId,
            });
        });
    }

    public initBanksAccountTypeDropdownItems(bankAccountTypes: IBankAcountType[]): void {
        this.banksAccountTypeDropdownItems = bankAccountTypes.map((bankAccountTypes) => {
            return new SearchableDropdownItem({
                id: bankAccountTypes.id,
                value: bankAccountTypes.id,
                name: bankAccountTypes.value,
                isChecked: bankAccountTypes.id === this.accountTypeId,
            });
        });
    }

    private getAccountType(accountId: number): string {
        switch (accountId) {
            case 1:
                return 'ออมทรัพย์';
            case 2:
                return 'ฝากประจำ';
            case 3:
                return 'กระแสรายวัน';
            default:
                return '';
        }
    }
}

export class InvoiceConfigsValidity {
    documentName: boolean;
    companyNameEnglish: boolean;
    companyNameThai: boolean;
    address: boolean;
    phoneNumber: boolean;
    emailAddress: boolean;
    licenseNumber: boolean;
    logo: boolean;

    public isAllValid(): boolean {
        return (
            this.documentName &&
            this.companyNameEnglish &&
            this.companyNameThai &&
            this.address &&
            this.phoneNumber &&
            this.emailAddress &&
            this.licenseNumber &&
            this.logo
        );
    }
}

export interface IInvoiceConfigs {
    documentName: string;
    companyNameEnglish: string;
    companyNameThai: string;
    address: string;
    phoneNumber: string;
    emailAddress: string;
    licenseNumber: string;
    approverName: string;
    paymentCondition: string;
    logoUrl: string;
    logoBase64: string;
    bankAccounts: IInvoiceBankAccount[];
}

export interface IInvoiceBankAccount {
    id: number;
    name: string;
    bankId: number;
    branch: string;
    accountName: string;
    accountNumber: string;
    accountType: string;
    accountTypeId: number;
    toUpdate?: boolean;
}
