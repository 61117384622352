import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';

@Component({
    selector: 'app-daterange',
    templateUrl: './daterange.component.html',
    styleUrls: ['./daterange.component.scss'],
})
export class DaterangeComponent implements OnInit, OnChanges {
    @Input() private startDateInput: Date;
    @Input() private endDateInput: Date;

    public startDate: Date;
    public endDate: Date;

    @Output() dateChange = new EventEmitter<IDateRange>();

    constructor() {}

    ngOnInit(): void {
        this.startDate = this.startDateInput;
        this.endDate = this.endDateInput;
    }

    ngOnChanges(changes: SimpleChanges): void {
        this.ngOnInit();
    }

    startDateChange(date: Date): void {
        this.startDate = date;
        this.dateChange.emit(this.toDataModel());
    }
    endDateChange(date: Date): void {
        this.endDate = date;
        this.dateChange.emit(this.toDataModel());
    }

    reset(): void {
        this.startDate = null;
        this.endDate = null;
    }

    private toDataModel(): IDateRange {
        return {
            startDate: this.startDate,
            endDate: this.endDate,
        };
    }
}

export interface IDateRange {
    startDate: Date;
    endDate: Date;
}
