import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'appDiscountPercentage' })
export class DiscountPercentagePipe implements PipeTransform {
    transform(price: number, priceCompare: number): number {
        if (priceCompare === null || priceCompare < price) {
            return 0;
        }
        return Math.floor(((priceCompare - price) / priceCompare) * 100);
    }
}
