export const COMMUNICATION_METHODS: any[] = [
    {
        id: '',
        text: 'ทั้งหมด',
    },
    {
        id: 'WEB',
        text: 'เว็บ',
    },
    {
        id: 'PHONE',
        text: 'โทร',
    },
    {
        id: 'LINE',
        text: 'Line',
    },
];

export enum LEAD_TYPE_SLUG {
    web = 'เว็บ',
    phone = 'โทร',
    line = 'Line',
}
