import { Component } from '@angular/core';

@Component({
    selector: 'app-star',
    templateUrl: './star.component.html',
    styleUrls: ['./star.component.scss'],
})
export class StarComponent {
    constructor() {}
}
