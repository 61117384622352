export interface ITransportation {
    id: number;
    name_en: string;
    name_th: string;
    code: string;
    flight_number?: string;
}

export class Transportation implements ITransportation {
    id: number;
    name_en: string;
    name_th: string;
    code: string;
    flight_number?: string;

    public deserialize(obj: any): Transportation {
        this.id = obj.id;
        this.name_en = obj.name_en;
        this.name_th = obj.name_th;
        this.code = obj.code;
        this.flight_number = obj.flight_number;
        return this;
    }
}
