import { Component, Output, EventEmitter, Input } from '@angular/core';

@Component({
    selector: 'app-toggle-button',
    template: `
        <input
            type="checkbox"
            id="toggle-button-checkbox"
            [(ngModel)]="checked"
            (change)="changed.emit($event.target.checked)"
        />
        <span class="value" [ngStyle]="{ color: checked ? '#33ac28' : '#8a9bad' }">{{ checked ? 'On' : 'Off' }}</span>
        <label class="toggle-button-switch" for="toggle-button-checkbox"></label>
        <div class="toggle-button-text">
            <div class="toggle-button-text-on"></div>
            <div class="toggle-button-text-off"></div>
        </div>
    `,
    styles: [
        `
            :host {
                display: block;
                position: relative;
                width: 70px;
                height: 50px;
            }

            input[type='checkbox'] {
                display: none;
            }

            .value {
                position: absolute;
                left: -19px;
                top: 6px;
                font-weight: bold;
                font-size: 10px;
            }

            /* 토글 버튼 내부의 원. 감춘 체크박스와 연동한다. */
            .toggle-button-switch {
                position: absolute;
                top: 3px;
                left: 5px;
                width: 20px;
                height: 20px;
                background-color: #fff;
                border-radius: 100%;
                cursor: pointer;
                z-index: 100;
                transition: left 0.3s;
            }

            /* 토글 버튼의 바탕 */
            .toggle-button-text {
                overflow: hidden;
                background-color: #8a9bad;
                border-radius: 25px;
                box-shadow: 2px 2px 5px 0 rgba(50, 50, 50, 0.75);
                transition: background-color 0.3s;
            }

            /* 토글 버튼의 텍스트 */
            .toggle-button-text-on,
            .toggle-button-text-off {
                float: left;
                width: 50%;
                height: 26px;
                line-height: 28px;
                font-family: Lato, sans-serif;
                font-weight: bold;
                color: #fff;
                text-align: center;
            }

            /* 체크박스가 체크 상태이면 토글 버튼 내부의 원을 오른쪽으로 52px 이동 */
            input[type='checkbox']:checked ~ .toggle-button-switch {
                left: 45px;
            }

            /* 체크박스가 체크 상태이면 토글 버튼의 배경색 변경 */
            input[type='checkbox']:checked ~ .toggle-button-text {
                background-color: #33ac28;
            }
        `,
    ],
})
export class ToggleButtonComponent {
    @Input() checked = true;
    @Output() changed = new EventEmitter<boolean>();
}
