import { Injectable } from '@angular/core';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { PlatformLocation } from '@angular/common';
import { ModalContentComponent } from '../../modules/shared/components/modal/modal.component';
import { NavigationExtras, Router } from '@angular/router';

@Injectable({
    providedIn: 'root',
})
export class ProductStoreModalService {
    private willRedirect: boolean = true;
    private modalRef: BsModalRef;

    constructor(private modalService: BsModalService, private location: PlatformLocation, private router: Router) {}

    public openSuccessModal(routeSegments: any[], extras?: NavigationExtras): void {
        this.willRedirect = true;
        const initialState = {
            message: 'บันทึกข้อมูลเรียบร้อยแล้ว',
        };
        this.modalRef = this.modalService.show(ModalContentComponent, {
            ignoreBackdropClick: false,
            initialState,
        });
        this.modalService.onHidden.subscribe(() => {
            if (this.willRedirect) {
                this.router.navigate(routeSegments, extras);
            }
        });
        /** for when going back to previous page while the modal is still opened */
        this.location.onPopState(() => {
            this.willRedirect = false;
            this.modalRef.hide();
        });
    }
}
