import { Component, EventEmitter, Input, Output } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { LeadContactInfoComponent } from 'src/app/modules/report/modals/b2b-booking/lead-contact-info/lead-contact-info.component';
import { LeadBookingInfoComponent } from '../../../modals/lead-booking-info/lead-booking-info.component';
import { SaleReportItem } from '../../../models/sale-report.model';

@Component({
    selector: 'app-sale-report-list-details',
    templateUrl: './sale-report-list-details.component.html',
    styleUrls: ['./sale-report-list-details.component.scss'],
})
export class SaleReportListDetailsComponent {
    @Input() index: number;
    @Input() report: SaleReportItem;

    @Output() clickViewMoreDetail = new EventEmitter<SaleReportItem>();

    public bsModalRef: BsModalRef;

    constructor(private modalService: BsModalService) {}

    viewMoreDetail(): void {
        this.clickViewMoreDetail.emit(this.report);
    }

    openContactInfoModal(): void {
        const initialState = {
            customerName: this.report.contactName,
            phone: this.report.phoneNumber,
            email: this.report.emailAddress,
            more_info: this.report.remark || '-',
        };
        this.bsModalRef = this.modalService.show(LeadContactInfoComponent, { initialState, class: 'contact-modal' });
    }

    openBusinessContactInfoModal(): void {
        const initialState = {
            id: this.report.id,
            ownerChannel: this.report.productOwnerChannelType,
        };
        this.bsModalRef = this.modalService.show(LeadBookingInfoComponent, {
            initialState,
            class: 'company-contact-modal',
        });
    }
}
