import { Agency } from 'src/app/models/agency';
import { ThaiDatePipe, ThaiDateRangePipe } from 'src/app/pipes/thaidate.pipe';
import { ICommissionReportListQueries } from './../../models/commission-report-list-queries';
import { Component, OnInit, OnDestroy, EventEmitter, Output } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { State } from 'src/app/stores/reducers';
import {
    selectCommissionListQueries,
    selectCommissionReportList,
    selectOrderCommissionSummarySelect,
} from '../../../../stores/order-store/order.selectors';
import { ICommissionReportList, IProductOwner } from 'src/app/modules/order/models/commission-report-list.model';
import { LeadBookingInfoComponent } from '../../modals/lead-booking-info/lead-booking-info.component';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import * as OrderActions from 'src/app/stores/order-store/order.actions';
import { LeadContactInfoComponent } from 'src/app/modules/report/modals/b2b-booking/lead-contact-info/lead-contact-info.component';
import { CATEGORY_SUB_PRODUCTS_TO_STRING } from 'src/app/constants/category-sub-product.constant';
import { ICommissionReport } from '../../models/commission-report-list.model';
import { SearchResultLabelHelper } from '../../helpers/search-result-label.helper';
import { selectAgencyInfo } from 'src/app/stores/user-store/user.selectors';
import { filter, takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

@Component({
    selector: 'app-commission-report',
    templateUrl: './commission-report.component.html',
    styleUrls: ['./commission-report.component.scss'],
})
export class CommissionReportComponent implements OnInit, OnDestroy {
    readonly CATEGORY_SUB_PRODUCTS_TO_STRING = CATEGORY_SUB_PRODUCTS_TO_STRING;

    @Output() clickViewMoreDetail = new EventEmitter<ICommissionReport>();

    agencyInfo: Agency;
    orderSummary: any;
    commissionReportList: ICommissionReportList;
    commissionListQueries: ICommissionReportListQueries;
    private bsModalRef: BsModalRef;

    private unsubscribe$: Subject<void> = new Subject<void>();
    constructor(
        private store: Store<State>,
        private modalService: BsModalService,
        private thaiDatePipe: ThaiDatePipe,
        private thaiDateRangePipe: ThaiDateRangePipe
    ) {}

    ngOnInit(): void {
        this.store
            .pipe(
                select(selectAgencyInfo),
                filter((agencyInfo) => !!agencyInfo),
                takeUntil(this.unsubscribe$)
            )
            .subscribe((agencyInfo) => (this.agencyInfo = agencyInfo));

        this.store
            .pipe(
                select(selectOrderCommissionSummarySelect),
                filter((data) => data !== null),
                takeUntil(this.unsubscribe$)
            )
            .subscribe((orderSummary) => {
                this.orderSummary = orderSummary;
            });

        this.store
            .pipe(
                select(selectCommissionReportList),
                filter((data) => data !== null),
                takeUntil(this.unsubscribe$)
            )
            .subscribe((commissionReportList: ICommissionReportList) => {
                this.commissionReportList = commissionReportList;
            });

        this.store
            .pipe(
                select(selectCommissionListQueries),
                filter((data) => data !== null),
                takeUntil(this.unsubscribe$)
            )
            .subscribe((commissionListQueries: ICommissionReportListQueries) => {
                this.commissionListQueries = commissionListQueries;
            });
    }

    viewMoreDetail(commissionReport: ICommissionReport): void {
        this.clickViewMoreDetail.emit(commissionReport);
    }

    openBusinessContactInfoModal(productOwner: IProductOwner): void {
        const initialState = {
            id: productOwner.id,
            ownerChannel: productOwner.channel,
        };
        this.bsModalRef = this.modalService.show(LeadBookingInfoComponent, {
            initialState,
            class: 'company-contact-modal',
        });
    }

    openContactInfoModal(commissionReport: ICommissionReport): void {
        const initialState = {
            customerName: commissionReport.contactName,
            phone: commissionReport.phoneNumber,
            email: commissionReport.email,
            more_info: commissionReport.customerRemark || '-',
        };
        this.bsModalRef = this.modalService.show(LeadContactInfoComponent, { initialState, class: 'contact-modal' });
    }

    disabledRow(startDate: Date) {
        return startDate.getTime() < new Date().getTime();
    }

    async getMoreCommissionReport(total: number) {
        const commissionReportListQueries: ICommissionReportListQueries = {
            filters: {
                created_at_between: {
                    min_date: this.orderSummary.dateRange.minDate,
                    max_date: this.orderSummary.dateRange.maxDate,
                },
                order_statuses: [
                    { status_code: 11, already_started: null },
                    { status_code: 21, already_started: null },
                    { status_code: 22, already_started: null },
                    { status_code: 31, already_started: false },
                    { status_code: 31, already_started: true },
                ],
            },
            criteria: {
                page: total / 50 + 1,
                pageEntries: 50,
            },
        };
        this.store.dispatch(
            OrderActions.getCommissionReportListPaginated({
                commissionReportListQueries: commissionReportListQueries,
                isNextPage: true,
            })
        );
    }

    getDynamicTotalString(): string {
        const productOwner = {
            nameEn: this.commissionReportList?.commissionReports[0]?.productOwner.nameEN,
            nameTh: this.commissionReportList?.commissionReports[0]?.productOwner.nameTH,
        };
        const sellerDetails = {
            firstName: this.commissionReportList?.commissionReports[0]?.sellerAgencyMember.firstName,
            lastName: this.commissionReportList?.commissionReports[0]?.sellerAgencyMember.lastName,
            nickName: this.commissionReportList?.commissionReports[0]?.sellerAgencyMember.nickName,
        };

        return SearchResultLabelHelper.getDynamicTotalString(
            this.commissionListQueries,
            this.thaiDatePipe,
            this.thaiDateRangePipe,
            productOwner,
            sellerDetails,
            this.agencyInfo?.productCode
        );
    }

    ngOnDestroy(): void {
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
    }
}
