import { Pipe, PipeTransform } from '@angular/core';
import { Country } from '../models/country';

@Pipe({
    name: 'displayCountries',
})
export class DisplayCountriesPipe implements PipeTransform {
    transform(countries: any[], delimiter = ','): any {
        if (!countries.length) {
            return '';
        }

        let value = '';
        countries.forEach((country) => {
            const nameTh: string = country.nameTh || country.name_th;

            if (!value) {
                value += nameTh;
            } else {
                value += delimiter + nameTh;
            }
        });
        return value;
    }
}
