<div class="payment">
    <div class="close-icon">
        <button type="button" class="close pull-right" aria-label="Close" (click)="bsModalRef.hide()">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="payment-title">
        <span>{{ mode === 'view' ? 'ข้อมูลชำระเงิน' : 'แจ้งชำระเงิน' }}</span>
    </div>
    <div class="order-info">
        <div class="order-info-title">
            <span>ข้อมูล Order</span>
            <span class="text-xs">{{ booking?.orderCode }} (จองวันที่ {{ booking?.createdAt | thaiDate }})</span>
        </div>
        <div class="mb-1">
            <span class="order-info-label">ชื่อลูกค้า : </span>
            <span>{{ booking?.customer?.name }}</span>
            <span class="text-xs"> (เบอร์โทร {{ booking.customer.phoneNumber }})</span>
        </div>

        <div class="mb-1">
            <span class="order-info-label">เดินทาง : </span>
            <span>{{ booking?.productPeriod?.startAt | thaidaterange: booking?.productPeriod?.endAt }}</span>
            <span class="text-xs"> ({{ booking?.product?.tourCode }} {{ booking?.product?.name }})</span>
        </div>

        <div class="mb-1">
            <span class="order-info-label">งวดที่ {{ installment.ordinal }} : </span>
            <span>{{ installment.amount | number: '1.2-2' }}</span>
            <span class="text-xs"> (ครบกำหนดวันที่ {{ installment.dueDate | thaiDate }})</span>
        </div>
    </div>
    <ng-container *ngIf="mode === 'form'">
        <app-form-manual-payment
            #formManualPayment
            [installment]="installment"
            [isSubmit]="isSubmit"
        ></app-form-manual-payment>
    </ng-container>
    <ng-container *ngIf="mode === 'view'">
        <app-view-manual-payment
            [installment]="installment"
            (cancelPaymentRequest)="onCancelPaymentRequest($event)"
        ></app-view-manual-payment>
    </ng-container>
    <div *ngIf="isSubmit && !formValid" class="form-invalid">กรุณากรอกข้อมูลให้ครบถ้วน</div>
    <div class="d-flex justify-content-center">
        <ng-container *ngIf="mode === 'form'">
            <button class="btn btn-primary mr-2" (click)="onSubmit()">บันทึก</button>
            <button class="btn btn-outline-secondary" (click)="bsModalRef.hide()">ยกเลิก</button>
        </ng-container>
        <ng-container *ngIf="mode === 'view'">
            <button class="btn btn-primary" (click)="bsModalRef.hide()">ปิด</button>
        </ng-container>
    </div>
</div>
