export class InstallmentList {
    private total: number;
    private installmentAmount: number;
    installments: Installment[];

    constructor(installmentAmount: number) {
        this.installments = [];
        for (let i = 0; i < installmentAmount; i++) {
            this.installments[i] = new Installment(i + 1);
        }
    }

    set totalRealPaid(price: number) {
        this.total = price;
        this.installments.forEach((i) => (i.maxPrice = price));
        this.calculateInstallmentPrice();
    }

    get totalRealPaid(): number {
        return this.total;
    }

    reCalculateInstallment(): void {
        this.calculateInstallmentPrice();
    }

    resetFirstInstallmentWithDeposit(deposit: number): void {
        this.installments[0].price = deposit;
        this.calculateInstallmentPrice();
    }

    private calculateInstallmentPrice(): void {
        //
        if (!this.installmentAmount) {
            return;
        }

        if (this.installmentAmount === 1) {
            this.installments[0].price = this.totalRealPaid;
        } else {
            this.setMaxPrice();

            // Set last input price
            let userSetPrice = 0;
            for (let i = 0; i < this.installmentAmount - 1; i++) {
                userSetPrice += this.installments[i].price;
            }
            this.installments[this.installmentAmount - 1].price = this.totalRealPaid - userSetPrice;
        }
    }

    private setMaxPrice(): void {
        // Set max enable input price
        for (let i = 0; i < this.installmentAmount - 1; i++) {
            let maxPrice = 0;
            for (let j = 0; j < this.installmentAmount - 1; j++) {
                if (i !== j) {
                    maxPrice += this.installments[j].price;
                }
            }

            this.installments[i].maxPrice = this.totalRealPaid - maxPrice;
        }

        this.installments[this.installmentAmount - 1].maxPrice = this.totalRealPaid;
    }

    setInstallmentAmount(number: number): void {
        this.installmentAmount = number;
        for (let i = 0; i < this.installments.length; i++) {
            if (i <= number - 1) {
                this.installments[i].hide = false;
                this.installments[i].enable = true;

                if (i === number - 1) {
                    this.installments[i].enable = false;
                }
            } else {
                this.installments[i].hide = true;
            }
        }

        this.calculateInstallmentPrice();
    }

    getInstallmentAmount(): number {
        return this.installmentAmount;
    }
}

export class Installment {
    date: Date;
    price: number;
    maxPrice: number;
    enable: boolean;
    hide: boolean;
    ordinal: number;

    constructor(ordinal: number) {
        this.date = null;
        this.price = null;
        this.enable = true;
        this.hide = false;
        this.ordinal = ordinal;

        this.maxPrice = undefined;
    }
}
