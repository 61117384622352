import { createAction, props } from '@ngrx/store';

// Models
import { ICountry } from 'src/app/models/country.model';
import { ICategoryTransport } from 'src/app/models/category-transport.model';
import { ITransportation } from 'src/app/models/transportation.model';
import { IGalleryResponse } from 'src/app/models/gallery.model';
import { Province } from 'src/app/models/province';
import { IAboutUsDetails, IAboutUsEditPayload } from 'src/app/modules/settings/models/about-us.model';
import { IBank } from 'src/app/models/bank.model';
import { ACTION_TYPE, ENTITY } from 'src/app/constants/event-log.constant';
import { CountrySubUnit } from 'src/app/models/country-sub-unit.model';
import { ProductSubCategory } from 'src/app/models/product-sub-category.model';

export enum ActionTypes {
    LOAD_COUNTRIES = '[Utility] Load All Countries List.',
    LOAD_COUNTRIES_SUCCESS = '[Utility] Load All Countries List Success.',
    LOAD_COUNTRIES_FAILURE = '[Utility] Load All Countries List Failure.',

    LOAD_PROVINCES = '[Utility] Load All Provinces List.',
    LOAD_PROVINCES_SUCCESS = '[Utility] Load All Provinces List Success.',
    LOAD_PROVINCES_FAILURE = '[Utility] Load All Provinces List Failure.',

    LOAD_COUNTRY_SUB_UNITS = '[Utility] Load All Country sub units List.',
    LOAD_COUNTRY_SUB_UNITS_SUCCESS = '[Utility] Load All Country sub units List Success.',
    LOAD_COUNTRY_SUB_UNITS_FAILURE = '[Utility] Load All Country sub units List Failure.',

    LOAD_CATEGORY_TRANSPORTS = '[Utility] Load All CategoryTransports List.',
    LOAD_CATEGORY_TRANSPORTS_SUCCESS = '[Utility] Load All CategoryTransports List Success.',
    LOAD_CATEGORY_TRANSPORTS_FAILURE = '[Utility] Load All CategoryTransports List Failure.',

    LOAD_TRANSPORTATIONS = '[Utility] Load All Transportations List.',
    LOAD_TRANSPORTATIONS_SUCCESS = '[Utility] Load All Transportations List Success.',
    LOAD_TRANSPORTATIONS_FAILURE = '[Utility] Load All Transportations List Failure.',

    UPLOAD_FILE_GALLERY = '[Utility] Upload File Gallery.',
    UPLOAD_FILE_GALLERY_PROGRESS_UPDATE = '[Utility] Upload File Gallery Progress Update.',
    UPLOAD_FILE_GALLERY_UNHANDLE = '[Utility] Upload File Gallery Unhandle.',
    UPLOAD_FILE_GALLERY_SUCCESS = '[Utility] Upload File Gallery Success.',
    UPLOAD_FILE_GALLERY_FAILURE = '[Utility] Upload File Gallery Failure.',
    UPLOAD_FILE_GALLERY_RESET_ALL = '[Utility] Upload File Gallery Reset All.',

    DELETE_FILE_GALLERY = '[Utility] Delete File Gallery.',
    DELETE_FILE_GALLERY_SUCCESS = '[Utility] Delete File Gallery Success.',
    DELETE_FILE_GALLERY_FAILURE = '[Utility] Delete File Gallery Failure.',

    LOAD_ABOUTUS_DETAILS = '[Utility] Load about us details.',
    LOAD_ABOUTUS_DETAILS_SUCCESS = '[Utility] Load about us details Success.',
    LOAD_ABOUTUS_DETAILS_FAILURE = '[Utility] Load about us details Failure.',

    EDIT_ABOUTUS_DETAILS = '[Utility] Edit about us details.',
    EDIT_ABOUTUS_DETAILS_SUCCESS = '[Utility] Edit about us details Success.',
    EDIT_ABOUTUS_DETAILS_FAILURE = '[Utility] Edit about us details Failure.',

    LOAD_BANKS = '[Utility] Get all banks list.',
    LOAD_BANKS_SUCCESS = '[Utility] Get all banks list success.',
    LOAD_BANKS_FAILURE = '[Utility] Get all banks list failure.',

    LOAD_PRODUCT_SUB_CATEGORIES = '[Utility] Get all Product sub categories list.',
    LOAD_PRODUCT_SUB_CATEGORIES_SUCCESS = '[Utility] Get all Product sub categories list success.',
    LOAD_PRODUCT_SUB_CATEGORIES_FAILURE = '[Utility] Get all Product sub categories list failure.',

    EVENT_LOG = '[Utility] Event log.',
    EVENT_LOG_SUCCESS = '[Utility] Event log success.',
    EVENT_LOG_FAILURE = '[Utility] Event log failure.',
}

//
export const loadCountries = createAction(ActionTypes.LOAD_COUNTRIES);
export const loadCountriesSuccess = createAction(
    ActionTypes.LOAD_COUNTRIES_SUCCESS,
    props<{ countries: ICountry[] }>()
);
export const loadCountriesFailure = createAction(ActionTypes.LOAD_COUNTRIES_FAILURE, props<{ error: any }>());

export const loadProvinces = createAction(ActionTypes.LOAD_PROVINCES);
export const loadProvincesSuccess = createAction(
    ActionTypes.LOAD_PROVINCES_SUCCESS,
    props<{ provinces: Province[] }>()
);
export const loadProvincesFailure = createAction(ActionTypes.LOAD_PROVINCES_FAILURE, props<{ error: any }>());

export const loadCountrySubUnits = createAction(ActionTypes.LOAD_COUNTRY_SUB_UNITS, props<{ countryIds: number[] }>());
export const loadCountrySubUnitsSuccess = createAction(
    ActionTypes.LOAD_COUNTRY_SUB_UNITS_SUCCESS,
    props<{ countrySubUnits: CountrySubUnit[] }>()
);
export const loadCountrySubUnitsFailure = createAction(
    ActionTypes.LOAD_COUNTRY_SUB_UNITS_FAILURE,
    props<{ error: any }>()
);

export const loadCategoryTransports = createAction(ActionTypes.LOAD_CATEGORY_TRANSPORTS, props<{ filters: any }>());
export const loadCategoryTransportsSuccess = createAction(
    ActionTypes.LOAD_CATEGORY_TRANSPORTS_SUCCESS,
    props<{ categoryTransports: ICategoryTransport[] }>()
);
export const loadCategoryTransportsFailure = createAction(
    ActionTypes.LOAD_CATEGORY_TRANSPORTS_FAILURE,
    props<{ error: any }>()
);

export const loadTransportations = createAction(ActionTypes.LOAD_TRANSPORTATIONS);
export const loadTransportationsSuccess = createAction(
    ActionTypes.LOAD_TRANSPORTATIONS_SUCCESS,
    props<{ transportations: ITransportation[] }>()
);
export const loadTransportationsFailure = createAction(
    ActionTypes.LOAD_CATEGORY_TRANSPORTS_FAILURE,
    props<{ error: any }>()
);

export const uploadFileGallery = createAction(
    ActionTypes.UPLOAD_FILE_GALLERY,
    props<{ form: FormData; identifier: string; showLoader?: boolean }>()
);
export const uploadFileGalleryProgressUpdate = createAction(
    ActionTypes.UPLOAD_FILE_GALLERY_PROGRESS_UPDATE,
    props<{ response: IGalleryResponse; identifier: string }>()
);
export const uploadFileGalleryUnhandle = createAction(
    ActionTypes.UPLOAD_FILE_GALLERY_UNHANDLE,
    props<{ identifier: string }>()
);
export const uploadFileGallerySuccess = createAction(
    ActionTypes.UPLOAD_FILE_GALLERY_SUCCESS,
    props<{ response: IGalleryResponse; identifier: string }>()
);
export const uploadFileGalleryFailure = createAction(
    ActionTypes.UPLOAD_FILE_GALLERY_FAILURE,
    props<{ error: any; identifier: string }>()
);
export const uploadFileGalleryResetAll = createAction(ActionTypes.UPLOAD_FILE_GALLERY_RESET_ALL);

export const deleteFileGallery = createAction(
    ActionTypes.DELETE_FILE_GALLERY,
    props<{ galleryId: number; fileId: number; identifier: string }>()
);
export const deleteFileGallerySuccess = createAction(
    ActionTypes.DELETE_FILE_GALLERY_SUCCESS,
    props<{ response: IGalleryResponse; identifier: string }>()
);
export const deleteFileGalleryFailure = createAction(
    ActionTypes.DELETE_FILE_GALLERY_FAILURE,
    props<{ error: any; identifier: string }>()
);

export const loadAboutUsDetails = createAction(ActionTypes.LOAD_ABOUTUS_DETAILS);
export const loadAboutUsDetailsSuccess = createAction(
    ActionTypes.LOAD_ABOUTUS_DETAILS_SUCCESS,
    props<{ response: IAboutUsDetails }>()
);
export const loadAboutUsDetailsFailure = createAction(
    ActionTypes.LOAD_ABOUTUS_DETAILS_FAILURE,
    props<{ error: any }>()
);

export const editAboutUsDetails = createAction(
    ActionTypes.EDIT_ABOUTUS_DETAILS,
    props<{ payload: IAboutUsEditPayload }>()
);
export const editAboutUsDetailsSuccess = createAction(
    ActionTypes.EDIT_ABOUTUS_DETAILS_SUCCESS,
    props<{ response: IAboutUsDetails }>()
);
export const editAboutUsDetailsFailure = createAction(
    ActionTypes.EDIT_ABOUTUS_DETAILS_FAILURE,
    props<{ error: any }>()
);

export const loadBanks = createAction(ActionTypes.LOAD_BANKS);
export const loadBanksSuccess = createAction(ActionTypes.LOAD_BANKS_SUCCESS, props<{ response: IBank[] }>());
export const loadBanksFailure = createAction(ActionTypes.LOAD_BANKS_FAILURE, props<{ error: any }>());

export const loadProductSubCategories = createAction(
    ActionTypes.LOAD_PRODUCT_SUB_CATEGORIES,
    props<{ productCatgeoryIds: number[] }>()
);
export const loadProductSubCategoriesSuccess = createAction(
    ActionTypes.LOAD_BANKS_SUCCESS,
    props<{ productSubCategories: ProductSubCategory[] }>()
);
export const loadProductSubCategoriesFailure = createAction(ActionTypes.LOAD_BANKS_FAILURE, props<{ error: any }>());

export const eventLog = createAction(
    ActionTypes.EVENT_LOG,
    props<{ entityName: ENTITY; actionType: ACTION_TYPE; content: any | null }>()
);
export const eventLogSuccess = createAction(ActionTypes.EVENT_LOG_SUCCESS);
export const eventLogFailure = createAction(ActionTypes.EVENT_LOG_FAILURE);
