import { NavigationEnd, Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';

@Component({
    selector: 'app-file-over-limit',
    templateUrl: './file-over-limit.component.html',
    styleUrls: ['./file-over-limit.component.scss'],
})
export class FileOverLimitComponent implements OnInit {
    constructor(public bsModalRef: BsModalRef, public bsModalService: BsModalService, private router: Router) {}

    ngOnInit() {
        this.closeOnRouteChange();
    }

    private closeOnRouteChange(): void {
        this.router.events.subscribe((evt) => {
            if (!(evt instanceof NavigationEnd)) {
                return;
            }
            this.bsModalRef.hide();
        });
    }
}
