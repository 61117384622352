import { Component, EventEmitter, Input, Output } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { filter } from 'rxjs/operators';
import { State } from 'src/app/stores/reducers';
import { uploadFileGallery } from 'src/app/stores/utility-store/utility.actions';
import { selectGalleryResponse } from 'src/app/stores/utility-store/utility.selectors';

@Component({
    selector: 'app-upload-img',
    templateUrl: './upload-img.component.html',
    styleUrls: ['./upload-img.component.scss'],
})
export class UploadImgComponent {
    fileUploaded: File;

    @Input() imageDescription: string;
    @Input() imagePreview: string;
    @Input() index: number;
    @Input() imageType: string;

    @Output() private galleryId: EventEmitter<number> = new EventEmitter<number>();
    @Output() private deleteImgComponent: EventEmitter<boolean> = new EventEmitter<boolean>();
    @Output() private emitDescription: EventEmitter<string> = new EventEmitter<string>();

    constructor(private store: Store<State>, private ngxLoader: NgxUiLoaderService) {}

    uploadImage(event: any) {
        const file = (this.fileUploaded = event.target.files[0]);
        const myReader: FileReader = new FileReader();

        myReader.onloadend = () => {
            const base64 = myReader.result;
            this.imagePreview = base64 as string;
            event.target.value = null;

            this.uploadToS3();
        };

        myReader.readAsDataURL(file);
    }

    private uploadToS3(): void {
        const form: FormData = new FormData();
        form.append('galleries_id', null);
        form.append('file', this.fileUploaded, this.fileUploaded.name);
        form.append('type', this.imageType);

        this.ngxLoader.start();
        this.store.dispatch(
            uploadFileGallery({
                form,
                identifier: this.imageType + '-file-upload-' + this.index,
            })
        );
        this.store
            .pipe(
                select(selectGalleryResponse, { identifier: this.imageType + '-file-upload-' + this.index }),
                filter((response) => !!response && !!response.fileUrl)
            )
            .subscribe((response) => {
                this.ngxLoader.stop();
                this.galleryId.emit(response.galleryId);
            });
    }

    deleteImg(): void {
        this.imagePreview = null;
        this.galleryId.emit(null);
    }

    deleteComponent(): void {
        this.deleteImgComponent.emit(true);
    }

    changeDescription(description: string): void {
        this.emitDescription.emit(description);
    }
}
