import { Injectable } from '@angular/core';
import { FillPattern, Workbook } from 'exceljs';
import * as moment from 'moment';
import { IBookingMonthlyReport } from '../models/booking-monthly-report.model';
import { ILeadMonthlyReport } from '../models/lead-monthly-report.model';
import { ExcelServiceBase } from './base/excel.service';
import * as fs from 'file-saver';

@Injectable({
    providedIn: 'root',
})
export class ExportLeadExcelService extends ExcelServiceBase {
    public exportLeadMonthlyReport(dateRange: string, count: number, reportModels: ILeadMonthlyReport[]): void {
        try {
            //Declare variables
            const today = moment().format('DDMMYYYY');
            const worksheetName = 'ข้อมูลใบจองจากลูกค้า(Lead Reports)-';
            const fileName = worksheetName + today;
            const worksheet = this.createWorksheet(worksheetName);

            //Adding title row
            const titleRow = worksheet.getCell('A1');
            titleRow.value = `สรุปรายการ ใบจองจากลูกค้า ของเดือน ${dateRange} จำนวน ${count} รายการ`;

            //Set font style
            titleRow.font = {
                bold: true,
            };

            //Adding Header Row
            const headerRow = worksheet.addRow([
                'ลำดับ',
                'วันที่จอง',
                'เวลาที่จอง',
                'ช่องทางการจอง',
                'สถานะ',
                'Tour code',
                'ชื่อโปรแกรมทัวร์',
                'วันไป',
                'วันกลับ',
                'ราคาเริ่มต้นต่อคน (บาท)',
                'คอมบริษัทต่อคน (บาท)',
                'คอมเซลล์ต่อคน (บาท)',
                'ประเภทสินค้า',
                'ประเทศ',
                'จังหวัด/เมือง',
                'ประเภทการเดินทาง',
                'สายการบิน',
                'เซลล์ผู้ขาย (ชื่อ-นามสกุล)',
                'เซลล์ผู้ขาย (ชื่อเล่น)',
                'Remark ภายใน',
                'ชื่อลูกค้า (ผู้จอง)',
                'เบอร์โทรลูกค้า (ผู้จอง)',
                'อีเมลลูกค้า (ผู้จอง)',
                'ข้อมูลเพิ่มเติมจากลูกค้า (ผู้จอง)',
                'โปรแกรมจาก',
                'เบอร์โทรติดต่อเจ้าของโปรแกรมทัวร์',
                'วันที่ยกเลิก',
                'เวลาที่ยกเลิก',
                'ยกเลิกโดย',
            ]);
            headerRow.eachCell((cell, colNumber) => {
                const columnInfo = cell.worksheet.getColumn(colNumber);
                columnInfo.width = 30;

                //Set font style
                cell.font = {
                    bold: true,
                };

                //Set border style
                cell.border = {
                    top: { style: 'thin' },
                    left: { style: 'thin' },
                    bottom: { style: 'thin' },
                    right: { style: 'thin' },
                };

                cell.alignment = { horizontal: 'center' };

                //Set bg style
                this.setLeadMonthlyReportHeaderBgStyle(colNumber, cell);
                if (colNumber === 1) {
                    columnInfo.width = 15;
                }
            });

            //Converting model to data rows
            const dataRows = [];
            reportModels.map((data) => {
                dataRows.push(Object.values(data));
            });

            // Adding Data
            dataRows.forEach((d) => {
                const row = worksheet.addRow(d);
                row.eachCell({ includeEmpty: true }, (cell, colNumber) => {
                    //Set border style
                    cell.border = {
                        top: { style: 'thin' },
                        left: { style: 'thin' },
                        bottom: { style: 'thin' },
                        right: { style: 'thin' },
                    };

                    if (colNumber === 1) {
                        cell.alignment = { horizontal: 'left' };
                    }

                    if ([10, 11, 12].includes(colNumber)) {
                        cell.numFmt = '#,##0';
                    }
                });
            });

            // Export to excel file
            this.generateFile(fileName);
        } catch (error) {
            throw new Error(error);
        }
    }

    private setLeadMonthlyReportHeaderBgStyle(number: number, cell) {
        //Constant variables
        const ClientFullNameColumnIndex = 21;
        const ClientPhoneNumberColumnIndex = 22;
        const ClientEmailColumnIndex = 23;
        const ClientCommentColumnIndex = 24;
        const CompanyNameColumnIndex = 25;
        const CompanyPhoneNumberColumnIndex = 26;

        let bgColor = 'E7E6E6';
        if ([CompanyNameColumnIndex, CompanyPhoneNumberColumnIndex].indexOf(number) !== -1) {
            bgColor = 'BDD7EE';
        } else if (
            [
                ClientFullNameColumnIndex,
                ClientPhoneNumberColumnIndex,
                ClientEmailColumnIndex,
                ClientCommentColumnIndex,
            ].indexOf(number) !== -1
        ) {
            bgColor = 'FFC000';
        }

        (<FillPattern>cell.fill) = {
            type: 'pattern',
            pattern: 'solid',
            bgColor: { argb: bgColor },
            fgColor: { argb: bgColor },
        };
    }

    public exportBookingMonthlyReport(dateRange: string, count: number, reportModels: IBookingMonthlyReport[]): void {
        try {
            //Declare variables
            const today = moment().format('DDMMYYYY');
            const worksheetName = 'ข้อมูลสถานะการจองจากลูกค้า(Booking Reports)-';
            const fileName = worksheetName + today;

            //Create a workbook
            const workbook = new Workbook();
            const worksheet = workbook.addWorksheet(worksheetName);

            //Adding title row
            const titleRow = worksheet.getCell('A1');
            titleRow.value = `สรุปรายการ ใบจองจากลูกค้าของเดือน ${dateRange} จำนวน ${count} รายการ`;

            //Set font style
            titleRow.font = {
                bold: true,
                name: 'Tahoma',
            };

            //Adding Header Row
            const headerRow = worksheet.addRow([
                'ลำดับ',
                'Booking No.',
                'วันที่สร้าง Booking',
                'เวลาที่สร้าง Booking',
                'ช่องทางการจอง',
                'สถานะ',
                'Tour code',
                'ชื่อโปรแกรมทัวร์',
                'วันไป',
                'วันกลับ',
                'คอมบริษัทต่อคน (บาท)',
                'คอมเซลล์ต่อคน (บาท)',
                'คอมรวม (บาท)',
                'ประเภทสินค้า',
                'ประเทศ',
                'จังหวัด/เมือง',
                'ประเภทการเดินทาง',
                'สายการบิน',
                'ราคาเริ่มต้นต่อคน (บาท)',
                'จำนวนผู้เดินทาง',
                'จำนวนเงินรวม',
                'จำนวนงวด',
                'วันที่ชำระเงินงวดที่ 1',
                'ยอดเงินงวดที่ 1',
                'วันที่ชำระเงินงวดที่ 2',
                'ยอดเงินงวดที่ 2',
                'วันที่ชำระเงินงวดที่ 3',
                'ยอดเงินงวดที่ 3',
                'วันที่ชำระเงินงวดที่ 4',
                'ยอดเงินงวดที่ 4',
                'เซลล์ผู้ขาย (ชื่อ-นามสกุล)',
                'เซลล์ผู้ขาย (ชื่อเล่น)',
                'ชื่อลูกค้า (ผู้จอง)',
                'เบอร์โทรลูกค้า (ผู้จอง)',
                'อีเมลลูกค้า (ผู้จอง)',
                'ข้อมูลเพิ่มเติมจากลูกค้า (ผู้จอง)',
                'โปรแกรมจาก',
                'เบอร์โทรติดต่อเจ้าของโปรแกรมทัวร์',
                'วันที่ยกเลิก',
                'เวลาที่ยกเลิก',
                'ยกเลิกโดย',
            ]);
            headerRow.eachCell((cell, colNumber) => {
                const columnInfo = cell.worksheet.getColumn(colNumber);
                columnInfo.width = 30;

                //Set font style
                cell.font = {
                    bold: true,
                };

                //Set border style
                cell.border = {
                    top: { style: 'thin' },
                    left: { style: 'thin' },
                    bottom: { style: 'thin' },
                    right: { style: 'thin' },
                };

                cell.alignment = { horizontal: 'center' };

                //Set bg style
                this.setBookingMonthlyReportHeaderBgStyle(colNumber, cell);
                cell.font.name = 'Tahoma';
                cell.font.bold = true;
                if (colNumber === 1) {
                    columnInfo.width = 15;
                }
            });

            //Converting model to data rows
            const dataRows = [];
            reportModels.map((data) => {
                dataRows.push(Object.values(data));
            });

            // Adding Data
            dataRows.forEach((d) => {
                const row = worksheet.addRow(d);
                row.eachCell({ includeEmpty: true }, (cell, colNumber) => {
                    //Set border style
                    cell.border = {
                        top: { style: 'thin' },
                        left: { style: 'thin' },
                        bottom: { style: 'thin' },
                        right: { style: 'thin' },
                    };

                    cell.font = {
                        name: 'Tahoma',
                    };

                    if (colNumber === 1 || colNumber === 2) {
                        cell.alignment = { horizontal: 'center' };
                    }

                    if ([20, 22].includes(colNumber)) {
                        cell.alignment = { horizontal: 'right' };
                        cell.numFmt = '#,##0';
                    }

                    if ([11, 12, 13, 19, 21, 24, 26, 28, 30].includes(colNumber)) {
                        cell.alignment = { horizontal: 'right' };
                        cell.numFmt = '#,##0.00';
                    }
                });
            });

            //Generate & Save Excel File
            workbook.xlsx.writeBuffer().then((data) => {
                const blob = new Blob([data], {
                    type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
                });
                fs.saveAs(blob, fileName + '.xlsx');
            });
        } catch (error) {
            throw new Error(error);
        }
    }

    private setBookingMonthlyReportHeaderBgStyle(number: number, cell) {
        let bgColor = 'E7E6E6';
        if ([1].indexOf(number) !== -1) {
            bgColor = 'FFFFFF';
        } else if ([20, 21].indexOf(number) !== -1) {
            bgColor = 'D4DDEF'; //blue
        } else if ([22, 23, 24, 25, 26, 27, 28, 29, 30].indexOf(number) !== -1) {
            bgColor = 'F2A944'; //orange
        } else if ([33, 34, 35, 36].indexOf(number) !== -1) {
            bgColor = 'FFE293'; //yellow
        } else if ([37, 38].indexOf(number) !== -1) {
            bgColor = 'B5D2EA'; //bluer
        }

        (<FillPattern>cell.fill) = {
            type: 'pattern',
            pattern: 'solid',
            bgColor: { argb: bgColor },
            fgColor: { argb: bgColor },
        };
    }
}
