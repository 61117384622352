import { IProduct, IProductInfo, IProductDescription, IProductPeriod } from './product.model';
import * as moment from 'moment';

export class BaseProduct implements IProduct {
    id: number = null;
    categoryProductId?: number = null;
    categorySubProductId?: number = null;
    productInfo: IProductInfo = null;
    productDescriptions: IProductDescription[] = [];
    tourCondition: string = null;
    productPeriods: IProductPeriod[] = [];
    tags: string[] = [];

    public constructor(iProduct?: IProduct) {
        if (iProduct) {
            this.productInfo = iProduct.productInfo;
            this.productDescriptions = iProduct.productDescriptions;
            this.tourCondition = iProduct.tourCondition;
            this.productPeriods = iProduct.productPeriods;
            this.tags = iProduct.tags;
        }
    }

    public serialize(): any {
        return {
            name: this.productInfo.name,
            tour_code: this.productInfo.tourCode,
            duration_day: this.productInfo.durationDay,
            duration_night: this.productInfo.durationNight,
            price: this.productInfo.priceStarts,
            galleries_id: this.productInfo.galleryId,
            gallery_items_ids_to_use: this.productInfo.gallery.galleryItems.map((galleryItem) => galleryItem.fileId),
            gallery_items_id_to_activate: this.productInfo.galleryItemIdToActivate,
            category_transports_id: this.productInfo.categoryTransportId,
            go_transportations_id: this.productInfo.goTransportationId,
            travel_period_start_at_first: this.getStartDateIsoString(),
            travel_period_start_at_last: this.getEndDateIsoString(),
            commission_company: this.productInfo.commissionCompany ? +this.productInfo.commissionCompany : null,
            commission_seller: this.productInfo.commissionSeller ? +this.productInfo.commissionSeller : null,
            hilight_description: this.productInfo.hilightDescription,
            tour_condition: this.tourCondition,
            countries_ids: this.productInfo.countryIds,
            provinces_ids: this.productInfo.provinceIds,
            product_descriptions: this.productDescriptions.map((productDescription) => {
                return {
                    id: productDescription.id || null,
                    ordinal: productDescription.ordinal,
                    description: productDescription.description,
                    galleries_id: productDescription.galleryId,
                };
            }),
            product_periods: this.productPeriods.map((productPeriod) => {
                return {
                    id: productPeriod.id || null,
                    start_at: moment(productPeriod.startAt).format('YYYY-MM-DD'),
                    end_at: moment(productPeriod.endAt).format('YYYY-MM-DD'),
                    price: productPeriod.price,
                    galleries_id: productPeriod.galleryId,
                };
            }),
            document_file: {
                is_same_file:
                    this.productInfo.documentIsSameFile === undefined ? false : this.productInfo.documentIsSameFile,
                base64_data: this.productInfo.documentFileBase64Data,
            },
            original_pdf_file: {
                is_same_file:
                    this.productInfo.originalPdfIsSameFile === undefined
                        ? false
                        : this.productInfo.originalPdfIsSameFile,
                base64_data: this.productInfo.originalPdfFileBase64Data,
            },
            showcase_banner_file: {
                is_same_file:
                    this.productInfo.showcaseImageIsSameFile === undefined
                        ? false
                        : this.productInfo.showcaseImageIsSameFile,
                base64_data: this.productInfo.showcaseImageBase64Data,
            },
            tags: this.tags,
        };
    }

    public deserialize(data: any): this {
        this.id = data.id;
        this.categoryProductId = data.category_product.id;
        this.categorySubProductId = data.category_sub_product.id;
        this.productInfo = {
            name: data.name,
            tourCode: data.tour_code,
            durationDay: data.duration_day,
            durationNight: data.duration_night,
            priceStarts: data.price,
            startDate: this.makeDateFromIsoString(data.travel_period_start_at_first),
            endDate: this.makeDateFromIsoString(data.travel_period_start_at_last),
            commissionCompany: data.commission_company ? +data.commission_company : null,
            commissionSeller: data.commission_seller ? +data.commission_seller : null,
            hilightDescription: data.hilight_description,

            documentFileBase64Data: null,
            originalPdfFileBase64Data: null,
            showcaseImageBase64Data: null,
            documentUrl: data.document_url,
            originalPdfUrl: data.original_pdf_url,
            showcaseImagePreviewUrl: data.showcase_banner_preview_url,
            showcaseImageThumbnailUrl: data.showcase_banner_thumbnail_url,

            categoryTransportId: data.category_transport === null ? null : data.category_transport.id,
            goTransportationId: data.go_transportation === null ? null : data.go_transportation.id,
            galleryId: data.gallery.id,
            galleryItemIdToActivate: null,
            countryIds: data.countries ? data.countries.map((country) => country.id) : [],
            provinceIds: data.provinces ? data.provinces.map((province) => province.id) : [],
            gallery: {
                id: data.gallery.id,
                galleryItems: data.gallery.gallery_item.map((galleryItem) => {
                    return {
                        fileId: galleryItem.id,
                        isActive: galleryItem.is_active,
                        fileName: galleryItem.item_file_name,
                        fileUrl: galleryItem.file_url,
                    };
                }),
            },
        };
        this.tourCondition = data.tour_condition;
        this.productDescriptions = data.product_descriptions.map((productDescription) => {
            return {
                id: productDescription.id,
                ordinal: productDescription.ordinal,
                description: productDescription.description,
                galleryId: productDescription.gallery_id,
                imageUrl: productDescription.image_url,
                fileId: productDescription.file_id,
                type: productDescription.type_slug,
            };
        });
        this.productPeriods = data.product_periods.map((productPeriod) => {
            return {
                id: productPeriod.id,
                startAt: this.makeDateFromIsoString(productPeriod.start_at),
                endAt: this.makeDateFromIsoString(productPeriod.end_at),
                price: productPeriod.price,
                galleryId: productPeriod.gallery_id,
                fileId: productPeriod.file_id,
                imageUrl: productPeriod.image_url,
            };
        });
        this.tags = data.tags || [];
        return this;
    }

    private makeDateFromIsoString(isoString: string): Date {
        if (isoString === null || isoString === '') {
            return null;
        }
        return moment(isoString).toDate();
    }

    public getStartDateIsoString(): string {
        if (this.productInfo.startDate) {
            return moment(this.productInfo.startDate).startOf('month').format('YYYY-MM-DD');
        }
        return null;
    }

    public getEndDateIsoString(): string {
        if (this.productInfo.endDate) {
            return moment(this.productInfo.endDate).endOf('month').format('YYYY-MM-DD');
        }
        return null;
    }
}
