export interface IBookingListQueries {
    filters: IBookingListFilter;
    criteria: {
        page: number;
        pageEntries: number;
    };
    sortBy?: BOOKING_LIST_SORT_BY;
}

export interface IBookingListFilter {
    order_status?: number;
    order_statuses?: IBookingListOrderStatusFilter[];
    product_owner?: IBookingListProductOwnerFilter;
    seller_agency_member_id?: number;
    contact_name_or_phone_number?: string;
    product_tour_code?: string;
    product_name?: string;
    created_at_between?: {
        min_date: string;
        max_date: string;
    };
    start_at_between?: {
        min_date: string;
        max_date: string;
    };
    order_ids?: number[];
    product_pool_key?: string[];

    clearCache?: string;
}

export interface IBookingListOrderStatusFilter {
    status_code: number;
    already_started: boolean;
}

export interface IBookingListProductOwnerFilter {
    id: number;
    channel: 'ws' | 'ag';
}

export enum BOOKING_LIST_SORT_BY {
    CREATED_BY_ASC = 'created_by_asc',
    CREATED_BY_DESC = 'created_by_desc',
    SUM_TOTAL_ASC = 'sum_total_asc',
    SUM_TOTAL_DESC = 'sum_total_desc',
    FIRST_PAYMENT_DUE_ASC = 'first_payment_duedate_at_asc',
    FIRST_PAYMENT_DUE_DESC = 'first_payment_duedate_at_desc',
}

export interface IBookingList {
    page: number;
    pageEntries: number;
    total: number;
    bookings: IBooking[];
}

export interface IBooking {
    id: number;
    createdAt: Date;
    order: {
        orderCode: string;
        orderStatus: number;
        sumQuantity: number;
        sumTotal: number;
        sumCommission: number;
    };
    product: {
        categoryProductsId: number;
        categorySubProductsId: number;
        productsId: number;
        productOwnerChannel: 'ws' | 'ag';
        tourCode: string;
        productName: string;
        startAt: Date;
        endAt: Date;
        commissionCompany: number;
        commissionSeller: number;
    };
    productOwner: IProductOwner;
    sellerAgencyMember: ISellerAgencyMember;
    contact: {
        contactName: string;
        phoneNumber: string;
        email: string;
        leadTypeSlug: string;
        customerRemark: string;
    };
    firstOrderInstallments: {
        paymentDuedate: Date;
        totalPaid: number;
    };
    otherOrderInstallments: IOtherOrderInstallments;
    orderFiles: IOrderFile[];
}

interface IProductOwner {
    id: number;
    channel: 'ws' | 'ag';
    nameTh: string;
    nameEn: string;
    phoneNumber: string;
    productCode: string;
    wsSlug: string;
    wsOpenOnlineBooking: boolean;
}

interface ISellerAgencyMember {
    firstName: string;
    lastName: string;
    nickName: string;
}

interface IOtherOrderInstallments {
    firstPaymentDuedate: Date;
    sumTotalPaid: number;
}

interface IOrderFile {
    id: number;
    fileUrl: string;
}

export class BookingList {
    public static deserialize(obj: any): IBookingList {
        const bookingList: IBookingList = {
            page: obj.page,
            pageEntries: obj.page_entries,
            total: obj.total,
            bookings: [],
        };

        obj.result.map((objBooking: any) => {
            bookingList.bookings.push(this.deserializeBooking(objBooking));
        });

        return bookingList;
    }

    private static deserializeBooking(obj: any): IBooking {
        const booking: IBooking = {
            id: obj.id,
            createdAt: new Date(obj.created_at),
            order: {
                orderCode: obj.order_code,
                orderStatus: obj.order_status,
                sumQuantity: obj.sum_quantity,
                sumTotal: obj.sum_total,
                sumCommission: obj.sum_commission,
            },
            product: {
                categoryProductsId: obj.pro_category_products_id,
                categorySubProductsId: obj.pro_category_sub_products_id,
                productsId: obj.products_id,
                productOwnerChannel: obj.product_owner_channel,
                tourCode: obj.tour_code,
                productName: obj.product_name,
                startAt: obj.start_at ? new Date(obj.start_at) : null,
                endAt: obj.end_at ? new Date(obj.end_at) : null,
                commissionCompany: obj.commission_company,
                commissionSeller: obj.commission_seller,
            },
            productOwner: this.getProductOwner(obj.product_owner),
            sellerAgencyMember: this.getSellerAgencyMember(obj.seller_agency_member),
            contact: {
                contactName: obj.contact_name,
                phoneNumber: obj.phone_number,
                email: obj.email,
                leadTypeSlug: obj.lead_type_slug,
                customerRemark: obj.customer_remark,
            },
            firstOrderInstallments: {
                paymentDuedate: obj.first_installment ? new Date(obj.first_installment.payment_duedate_at) : null,
                totalPaid: obj.first_installment ? obj.first_installment.total_paid : null,
            },
            otherOrderInstallments: this.getOtherOrderInstallments(obj.other_installments),
            orderFiles: this.getOrderFiles(obj.order_files),
        };

        return booking;
    }

    private static getProductOwner(obj: any): IProductOwner {
        if (!obj) {
            return null;
        }

        return {
            id: obj.id,
            channel: obj.channel,
            nameTh: obj.name_th,
            nameEn: obj.name_en,
            phoneNumber: obj.phone_number,
            productCode: obj.product_code,
            wsSlug: obj.wholesale_slug,
            wsOpenOnlineBooking: !!obj.wholesale_is_channel_ob,
        };
    }

    private static getSellerAgencyMember(obj: any): ISellerAgencyMember {
        if (!obj) {
            return null;
        }

        return {
            firstName: obj.first_name,
            lastName: obj.last_name,
            nickName: obj.nick_name,
        };
    }

    private static getOtherOrderInstallments(obj: any): IOtherOrderInstallments {
        if (!obj) {
            return null;
        }

        return {
            firstPaymentDuedate: obj.first_payment_duedate_at ? new Date(obj.first_payment_duedate_at) : null,
            sumTotalPaid: obj.sum_total_paid || null,
        };
    }

    private static getOrderFiles(obj: any): IOrderFile[] {
        const orderFiles: IOrderFile[] = [];

        // Reverse map
        obj.slice(0)
            .reverse()
            .map((item: any) => {
                orderFiles.push({
                    id: item.id,
                    fileUrl: item.file_url,
                });
            });

        return orderFiles;
    }
}
