import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'catalogTitle',
})
export class CatalogTitlePipe implements PipeTransform {
    transform(value: any, ...args: any[]): any {
        switch (value) {
            case 'promotion':
                return 'โปรโมชั่น';
            case 'voucher':
                return 'คูปอง/เวาเชอร์';
            case 'outbound':
                return 'ทัวร์ต่างประเทศ';
            case 'inbound':
                return 'ทัวร์ในประเทศ';
            case 'package':
                return 'แพ็กเกจทัวร์ต่างประเทศ';
            case 'maldives':
                return 'แพ็กเกจ<br>มัลดีฟส์';
            case 'ship':
                return 'เรือสำราญ';
            case 'airticket':
                return 'ตั๋วเครื่องบิน';
            case 'service':
                return 'สินค้าและ<br>บริการอื่นๆ';
            case 'content':
                return 'บทความ<br>ท่องเที่ยว';
            case 'gallery':
                return 'ผลงานที่ผ่านมา';
            default:
                return '';
        }
    }
}
@Pipe({
    name: 'catalogTitleEN',
})
export class CatalogTitleENPipe implements PipeTransform {
    transform(value: any, ...args: any[]): any {
        switch (value) {
            case 'promotion':
                return '';
            case 'voucher':
                return '(Coupon/Voucher)';
            case 'outbound':
                return '(Outbound Join tour)';
            case 'inbound':
                return '(Domestic Package/Join tour)';
            case 'package':
                return '(Outbound Package Tour)';
            case 'maldives':
                return '';
            case 'ship':
                return '';
            case 'airticket':
                return '';
            case 'service':
                return '';
            case 'content':
                return '';
            case 'gallery':
                return '';
            default:
                return '';
        }
    }
}
