export class SpecialPage {
    id: number;
    slug: string;
    name: string;

    public deserialize(data: any): this {
        this.id = +data.id;
        this.slug = data.slug;
        this.name = data.name;

        return this;
    }
}
