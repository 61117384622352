import { BOOKING_STATUS_CODES } from 'src/app/constants/booking-status-codes.constant';
import { Component, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { State } from 'src/app/stores/reducers';
import { IBooking } from '../../models/booking-list.model';

@Component({
    selector: 'app-cancel-booking',
    templateUrl: './cancel-booking.component.html',
    styleUrls: ['./cancel-booking.component.scss'],
})
export class CancelBookingComponent implements OnInit {
    public booking: IBooking;

    constructor(
        public bsModalRef: BsModalRef,
        public bsModalService: BsModalService,
        private store: Store<State>,
        private router: Router
    ) {}

    ngOnInit() {
        this.bsModalService.setDismissReason('undo');
        this.closeOnRouteChange();
    }

    public getStatusString(): string {
        switch (this.booking.order.orderStatus) {
            case BOOKING_STATUS_CODES.WAITING_FOR_FULL_PAYMENT:
                return 'Booking นี้ ชำระเงินงวดแรกแล้ว';
            case BOOKING_STATUS_CODES.COMPLETE:
                if (this.booking.product.startAt > new Date()) {
                    return 'Booking นี้ ชำระเงินครบ และ เดินทางแล้ว';
                } else {
                    return 'Booking นี้ ชำระเงินครบ และ รอเดินทาง';
                }
        }

        return '';
    }

    public submitButton(): void {
        this.bsModalService.setDismissReason('confirm-cancel-' + this.booking.id);
        this.bsModalRef.hide();
    }

    closeOnRouteChange(): void {
        this.router.events.subscribe((evt) => {
            if (!(evt instanceof NavigationEnd)) {
                return;
            }
            this.bsModalRef.hide();
        });
    }
}
