import { Component, Input, TemplateRef, ViewChild } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { UrlWriter } from 'src/app/helpers/url-writer.helper';
import { WindowDomHelper } from 'src/app/helpers/window-dom.helper';

@Component({
    selector: 'app-social-links',
    templateUrl: './social-links.component.html',
    styleUrls: ['./social-links.component.scss'],
})
export class SocialLinksComponent {
    @ViewChild('CopyLinkSuccessModalTemplate') CopyLinkSuccessModalTemplate: TemplateRef<any>;
    @Input() sharingUrl: string;
    @Input() settings: SocialLinksSettings = new SocialLinksSettings();

    modalRef: BsModalRef;

    constructor(private modalService: BsModalService) {}

    popupLineShare(): void {
        const url = UrlWriter.lineSharingLink(this.sharingUrl);
        WindowDomHelper.popupCenter(url, 'Line Share', 900, 500);
    }

    popupFacebookShare(): void {
        const url = UrlWriter.facebookSharingLink(this.sharingUrl);
        WindowDomHelper.popupCenter(url, 'Facebook Share', 900, 500);
    }

    copyLink(): void {
        UrlWriter.copyLink(this.sharingUrl);
        this.modalRef = this.modalService.show(this.CopyLinkSuccessModalTemplate, {
            class: 'modal-sm modal-dialog-centered',
        });
    }
}

export class SocialLinksSettings {
    fontSize: string = '14px';
    iconWidth: number = 20;
    iconHeight: number = 19;
    buttonWidth: string = 'auto';
    buttonHeight: string = 'auto';
    buttonXPadding: string = '0.5rem';

    static forProgramBox(): SocialLinksSettings {
        const settings = new SocialLinksSettings();
        settings.fontSize = '11px';
        settings.iconWidth = 15;
        settings.iconHeight = 14;
        settings.buttonWidth = '56px';
        settings.buttonXPadding = '3px';
        settings.buttonHeight = '25px';
        return settings;
    }
}
