<div class="list-container">
    <div class="list-row">
        <div class="first-row">
            <label>รายการ</label>
        </div>
        <div class="second-row text-center">
            <label>ราคาต่อคน</label>
        </div>
        <div class="third-row text-center">
            <label>จำนวน</label>
        </div>
        <div class="fourth-row text-center">
            <label>จำนวนเงิน</label>
        </div>
        <div class="fifth-row"></div>
    </div>

    <ng-container *ngFor="let item of bookingOrderItems; let i = index">
        <div class="list-row">
            <div class="first-row">
                <textarea
                    [(ngModel)]="item.description"
                    [class.red-border]="!validFields.description"
                    (input)="isEdit ? (item.to_update = true) : null"
                    name="description"
                    class="form-control"
                    placeholder="กรอกรายการ"
                    maxlength="200"
                    (input)="emitToParent()"
                    (input)="adjustHeight($event)"
                ></textarea>
            </div>
            <div class="second-row">
                <input
                    [(ngModel)]="item.price"
                    [class.red-border]="!validFields.price"
                    (change)="isEdit ? (item.to_update = true) : null"
                    name="price"
                    type="text"
                    class="form-control"
                    placeholder=""
                    maxlength="10"
                    appMycurrency
                    (change)="calculateTotal(i); emitToParent()"
                />
            </div>
            <div class="third-row">
                <input
                    [(ngModel)]="item.quantity"
                    appMycurrency
                    [class.red-border]="!validFields.quantity"
                    (change)="isEdit ? (item.to_update = true) : null"
                    (change)="calculateTotal(i); emitToParent()"
                    name="price"
                    type="text"
                    class="form-control"
                    placeholder=""
                    maxlength="4"
                />
            </div>
            <div class="fourth-row">
                <input
                    [(ngModel)]="item.total"
                    [class.red-border]="!validFields.total"
                    (change)="isEdit ? (item.to_update = true) : null"
                    name="price"
                    type="text"
                    class="form-control"
                    placeholder=""
                    maxlength="10"
                    appMycurrency
                    (change)="emitToParent()"
                />
            </div>
            <div class="fifth-row">
                <ng-container *ngIf="i > 0">
                    <i
                        class="icon icon-garbage"
                        (click)="removeItem(i)"
                        style="color: #ff010a; cursor: pointer; font-size: 20px"
                    ></i>
                </ng-container>
            </div>
        </div>
    </ng-container>

    <div class="list-row mt-1">
        <div class="add-button">
            <a (click)="addItem()" class="btn btn-add" [class.disabled]="bookingOrderItems.length >= 10">
                <i class="icon icon-plus-circle mr-1"></i> เพิ่มรายการ
            </a>
        </div>
        <div class="total-label">
            <label>จำนวนเงินจ่ายสุทธิ</label>
        </div>
        <div class="total-amount">
            <p>{{ calculateGrandTotal() | number: '1.2' }}</p>
        </div>
    </div>
</div>
