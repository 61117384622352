export const CATEGORY_SUB_PRODUCTS = {
    JOIN: 1,
    PACKAGE: 2,
    PACKAGE_DO: 6,
    JOIN_DO: 7,
    CHAOPHRAYA_CRUISE: 32,
};

export const CATEGORY_SUB_PRODUCTS_TO_STRING = {
    1: 'Outbound Join Tour',
    2: 'Outbound Package Tour',
    6: 'Domestic Tour',
    7: 'Domestic Join Tour',
    32: 'Chaophraya Cruise',
};

export const CATEGORY_SUB_PRODUCTS_FOR_TW_BOOKING = {
    1: 'Join Tour - Outbound',
    7: 'Join Tour - Domestic',
};
