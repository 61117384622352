export interface IVoucher {
    id: number;
    name: string;
    price: number;
    priceCompare: number;
    maxQuantity: number;
    quantitySold: number;
    quantityUsed: number;
    quantityRemaining: number;

    commissionAgency: number;
}

export class Voucher implements IVoucher {
    id: number;
    name: string;
    price: number;
    priceCompare: number;
    maxQuantity: number;
    quantitySold: number;
    quantityUsed: number;
    quantityRemaining: number;

    commissionAgency: number;

    public deserialize(data: any): this {
        this.id = data.id;
        this.maxQuantity = data.max_quantity;
        this.name = data.name;
        this.price = data.price;
        this.priceCompare = data.price_compare;
        this.quantityRemaining = data.quantity_remaining;
        this.quantitySold = data.quantity_sold;
        this.quantityUsed = data.quantity_used;
        this.commissionAgency = +data.commission_agency;

        return this;
    }
}
