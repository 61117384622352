export interface Agency {
    id: number;
    nameTH?: string;
    nameEN?: string;
    licenseNumber?: string;
    phoneNumber?: string;
    email?: string;
    facebookName?: string;
    lineId?: string;
    lineQrUrl?: string;
    lineUrl?: string;
    address?: string;
    slug?: string;
    productCode?: string;
    productDescription?: string;

    isActiveTourwowMall: boolean;
    allowPartnersToSellProduct?: boolean;
    allowSellTourwow?: boolean;
    displayCommission?: boolean;
    useSeoArticle: boolean;

    displayName: string;

    productCategories: AgencyProductCategory[];
}

export class AgencyProductCategory {
    public id: number;
    public slug: string;
    public name: string;
    public productSubCategories: AgencyProductSubCategory[];
    public isAllowed: boolean;

    public deserialize(data: any): this {
        this.id = data.id;
        this.slug = data.slug;
        this.name = data.name;
        this.productSubCategories = (data.product_sub_categories as any[]).map((subCategory) =>
            new AgencyProductSubCategory().deserialize(subCategory)
        );
        this.isAllowed = false;
        for (const subCategory of this.productSubCategories) {
            if (subCategory.isAllowed) this.isAllowed = true;
        }

        return this;
    }
}

export class AgencyProductSubCategory {
    public id: number;
    public slug: string;
    public nameEN: string;
    public nameTh: string;
    public isAllowed: boolean;

    public deserialize(data: any): this {
        this.id = data.id;
        this.slug = data.slug;
        this.nameEN = data.name;
        this.nameTh = data.name_th;
        this.isAllowed = data.is_allowed;

        return this;
    }
}
