import { PhoneNumberPipe } from './../../pipes/phone-number.pipe';
import { LeadMonthlyReport } from './../../models/lead-monthly-report.model';
import { ILeadCountByType, LeadCountByType } from 'src/app/modules/report/models/lead-count-by-type.model';
import { Injectable } from '@angular/core';
import { createEffect, Actions, ofType } from '@ngrx/effects';

import * as ReportActions from './report.actions';
import { switchMap, map, catchError } from 'rxjs/operators';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { ReportService } from 'src/app/services/report.service';
import { of } from 'rxjs';
import { LeadDetails } from 'src/app/modules/report/models/lead-details.model';
import { OrderBalancePeriod } from 'src/app/models/order-balance-period.model';
import { OrderBalanceReport, OrderBalanceReportOverView } from 'src/app/models/order-balance-report.model';
import { OrderBalance } from 'src/app/modules/report/models/order-balance-report.model';

@Injectable()
export class ReportEffects {
    constructor(
        private actions$: Actions,
        private ngxService: NgxUiLoaderService,
        private reportService: ReportService,
        private phoneNumberPipe: PhoneNumberPipe
    ) {}

    loadLeadList = createEffect(
        () => () =>
            this.actions$.pipe(
                ofType(ReportActions.getLeadList),
                switchMap((action) => {
                    this.ngxService.start();
                    return this.reportService.getLeadList(action.criteria, action.searchFilter || null).pipe(
                        map((resp) => {
                            this.ngxService.stop();
                            if (resp.status == 'success') {
                                const leadList = new LeadDetails().deserialize(resp.data.result);
                                this.ngxService.stop();
                                const pageDetails = {
                                    totalResults: resp.data.total,
                                    page: resp.data.page,
                                };
                                return ReportActions.loadLeadListSuccess({
                                    leadList,
                                    pageDetails,
                                    nextPage: action.nextPage,
                                });
                            } else {
                                this.ngxService.stop();
                                return ReportActions.loadLeadListError({
                                    error: resp,
                                });
                            }
                        }),
                        catchError((error) => {
                            this.ngxService.stop();
                            return of(
                                ReportActions.loadLeadListError({
                                    error,
                                })
                            );
                        })
                    );
                })
            )
    );

    addLead = createEffect(
        () => () =>
            this.actions$.pipe(
                ofType(ReportActions.addNewLead),
                switchMap((action) => {
                    this.ngxService.start();
                    return this.reportService.addLead(action.requestBody).pipe(
                        map((resp) => {
                            this.ngxService.stop();
                            if (resp.status == 'success') {
                                this.ngxService.stop();
                                return ReportActions.addLeadSuccess({
                                    leadId: parseInt(resp.data.lead_id),
                                });
                            } else {
                                this.ngxService.stop();
                                return ReportActions.addLeadError({
                                    error: resp,
                                });
                            }
                        }),
                        catchError((error) => {
                            this.ngxService.stop();
                            return of(
                                ReportActions.addLeadError({
                                    error,
                                })
                            );
                        })
                    );
                })
            )
    );

    editExistingLead = createEffect(
        () => () =>
            this.actions$.pipe(
                ofType(ReportActions.editExistingLead),
                switchMap((action) => {
                    this.ngxService.start();
                    return this.reportService.editLead(action.leadId, action.requestBody).pipe(
                        map((resp) => {
                            this.ngxService.stop();
                            if (resp.status == 'success') {
                                this.ngxService.stop();
                                return ReportActions.editLeadSuccess({
                                    leadId: action.refresh ? parseInt(resp.data.lead_id) : null,
                                });
                            } else {
                                this.ngxService.stop();
                                return ReportActions.editLeadError({
                                    error: resp,
                                });
                            }
                        }),
                        catchError((error) => {
                            this.ngxService.stop();
                            return of(
                                ReportActions.editLeadError({
                                    error,
                                })
                            );
                        })
                    );
                })
            )
    );

    getLeadCount = createEffect(
        () => () =>
            this.actions$.pipe(
                ofType(ReportActions.getLeadCount),
                switchMap((action) => {
                    return this.reportService.getLeadCount(action.statusSlug).pipe(
                        map((resp) => {
                            if (resp.status == 'success') {
                                return ReportActions.getLeadCountSuccess({
                                    leadCount: parseInt(resp.data.lead_count),
                                });
                            } else {
                                return ReportActions.getLeadCountError({
                                    error: resp,
                                });
                            }
                        }),
                        catchError((error) => {
                            return of(
                                ReportActions.getLeadCountError({
                                    error,
                                })
                            );
                        })
                    );
                })
            )
    );

    getLeadCountByType = createEffect(
        () => () =>
            this.actions$.pipe(
                ofType(ReportActions.getLeadCountByType),
                switchMap((action) => {
                    return this.reportService.getLeadCountByType(action.searchFilter).pipe(
                        map((resp) => {
                            if (resp.status == 'success') {
                                const leadCountByType: ILeadCountByType = new LeadCountByType().deserialize(resp.data);
                                return ReportActions.getLeadCountByTypeSuccess({
                                    leadCountByType: leadCountByType,
                                });
                            } else {
                                return ReportActions.getLeadCountByTypeError({
                                    error: resp,
                                });
                            }
                        }),
                        catchError((error) => {
                            return of(
                                ReportActions.getLeadCountByTypeError({
                                    error,
                                })
                            );
                        })
                    );
                })
            )
    );

    updateLeadStatus = createEffect(
        () => () =>
            this.actions$.pipe(
                ofType(ReportActions.updateLeadStatus),
                switchMap((action) => {
                    this.ngxService.start();
                    return this.reportService.updateLeadStatus(action.id, action.statusSlug).pipe(
                        map((resp) => {
                            if (resp.status == 'success') {
                                this.ngxService.stop();
                                return ReportActions.updateLeadStatusSuccess({
                                    leadId: parseInt(resp.data.lead_id),
                                    statusSlug: action.statusSlug,
                                });
                            } else {
                                this.ngxService.stop();
                                return ReportActions.updateLeadStatusFailure({
                                    error: resp,
                                });
                            }
                        }),
                        catchError((error) => {
                            this.ngxService.stop();
                            return of(
                                ReportActions.updateLeadStatusFailure({
                                    error,
                                })
                            );
                        })
                    );
                })
            )
    );

    loadBookingRequestLeadList = createEffect(
        () => () =>
            this.actions$.pipe(
                ofType(ReportActions.getBookingRequestLeadList),
                switchMap((action) => {
                    this.ngxService.start();
                    return this.reportService
                        .getBookingRequestLeadList(action.criteria, action.searchFilter || null)
                        .pipe(
                            map((resp) => {
                                this.ngxService.stop();
                                if (resp.status == 'success') {
                                    this.ngxService.stop();
                                    const pageDetails = {
                                        totalResults: resp.data.total,
                                        page: resp.data.page,
                                    };
                                    return ReportActions.loadBookingRequestLeadListSuccess({
                                        bookingRequestLeadList: resp.data.result,
                                        pageDetails,
                                        nextPage: action.nextPage,
                                    });
                                } else {
                                    this.ngxService.stop();
                                    return ReportActions.loadBookingRequestLeadListError({
                                        error: resp,
                                    });
                                }
                            }),
                            catchError((error) => {
                                this.ngxService.stop();
                                return of(
                                    ReportActions.loadBookingRequestLeadListError({
                                        error,
                                    })
                                );
                            })
                        );
                })
            )
    );

    getLeadBookingRequestCount = createEffect(
        () => () =>
            this.actions$.pipe(
                ofType(ReportActions.getLeadBookingRequestCount),
                switchMap((action) => {
                    return this.reportService.getLeadBookingRequestCount(action.statusSlug).pipe(
                        map((resp) => {
                            if (resp.status == 'success') {
                                return ReportActions.getLeadBookingRequestCountSuccess({
                                    leadBookingRequestCount: parseInt(resp.data.b2b_lead_count),
                                });
                            } else {
                                return ReportActions.getLeadBookingRequestCountError({
                                    error: resp,
                                });
                            }
                        }),
                        catchError((error) => {
                            return of(
                                ReportActions.getLeadBookingRequestCountError({
                                    error,
                                })
                            );
                        })
                    );
                })
            )
    );

    updateBookingRequestLeadStatus = createEffect(
        () => () =>
            this.actions$.pipe(
                ofType(ReportActions.updateBookingRequestLeadStatus),
                switchMap((action) => {
                    this.ngxService.start();
                    return this.reportService.updateBookingRequestLeadStatus(action.id, action.statusSlug).pipe(
                        map((resp) => {
                            if (resp.status == 'success') {
                                this.ngxService.stop();
                                return ReportActions.updateBookingRequestLeadStatusSuccess({
                                    leadId: parseInt(resp.data.b2b_lead_id),
                                    statusSlug: action.statusSlug,
                                });
                            } else {
                                this.ngxService.stop();
                                return ReportActions.updateBookingRequestLeadStatusFailure({
                                    error: resp,
                                });
                            }
                        }),
                        catchError((error) => {
                            this.ngxService.stop();
                            return of(
                                ReportActions.updateBookingRequestLeadStatusFailure({
                                    error,
                                })
                            );
                        })
                    );
                })
            )
    );

    addBookingRequestLead = createEffect(
        () => () =>
            this.actions$.pipe(
                ofType(ReportActions.addBookingRequestLead),
                switchMap((action) => {
                    this.ngxService.start();
                    return this.reportService.addBookingRequestLead(action.requestBody).pipe(
                        map((resp) => {
                            this.ngxService.stop();
                            if (resp.status == 'success') {
                                this.ngxService.stop();
                                return ReportActions.addBookingRequestLeadSuccess({
                                    leadBookingRequestId: parseInt(resp.data.b2b_lead_id),
                                });
                            } else {
                                this.ngxService.stop();
                                return ReportActions.addBookingRequestLeadError({
                                    error: resp,
                                });
                            }
                        }),
                        catchError((error) => {
                            this.ngxService.stop();
                            return of(
                                ReportActions.addBookingRequestLeadError({
                                    error,
                                })
                            );
                        })
                    );
                })
            )
    );

    getBookingRequestLeadSingleDetails = createEffect(
        () => () =>
            this.actions$.pipe(
                ofType(ReportActions.getBookingRequestLeadDetails),
                switchMap((action) => {
                    this.ngxService.start();
                    return this.reportService.getBookingRequestLeadInfo(action.leadId).pipe(
                        map((resp) => {
                            this.ngxService.stop();
                            if (resp.status == 'success') {
                                this.ngxService.stop();
                                return ReportActions.getBookingRequestLeadDetailsSuccess({
                                    responseBody: resp.data,
                                });
                            } else {
                                this.ngxService.stop();
                                return ReportActions.getBookingRequestLeadDetailsError({
                                    error: resp,
                                });
                            }
                        }),
                        catchError((error) => {
                            this.ngxService.stop();
                            return of(
                                ReportActions.getBookingRequestLeadDetailsError({
                                    error,
                                })
                            );
                        })
                    );
                })
            )
    );

    loadLeadSummaryList = createEffect(
        () => () =>
            this.actions$.pipe(
                ofType(ReportActions.getLeadSummaryList),
                switchMap((action) => {
                    this.ngxService.start();
                    return this.reportService.getProductSummaryList(action.criteria, action.searchFilter || null).pipe(
                        map((resp) => {
                            if (resp.status == 'success') {
                                this.ngxService.stop();
                                const pageDetails = {
                                    totalResults: resp.data.total,
                                    page: resp.data.page,
                                };
                                return ReportActions.getLeadSummaryListSuccess({
                                    leadSummaryList: resp.data.result,
                                    pageDetails,
                                    nextPage: action.nextPage,
                                });
                            } else {
                                this.ngxService.stop();
                                return ReportActions.getLeadSummaryListError({
                                    error: resp,
                                });
                            }
                        }),
                        catchError((error) => {
                            this.ngxService.stop();
                            return of(
                                ReportActions.getLeadSummaryListError({
                                    error,
                                })
                            );
                        })
                    );
                })
            )
    );

    getActiveProductCountForSummary = createEffect(
        () => () =>
            this.actions$.pipe(
                ofType(ReportActions.getProductCountForSummary),
                switchMap((action) => {
                    return this.reportService.getActiveProductsCountForSummary(action.searchFilter || null).pipe(
                        map((resp) => {
                            if (resp.status == 'success') {
                                const countData = {
                                    total: parseInt(resp.data.total_count),
                                    pending: parseInt(resp.data.pending_count),
                                    confirm: parseInt(resp.data.confirm_count),
                                    cancel: parseInt(resp.data.cancel_count),
                                };
                                return ReportActions.getProductCountForSummarySuccess({
                                    countData,
                                });
                            } else {
                                return ReportActions.getProductCountForSummaryError({
                                    error: resp,
                                });
                            }
                        }),
                        catchError((error) => {
                            return of(
                                ReportActions.getProductCountForSummaryError({
                                    error,
                                })
                            );
                        })
                    );
                })
            )
    );

    loadStatisticsForProductSummary = createEffect(
        () => () =>
            this.actions$.pipe(
                ofType(ReportActions.getStatisticsForProductSummary),
                switchMap((action) => {
                    return this.reportService.getStatisticsForProductSummary(action.idList).pipe(
                        map((resp) => {
                            if (resp.status == 'success') {
                                return ReportActions.getStatisticsForProductSummarySuccess({
                                    statisticsData: resp.data,
                                });
                            } else {
                                return ReportActions.getStatisticsForProductSummaryError({
                                    error: resp,
                                });
                            }
                        }),
                        catchError((error) => {
                            return of(
                                ReportActions.getStatisticsForProductSummaryError({
                                    error,
                                })
                            );
                        })
                    );
                })
            )
    );

    loadLeadStatMonthly = createEffect(
        () => () =>
            this.actions$.pipe(
                ofType(ReportActions.getLeadStatMonthly),
                switchMap((action) => {
                    this.ngxService.start();
                    return this.reportService.getLeadStatMonthly(action.criteria).pipe(
                        map((resp) => {
                            this.ngxService.stop();
                            if (resp.status == 'success') {
                                return ReportActions.getLeadStatMonthlySuccess({
                                    leadStatMonthly: resp.data,
                                });
                            } else {
                                throw new Error();
                            }
                        }),
                        catchError((error) => {
                            return of(ReportActions.getLeadStatMonthlyError({ error }));
                        })
                    );
                })
            )
    );

    loadLeadStatMonthlyDetails = createEffect(
        () => () =>
            this.actions$.pipe(
                ofType(ReportActions.getLeadStatMonthlyDetails),
                switchMap((action) => {
                    this.ngxService.start();
                    return this.reportService.getLeadStatMonthlyDetails(action.criteria).pipe(
                        map((resp) => {
                            this.ngxService.stop();
                            if (resp.status == 'success') {
                                return ReportActions.getLeadStatMonthlyDetailsSuccess({
                                    leadStatMonthlyDetails: new LeadMonthlyReport().deserialize(
                                        resp.data,
                                        this.phoneNumberPipe
                                    ),
                                });
                            } else {
                                throw new Error();
                            }
                        }),
                        catchError((error) => {
                            return of(ReportActions.getLeadStatMonthlyDetailsError({ error }));
                        })
                    );
                })
            )
    );

    loadOrderBalancePeriod = createEffect(
        () => () =>
            this.actions$.pipe(
                ofType(ReportActions.getOrderBalancePeriod),
                switchMap((action) => {
                    this.ngxService.start();
                    return this.reportService.getOrderBalancePeriod().pipe(
                        map((resp) => {
                            this.ngxService.stop();
                            if (resp.status == 'success') {
                                return ReportActions.getOrderBalancePeriodSuccess({
                                    data: new OrderBalancePeriod().deserialize(resp.data),
                                });
                            } else {
                                throw new Error();
                            }
                        }),
                        catchError((error) => {
                            return of(ReportActions.getOrderBalancePeriodError({ error }));
                        })
                    );
                })
            )
    );

    getOrderBalance = createEffect(
        () => () =>
            this.actions$.pipe(
                ofType(ReportActions.getOrderBalance),
                switchMap((action) => {
                    this.ngxService.start();
                    return this.reportService.getOrderBalance().pipe(
                        map((resp) => {
                            this.ngxService.stop();
                            if (resp.status == 'success') {
                                return ReportActions.getOrderBalanceSuccess({
                                    orderBalance: new OrderBalance().deserialize(resp.data),
                                });
                            } else {
                                throw new Error();
                            }
                        }),
                        catchError((error) => {
                            return of(ReportActions.getOrderBalanceError({ error }));
                        })
                    );
                })
            )
    );

    loadOrderBalanceReport = createEffect(
        () => () =>
            this.actions$.pipe(
                ofType(ReportActions.getOrderBalanceReport),
                switchMap((action) => {
                    this.ngxService.start();
                    return this.reportService.getOrderBalanceReport(action.criteria).pipe(
                        map((resp) => {
                            this.ngxService.stop();
                            if (resp.status == 'success') {
                                return ReportActions.getOrderBalanceReportSuccess({
                                    data: new OrderBalanceReportOverView().deserialize(resp.data),
                                    nextPage: action.nextPage,
                                });
                            } else {
                                throw new Error();
                            }
                        }),
                        catchError((error) => {
                            return of(ReportActions.getOrderBalanceReportError({ error }));
                        })
                    );
                })
            )
    );

    confirmOrderBalance = createEffect(
        () => () =>
            this.actions$.pipe(
                ofType(ReportActions.confirmOrderBalance),
                switchMap((action) => {
                    this.ngxService.start();
                    return this.reportService.confirmOrderBalance(action.orderId).pipe(
                        map((resp) => {
                            this.ngxService.stop();
                            if (resp.status == 'success') {
                                return ReportActions.confirmOrderBalanceSuccess({
                                    result: true,
                                });
                            } else {
                                alert('Confirming order balance failure');
                                throw new Error();
                            }
                        }),
                        catchError((error) => {
                            return of(ReportActions.confirmOrderBalanceError({ error }));
                        })
                    );
                })
            )
    );

    loadOrderBalanceReportItems = createEffect(
        () => () =>
            this.actions$.pipe(
                ofType(ReportActions.getOrderBalanceReportItems),
                switchMap((action) => {
                    this.ngxService.start();
                    return this.reportService.getOrderBalanceReportItems(action.criteria).pipe(
                        map((resp) => {
                            this.ngxService.stop();
                            if (resp.status == 'success') {
                                return ReportActions.getOrderBalanceReportItemsSuccess({
                                    data: new OrderBalanceReport().deserialize(resp.data),
                                    nextPage: action.nextPage,
                                });
                            } else {
                                throw new Error();
                            }
                        }),
                        catchError((error) => {
                            return of(ReportActions.getOrderBalanceReportItemsError({ error }));
                        })
                    );
                })
            )
    );

    getBalanceReceiptUri = createEffect(
        () => () =>
            this.actions$.pipe(
                ofType(ReportActions.getBalanceReceiptUri),
                switchMap((action) => {
                    this.ngxService.start();
                    return this.reportService.getBalanceReceiptUri(action.balanceId, action.receiptId).pipe(
                        map((resp) => {
                            this.ngxService.stop();
                            if (resp.status == 'success') {
                                return ReportActions.getBalanceReceiptUriSuccessful({
                                    balanceId: action.balanceId,
                                    receiptId: action.receiptId,
                                    receiptUri: resp.data.file_url,
                                });
                            } else {
                                throw new Error();
                            }
                        }),
                        catchError((error) => {
                            return of(ReportActions.getBalanceReceiptUriError({ error }));
                        })
                    );
                })
            )
    );
}
