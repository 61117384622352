import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import * as dayjs from 'dayjs';
import * as _ from 'lodash-es';
import { IOrderStatusCheckList, TW_ORDER_STATUS_CHECK_LIST } from 'src/app/constants/tw-order-status.constant';
import { ITwBookingPaginatedFilter } from '../../models/interfaces/tw-booking-interface/tw-booking-paginated-filter.interface';

@Component({
    selector: 'app-search-tw-booking-box',
    templateUrl: './search-tw-booking-box.component.html',
    styleUrls: ['./search-tw-booking-box.component.scss'],
})
export class SearchTwBookingBoxComponent implements OnInit {
    @Output() search: EventEmitter<ITwBookingPaginatedFilter> = new EventEmitter<ITwBookingPaginatedFilter>();
    @Input() defaultFilters!: ITwBookingPaginatedFilter;

    searchForm: FormGroup;

    filter: ITwBookingPaginatedFilter;

    orderStatusList: IOrderStatusCheckList[] = [];

    constructor(private fb: FormBuilder) {}

    ngOnInit(): void {
        this.setOrderStatusList();
        this.initForm();
        this.setDefaultForm();
    }

    initForm(): void {
        this.searchForm = this.fb.group({
            search_string: '',
            created_at_between: this.fb.group({
                min_date: '',
                max_date: '',
            }),
            due_date_at_between: this.fb.group({
                min_date: '',
                max_date: '',
            }),
            order_statuses: [],
        });
    }

    onCheckboxChange(event, item: IOrderStatusCheckList): void {
        if (event.target.checked) {
            item.checked = true;
            if (!item.value) {
                this.orderStatusList.forEach((status) => {
                    if (status.value != item.value) {
                        status.checked = false;
                    }
                });
            } else {
                this.orderStatusList[0].checked = false;
            }
        } else {
            item.checked = false;
        }
    }

    onSubmit(): void {
        this.filter = this.prepareData(this.searchForm.value);

        this.search.emit(this.filter);
    }

    prepareData(filterData: ITwBookingPaginatedFilter): ITwBookingPaginatedFilter {
        filterData.order_statuses = this.setOrderStatus();
        filterData.created_at_between.min_date = this.setDateFormat(filterData.created_at_between.min_date);
        filterData.created_at_between.max_date = this.setDateFormat(filterData.created_at_between.max_date);
        filterData.due_date_at_between.min_date = this.setDateFormat(filterData.due_date_at_between.min_date);
        filterData.due_date_at_between.max_date = this.setDateFormat(filterData.due_date_at_between.max_date);

        return filterData;
    }

    setOrderStatus(): string[] {
        return this.orderStatusList
            .filter((status) => {
                return status.checked && status.value != null;
            })
            .map((status) => status.value);
    }

    setDateFormat(date: string): string {
        if (date) {
            return dayjs(date).format('YYYY-MM-DD');
        } else {
            return '';
        }
    }

    setOrderStatusList(): void {
        this.orderStatusList = _.cloneDeep(TW_ORDER_STATUS_CHECK_LIST);
    }

    getCreatedAtBetweenForm(): FormGroup {
        return this.searchForm.get('created_at_between') as FormGroup;
    }

    getDueDateAtBetweenForm(): FormGroup {
        return this.searchForm.get('due_date_at_between') as FormGroup;
    }

    onReset(): void {
        this.searchForm.reset();
        this.setOrderStatusList();
    }

    setDefaultForm(): void {
        if (!this.defaultFilters) return;

        this.searchForm.setValue(this.defaultFilters);
    }
}
