import { ICreateBlog, IEditBlogSubmit } from './../../modules/settings/models/blog.model';
import { IAddNewPortfolioItem } from './../../modules/settings/models/portfolio-add.model';
import { props, createAction } from '@ngrx/store';
import { ICustomContent } from 'src/app/models/custom-content.model';
import { BannerConfigs, IBannerUpdaterRequest } from 'src/app/modules/settings/models/config-banners.model';
import { CustomContentUpdaterResponse } from './models/custom-content-updater-response.model';
import { InvoiceConfigs } from 'src/app/models/invoice-config.model';
import { SeoArticleListItem } from 'src/app/modules/settings/models/seo-article/seo-article-list-item.model';
import { ISeoArticleListFilter, ISeoArticleOptionFilter } from 'src/app/services/seo-article.service';
import { TargetPageType } from 'src/app/modules/settings/models/seo-article/target-page-type.model';
import { SpecialPage } from 'src/app/modules/settings/models/seo-article/special-page.model';
import { SeoArticleOptionItem } from 'src/app/modules/settings/models/seo-article/seo-article-option-item.model';
import { ISeoArticle, SeoArticle } from 'src/app/modules/settings/models/seo-article/seo-article.model';
import { INewSeoArticle } from 'src/app/modules/settings/models/seo-article/new-seo-article.model';
import { ISeoArticleContentUpdate } from 'src/app/modules/settings/models/seo-article/seo-article-content-update.model';
import { ISeoArticleUpdate } from 'src/app/modules/settings/models/seo-article/seo-article-update.model';

export enum ActionTypes {
    UPDATE_INVOICE_CONFIGS = '[Setting] Update invoice configs.',
    UPDATE_INVOICE_CONFIGS_SUCCESS = '[Setting] Update invoice configs successfully.',
    UPDATE_INVOICE_CONFIGS_FAILURE = '[Setting] Update invoice configs unsuccessfully.',
    RESET_INVOICE_CONFIGS_RESULT = '[Setting] Reset invoice configs result.',

    GET_SEO_ARTICLE_LISTING = '[Setting] Get seo article listing.',
    GET_SEO_ARTICLE_LISTING_SUCCESS = '[Setting] Get seo article listing success.',
    GET_SEO_ARTICLE_LISTING_FAILURE = '[Setting] Get seo article listing failed.',

    GET_SEO_ARTICLE_AS_OPTIONS = '[Setting] Get seo article as options.',
    GET_SEO_ARTICLE_AS_OPTIONS_SUCCESS = '[Setting] Get seo article as options success.',
    GET_SEO_ARTICLE_AS_OPTIONS_FAILURE = '[Setting] Get seo article as options failed.',

    GET_SEO_ARTICLE = '[Setting] Get seo article.',
    GET_SEO_ARTICLE_SUCCESS = '[Setting] Get seo article success.',
    GET_SEO_ARTICLE_FAILURE = '[Setting] Get seo article failed.',

    ADD_NEW_SEO_ARTICLE = '[Setting] Added a new seo article.',
    ADD_NEW_SEO_ARTICLE_SUCCESS = '[Setting] Added a new seo article success.',
    ADD_NEW_SEO_ARTICLE_FAILURE = '[Setting] Added a new seo article failed.',

    UPDATE_NEW_SEO_ARTICLE = '[Setting] Updated a seo article',
    UPDATE_NEW_SEO_ARTICLE_SUCCESS = '[Setting] Updated a seo article success',
    UPDATE_NEW_SEO_ARTICLE_FAILURE = '[Setting] Updated a seo article failed',

    UPDATE_SEO_ARTICLE_STATUS = '[Setting] Update seo article status.',
    UPDATE_SEO_ARTICLE_STATUS_SUCCESS = '[Setting] Update seo article status success.',
    UPDATE_SEO_ARTICLE_STATUS_FAILURE = '[Setting] Update seo article status failed.',

    GET_SEO_TARGET_PAGE_TYPES = '[Setting] Get seo target page types.',
    GET_SEO_TARGET_PAGE_TYPES_SUCCESS = '[Setting] Get seo target page types success.',
    GET_SEO_TARGET_PAGE_TYPES_FAILURE = '[Setting] Get seo target page types failed.',

    GET_SEO_SPECIAL_PAGES = '[Setting] Get seo special pages.',
    GET_SEO_SPECIAL_PAGES_SUCCESS = '[Setting] Get seo special pages success.',
    GET_SEO_SPECIAL_PAGES_FAILURE = '[Setting] Get seo special pages failed.',

    UPDATE_SEO_ARTICLE_CONTENT = '[Setting] Updated a seo article content',
    UPDATE_SEO_ARTICLE_CONTENT_SUCCESS = '[Setting] Updated a seo article content success',
    UPDATE_SEO_ARTICLE_CONTENT_FAILURE = '[Setting] Updated a seo article content failed',

    UPDATE_SEO_ARTICLE = '[Setting] Updated a seo article',
    UPDATE_SEO_ARTICLE_SUCCESS = '[Setting] Updated a seo article success',
    UPDATE_SEO_ARTICLE_FAILURE = '[Setting] Updated a seo article failed',
}

export const loadConfigBanners = createAction('[Setting] Fetching config banners');

export const loadConfigBannersSuccess = createAction(
    '[Setting] Config banners loaded successfully',
    props<{ configBanners: BannerConfigs }>()
);

export const loadConfigBannersError = createAction(
    '[Setting] Config banners loaded unsuccessfully',
    props<{ error: string }>()
);

export const updateConfigBanners = createAction(
    '[Setting] Updating config banners',
    props<{ requestBody: IBannerUpdaterRequest }>()
);

export const updateConfigBannersSuccess = createAction('[Setting] Config banners updated successfully');

export const updateConfigBannersError = createAction(
    '[Setting] Config banners updated unsuccessfully',
    props<{ error: string }>()
);

export const loadCustomContent = createAction('[Setting] Load custom content.', props<{ contentId: number }>());
export const loadCustomContentSuccess = createAction(
    '[Setting] Load custom content successfully.',
    props<{ content: ICustomContent }>()
);
export const loadCustomContentFailure = createAction(
    '[Setting] Load custom content failed.',
    props<{ error: string }>()
);
export const loadCustomContentReset = createAction('[Setting] Load custom content resetted.');

export const updateCustomContent = createAction(
    '[Setting] Update custom content.',
    props<{ content: ICustomContent }>()
);
export const updateCustomContentSuccess = createAction(
    '[Setting] Update custom content successfully.',
    props<{ response: CustomContentUpdaterResponse }>()
);
export const updateCustomContentFailure = createAction(
    '[Setting] Update custom content failed.',
    props<{ error: any }>()
);
export const updateCustomContentReset = createAction('[Setting] Update custom content resetted.');

export const getPortfolioListing = createAction('[Setting] Get portfolio listing.', props<{ criteria: any }>());
export const getPortfolioListingSuccess = createAction(
    '[Setting] Get portfolio listing success.',
    props<{ response: any }>()
);
export const getPortfolioListingFailure = createAction(
    '[Setting] Get portfolio listing failed.',
    props<{ error: any }>()
);

export const getPortfolioSingle = createAction('[Setting] Get portfolio single.', props<{ id: number }>());
export const getPortfolioSingleSuccess = createAction(
    '[Setting] Get portfolio single success.',
    props<{ response: any }>()
);
export const getPortfolioSingleFailure = createAction(
    '[Setting] Get portfolio single failed.',
    props<{ error: any }>()
);

export const addPortfolioItem = createAction(
    '[Setting] Add portfolio item.',
    props<{ requestBody: IAddNewPortfolioItem }>()
);
export const addPortfolioItemSuccess = createAction(
    '[Setting] Add portfolio item success.',
    props<{ portfolioNewId: number }>()
);
export const addPortfolioItemError = createAction('[Setting] Add portfolio item failed.', props<{ error: any }>());

export const editPortfolioItem = createAction(
    '[Setting] Edit portfolio item.',
    props<{ id: number; requestBody: IAddNewPortfolioItem }>()
);
export const editPortfolioItemSuccess = createAction(
    '[Setting] Edit portfolio item success.',
    props<{ portfolioEditedId: number }>()
);
export const editPortfolioItemError = createAction('[Setting] Edit portfolio item failed.', props<{ error: any }>());

export const deletePortfolioItem = createAction('[Setting] Delete portfolio item.', props<{ id: number }>());
export const deletePortfolioItemSuccess = createAction(
    '[Setting] Delete portfolio item success.',
    props<{ portfolioDeletedId: number }>()
);
export const deletePortfolioItemError = createAction(
    '[Setting] Delete portfolio item failed.',
    props<{ error: any }>()
);

export const updateIncentiveImg = createAction('[Setting] Update incentive img.', props<{ form: FormData }>());
export const updateIncentiveImgSuccess = createAction(
    '[Setting] Update incentive img success.',
    props<{ response: any }>()
);
export const updateIncentiveImgError = createAction('[Setting] Update incentive img failed.', props<{ error: any }>());

export const getIncentiveImg = createAction('[Setting] Get incentive img.');
export const getIncentiveImgSuccess = createAction('[Setting] Get incentive img success.', props<{ url: string }>());
export const getIncentiveImgError = createAction('[Setting] Get incentive img failed.', props<{ error: any }>());

export const getBlogListing = createAction('[Setting] Get blog listing.', props<{ criteria: any }>());
export const getBlogListingSuccess = createAction('[Setting] Get blog listing success.', props<{ response: any }>());
export const getBlogListingFailure = createAction('[Setting] Get blog listing failed.', props<{ error: any }>());

export const getBlogSingle = createAction('[Setting] Get blog single.', props<{ id: number }>());
export const getBlogSingleSuccess = createAction('[Setting] Get blog single success.', props<{ response: any }>());
export const getBlogSingleFailure = createAction('[Setting] Get blog single failed.', props<{ error: any }>());

export const addBlog = createAction('[Setting] Add a new blog.', props<{ blogDetails: ICreateBlog }>());
export const addBlogSuccess = createAction('[Setting] Added a new blog successfully.', props<{ newBlogId: number }>());
export const addBlogFailure = createAction('[Setting] Added a new blog unsuccessfully.', props<{ error: any }>());

export const editBlog = createAction(
    '[Setting] Edit an existing blog.',
    props<{ id: number; blogDetails: IEditBlogSubmit }>()
);
export const editBlogSuccess = createAction(
    '[Setting] Edit an existing successfully.',
    props<{ editedBlogId: number }>()
);
export const editBlogFailure = createAction('[Setting] Edit an existing unsuccessfully.', props<{ error: any }>());

export const deleteBlog = createAction('[Setting] Delete an existing blog.', props<{ id: number }>());
export const deleteBlogSuccess = createAction(
    '[Setting] Deleted an existing blog successfully.',
    props<{ deletedBlogId: number }>()
);
export const deleteBlogFailure = createAction(
    '[Setting] Deleted an existing blog unsuccessfully.',
    props<{ error: any }>()
);

export const loadInvoiceConfigs = createAction('[Setting] Fetching invoice configs.');
export const loadInvoiceConfigsSuccess = createAction(
    '[Setting] Invoice configs loaded successfully.',
    props<{ invoiceConfigs: InvoiceConfigs }>()
);
export const loadInvoiceConfigsFailure = createAction(
    '[Setting] Invoice configs loaded unsuccessfully.',
    props<{ error: any }>()
);

export const updateInvoiceConfigs = createAction(
    ActionTypes.UPDATE_INVOICE_CONFIGS,
    props<{ invoiceConfigs: InvoiceConfigs }>()
);
export const updateInvoiceConfigsSuccess = createAction(ActionTypes.UPDATE_INVOICE_CONFIGS_SUCCESS);
export const updateInvoiceConfigsFailure = createAction(
    ActionTypes.UPDATE_INVOICE_CONFIGS_FAILURE,
    props<{ error: any }>()
);
export const resetInvoiceConfigsResult = createAction(ActionTypes.RESET_INVOICE_CONFIGS_RESULT);

export const getSeoArticleListing = createAction(
    ActionTypes.GET_SEO_ARTICLE_LISTING,
    props<{ filter: ISeoArticleListFilter; maxNesting: number }>()
);
export const getSeoArticleListingSuccess = createAction(
    ActionTypes.GET_SEO_ARTICLE_LISTING_SUCCESS,
    props<{ articles: SeoArticleListItem[] }>()
);
export const getSeoArticleListingFailure = createAction(
    ActionTypes.GET_SEO_ARTICLE_LISTING_FAILURE,
    props<{ error: any }>()
);

export const getSeoArticleAsOptions = createAction(
    ActionTypes.GET_SEO_ARTICLE_AS_OPTIONS,
    props<{ filter: ISeoArticleOptionFilter }>()
);
export const getSeoArticleAsOptionsSuccess = createAction(
    ActionTypes.GET_SEO_ARTICLE_AS_OPTIONS_SUCCESS,
    props<{ articles: SeoArticleOptionItem[] }>()
);
export const getSeoArticleAsOptionsFailure = createAction(
    ActionTypes.GET_SEO_ARTICLE_AS_OPTIONS_FAILURE,
    props<{ error: any }>()
);

export const updateSeoArticleStatus = createAction(
    ActionTypes.UPDATE_SEO_ARTICLE_STATUS,
    props<{ articleId: number; isActive: boolean }>()
);
export const updateSeoArticleStatusSuccess = createAction(
    ActionTypes.UPDATE_SEO_ARTICLE_STATUS_SUCCESS,
    props<{ articleId: number; isActive: boolean }>()
);
export const updateSeoArticleStatusFailure = createAction(
    ActionTypes.UPDATE_SEO_ARTICLE_STATUS_FAILURE,
    props<{ error: any }>()
);

export const getSeoTargetPageTypes = createAction(ActionTypes.GET_SEO_TARGET_PAGE_TYPES);
export const getSeoTargetPageTypesSuccess = createAction(
    ActionTypes.GET_SEO_TARGET_PAGE_TYPES_SUCCESS,
    props<{ targetPageTypes: TargetPageType[] }>()
);
export const getSeoTargetPageTypesFailure = createAction(
    ActionTypes.GET_SEO_TARGET_PAGE_TYPES_FAILURE,
    props<{ error: any }>()
);

export const getSeoSpecialPages = createAction(ActionTypes.GET_SEO_SPECIAL_PAGES);
export const getSeoSpecialPagesSuccess = createAction(
    ActionTypes.GET_SEO_SPECIAL_PAGES_SUCCESS,
    props<{ seoSpecialPages: SpecialPage[] }>()
);
export const getSeoSpecialPagesFailure = createAction(
    ActionTypes.GET_SEO_SPECIAL_PAGES_FAILURE,
    props<{ error: any }>()
);

export const addNewSeoArticle = createAction(ActionTypes.ADD_NEW_SEO_ARTICLE, props<{ param: INewSeoArticle }>());
export const addNewSeoArticleSuccess = createAction(
    ActionTypes.ADD_NEW_SEO_ARTICLE_SUCCESS,
    props<{ articleId: number }>()
);
export const addNewSeoArticleFailure = createAction(ActionTypes.ADD_NEW_SEO_ARTICLE_FAILURE, props<{ error: any }>());

export const getSeoArticle = createAction(ActionTypes.GET_SEO_ARTICLE, props<{ id: number }>());
export const getSeoArticleSuccess = createAction(ActionTypes.GET_SEO_ARTICLE_SUCCESS, props<{ data: SeoArticle }>());
export const getSeoArticleFailure = createAction(ActionTypes.GET_SEO_ARTICLE_FAILURE, props<{ error: any }>());

export const updateSeoArticleContent = createAction(
    ActionTypes.UPDATE_SEO_ARTICLE_CONTENT,
    props<{ articleId: number; content: ISeoArticleContentUpdate }>()
);
export const updateSeoArticleContentSuccess = createAction(
    ActionTypes.UPDATE_SEO_ARTICLE_CONTENT_SUCCESS,
    props<{ articleId: number }>()
);
export const updateSeoArticleContentFailure = createAction(
    ActionTypes.UPDATE_SEO_ARTICLE_CONTENT_FAILURE,
    props<{ error: any }>()
);

export const updateSeoArticle = createAction(
    ActionTypes.UPDATE_SEO_ARTICLE,
    props<{ articleId: number; payload: ISeoArticleUpdate }>()
);
export const updateSeoArticleSuccess = createAction(
    ActionTypes.UPDATE_SEO_ARTICLE_SUCCESS,
    props<{ articleId: number }>()
);
export const updateSeoArticleFailure = createAction(ActionTypes.UPDATE_SEO_ARTICLE_FAILURE, props<{ error: any }>());
