import { createFeatureSelector, createSelector } from '@ngrx/store';
import * as report from './report.reducer';
import { FEATURE_NAME } from './report-store.module';
import {
    BookingRequestLeadList,
    BookingRequestSingleDetails,
} from 'src/app/modules/report/models/booking-request-lead-details.model';
import { LeadSummaryDetails } from 'src/app/modules/report/models/lead-summary.model';
import { LeadSummaryStatistics } from 'src/app/modules/report/models/lead-summary-statistics.model';
import { LeadStatMonthly } from 'src/app/models/lead-stat-monthly.model';

export const ReportState = createFeatureSelector<report.ReportState>(FEATURE_NAME);

export const selectReportLeadList = createSelector(ReportState, (state: report.ReportState) => state.leadList);

export const selectPageDetails = createSelector(ReportState, (state: report.ReportState) => state.pageDetails);

export const selectGetSearchFilter = createSelector(ReportState, (state: report.ReportState) => state.searchFilter);

export const selectLeadId = createSelector(ReportState, (state: report.ReportState) => state.leadId);

export const selectLeadCount = createSelector(ReportState, (state: report.ReportState) => state.leadCount);

export const selectLeadCountByType = createSelector(ReportState, (state: report.ReportState) => state.leadCountByType);

export const selectUpdatedLeadStatusIdAndStatus = createSelector(
    ReportState,
    (state: report.ReportState) =>
        (state.updatedLeadStatusId ? state.updatedLeadStatusId + '-' : null) + state.updatedLeadStatusSlug
);

export const selectReportBookingRequestLeadList = createSelector(ReportState, (state: report.ReportState) =>
    state.bookingRequestLeadList ? new BookingRequestLeadList().deserialize(state.bookingRequestLeadList) : null
);

export const selectLeadBookingRequestCount = createSelector(
    ReportState,
    (state: report.ReportState) => state.leadBookingRequestCount
);

export const selectReportAgencyList = createSelector(ReportState, (state: report.ReportState) =>
    state.bookingRequestLeadList ? new BookingRequestLeadList().getAgencyList(state.bookingRequestLeadList) : null
);

export const selectBookingRequestUpdatedLeadStatusIdAndStatus = createSelector(
    ReportState,
    (state: report.ReportState) =>
        state.updatedBookingRequestLeadStatusId + '-' + state.updatedBookingRequestLeadStatusSlug
);

export const selectBookingRequestLeadId = createSelector(
    ReportState,
    (state: report.ReportState) => state.leadBookingRequestId
);

export const selectReportBookingRequestDetails = createSelector(ReportState, (state: report.ReportState) =>
    state.leadBookingRequestDetailsResponseBody
        ? new BookingRequestSingleDetails().deserialize(state.leadBookingRequestDetailsResponseBody)
        : null
);
export const selectReportLeadSummaryList = createSelector(ReportState, (state: report.ReportState) =>
    state.summaryLeadList ? new LeadSummaryDetails().deserialize(state.summaryLeadList) : null
);

export const selectReportLeadSummaryListNewOnly = createSelector(ReportState, (state: report.ReportState) =>
    state.summaryLeadList ? new LeadSummaryDetails().deserialize(state.summaryLeadList) : null
);

export const selectReportActiveProductCountData = createSelector(
    ReportState,
    (state: report.ReportState) => state.activeCountData
);

export const selectReportLeadSummaryStatistics = createSelector(ReportState, (state: report.ReportState) =>
    state.productSummaryStatisticsData
        ? new LeadSummaryStatistics().deserialize(state.productSummaryStatisticsData)
        : null
);

export const selectLeadStatMonthly = createSelector(ReportState, (state: report.ReportState) =>
    state.leadStatMonthly ? new LeadStatMonthly().deserialize(state.leadStatMonthly) : null
);

export const selectLeadStatMonthlyDetails = createSelector(
    ReportState,
    (state: report.ReportState) => state.leadStatMonthlyDetails
);

export const selectOrderBalancePeriod = createSelector(
    ReportState,
    (state: report.ReportState) => state.orderBalancePeriod
);

export const selectOrderBalanceReport = createSelector(
    ReportState,
    (state: report.ReportState) => state.orderBalanceReport
);

export const selectOrderBalanceReportItems = createSelector(
    ReportState,
    (state: report.ReportState) => state.orderBalanceReportItems
);

export const selectOrderBalance = createSelector(ReportState, (state: report.ReportState) => state.orderBalance);

export const selectConfirmOrderBalanceSuccessful = createSelector(
    ReportState,
    (state: report.ReportState) => state.confirmOrderBalanceSuccessful
);

export const selectOrderBalanceReceiptUri = createSelector(
    ReportState,
    (state: report.ReportState) => state.orderBalanceReceiptUri
);
