export enum TW_ORDER_STATUS {
    waiting_confirmation = 'รอยืนยัน',
    waiting_first_payment = 'รอชำระเงิน',
    waiting_last_payment = 'รอชำระเงินครบ',
    completed = 'รอเดินทาง',
    completed_traveled = 'เดินทางแล้ว',
    canceled = 'ยกเลิก',
}
export const TW_ORDER_STATUS_OPTION: IOrderStatusOption[] = [
    {
        value: 'waiting_confirmation',
        label: 'รอยืนยัน',
    },
    {
        value: 'waiting_first_payment',
        label: 'รอชำระเงิน',
    },
    {
        value: 'waiting_last_payment',
        label: 'รอชำระเงินครบ',
    },
    {
        value: 'completed',
        label: 'รอเดินทาง',
    },
    {
        value: 'completed_traveled',
        label: 'เดินทางแล้ว',
    },
];

export const TW_ORDER_STATUS_CHECK_LIST: IOrderStatusCheckList[] = [
    { name: 'ทั้งหมด', value: null, checked: true },
    { name: 'รอยืนยัน', value: 'waiting_confirmation', checked: false },
    { name: 'รอชำระเงินมัดจำ', value: 'waiting_first_payment', checked: false },
    { name: 'รอชำระเงินครบ', value: 'waiting_last_payment', checked: false },
    { name: 'รอเดินทาง', value: 'completed', checked: false },
    { name: 'เดินทางแล้ว', value: 'completed_traveled', checked: false },
    { name: 'ยกเลิก', value: 'canceled', checked: false },
];

export interface IOrderStatusOption {
    value: string;
    label: string;
}
export interface IOrderStatusCheckList {
    name: string;
    value: string;
    checked: boolean;
}
