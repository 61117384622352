<div class="modal-header">
    <button type="button" class="close pull-right" aria-label="Close" (click)="bsModalRef.hide()">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body">
    <div class="modal-detail">
        <div class="modal-detail-title">{{ title }}</div>
        <div class="modal-detail-content">
            <form [formGroup]="dataForm">
                <div class="form-group">
                    <textarea
                        class="form-control"
                        id="exampleFormControlTextarea1"
                        formControlName="data"
                        rows="7"
                        placeholder="กรอกข้อมูล"
                        maxlength="1000"
                    ></textarea>
                </div>
            </form>
        </div>
    </div>
</div>
<div class="modal-footer">
    <div class="confirm-button-container">
        <button type="button" class="btn btn-secondary confirm-btn" (click)="onSubmit()">บันทึก</button>
        <button type="button" class="btn btn-outline-secondary cancle-btn" (click)="onCancle()">ยกเลิก</button>
    </div>
</div>
