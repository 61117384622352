import { Blog } from './../../modules/settings/models/blog.model';
import { PortfolioSingle } from '../../modules/settings/models/portfolio-single.model';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import * as setting from './setting.reducer';
import { FEATURE_NAME } from './setting-store.module';
import { PortfolioListing } from 'src/app/modules/settings/models/portfolio-pagination.model';

export const SettingState = createFeatureSelector<setting.SettingState>(FEATURE_NAME);

export const selectConfigBannersResult = createSelector(
    SettingState,
    (state: setting.SettingState) => state.configBannersResult
);

export const selectConfigBannersResultErrorMessage = createSelector(
    SettingState,
    (state: setting.SettingState) => state.configBannersResultErrorMessage
);

export const selectConfigBannersUpdatedResponse = createSelector(
    SettingState,
    (state: setting.SettingState) => state.configBannerUpdaterStatus
);

export const selectCustomContentDetail = createSelector(
    SettingState,
    (state: setting.SettingState) => state.customContent
);

export const selectCustomContentUpdaterResponse = createSelector(
    SettingState,
    (state: setting.SettingState) => state.customContentUpdaterResponse
);

export const selectPortfolioListing = createSelector(SettingState, (state: setting.SettingState) =>
    state.portfolioListingResponse ? new PortfolioListing().deserializePortfolio(state.portfolioListingResponse) : null
);

export const selectPortfolioSingle = createSelector(SettingState, (state: setting.SettingState) =>
    state.portfolioSingleResponse ? new PortfolioSingle().deserializePortfolio(state.portfolioSingleResponse) : null
);

export const selectPortfolioNewId = createSelector(SettingState, (state: setting.SettingState) => state.portfolioNewId);

export const selectPortfolioEditedId = createSelector(
    SettingState,
    (state: setting.SettingState) => state.portfolioEditedId
);

export const selectPortfolioDeletedId = createSelector(
    SettingState,
    (state: setting.SettingState) => state.portfolioDeletedId
);

export const selectUpdateIncentiveImageResponse = createSelector(
    SettingState,
    (state: setting.SettingState) => state.updateIncentiveImgResponse
);

export const selectIncentiveImgUrl = createSelector(
    SettingState,
    (state: setting.SettingState) => state.incentiveImgUrl
);

export const selectBlogListing = createSelector(SettingState, (state: setting.SettingState) =>
    state.blogList ? new Blog().deserialize(state.blogList) : null
);

export const selectBlogSingle = createSelector(SettingState, (state: setting.SettingState) =>
    state.blogSingleResponse ? new Blog().deserializeDetail(state.blogSingleResponse) : null
);

export const selectnewBlogId = createSelector(SettingState, (state: setting.SettingState) => state.newBlogId);

export const selectEditedBlogId = createSelector(SettingState, (state: setting.SettingState) => state.editedBlogId);

export const selectDeletedBlogId = createSelector(SettingState, (state: setting.SettingState) => state.deletedBlogId);

export const selectInvoiceConfigs = createSelector(SettingState, (state: setting.SettingState) => state.invoiceConfigs);

export const selectInvoiceConfigsSuccess = createSelector(
    SettingState,
    (state: setting.SettingState) => state.invoiceConfigsSuccess
);

export const selectSeoArticleList = createSelector(SettingState, (state) => state.seoArticleList);

export const selectSeoArticleOptions = createSelector(SettingState, (state) => state.seoArticleOptions);

export const selectSeoTargetPageTypes = createSelector(SettingState, (state) => state.seoTargetPageTypes);

export const selectSeoSpecialPages = createSelector(SettingState, (state) => state.seoSpecialPages);
