<div class="sale-report-listing">
    <div class="card">
        <div class="card-body">
            <div class="title-wrapper mb-2">
                <div class="title">
                    คุณกำลังดูรายงานยอดขาย
                    <ng-container *ngIf="!saleReportListQuery?.filters?.clearCache">
                        เดือน {{ saleReportListQuery?.filters?.created_at_between?.min_date | thaiMonth }}
                    </ng-container>
                    <ng-container *ngIf="saleReportListQuery?.filters?.clearCache">
                        {{ getDynamicTotalString() }}
                    </ng-container>
                    จำนวน
                    {{ saleReportListPaginated?.totalReports }} Booking ยอดจองรวม
                    {{ saleReportListPaginated?.totalSumPrice | mycurrency }}
                </div>
            </div>
            <div class="sale-report-listing-header d-flex flex-rows border">
                <p class="p-2 border-right w-75 mb-0">Booking</p>
                <p class="p-2 border-right w-15 mb-0 text-center">ยอดจอง</p>
                <p class="p-2 border-right w-15 mb-0 text-center">จำนวนผู้เดินทาง</p>
                <p class="p-2 border-right w-15 mb-0"></p>
            </div>
            <div class="sale-report-listing-content">
                <ng-container *ngFor="let report of saleReportListPaginated?.items; let index = index">
                    <app-sale-report-list-details
                        [index]="index"
                        [report]="report"
                        (clickViewMoreDetail)="doAdvanceFilter($event)"
                    ></app-sale-report-list-details>
                </ng-container>
            </div>

            <ng-container>
                <div
                    class="text-center show-more-results"
                    *ngIf="saleReportListPaginated?.totalReports !== saleReportListPaginated?.items?.length"
                >
                    <button class="btn btn-show-more" (click)="loadMoreResults()">
                        แสดงรายการที่
                        {{ saleReportListPaginated?.currentPage * saleReportListPaginated?.itemPerPage + 1 }} -
                        {{
                            saleReportListPaginated?.currentPage * saleReportListPaginated?.itemPerPage +
                                saleReportListPaginated?.itemPerPage
                        }}
                    </button>
                </div>
            </ng-container>
        </div>
    </div>
</div>
