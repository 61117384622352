import { Action, createReducer, on } from '@ngrx/store';
import { CampaignHilight } from 'src/app/models/campaigns/campaign-hilight.model';
import { ChaophrayaCruiseBookingView } from 'src/app/models/chaophraya-cruise-booking.model';
import { ICampaign, ICampaignPaginated } from 'src/app/modules/product/models/campaign-model';
import * as CampaignActions from './campaign.actions';

export const campaignFeatureKey = 'campaign';

export interface CampaignState {
    campaigns: ICampaign[];
    campaignPaginated: ICampaignPaginated;
    campaignHilight: CampaignHilight;
    chaophrayaCruiseBooking: ChaophrayaCruiseBookingView;
}

export const initialState: CampaignState = {
    campaigns: null,
    campaignPaginated: null,
    campaignHilight: null,
    chaophrayaCruiseBooking: null,
};

export const campaignReducer = createReducer(
    initialState,

    on(CampaignActions.loadCampaigns, (state) => {
        return { ...state };
    }),
    on(CampaignActions.loadCampaignsSuccess, (state, { campaigns }) => {
        return { ...state, campaigns: campaigns };
    }),
    on(CampaignActions.loadCampaignsError, (state) => {
        return { ...state };
    }),
    on(CampaignActions.loadCampaignPaginated, (state) => {
        return { ...state };
    }),
    on(CampaignActions.loadCampaignPaginatedSuccess, (state, { campaignPaginated }) => {
        let campaigns = campaignPaginated.campaigns;
        if (campaignPaginated.currentPage > 1) {
            campaigns = state?.campaignPaginated?.campaigns ? [...state.campaignPaginated.campaigns] : [];
            if (campaignPaginated?.campaigns?.length) {
                campaigns = campaigns.concat(campaignPaginated.campaigns);
            }
        }

        return {
            ...state,
            campaignPaginated: <ICampaignPaginated>{
                currentPage: campaignPaginated.currentPage,
                itemPerPage: campaignPaginated.itemPerPage,
                totalItems: campaignPaginated.totalItems,
                campaigns: campaigns,
            },
        };
    }),
    on(CampaignActions.loadCampaignPaginatedError, (state) => {
        return { ...state };
    }),
    on(CampaignActions.loadCampaignHilight, (state) => {
        return { ...state };
    }),
    on(CampaignActions.loadCampaignHilightSuccess, (state, { hilight }) => {
        return { ...state, campaignHilight: hilight };
    }),
    on(CampaignActions.loadCampaignHilightError, (state) => {
        return { ...state };
    }),
    on(CampaignActions.loadChaophrayaCruiseBooking, (state) => {
        return { ...state };
    }),
    on(CampaignActions.loadChaophrayaCruiseBookingSuccess, (state, { model }) => {
        return { ...state, chaophrayaCruiseBooking: model };
    }),
    on(CampaignActions.loadChaophrayaCruiseBookingError, (state) => {
        return { ...state };
    })
);

export function reducer(state: CampaignState | undefined, action: Action) {
    return campaignReducer(state, action);
}
