import { CommissionDisabledComponent } from './../../modals/commission-disabled/commission-disabled.component';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { Agency } from 'src/app/models/agency';
import { IBookingStatusCount } from '../../models/booking-status-count.model';
import { BOOKING_LIST_SORT_BY, IBookingListQueries } from '../../models/booking-list.model';
import { BOOKING_LIST_TABS } from '../../../../constants/booking-list-tabs.contstant';
import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output } from '@angular/core';
import {
    SearchableDropdownSettings,
    SearchableDropdownItem,
} from 'src/app/modules/shared/components/searchable-dropdown/searchable-dropdown.component';
import { BOOKING_STATUS_CODES, BOOKING_STATUS_KEYWORDS } from 'src/app/constants/booking-status-codes.constant';
import * as OrderActions from 'src/app/stores/order-store/order.actions';
import { Subject } from 'rxjs';
import { select, Store } from '@ngrx/store';
import { State } from 'src/app/stores/reducers';
import { filter, takeUntil } from 'rxjs/operators';
import { selectBookingListQueries, selectBookingStatusCount } from 'src/app/stores/order-store/order.selectors';
import { selectAgencyInfo } from 'src/app/stores/user-store/user.selectors';
import * as _ from 'lodash-es';

@Component({
    selector: 'app-booking-list-tabs',
    templateUrl: './booking-list-tabs.component.html',
    styleUrls: ['./booking-list-tabs.component.scss'],
})
export class BookingListTabsComponent implements OnInit, OnChanges, OnDestroy {
    readonly BOOKING_LIST_TABS = BOOKING_LIST_TABS;
    readonly STATUS_CODES = BOOKING_STATUS_CODES;
    readonly STATUS_KEYWORDS = BOOKING_STATUS_KEYWORDS;
    @Input() activeTab: number;

    @Input() hideFilter: boolean;
    @Output() activeTabEmitter: EventEmitter<number> = new EventEmitter<number>();

    agencyInfo: Agency;
    dateRangeDropdownSettings: SearchableDropdownSettings = new SearchableDropdownSettings();
    dateRangeDropdownItems: SearchableDropdownItem[];
    private searchFilter: IBookingListQueries;
    bookingStatusCount: IBookingStatusCount;
    private unsubscribe$: Subject<void> = new Subject<void>();
    private bsModalRef: BsModalRef;

    constructor(private store: Store<State>, private bsModalService: BsModalService) {}

    ngOnInit(): void {
        this.store
            .pipe(
                select(selectAgencyInfo),
                filter((agencyInfo) => !!agencyInfo),
                takeUntil(this.unsubscribe$)
            )
            .subscribe((agencyInfo) => (this.agencyInfo = agencyInfo));
        this.changeTabs();
    }

    ngOnChanges(): void {
        this.changeTabs;
    }

    ngOnDestroy(): void {
        this.store.dispatch(
            OrderActions.getBookingListPaginatedSuccess({
                bookingList: null,
            })
        );
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
    }

    private changeTabs(): void {
        this.activeTabEmitter.emit(this.activeTab);

        if (this.activeTab === BOOKING_LIST_TABS.BOOKING_LIST) {
            this.loadDateRangeDropdown();
            this.subscribeToBookingStatusCount();
            this.initSearchFilter();
            this.subscribeToSearchFilterChanges();
        }
    }

    private initSearchFilter(): void {
        this.searchFilter = {
            filters: {
                order_status: null,
                created_at_between: {
                    min_date: null,
                    max_date: null,
                },
                start_at_between: {
                    min_date: null,
                    max_date: null,
                },
            },
            criteria: {
                page: 1,
                pageEntries: 50,
            },
            sortBy: BOOKING_LIST_SORT_BY.CREATED_BY_DESC,
        };
    }

    private subscribeToSearchFilterChanges(): void {
        // Last seven days
        this.onSelectDateRange([1]);

        this.store
            .pipe(
                select(selectBookingListQueries),
                filter((currentPage) => currentPage !== null),
                takeUntil(this.unsubscribe$)
            )
            .subscribe((queries: IBookingListQueries) => {
                this.searchFilter = _.cloneDeep(queries);
            });
    }

    private getBookingStatusCount(): void {
        this.store.dispatch(
            OrderActions.getBookingStatusCount({
                dates: Object.assign({}, this.searchFilter.filters.created_at_between),
            })
        );
    }

    private subscribeToBookingStatusCount(): void {
        this.store
            .pipe(
                select(selectBookingStatusCount),
                filter((resp) => resp !== null),
                takeUntil(this.unsubscribe$)
            )
            .subscribe((bookingStatusCount: IBookingStatusCount) => {
                this.bookingStatusCount = bookingStatusCount;
            });
    }

    private loadDateRangeDropdown(): void {
        this.dateRangeDropdownItems = [
            {
                id: 1,
                name: '7 วันล่าสุด',
                isChecked: true,
            },
            {
                id: 2,
                name: '30 วันล่าสุด',
            },
            {
                id: 3,
                name: '3 เดือนล่าสุด',
            },
        ];
        this.dateRangeDropdownSettings.hideSearchBox = true;
        this.dateRangeDropdownSettings.multipleSelection = false;
        this.dateRangeDropdownSettings.closeAfterSelect = true;
        this.dateRangeDropdownSettings.unableToUncheck = true;
        this.dateRangeDropdownSettings.styleSetting.fontSize = '16px';
        this.dateRangeDropdownSettings.styleSetting.height = '30px';
    }

    onSelectDateRange(idList: number[]): void {
        const id: number = idList[0];
        let date = new Date();

        switch (id) {
            case 3:
                date.setMonth(date.getMonth() - 3);
                break;
            case 2:
                date.setDate(date.getUTCDate() - 29);
                break;
            case 1:
                date.setDate(date.getUTCDate() - 6);
                break;
            default:
                date = null;
                break;
        }

        const dateString = this.dateToString(date);
        this.searchFilter.filters.created_at_between.min_date = dateString;

        this.getBookingStatusCount();
        this.dispatchBookingList();
    }

    onSelectBookingStatus(orderStatus: number, addDateCheck?: number) {
        this.initSearchFilter();

        if (addDateCheck) {
            if (addDateCheck === 1) {
                this.searchFilter.filters.start_at_between.min_date = this.dateToString(new Date());
            } else if (addDateCheck === 2) {
                const dateYesterday = new Date();
                dateYesterday.setDate(new Date().getDate() - 1);
                this.searchFilter.filters.start_at_between.max_date = this.dateToString(dateYesterday);
            }
        }

        this.searchFilter.filters.order_status = orderStatus;

        this.dispatchBookingList();
    }

    resetStatusFilter(): void {
        this.searchFilter.filters.order_status = null;
        this.searchFilter.filters.start_at_between.min_date = null;
        this.dispatchBookingList();
    }

    private dispatchBookingList(): void {
        this.store.dispatch(
            OrderActions.getBookingListPaginated({
                queries: _.cloneDeep(this.searchFilter),
            })
        );
    }

    setActiveTab(tabNumber: number): void {
        if (tabNumber === BOOKING_LIST_TABS.COMMISSION_REPORT && !this.agencyInfo.displayCommission) {
            this.bsModalRef = this.bsModalService.show(CommissionDisabledComponent, {
                class: 'commission-disabled-modal',
            });
            return;
        }
        if (tabNumber === this.activeTab) return;
        this.activeTab = tabNumber;
        this.changeTabs();
    }

    private dateToString(date: Date): string {
        return date ? date.toISOString().substring(0, 10) : null;
    }
}
