import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { BsModalRef } from 'ngx-bootstrap/modal';

@Component({
    selector: 'app-modal',
    templateUrl: './modal.component.html',
    styleUrls: ['./modal.component.scss'],
})
export class ModalContentComponent implements OnInit {
    message: string;
    type: string;
    hasCloseButton: boolean = true;

    @Output() hidden = new EventEmitter<void>();

    constructor(public bsModalRef: BsModalRef, private router: Router) {}

    ngOnInit() {
        this.closeOnRouteChange();
    }

    public hide() {
        this.hidden.emit();
        this.bsModalRef.hide();
    }

    private closeOnRouteChange(): void {
        this.router.events.subscribe((evt) => {
            if (!(evt instanceof NavigationEnd)) {
                return;
            }
            this.hide();
        });
    }
}
