export class Owner {
    public id: number;
    public nameTh: string;
    public nameEn: string;
    public phoneNumber: string;
    public additionPhoneNumber: string;
    public email: string;
    public address: string;
    public licenseNumber: string;

    public deserialize(obj: any): Owner {
        this.id = obj.id;
        this.nameTh = obj.name_th;
        this.nameEn = obj.name_en;
        this.phoneNumber = obj.phone_number;
        this.additionPhoneNumber = obj.addition_phone_number;
        this.email = obj.email;
        this.address = obj.address;
        this.licenseNumber = obj.license_number;
        return this;
    }
}
