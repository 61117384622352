import { Component, OnDestroy, OnInit } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { ProductOwner } from 'src/app/models/product-owner';
import { State } from 'src/app/stores/reducers';
import { selectProductOwner } from 'src/app/stores/product-store/product.selectors';
import { filter, take } from 'rxjs/operators';
import { loadProductOwner, resetProductOwner } from 'src/app/stores/product-store/product.actions';

@Component({
    selector: 'app-product-owner-modal',
    templateUrl: './product-owner-modal.component.html',
    styleUrls: ['./product-owner-modal.component.scss'],
})
export class ProductOwnerModalComponent implements OnInit, OnDestroy {
    public usingLatestProductOwner: boolean = false;
    public ownerChannel: string = null;
    public ownerId: number = null;

    public productOwner: ProductOwner;

    constructor(public bsModalRef: BsModalRef, public bsModalService: BsModalService, private store: Store<State>) {}

    ngOnInit(): void {
        if (this.usingLatestProductOwner) {
            this.loadLatestProductOwner();
        }
    }

    ngOnDestroy(): void {
        if (this.usingLatestProductOwner) {
            this.store.dispatch(resetProductOwner());
        }
    }

    private loadLatestProductOwner(): void {
        this.store
            .pipe(
                select(selectProductOwner),
                filter((productOwner) => !!productOwner),
                take(1)
            )
            .subscribe((productOwner) => {
                this.productOwner = {
                    name: productOwner.nameEn + ' (' + productOwner.nameTh + ')',
                    email: productOwner.email,
                    address: productOwner.address,
                    licenseNumber: productOwner.licenseNumber,
                    telephoneNumber: productOwner.phoneNumber,
                    additionTelephoneNumber: productOwner.additionPhoneNumber,
                };
            });

        this.store.dispatch(loadProductOwner({ ownerChannel: this.ownerChannel, ownerId: this.ownerId }));
    }
}
