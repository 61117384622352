import { selectModifiedBookingId } from '../../../../stores/order-store/order.selectors';
import { BOOKING_STATUS_CODES } from 'src/app/constants/booking-status-codes.constant';
import { Component, Input, OnInit } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { IBooking } from '../../models/booking-list.model';
import { select, Store } from '@ngrx/store';
import { State } from 'src/app/stores/reducers';
import * as OrderActions from 'src/app/stores/order-store/order.actions';
import { filter, take } from 'rxjs/operators';
@Component({
    selector: 'app-change-booking-status',
    templateUrl: './change-booking-status.component.html',
    styleUrls: ['./change-booking-status.component.scss'],
})
export class ChangeBookingStatusComponent implements OnInit {
    @Input() booking: IBooking;
    public firstPayment: boolean = false;
    public fullPayment: boolean = false;

    constructor(public bsModalRef: BsModalRef, private store: Store<State>, public bsModalService: BsModalService) {}

    ngOnInit(): void {
        this.setCurrentState();
    }

    private setCurrentState(): void {
        switch (this.booking.order.orderStatus) {
            case BOOKING_STATUS_CODES.COMPLETE:
                this.fullPayment = true;
            case BOOKING_STATUS_CODES.WAITING_FOR_FULL_PAYMENT:
                this.firstPayment = true;
        }
    }

    public onSubmit(): void {
        let status: number = BOOKING_STATUS_CODES.WAITING_FOR_FIRST_PAYMENT;
        if (this.firstPayment) {
            status = BOOKING_STATUS_CODES.WAITING_FOR_FULL_PAYMENT;
        }
        if (this.fullPayment) {
            status = BOOKING_STATUS_CODES.COMPLETE;
        }

        this.store.dispatch(
            OrderActions.modifyBookingStatus({
                bookingOrderId: this.booking.id,
                status,
            })
        );

        this.store
            .pipe(
                select(selectModifiedBookingId),
                filter((resp) => !!resp),
                take(1)
            )
            .subscribe(() => {
                this.store.dispatch(
                    OrderActions.modifyBookingStatusSuccess({
                        modifiedBookingId: null,
                    })
                );
                this.bsModalService.setDismissReason('success-' + status);
                this.bsModalRef.hide();
                this.bsModalRef = null;
            });
    }

    public onCancel(): void {
        this.bsModalRef.hide();
        this.bsModalRef = null;
    }
}
