import { ICommissionReportListQueries } from './../../models/commission-report-list-queries';
import { selectCommissionListQueries } from './../../../../stores/order-store/order.selectors';
import { BOOKING_LIST_TABS } from 'src/app/constants/booking-list-tabs.contstant';
import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Subject } from 'rxjs';
import { filter, map, take, takeUntil } from 'rxjs/operators';
import { BOOKING_STATUS_CODES } from 'src/app/constants/booking-status-codes.constant';
import { DaterangeComponent, IDateRange } from 'src/app/modules/shared/components/daterange/daterange.component';
import {
    SearchableDropdownComponent,
    SearchableDropdownItem,
    SearchableDropdownSettings,
} from 'src/app/modules/shared/components/searchable-dropdown/searchable-dropdown.component';
import * as UserActions from 'src/app/stores/user-store/user.actions';
import * as OrderActions from 'src/app/stores/order-store/order.actions';
import {
    selectBookingListQueries,
    selectGroupPlanListQueries,
    selectProductList,
    selectProductOwnerList,
    selectSaleReportListQueries,
} from 'src/app/stores/order-store/order.selectors';
import { State } from 'src/app/stores/reducers';
import {
    IBookingAdvanceSearch,
    IBookingProductOwner,
    IBookingStatuses,
} from '../../models/booking-advance-search.model';
import { selectAgencyInfo, selectUsersList } from 'src/app/stores/user-store/user.selectors';
import { IGroupPlanListQueries } from '../../models/group-plan-list-queries';
import { Agency } from 'src/app/models/agency';
import { DateHelper } from 'src/app/helpers/date.helper';
import { IBookingListOrderStatusFilter } from 'src/app/modules/order/models/booking-list.model';
import { IAdvancedSearchPresets } from '../../pages/booking-list/booking-list.component';
import { ProductListOptional } from 'src/app/modules/order/models/product-list.model';

@Component({
    selector: 'app-booking-advance-search',
    templateUrl: './booking-advance-search.component.html',
    styleUrls: ['./booking-advance-search.component.scss'],
})
export class BookingAdvanceSearchComponent implements OnInit, OnDestroy, OnChanges {
    @Input() private activeTab: number;
    @Input() private presets: IAdvancedSearchPresets;
    @Output() private bookingSubmit = new EventEmitter<IBookingAdvanceSearch>();
    @ViewChild('productOwnerDropdownComponent') productOwnerDropdownComponent: SearchableDropdownComponent;
    @ViewChild('sellerDropdownComponent') sellerDropdownComponent: SearchableDropdownComponent;
    @ViewChild('productDropdownComponent') productDropdownComponent: SearchableDropdownComponent;
    @ViewChild('bookingDateRangeComponent') bookingDateRangeComponent: DaterangeComponent;
    @ViewChild('outboundDateRangeComponent') outboundDateRangeComponent: DaterangeComponent;

    private agency: Agency;

    contactNameOrPhoneNumber: string;
    private productOwner: IBookingProductOwner;
    private sellerId: number;
    private productName: string;
    private product: ProductListOptional;

    productDropdownSettings: SearchableDropdownSettings;
    productDropdownItems: SearchableDropdownItem[];

    productOwnerDropdownSettings: SearchableDropdownSettings;
    productOwnerDropdownItems: SearchableDropdownItem[];

    sellerDropdownSettings: SearchableDropdownSettings;
    sellerOwnerDropdownItems: SearchableDropdownItem[];

    bookingDate: IDateRange;
    outboundDate: IDateRange;

    bookingStatuses: Map<number, { checked?: boolean; label: string }>;
    bookingStatusesSelected: IBookingStatuses[];

    private preselectedOwner: { ownerId: number; ownerChannel: string };
    private preselectedSalesId: number;

    private readonly BOOKING_STATUS_ALL = -1;
    private unsubscribe$: Subject<void> = new Subject<void>();

    constructor(private store: Store<State>) {}

    ngOnInit(): void {
        this.subscribeAgencyInfo();
        this.initPresets();
        this.initDropdownSettings();
        this.initDropdownItems();
        if (this.activeTab === BOOKING_LIST_TABS.BOOKING_LIST) {
            this.initBookingStatuses([this.BOOKING_STATUS_ALL]);
        }
        this.getValuesFromShortcut();
    }

    ngOnDestroy(): void {
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
    }

    ngOnChanges(): void {
        this.ngOnInit();
    }

    private subscribeAgencyInfo(): void {
        this.store
            .pipe(
                select(selectAgencyInfo),
                filter((agency) => !!agency),
                take(1)
            )
            .subscribe((agency) => {
                this.agency = agency;
            });
    }

    private getValuesFromShortcut(): void {
        switch (this.activeTab) {
            case BOOKING_LIST_TABS.BOOKING_LIST:
                this.store
                    .pipe(
                        select(selectBookingListQueries),
                        filter((resp) => resp !== null),
                        takeUntil(this.unsubscribe$)
                    )
                    .subscribe((queries) => {
                        if (queries?.filters?.created_at_between) {
                            this.bookingDate = {
                                startDate: DateHelper.convertStringToDate(queries.filters.created_at_between.min_date),
                                endDate: new Date(),
                            };
                            if (queries.filters.order_status) {
                                const orderStatus: number = queries.filters.start_at_between.min_date
                                    ? BOOKING_STATUS_CODES.COMPLETE + 1
                                    : queries.filters.order_status;
                                this.initBookingStatuses([orderStatus]);
                                this.bookingStatusesSelected = [
                                    {
                                        statusCode: queries.filters.order_status,
                                        isStarted: queries.filters.start_at_between.min_date ? true : false,
                                    },
                                ];
                            }
                        }
                    });
                break;
            case BOOKING_LIST_TABS.SALES_REVENUE:
                this.store
                    .pipe(
                        select(selectSaleReportListQueries),
                        filter((resp) => resp !== null),
                        takeUntil(this.unsubscribe$)
                    )
                    .subscribe((queries) => {
                        this.bookingDate = {
                            startDate: DateHelper.convertStringToDate(queries?.filters?.created_at_between?.min_date),
                            endDate: DateHelper.convertStringToDate(queries?.filters?.created_at_between?.max_date),
                        };
                        this.syncBookingStatusesFromShortcut(queries.filters.order_statuses);
                    });
                break;
            case BOOKING_LIST_TABS.GROUP_PLAN:
                this.store
                    .pipe(
                        select(selectGroupPlanListQueries),
                        filter((data) => data !== null),
                        takeUntil(this.unsubscribe$)
                    )
                    .subscribe((groupPlanListQueries: IGroupPlanListQueries) => {
                        this.outboundDate = {
                            startDate: DateHelper.convertStringToDate(
                                groupPlanListQueries?.filters?.start_at_between?.min_date
                            ),
                            endDate: DateHelper.convertStringToDate(
                                groupPlanListQueries?.filters?.start_at_between?.max_date
                            ),
                        };
                        this.syncBookingStatusesFromShortcut(groupPlanListQueries.filters.order_statuses);
                    });
                break;
            case BOOKING_LIST_TABS.COMMISSION_REPORT:
                this.store
                    .pipe(
                        select(selectCommissionListQueries),
                        filter((data) => data !== null),
                        takeUntil(this.unsubscribe$)
                    )
                    .subscribe((commissionListQueries: ICommissionReportListQueries) => {
                        this.bookingDate = {
                            startDate: DateHelper.convertStringToDate(
                                commissionListQueries?.filters?.created_at_between?.min_date
                            ),
                            endDate: DateHelper.convertStringToDate(
                                commissionListQueries?.filters?.created_at_between?.max_date
                            ),
                        };
                        this.syncBookingStatusesFromShortcut(commissionListQueries.filters.order_statuses);
                    });
                break;
        }
    }

    private initPresets(): void {
        if (this.presets) {
            if (this.presets.nameOrNumber) {
                this.contactNameOrPhoneNumber = this.presets.nameOrNumber;
            }
            if (this.presets.bookingDate) {
                this.bookingDate = {
                    startDate: DateHelper.convertStringToDate(this.presets.bookingDate),
                    endDate: DateHelper.convertStringToDate(this.presets.bookingDate),
                };
            }
            if (this.presets.travelStartDate) {
                this.outboundDate = {
                    startDate: DateHelper.convertStringToDate(this.presets.travelStartDate),
                    endDate: DateHelper.convertStringToDate(this.presets.travelStartDate),
                };
            }
            if (this.presets.ownerId && this.presets.ownerChannel) {
                this.preselectedOwner = {
                    ownerId: this.presets.ownerId,
                    ownerChannel: this.presets.ownerChannel,
                };
            }
            if (this.presets.salesId) {
                this.preselectedSalesId = this.presets.salesId;
            }
        }
    }

    productOwnerSelected(productOwnerKeys: string[]): void {
        let owner: { id: number; channel: 'ws' | 'ag' } = null;
        if (productOwnerKeys.length > 0) {
            owner = {
                id: parseInt(productOwnerKeys[0].substr(2)),
                channel: productOwnerKeys[0].substr(0, 2) as 'ws' | 'ag',
            };
        }
        this.store.dispatch(OrderActions.getProductList({ owner: owner, hideLoadingIcon: true }));
    }

    sellerSelected(sellerId: number[]): void {
        this.sellerId = sellerId.length || sellerId[0] !== null ? sellerId[0] : null;
    }

    productSelected(productOptionIds: string[]): void {
        if (productOptionIds.length) {
            this.product = this.productDropdownItems.find((item) => item.id === productOptionIds[0]).value;
        } else {
            this.product = null;
        }
    }

    bookingDateChange(dateRange: IDateRange): void {
        this.bookingDate = dateRange;
    }

    outboundDateChange(dateRange: IDateRange): void {
        this.outboundDate = dateRange;
    }

    bookingStatusChange(checked: boolean, statusId: number): void {
        if (!this.bookingStatusesSelected) {
            this.bookingStatusesSelected = [];
        }
        if (checked) {
            this.bookingStatuses.get(statusId).checked = true;
            if (statusId != this.BOOKING_STATUS_ALL) {
                this.bookingStatuses.get(this.BOOKING_STATUS_ALL).checked = false;
                let isStarted = null;
                if (statusId === BOOKING_STATUS_CODES.COMPLETE + 1) {
                    isStarted = true;
                } else if (statusId === BOOKING_STATUS_CODES.COMPLETE) {
                    isStarted = false;
                }
                this.bookingStatusesSelected.push({
                    statusCode:
                        statusId === BOOKING_STATUS_CODES.COMPLETE + 1 ? BOOKING_STATUS_CODES.COMPLETE : statusId,
                    isStarted: isStarted,
                });
            } else {
                this.bookingStatusesSelected = [];
                for (const [key, value] of this.bookingStatuses) {
                    if (key != this.BOOKING_STATUS_ALL) value.checked = false;
                }
            }
        } else {
            this.bookingStatuses.get(statusId).checked = false;
            let index: number = null;
            if (statusId === BOOKING_STATUS_CODES.COMPLETE + 1) {
                index = this.bookingStatusesSelected.findIndex(
                    (s) => s.statusCode === BOOKING_STATUS_CODES.COMPLETE && s.isStarted
                );
            } else {
                index = this.bookingStatusesSelected.findIndex((s) => s.statusCode === statusId);
            }
            this.bookingStatusesSelected.splice(index, 1);
        }
    }

    doSearch(): void {
        const advanceSearchFilter: IBookingAdvanceSearch = {
            contactNameOrPhoneNumber: this.contactNameOrPhoneNumber,
            productOwner: this.productOwner,
            product: this.product,
            sellerId: this.sellerId,
            outboundDate: this.outboundDate,
            bookingDate: this.bookingDate,
            bookingStatuses: this.bookingStatusesSelected,
        };
        this.bookingSubmit.emit(advanceSearchFilter);
    }

    resetSearchFilter(): void {
        this.contactNameOrPhoneNumber = null;
        this.productOwner = null;
        this.productName = null;
        this.sellerId = null;
        this.outboundDate = { startDate: null, endDate: null };
        this.bookingDate = { startDate: null, endDate: null };
        this.bookingStatusesSelected = null;
        this.initBookingStatuses([this.BOOKING_STATUS_ALL]);
        this.productOwnerDropdownComponent.selectAllItems(false);
        this.sellerDropdownComponent.selectAllItems(false);
        this.productDropdownComponent.selectAllItems(false);
        this.bookingDateRangeComponent.reset();
        this.outboundDateRangeComponent.reset();
    }

    private initDropdownSettings(): void {
        const dropdownSettings = new SearchableDropdownSettings();
        dropdownSettings.hideSearchBox = true;
        dropdownSettings.multipleSelection = false;
        dropdownSettings.closeAfterSelect = true;
        dropdownSettings.unableToUncheck = true;
        dropdownSettings.styleSetting.fontSize = '14px';

        this.productOwnerDropdownSettings = Object.assign({}, dropdownSettings);
        this.productOwnerDropdownSettings.underlineAfterFirstItem = true;
        this.productOwnerDropdownSettings.hideSearchBox = false;
        this.productOwnerDropdownSettings.searchBoxPlaceholder = 'ค้นหาเจ้าของสินค้า';
        this.productOwnerDropdownSettings.dropdownListPlaceholder = 'เลือกเจ้าของสินค้า';

        this.productDropdownSettings = Object.assign({}, dropdownSettings);
        this.productDropdownSettings.dropdownListPlaceholder = 'เลือกโปรแกรมทัวร์';

        this.sellerDropdownSettings = Object.assign({}, dropdownSettings);
        this.sellerDropdownSettings.underlineAfterFirstItem = true;
        this.sellerDropdownSettings.hideSearchBox = false;
        this.sellerDropdownSettings.searchBoxPlaceholder = 'ค้นหาเซลล์บริษัท';
        this.sellerDropdownSettings.dropdownListPlaceholder = 'เลือกเซลล์บริษัท';
    }

    private initDropdownItems(): void {
        this.productOwnerDropdownItems = [];
        this.productDropdownItems = [];
        this.sellerOwnerDropdownItems = [];

        this.store.dispatch(UserActions.getUsersList(true, true, true));
        this.store.dispatch(OrderActions.getProductList({ owner: null, hideLoadingIcon: true }));
        this.store.dispatch(OrderActions.getProductOwnerList({ hideLoadingIcon: true }));

        this.store
            .pipe(
                select(selectUsersList),
                filter((resp) => !!resp),
                take(1),
                map((usersList) => {
                    this.sellerOwnerDropdownItems = [
                        new SearchableDropdownItem({ id: null, name: 'เซลล์บริษัท ทั้งหมด' }),
                    ];
                    return usersList.map((user) => {
                        const dropDownItem = new SearchableDropdownItem({
                            id: user.id,
                            name: user.nameString,
                            isChecked: user.id === this.preselectedSalesId,
                        });
                        this.sellerOwnerDropdownItems.push(dropDownItem);
                    });
                })
            )
            .subscribe();

        this.store
            .pipe(
                select(selectProductList),
                filter((resp) => resp !== null),
                takeUntil(this.unsubscribe$)
            )
            .subscribe((response) => {
                this.productDropdownItems = response.map((x) => {
                    return new SearchableDropdownItem({
                        id: x.isCustomProduct ? x.customProductOrderId.toString() : x.productPoolKey,
                        name: x.tourCode ? `${this.agency.productCode}-${x.tourCode} ${x.name}` : x.name,
                        isChecked:
                            (x.productPoolKey && x.productPoolKey === this.presets?.poolKey) ||
                            (x.isCustomProduct && x.name === this.presets?.customProductName),
                        value: x,
                    });
                });
            });

        this.store
            .pipe(
                select(selectProductOwnerList),
                filter((resp) => resp !== null),
                takeUntil(this.unsubscribe$)
            )
            .subscribe((response) => {
                this.productOwnerDropdownItems = response.map((x) => {
                    return new SearchableDropdownItem({
                        id: x.channel + x.id,
                        name: `${this.agency.id == x.id && x.channel == 'ag' ? 'Owner ' : ''}${x.nameEn} (${x.nameTh})`,
                        isChecked:
                            x.id === this.preselectedOwner?.ownerId &&
                            x.channel === this.preselectedOwner?.ownerChannel,
                    });
                });
            });
    }

    private initBookingStatuses(checkedIds?: number[]) {
        this.bookingStatuses = new Map<number, { checked?: boolean; label: string }>();
        this.bookingStatuses.set(this.BOOKING_STATUS_ALL, {
            label: 'ทั้งหมด',
            checked: checkedIds.includes(this.BOOKING_STATUS_ALL),
        });
        this.bookingStatuses.set(BOOKING_STATUS_CODES.WAITING_FOR_FIRST_PAYMENT, {
            label: 'รอชำระเงินงวดแรก',
            checked: checkedIds.includes(BOOKING_STATUS_CODES.WAITING_FOR_FIRST_PAYMENT),
        });
        this.bookingStatuses.set(BOOKING_STATUS_CODES.WAITING_FOR_FULL_PAYMENT, {
            label: 'รอชำระเงินครบ',
            checked: checkedIds.includes(BOOKING_STATUS_CODES.WAITING_FOR_FULL_PAYMENT),
        });
        this.bookingStatuses.set(BOOKING_STATUS_CODES.COMPLETE, {
            label: 'รอเดินทาง',
            checked: checkedIds.includes(BOOKING_STATUS_CODES.COMPLETE),
        });
        this.bookingStatuses.set(BOOKING_STATUS_CODES.COMPLETE + 1, {
            label: 'เดินทางแล้ว',
            checked: checkedIds.includes(BOOKING_STATUS_CODES.COMPLETE + 1),
        });
        this.bookingStatuses.set(BOOKING_STATUS_CODES.CANCEL, {
            label: 'ยกเลิก',
            checked: checkedIds.includes(BOOKING_STATUS_CODES.CANCEL),
        });
    }

    private syncBookingStatusesFromShortcut(queryBookingStatuses: IBookingListOrderStatusFilter[]): void {
        this.bookingStatusesSelected = [];
        if (queryBookingStatuses) {
            this.bookingStatusesSelected = queryBookingStatuses.map((status) => {
                return {
                    statusCode: status.status_code,
                    isStarted: status.already_started,
                };
            });
        }
        let checkboxBookingStatuses: number[] = [this.BOOKING_STATUS_ALL];
        if (this.bookingStatusesSelected.length > 0) {
            checkboxBookingStatuses = this.bookingStatusesSelected.map((status) => {
                if (status.statusCode === BOOKING_STATUS_CODES.COMPLETE) {
                    return status.isStarted ? BOOKING_STATUS_CODES.COMPLETE + 1 : BOOKING_STATUS_CODES.COMPLETE;
                }
                return status.statusCode;
            });
        }
        this.initBookingStatuses(checkboxBookingStatuses);
    }
}
