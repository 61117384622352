<div class="lead-details row" [class.disabled]="fieldStatus === 'ยกเลิก'">
    <div class="col-2 booking-column d-flex">
        <div class="mr-2">{{ ordinal }}.</div>
        <div>
            <div *ngIf="isNameClickable; then nameLink; else nameField"></div>
            <ng-template #nameLink>
                <button (click)="openContactInfoModal()" class="name-field-link">
                    <p class="name-field">
                        <span class="label-customer-name">ลูกค้า : </span>
                        <span class="font-weight-bold"> {{ fieldCustomerName }} <i class="icon icon-info"></i></span>
                    </p>
                </button>
            </ng-template>
            <ng-template #nameField>
                <p class="name-field">
                    ลูกค้า : <span class="font-weight-bold">{{ fieldCustomerName }}</span>
                </p>
            </ng-template>
            <p>
                จาก : <span class="font-weight-bold">{{ fieldFrom }}</span>
            </p>
            <p>เซลล์ : {{ fieldSalesPerson }}</p>
        </div>
    </div>
    <div class="col-1 time-col">
        <p>{{ fieldDate }}</p>
        <p>{{ fieldTime }}</p>
    </div>
    <div class="col-5 program-name-col overflow-hidden">
        <p>
            <ng-container *ngIf="lead.tour_code">
                <span>{{ (agencyInfo$ | async).productCode | uppercase }}</span>
                <span>-</span>
                <span>{{ lead.tour_code | uppercase }}</span>
            </ng-container>
            <span> {{ lead.product_name }}</span>
        </p>
        <p>
            {{ fieldProgramPeriod }} ราคาเริ่มต้น {{ lead.price | number }}
            <span *ngIf="lead.commissionString">{{ lead.commissionLabel }} {{ lead.commissionString }}</span>
        </p>
        <small class="detail-field">
            <div
                class="d-inline-block"
                (click)="openProductOwnerModal(lead.owner)"
                *ngIf="lead.owner && !lead.agency_code; else ownerIsAgencyTemplate"
            >
                <u class="cursor-pointer">
                    โปรแกรมจาก : {{ lead.owner?.name_en }} ({{ lead.owner?.name_th }}) โทร.{{
                        lead.owner?.phone_number
                    }}
                    <i class="icon icon-info"></i>
                </u>
            </div>
            <span>
                <a
                    *ngIf="lead.wholesale_open_onlinebooking"
                    href="{{ getOnlineBookingLink(lead) }}"
                    target="_blank"
                    style="color: #146d99"
                    class="ml-2"
                    >จอง Online Booking</a
                >
            </span>
            <ng-template #ownerIsAgencyTemplate>
                <div
                    (click)="openProductOwnerModal(lead.owner)"
                    *ngIf="
                        lead.agency_code?.toUpperCase() !== (agencyInfo$ | async).productCode?.toUpperCase();
                        else ownerIsSelfTemplate
                    "
                >
                    <u class="cursor-pointer">
                        โปรแกรมจาก : {{ lead.owner?.name_en }} ({{ lead.owner?.name_th }}) โทร.{{
                            lead.owner?.phone_number
                        }}
                        <i class="icon icon-info"></i>
                    </u>
                </div>
                <ng-template #ownerIsSelfTemplate>
                    <strong style="color: #28a744">Owner</strong>
                </ng-template>
            </ng-template>
        </small>
    </div>
    <div class="col-2 status-dd-col">
        <div class="btn-group" dropdown>
            <button
                id="button-basic"
                dropdownToggle
                type="button"
                class="btn btn-primary dropdown-toggle"
                aria-controls="dropdown-basic"
            >
                {{ fieldStatus }} <span class="caret"></span>
            </button>
            <ul id="dropdown-basic" *dropdownMenu class="dropdown-menu" role="menu" aria-labelledby="button-basic">
                <ng-container *ngFor="let status of statusValues">
                    <li role="menuitem">
                        <span
                            class="dropdown-item"
                            (click)="onStatusChange(status)"
                            [class.active]="fieldStatus === status"
                        >
                            {{ status }}
                        </span>
                    </li>
                </ng-container>
            </ul>
        </div>
    </div>
    <div class="col-1 pl-3">
        <button
            *ngIf="fieldStatus !== 'ยกเลิก'"
            type="button"
            class="btn btn-primary edit-button"
            (click)="onEditBooking()"
        >
            Edit
        </button>
    </div>
    <div *ngIf="fieldStatus === 'ยกเลิก'" class="backdrop"></div>
</div>
