<div class="campaign-orderlist">
    <div class="campaign-orderlist-header mb-5">
        <app-breadcrumb [items]="breadcrumb"></app-breadcrumb>
        <h2 class="text-primary align-baseline">
            <img class="head-icon" src="assets/img/icon/icon-coupon-orange.svg" />
            ข้อมูลการซื้อเวาเชอร์
        </h2>
    </div>

    <div class="campaign-orderlist-section mb-4 p-4">
        <app-search-order-box (search)="doSearchFilter($event)"></app-search-order-box>
    </div>

    <div class="campaign-orderlist-section pt-4 pb-4">
        <h5 class="align-baseline mb-3 ml-4 mr-4">
            <strong style="color: #344251">
                คุณกำลังดูข้อมูลการซื้อเวาเชอร์
                <span *ngIf="filter?.hasUsableVoucherTickets === true">รอใช้สิทธิ์</span>
                <span *ngIf="filter?.hasUsableVoucherTickets === null">ทั้งหมด</span> {{ paginated?.total }} รายการ
            </strong>
            <span class="bluey-grey-text pl-2">(ทุกรายการชำระเงินครบแล้ว)</span>
        </h5>
        <div class="campaign-orderlist-view">
            <app-order-list-table [paginated]="paginated" (dataChanged)="dispatchPaginated()"></app-order-list-table>
        </div>
    </div>

    <div class="mt-4 text-center" *ngIf="paginated?.result?.length < paginated?.total">
        <button class="btn btn-show-more bluey-grey-text" (click)="showMorePaginated()">
            แสดงรายการที่ {{ paginated?.result?.length + 1 }} - {{ paginated?.result?.length + paginated?.pageEntries }}
        </button>
    </div>
</div>
