import { Pipe, PipeTransform } from '@angular/core';
import { IWorkListTask } from '../models/interfaces/worklist-task.interface';

@Pipe({
    name: 'worklistPipe',
})
export class WorklistPipe implements PipeTransform {
    transform(tasks: IWorkListTask[], filterByRole: string, isSupplement: boolean): IWorkListTask[] {
        return tasks
            .filter((f) => f.taskType.forRole.indexOf(filterByRole) >= 0 && f.taskType.isSupplement === isSupplement)
            .sort((a, b) => a.taskType.ordinal - b.taskType.ordinal);
    }
}
