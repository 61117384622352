export class ProductFileBase64 {
    public fileName: string = null;
    public base64Data: string = null;

    public deserialize(obj: any): ProductFileBase64 {
        this.fileName = obj.file_name;
        this.base64Data = obj.base64_data;
        return this;
    }
}
