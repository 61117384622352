import { HttpService } from './base/http.service';

import { ENDPOINTS } from '../constants/endpoint.constant';
import { Observable } from 'rxjs';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { environment } from 'src/environments/environment';
import { ENTITY, ACTION_TYPE } from '../constants/event-log.constant';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { map, switchMap } from 'rxjs/operators';

@Injectable({
    providedIn: 'root',
})
export class EventLogService extends HttpService {
    constructor(
        @Inject(PLATFORM_ID) protected platformId: Object,
        protected http: HttpClient,
        protected router: Router
    ) {
        super(platformId, http, router);
    }

    log(entityName: ENTITY, actionType: ACTION_TYPE, content: any | null): Observable<any> {
        const url = environment.apiProUrl + ENDPOINTS.LOG.WEB_EVENT_LOG;
        const payload = {
            entity_name: entityName,
            action_type: actionType,
            content: content,
        };

        return this.getRequestOptions(true).pipe(
            switchMap((headers) => {
                const options = {
                    headers: headers.headers,
                };

                return this.http.post(url, payload, options);
            })
        );
    }
}
