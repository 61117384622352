import { Injectable, Inject, PLATFORM_ID } from '@angular/core';
import { HttpService } from './base/http.service';
import { Observable } from 'rxjs';
import { ENDPOINTS } from '../constants/endpoint.constant';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { IBannerUpdaterRequest } from '../modules/settings/models/config-banners.model';

@Injectable({
    providedIn: 'root',
})
export class ConfigBannersService extends HttpService {
    constructor(
        @Inject(PLATFORM_ID) protected platformId: Object,
        protected http: HttpClient,
        protected router: Router,
        private httpService: HttpService
    ) {
        super(platformId, http, router);
    }

    loadConfigBanners(): Observable<any> {
        return this.httpService.get(environment.apiProUrl + ENDPOINTS.CONFIG_BANNERS.GET, true);
    }

    updateConfigBanners(payload: IBannerUpdaterRequest): Observable<any> {
        return this.httpService.put(environment.apiProUrl + ENDPOINTS.CONFIG_BANNERS.PUT, payload, true);
    }
}
