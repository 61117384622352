import { environment } from 'src/environments/environment';
import { IMenu } from '../models/menu.model';

export const MENU: IMenu[] = [
    {
        name: 'ใบจองจากลูกค้า',
        leadCountNotification: 'lead-count',
        link: '/report/lead-list',
        iconClass: 'icon-dashboard',
        slug: 'lead-list',
        isHidden: false,
    },
    {
        name: 'ข้อมูลการจองทัวร์(TW)',
        link: '/order/tw-booking/list',
        iconClass: 'icon-status',
        slug: 'tw-booking-list',
        isHidden: false,
    },
    {
        name: 'ข้อมูลการจองทัวร์',
        link: '/order/booking-list',
        iconClass: 'icon-status',
        slug: 'booking-list',
        isHidden: false,
    },
    {
        name: 'ข้อมูลการซื้อเวาเชอร์',
        link: '/order/campaign-list',
        svgIconFilePath: 'assets/img/icon/icon-coupon-white.svg',
        slug: 'order-list',
        isHidden: false,
    },
    {
        name: 'ข้อมูลการซื้อ\nโปรแกรมทัวร์',
        link: '/order/program-list',
        svgIconFilePath: 'assets/img/icon/icon-coupon-white.svg',
        slug: 'program-list',
        isHidden: environment.production,
    },
    {
        name: 'จัดการข้อมูลสินค้า',
        link: '/product/catalog',
        svgIconFilePath: 'assets/img/icon/icon-management-white.svg',
        collapsedSubMenu: true,
        slug: 'product-management',
        isHidden: false,
    },
    {
        name: 'รายงาน',
        link: '',
        iconClass: 'icon-report',
        collapsedSubMenu: true,
        subMenu: [
            {
                name: 'ดาวน์โหลดข้อมูลใบจองจากลูกค้า',
                link: '/report/lead-report-download',
                slug: 'report-download',
                isHidden: false,
            },
            {
                name: 'ดาวน์โหลดข้อมูลสถานะการจอง',
                link: '/report/booking-report-download',
                slug: 'report-download',
                isHidden: false,
            },
            {
                name: 'สรุปรายการรับค่าคอม',
                link: '/report/order-balance-report',
                slug: 'report-download',
                isHidden: false,
            },
            {
                name: 'ดาวน์โหลดข้อมูลใบเสร็จรับเงินค่าคอมของเวาเชอร์',
                link: '/report/order-balance-report-download',
                slug: 'report-download',
                isHidden: false,
            },
        ],
        slug: 'report',
        isHidden: false,
    },
    // { name: 'จัดการข้อมูลบริษัท', link: '', iconClass: 'icon-management' },
    // { name: 'ดาวน์โหลดรายงาน', link: '', iconClass: 'icon-report' },
    {
        name: 'Settings',
        link: '',
        iconClass: 'icon-settings',
        collapsedSubMenu: true,
        subMenu: [
            { name: 'เกี่ยวกับเรา', link: '/settings/about-us', isHidden: false },
            { name: 'จัดการ Banner', link: '/settings/config-banners', isHidden: false },
            { name: 'จัดการเมนูพิเศษ 1', slug: 'custom-content-menu-1', link: '/settings/menu/1', isHidden: false },
            { name: 'จัดการเมนูพิเศษ 2', slug: 'custom-content-menu-2', link: '/settings/menu/2', isHidden: false },
            { name: 'ผลงานที่ผ่านมา', slug: 'portfolio', link: '/settings/portfolio', isHidden: false },
            { name: 'บทความ', slug: 'blog', link: '/settings/blog', isHidden: false },
            { name: 'บทความ SEO', slug: 'article', link: '/settings/article', isHidden: false },
            {
                name: 'จัดการใบเรียกเก็บเงิน',
                slug: 'invoice-config',
                link: '/settings/invoice-config',
                isHidden: false,
            },
        ],
        slug: 'settings',
        isHidden: false,
    },
];
