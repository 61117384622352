import { HttpService } from './base/http.service';

import { ENDPOINTS } from '../constants/endpoint.constant';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
    providedIn: 'root',
})
export class ProvinceService {
    constructor(private httpService: HttpService) {}

    getAllProvinces(): Observable<any> {
        const url = environment.apiProUrl + ENDPOINTS.PROVINCE.GET_ALL;
        return this.httpService.get(url, null);
    }
}
