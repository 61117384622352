export interface IAddBookingB2c {
    seller_agency_member_id: number;
    b2c_lead_id: number;
    product: {
        pool_key: string;
        owner_id: number;
        owner_channel: string;
        custom_product_name: string;
        start_at: string;
        end_at: string;
        commission_company: number;
        commission_seller: number;
    };
    contact: {
        contact_name: string;
        phone_number: string;
        email: string;
        lead_type_slug: string;
        customer_remark: string;
    };
    order_items: IBookingOrderItem[];
    order_installments: IBookingOrderInstallment[];
}

export interface IBookingOrderItem {
    id?: null;
    description: string;
    price: number;
    quantity: number;
    total: number;
    to_update?: boolean;
}

export interface IBookingOrderInstallment {
    payment_duedate_at: string;
    total_paid: number;
    id?: number;
    to_update?: boolean;
}

export class AddBookingB2c {
    public static initNewBooking(): IAddBookingB2c {
        return {
            seller_agency_member_id: null,
            b2c_lead_id: null,
            product: {
                pool_key: null,
                owner_id: null,
                owner_channel: null,
                custom_product_name: null,
                start_at: null,
                end_at: null,
                commission_company: null,
                commission_seller: null,
            },
            contact: {
                contact_name: null,
                phone_number: null,
                email: null,
                lead_type_slug: null,
                customer_remark: null,
            },
            order_items: null,
            order_installments: null,
        };
    }
}
