<div class="booking-container" [class.cancelled-booking]="booking.order.orderStatus === STATUS_CODES.CANCEL">
    <div class="main-row">
        <div class="1">
            <p class="index">{{ index + 1 }}.</p>
            <p class="first-line">
                <a (click)="openContactInfoModal()" class="name-field-link">
                    <span class="font-weight-bold">
                        ลูกค้า : {{ booking.contact.contactName }}
                        <i class="icon icon-info"></i>
                    </span>
                </a>
                <span> จองวันที่ {{ booking.createdAt | thaiMonthDateTime: true }} </span>
                <span> ({{ booking.order.orderCode }}) </span>
            </p>
            <p class="second-line">
                {{ CATEGORY_SUB_PRODUCTS_TO_STRING[booking.product.categorySubProductsId] }}
                เดินทาง {{ booking.product.startAt | thaiDate }}&nbsp;-&nbsp;{{ booking.product.endAt | thaiDate }}
                <ng-container *ngIf="booking.product.tourCode">
                    {{ agencyInfo.productCode }}-{{ booking.product.tourCode }}
                </ng-container>
                {{ booking.product.productName }}
            </p>
            <p class="third-line">
                <a (click)="openBusinessContactInfoModal()" class="name-field-link">
                    {{ booking.productOwner.nameEn }} ({{ booking.productOwner.nameTh }}) โทร.{{
                    booking.productOwner.phoneNumber | phoneNumber
                    }}
                    <i class="icon icon-info"></i>
                </a>
                <a *ngIf="booking.productOwner.wsOpenOnlineBooking" target="_blank" class="ml-2" style="color: #146d99"
                    href="{{getOnlineBookingLink(booking)}}">จอง Online
                    Booking</a>
            </p>
            <p class="fourth-line" *ngIf="booking.sellerAgencyMember">
                เซลล์ผู้ขาย : {{ booking.sellerAgencyMember.firstName }} {{ booking.sellerAgencyMember.lastName }}
                {{ booking.sellerAgencyMember.nickName ? ' (' + booking.sellerAgencyMember.nickName + ')' : '' }}
            </p>
        </div>
        <div class="2">
            <p>{{ booking.order.sumQuantity }} คน</p>
            <p>{{ booking.order.sumTotal | number: '1.2-2' }}</p>
            <p *ngIf="agencyInfo.displayCommission">คอม {{ booking.order.sumCommission | number }}</p>
            <a *ngIf="booking.order.orderStatus !== STATUS_CODES.CANCEL"
                (click)="downloadReport(true)">ดาวน์โหลดใบเรียกเก็บเงิน</a>
        </div>
        <div class="3" [class.blue-background]="booking.order.orderStatus === STATUS_CODES.WAITING_FOR_FIRST_PAYMENT">
            <div *ngIf="
                    booking.order.orderStatus === STATUS_CODES.WAITING_FOR_FULL_PAYMENT ||
                    booking.order.orderStatus === STATUS_CODES.COMPLETE
                " class="success-tick"></div>
            <p>{{ booking.firstOrderInstallments.totalPaid | number: '1.2-2' }}</p>
            <p [class.is-expired]="
                    booking.order.orderStatus === STATUS_CODES.WAITING_FOR_FIRST_PAYMENT &&
                    isExpired(booking.firstOrderInstallments.paymentDuedate)
                ">
                วันที่ {{ booking.firstOrderInstallments.paymentDuedate | thaiDate }}
            </p>
        </div>
        <div class="4" [class.blue-background]="
                (booking.order.orderStatus === STATUS_CODES.WAITING_FOR_FIRST_PAYMENT ||
                    booking.order.orderStatus === STATUS_CODES.WAITING_FOR_FULL_PAYMENT) &&
                booking.otherOrderInstallments
            ">
            <ng-container *ngIf="booking.otherOrderInstallments">
                <div *ngIf="booking.order.orderStatus === STATUS_CODES.COMPLETE" class="success-tick"></div>
                <p *ngIf="booking.otherOrderInstallments.sumTotalPaid">
                    {{ booking.otherOrderInstallments.sumTotalPaid | number: '1.2-2' }}
                </p>
                <p *ngIf="booking.otherOrderInstallments.firstPaymentDuedate" [class.is-expired]="
                        (booking.order.orderStatus === STATUS_CODES.WAITING_FOR_FIRST_PAYMENT ||
                            booking.order.orderStatus === STATUS_CODES.WAITING_FOR_FULL_PAYMENT) &&
                        isExpired(booking.otherOrderInstallments.firstPaymentDuedate)
                    ">
                    วันที่ {{ booking.otherOrderInstallments.firstPaymentDuedate | thaiDate }}
                </p>
            </ng-container>
            <p *ngIf="!booking.otherOrderInstallments">-</p>
        </div>
        <div class="5">
            <p>{{ getStatus() }}</p>
            <button (click)="openEditBookingModal()">แก้ไข</button>
        </div>
    </div>
    <div class="bottom-row">
        <div class="1" [ngClass]="showBottomPart ? 'expanded' : 'not-expanded'">
            <ng-container *ngIf="!showBottomPart">
                <p>{{ booking.contact.customerRemark }}</p>
            </ng-container>
            <ng-container *ngIf="showBottomPart">
                <h4 *ngIf="booking.contact.customerRemark">ข้อมูลเพิ่มเติม (Remark)</h4>
                <p>{{ booking.contact.customerRemark }}</p>
            </ng-container>
        </div>
        <div class="2">
            <ng-container *ngIf="showBottomPart && booking.orderFiles.length">
                <p>ไฟล์ที่แนบ</p>
                <ng-container *ngFor="let file of booking.orderFiles">
                    <div class="d-flex">
                        <a class="d-block" href="{{ file.fileUrl }}" target="_blank">
                            {{ getFileDisplayUrl(file.fileUrl) }}
                        </a>
                        <button type="button" class="btn-bin" (click)="deleteFile(file.id)">
                            <i class="icon icon-garbage"></i>
                        </button>
                    </div>
                </ng-container>
            </ng-container>
        </div>
        <div class="4">
            <ng-container *ngIf="showBottomPart">
                <p>จัดการข้อมูลการจอง</p>
                <a (click)="openStatusDropdown()" class="icon-booking-payment">แจ้งชำระเงิน</a>
                <label class="icon-attachment-clip" [for]="'upload-file-' + booking.id">แนบไฟล์</label>
                <input class="d-none" type="file" [id]="'upload-file-' + booking.id"
                    (change)="onFileUpload($event.target.files, $event)" />
                <a (click)="onCancelBooking()" class="icon-booking-cancel">ยกเลิกจอง</a>
            </ng-container>
        </div>
        <div class="5">
            <div (click)="toggleBottomExpand()" class="toggle-expand">
                <span>{{ showBottomPart ? 'ปิด' : 'เปิด' }}</span>
                <i class="icon" [ngClass]="showBottomPart ? 'icon-arrow-up' : 'icon-arrow-down'"></i>
            </div>
        </div>
    </div>
</div>