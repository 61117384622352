import { createFeatureSelector, createSelector } from '@ngrx/store';
import * as fromCampaign from './campaign.reducer';

export const selectCampaignState = createFeatureSelector<fromCampaign.CampaignState>(fromCampaign.campaignFeatureKey);

export const selectCampaigns = createSelector(
    selectCampaignState,
    (state: fromCampaign.CampaignState) => state.campaigns
);

export const selectCampaignsPaginated = createSelector(
    selectCampaignState,
    (state: fromCampaign.CampaignState) => state.campaignPaginated
);

export const selectCampaignHilight = createSelector(
    selectCampaignState,
    (state: fromCampaign.CampaignState) => state.campaignHilight
);

export const selectChaophrayaCruiseBooking = createSelector(
    selectCampaignState,
    (state: fromCampaign.CampaignState) => state.chaophrayaCruiseBooking
);
