import * as moment from 'moment';

import { Pipe, PipeTransform } from '@angular/core';
import { MomentUtil } from '../utils/moment.util';

const thaiMonths = [
    'มกราคม',
    'กุมภาพันธ์',
    'มีนาคม',
    'เมษายน',
    'พฤษภาคม',
    'มิถุนายน',
    'กรกฎาคม',
    'สิงหาคม',
    'กันยายน',
    'ตุลาคม',
    'พฤศจิกายน',
    'ธันวาคม',
];

const thaiMonthsAbbreviation = [
    'ม.ค.',
    'ก.พ.',
    'มี.ค.',
    'เม.ย.',
    'พ.ค.',
    'มิ.ย.',
    'ก.ค.',
    'ส.ค.',
    'ก.ย.',
    'ต.ค.',
    'พ.ย.',
    'ธ.ค.',
];

const dayOfWeekInThai = {
    Mon: 'จันทร์',
    Tue: 'อังคาร',
    Wed: 'พุธ',
    Thu: 'พฤหัสบดี',
    Fri: 'ศุกร์',
    Sat: 'เสาร์',
    Sun: 'อาทิตย์',
};

@Pipe({
    name: 'thaiMonthDateTime',
})
export class ThaiMonthDatetimePipe implements PipeTransform {
    transform(value: any, useAbbr: boolean = false): any {
        if (!value) {
            return;
        }
        const date = moment(value);
        if (!date.isValid()) {
            return;
        }

        const thaiDate = date.add(543, 'y');
        const dateAsString = thaiDate.format('DD/MM/YY HH:mm');

        const dateAsArray = dateAsString.split('/');
        const monthIndex = Number(dateAsArray[1]) - 1;
        const monthSelected = useAbbr ? thaiMonthsAbbreviation[monthIndex] : thaiMonths[monthIndex];
        dateAsArray['1'] = monthSelected;
        const result = dateAsArray.join(' ');
        return result;
    }
}
@Pipe({
    name: 'thaiMonth',
})
export class ThaiMonthPipe implements PipeTransform {
    transform(value: any, useAbbr: boolean = false): any {
        if (!value) {
            return;
        }
        const date = moment(value);
        if (!date.isValid()) {
            return;
        }

        const thaiDate = date.add(543, 'y');
        const dateAsString = thaiDate.format('MM/YY');

        const dateAsArray = dateAsString.split('/');
        const monthIndex = Number(dateAsArray[0]) - 1;
        const monthSelected = useAbbr ? thaiMonthsAbbreviation[monthIndex] : thaiMonths[monthIndex];
        dateAsArray['0'] = monthSelected;
        const result = dateAsArray.join(' ');
        return result;
    }
}

@Pipe({
    standalone: true,
    name: 'thaidaterange',
})
export class ThaiDateRangePipe implements PipeTransform {
    transform(date1: string | Date, date2: string | Date, displayDate = true): string {
        let result = '';
        const date1Obj = moment(date1);
        const date2Obj = moment(date2);
        if (!date1Obj.isValid() || !date2Obj.isValid()) {
            return result;
        }
        if (displayDate) {
            if (
                date1Obj.date() == date2Obj.date() &&
                date1Obj.month() == date2Obj.month() &&
                date1Obj.year() == date2Obj.year()
            ) {
                result = `${date2Obj.format('DD')} ${
                    thaiMonthsAbbreviation[date1Obj.month()]
                } ${this.convertToBuddhistYear(date1Obj)}`;
            } else if (date1Obj.month() == date2Obj.month() && date1Obj.year() == date2Obj.year()) {
                result = `${date1Obj.format('DD')} - ${date2Obj.format('DD')} ${
                    thaiMonthsAbbreviation[date1Obj.month()]
                } ${this.convertToBuddhistYear(date1Obj)}`;
            } else if (date1Obj.month() != date2Obj.month() && date1Obj.year() != date2Obj.year()) {
                result = `${date1Obj.format('DD')} ${
                    thaiMonthsAbbreviation[date1Obj.month()]
                } ${this.convertToBuddhistYear(date1Obj)} - ${date2Obj.format('DD')} ${
                    thaiMonthsAbbreviation[date2Obj.month()]
                } ${this.convertToBuddhistYear(date2Obj)}`;
            } else {
                result = `${date1Obj.format('DD')} ${thaiMonthsAbbreviation[date1Obj.month()]} - ${date2Obj.format(
                    'DD'
                )} ${thaiMonthsAbbreviation[date2Obj.month()]} ${this.convertToBuddhistYear(date2Obj)}`;
            }
        } else {
            if (date1Obj.month() == date2Obj.month() && date1Obj.year() == date2Obj.year()) {
                result = `${thaiMonthsAbbreviation[date1Obj.month()]} ${this.convertToBuddhistYear(date1Obj)}`;
            } else if (date1Obj.month() != date2Obj.month() && date1Obj.year() != date2Obj.year()) {
                result = `${thaiMonthsAbbreviation[date1Obj.month()]} ${this.convertToBuddhistYear(date1Obj)} -  ${
                    thaiMonthsAbbreviation[date2Obj.month()]
                } ${this.convertToBuddhistYear(date2Obj)}`;
            } else {
                result = ` ${thaiMonthsAbbreviation[date1Obj.month()]} - ${
                    thaiMonthsAbbreviation[date2Obj.month()]
                } ${this.convertToBuddhistYear(date2Obj)}`;
            }
        }

        return result;
    }

    private convertToBuddhistYear(moment: moment.Moment): string {
        return (+moment.format('YYYY') + 543).toString().slice(2);
    }
}

@Pipe({
    name: 'thaimonthrange',
})
export class ThaiMonthRangePipe implements PipeTransform {
    transform(date1: string, date2: string): string {
        let result = '';
        const date1Obj = moment(date1);
        const date2Obj = moment(date2);
        if (!date1Obj.isValid() || !date2Obj.isValid()) {
            return result;
        }

        if (
            date1Obj.date() == date2Obj.date() &&
            date1Obj.month() == date2Obj.month() &&
            date1Obj.year() == date2Obj.year()
        ) {
            result = `${thaiMonthsAbbreviation[date1Obj.month()]} ${this.convertToBuddhistYear(date1Obj)}`;
        } else if (date1Obj.month() == date2Obj.month() && date1Obj.year() == date2Obj.year()) {
            result = `${thaiMonthsAbbreviation[date1Obj.month()]} ${this.convertToBuddhistYear(date1Obj)}`;
        } else if (date1Obj.month() != date2Obj.month() && date1Obj.year() != date2Obj.year()) {
            result = `${thaiMonthsAbbreviation[date1Obj.month()]} ${this.convertToBuddhistYear(date1Obj)} - ${
                thaiMonthsAbbreviation[date2Obj.month()]
            } ${this.convertToBuddhistYear(date2Obj)}`;
        } else {
            result = `${thaiMonthsAbbreviation[date1Obj.month()]} - ${
                thaiMonthsAbbreviation[date2Obj.month()]
            } ${this.convertToBuddhistYear(date2Obj)}`;
        }
        return result;
    }

    private convertToBuddhistYear(moment: moment.Moment): string {
        return (+moment.format('YYYY') + 543).toString().slice(2);
    }
}

@Pipe({
    name: 'thaiMonthDate',
})
export class ThaiMonthDatePipe implements PipeTransform {
    transform(value: any, useAbbr: boolean = true): string {
        if (!value) {
            return '';
        }
        const date = moment(value);
        if (!date.isValid()) {
            return '';
        }

        const dateFormat = 'MM YY';
        const dateAsString = MomentUtil.toBuddhistYear(date, dateFormat);
        const dateAsArray = dateAsString.split(' ');
        const monthIndex = dateAsArray['0'] - 1;
        const monthSelected = useAbbr ? thaiMonthsAbbreviation[monthIndex] : thaiMonths[monthIndex];
        dateAsArray['0'] = monthSelected;
        const result = dateAsArray.join(' ');
        return result;
    }
}

@Pipe({
    standalone: true,
    name: 'thaiDate',
})
export class ThaiDatePipe implements PipeTransform {
    transform(value: any, dateFormat: string = 'DD MM YY'): string {
        if (!value) {
            return '';
        }
        const date = moment(value);
        if (!date.isValid()) {
            return '';
        }

        const dateAsString = MomentUtil.toBuddhistYear(date, dateFormat);
        const dateAsArray = dateAsString.split(' ');
        const monthIndex = dateAsArray['1'] - 1;
        const monthSelected = thaiMonthsAbbreviation[monthIndex];
        dateAsArray['1'] = monthSelected;
        const result = dateAsArray.join(' ');
        return result;
    }
}

@Pipe({
    name: 'thaidayOfWeek',
})
export class DayOfWeekInThaiPipe implements PipeTransform {
    transform(value: number): any {
        switch (value) {
            case 0:
                return dayOfWeekInThai.Sun;
            case 1:
                return dayOfWeekInThai.Mon;
            case 2:
                return dayOfWeekInThai.Tue;
            case 3:
                return dayOfWeekInThai.Wed;
            case 4:
                return dayOfWeekInThai.Thu;
            case 5:
                return dayOfWeekInThai.Fri;
            case 6:
                return dayOfWeekInThai.Sat;
            default:
                return '';
        }
    }
}

@Pipe({
    name: 'thaiDateWithoutYear',
})
export class ThaiDateWithoutYearPipe implements PipeTransform {
    transform(value: string): any {
        if (!value) {
            return '';
        }
        const date = moment(value);
        if (!date.isValid()) {
            return '';
        }

        const thaiDay = new DayOfWeekInThaiPipe().transform(date.day());
        const thaiMonth = thaiMonths[date.month()];

        return `วัน${thaiDay}ที่ ${date.date()} ${thaiMonth}`;
    }
}
