<div class="partnercontactmodal p-4">
    <div class="text-right">
        <a href="javascript: void(0)">
            <i class="icon icon-close1" (click)="closeModal()"></i>
        </a>
    </div>
    <div class="partnercontactmodal-info">
        <div class="text-center">
            <h6>
                <strong>รายละเอียดผู้ติดต่อ (เจ้าของ Campaign)</strong>
            </h6>
        </div>
        <div class="mt-4 partnercontactmodal-table">
            <table class="table">
                <tr>
                    <td class="table-label">Seller :</td>
                    <td>
                        <span *ngIf="partner.nameEn">
                            {{partner.nameEn}}
                        </span>
                        <span  *ngIf="partner.nameTh">
                            ({{partner.nameTh}})
                        </span>
                    </td>
                </tr>
                <tr>
                    <td class="table-label">เบอร์โทรหลัก : </td>
                    <td>{{partner.officePhoneNumber | phoneNumber}}</td>
                </tr>
                <tr *ngIf="partner.additionalPhoneNumber">
                    <td class="table-label">เบอร์โทรเพิ่มเติม : </td>
                    <td>
                        <span class="whitespace-prewrap">{{partner.additionalPhoneNumber}}</span>
                    </td>
                </tr>
                <tr>
                    <td class="table-label">อีเมล :</td>
                    <td>{{partner.emailAddress || '-'}}</td>
                </tr>
                <tr>
                    <td class="table-label">ที่อยู่ :</td>
                    <td>
                        <span class="whitespace-prewrap">{{partner.address || '-'}}</span>
                    </td>
                </tr>
            </table>
        </div>
        <div class="mt-auto text-center">
            <button class="btn text-white modal-close-button" (click)="closeModal()">ปิด</button>
        </div>
    </div>
</div>