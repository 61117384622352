import { Injectable, Inject, PLATFORM_ID } from '@angular/core';
import { environment } from 'src/environments/environment';
import { ENDPOINTS } from '../constants/endpoint.constant';
import { switchMap } from 'rxjs/operators';
import { HttpService } from './base/http.service';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';

@Injectable({
    providedIn: 'root',
})
export class AboutUsService extends HttpService {
    constructor(
        @Inject(PLATFORM_ID) protected platformId: Object,
        protected http: HttpClient,
        protected router: Router
    ) {
        super(platformId, http, router);
    }

    public getAboutUsInfo() {
        const url = environment.apiProUrl + ENDPOINTS.ABOUT_US.DETAILS;
        return this.getRequestOptions(true).pipe(
            switchMap((headers) => {
                const options = {
                    headers: headers.headers,
                };
                return this.http.get(url, options);
            })
        );
    }

    public editAboutUsInfo(body: any) {
        const url = environment.apiProUrl + ENDPOINTS.ABOUT_US.DETAILS;
        return this.getRequestOptions(true).pipe(
            switchMap((headers) => {
                const options = {
                    headers: headers.headers,
                };
                return this.http.put(url, body, options);
            })
        );
    }
}
