import { IConfig } from '../interfaces/tw-booking-interface/config.interface';

export class Config {
    id: number;
    onlineBookingUrl: string;
    public deserialize(data: IConfig): Config {
        this.id = data.id;
        this.onlineBookingUrl = data.online_booking_url;
        return this;
    }
}
