export class Province {
    public id: number;
    public nameTh: string;
    public nameEn: string;
    public iconUrl: string;

    public deserialize(obj: any): Province | null {
        if (!obj) {
            return null;
        }

        this.id = obj.id;
        this.nameTh = obj.name_th;
        this.nameEn = obj.name_en;
        this.iconUrl = obj.icon_url;
        return this;
    }
}
