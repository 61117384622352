import { Component, OnDestroy, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';
import { IAddLeadRequest } from '../../../models/add-lead-request.model';
import { Store, select } from '@ngrx/store';
import { State } from 'src/app/stores/reducers';
import * as ReportActions from 'src/app/stores/report-store/report.actions';
import * as ProductActions from 'src/app/stores/product-store/product.actions';
import { LEAD_TYPE_SLUG } from 'src/app/constants/communication-method.constant';
import {
    selectGetProductDetailsFromSearchString,
    selectGetErrorFromSearchString,
} from 'src/app/stores/product-store/product.selectors';
import { filter, takeUntil } from 'rxjs/operators';
import { IProductDataFromSearchString, IPeriod } from '../../../models/product-data-from-search-string.model';
import { Subject } from 'rxjs';
import { selectAgencyInfo, selectUser } from 'src/app/stores/user-store/user.selectors';
import { Agency } from 'src/app/models/agency';
import { User } from 'src/app/models/user';
import { NavigationEnd, Router } from '@angular/router';

@Component({
    selector: 'app-add-booking',
    templateUrl: './add-booking.component.html',
    styleUrls: ['./add-booking.component.scss'],
})
export class AddBookingComponent implements OnInit, OnDestroy {
    private unsubscribe$: Subject<void> = new Subject<void>();
    private leadId: number;
    private travelPeriodId: number;

    lead: IAddLeadRequest;
    agencyInfo: Agency;
    user: User;

    isEditBooking: boolean;

    periodRange: string;
    leadStatus: string;

    addBookingForm: UntypedFormGroup;

    fieldCommMethod: string;
    fieldTravelPeriod: string;
    fieldProductPrice: string;

    validation: any;
    searchStringErrorMessage: string;

    COMM_METHODS: string[] = Object.keys(LEAD_TYPE_SLUG).map((key) => LEAD_TYPE_SLUG[key]);
    tourProductData: IProductDataFromSearchString;

    constructor(public bsModalRef: BsModalRef, private store: Store<State>, private router: Router) {}

    ngOnInit() {
        this.initialiseValidationList();
        this.setFormValidation();
        this.initialiseLead();

        this.store.select(selectAgencyInfo).subscribe((agency) => (this.agencyInfo = agency));
        this.store.select(selectUser).subscribe((user) => (this.user = user));
        this.closeOnRouteChange();
    }

    ngOnDestroy(): void {
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
    }

    initialiseLead(): void {
        if (!this.isEditBooking) {
            this.lead = {
                contact_name: null,
                phone_number: null,
                lead_type_slug: null,
                email: null,
                more_info: null,
                tour_code: null,
                pool_code: null,
                period_id: null,
            };
        } else {
            this.addBookingForm.patchValue({
                name: this.lead.contact_name,
                phone: this.lead.phone_number,
                email: this.lead.email,
                additionalInfo: this.lead.more_info,
                remark: this.lead.remark,
            });
            this.fieldCommMethod = LEAD_TYPE_SLUG[this.lead.lead_type_slug];
        }
    }

    setFormValidation(): void {
        this.addBookingForm = new UntypedFormGroup({
            name: new UntypedFormControl('', [Validators.required, Validators.maxLength(200)]),
            phone: new UntypedFormControl('', [
                Validators.required,
                Validators.minLength(9),
                Validators.maxLength(10),
                Validators.pattern('^[0-9]*$'),
            ]),
            email: new UntypedFormControl('', [Validators.email]),
            additionalInfo: new UntypedFormControl('', [Validators.maxLength(500)]),
            remark: new UntypedFormControl('', [Validators.maxLength(500)]),
            searchString: new UntypedFormControl('', [Validators.required]),
        });
    }

    private initialiseValidationList(): void {
        this.validation = {
            generalError: false,
            name: false,
            phone: false,
            email: false,
            additionalInfo: false,
            remark: false,
            commMethod: false,
            searchString: false,
        };
    }

    private setProductPrice(price: string): void {
        this.fieldProductPrice = parseInt(price).toLocaleString();
    }

    setCommMethod(commMethod: string): void {
        this.fieldCommMethod = commMethod;
    }

    setTravelPeriod(period: IPeriod): void {
        this.travelPeriodId = period.id;
        this.fieldTravelPeriod = period.date;

        if (period.price) {
            this.setProductPrice(period.price);
        }
    }

    checkTourButton(periodId?: number): void {
        this.validation.generalError = false;

        const searchString: string = this.addBookingForm.get('searchString').value;
        if (searchString) {
            this.store.dispatch(
                ProductActions.loadProductWithSearchString({
                    searchString,
                })
            );

            this.store
                .pipe(
                    select(selectGetProductDetailsFromSearchString),
                    filter((m) => !!m),
                    takeUntil(this.unsubscribe$)
                )
                .subscribe((tourProductData: IProductDataFromSearchString) => {
                    this.addBookingForm.patchValue({ searchString });
                    this.validation.searchString = false;
                    this.tourProductData = tourProductData;
                    this.setProductPrice(tourProductData.price);
                    if (periodId) {
                        const period: IPeriod = this.tourProductData.periods.find((period) => period.id === periodId);
                        this.setTravelPeriod(period);
                    }
                });

            this.store
                .pipe(
                    select(selectGetErrorFromSearchString),
                    filter((m) => !!m),
                    takeUntil(this.unsubscribe$)
                )
                .subscribe(() => {
                    this.tourProductData = null;
                    this.searchStringErrorMessage = 'ไม่พบโปรแกรมทัวร์ที่ท่านค้นหา';
                    this.validation.searchString = true;
                    this.addBookingForm.patchValue({ searchString: '' });
                });
        } else {
            this.tourProductData = null;
            this.searchStringErrorMessage = 'กรุณาตรวจสอบโปรแกรมทัวร์ที่ท่านค้นหา';
            this.validation.searchString = true;
        }

        if (!periodId) {
            this.travelPeriodId = undefined;
            this.fieldTravelPeriod = undefined;
        }
    }

    private checkValidationErrorsUponSubmit(): boolean {
        this.initialiseValidationList();

        if (this.addBookingForm.get('name').errors) {
            this.validation.name = true;
        }
        if (this.addBookingForm.get('phone').errors) {
            this.validation.phone = true;
        }
        if (this.addBookingForm.get('email').errors) {
            this.validation.email = true;
        }
        if (this.addBookingForm.get('additionalInfo').errors) {
            this.validation.additionalInfo = true;
        }
        if (this.addBookingForm.get('remark').errors) {
            this.validation.remark = true;
        }
        if ((this.addBookingForm.get('searchString').errors || !this.tourProductData) && !this.isEditBooking) {
            this.validation.searchString = true;
        }
        if (!this.fieldCommMethod) {
            this.validation.commMethod = true;
        }

        if (Object.values(this.validation).includes(true)) {
            this.validation.generalError = true;
            return true;
        } else {
            return false;
        }
    }

    submitButton(): void {
        if (!this.checkValidationErrorsUponSubmit()) {
            this.lead.contact_name = this.addBookingForm.get('name').value;
            this.lead.phone_number = '' + this.addBookingForm.get('phone').value;
            this.lead.email = this.addBookingForm.get('email').value;
            this.lead.more_info = this.addBookingForm.get('additionalInfo').value;
            this.lead.remark = this.addBookingForm.get('remark').value;

            for (const type in LEAD_TYPE_SLUG) {
                if (LEAD_TYPE_SLUG[type] === this.fieldCommMethod) {
                    this.lead.lead_type_slug = type;
                }
            }

            if (!this.isEditBooking) {
                if (this.tourProductData) {
                    this.lead.tour_code = this.tourProductData.tour_code;
                    this.lead.pool_code = this.tourProductData.pool_code;

                    if (this.travelPeriodId) {
                        this.lead.period_id = this.travelPeriodId;
                    }
                }

                this.store.dispatch(
                    ReportActions.addNewLead({
                        requestBody: this.lead,
                    })
                );
            } else {
                this.store.dispatch(
                    ReportActions.editExistingLead({
                        leadId: this.leadId,
                        requestBody: this.lead,
                        refresh: true,
                    })
                );
            }

            this.bsModalRef.hide();
        }
    }

    closeOnRouteChange(): void {
        this.router.events.subscribe((evt) => {
            if (!(evt instanceof NavigationEnd)) {
                return;
            }
            this.bsModalRef.hide();
        });
    }
}
