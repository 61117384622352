export interface IPortfolioSingleResponse {
    id: number;
    title: string;
    descriptions: IPortfolioSingle[];
}

export interface IPortfolioSingle {
    id: number;
    typeSlug: 'text' | 'image';
    ordinal: number;
    description?: string;
    agcyGalleriesId?: number;
    imagePreviewUrl?: string;
    imageDescription?: string;
}

export class PortfolioSingle {
    public deserializePortfolio(obj: any): any {
        const portfolioSingle: IPortfolioSingleResponse = {
            id: obj.id,
            title: obj.title,
            descriptions: [],
        };

        if (obj.portfolio_descriptions) {
            obj.portfolio_descriptions.forEach((desc: any) => {
                const tempItem: IPortfolioSingle = {
                    id: desc.id,
                    typeSlug: desc.type_slug,
                    ordinal: desc.ordinal,
                };
                if (tempItem.typeSlug === 'text') {
                    tempItem.description = desc.description;
                } else {
                    (tempItem.agcyGalleriesId = desc.agcy_galleries_id),
                        (tempItem.imagePreviewUrl = desc.image_preview_url),
                        (tempItem.imageDescription = desc.image_description);
                }
                portfolioSingle.descriptions.push(tempItem);
            });
        }

        return portfolioSingle;
    }
}
