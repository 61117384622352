<div class="modal-header">
    <div class="title">ข้อมูลแจ้งเตือนสิ่งที่ต้องทำ</div>
    <button type="button" class="close pull-right" aria-label="Close" (click)="cancel()">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body">
    <tabset #staticTabs>
        <tab heading="Sale" [customClass]="'customClass'">
            <div class="row">
                <div class="col">
                    <div class="title">งานที่ต้องทำ</div>
                    <ol>
                        <li *ngFor="let item of tasks | worklistPipe: 'sale':false">
                            <div class="d-flex justify-content-between">
                                <img
                                    *ngIf="item.status === 'done'"
                                    src="/assets/img/icon/icon-check-mark.svg"
                                    alt=""
                                    class="check-status"
                                />
                                <span>{{ item.taskType.name }}</span>
                                <app-calendar-input
                                    [placement]="'right'"
                                    style="max-width: 120px"
                                    [bsValue]="item.dateTime"
                                    (bsValueChange)="item.dateTime = $event"
                                    (bsShown)="item.onSelected = true"
                                    (bsHidden)="item.onSelected = false"
                                    [disabled]="!(userRole === 'sale' || userRole === 'admin')"
                                    placeholder="เลือกวันที่"
                                >
                                </app-calendar-input>
                                <i class="arrow left" [ngClass]="{ active: item.onSelected }"></i>
                            </div>
                        </li>
                    </ol>
                </div>
                <div class="col border-left">
                    <div class="title">ส่วนเสริม</div>
                    <ol>
                        <li *ngFor="let item of tasks | worklistPipe: 'sale':true">
                            <div class="d-flex justify-content-between">
                                <img
                                    *ngIf="item.status === 'done'"
                                    src="/assets/img/icon/icon-check-mark.svg"
                                    alt=""
                                    class="check-status"
                                />
                                <span>{{ item.taskType.name }}</span>
                                <ng-container *ngIf="item.taskType.id === 18 && airports$ | async as airports">
                                    <select
                                        class="form-control form-select"
                                        aria-label="เลือกสนามบิน"
                                        style="height: 34px; width: 150px; font-size: 12px"
                                        [disabled]="!(userRole === 'sale' || userRole === 'admin')"
                                        [ngModel]="item.airport ? item.airport.id : null"
                                        (change)="selectAirport(item, $event.target.value)"
                                    >
                                        <option [value]="null">เลือกสนามบิน</option>
                                        <option [value]="item.id" *ngFor="let item of airports">
                                            {{ item.nameTh }}
                                        </option>
                                    </select>
                                </ng-container>
                                <app-calendar-input
                                    [placement]="'right'"
                                    style="max-width: 120px"
                                    [bsValue]="item.dateTime"
                                    (bsValueChange)="item.dateTime = $event"
                                    (bsShown)="item.onSelected = true"
                                    (bsHidden)="item.onSelected = false"
                                    [disabled]="!(userRole === 'sale' || userRole === 'admin')"
                                    placeholder="เลือกวันที่"
                                ></app-calendar-input>
                                <i class="arrow left" [ngClass]="{ active: item.onSelected }"></i>
                            </div>
                            <div
                                style="display: flex; align-items: center; justify-content: end; margin-top: 5px"
                                *ngIf="item.taskType.id === 18"
                            >
                                <input
                                    class="form-control"
                                    type="text"
                                    [(ngModel)]="item.meetingPoint"
                                    placeholder="จุดนัดหมาย"
                                    style="height: 34px; width: 150px; font-size: 12px; margin-right: 21px"
                                    [disabled]="!(userRole === 'sale' || userRole === 'admin')"
                                />
                                <timepicker
                                    [(ngModel)]="item.dateTime"
                                    [hoursPlaceholder]="'เวลา'"
                                    [minutesPlaceholder]="'นาที'"
                                    [showMeridian]="false"
                                    [showSpinners]="false"
                                    [disabled]="!(userRole === 'sale' || userRole === 'admin')"
                                ></timepicker>
                            </div>
                        </li>
                    </ol>
                </div>
            </div>
        </tab>
        <tab heading="Finance" [customClass]="'customClass'">
            <div class="row">
                <div class="col">
                    <div class="title">งานที่ต้องทำ</div>
                    <ol>
                        <li *ngFor="let item of tasks | worklistPipe: 'finance':false">
                            <div class="d-flex justify-content-between">
                                <img
                                    *ngIf="item.status === 'done'"
                                    src="/assets/img/icon/icon-check-mark.svg"
                                    alt=""
                                    class="check-status"
                                />
                                <span>{{ item.taskType.name }}</span>
                                <app-calendar-input
                                    [placement]="'right'"
                                    style="max-width: 120px"
                                    [bsValue]="item.dateTime"
                                    (bsValueChange)="item.dateTime = $event"
                                    (bsShown)="item.onSelected = true"
                                    (bsHidden)="item.onSelected = false"
                                    [disabled]="!(userRole === 'finance' || userRole === 'admin')"
                                    placeholder="เลือกวันที่"
                                ></app-calendar-input>
                                <i class="arrow left" [ngClass]="{ active: item.onSelected }"></i>
                            </div>
                        </li>
                    </ol>
                </div>
                <div class="col border-left">
                    <div class="title">ส่วนเสริม</div>
                    <ol>
                        <li *ngFor="let item of tasks | worklistPipe: 'finance':true">
                            <div class="d-flex justify-content-between">
                                <img
                                    *ngIf="item.status === 'done'"
                                    src="/assets/img/icon/icon-check-mark.svg"
                                    alt=""
                                    class="check-status"
                                />
                                <span>{{ item.taskType.name }}</span>
                                <app-calendar-input
                                    [placement]="'right'"
                                    style="max-width: 120px"
                                    [bsValue]="item.dateTime"
                                    (bsValueChange)="item.dateTime = $event"
                                    (bsShown)="item.onSelected = true"
                                    (bsHidden)="item.onSelected = false"
                                    [disabled]="!(userRole === 'finance' || userRole === 'admin')"
                                    placeholder="เลือกวันที่"
                                ></app-calendar-input>
                                <i class="arrow left" [ngClass]="{ active: item.onSelected }"></i>
                            </div>
                        </li>
                    </ol>
                </div>
            </div>
        </tab>
    </tabset>
</div>
<div class="modal-footer">
    <button (click)="save()" type="button" class="btn btn-primary">บันทึก</button>
    <button (click)="cancel()" type="button" class="btn btn-default">ยกเลิก</button>
</div>
