import { IAddLeadBookingRequest } from './../../modules/report/models/add-lead-request.model';
import { createAction, props } from '@ngrx/store';
import { ISearchFilter } from 'src/app/modules/report/models/search-filter.model';
import { IAddLeadRequest } from 'src/app/modules/report/models/add-lead-request.model';
import { ILeadCountByType } from 'src/app/modules/report/models/lead-count-by-type.model';
import { ILeadReportDownloadSearch } from 'src/app/modules/report/models/lead-report-download-search.model';
import { LeadStatMonthly } from 'src/app/models/lead-stat-monthly.model';
import { ILeadMonthlyReport } from 'src/app/models/lead-monthly-report.model';
import { IOrderBalancePeriod } from 'src/app/models/order-balance-period.model';
import { IOrderBalanceReport, IOrderBalanceReportOverView } from 'src/app/models/order-balance-report.model';
import { IOrderBalanceReportCriteria } from 'src/app/modules/report/models/order-balance-report-criteria.model';
import { OrderBalance } from 'src/app/modules/report/models/order-balance-report.model';

export const loadLeadListWithoutFilter = createAction('[Report] Search filter submitted', props<{ criteria: any }>());

export const getLeadList = createAction(
    '[Report] Fetching lead list',
    props<{ searchFilter?: ISearchFilter; criteria: any; nextPage?: boolean }>()
);

export const loadLeadListSuccess = createAction(
    '[Report] Lead list loaded successfully',
    props<{ leadList: any; pageDetails: any; nextPage?: boolean }>()
);

export const loadLeadListError = createAction('[Report] Lead list loaded unsuccessfully', props<{ error: string }>());

export const addNewLead = createAction(
    '[Report] User tries to add a new lead',
    props<{ requestBody: IAddLeadRequest }>()
);

export const addLeadSuccess = createAction('[Report] Lead list added successfully', props<{ leadId: number }>());

export const addLeadError = createAction('[Report] Lead list added unsuccessfully', props<{ error: string }>());

export const editExistingLead = createAction(
    '[Report] User tries to edit a existing lead',
    props<{ leadId: number; requestBody: IAddLeadRequest; refresh?: boolean }>()
);

export const editLeadSuccess = createAction('[Report] Lead list edited successfully', props<{ leadId: number }>());

export const editLeadError = createAction('[Report] Lead list edited unsuccessfully', props<{ error: string }>());

export const getLeadCount = createAction('[Report] Lead count data is requested', props<{ statusSlug: string }>());

export const getLeadCountSuccess = createAction(
    '[Report] Lead count data is retrieved successfully',
    props<{ leadCount: number }>()
);

export const getLeadCountError = createAction(
    '[Report] Lead count data is retrieved unsuccessfully',
    props<{ error: any }>()
);

export const getLeadCountByType = createAction(
    '[Report] Lead count data by type is requested',
    props<{ searchFilter?: ISearchFilter }>()
);

export const getLeadCountByTypeSuccess = createAction(
    '[Report] Lead count data by type is retrieved successfully',
    props<{ leadCountByType: ILeadCountByType }>()
);

export const getLeadCountByTypeError = createAction(
    '[Report] Lead count data by type is retrieved unsuccessfully',
    props<{ error: any }>()
);

export const updateLeadStatus = createAction(
    '[Report] Lead status update requested',
    props<{ id: number; statusSlug: string }>()
);

export const updateLeadStatusSuccess = createAction(
    '[Report] Lead status updated successfully',
    props<{ leadId: number; statusSlug: string }>()
);

export const updateLeadStatusFailure = createAction(
    '[Report] Lead status updated unsuccessfully',
    props<{ error: any }>()
);

export const getBookingRequestLeadList = createAction(
    '[Report] Fetching booking request lead list',
    props<{ searchFilter?: ISearchFilter; criteria: any; nextPage?: boolean }>()
);

export const loadBookingRequestLeadListSuccess = createAction(
    '[Report] Booking request lead list loaded successfully',
    props<{ bookingRequestLeadList: any[]; pageDetails: any; nextPage?: boolean }>()
);

export const loadBookingRequestLeadListError = createAction(
    '[Report] Booking request lead list loaded unsuccessfully',
    props<{ error: string }>()
);

export const getLeadBookingRequestCount = createAction(
    '[Report] Lead booking request count data is requested',
    props<{ statusSlug: string }>()
);

export const getLeadBookingRequestCountSuccess = createAction(
    '[Report] Lead booking request count data is retrieved successfully',
    props<{ leadBookingRequestCount: number }>()
);

export const getLeadBookingRequestCountError = createAction(
    '[Report] Lead booking request count data is retrieved unsuccessfully',
    props<{ error: any }>()
);

export const updateBookingRequestLeadStatus = createAction(
    '[Report] Booking request lead status update requested',
    props<{ id: number; statusSlug: string }>()
);

export const updateBookingRequestLeadStatusSuccess = createAction(
    '[Report] Booking request lead status updated successfully',
    props<{ leadId: number; statusSlug: string }>()
);

export const updateBookingRequestLeadStatusFailure = createAction(
    '[Report] Booking request lead status updated unsuccessfully',
    props<{ error: any }>()
);

export const addBookingRequestLead = createAction(
    '[Report] User tries to add a new booking request lead',
    props<{ requestBody: IAddLeadBookingRequest }>()
);

export const addBookingRequestLeadSuccess = createAction(
    '[Report] Booking request lead list added successfully',
    props<{ leadBookingRequestId: number }>()
);

export const addBookingRequestLeadError = createAction(
    '[Report] Booking request lead list added unsuccessfully',
    props<{ error: string }>()
);

export const getLeadSummaryList = createAction(
    '[Report] User tries to get the lead summary list',
    props<{ searchFilter?: ISearchFilter; criteria: any; nextPage?: boolean }>()
);

export const getLeadSummaryListSuccess = createAction(
    '[Report] Lead summary list fetched successfully',
    props<{ leadSummaryList: any[]; pageDetails: any; nextPage?: boolean }>()
);

export const getLeadSummaryListError = createAction(
    '[Report] Lead summary list fetched unsuccessfully',
    props<{ error: string }>()
);

export const getProductCountForSummary = createAction(
    '[Report] User tries to get the active product count',
    props<{ searchFilter?: ISearchFilter }>()
);

export const getProductCountForSummarySuccess = createAction(
    '[Report] Active product count fetched successfully',
    props<{ countData: any }>()
);

export const getProductCountForSummaryError = createAction(
    '[Report] Active product count fetched unsuccessfully',
    props<{ error: string }>()
);

export const getBookingRequestLeadDetails = createAction(
    '[Report] Request booking request lead details',
    props<{ leadId: number }>()
);

export const getBookingRequestLeadDetailsSuccess = createAction(
    '[Report] Booking request lead details fetched successfully',
    props<{ responseBody: string }>()
);

export const getBookingRequestLeadDetailsError = createAction(
    '[Report] Booking request lead details fetched unsuccessfully',
    props<{ error: string }>()
);
export const getStatisticsForProductSummary = createAction(
    '[Report] User tries to get the statistics for the product summary',
    props<{ idList: number[] }>()
);

export const getStatisticsForProductSummarySuccess = createAction(
    '[Report] Statistics for the product summary fetched successfully',
    props<{ statisticsData: any[] }>()
);

export const getStatisticsForProductSummaryError = createAction(
    '[Report] Statistics for the product summary fetched unsuccessfully',
    props<{ error: string }>()
);

export const getLeadStatMonthly = createAction(
    '[Report] User tries to get lead stat monthly',
    props<{ criteria?: ILeadReportDownloadSearch }>()
);

export const getLeadStatMonthlySuccess = createAction(
    '[Report] Lead stat monthly fetched successfully',
    props<{ leadStatMonthly: LeadStatMonthly }>()
);

export const getLeadStatMonthlyError = createAction(
    '[Report] Lead stat monthly fetched unsuccessfully',
    props<{ error: any }>()
);

export const getLeadStatMonthlyDetails = createAction(
    '[Report] User tries to get lead stat monthly details',
    props<{ criteria?: ILeadReportDownloadSearch }>()
);

export const getLeadStatMonthlyDetailsSuccess = createAction(
    '[Report] Lead stat monthly details fetched successfully',
    props<{ leadStatMonthlyDetails: ILeadMonthlyReport[] }>()
);

export const getLeadStatMonthlyDetailsError = createAction(
    '[Report] Lead stat monthly details fetched unsuccessfully',
    props<{ error: any }>()
);

export const getOrderBalancePeriod = createAction('[Report] User tries to get order balance period');

export const getOrderBalancePeriodSuccess = createAction(
    '[Report] Order balance period fetched successfully',
    props<{ data: IOrderBalancePeriod }>()
);

export const getOrderBalancePeriodError = createAction(
    '[Report] Order balance period fetched unsuccessfully',
    props<{ error: any }>()
);

export const getOrderBalanceReport = createAction(
    '[Report] User tries to get order balance report',
    props<{ criteria?: IOrderBalanceReportCriteria; nextPage?: boolean }>()
);

export const getOrderBalanceReportSuccess = createAction(
    '[Report] Order balance report fetched successfully',
    props<{ data: IOrderBalanceReportOverView; nextPage?: boolean }>()
);

export const getOrderBalanceReportError = createAction(
    '[Report] Order balance report fetched unsuccessfully',
    props<{ error: any }>()
);

export const getOrderBalanceReportItems = createAction(
    '[Report] User tries to get order balance report items',
    props<{ criteria?: IOrderBalanceReportCriteria; nextPage?: boolean }>()
);

export const getOrderBalanceReportItemsSuccess = createAction(
    '[Report] Order balance report items fetched successfully',
    props<{ data: IOrderBalanceReport; nextPage?: boolean }>()
);

export const getOrderBalanceReportItemsError = createAction(
    '[Report] Order balance report items fetched unsuccessfully',
    props<{ error: any }>()
);

export const getOrderBalance = createAction('[Report] User tries to get order balance');

export const getOrderBalanceSuccess = createAction(
    '[Report] Get order balance successfully',
    props<{ orderBalance: OrderBalance }>()
);

export const getOrderBalanceError = createAction('[Report] Get order balance unsuccessfully', props<{ error: any }>());

export const confirmOrderBalance = createAction('[Report] User confirm order balance', props<{ orderId: number }>());

export const confirmOrderBalanceSuccess = createAction(
    '[Report] Confirm order balance successfully',
    props<{ result: boolean }>()
);

export const confirmOrderBalanceError = createAction(
    '[Report] Confirm order balance unsuccessfully',
    props<{ error: any }>()
);

export const getBalanceReceiptUri = createAction(
    '[Report] Get balance receipt uri.',
    props<{ balanceId: number; receiptId: number }>()
);

export const getBalanceReceiptUriSuccessful = createAction(
    '[Report] Get balance receipt uri successfully.',
    props<{ balanceId: number; receiptId: number; receiptUri: string }>()
);

export const getBalanceReceiptUriError = createAction(
    '[Report] Get balance receipt uri successfully.',
    props<{ error: any }>()
);
