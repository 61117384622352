export interface IPartner {
    id: number;
    nameTh: string;
    nameEn: string;
    channel: 'ag' | 'ws';
}

export class PartnersList {
    public deserialize(obj: any): IPartner[] {
        const partnersList: IPartner[] = [];
        obj.map((partner: any) => {
            partnersList.push({
                id: partner.id,
                nameTh: partner.name_th,
                nameEn: partner.name_en,
                channel: partner.channel,
            });
        });

        return partnersList;
    }
}
