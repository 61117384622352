<div class="lead-details row" [class.disabled]="fieldStatus===getStatus('cancel')">
    <div class="col-2 agency-column">
        <span class="index">{{index}}.</span>
        <p>Ref. {{lead.bookingNumberCode}}</p>
        <p>{{lead.leadCreatedByAgency.nameEn}}</p>
    </div>
    <div class="col-2">
        <p>{{fieldDate}}</p>
        <p>{{fieldTime}}</p>
    </div>
    <div class="col-3">
        <p>{{lead.agencyProductCode}}-{{lead.productDetail.tourCode}} {{lead.productDetail.name}}</p>
        <p>{{fieldProgramPeriod}} ราคาเริ่มต้น 
            <span *ngIf="lead.periodPrice || lead.productDetail.price"> {{this.lead.periodPrice || lead.productDetail.price | number: '1.0-0'}}</span>
            <span *ngIf="lead.commissionString"> {{lead.commissionLabel}} {{lead.commissionString}}</span>
        </p>
    </div>
    <div class="col-1">
        <p>{{lead.numberOfPassengers}}</p>
    </div>
    <div class="col-3">
        <div class="btn-group" dropdown>
            <button id="button-basic" dropdownToggle type="button" class="btn btn-primary dropdown-toggle"
                    aria-controls="dropdown-basic">{{fieldStatus}}<span class="caret"></span>
            </button>
            <ul id="dropdown-basic" *dropdownMenu class="dropdown-menu"
                role="menu" aria-labelledby="button-basic">
                <ng-container *ngFor="let status of statusValues">
                    <li role="menuitem"><span class="dropdown-item" (click)="onStatusChange(status)"
                        [class.active]="fieldStatus === status"
                        *ngIf="!(status === getStatus('pending') && fieldStatus === getStatus('confirm'))">
                        {{status}}
                    </span></li>
                </ng-container>
            </ul>
        </div>
    </div>
    <div class="col-1">
        <button *ngIf="fieldStatus!==getStatus('cancel')" type="button" (click)="viewInfo()"
        class="btn btn-primary see-more-info-button">ดูข้อมูล</button>
    </div>
    <div *ngIf="fieldStatus===getStatus('cancel')" class="backdrop"></div>
</div>
