<div class="search-panel text-lg">
    <div class="upper-panel">
        <div class="d-flex mb-2">
            <span class="search-box pr-4">
                <i class="icon icon-search"></i>
                <input
                    type="text"
                    class="form-control"
                    placeholder="ค้นหาจาก ชื่อลูกค้า, เบอร์โทร, รหัส Order"
                    [(ngModel)]="searchCriteria.searchString"
                    (keyup)="typeSearchString()"
                />
            </span>
            <button class="btn btn-primary ml-auto" (click)="applySearch()">ค้นหา</button>
        </div>
        <ng-container *ngIf="countProductSubCategory$ | async as countProductSubCategory">
            <div class="d-flex search-category">
                <span
                    class="text-blue-tourwow mr-4 cursor-pointer"
                    (click)="selectProductSubCategory('all')"
                    [ngClass]="{ active: searchCriteria.productSubCategorySlug === 'all' }"
                    >ทุกประเภท ({{ countProductSubCategory.all }})</span
                >
                <span
                    class="text-blue-tourwow mr-4 cursor-pointer"
                    (click)="selectProductSubCategory('outbound')"
                    [ngClass]="{ active: searchCriteria.productSubCategorySlug === 'outbound' }"
                    >Outbound ({{ countProductSubCategory.outbound }})</span
                >
                <span
                    class="text-blue-tourwow cursor-pointer"
                    (click)="selectProductSubCategory('domestic')"
                    [ngClass]="{ active: searchCriteria.productSubCategorySlug === 'domestic' }"
                    >Domestic ({{ countProductSubCategory.domestic }})</span
                >
            </div>
        </ng-container>
    </div>
    <div class="line-separetor"></div>
    <div class="lower-panel">
        <div class="mb-2">
            <strong>ทั้งหมด {{ totalProductOrders }} Booking</strong>
        </div>
        <ng-container *ngIf="countOrderStatus$ | async as countOrderStatus">
            <div class="d-flex search-status">
                <span
                    class="mr-4 cursor-pointer"
                    (click)="selectOrderStatus('waiting_confirm')"
                    [ngClass]="{ active: searchCriteria.orderStatusSlug === 'waiting_confirm' }"
                >
                    <i class="icon icon-waiting-confirmation mr-2"></i>
                    <span class="text-blue-tourwow">รอยืนยัน ({{ countOrderStatus.waitingConfirm }})</span>
                </span>
                <span
                    class="mr-4 cursor-pointer"
                    (click)="selectOrderStatus('waiting_full_paid')"
                    [ngClass]="{ active: searchCriteria.orderStatusSlug === 'waiting_full_paid' }"
                >
                    <i class="icon icon-booking-payment mr-2"></i>
                    <span class="text-blue-tourwow">รอชำระเงินครบ ({{ countOrderStatus.waitingFullPaid }})</span>
                </span>
                <span
                    class="mr-4 cursor-pointer"
                    (click)="selectOrderStatus('complete_not_started')"
                    [ngClass]="{ active: searchCriteria.orderStatusSlug === 'complete_not_started' }"
                >
                    <i class="icon icon-booking-bag-travel mr-2"></i>
                    <span class="text-blue-tourwow">รอเดินทาง ({{ countOrderStatus.completeNotStarted }})</span>
                </span>
                <span
                    class="mr-4 cursor-pointer"
                    (click)="selectOrderStatus('complete_started')"
                    [ngClass]="{ active: searchCriteria.orderStatusSlug === 'complete_started' }"
                >
                    <i class="icon icon-booking-travel mr-2"></i>
                    <span class="text-blue-tourwow">เดินทางแล้ว ({{ countOrderStatus.completeStarted }})</span>
                </span>
                <span
                    class="cursor-pointer"
                    (click)="selectOrderStatus('cancel')"
                    [ngClass]="{ active: searchCriteria.orderStatusSlug === 'cancel' }"
                >
                    <i class="icon icon-booking-cancel mr-2"></i>
                    <span class="text-blue-tourwow">ยกเลิก ({{ countOrderStatus.cancel }})</span>
                </span>
            </div>
        </ng-container>
    </div>
</div>

<div class="text-right mt-4 mb-4">
    <span class="text-dark-light cursor-pointer"
        ><u>กดค้นหาข้อมูลเพิ่มเติม</u> <i class="icon icon-arrow-down"></i
    ></span>
</div>
