import { IDeserializable } from './interfaces/deserializable';

export class OrderBalancePeriod implements IOrderBalancePeriod, IDeserializable {
    data: IOrderBalancePeriodItem[];

    deserialize(source: any): this {
        this.data = source.map((d) => new OrderBalancePeriodItem().deserialize(d));

        return this;
    }
}

export interface IOrderBalancePeriod {
    data: IOrderBalancePeriodItem[];
}

export class OrderBalancePeriodItem implements IOrderBalancePeriodItem, IDeserializable {
    id: number;
    transferYear?: number;
    orderFirstDate: string;
    orderLastDate: string;
    transferDueDate: string;
    orderBalance: IOrderBalancePeriodInfo;

    castFromPeriodViews(source: IOrderBalancePeriodItem[]): Map<number, IOrderBalancePeriodItem[]> {
        const views = new Map<number, IOrderBalancePeriodItem[]>();
        source.forEach((period) => {
            if (!views.has(period.transferYear)) {
                views.set(period.transferYear, []);
            }
            views.get(period.transferYear).push(period);
        });
        return views;
    }

    deserialize(source: any): this {
        this.id = source.id;
        this.orderFirstDate = source.order_first_date;
        this.orderLastDate = source.order_last_date;
        this.transferDueDate = source.transfer_due_date;
        this.orderBalance = new OrderBalancePeriodInfo().deserialize(source.order_balance);

        this.transferYear = this.transferDueDate ? +this.transferDueDate.split('-').shift() : null;

        return this;
    }
}

export interface IOrderBalancePeriodItem {
    id: number;
    transferYear?: number;
    orderFirstDate: string;
    orderLastDate: string;
    transferDueDate: string;
    orderBalance: IOrderBalancePeriodInfo;

    castFromPeriodViews(source: IOrderBalancePeriodItem[]): Map<number, IOrderBalancePeriodItem[]>;
}

export class OrderBalancePeriodInfo implements IOrderBalancePeriodInfo, IDeserializable {
    id: number;
    receiptItemQuantity: number;
    sumAmount: string;
    sumNetCommissionAgency: string;
    confirmed: boolean;
    transfered: boolean;

    deserialize(source: any): this {
        this.id = source.id;
        this.receiptItemQuantity = source.receipt_item_quantity;
        this.sumAmount = source.sum_amount;
        this.sumNetCommissionAgency = source.sum_net_commission_agency;
        this.confirmed = source.confirmed;
        this.transfered = source.transfered;

        return this;
    }
}

export interface IOrderBalancePeriodInfo {
    id: number;
    receiptItemQuantity: number;
    sumAmount: string;
    sumNetCommissionAgency: string;
    confirmed: boolean;
    transfered: boolean;
}
