import { Component, OnInit } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';

@Component({
    selector: 'app-close-advanced-search-warning',
    templateUrl: './close-advanced-search-warning.component.html',
    styleUrls: ['./close-advanced-search-warning.component.scss'],
})
export class CloseAdvancedSearchWarningComponent implements OnInit {
    constructor(public bsModalRef: BsModalRef, public bsModalService: BsModalService) {}

    ngOnInit(): void {
        this.bsModalService.setDismissReason('dont-close-advanced');
    }

    public confirm() {
        this.bsModalService.setDismissReason('confirm-close-advanced');
        this.bsModalRef.hide();
    }
}
