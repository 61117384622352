<ng-container *ngIf="groupPlanList">
  <div class="group-plan-container">
    <div class="group-plan-header">
      <div class="title">
        Plan ออกกรุ๊ปของ
        <ng-container *ngIf="!groupPlanListQueries.filters.clearCache">
            <ng-container *ngIf="orderSummary?.filterType==='month'">
            {{orderSummary?.dateRange?.minDate | thaiMonth}}
            </ng-container>
            <ng-container *ngIf="orderSummary?.filterType==='week'">
            {{orderSummary.dateRange.minDate | thaidaterange:orderSummary.dateRange.maxDate}}
            </ng-container>
        </ng-container>
        <ng-container *ngIf="groupPlanListQueries.filters.clearCache">
            {{getDynamicTotalString()}}
        </ng-container>
      </div>
      <div class="status-summary">
        <div class="status-item">
          <span class="icon-booking-travel"></span>
          <div class="status">เดินทางแล้ว</div>
          <div class="qutatity">{{groupPlanList.groupPlanStats.orderCountAlreadyStarted}} <span>booking</span></div>
        </div>
        <div class="status-item">
          <span class="icon-booking-bag-travel"></span>
          <div class="status">รอเดินทาง</div>
          <div class="qutatity">{{groupPlanList.groupPlanStats.orderCountNotStarted}} <span>booking</span></div>
        </div>
        <div class="status-item">
          <span class="icon-booking-bag-travel-person"></span>
          <div class="status">จำนวนผู้เดินทางทั้งหมด</div>
          <div class="qutatity">{{groupPlanList.groupPlanStats.allOrderSumQuantity}} <span>คน</span></div>
        </div>
      </div>
    </div>
    <div class="group-plan-table">
      <div class="header">
        <div class="column column-01">วันออกเดินทาง</div>
        <div class="column column-02">โปรแกรมทัวร์</div>
        <div class="column column-03">ยอดจอง</div>
        <div class="column column-04">จำนวน Booking</div>
        <div class="column column-05"></div>
      </div>
      <div class="body">
        <div class="body-row " *ngFor="let groupPlan of groupPlanList.groupPlans; let i = index">
          <div class="column column-01" [ngClass]="{'disable': disabledRow(groupPlan.startAt)}">{{i+1}}.
            {{groupPlan.startAt.getDay() | thaidayOfWeek}}
            {{groupPlan.startAt | thaiDate}}</div>
          <div class="column column-02" [ngClass]="{'disable': disabledRow(groupPlan.startAt)}">
            <div class="program">
              <ng-container *ngIf="groupPlan.tourCode">
                {{agencyProductCode}}-{{groupPlan.tourCode}}
              </ng-container>
              {{groupPlan.productName}}
            </div>
            <div class="contact">
              <a (click)="openBusinessContactInfoModal(groupPlan.productOwner)" class="name-field-link cursor-pointer">
                {{groupPlan.productOwner.nameEN}}({{groupPlan.productOwner.nameTH}})
                &nbsp;โทร.{{groupPlan.productOwner.phoneNumber | phoneNumber}}
                <i class="icon icon-info"></i>
              </a>
            </div>
          </div>
          <div class="column column-03" [ngClass]="{'disable': disabledRow(groupPlan.startAt)}">
            {{groupPlan.orderSumTotalPrice | number}}</div>
          <div class="column column-04" [ngClass]="{'disable': disabledRow(groupPlan.startAt)}">
            {{groupPlan.orderCount| number}} Booking
            <br>{{groupPlan.orderSumQuantity| number}} ท่าน
          </div>
          <div (click)="viewMoreDetail(groupPlan)" class="column column-05" [ngClass]="{'disable': disabledRow(groupPlan.startAt)}">
            <button class="btn-see-more">ดูข้อมูล</button>
          </div>
        </div>
      </div>
      <button class="btn-paging" *ngIf="groupPlanList.total>groupPlanList.groupPlans.length"
        (click)="getMoreGroupPlan(groupPlanList.groupPlans.length)">แสดงรายการที่
        {{groupPlanList.groupPlans.length+1}} -
        {{groupPlanList.groupPlans.length+50}}</button>
    </div>

  </div>
</ng-container>
