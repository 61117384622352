import { Component, OnInit } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { IBreadcrumb } from 'src/app/models/breadcrumb.model';
import { State } from 'src/app/stores/reducers';
import * as OrderActions from 'src/app/stores/order-store/order.actions';
import {
    ICampaignPaymentItem,
    ICampaignPaymentPaginated,
    ICampaignPaymentPaginatedFilter,
} from '../../models/campaign-payment.model';
import { selectCampaignPaymentPaginated } from 'src/app/stores/order-store/order.selectors';
import { filter } from 'rxjs/operators';

@Component({
    selector: 'app-campaign-order-list-page',
    templateUrl: './campaign-order-list-page.component.html',
    styleUrls: ['./campaign-order-list-page.component.scss'],
})
export class CampaignOrderListPageComponent implements OnInit {
    public breadcrumb: IBreadcrumb[];

    public filter: ICampaignPaymentPaginatedFilter;
    public paginated: ICampaignPaymentPaginated;

    private backedUpResults: ICampaignPaymentItem[];

    constructor(private store: Store<State>) {}

    ngOnInit(): void {
        this.setBreadcrumb();
        this.subscibeCampaignPaymentPaginated();
    }

    public doSearchFilter(filter: ICampaignPaymentPaginatedFilter): void {
        this.filter = filter;

        // restore backedup results to prepare for next perform
        if (this.backedUpResults) {
            this.paginated.result = JSON.parse(JSON.stringify(this.backedUpResults));
        }

        if (this.filter.isLocalSearch) {
            // backup results before search via local
            this.backedUpResults = JSON.parse(JSON.stringify(this.paginated.result));
            const searchTerm = this.filter.clientNamePhoneOrVoucherCode.toLowerCase();
            if (searchTerm.length >= 3) {
                this.paginated.result = this.paginated.result.filter(
                    (p) =>
                        p.campaign?.campaignCode?.toLowerCase()?.indexOf(searchTerm) !== -1 ||
                        p.order?.orderCode?.toLowerCase()?.indexOf(searchTerm) !== -1 ||
                        p.contactName?.toLowerCase()?.indexOf(searchTerm) !== -1 ||
                        p.phoneNumber?.toLowerCase()?.indexOf(searchTerm) !== -1
                );
            }
        } else {
            // search via api
            this.dispatchPaginated();
        }
    }

    public showMorePaginated(): void {
        this.filter.page++;
        this.dispatchPaginated();
    }

    private subscibeCampaignPaymentPaginated() {
        this.store
            .pipe(
                select(selectCampaignPaymentPaginated),
                filter((p) => !!p)
            )
            .subscribe((p) => {
                this.paginated = p;
            });
    }

    public dispatchPaginated(): void {
        this.store.dispatch(
            OrderActions.getCampaignPaymentPaginated({
                filter: this.filter,
            })
        );
    }

    private setBreadcrumb(): void {
        this.breadcrumb = [
            { name: 'หน้าแรก', link: '/dashboard' },
            { name: 'ข้อมูลการซื้อเวาเชอร์', link: '/order/campaign-list' },
        ];
    }
}
