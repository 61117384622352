import { AddBookingB2cComponent } from '../../../../order/modals/add-booking-b2c/add-booking-b2c.component';
import { selectPermissionAllowPartnersToSellProduct } from '../../../../../stores/user-store/user.selectors';
import { PassBookingToOwnerComponent } from '../../../modals/b2b-booking/pass-booking-to-owner/pass-booking-to-owner.component';
import { CancelLeadComponent } from '../../../modals/lead-list/cancel-lead/cancel-lead.component';
import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { State } from 'src/app/stores/reducers';
import { select, Store } from '@ngrx/store';
import * as ReportActions from 'src/app/stores/report-store/report.actions';
import { ILeadDetails, StatusTypes } from '../../../models/lead-details.model';
import { ThaiMonthDatePipe } from 'src/app/pipes/thaidate.pipe';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { LeadContactInfoComponent } from '../../../modals/b2b-booking/lead-contact-info/lead-contact-info.component';
import { LEAD_TYPE_SLUG } from 'src/app/constants/communication-method.constant';
import { LEAD_STATUS_SLUG } from 'src/app/constants/lead-status.constant';
import { AddBookingComponent } from '../../../modals/lead-list/add-booking/add-booking.component';
import { combineLatest, Observable, Subject } from 'rxjs';
import { filter, take, takeUntil } from 'rxjs/operators';
import { Agency } from 'src/app/models/agency';
import { selectAgencyInfo } from 'src/app/stores/user-store/user.selectors';
import { ProductOwnerModalComponent } from 'src/app/shared/modals/product-owner-modal/product-owner-modal.component';
import { selectError, selectProductView } from 'src/app/stores/product-store/product.selectors';
import { loadProductView, resetProductView } from 'src/app/stores/product-store/product.actions';
import { environment } from 'src/environments/environment';

@Component({
    selector: 'app-lead-details',
    templateUrl: './lead-details.component.html',
    styleUrls: ['./lead-details.component.scss'],
})
export class LeadDetailsComponent implements OnInit, OnDestroy {
    @Input() index: number;
    @Input() lead: ILeadDetails;
    @Input() ordinal: number;
    isNameClickable: boolean = false;
    fieldCustomerName: string = '';
    fieldFrom: string = '';
    fieldSalesPerson: string = '-';
    fieldDate: string = '';
    fieldTime: string = '';
    fieldProgramPeriod: string = '';
    fieldStatus: StatusTypes;
    fieldPrice: string = '';

    agencyInfo$: Observable<Agency>;

    statusValues: string[];
    bsModalRef: BsModalRef;

    private unsubscribe$: Subject<void> = new Subject<void>();

    constructor(
        private thaiMonthDatePipe: ThaiMonthDatePipe,
        private modalService: BsModalService,
        private store: Store<State>
    ) {}

    ngOnInit() {
        this.agencyInfo$ = this.store.select(selectAgencyInfo);
        this.lead = Object.assign({}, this.lead);
        this.setBookingColumn();
        this.setDateTimeColumn();
        this.setProgramPeriod();
        this.setInitialStatus();
        this.setPriceField();
        this.initStatusDropdown();
        // this.checkSellToPartnersPermission();
    }

    ngOnDestroy(): void {
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
    }

    private initStatusDropdown(): void {
        if (this.lead.lead_status_slug === 'booked') {
            this.statusValues = [LEAD_STATUS_SLUG['booked'], LEAD_STATUS_SLUG['cancel']];
        } else {
            this.statusValues = Object.keys(LEAD_STATUS_SLUG).map((key) => LEAD_STATUS_SLUG[key]);
        }
    }

    private setBookingColumn(): void {
        this.fieldFrom = LEAD_TYPE_SLUG[this.lead.lead_type_slug];

        if (this.lead.customerDetails.contact_name) {
            this.fieldCustomerName = this.lead.customerDetails.contact_name;
            this.isNameClickable = true;
        } else {
            if (this.fieldFrom === 'Line') {
                this.fieldCustomerName = 'ผู้ติดต่อจาก Line';
            } else if (this.fieldFrom === 'โทร') {
                this.fieldCustomerName = 'ผู้ติดต่อจากโทร';
            }
        }

        if (this.lead.number_of_passengers) {
            this.fieldFrom = this.fieldFrom + ` (${this.lead.number_of_passengers} คน)`;
        }

        if (this.lead.sales_person) {
            this.fieldSalesPerson = this.lead.sales_person;
        }
    }

    private setDateTimeColumn(): void {
        const castedDate = new Date(this.lead.created_at);

        this.fieldDate = castedDate.getDate() + ' ' + this.fieldDate + this.thaiMonthDatePipe.transform(castedDate);

        this.fieldTime = castedDate.getHours() + ':' + ('0' + castedDate.getMinutes()).slice(-2);
    }

    private setProgramPeriod(): void {
        let programPeriod = '';

        if (this.lead.start_period) {
            const startDate = new Date(this.lead.start_period);
            const endDate = new Date(this.lead.end_period);

            programPeriod += startDate.getDate() + ' ';
            if (startDate.getMonth() !== endDate.getMonth()) {
                programPeriod += this.thaiMonthDatePipe.transform(startDate) + ' ';

                if (startDate.getFullYear() === endDate.getFullYear()) {
                    programPeriod = programPeriod.slice(0, -3);
                }
            }
            programPeriod += '- ' + endDate.getDate() + ' ' + this.thaiMonthDatePipe.transform(endDate);
        }

        this.fieldProgramPeriod = programPeriod;
    }

    private setInitialStatus(): void {
        if (this.lead.lead_status_slug === 'confirm') {
            this.lead.lead_status_slug = 'booked';
        }
        this.fieldStatus = LEAD_STATUS_SLUG[this.lead.lead_status_slug];
    }

    private setPriceField(): void {
        this.fieldPrice = parseInt(this.lead.price).toLocaleString();
    }

    private checkSellToPartnersPermission(): void {
        this.store
            .pipe(select(selectPermissionAllowPartnersToSellProduct), takeUntil(this.unsubscribe$))
            .subscribe((sellToPartnerPermission) => {
                if (sellToPartnerPermission == false) {
                    this.statusValues = this.statusValues.filter((v) => v !== 'ส่งใบจอง');
                }
            });
    }

    onStatusChange(status: string): void {
        if (this.fieldStatus !== status) {
            if (status === 'ส่งใบจอง') {
                const initialState = {
                    lead: this.lead,
                    period: this.fieldProgramPeriod,
                };
                this.bsModalRef = this.modalService.show(PassBookingToOwnerComponent, {
                    initialState,
                    class: 'pass-booking-to-owner-modal',
                });
            } else if (status === 'จองแล้ว') {
                this.openAddBookingModalIfProductExist();
            } else if (status === 'ยกเลิก') {
                const initialState = {
                    id: this.lead.customerDetails.id,
                    lead: this.lead,
                    period: this.fieldProgramPeriod,
                };
                this.bsModalRef = this.modalService.show(CancelLeadComponent, {
                    initialState,
                    class: 'cancel-booking-modal',
                });
            } else {
                this.updateStatus(status);
            }

            this.modalService.onHide
                .pipe(filter((reason) => reason === this.lead.customerDetails.id + '-submit-booking'))
                .subscribe(() => this.updateStatus('ส่งใบจอง'));
            this.modalService.onHide
                .pipe(filter((reason) => reason === this.lead.customerDetails.id + '-submit-booking-b2c'))
                .subscribe(() => this.updateStatus('จองแล้ว'));
            this.modalService.onHide
                .pipe(filter((reason) => reason === this.lead.customerDetails.id + '-confirm-cancel'))
                .subscribe(() => this.updateStatus('ยกเลิก'));
        }
    }

    private updateStatus(status: string): void {
        this.fieldStatus = status as StatusTypes;
        const statusToUpdate: string = Object.keys(LEAD_STATUS_SLUG).find((key) => LEAD_STATUS_SLUG[key] === status);

        if (statusToUpdate) {
            this.store.dispatch(
                ReportActions.updateLeadStatus({
                    id: this.lead.customerDetails.id,
                    statusSlug: statusToUpdate,
                })
            );
        }
        this.lead.lead_status_slug = statusToUpdate;
        this.initStatusDropdown();
    }

    openContactInfoModal(): void {
        const initialState = {
            customerName: this.lead.customerDetails.contact_name,
            phone: this.lead.customerDetails.phone_number,
            email: this.lead.customerDetails.email,
            more_info: this.lead.customerDetails.more_info || '-',
        };
        this.bsModalRef = this.modalService.show(LeadContactInfoComponent, { initialState, class: 'contact-modal' });
    }

    onEditBooking(): void {
        const initialState = {
            lead: {
                contact_name: this.lead.customerDetails.contact_name,
                phone_number: this.lead.customerDetails.phone_number,
                lead_type_slug: this.lead.lead_type_slug,
                number_of_passengers: this.lead.number_of_passengers,
                email: this.lead.customerDetails.email,
                more_info: this.lead.customerDetails.more_info,
                remark: this.lead.customerDetails.remark || null,
                tour_code: this.lead.tour_code,
                pool_code: this.lead.pool_code,
                period_id: this.lead.product_period_id,
                price: parseInt(this.lead.price),
                agency_code: this.lead.agency_code || null,
                agency_name_th: this.lead.agency_name_th || null,
                agency_name_en: this.lead.agency_name_en || null,
                product_name: this.lead.product_name || null,
                owner: this.lead.owner || null,
                more_info_for_ws: this.lead.more_info_for_ws || null,
                commissionLabel: this.lead.commissionLabel,
                commissionString: this.lead.commissionString,
            },
            isEditBooking: true,
            leadId: this.lead.customerDetails.id,
            periodRange: this.fieldProgramPeriod || null,
            leadStatus: this.lead.lead_status_slug,
        };
        this.bsModalRef = this.modalService.show(AddBookingComponent, { initialState, class: 'edit-booking-modal' });
    }

    openProductOwnerModal(owner: any) {
        const initialState = {
            productOwner: null,
            usingLatestProductOwner: true,
            ownerChannel: this.lead.owner_channel,
            ownerId: this.lead.owner.id,
        };

        this.modalService.show(ProductOwnerModalComponent, {
            initialState,
            class: 'product-owner-modal modal-lg',
        });
    }

    private openAddBookingModalIfProductExist(): void {
        this.store.dispatch(resetProductView());
        this.store.dispatch(loadProductView({ criteria: { poolProduct: true, poolKey: this.lead.pool_code } }));
        const done$ = new Subject<void>();
        combineLatest([
            this.store.pipe(select(selectProductView), takeUntil(done$)),
            this.store.pipe(select(selectError), takeUntil(done$)),
        ])
            .pipe(
                filter(([product, error]) => !!product || !!error),
                take(1)
            )
            .subscribe(([product, error]) => {
                done$.next();
                done$.complete();
                this.store.dispatch(resetProductView());
                if (product) {
                    if (product.owner_channel + product.category_sub_products_id + product.id === this.lead.pool_code) {
                        const initialState = {
                            id: this.lead.customerDetails.id,
                            lead: this.lead,
                        };
                        this.bsModalRef = this.modalService.show(AddBookingB2cComponent, {
                            initialState,
                            class: 'add-booking-b2c-modal',
                        });
                    }
                } else {
                    this.updateStatus('จองแล้ว');
                }
            });
    }

    getOnlineBookingLink(lead: ILeadDetails): string {
        if (lead.wholesale_slug === null) {
            return '#';
        }

        return environment.onlineBookingUrl + lead.wholesale_slug;
    }
}
