import { Injectable } from '@angular/core';
import {
    CanActivate,
    ActivatedRouteSnapshot,
    RouterStateSnapshot,
    Router,
    CanActivateChild,
    UrlTree,
    Route,
} from '@angular/router';

import { tap, map, delay } from 'rxjs/operators';
import { Store, select } from '@ngrx/store';
import { State } from '../stores/reducers';
import { selectUserIsAuthenticate } from '../stores/user-store/user.selectors';
import { Observable, of } from 'rxjs';

// Services
import { checkAuthentication, getAgencyInfo } from '../stores/user-store/user.actions';
import { AuthenticationService } from './authentication.service';

@Injectable()
export class AuthGuardService implements CanActivate {
    constructor(private router: Router, private authService: AuthenticationService, private store: Store<State>) {}

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | UrlTree {
        const url: string = state.url;

        return this.checkLogin(url);
    }

    canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | UrlTree {
        return this.canActivate(route, state);
    }

    canLoad(route: Route): Observable<boolean> | UrlTree {
        const url = `/${route.path}`;

        return this.checkLogin(url);
    }

    checkLogin(url: string): Observable<boolean> | UrlTree {
        this.store.dispatch(checkAuthentication());
        return this.store.select(selectUserIsAuthenticate).pipe(
            delay(500),
            map((response) => {
                if (response) {
                    this.store.dispatch(getAgencyInfo());
                    return true;
                }

                this.router.navigate(['/login']);
            })
        );
    }
}
