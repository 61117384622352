import { Component, OnInit, ElementRef, OnDestroy } from '@angular/core';
import { Subject, Observable } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';

import { Store, select } from '@ngrx/store';
import { State } from 'src/app/stores/reducers';
import {
    selectUser,
    selectAgencyInfo,
    selectPermissionIsActiveTourwowMall,
    selectMenuCustomContentByOrdinal,
} from 'src/app/stores/user-store/user.selectors';
import { logout } from 'src/app/stores/user-store/user.actions';

import { MENU } from 'src/app/constants/menu.constant';
import { IMenu } from 'src/app/models/menu.model';
import { User } from 'src/app/models/user';
import { selectLeadBookingRequestCount } from 'src/app/stores/report-store/report.selectors';
import { environment } from 'src/environments/environment';
import { ICustomContent } from 'src/app/models/custom-content.model';
import { Router } from '@angular/router';
import { getLeadStatMonthly } from 'src/app/stores/report-store/report.actions';

@Component({
    selector: 'app-main-theme',
    templateUrl: './main-theme.component.html',
    styleUrls: ['./main-theme.component.scss'],
})
export class MainThemeComponent implements OnInit, OnDestroy {
    private unsubscribe$: Subject<void> = new Subject<void>();

    user$: Observable<User>;
    leadCount: number;
    leadBookingRequestCount: number;
    isCollapseSidebar: boolean = false;
    menus: IMenu[] = MENU;

    appVersion: string = environment.appVersion;

    constructor(private elementRef: ElementRef, private store: Store<State>, public router: Router) {}

    ngOnInit() {
        this.initialSelect();
        this.getLeadCount();
        this.checkMenuPermission();
        this.checkMenuReportSetting();
        this.checkMenuCustomContentSetting();
    }

    ngOnDestroy(): void {
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
    }

    private getLeadCount(): void {
        this.store
            .pipe(select(selectLeadBookingRequestCount), takeUntil(this.unsubscribe$))
            .subscribe((leadBookingRequestCount: number) => {
                this.leadBookingRequestCount = leadBookingRequestCount;
            });
    }

    private initialSelect(): void {
        this.user$ = this.store.pipe(
            select(selectUser),
            filter((m) => !!m),
            takeUntil(this.unsubscribe$)
        );
    }

    public logout(): void {
        this.store.dispatch(logout());
    }

    private checkMenuPermission(): void {
        this.store.pipe(select(selectAgencyInfo), takeUntil(this.unsubscribe$)).subscribe((agencyInfo) => {
            for (let i = 0; i < this.menus.length; i++) {
                if (this.menus[i].slug == 'booking-list') {
                    this.menus[i].isHidden = !agencyInfo.isActiveTourwowMall;
                }
                if (this.menus[i].slug == 'booking-request-list' || this.menus[i].slug == 'booking-request-summary') {
                    this.menus[i].isHidden = !agencyInfo.allowPartnersToSellProduct;
                }
                if (this.menus[i].slug == 'order-list') {
                    if (agencyInfo.productCategories?.length) {
                        this.menus[i].isHidden = !agencyInfo.productCategories.find((cat) => cat.slug === 'campaign')
                            .isAllowed;
                    }
                }
                if (this.menus[i].slug == 'settings') {
                    this.menus[i].subMenu.find((x) => x.slug === 'blog').isHidden = agencyInfo.useSeoArticle;
                    this.menus[i].subMenu.find((x) => x.slug === 'article').isHidden = !agencyInfo.useSeoArticle;
                }

                if (this.menus[i].slug === 'tw-booking-list') {
                    this.menus[i].isHidden = !agencyInfo.allowSellTourwow;
                }
            }
        });
    }

    private checkMenuCustomContentSetting(): void {
        this.store
            .pipe(select(selectMenuCustomContentByOrdinal, { menuOrdinal: 1 }), takeUntil(this.unsubscribe$))
            .subscribe((response: ICustomContent) => {
                const settingsMenu = this.menus.filter((menu) => menu.slug == 'settings');
                if (settingsMenu.length > 0) {
                    settingsMenu[0].subMenu.forEach((element) => {
                        if (element.slug == 'custom-content-menu-1') {
                            element.isHidden = response ? !response.isActive : true;
                        }
                    });
                }
            });

        this.store
            .pipe(select(selectMenuCustomContentByOrdinal, { menuOrdinal: 2 }), takeUntil(this.unsubscribe$))
            .subscribe((response: ICustomContent) => {
                const settingsMenu = this.menus.filter((menu) => menu.slug == 'settings');
                if (settingsMenu.length > 0) {
                    for (let index = 0; index < settingsMenu[0].subMenu.length; index++) {
                        const element = settingsMenu[0].subMenu[index];
                        if (element.slug == 'custom-content-menu-2') {
                            element.isHidden = response ? !response.isActive : true;
                        }
                    }
                }
            });
    }

    private checkMenuReportSetting(): void {
        this.store.dispatch(getLeadStatMonthly({}));

        this.store
            .pipe(select(selectPermissionIsActiveTourwowMall), takeUntil(this.unsubscribe$))
            .subscribe((twmActive) => {
                if (twmActive !== null) {
                    const menuIndex = this.menus.findIndex((menu) => menu.slug === 'report');
                    const subMenuIndex = this.menus[menuIndex].subMenu.findIndex(
                        (menu) => menu.slug === 'report-download'
                    );
                    this.menus[menuIndex].subMenu[subMenuIndex].isHidden = !twmActive;

                    this.hideAllMenusWithNoActiveSubMenus();
                }
            });
    }

    private hideAllMenusWithNoActiveSubMenus(): void {
        for (let i = 0; i < this.menus.length; i++) {
            if (Array.isArray(this.menus[i].subMenu)) {
                const activeSubMenus = this.menus[i].subMenu.filter((subMenu) => !subMenu.isHidden);
                if (activeSubMenus.length == 0) {
                    this.menus[i].isHidden = true;
                }
            }
        }
    }
}
