import { Component, OnInit, ViewChild } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { filter } from 'rxjs/operators';
import { ENTITY, ACTION_TYPE } from 'src/app/constants/event-log.constant';
import { DateHelper } from 'src/app/helpers/date.helper';
import { IBreadcrumb } from 'src/app/models/breadcrumb.model';
import { IOrderBalanceCommissionReport } from 'src/app/models/order-balance-comission-report.model';
import {
    IOrderBalancePeriod,
    IOrderBalancePeriodItem,
    OrderBalancePeriodItem,
} from 'src/app/models/order-balance-period.model';
import {
    SearchableDropdownComponent,
    SearchableDropdownItem,
    SearchableDropdownSettings,
} from 'src/app/modules/shared/components/searchable-dropdown/searchable-dropdown.component';
import { ExportOrderBalanceCommissionExcelService } from 'src/app/services/export-order-balance-commission-excel.service';
import { ReportService } from 'src/app/services/report.service';
import { State } from 'src/app/stores/reducers';
import { getOrderBalancePeriod } from 'src/app/stores/report-store/report.actions';
import { selectOrderBalancePeriod } from 'src/app/stores/report-store/report.selectors';
import { eventLog } from 'src/app/stores/utility-store/utility.actions';

@Component({
    selector: 'app-order-balance-report-download',
    templateUrl: './order-balance-report-download.component.html',
    styleUrls: ['./order-balance-report-download.component.scss'],
})
export class OrderBalanceReportDownloadComponent implements OnInit {
    public breadcrumb: IBreadcrumb[];

    public yearsDropdownSettings: SearchableDropdownSettings = new SearchableDropdownSettings();
    public yearsDropdownItems: SearchableDropdownItem[] = [];
    public periodDropdownSettings: SearchableDropdownSettings = new SearchableDropdownSettings();
    public periodDropdownItems: SearchableDropdownItem[] = [];

    @ViewChild('yearsDropdown') yearsDropdown: SearchableDropdownComponent;

    public periodBalances: IOrderBalancePeriod;
    public yearNumbersView: Array<number>;
    public yearlyPeriodsView: Map<number, IOrderBalancePeriodItem[]>;

    public periodRowDisplay: IOrderBalancePeriodItem[] = [];

    public filters = {
        year: new Date().getFullYear(),
        periodId: null,
    };

    constructor(
        private store: Store<State>,
        private reportService: ReportService,
        private reportExcelService: ExportOrderBalanceCommissionExcelService
    ) {}

    ngOnInit(): void {
        this.setBreadcrumb();
        this.subscribeStates();

        this.getOrderBalanacePeriod();
    }

    private getOrderBalanacePeriod(): void {
        this.store.dispatch(getOrderBalancePeriod());
    }

    private subscribeStates(): void {
        this.store
            .pipe(
                select(selectOrderBalancePeriod),
                filter((f) => !!f)
            )
            .subscribe((v: IOrderBalancePeriod) => {
                this.periodBalances = v;
                this.yearlyPeriodsView = new OrderBalancePeriodItem().castFromPeriodViews(v.data);
                this.yearNumbersView = Array.from(this.yearlyPeriodsView.keys());

                this.onReset();
            });
    }

    private setBreadcrumb(): void {
        this.breadcrumb = [
            { name: 'หน้าแรก', link: '/dashboard' },
            { name: 'รายงาน', link: '/report' },
            {
                name: 'สรุปรายกดาวน์โหลดข้อมูลใบเสร็จรับเงินค่าคอมของเวาเชอร์ารรับค่าคอม',
                link: 'report/order-balance-report-download',
            },
        ];
    }

    private setYearsDrodpdownlist(): void {
        this.yearsDropdownItems = this.yearNumbersView.map((year) => {
            return new SearchableDropdownItem({
                id: year,
                name: year + 543 + '',
                value: year,
                isChecked: year === new Date().getFullYear() ? true : false,
            });
        });

        this.yearsDropdownSettings.dropdownListPlaceholder = 'เลือกปี';
        this.yearsDropdownSettings.hideSearchBox = true;
        this.yearsDropdownSettings.customSearchIcon = '';
        this.yearsDropdownSettings.multipleSelection = false;
        this.yearsDropdownSettings.closeAfterSelect = true;
        this.yearsDropdownSettings.styleSetting.fontSize = '14px';
        this.yearsDropdownSettings.unableToUncheck = true;
    }

    private setPeriodDropdownlist(year: number): void {
        this.periodDropdownItems = [];

        const regex = new RegExp('^' + year, 'g');
        const period = this.periodBalances.data.filter((p) => p.transferDueDate.match(regex));

        period.forEach((p) => {
            this.periodDropdownItems.push(
                new SearchableDropdownItem({
                    id: p.id,
                    name: DateHelper.convertDateStringFormat(p.transferDueDate),
                    value: p.id,
                    isChecked: false,
                })
            );
        });

        this.periodDropdownSettings.dropdownListPlaceholder = 'เลือกรอบการโอน';
        this.periodDropdownSettings.hideSearchBox = true;
        this.periodDropdownSettings.customSearchIcon = '';
        this.periodDropdownSettings.multipleSelection = false;
        this.periodDropdownSettings.closeAfterSelect = true;
        this.periodDropdownSettings.styleSetting.fontSize = '14px';
        this.periodDropdownSettings.unableToUncheck = true;
    }

    private getDateString(dateTime: string): string {
        if (!dateTime) {
            return '';
        }
        const dateSegments = dateTime.split(' ')[0].split('-');
        const day = dateSegments[2];
        const month = dateSegments[1];
        const year = dateSegments[0];

        return day + '/' + month + '/' + (parseInt(year) + 543).toString();
    }

    private getTimeString(dateTime: string): string {
        if (!dateTime) {
            return '';
        }

        return dateTime.split(' ')[1];
    }

    private getFullName(data: any): string {
        if (!data) {
            return '';
        }

        let fullname = '';
        if (data.first_name) {
            fullname = fullname + data.first_name;
        }

        if (data.last_name) {
            fullname = fullname + ' ' + data.last_name;
        }

        return fullname;
    }

    public convertDateString(
        dateString: string,
        options: any = { year: 'numeric', month: 'long', day: 'numeric' }
    ): string {
        return DateHelper.convertDateStringFormat(dateString, options);
    }

    public onSelectedYears(year: number[]): void {
        if (year.length <= 0) {
            return;
        }

        this.filters.year = year[0];
        this.setPeriodDropdownlist(this.filters.year);
    }

    public onSelectedPeriod(periodId: number[]): void {
        if (periodId.length <= 0) {
            return;
        }

        this.filters.periodId = periodId[0];
    }

    public onSearch(): void {
        this.periodRowDisplay = this.yearlyPeriodsView.get(this.filters.year).sort((period1, period2) => {
            return period1.transferDueDate > period2.transferDueDate ? -1 : 1;
        });
        if (this.periodRowDisplay && this.filters.periodId) {
            this.periodRowDisplay = this.periodRowDisplay.filter((p) => p.id === this.filters.periodId);
        }
    }

    public onReset(): void {
        this.filters = {
            year: new Date().getFullYear(),
            periodId: null,
        };

        this.setYearsDrodpdownlist();
        this.setPeriodDropdownlist(this.filters.year);
        this.onSearch();
    }

    public downloadReport(period: IOrderBalancePeriodItem): void {
        const dateTrasnfer = DateHelper.convertStringToDate(period.transferDueDate);
        this.reportService.getOrderBalanceCommissionReport(period.orderBalance.id).subscribe((response) => {
            if (response.status === 'success') {
                const data = response.data;
                const receiptItems = data.order_balance_receipt_items;
                const reportModels: IOrderBalanceCommissionReport[] = [];

                receiptItems.forEach((element) => {
                    reportModels.push({
                        periodTransferDate:
                            dateTrasnfer.getDate() +
                            '/' +
                            dateTrasnfer.getMonth() +
                            '/' +
                            (dateTrasnfer.getFullYear() + 543),
                        receiptNumber: element.receipt_code,
                        commissionVat: +element.commission_agency_vat,
                        commissionWithHolding: +element.commission_agency_wh_tax,
                        commissionNetTotal: +element.commission_agency,
                        receiptCreatedDate: this.getDateString(data.receipt_issued_at),
                        receiptCreatedTime: this.getTimeString(data.receipt_issued_at),
                        transferStatus: data.transfered ? 'โอนเงินแล้ว' : 'รอโอนเงิน',
                        transferDate: this.getDateString(data.transfered_at),
                        transferTime: this.getTimeString(data.transfered_at),
                        confirmationStatus: data.confirmed ? 'ยืนยันรับเงินแล้ว' : 'รอยืนยันรับเงินแล้ว',
                        confirmationDate: this.getDateString(data.confirmed_at),
                        confirmationTime: this.getTimeString(data.confirmed_at),
                        confirmationBy: this.getFullName(data.confirmed_by_agency_member),
                        confirmationByNickName: data.confirmed_by_agency_member
                            ? data.confirmed_by_agency_member.nick_name
                            : '',
                        sellerName: element.campaign.shop_name,
                        sellerTelephoneNumber: element.campaign.office_telephone_number,
                        orderNumber: element.order.order_code,
                        campaignCode: element.campaign.campaign_code,
                        campaignName: element.campaign.name,
                        voucherName: element.voucher.name,
                        voucherPricePerUnit: +element.voucher.price,
                        voucherCommissionPerUnit: +element.voucher.commission_agency,
                        voucherQuantity: +element.item_quantity,
                        voucherTotalAmount: +element.amount,
                        voucherCommissionBeforeWithHolding: +element.net_commission_agency,
                        customerName: element.order.contact_name,
                        customerTelephoneNumber: element.order.phone_number,
                        buyVoucherDate: this.getDateString(element.order.created_at),
                        buyVoucherTime: this.getTimeString(element.order.created_at),
                    });
                });

                this.reportExcelService.exportOrderBalanceCommissionReport(
                    'RV_' +
                        dateTrasnfer.getFullYear().toString().substr(2) +
                        (dateTrasnfer.getMonth() + 1).toString().padStart(2, '0') +
                        dateTrasnfer.getDate().toString().padStart(2, '0'),
                    this.convertDateString(period.transferDueDate, { year: 'numeric', month: 'long', day: 'numeric' }),
                    period.orderBalance.receiptItemQuantity,
                    new Intl.NumberFormat().format(+period.orderBalance.sumAmount),
                    new Intl.NumberFormat().format(+period.orderBalance.sumNetCommissionAgency),
                    reportModels
                );

                //Event log
                this.eventLog(data.id);
            }
        });
    }

    private eventLog(orderBalanceId: number): void {
        // Event Log
        this.store.dispatch(
            eventLog({
                entityName: ENTITY.AGENCY_ORDER_BALANCE,
                actionType: ACTION_TYPE.DOWNLOAD_COMMISSION_REPORT,
                content: {
                    agcy_order_balance_id: orderBalanceId,
                },
            })
        );
    }
}
