<div class="lead-report-component">
    <app-breadcrumb [items]="breadcrumb"></app-breadcrumb>

    <h2 class="text-primary mb-4"><i class="icon icon-report"></i>ดาวน์โหลดข้อมูลใบจองจากลูกค้า</h2>
    <div class="card mb-4">
        <div class="card-body">
            <div class="card-title">
                <strong>เลือกเดือน</strong>
            </div>
            <div class="row">
                <div class="col-12 col-md-10">
                    <div class="d-flex">
                        <div class="flex-1">
                            <app-calendar-input
                                class="rounded"
                                placeholder="เลือกเดือน"
                                [showOnlyMonth]="true"
                                [bsValue]="fieldStartDate"
                                [maxDate]="fieldEndDate"
                                [bsConfig]="{
                                    dateInputFormat: 'DD MM YY',
                                    maxDate: fieldEndDate || '',
                                    minMode: 'month'
                                }"
                                (bsValueChange)="onStartDateChange($event)"
                            >
                            </app-calendar-input>
                        </div>
                        <div>
                            <label class="col-auto col-form-label small-label">ถึง</label>
                        </div>
                        <div class="flex-1">
                            <app-calendar-input
                                class="rounded"
                                placeholder="เลือกเดือน"
                                [showOnlyMonth]="true"
                                [bsValue]="fieldEndDate"
                                [bsConfig]="{
                                    dateInputFormat: 'DD MM YY',
                                    minDate: fieldStartDate || '',
                                    minMode: 'month'
                                }"
                                (bsValueChange)="onEndDateChange($event)"
                            >
                            </app-calendar-input>
                        </div>
                        <div>
                            <label class="col-auto col-form-label small-label">เซลล์บริษัท (ผู้ขาย)</label>
                        </div>
                        <div class="flex-1">
                            <app-searchable-dropdown #salesDropdown
                                [settings]="salesDropdownSettings"
                                [items]="salesDropdownItems"
                                (selected)="onSelectedSales($event)"
                                [unclickable]="disableSalesDropdown"
                            >
                            </app-searchable-dropdown>
                        </div>
                    </div>
                </div>
                <div class="col-12 col-md-2">
                    <div class="d-flex">
                        <button class="btn btn-primary mr-2" style="max-width: 80px" (click)="searchLeadReport()">
                            ค้นหา
                        </button>
                        <button class="btn btn-link" (click)="resetSearchLeadReportFilters()">เริ่มใหม่</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="card lead-report-row-container" *ngIf="leadStatMonthly && getMonthRange()">
        <div class="row m-0 lead-report-row">
            <div class="col-12 col-sm-3 d-flex align-items-center justify-content-center px-3 font-weight-bold">
                เดือน {{ getMonthRange() }}
            </div>
            <div class="col-12 col-sm-3 d-flex align-items-center justify-content-center px-3">
                พบ {{ leadStatMonthly.total }} รายการ
            </div>
            <div class="col-12 col-sm-6 d-flex align-items-center justify-content-end px-3">
                <button
                    class="btn btn-add"
                    (click)="downloadLeadReport(getMonthRange(), leadStatMonthly.total)"
                    [class.disabled]="leadStatMonthly.total === 0"
                >
                    <i class="icon icon-excel mr-1" *ngIf="leadStatMonthly.total > 0"></i>
                    <i class="icon icon-excel-non mr-1" *ngIf="leadStatMonthly.total === 0"></i>
                    ดาวน์โหลด Excel
                </button>
            </div>
        </div>
        <div class="row m-0 lead-report-row" *ngFor="let period of leadStatMonthly?.period">
            <div class="col-12 col-sm-3 d-flex align-items-center justify-content-center px-3">
                เดือน {{ period.yearMonth | thaiMonthDate }}
            </div>
            <div class="col-12 col-sm-3 d-flex align-items-center justify-content-center px-3">
                พบ {{ period.count }} รายการ
            </div>
            <div class="col-12 col-sm-6 d-flex align-items-center justify-content-end px-3">
                <button class="btn btn-add" (click)="downloadLeadReport(thaiMonthDatePipe.transform(period.yearMonth), period.count, period)">
                    <i class="icon icon-excel mr-1" *ngIf="period.hasReport"></i>
                    <i class="icon icon-excel-non mr-1" *ngIf="!period.hasReport"></i>
                    ดาวน์โหลด Excel
                </button>
            </div>
        </div>
    </div>
</div>
