import { ICountry } from 'src/app/models/country.model';
import { ICategoryTransport } from 'src/app/models/category-transport.model';
import { ITransportation } from 'src/app/models/transportation.model';
import { IGalleryResponse } from 'src/app/models/gallery.model';
import { Province } from 'src/app/models/province';
import { IAboutUsDetails, IAboutUsEditPayload } from 'src/app/modules/settings/models/about-us.model';
import { IBank } from 'src/app/models/bank.model';
import { CountrySubUnit } from 'src/app/models/country-sub-unit.model';
import { ProductSubCategory } from 'src/app/models/product-sub-category.model';

export interface UtilityState {
    countries: ICountry[];
    provinces: Province[];
    countrySubUnits: CountrySubUnit[];
    banks: IBank[];
    transportations: ITransportation[];
    categoryTransports: ICategoryTransport[];
    galleryResponses: Map<string, IGalleryResponse>;
    aboutUsResponse: IAboutUsDetails;
    aboutUsEditPayload: IAboutUsEditPayload;
    aboutUsEditedResponse: any;
    aboutUsError: any;
    productSubCategories: ProductSubCategory[];
}

export const initialState: UtilityState = {
    countries: [],
    provinces: [],
    countrySubUnits: [],
    banks: [],
    transportations: [],
    categoryTransports: [],
    galleryResponses: new Map<string, IGalleryResponse>(),
    aboutUsResponse: null,
    aboutUsEditPayload: null,
    aboutUsEditedResponse: null,
    aboutUsError: null,
    productSubCategories: [],
};
