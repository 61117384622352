import * as moment from 'moment';
import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';

import { TourwowOrderService } from 'src/app/services/tourwow-order.service';

import { Store } from '@ngrx/store';
import { State } from 'src/app/stores/reducers';
import { selectUser } from 'src/app/stores/user-store/user.selectors';

// Components
import { ProgramToursFilterSelectComponent } from './partials/program-tours-filter-select/program-tours-filter-select.component';
import { CustomerInfoFormComponent } from './partials/customer-info-form/customer-info-form.component';
import { SuccessModalComponent } from 'src/app/modules/shared/modals/success-modal/success-modal.component';
import { SearchableDropdownItem } from 'src/app/modules/shared/components/searchable-dropdown/searchable-dropdown.component';

//  Models and Interfaces
import { WholesaleProductPeriod } from 'src/app/models/wholesale-products/wholesale-product-period.model';
import { WholesaleProduct } from 'src/app/models/wholesale-products/wholesale-product.model';
import { User } from 'src/app/models/user';
import { ICustomerInfoForm } from './interfaces/customer-info-form.interface';
import { ISkuListForm } from './interfaces/sku-list-form.interface';
import { INewOrder } from 'src/app/models/interfaces/tourwow-order/new-order.interface';
import { IReponseOrder } from 'src/app/models/interfaces/tourwow-order/response-new-order.interface';
import { IResponseGetOrder } from 'src/app/models/interfaces/tourwow-order/response-get-order.interface';
import { IUpdateCustomerInfo } from 'src/app/models/interfaces/tourwow-order/update-customer-info.interface';
import { Observable } from 'rxjs';
import { NgxUiLoaderService } from 'ngx-ui-loader';

enum MODE {
    ADD = 'add',
    EDIT = 'edit',
    VIEW = 'view',
}

@Component({
    selector: 'app-tw-add-edit-booking-page',
    templateUrl: './tw-add-edit-booking-page.component.html',
    styleUrls: ['./tw-add-edit-booking-page.component.scss'],
})
export class TwAddEditBookingPageComponent implements OnInit {
    @ViewChild('programFilter') appProgramFilter: ProgramToursFilterSelectComponent;
    @ViewChild('customerForm') appCustomerForm: CustomerInfoFormComponent;
    MODE = MODE;
    mode: MODE = MODE.ADD;
    paramOrderId: string;

    isSubmitted = false;
    disabledSubmitButton = false;
    formValid = false;

    program: WholesaleProduct = null; // Selected program from dropdownlist filter
    period: WholesaleProductPeriod = null; // Selected period from dropdownlist filter

    skuList: ISkuListForm;
    skuQuantity = {
        adultSingleBed: 0,
        adultDoubleBed: 0,
        adultTripleBed: 0,
        childWithBed: 0,
        childNoBed: 0,
        infant: 0,
        joinLand: 0,
    };

    customerInfo: ICustomerInfoForm;
    defaultSellerId: number;

    showPreviewLink = false;
    showPreview = false;
    programTourFilterValid = false;
    skuListValid = false;
    customerInfoValid = false;

    bsModalRef: BsModalRef;

    order: IResponseGetOrder;
    countryDefaultDropdownItem: SearchableDropdownItem;
    programDefaultDropdownItem: SearchableDropdownItem;

    constructor(
        private store: Store<State>,
        private bsModalService: BsModalService,
        private router: Router,
        private activatedRoute: ActivatedRoute,
        private tourwowOrder: TourwowOrderService,
        private ngxService: NgxUiLoaderService
    ) {
        this.activatedRoute.params.subscribe((params) => {
            this.paramOrderId = params.id;
        });
    }

    ngOnInit(): void {
        this.getUser();
        if (this.paramOrderId) {
            this.mode = MODE.VIEW;
            this.getOrderDetail(this.paramOrderId).subscribe((order: IResponseGetOrder | null) => {
                this.order = order;
                if (this.order.order_status === 'canceled') {
                    this.disabledSubmitButton = true;
                }
            });
        }
    }

    get allSKUQuantity(): number {
        return (
            this.skuQuantity.adultSingleBed +
            this.skuQuantity.adultDoubleBed +
            this.skuQuantity.adultTripleBed +
            this.skuQuantity.childNoBed +
            this.skuQuantity.childWithBed +
            this.skuQuantity.infant +
            this.skuQuantity.joinLand
        );
    }

    private getUser(): void {
        this.store.select(selectUser).subscribe((user: User) => {
            this.defaultSellerId = user.id;
        });
    }

    private getOrderDetail(orderCode: string): Observable<IResponseGetOrder | null> {
        return this.tourwowOrder.getOrder(orderCode);
    }

    private transformPayload(): INewOrder {
        const items = [];
        if (this.skuList.skuQuantity.adultDoubleBed > 0) {
            items.push({
                product_room_type_slug: 'adult_double',
                quantity: this.skuList.skuQuantity.adultDoubleBed,
            });
        }
        if (this.skuList.skuQuantity.adultSingleBed > 0) {
            items.push({
                product_room_type_slug: 'adult_single',
                quantity: this.skuList.skuQuantity.adultSingleBed,
            });
        }
        if (this.skuList.skuQuantity.adultTripleBed > 0) {
            items.push({
                product_room_type_slug: 'adult_triple',
                quantity: this.skuList.skuQuantity.adultTripleBed,
            });
        }
        if (this.skuList.skuQuantity.childWithBed > 0) {
            items.push({
                product_room_type_slug: 'child_bed',
                quantity: this.skuList.skuQuantity.childWithBed,
            });
        }
        if (this.skuList.skuQuantity.childNoBed > 0) {
            items.push({
                product_room_type_slug: 'child_no_bed',
                quantity: this.skuList.skuQuantity.childNoBed,
            });
        }
        if (this.skuList.skuQuantity.infant > 0) {
            items.push({
                product_room_type_slug: 'infant',
                quantity: this.skuList.skuQuantity.infant,
            });
        }
        if (this.skuList.skuQuantity.joinLand > 0) {
            items.push({
                product_room_type_slug: 'join_land',
                quantity: this.skuList.skuQuantity.joinLand,
            });
        }

        let customerInstallment = [];
        const customerInstallmentSum = this.skuList.customerInstallment.totalRealPaid;
        customerInstallment = this.skuList.customerInstallment.installments
            .filter((i) => i.hide === false)
            .map((i) => {
                return {
                    ordinal: i.ordinal,
                    due_date: moment(i.date).format('YYYY-MM-DD'),
                    amount: i.price,
                };
            });

        let wholesaleInstallment = [];
        const wholesaleInstallmentSum = this.skuList.wholesaleInstallment.totalRealPaid;
        wholesaleInstallment = this.skuList.wholesaleInstallment.installments
            .filter((i) => i.hide === false)
            .map((i) => {
                return {
                    ordinal: i.ordinal,
                    due_date: moment(i.date).format('YYYY-MM-DD'),
                    amount: i.price,
                };
            });

        return {
            product_id: this.program.id,
            period_id: this.period.id,
            seller_agency_member_id: this.customerInfo.sellerId || this.defaultSellerId,
            order_items: items,
            customer_order_installments: customerInstallment,
            supplier_order_installments: wholesaleInstallment,
            use_vat: this.skuList.requestVat,
            discount: this.skuList.discount,
            net_amount: this.skuList.totalRealPaid,
            sum_customer_order_installment_amount: customerInstallmentSum,
            sum_supplier_order_installment_amount: wholesaleInstallmentSum,
            customer_name: this.customerInfo.name,
            customer_phone_number: this.customerInfo.phoneNumber,
            customer_email: this.customerInfo.email,
            customer_facebook: this.customerInfo.facebook,
            customer_line: this.customerInfo.line,
            customer_instagram: this.customerInfo.instagram,
            customer_remark: this.customerInfo.remark,
        };
    }

    private redirectToListPage(): void {
        this.router.navigate(['/order/tw-booking/list']);
    }

    private transformCustomerInfoPayload(): IUpdateCustomerInfo {
        return {
            seller_agency_member_id: this.customerInfo.sellerId,
            customer_name: this.customerInfo.name,
            customer_phone_number: this.customerInfo.phoneNumber,
            customer_email: this.customerInfo.email,
            customer_facebook: this.customerInfo.facebook,
            customer_line: this.customerInfo.line,
            customer_instagram: this.customerInfo.instagram,
            customer_remark: this.customerInfo.remark,
        };
    }

    private popupModalSuccess(): void {
        this.bsModalRef = this.bsModalService.show(SuccessModalComponent, {
            class: 'success-modal',
        });

        this.bsModalRef.onHide.subscribe(() => {
            this.redirectToListPage();
        });
    }

    // Events Listener
    togglePreviewDisplay(): void {
        this.showPreview = !this.showPreview;
    }

    skuFormChange(form: ISkuListForm): void {
        this.skuList = form;
        this.skuQuantity = form.skuQuantity;

        if (this.allSKUQuantity > 0) {
            this.showPreviewLink = true;
        } else {
            this.showPreviewLink = false;
            this.showPreview = false;
        }
    }

    customerInfoFormChange(form: ICustomerInfoForm): void {
        this.customerInfo = form;
    }

    onClickSubmit(): void {
        if (this.disabledSubmitButton) {
            return;
        }

        this.isSubmitted = true;

        if (this.mode === MODE.VIEW) {
            this.appCustomerForm.submitForm();
            if (this.customerInfoValid) {
                // Update only customer info
                const payload = this.transformCustomerInfoPayload();
                this.tourwowOrder
                    .updateOrderCustomerInfo(this.paramOrderId, payload)
                    .subscribe((data: IReponseOrder | null) => {
                        if (data) {
                            this.popupModalSuccess();
                        }
                    });
            } else {
                this.formValid = false;
            }
        } else if (this.mode === MODE.ADD) {
            this.appProgramFilter.submitForm();
            this.appCustomerForm.submitForm();

            if (this.programTourFilterValid && this.skuListValid && this.customerInfoValid) {
                this.ngxService.start();
                this.disabledSubmitButton = true;
                this.formValid = true;
                const payload = this.transformPayload();
                this.tourwowOrder.newOrder(payload).subscribe((data: IReponseOrder | null) => {
                    this.ngxService.stop();
                    if (data) {
                        this.popupModalSuccess();
                    }
                });
            } else {
                this.formValid = false;
            }
        } else if (this.mode === MODE.EDIT) {
            this.appProgramFilter.submitForm();
            this.appCustomerForm.submitForm();

            if (this.programTourFilterValid && this.skuListValid && this.customerInfoValid && this.period) {
                this.ngxService.start();
                this.disabledSubmitButton = true;
                this.formValid = true;
                const payload = this.transformPayload();
                this.tourwowOrder.updateOrder(this.paramOrderId, payload).subscribe((data: IReponseOrder | null) => {
                    this.ngxService.stop();
                    if (data) {
                        this.popupModalSuccess();
                    }
                });
            } else {
                this.formValid = false;
            }
        }
    }

    onClickCancel(): void {
        this.redirectToListPage();
    }
}
