import { SORT_BY } from './../../../constants/product-sorting.constant';
import * as moment from 'moment';
import { ProductListQuery } from './product-list-query';
import { HttpParams } from '@angular/common/http';

export class ProductPoolListQuery {
    public page: number = 1;
    public pageEntries: number = 20;
    public filters: ProductPoolListFilter = new ProductPoolListFilter();
    public productIds: number[];
    public sortBy: SORT_BY;

    public fillFromProductListQuery(query: ProductListQuery): this {
        this.page = query.page;
        this.pageEntries = query.pageEntries;
        this.sortBy = query.sortBy || null;
        this.filters.category_sub_product_ids = query.categorySubProductIds;
        this.filters.tour_code = query.filters.tour_code;
        this.filters.tour_code_exact_start = query.filters.tour_code_exact_start;
        this.filters.country_ids = query.filters.country_ids;
        this.filters.province_ids = query.filters.province_ids;
        this.filters.period_ranges = query.filters.months.map((month) => ({
            min_date: month,
            max_date: moment(month).endOf('month').format('YYYY-MM-DD'),
        }));
        this.filters.price_ranges = query.filters.price_ranges.map((priceRange) => {
            const segments = priceRange.split('-');
            return {
                min_price: parseInt(segments[0]),
                max_price: segments[1] ? parseInt(segments[1]) : null,
            };
        });
        if (query.filters.product_ids) {
            this.productIds = query.filters.product_ids;
        }
        return this;
    }

    public getHttpParams(): HttpParams {
        let params = new HttpParams();

        params = params.append('page', this.page.toString());
        params = params.append('page_entries', this.pageEntries.toString());

        if (this.sortBy) {
            params = params.append('sort_by', (this.sortBy as unknown) as string);
        }

        if (this.productIds) {
            const filters = {
                product_pool_ids: this.productIds,
            };
            params = params.append('filters', JSON.stringify(filters));
        } else {
            params = params.append('filters', JSON.stringify(this.filters));
        }

        return params;
    }
}

export class ProductPoolListFilter {
    public tour_code: string = null;
    public tour_code_exact_start: boolean = false;
    public category_sub_product_ids: number[] = [];
    public country_ids: number[] = null;
    public province_ids: number[] = null;
    public period_ranges: { min_date: string; max_date: string }[] = [];
    public price_ranges: { min_price: number; max_price: number }[] = [];
}
