<div class="sale-report-filter">
    <div class="sale-report-row row">
        <div class="sale-report-column col-md-2" *ngFor="let report of saleReports">
            <div class="sale-report-card" [ngClass]="{'active': report.id === saleReportSelected.id}" (click)="doFilter(report)">
                <div class="d-flex booking-summary align-items-center justify-content-center">
                    <span class="year-month pr-1">{{report.minDate | thaiMonthDate: false}}</span>
                    <span class="total-booking pl-1 d-inline-flex flex-column">
                        <span>{{report.totalOrder}}</span>
                        <span class="suffix">Booking</span>
                    </span>
                </div>
                <div class="text-center">
                    <span class="total-amount">{{report.totalPrice | mycurrency}}</span>
                </div>
            </div>
        </div>
    </div>
</div>
