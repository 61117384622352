import { AfterViewInit, Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { fromEvent } from 'rxjs';
import { debounceTime, filter, tap } from 'rxjs/operators';
import { Installment } from '../../models/installment.model';

@Component({
    selector: 'app-installment-item',
    templateUrl: './installment-item.component.html',
    styleUrls: ['./installment-item.component.scss'],
})
export class InstallmentItemComponent implements AfterViewInit {
    @ViewChild('input') input: ElementRef;
    @Input() installment: Installment;
    @Input() disabled = false;

    @Output() changePrice = new EventEmitter();

    ngAfterViewInit(): void {
        if (this.input) {
            fromEvent(this.input.nativeElement, 'keyup')
                .pipe(
                    filter(Boolean),
                    debounceTime(50),
                    tap(async (event: KeyboardEvent) => {
                        this.changePrice.emit();
                    })
                )
                .subscribe();
        }
    }

    onValueChange(value: Date): void {
        this.installment.date = value;
    }
}
