import { BookingRequestInfoComponent } from '../../modals/b2b-booking/booking-request-info/booking-request-info.component';
import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { ILeadBookingRequestDetails, StatusTypes } from '../../models/booking-request-lead-details.model';
import { ThaiMonthDatePipe } from 'src/app/pipes/thaidate.pipe';
import { BOOKING_REQUEST_LEAD_STATUS_SLUG } from 'src/app/constants/lead-status.constant';
import * as ReportActions from 'src/app/stores/report-store/report.actions';
import { State } from 'src/app/stores/reducers';
import { Store, select } from '@ngrx/store';
import { filter, takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { selectBookingRequestUpdatedLeadStatusIdAndStatus } from 'src/app/stores/report-store/report.selectors';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';

@Component({
    selector: 'app-lead-booking-request-details',
    templateUrl: './lead-booking-request-details.component.html',
    styleUrls: ['./lead-booking-request-details.component.scss'],
})
export class LeadBookingRequestDetailsComponent implements OnInit, OnDestroy {
    @Input() lead: ILeadBookingRequestDetails;
    @Input() index: number;

    fieldDate: string = '';
    fieldTime: string = '';
    fieldProgramPeriod: string = '';
    fieldStatus: StatusTypes;

    bsModalRef: BsModalRef;

    private unsubscribe$: Subject<void> = new Subject<void>();

    statusValues = Object.keys(BOOKING_REQUEST_LEAD_STATUS_SLUG).map((key) => BOOKING_REQUEST_LEAD_STATUS_SLUG[key]);

    constructor(
        private thaiMonthDatePipe: ThaiMonthDatePipe,
        private modalService: BsModalService,
        private store: Store<State>
    ) {}

    ngOnInit() {
        this.lead = Object.assign({}, this.lead);
        this.setDateTimeColumn();
        this.setProgramPeriod();
        this.setFieldStatus();
    }

    ngOnDestroy(): void {
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
    }

    private setDateTimeColumn(): void {
        const castedDate = new Date(this.lead.createdAt);

        this.fieldDate = castedDate.getDate() + ' ' + this.fieldDate + this.thaiMonthDatePipe.transform(castedDate);

        this.fieldTime = castedDate.getHours() + ':' + ('0' + castedDate.getMinutes()).slice(-2);
    }

    private setProgramPeriod(): void {
        let programPeriod = '';

        if (this.lead.startDate) {
            const startDate = new Date(this.lead.startDate);
            const endDate = new Date(this.lead.endDate);

            programPeriod += startDate.getDate() + ' ';
            if (startDate.getMonth() !== endDate.getMonth()) {
                programPeriod += this.thaiMonthDatePipe.transform(startDate) + ' ';

                if (startDate.getFullYear() === endDate.getFullYear()) {
                    programPeriod = programPeriod.slice(0, -3);
                }
            }
            programPeriod += '- ' + endDate.getDate() + ' ' + this.thaiMonthDatePipe.transform(endDate);
        }

        this.fieldProgramPeriod = programPeriod;
    }

    private setFieldStatus(): void {
        this.fieldStatus = BOOKING_REQUEST_LEAD_STATUS_SLUG[this.lead.leadStatusSlug];

        this.store
            .pipe(
                select(selectBookingRequestUpdatedLeadStatusIdAndStatus),
                filter((m) => !!m),
                takeUntil(this.unsubscribe$)
            )
            .subscribe((result) => {
                if (result.split('-')[0] && this.lead.id === parseInt(result.split('-')[0])) {
                    this.fieldStatus = BOOKING_REQUEST_LEAD_STATUS_SLUG[result.split('-')[1]];
                }
            });
    }

    onStatusChange(status: string): void {
        if (this.fieldStatus !== status) {
            this.fieldStatus = status as StatusTypes;

            const statusToUpdate: string = Object.keys(BOOKING_REQUEST_LEAD_STATUS_SLUG).find(
                (key) => BOOKING_REQUEST_LEAD_STATUS_SLUG[key] === status
            );

            if (statusToUpdate) {
                this.store.dispatch(
                    ReportActions.updateBookingRequestLeadStatus({
                        id: this.lead.id,
                        statusSlug: statusToUpdate,
                    })
                );
                this.lead.leadStatusSlug = statusToUpdate;
            }
        }
    }

    getStatus(status: string): string {
        return BOOKING_REQUEST_LEAD_STATUS_SLUG[status];
    }

    viewInfo(): void {
        const initialState = {
            leadId: this.lead.id,
            dateAndTime: this.fieldDate + ' ' + this.fieldTime,
            period: this.fieldProgramPeriod,
            commissionLabel: this.lead.commissionLabel,
            commissionString: this.lead.commissionString,
        };
        this.bsModalRef = this.modalService.show(BookingRequestInfoComponent, {
            initialState,
            class: 'pass-booking-to-owner-modal',
        });
    }
}
