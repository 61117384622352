export class Deal {
    description: string = null;
    discountPricePercent: number = null;
    expiredAt: string = null;
    dealBannerFileName: string = null;

    public deserialize(obj: any): this {
        this.description = obj.description || null;
        this.discountPricePercent = obj.discount_price_percent || null;
        this.expiredAt = obj.expired_at || null;
        this.dealBannerFileName = obj.deal_banner_file_name || null;
        return this;
    }
}
