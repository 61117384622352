import { ILeadCountByType, LeadCountByType } from 'src/app/modules/report/models/lead-count-by-type.model';
import { COMMUNICATION_METHODS } from 'src/app/constants/communication-method.constant';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { IBreadcrumb } from 'src/app/models/breadcrumb.model';
import {
    SearchableDropdownItem,
    SearchableDropdownSettings,
} from 'src/app/modules/shared/components/searchable-dropdown/searchable-dropdown.component';
import { State } from 'src/app/stores/reducers';
import { IProgressStatus, ISearchFilter, SearchFilter } from '../../models/search-filter.model';
import * as ReportActions from 'src/app/stores/report-store/report.actions';
import { LEAD_STATUS_SLUG } from 'src/app/constants/lead-status.constant';
import { selectLeadCountByType } from 'src/app/stores/report-store/report.selectors';
import { filter, takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import * as _ from 'lodash-es';

@Component({
    selector: 'app-lead-list',
    templateUrl: './lead-list.component.html',
    styleUrls: ['./lead-list.component.scss'],
})
export class LeadListComponent implements OnInit, OnDestroy {
    private searchFilter: ISearchFilter = new SearchFilter().initialiseSearchFilter();
    private leadStatusIds = Object.keys(LEAD_STATUS_SLUG);

    breadcrumb: IBreadcrumb[];
    showFilter: boolean = false;
    hideShortcutFilters: boolean = false;

    communicationMethods: typeof COMMUNICATION_METHODS = COMMUNICATION_METHODS;
    activeCommunicationMethod: string = '';
    leadCountByType: ILeadCountByType = new LeadCountByType().deserialize(null);

    dateRangeDropdownSettings: SearchableDropdownSettings = new SearchableDropdownSettings();
    dateRangeDropdownItems: SearchableDropdownItem[];
    leadStatusDropdownSettings: SearchableDropdownSettings = new SearchableDropdownSettings();
    leadStatusDropdownItems: SearchableDropdownItem[] = [];

    advancedSearchFilterProgressCheckbox: IProgressStatus;
    advancedSearchFilterStartDate: Date;
    advancedSearchFilterEndDate: Date = new Date();

    private unsubscribe$: Subject<void> = new Subject<void>();

    constructor(private store: Store<State>) {}

    ngOnInit() {
        this.setBreadcrumb();
        this.loadDateRangeDropdown();
        this.setInitialFiltersAndSubscribeToCount();
        this.dispatchLeadList();
        this.loadLeadStatusDropdown();
        this.resetAdvancedSearchFilterProgressCheckbox();
        this.advancedSearchFilterProgressCheckbox.waiting_contact = true;
    }

    ngOnDestroy(): void {
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
    }

    private setBreadcrumb(): void {
        this.breadcrumb = [
            { name: 'หน้าแรก', link: '/dashboard' },
            { name: 'ใบจองจากลูกค้า', link: '/report/lead-list' },
        ];
    }

    toggleFilter(): void {
        if (this.showFilter) {
            this.showFilter = false;
        } else {
            this.showFilter = true;
            this.hideShortcutFilters = true;
        }
    }

    private resetAdvancedSearchFilterProgressCheckbox(): void {
        this.advancedSearchFilterProgressCheckbox = {
            all: false,
            waiting_contact: false,
            pending: false,
            waiting_dicision: false,
            confirm: false,
            booked: false,
            cancel: false,
        };
    }

    private setInitialFiltersAndSubscribeToCount(): void {
        this.searchFilter.lead_status_slugs = [this.leadStatusIds[0]];
        this.onSelectDateRange([3]); // Past 7 days

        this.store
            .pipe(
                select(selectLeadCountByType),
                filter((m) => !!m),
                takeUntil(this.unsubscribe$)
            )
            .subscribe((leadCountByType: ILeadCountByType) => {
                this.leadCountByType = leadCountByType;
            });
    }

    private dispatchLeadList(): void {
        this.store.dispatch(
            ReportActions.getLeadList({
                searchFilter: _.cloneDeep(this.searchFilter),
                criteria: {
                    page: 1,
                    pageEntries: 50,
                },
            })
        );
        this.store.dispatch(
            ReportActions.getLeadCountByType({
                searchFilter: _.cloneDeep(this.searchFilter),
            })
        );
    }

    private loadDateRangeDropdown(): void {
        this.dateRangeDropdownItems = [
            {
                id: 1,
                name: 'วันนี้',
            },
            {
                id: 2,
                name: 'เมื่อวานนี้',
            },
            {
                id: 3,
                name: '7 วันล่าสุด',
                isChecked: true,
            },
            {
                id: 4,
                name: 'เดือนล่าสุด',
            },
        ];
        this.dateRangeDropdownSettings.hideSearchBox = true;
        this.dateRangeDropdownSettings.multipleSelection = false;
        this.dateRangeDropdownSettings.closeAfterSelect = true;
        this.dateRangeDropdownSettings.unableToUncheck = true;
        this.dateRangeDropdownSettings.styleSetting.fontSize = '14px';
    }

    private loadLeadStatusDropdown(): void {
        this.leadStatusIds.map((id) =>
            this.leadStatusDropdownItems.push(
                new SearchableDropdownItem({
                    id: id,
                    name: LEAD_STATUS_SLUG[id],
                    isChecked: id === this.leadStatusIds[0],
                })
            )
        );
        this.leadStatusDropdownSettings.dropdownListPlaceholder = 'เลือกช่องทาง';
        this.leadStatusDropdownSettings.hideSearchBox = true;
        this.leadStatusDropdownSettings.multipleSelection = false;
        this.leadStatusDropdownSettings.closeAfterSelect = true;
        this.leadStatusDropdownSettings.unableToUncheck = true;
        this.leadStatusDropdownSettings.styleSetting.fontSize = '14px';
    }

    onSelectDateRange(idList: number[], dispatch?: boolean): void {
        const id: number = idList[0];
        let date = new Date();

        switch (id) {
            case 4:
                date.setUTCDate(1);
                break;
            case 3:
                date.setDate(date.getUTCDate() - 6);
                break;
            case 2:
                date.setDate(date.getUTCDate() - 1);
                this.advancedSearchFilterEndDate = date;
                break;
            case 1:
                date.setDate(date.getUTCDate());
                break;
            default:
                date = null;
                break;
        }

        this.advancedSearchFilterStartDate = date;
        this.searchFilter.contact_date_range.min_date = date.toISOString().substring(0, 10);

        if (id === 2) {
            this.searchFilter.contact_date_range.max_date = this.searchFilter.contact_date_range.min_date;
        } else {
            this.searchFilter.contact_date_range.max_date = null;
        }

        if (dispatch) {
            this.dispatchLeadList();
        }
    }

    communicationMethodSelect(communicationMethod: string): void {
        this.activeCommunicationMethod = communicationMethod;

        if (communicationMethod) {
            this.searchFilter.lead_type_slug = communicationMethod;
        } else {
            this.searchFilter.lead_type_slug = null;
        }

        this.dispatchLeadList();
    }

    onSelectLeadStatus(statusSlug: string[]): void {
        this.searchFilter.lead_status_slugs = statusSlug;
        if (statusSlug[0] === 'booked') {
            this.searchFilter.lead_status_slugs.push('confirm');
        }
        this.dispatchLeadList();

        this.resetAdvancedSearchFilterProgressCheckbox();
        switch (statusSlug[0]) {
            case 'waiting_contact':
                this.advancedSearchFilterProgressCheckbox.waiting_contact = true;
                break;
            case 'waiting_dicision':
                this.advancedSearchFilterProgressCheckbox.waiting_dicision = true;
                break;
            case 'booked':
                this.advancedSearchFilterProgressCheckbox.confirm = true;
                this.advancedSearchFilterProgressCheckbox.booked = true;
                break;
            case 'cancel':
                this.advancedSearchFilterProgressCheckbox.cancel = true;
                break;
            default:
                break;
        }
    }
}
