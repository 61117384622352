<div class="product-box d-flex" *ngIf="productResult || orderDetail">
    <img *ngIf="productResult" src="{{productResult.gallery.gallery_item[0].preview_url}}"
    style="cursor: pointer;" (click)="redirectToProductViewPage()"
    >
    <img *ngIf="orderDetail" src="{{orderDetail.bannerPreviewUrl}}"
    style="cursor: pointer;" (click)="redirectToProductViewPage()"
    >
    <div class="right-side">

        <div class="location">
            <div class="d-inline mr-1">
                <i class="icon icon-location"></i>
            </div>
            <p class="d-inline">
                <span *ngIf="(provinces && provinces.length > 0) ||
                    (orderDetail?.provinces && orderDetail.provinces.length > 0);
                    else countryDestination">
                    {{(provinces || orderDetail.provinces) | displayProvinces : ' - '}}
                </span>
                <ng-template #countryDestination>
                    <span *ngIf="(countries && countries.length > 0) ||
                        (orderDetail?.countries && orderDetail.countries.length > 0);
                        else noDestination">
                    {{(countries || orderDetail.countries) | displayCountries : ' - '}}
                    </span>
                </ng-template>
                <ng-template #noDestination>
                    <span>ไม่ระบุ</span>
                </ng-template>
            </p>
        </div>

        <div class="transport">
            <div class="d-inline mr-1">
                <img [src]="getCategoryTransportIconFilePath()">
            </div>
            <p class="d-inline">
            <span>{{ getGoTransportationString() }}</span>
            </p>
        </div>

        <div class="duration">
            <div class="d-inline mr-2">
                <i class="icon icon-clock"></i>
            </div>
        <p class="d-inline">
            <span>{{ getDurationString() }}</span>
        </p>

        <div class="commission">
            <div class="commision-icon d-inline mr-1">
                <img src="/assets/img/icon/icon-commision.svg">
            </div>
            <p class="d-inline">
                {{getCommissionString()}}
            </p>
        </div>
    </div>
</div>
