import { selectReportAgencyList } from '../../../../stores/report-store/report.selectors';
import { getBookingRequestLeadList, getLeadSummaryList } from '../../../../stores/report-store/report.actions';
import { Component, OnInit, ViewChild, Input, OnChanges, OnDestroy } from '@angular/core';
import {
    SearchableDropdownSettings,
    SearchableDropdownItem,
    SearchableDropdownComponent,
} from 'src/app/modules/shared/components/searchable-dropdown/searchable-dropdown.component';
import { COMMUNICATION_METHODS, LEAD_TYPE_SLUG } from 'src/app/constants/communication-method.constant';
import { ISearchFilter, IProgressStatus, SearchFilter } from 'src/app/modules/report/models/search-filter.model';
import { Store, select } from '@ngrx/store';
import { State } from 'src/app/stores/reducers';
import { getLeadList } from 'src/app/stores/report-store/report.actions';
import { BsLocaleService } from 'ngx-bootstrap/datepicker';
import { defineLocale, thLocale } from 'ngx-bootstrap/chronos';
import { filter, takeUntil, tap } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { selectCountries } from 'src/app/stores/utility-store/utility.selectors';
import { loadCountries } from 'src/app/stores/utility-store/utility.actions';
import * as _ from 'lodash-es';

@Component({
    selector: 'app-search-filter',
    templateUrl: './search-filter.component.html',
    styleUrls: ['./search-filter.component.scss'],
})
export class SearchFilterComponent implements OnInit, OnChanges, OnDestroy {
    @Input() activeList: 'LEAD_LIST' | 'LEAD_BOOKING_REQUEST' | 'LEAD_BOOKING_SUMMARY';
    @Input() searchFilter?: ISearchFilter;

    @ViewChild('communicationMethodDropdown') searchableDropdownComponent: SearchableDropdownComponent;
    @ViewChild('agencyDropdown') searchableAgencyDropdownComponent: SearchableDropdownComponent;
    @ViewChild('countryDropdown') searchableCountryDropdownComponent: SearchableDropdownComponent;

    communicationDropdownSettings: SearchableDropdownSettings = new SearchableDropdownSettings();
    communicationDropdownItems: SearchableDropdownItem[] = [];
    agencyDropdownSettings: SearchableDropdownSettings = new SearchableDropdownSettings();
    agencyDropdownItems: SearchableDropdownItem[] = [];
    countryDropdownSettings: SearchableDropdownSettings = new SearchableDropdownSettings();
    countryDropdownItems: SearchableDropdownItem[] = [];

    private page: number = 1;
    private pageEntries: number = 50;

    private unsubscribe$: Subject<void> = new Subject<void>();

    @Input() fieldStartDate: Date;
    @Input() fieldEndDate: Date;
    @Input() private preselectedCommunicationMethod: string = '';
    @Input() fieldStatusCheckbox: IProgressStatus = {
        all: false,
        waiting_contact: false,
        pending: false,
        waiting_dicision: false,
        confirm: false,
        booked: false,
        cancel: false,
    };

    constructor(private store: Store<State>) {}

    ngOnInit() {
        if (!this.searchFilter) {
            this.initialiseSearchFilter();
        }

        if (this.activeList === 'LEAD_LIST') {
            this.loadCommunicationMethodDropdown();
        } else {
            this.fieldStatusCheckbox.all = true;
            this.loadAgencyDropdown();
            this.loadCountryDropdown();
        }
        if (this.activeList === 'LEAD_BOOKING_SUMMARY') {
            this.pageEntries = 15;
        }
    }

    ngOnChanges() {
        this.loadCommunicationMethodDropdown();
    }

    ngOnDestroy(): void {
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
    }

    onStartDateChange(date: Date): void {
        this.fieldStartDate = date;
        if (this.activeList === 'LEAD_LIST') {
            this.searchFilter.contact_date_range.min_date = date ? date.toISOString().substring(0, 10) : null;
        } else if (this.activeList === 'LEAD_BOOKING_REQUEST') {
            this.searchFilter.created_at_date_range.min_date = date ? date.toISOString().substring(0, 10) : null;
        } else if (this.activeList === 'LEAD_BOOKING_SUMMARY') {
            this.searchFilter.b2b_lead_created_at_date_range.min_date = date
                ? date.toISOString().substring(0, 10)
                : null;
        }
    }

    onEndDateChange(date: Date): void {
        this.fieldEndDate = date;
        if (this.activeList === 'LEAD_LIST') {
            this.searchFilter.contact_date_range.max_date = date ? date.toISOString().substring(0, 10) : null;
        } else if (this.activeList === 'LEAD_BOOKING_REQUEST') {
            this.searchFilter.created_at_date_range.max_date = date ? date.toISOString().substring(0, 10) : null;
        } else if (this.activeList === 'LEAD_BOOKING_SUMMARY') {
            this.searchFilter.b2b_lead_created_at_date_range.max_date = date
                ? date.toISOString().substring(0, 10)
                : null;
        }
    }

    loadCommunicationMethodDropdown(): void {
        this.communicationDropdownItems = [];
        COMMUNICATION_METHODS.map((option) =>
            this.communicationDropdownItems.push(
                new SearchableDropdownItem({
                    id: option.id,
                    name: option.text,
                    isChecked: option.id === this.preselectedCommunicationMethod,
                })
            )
        );
        this.communicationDropdownSettings.dropdownListPlaceholder = 'เลือกช่องทาง';
        this.communicationDropdownSettings.hideSearchBox = true;
        this.communicationDropdownSettings.multipleSelection = false;
        this.communicationDropdownSettings.closeAfterSelect = true;
        this.communicationDropdownSettings.unableToUncheck = true;
        this.communicationDropdownSettings.styleSetting.fontSize = '14px';
    }

    private loadAgencyDropdown(): void {
        this.store
            .pipe(
                select(selectReportAgencyList),
                filter((response) => response !== null),
                takeUntil(this.unsubscribe$)
            )
            .subscribe((result) => {
                if (result.length > this.agencyDropdownItems.length) {
                    this.agencyDropdownItems = [];
                    result.map((result) => {
                        this.agencyDropdownItems.push(
                            new SearchableDropdownItem({
                                id: result.id,
                                name: result.name_en,
                            })
                        );
                    });
                    this.agencyDropdownItems = this.agencyDropdownItems.sort((a, b) => a.name.localeCompare(b.name));
                }
            });
        this.agencyDropdownSettings.dropdownListPlaceholder = 'เลือก Agency';
        this.agencyDropdownSettings.hideSearchBox = true;
        this.agencyDropdownSettings.multipleSelection = false;
        this.agencyDropdownSettings.styleSetting.fontSize = '14px';
        this.agencyDropdownSettings.closeAfterSelect = true;
    }

    private loadCountryDropdown(): void {
        this.store.dispatch(loadCountries());
        this.store
            .pipe(
                select(selectCountries),
                filter((countries) => countries && countries.length > 0),
                tap((countries) => {
                    this.countryDropdownItems = countries.map(
                        (country) =>
                            new SearchableDropdownItem({
                                id: country.id,
                                name: country.nameTH + ' (' + country.nameEN + ')',
                                imageUrl: country.iconPathUrl,
                            })
                    );
                }),
                takeUntil(this.unsubscribe$)
            )
            .subscribe();

        this.countryDropdownSettings.dropdownListAllItemsLabel = 'ทุกประเทศ';
        this.countryDropdownSettings.dropdownListPlaceholder = 'เลือกประเทศ';
        this.countryDropdownSettings.searchBoxPlaceholder = 'กรอกชื่อประเทศ';
        this.countryDropdownSettings.styleSetting.fontSize = '14px';
    }

    initialiseSearchFilter(): void {
        this.searchFilter = new SearchFilter().initialiseSearchFilter();
    }

    onSelectCommunicationMethod(communicationMethod: string[]): void {
        if (communicationMethod.length) {
            this.searchFilter.lead_type_slug = communicationMethod[0];
        } else {
            this.searchFilter.lead_type_slug = null;
        }
    }

    onSelectAgency(agencyId: string[]): void {
        if (agencyId.length) {
            this.searchFilter.agency_id = parseInt(agencyId[0]);
        } else {
            this.searchFilter.agency_id = null;
        }
    }

    onSelectCountry(countries: string[]): void {
        if (countries.length) {
            this.searchFilter.country_ids = countries.map(function (x) {
                return parseInt(x, 10);
            });
        } else {
            this.searchFilter.country_ids = null;
        }
    }

    onCheckboxClick(isAll: boolean, checkboxNo: number) {
        switch (checkboxNo) {
            case 1:
                this.fieldStatusCheckbox.all = !this.fieldStatusCheckbox.all;
                break;
            case 2:
                this.fieldStatusCheckbox.waiting_contact = !this.fieldStatusCheckbox.waiting_contact;
                break;
            case 3:
                this.fieldStatusCheckbox.waiting_dicision = !this.fieldStatusCheckbox.waiting_dicision;
                break;
            case 4:
                this.fieldStatusCheckbox.pending = !this.fieldStatusCheckbox.pending;
                break;
            case 5:
                this.fieldStatusCheckbox.confirm = !this.fieldStatusCheckbox.confirm;
                this.fieldStatusCheckbox.booked = !this.fieldStatusCheckbox.booked;
                break;
            case 6:
                this.fieldStatusCheckbox.cancel = !this.fieldStatusCheckbox.cancel;
                break;
            default:
                break;
        }

        if (!isAll) {
            this.fieldStatusCheckbox.all = false;
        } else {
            this.fieldStatusCheckbox.waiting_contact = false;
            this.fieldStatusCheckbox.waiting_dicision = false;
            this.fieldStatusCheckbox.pending = false;
            this.fieldStatusCheckbox.confirm = false;
            this.fieldStatusCheckbox.booked = false;
            this.fieldStatusCheckbox.cancel = false;
        }
    }

    resetSearchFilter(): void {
        this.initialiseSearchFilter();

        if (this.searchableDropdownComponent) {
            this.searchableDropdownComponent.selectAllItems(false);
        }
        if (this.searchableAgencyDropdownComponent) {
            this.searchableAgencyDropdownComponent.selectAllItems(false);
        }
        if (this.searchableCountryDropdownComponent) {
            this.searchableCountryDropdownComponent.selectAllItems(false);
        }

        this.onCheckboxClick(true, null);
        if (this.activeList === 'LEAD_LIST') {
            this.onCheckboxClick(false, 2);
        } else {
            this.fieldStatusCheckbox.all = true;
        }

        this.fieldStartDate = null;
        this.fieldEndDate = null;
    }

    private setLeadStatusToSubmit(): any {
        let leadStatusSlugs = [];
        if (this.fieldStatusCheckbox.all) {
            leadStatusSlugs = null;
        } else {
            if (this.fieldStatusCheckbox.waiting_contact) {
                leadStatusSlugs.push('waiting_contact');
            }
            if (this.fieldStatusCheckbox.waiting_dicision) {
                leadStatusSlugs.push('waiting_dicision');
            }
            if (this.fieldStatusCheckbox.pending) {
                leadStatusSlugs.push('pending');
            }
            if (this.fieldStatusCheckbox.confirm || this.fieldStatusCheckbox.booked) {
                leadStatusSlugs.push('confirm');
                leadStatusSlugs.push('booked');
            }
            if (this.fieldStatusCheckbox.cancel) {
                leadStatusSlugs.push('cancel');
            }
            if (!leadStatusSlugs.length) {
                leadStatusSlugs = null;
            }
        }
        return leadStatusSlugs;
    }

    submit(): void {
        const filterToSubmit = _.cloneDeep(this.searchFilter);
        for (const prop in filterToSubmit) {
            if (filterToSubmit[prop] === null || filterToSubmit[prop] === '') {
                delete filterToSubmit[prop];
            }
        }

        for (const type in LEAD_TYPE_SLUG) {
            if (LEAD_TYPE_SLUG[type] === filterToSubmit.lead_type_slug) {
                filterToSubmit.lead_type_slug = type;
            }
        }

        switch (this.activeList) {
            case 'LEAD_LIST':
                delete filterToSubmit.created_at_date_range;
                delete filterToSubmit.b2b_lead_created_at_date_range;
                filterToSubmit.lead_status_slugs = this.setLeadStatusToSubmit();
                this.store.dispatch(
                    getLeadList({
                        searchFilter: filterToSubmit,
                        criteria: {
                            page: this.page,
                            pageEntries: this.pageEntries,
                        },
                    })
                );
                break;
            case 'LEAD_BOOKING_REQUEST':
                delete filterToSubmit.contact_date_range;
                delete filterToSubmit.b2b_lead_created_at_date_range;
                filterToSubmit.b2b_lead_status_slugs = this.setLeadStatusToSubmit();
                this.store.dispatch(
                    getBookingRequestLeadList({
                        searchFilter: filterToSubmit,
                        criteria: {
                            page: this.page,
                            pageEntries: this.pageEntries,
                        },
                    })
                );
                break;
            case 'LEAD_BOOKING_SUMMARY':
                delete filterToSubmit.contact_date_range;
                delete filterToSubmit.created_at_date_range;
                this.store.dispatch(
                    getLeadSummaryList({
                        searchFilter: filterToSubmit,
                        criteria: {
                            page: this.page,
                            pageEntries: this.pageEntries,
                        },
                    })
                );
                break;
            default:
                break;
        }
    }
}
