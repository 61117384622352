import { IBlogDetail } from '../modules/settings/models/blog.model';

export class UrlWriter {
    public static generateProductDetailUrl(twmMallUrl: string, shareUrl: string): string {
        shareUrl = twmMallUrl + '/' + shareUrl;
        return this.prependSchemeToUrl(shareUrl);
    }

    public static generateCampaignDetailUrl(twmMallUrl: string, shareUrl: string): string {
        shareUrl = twmMallUrl + '/' + shareUrl;
        return this.prependSchemeToUrl(shareUrl);
    }

    public static generateBlogShareUrl(twmMallUrl: string, blogDetail: IBlogDetail): string {
        const title = blogDetail.title.replace(/ /g, '-');
        const shareUrl = twmMallUrl + '/blog/' + blogDetail.id + '-' + title.substring(0, 50);
        return this.prependSchemeToUrl(shareUrl);
    }

    public static generatePortfolioShareUrl(twmMallUrl: string, id: number, title: string): string {
        title = title.replace(/ /g, '-');
        const shareUrl = twmMallUrl + '/gallery/' + id + '-' + title.substring(0, 50);
        return this.prependSchemeToUrl(shareUrl);
    }

    public static lineSharingLink(url: string): string {
        url = this.prependSchemeToUrl(encodeURI(url));
        return 'https://social-plugins.line.me/lineit/share?url=' + url;
    }

    public static facebookSharingLink(url: string): string {
        url = this.prependSchemeToUrl(encodeURI(url));
        return 'https://www.facebook.com/sharer/sharer.php?amp;src=sdkpreparse&u=' + url;
    }

    public static prependSchemeToUrl(url: string, scheme: string = 'https'): string {
        const alreadyHasScheme = url.startsWith('https://') || url.startsWith('http://');
        if (alreadyHasScheme) return url;
        return scheme + '://' + url;
    }

    public static copyLink(url: string): void {
        const selBox = document.createElement('textarea');
        selBox.style.position = 'fixed';
        selBox.style.left = '0';
        selBox.style.top = '0';
        selBox.style.opacity = '0';
        selBox.value = url;
        document.body.appendChild(selBox);
        selBox.focus();
        selBox.select();
        document.execCommand('copy');
        document.body.removeChild(selBox);
    }
}
