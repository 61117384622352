import { IOrderFile } from '../interfaces/tw-booking-interface/order-file.interface';

export class OrderFile {
    id: number;
    fileName: string;
    fileType: string;
    fileUrl: string;

    public deserialize(data: IOrderFile): OrderFile {
        this.id = data.id;
        this.fileName = data.file_name;
        this.fileType = data.file_type;
        this.fileUrl = data.file_url;
        return this;
    }
}
