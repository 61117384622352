import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { APP_BASE_HREF } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { environment } from '../environments/environment';

// Pages
import { MainThemeComponent } from './themes/main-theme/main-theme.component';
import { LoginPageComponent } from './modules/public/pages/login-page/login-page.component';
import { LoadingPageComponent } from './modules/public/pages/loading-page/loading-page.component';

// States
import { StoreModule } from '@ngrx/store';
import { reducers, metaReducers } from './stores/reducers';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { EffectsModule } from '@ngrx/effects';
import { UserStoreModule } from './stores/user-store/user-store.module';
import { ProductStoreModule } from './stores/product-store/product-store.module';
import { ReportStoreModule } from './stores/report-store/report-store.module';
import { SettingStoreModule } from './stores/setting-store/setting-store.module';
import { OrderStoreModule } from './stores/order-store/order-store.module';
import { CampaignStoreModule } from './stores/campaign-store/campaign-store.module';

// Service
import { AuthGuardService } from './services/auth-guard.service';
import { TokenService } from './services/token.service';

// Modules
import { ReportModule } from './modules/report/report.module';
import { OrderModule } from './modules/order/order.module';

// Third Party
import { CollapseModule } from 'ngx-bootstrap/collapse';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { UtilityStoreModule } from './stores/utility-store/utility-store.module';
import { NgxUiLoaderModule, NgxUiLoaderConfig } from 'ngx-ui-loader';
import { defineLocale, thBeLocale } from 'ngx-bootstrap/chronos';

const ngxLoaderConfig: NgxUiLoaderConfig = {
    bgsColor: 'red',
    bgsOpacity: 0.5,
    bgsPosition: 'bottom-right',
    bgsSize: 0,
    bgsType: 'ball-spin-clockwise',
    blur: 5,
    delay: 0,
    fgsColor: '#ff6704',
    fgsPosition: 'center-center',
    fgsSize: 0,
    fgsType: 'ball-spin-clockwise',
    gap: 24,
    logoPosition: 'center-center',
    logoSize: 60,
    logoUrl: 'assets/img/gif/icon-loading.gif',
    masterLoaderId: 'master',
    overlayBorderRadius: '0',
    overlayColor: 'rgba(40, 40, 40, 0.8)',
    pbColor: 'red',
    pbDirection: 'ltr',
    pbThickness: 3,
    hasProgressBar: true,
    text: '',
    textColor: '#FFFFFF',
    textPosition: 'center-center',
    maxTime: -1,
    minTime: 300,
};

defineLocale('th-be', thBeLocale);

@NgModule({
    declarations: [AppComponent, LoginPageComponent, LoadingPageComponent, MainThemeComponent],
    imports: [
        BrowserModule.withServerTransition({ appId: 'serverApp' }),
        AppRoutingModule,
        HttpClientModule,
        FormsModule,
        ReactiveFormsModule,

        UserStoreModule,
        ProductStoreModule,
        UtilityStoreModule,
        ReportStoreModule,
        SettingStoreModule,
        OrderStoreModule,
        CampaignStoreModule,

        EffectsModule.forRoot(),
        StoreModule.forRoot(reducers, {
            metaReducers,
            runtimeChecks: {
                strictStateImmutability: false,
                strictActionImmutability: false,
            },
        }),
        !environment.production ? StoreDevtoolsModule.instrument() : [],
        BrowserAnimationsModule,
        CollapseModule.forRoot(),

        ReportModule,
        OrderModule,
        NgxUiLoaderModule.forRoot(ngxLoaderConfig),
    ],
    providers: [
        AuthGuardService,
        {
            provide: APP_BASE_HREF,
            useFactory: (tokenService: TokenService) => '/' + tokenService.getAgencySlug(),
            deps: [TokenService],
        },
    ],
    bootstrap: [AppComponent],
})
export class AppModule {}
