<div class="tabs-container">
    <div class="tabs">
        <button
            class="tab"
            [class.active]="activeTab === BOOKING_LIST_TABS.BOOKING_LIST"
            (click)="setActiveTab(BOOKING_LIST_TABS.BOOKING_LIST)"
        >
            Booking List
        </button>
        <button
            class="tab"
            [class.active]="activeTab === BOOKING_LIST_TABS.SALES_REVENUE"
            (click)="setActiveTab(BOOKING_LIST_TABS.SALES_REVENUE)"
        >
            รายงานยอดขาย
        </button>
        <button
            class="tab"
            [class.active]="activeTab === BOOKING_LIST_TABS.GROUP_PLAN"
            (click)="setActiveTab(BOOKING_LIST_TABS.GROUP_PLAN)"
        >
            Plan ออกกรุ๊ป
        </button>
        <button
            class="tab"
            [class.disabled]="!agencyInfo.displayCommission"
            [class.active]="activeTab === BOOKING_LIST_TABS.COMMISSION_REPORT"
            (click)="setActiveTab(BOOKING_LIST_TABS.COMMISSION_REPORT)"
        >
            รายงานค่าคอม
        </button>
    </div>

    <div class="shortcut-filters" *ngIf="!hideFilter">
        <div *ngIf="activeTab === BOOKING_LIST_TABS.BOOKING_LIST">
            <div class="first-row">
                <label>ลูกค้าจองเมื่อ</label>
                <app-searchable-dropdown
                    #dateRangeDropdown
                    [settings]="dateRangeDropdownSettings"
                    [items]="dateRangeDropdownItems"
                    (selected)="onSelectDateRange($event)"
                    id="searchable-filter-booking"
                >
                </app-searchable-dropdown>
                <p class="cursor-pointer" (click)="resetStatusFilter()">
                    <u>มีทั้งหมด {{ bookingStatusCount?.total }} Booking</u>
                </p>
            </div>
            <div class="second-row">
                <a class="icon-booking-payment" (click)="onSelectBookingStatus(STATUS_CODES.WAITING_FOR_FIRST_PAYMENT)">
                    {{ STATUS_KEYWORDS.WAITING_FOR_FIRST_PAYMENT }} ({{ bookingStatusCount?.waitingFirstPaid }})
                </a>
                <a class="icon-booking-payment" (click)="onSelectBookingStatus(STATUS_CODES.WAITING_FOR_FULL_PAYMENT)">
                    {{ STATUS_KEYWORDS.WAITING_FOR_FULL_PAYMENT }} ({{ bookingStatusCount?.waitingFullPaid }})
                </a>
                <a class="icon-booking-bag-travel" (click)="onSelectBookingStatus(STATUS_CODES.COMPLETE, 1)">
                    {{ STATUS_KEYWORDS.WAITING_TO_TRAVEL }} ({{ bookingStatusCount?.completeNotStarted }})
                </a>
                <a class="icon-booking-travel" (click)="onSelectBookingStatus(STATUS_CODES.COMPLETE, 2)">
                    {{ STATUS_KEYWORDS.TRAVELLED }} ({{ bookingStatusCount?.completeStarted }})
                </a>
                <a class="icon-booking-cancel" (click)="onSelectBookingStatus(STATUS_CODES.CANCEL)">
                    {{ STATUS_KEYWORDS.CANCELLED }} ({{ bookingStatusCount?.cancel }})
                </a>
            </div>
        </div>
        <div *ngIf="activeTab === BOOKING_LIST_TABS.SALES_REVENUE">
            <app-sale-report></app-sale-report>
        </div>
        <div *ngIf="activeTab === BOOKING_LIST_TABS.GROUP_PLAN">
            <app-group-plan-filter></app-group-plan-filter>
        </div>
        <div *ngIf="activeTab === BOOKING_LIST_TABS.COMMISSION_REPORT">
            <app-commission-report-filter></app-commission-report-filter>
        </div>
    </div>
</div>
