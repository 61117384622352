<div class="search-box">
    <form class="mt-3" [formGroup]="searchForm">
        <div class="row form-group">
            <div class="col mb-2">
                <label id="headline">ตัวช่วยค้นหา</label>
                <div class="position-relative">
                    <i class="icon icon-search position-absolute"></i>
                    <input
                        type="text"
                        formControlName="search_string"
                        class="form-control search-string-input"
                        placeholder="ค้นหาจาก ชื่อลูกค้า, เบอร์โทรศัพท์, รหัส Order, ชื่อโปรแกรมทัวร์"
                    />
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-6" formGroupName="created_at_between">
                <div class="form-group d-flex align-items-baseline">
                    <label style="width: 78px" class="label-form horizontal-form">เลือกวันที่จอง</label>
                    <div class="position-relative">
                        <app-calendar-input
                            placeholder="เลือกวันที่"
                            readonly
                            [ngStyle]="{ 'background-color': '#fff' }"
                            [bsValue]="searchForm.get('created_at_between.min_date').value"
                            (bsValueChange)="searchForm.get('created_at_between.min_date').setValue($event)"
                            [maxDate]="searchForm.get('created_at_between.max_date').value"
                        ></app-calendar-input>
                    </div>
                    <label class="label-form horizontal-form ml-3">ถึงวันที่</label>
                    <div class="position-relative">
                        <app-calendar-input
                            placeholder="เลือกวันที่"
                            readonly
                            [ngStyle]="{ 'background-color': '#fff' }"
                            [bsValue]="searchForm.get('created_at_between.max_date').value"
                            (bsValueChange)="searchForm.get('created_at_between.max_date').setValue($event)"
                            [minDate]="searchForm.get('created_at_between.min_date').value"
                        ></app-calendar-input>
                    </div>
                </div>
            </div>
            <div class="col-6" formGroupName="due_date_at_between">
                <div class="form-group d-flex align-items-baseline justify-content-end">
                    <label class="label-form horizontal-form">วันที่ครบกำหนด ชำระเงิน (ลูกค้า)</label>
                    <div class="position-relative">
                        <app-calendar-input
                            placeholder="เลือกวันที่"
                            readonly
                            [ngStyle]="{ 'background-color': '#fff' }"
                            [bsValue]="searchForm.get('due_date_at_between.min_date').value"
                            (bsValueChange)="searchForm.get('due_date_at_between.min_date').setValue($event)"
                            [maxDate]="searchForm.get('due_date_at_between.max_date').value"
                        ></app-calendar-input>
                    </div>
                    <label class="label-form horizontal-form ml-3">ถึงวันที่</label>
                    <div class="position-relative">
                        <app-calendar-input
                            placeholder="เลือกวันที่"
                            readonly
                            [ngStyle]="{ 'background-color': '#fff' }"
                            [bsValue]="searchForm.get('due_date_at_between.max_date').value"
                            (bsValueChange)="searchForm.get('due_date_at_between.max_date').setValue($event)"
                            [minDate]="searchForm.get('due_date_at_between.min_date').value"
                        ></app-calendar-input>
                    </div>
                </div>
            </div>
        </div>

        <div class="form-group d-flex">
            <label style="width: 115px; padding-top: 1em">สถานะ Order</label>
            <div class="status-box" style="width: 100%">
                <div class="row">
                    <div class="col-3" *ngFor="let item of orderStatusList; let i = index">
                        <div class="form-check">
                            <input
                                class="form-check-input"
                                type="checkbox"
                                [checked]="item.checked"
                                [value]="item.value"
                                (change)="onCheckboxChange($event, item)"
                            />
                            <span class="form-check-label"> {{ item.name }} </span>
                        </div>
                    </div>
                </div>
            </div>
            <button
                style="width: 150px; margin-left: 1em; margin-right: 1em"
                class="btn btn-primary"
                (click)="onSubmit()"
            >
                ค้นหา
            </button>
            <span style="width: 60px; padding-top: 7px" class="cursor-pointer reset-btn" (click)="onReset()"
                >เริ่มใหม่</span
            >
        </div>
    </form>
</div>
