import { ISupplier } from '../interfaces/tw-booking-interface/supplier.interface';
import { Config } from './config.model';

export class Supplier {
    id: number;
    nameEn: string;
    nameTh: string;
    tourismLicense: string;
    phoneNumber: string;
    additionPhoneNumber: string;
    email: string;
    addresses: string;
    config: Config;

    public deserialize(data: ISupplier): Supplier {
        this.id = data.id;
        this.nameEn = data.name_en;
        this.nameTh = data.name_th;
        this.tourismLicense = data.tourism_license;
        this.phoneNumber = data.phone_number;
        this.additionPhoneNumber = data.addition_phone_number;
        this.email = data.email;
        this.addresses = data.addresses;
        this.config = data.config ? new Config().deserialize(data.config) : null;
        return this;
    }
}
