import { SEO_ARTICLE_TYPES } from 'src/app/constants/seo-article-types.enum';
import { Country } from 'src/app/models/country';
import { CountrySubUnit } from 'src/app/models/country-sub-unit.model';
import { ProductSubCategory } from 'src/app/models/product-sub-category.model';
import { Province } from 'src/app/models/province';
import { IGetSeoArticle } from './get-seo-article.model';
import { ParentArticle } from './parent-article.model';
import { SeoArticleType } from './seo-article-type.model';
import { TargetPageType } from './target-page-type.model';

export interface ISeoArticle {
    id: number;
    code: string;
    name: string;
    urlPath: string;

    metaTitle: string;
    metaDescription: string;

    primaryKeyword: string;
    secondaryKeyword: string;
    relatedKeyword: string;

    note: string;

    articleType: SeoArticleType;
    targetPageType: TargetPageType;
    isChildOfHomepage: boolean;
    parentArticle: ParentArticle;
    country: Country | null;
    countrySubUnit: CountrySubUnit | null;
    province: Province | null;
    productSubCategory: ProductSubCategory | null;
    specialPageId: number | null;

    desktopCoverImageFileUrl: string | null;
    mobileCoverImageFileUrl: string | null;
    coverImageAlternateText: string | null;
    content: string | null;
    tags: string[];

    isActive: boolean;

    updatedAt: Date;
    updatedByAgencyMemberId: number;
    updatedByAgencyMemberFirstName: string;
    updatedByAgencyMemberLastName: string;
}

export class SeoArticle implements ISeoArticle {
    public id: number;
    public code: string;
    public name: string;
    public urlPath: string;
    public metaTitle: string;
    public metaDescription: string;
    public primaryKeyword: string;
    public secondaryKeyword: string;
    public relatedKeyword: string;
    public note: string;

    public articleType: SeoArticleType;
    public targetPageType: TargetPageType;
    public isChildOfHomepage: boolean;
    public parentArticle: ParentArticle;
    public country: Country;
    public countrySubUnit: CountrySubUnit;
    public province: Province;
    public productSubCategory: ProductSubCategory;
    public specialPageId: number;
    public desktopCoverImageFileUrl: string;
    public mobileCoverImageFileUrl: string;
    public coverImageAlternateText: string;
    public content: string;
    public tags: string[];

    public isActive: boolean;

    public updatedAt: Date;
    public updatedByAgencyMemberId: number;
    public updatedByAgencyMemberFirstName: string;
    public updatedByAgencyMemberLastName: string;

    public deserialize(article: IGetSeoArticle): this {
        const data = article.data;

        this.id = data.id;
        this.code = data.code;
        this.name = data.name;
        this.urlPath = data.url_path;
        this.metaTitle = data.meta_title;
        this.metaDescription = data.meta_description;
        this.primaryKeyword = data.primary_keyword;
        this.secondaryKeyword = data.secondary_keyword;
        this.relatedKeyword = data.related_keyword;
        this.note = data.note;

        this.articleType = new SeoArticleType().deserialize(data.article_type);
        this.targetPageType = new TargetPageType().deserialize(data.target_page_type);
        this.country = new Country().deserialize(data.country);
        this.province = new Province().deserialize(data.province);
        this.countrySubUnit = new CountrySubUnit().deserialize(data.country_sub_unit);

        this.productSubCategory = new ProductSubCategory().deserialize(data.product_sub_category);
        this.parentArticle = new ParentArticle().deserialize(data.parent_article);

        this.isChildOfHomepage = data.is_child_of_home_page;
        this.specialPageId = data.special_page ? data.special_page.id : null;
        this.desktopCoverImageFileUrl = data.desktop_cover_image_file_url;
        this.mobileCoverImageFileUrl = data.mobile_cover_image_file_url;
        this.coverImageAlternateText = data.cover_image_alt_text;
        this.content = data.content;
        this.tags = data.tags;

        this.isActive = data.is_active;

        this.updatedAt = new Date(data.updated_at);
        this.updatedByAgencyMemberId = data.updated_by_agency_member ? +data.updated_by_agency_member.id : null;
        this.updatedByAgencyMemberFirstName = data.updated_by_agency_member
            ? data.updated_by_agency_member.first_name
            : null;
        this.updatedByAgencyMemberLastName = data.updated_by_agency_member
            ? data.updated_by_agency_member.last_name
            : null;
        return this;
    }
}
