<div class="order-balance-transaction">
    <div class="order-balance-report-title">
        <app-breadcrumb [items]="breadcrumb"></app-breadcrumb>
        <h2 class="text-primary mb-4">
            <img src="assets/img/icon/icon-coupon-orange.svg" style="width: 45px" /> Order Balancing Report
        </h2>
    </div>

    <div class="order-balance-report-filter">
        <app-order-balance-report-filter
            [(yearNumbersView)]="yearNumbersView"
            [(yearlyPeriodsView)]="yearlyPeriodsView"
            (selectedPeriod)="selectedPeriodFromFilter($event)"
            (applySearch)="applySearchFromFilter()"
        ></app-order-balance-report-filter>
    </div>

    <div class="order-balance-report-tab">
        <app-order-balance-report-tab
            [(periodInfo)]="periodInfoView"
            (onChangeTab)="changeTab($event)"
            (onChangeSortOrder)="changeSortOrder($event)"
        ></app-order-balance-report-tab>
    </div>

    <div class="order-balance-report-listing" *ngIf="showListingTab">
        <app-order-balance-report-listing
            [reportView]="reportView"
            (requestMoreReports)="loadMoreReports()"
        ></app-order-balance-report-listing>
    </div>

    <div class="order-balance-report-receipt" *ngIf="showReceiptTab">
        <app-order-balance-report-receipt
            [orderBalanceId]="periodInfoView?.orderBalance.id"
            [reportView]="reportOverViewItem"
            *ngFor="let reportOverViewItem of reportOverView?.data"
        ></app-order-balance-report-receipt>

        <div class="text-center show-more-results" [hidden]="reportOverView?.total <= reportView?.data?.length">
            <button class="btn btn-show-more" (click)="loadMoreReports()">
                แสดงรายการที่ {{ reportOverView?.data?.length + 1 }} - {{ totalEndReportsOverview }}
            </button>
        </div>
    </div>
</div>
