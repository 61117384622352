export interface IUser {
    id: number;
    firstName: string;
    lastName: string;
    nickName: string;
    nameString: string;
}

export class UsersList {
    private users: IUser[] = [];

    public deserialize(obj: any): IUser[] {
        if (Array.isArray(obj.users)) {
            obj.users.map((user: any) => {
                this.addUserToUsersList(user);
            });
        } else {
            this.addUserToUsersList(obj.users);
        }

        return this.users;
    }

    private addUserToUsersList(user: any): void {
        this.users.push({
            id: user.id,
            firstName: user.first_name || null,
            lastName: user.last_name || null,
            nickName: user.nick_name || null,
            nameString: this.getNameString(user),
        });
    }

    private getNameString(user: any): string {
        let nameString = '';
        if (user.first_name) {
            nameString += user.first_name + ' ';
        }
        if (user.last_name) {
            nameString += user.last_name + ' ';
        }
        if (user.nick_name) {
            nameString += '(' + user.nick_name + ')';
        }

        return nameString;
    }
}
