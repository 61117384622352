import { NavigationEnd, Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';

@Component({
    selector: 'app-lead-contact-info',
    templateUrl: './lead-contact-info.component.html',
    styleUrls: ['./lead-contact-info.component.scss'],
})
export class LeadContactInfoComponent implements OnInit {
    public customerName: string;
    public phone: string;
    public email: string;
    public more_info: string;

    constructor(public bsModalRef: BsModalRef, private router: Router) {}

    ngOnInit() {
        this.closeOnRouteChange();
    }

    closeOnRouteChange(): void {
        this.router.events.subscribe((evt) => {
            if (!(evt instanceof NavigationEnd)) {
                return;
            }
            this.bsModalRef.hide();
        });
    }
}
