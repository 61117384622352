export interface ISearchFilter {
    contact_name: string;
    tour_code: string;
    lead_type_slug: string;
    lead_status_slugs: string[];
    contact_date_range: {
        min_date: string;
        max_date: string;
    };
    agency_id: number;
    country_ids: number[];
    b2b_lead_status_slugs: string[];
    created_at_date_range: {
        min_date: string;
        max_date: string;
    };
    b2b_lead_created_at_date_range: {
        min_date: string;
        max_date: string;
    };
}

export interface IProgressStatus {
    all: boolean;
    waiting_contact: boolean;
    waiting_dicision: boolean;
    pending: boolean;
    confirm: boolean;
    booked: boolean;
    cancel: boolean;
}

export class SearchFilter {
    public initialiseSearchFilter(): ISearchFilter {
        return {
            contact_name: null,
            tour_code: null,
            contact_date_range: {
                min_date: null,
                max_date: null,
            },
            lead_type_slug: null,
            lead_status_slugs: null,
            agency_id: null,
            country_ids: null,
            b2b_lead_status_slugs: null,
            created_at_date_range: {
                min_date: null,
                max_date: null,
            },
            b2b_lead_created_at_date_range: {
                min_date: null,
                max_date: null,
            },
        };
    }
}
