import { Injectable, Inject, PLATFORM_ID } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';

@Injectable({
    providedIn: 'root',
})
export class TokenService {
    public localStorageTokenKey = 'token';

    constructor(@Inject(PLATFORM_ID) protected platformId: Object) {}

    public getAgencySlug(): string {
        let slug = '';
        if (isPlatformBrowser(this.platformId)) {
            const token = localStorage.getItem(this.localStorageTokenKey);
            if (token) {
                const data = JSON.parse(atob(token.split('.')[1]));
                const user = JSON.parse(atob(data.agcy));

                slug = user.agency.slug;
            }
        }

        return slug;
    }
}
