<div class="table-list-body-inner d-flex flex-rows border">
    <div class="d-flex flex-rows p-2" style="width: 20%">
        <div style="width: 12%">
            <span>{{runNumber}}.</span>
        </div>
        <div class="d-flex flex-column">
            <strong class="mb-1">ลูกค้า : {{item?.order?.contactName}}</strong>
            <span class="mb-1 text-sm">Tel : {{item?.order?.phoneNumber | phoneNumber}}</span>
            <span class="mb-1 text-sm">วันที่ซื้อ : {{item?.order?.createdAt | thaiMonthDateTime: true}}</span>
            <span class="mb-1 text-xxs">(Order No. {{item?.order?.orderCode}})</span>
        </div>
    </div>
    <div class="d-flex flex-rows p-2" style="width: 40%">
        <div class="d-flex flex-column">
            <strong class="mb-1">{{item?.voucher?.name}}</strong>
            <span class="mb-1">ราคา {{item?.voucher?.price | number}}/เวาเชอร์ | คอม {{item?.voucher?.commissionAgency | number}}/เวาเชอร์</span>
            <span class="mb-1">รวม {{item?.itemQuantity | number}} เวาเชอร์ ยอดรวม {{item?.amount | number}}</span>
            <span class="mb-1 cursor-pointer" (click)="showPartnerModal()">
                <u class="mr-2 text-xs">Seller : ร้าน {{item?.campaign?.shopName}} โทร.{{item?.campaign?.officeTelephoneNumber | phoneNumber}}</u>
                <i class="icon-info"></i>
            </span>
        </div>
    </div>
    <div class="d-flex justify-content-center p-2" style="width: 10%">
        <strong>{{item?.commissionAgency | number}}</strong>
    </div>
    <div class="d-flex justify-content-center p-2" style="width: 10%">
        <strong *ngIf="item?.commissionAgencyVat === null">-</strong>
        <strong *ngIf="item?.commissionAgencyVat !== null">{{item?.commissionAgencyVat | number}}</strong>
    </div>
    <div class="d-flex justify-content-center p-2" style="width: 10%">
        <strong *ngIf="item?.commissionAgencyWhTax === null">-</strong>
        <strong *ngIf="item?.commissionAgencyWhTax !== null">{{item?.commissionAgencyWhTax | number}}</strong>
    </div>
    <div class="d-flex justify-content-center p-2" style="width: 10%">
        <strong class="text-blue">{{item?.netCommissionAgency | number}}</strong>
    </div>
</div>