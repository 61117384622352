<div class="modal-header" *ngIf="hasCloseButton">
    <button type="button" class="close pull-right" aria-label="Close" (click)="hide()">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body text-center">
    <div class="header">
        <div class="bg-icons_modal"></div>
        <strong class="modal-title">{{ message }}</strong>
    </div>

    <button class="btn btn-success mt-4" (click)="hide()">ปิด</button>
</div>
