import { Agency } from 'src/app/models/agency';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { State } from 'src/app/stores/reducers';
import * as OrderActions from 'src/app/stores/order-store/order.actions';
import { selectOrderCommissionSummaries } from 'src/app/stores/order-store/order.selectors';
import { OrderCommissionSummary } from 'src/app/models/order-summary.model';
import { ICommissionReportListQueries } from '../../models/commission-report-list-queries';
import { selectAgencyInfo } from 'src/app/stores/user-store/user.selectors';
import { filter, takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

@Component({
    selector: 'app-commission-report-filter',
    templateUrl: './commission-report-filter.component.html',
    styleUrls: ['./commission-report-filter.component.scss'],
})
export class CommissionReportFilterComponent implements OnInit, OnDestroy {
    public agencyInfo: Agency;
    public orderSummaries: any[];
    private unsubscribe$: Subject<void> = new Subject<void>();

    constructor(private store: Store<State>) {}

    ngOnInit(): void {
        this.store
            .pipe(
                select(selectAgencyInfo),
                filter((agencyInfo) => !!agencyInfo),
                takeUntil(this.unsubscribe$)
            )
            .subscribe((agencyInfo) => (this.agencyInfo = agencyInfo));

        this.dispatchOrderSummaries();
        this.store
            .pipe(
                select(selectOrderCommissionSummaries),
                filter((data) => data !== null),
                takeUntil(this.unsubscribe$)
            )
            .subscribe((orderSummaries: OrderCommissionSummary[]) => {
                this.orderSummaries = orderSummaries.map((item: OrderCommissionSummary) => {
                    return {
                        active: false,
                        ...item,
                    };
                });

                this.onSelectCommission(this.orderSummaries[0]);
            });
    }

    public onSelectCommission(item) {
        this.orderSummaries = this.orderSummaries.map((m) => {
            m.active = false;
            return m;
        });
        item.active = true;

        this.store.dispatch(
            OrderActions.selectOrderCommissionSummaries({
                orderCommissionSummarySelect: {
                    orderCount: item.orderCount,
                    dateRange: item.dateRange,
                },
            })
        );

        const commissionReportListQueries: ICommissionReportListQueries = {
            filters: {
                created_at_between: {
                    min_date: item.dateRange.minDate,
                    max_date: item.dateRange.maxDate,
                },
                order_statuses: [
                    { status_code: 11, already_started: null },
                    { status_code: 21, already_started: null },
                    { status_code: 22, already_started: null },
                    { status_code: 31, already_started: false },
                    { status_code: 31, already_started: true },
                ],
            },
            criteria: {
                page: 1,
                pageEntries: 50,
            },
        };

        this.store.dispatch(
            OrderActions.getCommissionReportListPaginated({
                commissionReportListQueries: commissionReportListQueries,
                isNextPage: false,
            })
        );
    }

    private dispatchOrderSummaries(): void {
        this.store.dispatch(
            OrderActions.getOrderCommissionSummaries({
                criteria: {
                    interval_direction: 'backward',
                    interval_limit: 6,
                    interval_type: 'month',
                },
            })
        );
    }

    ngOnDestroy(): void {
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
    }
}
