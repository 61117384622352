import { createAction, props } from '@ngrx/store';
import { CampaignHilight } from 'src/app/models/campaigns/campaign-hilight.model';
import { ChaophrayaCruiseBookingView, IChaophrayaCruiseBooking } from 'src/app/models/chaophraya-cruise-booking.model';
import { ICampaign, ICampaignPaginated, ICampaignPaginatedFilter } from 'src/app/modules/product/models/campaign-model';

export const loadCampaigns = createAction('[Campaign] Load campaigns');
export const loadCampaignsSuccess = createAction(
    '[Campaign] Load campaigns success',
    props<{ campaigns: ICampaign[] }>()
);
export const loadCampaignsError = createAction('[Campaign] Load campaigns failed');

export const loadCampaignPaginated = createAction(
    '[Campaign] Load campaigns paginated',
    props<{ currentPage: number; itemPerPage: number; filter: ICampaignPaginatedFilter }>()
);
export const loadCampaignPaginatedSuccess = createAction(
    '[Campaign] Load campaigns paginated success',
    props<{ campaignPaginated: ICampaignPaginated }>()
);
export const loadCampaignPaginatedError = createAction('[Campaign] Load campaigns paginated failed');

export const sendEmailCampaignToContact = createAction(
    '[Campaign] Send email to contact.',
    props<{ paymentId: number }>()
);
export const sendEmailCampaignToContactSuccess = createAction('[Campaign] Send email to contact success.');

export const loadCampaignHilight = createAction('[Campaign] Load campaign hilight.', props<{ campaignId: number }>());
export const loadCampaignHilightSuccess = createAction(
    '[Campaign] Load campaign hilight success',
    props<{ hilight: CampaignHilight }>()
);
export const loadCampaignHilightError = createAction('[Campaign] Load campaign hilight failed');

export const postChaophrayaCruiseBooking = createAction(
    '[Campaign] Post chaophraya cruise booking.',
    props<{ model: IChaophrayaCruiseBooking }>()
);
export const postChaophrayaCruiseBookingSuccess = createAction('[Campaign] Post chaophraya cruise booking success.');

export const loadChaophrayaCruiseBooking = createAction(
    '[Campaign] Load chaophraya cruise booking.',
    props<{ bookingId: number }>()
);
export const loadChaophrayaCruiseBookingSuccess = createAction(
    '[Campaign] Load chaophraya cruise booking success.',
    props<{ model: ChaophrayaCruiseBookingView }>()
);
export const loadChaophrayaCruiseBookingError = createAction('[Campaign] Load chaophraya cruise booking failed.');
