export class ParentArticle {
    id: number;
    code: string;
    name: string;

    public deserialize(data: any): this | null {
        if (!data) {
            return null;
        }

        this.id = +data.id;
        this.code = data.code;
        this.name = data.name;

        return this;
    }
}
