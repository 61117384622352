import { NavigationEnd, Router } from '@angular/router';
import { IAddLeadBookingRequest } from '../../../models/add-lead-request.model';
import { User } from '../../../../../models/user';
import { selectUser } from '../../../../../stores/user-store/user.selectors';
import { ILeadDetails } from '../../../models/lead-details.model';
import { Component, OnInit } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { Store } from '@ngrx/store';
import { State } from 'src/app/stores/reducers';
import { UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';
import { selectAgencyInfo } from 'src/app/stores/user-store/user.selectors';
import { Agency } from 'src/app/models/agency';
import * as ReportActions from 'src/app/stores/report-store/report.actions';
import { Observable } from 'rxjs';

@Component({
    selector: 'app-pass-booking-to-owner',
    templateUrl: './pass-booking-to-owner.component.html',
    styleUrls: ['./pass-booking-to-owner.component.scss'],
})
export class PassBookingToOwnerComponent implements OnInit {
    public lead: ILeadDetails;
    public period: string;
    public requiredPassengerField: boolean = false;
    public agencyInfo: Agency;
    public user: User;

    public agencyInfo$: Observable<Agency>;
    public form: UntypedFormGroup;

    constructor(
        public bsModalRef: BsModalRef,
        public bsModalService: BsModalService,
        private store: Store<State>,
        private router: Router
    ) {}

    ngOnInit() {
        this.agencyInfo$ = this.store.select(selectAgencyInfo);
        this.bsModalService.setDismissReason('cancel');
        this.closeOnRouteChange();

        this.store.select(selectAgencyInfo).subscribe((agency) => (this.agencyInfo = agency));

        this.store.select(selectUser).subscribe((user) => (this.user = user));

        this.form = new UntypedFormGroup({
            passengerNo: new UntypedFormControl('', [
                Validators.required,
                Validators.pattern('^[0-9]*$'),
                Validators.min(1),
            ]),
            additionalInfo: new UntypedFormControl(),
        });

        if (this.lead.number_of_passengers) {
            this.form.patchValue({
                passengerNo: this.lead.number_of_passengers,
            });
        }
    }

    public submitButton(): void {
        if (!this.form.get('passengerNo').errors) {
            this.requiredPassengerField = false;
            this.bsModalService.setDismissReason(this.lead.customerDetails.id + '-submit-booking');

            const requestBody: IAddLeadBookingRequest = {
                b2c_lead_id: this.lead.customerDetails.id,
                number_of_passengers: this.form.get('passengerNo').value,
            };

            if (this.form.get('additionalInfo').value) {
                requestBody.more_info_for_ws = this.form.get('additionalInfo').value;
            }

            this.store.dispatch(
                ReportActions.addBookingRequestLead({
                    requestBody,
                })
            );

            this.store.dispatch(
                ReportActions.editExistingLead({
                    leadId: this.lead.customerDetails.id,
                    requestBody: {
                        contact_name: this.lead.customerDetails.contact_name,
                        phone_number: this.lead.customerDetails.phone_number,
                        email: this.lead.customerDetails.email,
                        more_info: this.lead.customerDetails.more_info,
                        lead_type_slug: this.lead.lead_type_slug,
                        tour_code: this.lead.tour_code,
                        pool_code: this.lead.pool_code,
                        period_id: this.lead.product_period_id,
                        number_of_passengers: this.form.get('passengerNo').value,
                        more_info_for_ws: this.form.get('additionalInfo').value,
                    },
                    refresh: false,
                })
            );

            this.store.dispatch(
                ReportActions.updateLeadStatus({
                    id: this.lead.customerDetails.id,
                    statusSlug: 'confirm',
                })
            );

            this.bsModalRef.hide();
        } else {
            this.requiredPassengerField = true;
        }
    }

    closeOnRouteChange(): void {
        this.router.events.subscribe((evt) => {
            if (!(evt instanceof NavigationEnd)) {
                return;
            }
            this.bsModalRef.hide();
        });
    }
}
