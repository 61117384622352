import { ILeadReportDownloadSearch } from './../modules/report/models/lead-report-download-search.model';
import { Injectable, Inject, PLATFORM_ID } from '@angular/core';
import { HttpService } from './base/http.service';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Router } from '@angular/router';
import { Observable, of, throwError } from 'rxjs';
import { environment } from 'src/environments/environment';
import { switchMap, map } from 'rxjs/operators';
import { ENDPOINTS } from '../constants/endpoint.constant';
import { ISearchFilter } from '../modules/report/models/search-filter.model';
import { IAddLeadRequest, IAddLeadBookingRequest } from '../modules/report/models/add-lead-request.model';
import { IOrderBalanceReportCriteria } from '../modules/report/models/order-balance-report-criteria.model';

@Injectable({
    providedIn: 'root',
})
export class ReportService extends HttpService {
    constructor(
        @Inject(PLATFORM_ID) protected platformId: Object,
        protected http: HttpClient,
        protected router: Router
    ) {
        super(platformId, http, router);
    }

    public getLeadList(criteria: any, searchFilter?: ISearchFilter): Observable<any> {
        let params = new HttpParams();
        params = params.append('page', criteria.page);
        params = params.append('page_entries', criteria.pageEntries);

        if (searchFilter) {
            const $json = JSON.stringify(searchFilter);
            params = params.append('filters', $json);
        }

        const url = environment.apiProUrl + ENDPOINTS.REPORT.LEAD_LIST;

        return this.getRequestOptions(true).pipe(
            switchMap((headers) => {
                const options = {
                    headers: headers.headers,
                    params,
                };

                return this.http.get(url, options).pipe(
                    map((resp: any) => {
                        if (resp.status !== 'success') {
                            throwError(resp);
                        }
                        return resp;
                    })
                );
            })
        );
    }

    public addLead(body: IAddLeadRequest): Observable<any> {
        const url = environment.apiProUrl + ENDPOINTS.REPORT.ADD_LEAD;
        return this.getRequestOptions(true).pipe(
            switchMap((headers) => {
                const options = {
                    headers: headers.headers,
                };
                return this.http.post(url, body, options);
            })
        );
    }

    public editLead(leadId: number, body: IAddLeadRequest): Observable<any> {
        const url = environment.apiProUrl + ENDPOINTS.REPORT.EDIT_LEAD.replace('{lead_id}', leadId);
        return this.getRequestOptions(true).pipe(
            switchMap((headers) => {
                const options = {
                    headers: headers.headers,
                };
                return this.http.put(url, body, options);
            })
        );
    }

    public getLeadCount(statusSlug: string): Observable<any> {
        let params = new HttpParams();

        if (statusSlug) {
            const statusSlugObj = {
                lead_status_slug: statusSlug,
            };
            const $json = JSON.stringify(statusSlugObj);
            params = params.append('filters', $json);
        }

        const url = environment.apiProUrl + ENDPOINTS.REPORT.LEAD_COUNT;

        return this.getRequestOptions(true).pipe(
            switchMap((headers) => {
                const options = {
                    headers: headers.headers,
                    params,
                };

                return this.http.get(url, options);
            })
        );
    }

    public getLeadCountByType(searchFilter: ISearchFilter): Observable<any> {
        let params = new HttpParams();

        if (searchFilter) {
            const $json = JSON.stringify(searchFilter);
            params = params.append('filters', $json);
        }

        const url = environment.apiProUrl + ENDPOINTS.REPORT.LEAD_COUNT_BY_TYPE;

        return this.getRequestOptions(true).pipe(
            switchMap((headers) => {
                const options = {
                    headers: headers.headers,
                    params,
                };

                return this.http.get(url, options);
            })
        );
    }

    public updateLeadStatus(leadId: number, statusSlug: string): Observable<any> {
        const url = environment.apiProUrl + ENDPOINTS.REPORT.UPDATE_LEAD_STATUS.replace('{lead_id}', leadId);

        const body = {
            lead_status_slug: statusSlug,
        };

        return this.getRequestOptions(true).pipe(
            switchMap((headers) => {
                const options = {
                    headers: headers.headers,
                };
                return this.http.put(url, body, options);
            })
        );
    }

    public getBookingRequestLeadList(criteria: any, searchFilter?: ISearchFilter): Observable<any> {
        let params = new HttpParams();
        params = params.append('page', criteria.page);
        params = params.append('page_entries', criteria.pageEntries);

        if (searchFilter) {
            const $json = JSON.stringify(searchFilter);
            params = params.append('filters', $json);
        }

        const url = environment.apiProUrl + ENDPOINTS.REPORT.BOOKING_REQUEST_LEAD_LIST;

        return this.getRequestOptions(true).pipe(
            switchMap((headers) => {
                const options = {
                    headers: headers.headers,
                    params,
                };

                return this.http.get(url, options).pipe(
                    map((resp: any) => {
                        if (resp.status !== 'success') {
                            throwError(resp);
                        }
                        return resp;
                    })
                );
            })
        );
    }

    public getLeadBookingRequestCount(statusSlug: string): Observable<any> {
        let params = new HttpParams();

        if (statusSlug) {
            const statusSlugObj = {
                b2b_lead_status_slug: statusSlug,
            };
            const $json = JSON.stringify(statusSlugObj);
            params = params.append('filters', $json);
        }

        const url = environment.apiProUrl + ENDPOINTS.REPORT.LEAD_BOOKING_REQUEST_COUNT;

        return this.getRequestOptions(true).pipe(
            switchMap((headers) => {
                const options = {
                    headers: headers.headers,
                    params,
                };

                return this.http.get(url, options);
            })
        );
    }

    public updateBookingRequestLeadStatus(leadId: number, statusSlug: string): Observable<any> {
        const url =
            environment.apiProUrl + ENDPOINTS.REPORT.UPDATE_BOOKING_REQUEST_LEAD_STATUS.replace('{lead_id}', leadId);

        const body = {
            lead_status_slug: statusSlug,
        };

        return this.getRequestOptions(true).pipe(
            switchMap((headers) => {
                const options = {
                    headers: headers.headers,
                };
                return this.http.put(url, body, options);
            })
        );
    }

    public addBookingRequestLead(body: IAddLeadBookingRequest): Observable<any> {
        const url = environment.apiProUrl + ENDPOINTS.REPORT.ADD_BOOKING_REQUEST_LEAD;
        return this.getRequestOptions(true).pipe(
            switchMap((headers) => {
                const options = {
                    headers: headers.headers,
                };
                return this.http.post(url, body, options);
            })
        );
    }

    public getBookingRequestLeadInfo(leadId: number): Observable<any> {
        const url = environment.apiProUrl + ENDPOINTS.REPORT.BOOKING_REQUEST_LEAD_DETAILS.replace('{lead_id}', leadId);
        return this.getRequestOptions(true).pipe(
            switchMap((headers) => {
                const options = {
                    headers: headers.headers,
                };
                return this.http.get(url, options);
            })
        );
    }

    public getProductSummaryList(criteria: any, searchFilter?: ISearchFilter): Observable<any> {
        let params = new HttpParams();
        params = params.append('page', criteria.page);
        params = params.append('page_entries', criteria.pageEntries);

        if (searchFilter) {
            const $json = JSON.stringify(searchFilter);
            params = params.append('filters', $json);
        }

        const url = environment.apiProUrl + ENDPOINTS.REPORT.SUMMARY.PRODUCTS_LIST;
        return this.getRequestOptions(true).pipe(
            switchMap((headers) => {
                const options = {
                    headers: headers.headers,
                    params,
                };

                return this.http.get(url, options).pipe(
                    map((resp: any) => {
                        if (resp.status !== 'success') {
                            throwError(resp);
                        }
                        return resp;
                    })
                );
            })
        );
    }

    public getActiveProductsCountForSummary(searchFilter?: ISearchFilter): Observable<any> {
        let params = new HttpParams();

        if (searchFilter) {
            const $json = JSON.stringify(searchFilter);
            params = params.append('filters', $json);
        }

        const url = environment.apiProUrl + ENDPOINTS.REPORT.SUMMARY.LEAD_COUNT_FOR_ACTIVE_PRODUCTS;
        return this.getRequestOptions(true).pipe(
            switchMap((headers) => {
                const options = {
                    headers: headers.headers,
                    params,
                };

                return this.http.get(url, options).pipe(
                    map((resp: any) => {
                        if (resp.status !== 'success') {
                            throwError(resp);
                        }
                        return resp;
                    })
                );
            })
        );
    }

    public getStatisticsForProductSummary(idList: number[]): Observable<any> {
        let params = new HttpParams();

        if (idList) {
            const idListObj = {
                agcy_product_ids: idList,
            };
            const $json = JSON.stringify(idListObj);
            params = params.append('filters', $json);
        } else {
            return null;
        }

        const url = environment.apiProUrl + ENDPOINTS.REPORT.SUMMARY.STATISTICS_FOR_PRODUCT_LIST;

        return this.getRequestOptions(true).pipe(
            switchMap((headers) => {
                const options = {
                    headers: headers.headers,
                    params,
                };

                return this.http.get(url, options).pipe(
                    map((resp: any) => {
                        if (resp.status !== 'success') {
                            throwError(resp);
                        }
                        return resp;
                    })
                );
            })
        );
    }

    public getLeadStatMonthly(criteria: ILeadReportDownloadSearch): Observable<any> {
        let params = new HttpParams();
        if (criteria) {
            params = params.append('filters', JSON.stringify(criteria));
        }

        const url = environment.apiProUrl + ENDPOINTS.REPORT.LEAD_STAT_MONTHLY;

        return this.getRequestOptions(true).pipe(
            switchMap((headers) => {
                const options = {
                    headers: headers.headers,
                    params,
                };

                return this.http.get(url, options).pipe(
                    map((resp: any) => {
                        if (resp.status !== 'success') {
                            throwError(resp);
                        }
                        return resp;
                    })
                );
            })
        );
    }

    public getLeadStatMonthlyDetails(criteria: ILeadReportDownloadSearch): Observable<any> {
        let params = new HttpParams();
        if (criteria) {
            params = params.append('filters', JSON.stringify(criteria));
        }

        const url = environment.apiProUrl + ENDPOINTS.REPORT.LEAD_STAT_MONTHLY_DETAILS;

        return this.getRequestOptions(true).pipe(
            switchMap((headers) => {
                const options = {
                    headers: headers.headers,
                    params,
                };

                return this.http.get(url, options).pipe(
                    map((resp: any) => {
                        if (resp.status !== 'success') {
                            throwError(resp);
                        }
                        return resp;
                    })
                );
            })
        );
    }

    public getOrderBalancePeriod(): Observable<any> {
        const url = environment.apiProUrl + ENDPOINTS.REPORT.ORDER_BALANCE_PERIOD;
        return this.getRequestOptions(true).pipe(
            switchMap((headers) => {
                const options = {
                    headers: headers.headers,
                };

                return this.http.get(url, options).pipe(
                    map((resp: any) => {
                        if (resp.status !== 'success') {
                            throwError(resp);
                        }
                        return resp;
                    })
                );
            })
        );
    }

    public getOrderBalance(): Observable<any> {
        const url = environment.apiProUrl + ENDPOINTS.REPORT.ORDER_BALANCE;

        return this.getRequestOptions(true).pipe(
            switchMap((headers) => {
                const options = {
                    headers: headers.headers,
                };

                return this.http.get(url, options).pipe(
                    map((resp: any) => {
                        if (resp.status !== 'success') {
                            throwError(resp);
                        }
                        return resp;
                    })
                );
            })
        );
    }

    public getOrderBalanceReport(criteria: IOrderBalanceReportCriteria): Observable<any> {
        let params = new HttpParams();

        if (criteria.page) {
            params = params.append('page', criteria.page.toString());
        }
        if (criteria.pageEntries) {
            params = params.append('page_entries', criteria.pageEntries.toString());
        }
        if (criteria.orderBy) {
            params = params.append('order_by', criteria.orderBy);
        }

        const url =
            environment.apiProUrl + ENDPOINTS.REPORT.ORDER_BALANCE_REPORT.replace('{balance_id}', criteria.balanceId);

        return this.getRequestOptions(true).pipe(
            switchMap((headers) => {
                const options = {
                    headers: headers.headers,

                    params,
                };

                return this.http.get(url, options).pipe(
                    map((resp: any) => {
                        if (resp.status !== 'success') {
                            throwError(resp);
                        }
                        return resp;
                    })
                );
            })
        );
    }

    public confirmOrderBalance(orderId: number): Observable<any> {
        const url =
            environment.apiProUrl + ENDPOINTS.REPORT.ORDER_BALANCE_CONFIRM.replace('{order_balance_id}', orderId);

        return this.getRequestOptions(true).pipe(
            switchMap((headers) => {
                const options = {
                    headers: headers.headers,
                };

                return this.http.post(url, {}, options).pipe(
                    map((resp: any) => {
                        if (resp.status !== 'success') {
                            throwError(resp);
                        }
                        return resp;
                    })
                );
            })
        );
    }

    public getOrderBalanceReportItems(criteria: IOrderBalanceReportCriteria): Observable<any> {
        let params = new HttpParams();

        if (criteria.page) {
            params = params.append('page', criteria.page.toString());
        }
        if (criteria.pageEntries) {
            params = params.append('page_entries', criteria.pageEntries.toString());
        }
        if (criteria.orderBy) {
            params = params.append('order_by', criteria.orderBy);
        }

        const url =
            environment.apiProUrl +
            ENDPOINTS.REPORT.ORDER_BALANCE_REPORT_ITEMS.replace('{balance_id}', criteria.balanceId);

        return this.getRequestOptions(true).pipe(
            switchMap((headers) => {
                const options = {
                    headers: headers.headers,
                    params,
                };

                return this.http.get(url, options).pipe(
                    map((resp: any) => {
                        if (resp.status !== 'success') {
                            throwError(resp);
                        }
                        return resp;
                    })
                );
            })
        );
    }

    public getOrderBalanceCommissionReport(orderBalanceId: number): Observable<any> {
        const url =
            environment.apiProUrl +
            ENDPOINTS.REPORT.ORDER_BALANCE_COMMISSION_REPOT.replace('{balance_id}', orderBalanceId);

        return this.getRequestOptions(true).pipe(
            switchMap((headers) => {
                const options = {
                    headers: headers.headers,
                };

                return this.http.get(url, options).pipe(
                    map((resp: any) => {
                        if (resp.status !== 'success') {
                            throwError(resp);
                        }
                        return resp;
                    })
                );
            })
        );
    }

    public getBalanceReceiptUri(balanceId: number, receiptId: number): Observable<any> {
        const url =
            environment.apiProUrl +
            ENDPOINTS.REPORT.GET_BALANCE_RECEIPT_URI.replace('{balance_id}', balanceId).replace(
                '{receipt_id}',
                receiptId
            );

        return this.getRequestOptions(true).pipe(
            switchMap((headers) => {
                const options = {
                    headers: headers.headers,
                };

                return this.http.get(url, options).pipe(
                    map((resp: any) => {
                        if (resp.status !== 'success') {
                            throwError(resp);
                        }
                        return resp;
                    })
                );
            })
        );
    }
}
