import { Injectable } from '@angular/core';
import * as moment from 'moment';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { ENDPOINTS } from '../constants/endpoint.constant';
import { IChaophrayaCruiseBooking } from '../models/chaophraya-cruise-booking.model';
import { HttpService } from './base/http.service';

@Injectable({
    providedIn: 'root',
})
export class ChaophrayaCruiseBookingService {
    constructor(private httpService: HttpService) {}

    post(model: IChaophrayaCruiseBooking): Observable<any> {
        const url = environment.apiProUrl + ENDPOINTS.CHAOPHRAYA_CRUISE_BOOKINGS.POST;
        const body = {};
        body['payment_id'] = model.paymentId;
        body['usage_date'] = model.usageDate ? moment(model.usageDate).format('YYYY-MM-DD') : null;
        body['remark'] = model.remark;
        body['tickets'] = model.tickets.map((x) => ({ ticket_code: x }));

        return this.httpService.post(url, body);
    }

    get(bookingId: number): Observable<any> {
        const url =
            environment.apiProUrl +
            ENDPOINTS.CHAOPHRAYA_CRUISE_BOOKINGS.GET.replace('{booking_id}', bookingId.toString());

        return this.httpService.get(url);
    }
}
