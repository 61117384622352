<div class="card mb-4">
    <div class="card-body p-0">
        <div class="header-info pl-4 pr-4 pt-4 pb-3">
            <div class="d-flex mb-3">
                <strong class="text-xl">
                    {{ reportView?.productOwnerPartner?.nameEn }} ({{ reportView?.productOwnerPartner?.nameTh }})
                </strong>
                <span class="ml-auto text-lg">
                    จำนวน {{ reportView?.receiptItemQuantity | number }} Order รวม
                    {{ reportView?.sumAmount | number }}
                </span>
            </div>
            <div class="d-flex">
                <span>
                    <span class="mr-4" *ngIf="reportView?.requestedReceipt">
                        <i class="icon-check-mark mr-1"></i>
                        <span class="text-lg">ขอใบเสร็จรับเงิน</span>
                    </span>
                    <span class="mr-4" *ngIf="reportView?.hasWithHoldingTax">
                        <i class="icon-check-mark mr-1"></i>
                        <span class="text-lg">หัก ณ ที่จ่าย</span>
                    </span>
                    <button
                        class="btn btn-add mr-3"
                        [disabled]="!reportView?.requestedReceipt"
                        (click)="downloadReceiptFile(orderBalanceId, reportView?.id)"
                    >
                        ดาวน์โหลดใบเสร็จรับเงิน
                    </button>
                    <button
                        class="btn btn-add"
                        [disabled]="!reportView?.hasWithHoldingTax || !reportView?.whTaxDocFileUrl"
                        (click)="openFileUrl(reportView?.whTaxDocFileUrl)"
                    >
                        ดาวน์โหลด หัก ณ ที่จ่าย
                    </button>
                </span>
                <span class="ml-auto text-lg">
                    ค่าคอมที่ได้รับ
                    <strong class="text-blue text-xxl">{{ reportView?.sumNetCommissionAgency | number }}</strong>
                </span>
            </div>
        </div>

        <div class="table-list-head">
            <p style="width: 20%">Order</p>
            <p style="width: 40%">Voucher</p>
            <p style="width: 10%">คอมรวม</p>
            <p style="width: 10%">Vat 7%</p>
            <p style="width: 10%" class="flex-column">
                <span>หัก ณ ที่จ่าย</span>
                <span class="text-xxs">(3% จากคอมรวม - Vat)</span>
            </p>
            <p style="width: 10%" class="flex-column align-items-start">
                <span>ค่าคอมที่ได้รับ</span>
                <span class="text-xxs">(คอมรวม - หัก ณ ที่จ่าย)</span>
            </p>
        </div>
        <div class="table-list-body">
            <ng-container *ngFor="let item of reportView?.items; let index = index">
                <app-order-balance-report-box [hidden]="!showAllReports(index)" [runNumber]="index + 1" [item]="item">
                </app-order-balance-report-box>
            </ng-container>
        </div>

        <div class="footer-info">
            <div class="d-flex align-items-center justify-content-center m-3" *ngIf="reportView?.items.length > 5">
                <span class="cursor-pointer" (click)="toggleExpandRows()">
                    <u class="mr-1">ดู Order ทั้งหมด</u>
                    <i [ngClass]="{ 'icon-arrow-up': isExpand, 'icon-arrow-down': !isExpand }"></i>
                </span>
            </div>
        </div>
    </div>
</div>
