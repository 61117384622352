import { ICustomer } from '../interfaces/tw-booking-interface/customer.interface';

export class Customer {
    name: string;
    phoneNumber: string;
    email: string;
    facebook: string;
    line: string;
    instagram: string;
    remark: string;

    public deserialize(data: ICustomer): Customer {
        this.name = data.name;
        this.phoneNumber = data.phone_number;
        this.email = data.email;
        this.facebook = data.facebook;
        this.line = data.line;
        this.instagram = data.instagram;
        this.remark = data.remark;
        return this;
    }
}
