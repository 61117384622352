import { Action, createReducer, on, State } from '@ngrx/store';
import * as UtilityActions from './utility.actions';

import { initialState } from '../utility-store/utility.state';
import { UtilityState } from './utility.state';

export const productReducer = createReducer(
    initialState,

    /** country reducers */
    on(UtilityActions.loadCountries, (state) => state),
    on(UtilityActions.loadCountriesSuccess, (state, action) => ({
        ...state,
        countries: action.countries,
    })),
    on(UtilityActions.loadCountriesFailure, (state, action) => state),

    /** province reducers */
    on(UtilityActions.loadProvinces, (state) => state),
    on(UtilityActions.loadProvincesSuccess, (state, action) => ({
        ...state,
        provinces: action.provinces,
    })),
    on(UtilityActions.loadProvincesFailure, (state, action) => state),

    /** countrySubUnit reducers */
    on(UtilityActions.loadCountrySubUnits, (state) => state),
    on(UtilityActions.loadCountrySubUnitsSuccess, (state, action) => ({
        ...state,
        countrySubUnits: action.countrySubUnits,
    })),
    on(UtilityActions.loadCountrySubUnitsFailure, (state, action) => state),

    /** categoryTransport reducers */
    on(UtilityActions.loadCategoryTransports, (state) => state),
    on(UtilityActions.loadCategoryTransportsSuccess, (state, action) => ({
        ...state,
        categoryTransports: action.categoryTransports,
    })),
    on(UtilityActions.loadCategoryTransportsFailure, (state, action) => state),

    /** transport reducers */
    on(UtilityActions.loadTransportations, (state) => state),
    on(UtilityActions.loadTransportationsSuccess, (state, action) => ({
        ...state,
        transportations: action.transportations,
    })),
    on(UtilityActions.loadTransportationsFailure, (state, action) => state),

    /** upload fle gallery reducers */
    on(UtilityActions.uploadFileGallery, (state, action) => {
        const newState = { ...state };
        newState.galleryResponses.set(action.identifier, null);
        return newState;
    }),
    on(UtilityActions.uploadFileGalleryProgressUpdate, (state, action) => {
        const newState = { ...state };
        const galleryResponse = newState.galleryResponses.set(action.identifier, action.response);
        return newState;
    }),
    on(UtilityActions.uploadFileGalleryUnhandle, (state) => state),
    on(UtilityActions.uploadFileGallerySuccess, (state, action) => {
        const newState = { ...state };
        newState.galleryResponses.set(action.identifier, action.response);
        return newState;
    }),
    on(UtilityActions.uploadFileGalleryFailure, (state, action) => state),
    on(UtilityActions.uploadFileGalleryResetAll, (state, action) => {
        const newState = { ...state };
        newState.galleryResponses.clear();
        return newState;
    }),

    /** delete fle gallery reducers */
    on(UtilityActions.deleteFileGallery, (state, action) => {
        const newState = { ...state };
        const galleryResponse = newState.galleryResponses.set(action.identifier, null);
        return newState;
    }),
    on(UtilityActions.deleteFileGallerySuccess, (state, action) => state),
    on(UtilityActions.deleteFileGalleryFailure, (state, action) => state),

    /** about us get details */
    on(UtilityActions.loadAboutUsDetails, (state, action) => state),
    on(UtilityActions.loadAboutUsDetailsSuccess, (state, action) => ({
        ...state,
        aboutUsResponse: action.response,
    })),
    on(UtilityActions.loadAboutUsDetailsFailure, (state, action) => ({
        ...state,
        aboutUsError: action.error,
    })),

    /** about us edit details */
    on(UtilityActions.editAboutUsDetails, (state, action) => ({
        ...state,
        aboutUsEditPayload: action.payload,
    })),
    on(UtilityActions.editAboutUsDetailsSuccess, (state, action) => ({
        ...state,
        aboutUsEditedResponse: action.response,
    })),
    on(UtilityActions.editAboutUsDetailsFailure, (state, action) => ({
        ...state,
        aboutUsError: action.error,
    })),

    /** get banks reducers */
    on(UtilityActions.loadBanks, (state, action) => state),
    on(UtilityActions.loadBanksSuccess, (state, action) => ({
        ...state,
        banks: action.response,
    })),
    on(UtilityActions.loadBanksFailure, (state, action) => ({
        ...state,
        banksError: action.error,
    })),

    /** get productSubCategory reducers */
    on(UtilityActions.loadProductSubCategoriesSuccess, (state, action) => ({
        ...state,
        productSubCategories: action.productSubCategories,
    }))
);

export function reducer(state: UtilityState | undefined, action: Action) {
    return productReducer(state, action);
}
