import { SORT_BY } from './../../constants/product-sorting.constant';
import { ProductNameList } from 'src/app/models/product-name-list';
import { Action, createReducer, on } from '@ngrx/store';
import * as ProductActions from './product.actions';
import { ProductList } from 'src/app/models/product-list';
import {
    ICreateProductResponse,
    IProductDeleteResponse,
    IProduct,
    IUpdateProductResponse,
    IUpdateProductPoolRecommendationResponse,
} from 'src/app/modules/product/models/product.model';
import { ProductListQuery } from 'src/app/models/request/queries/product-list-query';
import { IProductPdfDetailFile } from 'src/app/models/product-pdf-detail-file.model';
import { Owner } from 'src/app/models/owner';
import { CampaignDetail } from 'src/app/modules/product/models/campaign-detail.model';

export const productFeatureKey = 'product';

export interface ProductState {
    productCountOfAgency: {
        promotion_join_tour_count: number;
        outbound_join_tour_count: number;
        outbound_package_tour_count: number;
        domestic_tour_count: number;
        campaign_count: number;
    };
    product: IProduct;
    productList: ProductList[];
    productView: any;
    productMonth: string[];
    productDelete: any;
    productListQuery: ProductListQuery;
    productListCurrentPage: number;
    productListTotalRecord: number;
    createProductResponse: ICreateProductResponse;
    updateProductResponse: IUpdateProductResponse;
    deleteProductResponse: IProductDeleteResponse;
    updateProductPoolRecommendationResponse: IUpdateProductPoolRecommendationResponse;
    productDocumentFileBase64: any;
    productPdfFileBase64: any;
    productPdfDetailFile: IProductPdfDetailFile;
    loading: boolean;
    error: any;
    searchString: string;
    searchStringProductDetails: any;
    searchStringError: any;
    productNameList: ProductNameList[];
    productOwner: Owner;
    sortBy: SORT_BY;
    campaignDetail: CampaignDetail;
}

export const initialState: ProductState = {
    productCountOfAgency: null,
    product: null,
    productList: null,
    productView: null,
    productMonth: null,
    productDelete: null,
    productListQuery: null,
    productListCurrentPage: null,
    productListTotalRecord: null,
    createProductResponse: null,
    updateProductResponse: null,
    deleteProductResponse: null,
    updateProductPoolRecommendationResponse: null,
    productDocumentFileBase64: null,
    productPdfFileBase64: null,
    productPdfDetailFile: null,
    loading: false,
    error: null,
    searchString: null,
    searchStringProductDetails: null,
    searchStringError: null,
    productNameList: null,
    productOwner: null,
    sortBy: null,
    campaignDetail: null,
};

export const productReducer = createReducer(
    initialState,

    on(ProductActions.loadProductCountOfAgency, (state) => {
        return { ...state, loading: true };
    }),
    on(ProductActions.loadProductCountOfAgencySuccess, (state, action) => {
        return {
            ...state,
            loading: false,
            productCountOfAgency: action.data,
        };
    }),
    on(ProductActions.loadProductCountOfAgencyFailure, (state, action) => {
        return {
            ...state,
            loading: false,
            error: action,
        };
    }),

    on(ProductActions.loadProductMonth, (state) => {
        return { ...state, loading: true };
    }),
    on(ProductActions.loadProductMonthSuccess, (state, action) => {
        return {
            ...state,
            loading: false,
            productMonth: action.data,
        };
    }),
    on(ProductActions.loadProductMonthFailure, (state, action) => {
        return {
            ...state,
            loading: false,
            error: action,
        };
    }),

    on(ProductActions.loadNewProductListing, (state, action) => {
        return {
            ...state,
            loading: true,
            productListQuery: action.query,
        };
    }),
    on(ProductActions.loadProductListing, (state, action) => {
        return {
            ...state,
            loading: true,
            productListQuery: action.query,
        };
    }),
    on(ProductActions.loadProductListingSuccess, (state, action) => {
        const oldProductList = state.productList || [];
        const productList: ProductList[] = action.refresh ? [] : [...oldProductList];

        productList.push(...action.productList);
        return {
            ...state,
            loading: false,
            productList,
            productListCurrentPage: action.page,
            productListTotalRecord: action.total,
        };
    }),
    on(ProductActions.loadProductListingFailure, (state, action) => {
        return {
            ...state,
            loading: false,
            error: action,
        };
    }),
    on(ProductActions.clearProductListing, (state) => {
        return {
            ...state,
            loading: false,
            productList: [],
            productListFilter: null,
            productListCurrentPage: null,
            productListTotalRecord: null,
        };
    }),

    ///
    on(ProductActions.loadProductView, (state) => {
        return { ...state, loading: true };
    }),
    on(ProductActions.loadProductViewSuccess, (state, action) => {
        return {
            ...state,
            loading: false,
            productView: action.productView,
        };
    }),
    on(ProductActions.loadProductViewFailure, (state, action) => {
        return {
            ...state,
            loading: false,
            error: action,
        };
    }),
    on(ProductActions.resetProductView, (state) => {
        return {
            ...state,
            productView: null,
            loading: false,
            error: null,
        };
    }),

    on(ProductActions.loadProductListingFailure, (state, action) => {
        return {
            ...state,
            loading: false,
            error: action.error,
        };
    }),

    ///
    on(ProductActions.loadProductView, (state) => {
        return { ...state, loading: true };
    }),
    on(ProductActions.loadProductViewSuccess, (state, action) => {
        return {
            ...state,
            loading: false,
            productView: action,
        };
    }),
    on(ProductActions.loadProductViewFailure, (state, action) => {
        return {
            ...state,
            loading: false,
            error: action,
        };
    }),

    on(ProductActions.deleteProduct, (state) => {
        return {
            ...state,
            loading: true,
            deleteProductResponse: null,
        };
    }),
    on(ProductActions.deleteProductSuccess, (state, action) => {
        return {
            ...state,
            loading: false,
            deleteProductResponse: action.response,
        };
    }),
    on(ProductActions.deleteProductFailure, (state, action) => {
        return {
            ...state,
            loading: false,
            error: action.error,
        };
    }),

    /** load product reducer */
    on(ProductActions.loadProduct, (state) => {
        return {
            ...state,
            loading: true,
            product: initialState.product,
        };
    }),
    on(ProductActions.loadProductSuccess, (state, action) => {
        return {
            ...state,
            loading: false,
            product: action.product,
        };
    }),
    on(ProductActions.loadProductFailure, (state, action) => {
        return {
            ...state,
            loading: false,
            error: action.error,
        };
    }),
    on(ProductActions.resetProduct, (state) => {
        return {
            ...state,
            product: initialState.product,
        };
    }),

    /** create outbound join product reducers */
    on(ProductActions.createOutBoundJoinProduct, (state) => {
        return {
            ...state,
            loading: true,
            createProductResponse: initialState.createProductResponse,
        };
    }),
    on(ProductActions.createOutBoundJoinProductSuccess, (state, action) => {
        return {
            ...state,
            loading: false,
            createProductResponse: action.response,
        };
    }),
    on(ProductActions.createOutBoundJoinProductFailure, (state, action) => {
        return {
            ...state,
            loading: false,
            createProductResponse: action.response,
            error: action.response.error,
        };
    }),
    on(ProductActions.resetCreateOutBoundJoinProduct, (state) => {
        return {
            ...state,
            createProductResponse: initialState.createProductResponse,
        };
    }),

    /** update outbound join product reducer */
    on(ProductActions.updateOutBoundJoinProduct, (state) => {
        return {
            ...state,
            loading: true,
            updateProductResponse: initialState.updateProductResponse,
        };
    }),
    on(ProductActions.updateOutBoundJoinProductSuccess, (state, action) => {
        return {
            ...state,
            loading: false,
            updateProductResponse: action.response,
        };
    }),
    on(ProductActions.updateOutBoundJoinProductFailure, (state, action) => {
        return {
            ...state,
            loading: false,
            updateProductResponse: action.response,
            error: action.response.error,
        };
    }),
    on(ProductActions.resetUpdateOutBoundJoinProduct, (state) => {
        return {
            ...state,
            updateProductResponse: initialState.createProductResponse,
        };
    }),

    /** create outbound package product reducers */
    on(ProductActions.createOutBoundPackageProduct, (state) => {
        return {
            ...state,
            loading: true,
            createProductResponse: initialState.createProductResponse,
        };
    }),
    on(ProductActions.createOutBoundPackageProductSuccess, (state, action) => {
        return {
            ...state,
            loading: false,
            createProductResponse: action.response,
        };
    }),
    on(ProductActions.createOutBoundPackageProductFailure, (state, action) => {
        return {
            ...state,
            loading: false,
            createProductResponse: action.response,
            error: action.response.error,
        };
    }),
    on(ProductActions.resetCreateOutBoundPackageProduct, (state) => {
        return {
            ...state,
            createProductResponse: initialState.createProductResponse,
        };
    }),

    /** update outbound join product reducer */
    on(ProductActions.updateOutBoundJoinProduct, (state) => {
        return {
            ...state,
            loading: true,
            updateProductResponse: initialState.updateProductResponse,
        };
    }),
    on(ProductActions.updateOutBoundJoinProductSuccess, (state, action) => {
        return {
            ...state,
            loading: false,
            updateProductResponse: action.response,
        };
    }),
    on(ProductActions.updateOutBoundJoinProductFailure, (state, action) => {
        return {
            ...state,
            loading: false,
            updateProductResponse: action.response,
            error: action.response.error,
        };
    }),
    on(ProductActions.resetUpdateOutBoundJoinProduct, (state) => {
        return {
            ...state,
            updateProductResponse: initialState.createProductResponse,
        };
    }),

    /** create domestic product reducers */
    on(ProductActions.createDomesticProduct, (state) => {
        return {
            ...state,
            loading: true,
            createProductResponse: initialState.createProductResponse,
        };
    }),
    on(ProductActions.createDomesticProductSuccess, (state, action) => {
        return {
            ...state,
            loading: false,
            createProductResponse: action.response,
        };
    }),
    on(ProductActions.createDomesticProductFailure, (state, action) => {
        return {
            ...state,
            loading: false,
            createProductResponse: action.response,
            error: action.response.error,
        };
    }),
    on(ProductActions.resetCreateDomesticProduct, (state) => {
        return {
            ...state,
            createProductResponse: initialState.createProductResponse,
        };
    }),

    /** update domestic product reducer */
    on(ProductActions.updateDomesticProduct, (state) => {
        return {
            ...state,
            loading: true,
            updateProductResponse: initialState.updateProductResponse,
        };
    }),
    on(ProductActions.updateDomesticProductSuccess, (state, action) => {
        return {
            ...state,
            loading: false,
            updateProductResponse: action.response,
        };
    }),
    on(ProductActions.updateDomesticProductFailure, (state, action) => {
        return {
            ...state,
            loading: false,
            updateProductResponse: action.response,
            error: action.response.error,
        };
    }),
    on(ProductActions.resetUpdateDomesticProduct, (state) => {
        return {
            ...state,
            updateProductResponse: initialState.createProductResponse,
        };
    }),

    /** update outbound package product reducer */
    on(ProductActions.updateOutBoundPackageProduct, (state) => {
        return {
            ...state,
            loading: true,
            updateProductResponse: initialState.updateProductResponse,
        };
    }),
    on(ProductActions.updateOutBoundPackageProductSuccess, (state, action) => {
        return {
            ...state,
            loading: false,
            updateProductResponse: action.response,
        };
    }),
    on(ProductActions.updateOutBoundPackageProductFailure, (state, action) => {
        return {
            ...state,
            loading: false,
            updateProductResponse: action.response,
            error: action.response.error,
        };
    }),
    on(ProductActions.resetUpdateOutBoundPackageProduct, (state) => {
        return {
            ...state,
            updateProductResponse: initialState.createProductResponse,
        };
    }),

    /** update product pool recommendation reducer */
    on(ProductActions.updateProductPoolRecommendation, (state) => {
        return {
            ...state,
            loading: true,
            updateProductPoolRecommendationResponse: null,
        };
    }),
    on(ProductActions.updateProductPoolRecommendationSuccess, (state, action) => {
        const oldProductList = state.productList;
        const productList: ProductList[] = [...oldProductList];
        const indexToUpdate = productList.findIndex((product) => product.productPoolId === action.productPoolId);
        if (indexToUpdate !== -1) {
            const updatedProduct: ProductList = Object.assign(new ProductList(), { ...productList[indexToUpdate] });
            updatedProduct.isRecommended = action.isRecommended;
            productList[indexToUpdate] = updatedProduct;
        }
        return {
            ...state,
            loading: false,
            updateProductPoolRecommendationResponse: action.response,
            productList,
        };
    }),
    on(ProductActions.updateProductPoolRecommendationFailure, (state, action) => {
        return {
            ...state,
            loading: false,
            updateProductPoolRecommendationResponse: action.response,
        };
    }),
    on(ProductActions.updateProductPoolRecommendationResetResponse, (state) => {
        return {
            ...state,
            updateProductPoolRecommendationResponse: null,
        };
    }),

    /** Load product with tour code */
    on(ProductActions.loadProductWithSearchString, (state, action) => {
        return {
            ...state,
            searchString: action.searchString,
        };
    }),
    on(ProductActions.loadProductWithSearchStringSuccess, (state, action) => {
        return {
            ...state,
            searchStringProductDetails: action.productDetails,
        };
    }),
    on(ProductActions.loadProductWithSearchStringFailure, (state, action) => {
        return {
            ...state,
            searchStringError: action.error,
        };
    }),

    /** Load product document file base64 */
    on(ProductActions.loadProductDocumentFileBase64, (state) => {
        return {
            ...state,
            productDocumentFileBase64: null,
        };
    }),
    on(ProductActions.loadProductDocumentFileBase64Succcess, (state, action) => {
        return {
            ...state,
            productDocumentFileBase64: action.data,
        };
    }),
    on(ProductActions.loadProductDocumentFileBase64Failure, (state, action) => {
        return {
            ...state,
            productDocumentFileBase64: null,
            error: action.error,
        };
    }),
    on(ProductActions.loadProductDocumentFileBase64Reset, (state) => {
        return {
            ...state,
            productDocumentFileBase64: null,
        };
    }),

    /** Load product pdf file base64 */
    on(ProductActions.loadProductPdfFileBase64, (state) => {
        return {
            ...state,
            productPdfFileBase64: null,
        };
    }),
    on(ProductActions.loadProductPdfFileBase64Succcess, (state, action) => {
        return {
            ...state,
            productPdfFileBase64: action.data,
        };
    }),
    on(ProductActions.loadProductPdfFileBase64Failure, (state, action) => {
        return {
            ...state,
            productPdfFileBase64: null,
            error: action.error,
        };
    }),
    on(ProductActions.loadProductPdfFileBase64Reset, (state) => {
        return {
            ...state,
            productPdfFileBase64: null,
        };
    }),

    /** Load product name list for search filter */
    on(ProductActions.loadProductNameListListing, (state, action) => {
        return {
            ...state,
            loading: true,
        };
    }),
    on(ProductActions.loadProductNameListListingSuccess, (state, action) => {
        return {
            ...state,
            productNameList: action.productNameList,
        };
    }),
    on(ProductActions.loadProductNameListListingFailure, (state, action) => {
        return {
            ...state,
            error: action,
        };
    }),

    on(ProductActions.setProductSortBy, (state, action) => {
        return {
            ...state,
            sortBy: action.sortBy,
        };
    }),

    on(ProductActions.loadProductPdfDetailFile, (state) => {
        return {
            ...state,
            productPdfDetailFile: null,
        };
    }),
    on(ProductActions.loadProductPdfDetailFileSuccess, (state, action) => {
        return {
            ...state,
            productPdfDetailFile: action.productPdfDetailFile,
        };
    }),
    on(ProductActions.loadProductPdfDetailFileFailure, (state, action) => {
        return {
            ...state,
            productPdfDetailFile: null,
        };
    }),
    on(ProductActions.resetProductPdfDetailFile, (state) => {
        return {
            ...state,
            productPdfDetailFile: null,
        };
    }),

    on(ProductActions.loadProductOwner, (state) => ({
        ...state,
        productOwner: null,
    })),
    on(ProductActions.loadProductOwnerSuccess, (state, action) => ({
        ...state,
        productOwner: action.productOwner,
    })),
    on(ProductActions.loadProductOwnerFailure, (state, action) => ({
        ...state,
        productOwner: null,
    })),
    on(ProductActions.resetProductOwner, (state) => ({
        ...state,
        productOwner: null,
    })),
    on(ProductActions.loadCampaignDetail, (state) => ({
        ...state,
        campaignDetail: null,
        error: null,
    })),
    on(ProductActions.loadCampaignDetailSuccess, (state, action) => ({
        ...state,
        campaignDetail: action.campaignDetail,
        error: null,
    })),
    on(ProductActions.loadCampaignDetailFailure, (state, action) => ({
        ...state,
        campaignDetail: null,
        error: action.error,
    }))
);

export function reducer(state: ProductState | undefined, action: Action) {
    return productReducer(state, action);
}
