<span class="d-inline-flex align-items-center w-100">
    <div class="flex-grow-1">
        <app-calendar-input
        class="rounded" 
        placeholder="เลือกวัน"
        [showDateInValue]=true
        [bsValue]="startDate"
        (bsValueChange)="startDateChange($event)"
        [bsConfig]="{
            dateInputFormat: 'DD MM YY',
            maxDate: endDate || ''}"></app-calendar-input>
    </div>


    <span class="ml-1 mr-1">ถึง</span>

    <div class="flex-grow-1">
        <app-calendar-input
        class="rounded" 
        placeholder="เลือกวัน"
        [showDateInValue]=true
        [bsValue]="endDate"
        (bsValueChange)="endDateChange($event)"
        [bsConfig]="{
            dateInputFormat: 'DD MM YY',
            minDate: startDate || ''}"></app-calendar-input>
    </div>

</span>
