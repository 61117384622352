import { Pipe, PipeTransform } from '@angular/core';
import { CAMPAIGN_TYPES } from '../constants/campaign-types.constant';

@Pipe({
    name: 'campaigncategory',
})
export class CampaignCategoryPipe implements PipeTransform {
    transform(slug: string): any {
        const name = CAMPAIGN_TYPES[slug];
        return name ?? slug;
    }
}
