<div class="modal-header">
    <button type="button" class="close pull-right" aria-label="Close" (click)="bsModalRef.hide()">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<div>
    <h4 class="modal-title text-center">รายละเอียดผู้ติดต่อ (เจ้าของโปรแกรมทัวร์)</h4>
</div>
<div class="modal-body">
    <div class="contact-detail">
        <span class="contact-key font-weight-bold">ชื่อบริษัท :</span>
        <p class="value">{{ productOwner?.name }}</p>
    </div>
    <div class="contact-detail">
        <span class="contact-key font-weight-bold">เบอร์โทรหลัก :</span>
        <p class="value">{{ productOwner?.telephoneNumber | phoneNumber }}</p>
    </div>
    <div class="contact-detail">
        <span class="contact-key font-weight-bold">เบอร์โทรเพิ่มเติม :</span>
        <p class="value">{{ productOwner?.additionTelephoneNumber || '-' }}</p>
    </div>
    <div class="contact-detail">
        <span class="contact-key font-weight-bold">อีเมล :</span>
        <p class="value">{{ productOwner?.email || '-' }}</p>
    </div>
    <div class="contact-detail">
        <span class="contact-key font-weight-bold">ที่อยู่ :</span>
        <p class="value">{{ productOwner?.address || '-' }}</p>
    </div>
    <div class="contact-detail">
        <span class="contact-key font-weight-bold">เลขที่ใบอนุญาตนำเที่ยว :</span>
        <p class="value">{{ productOwner?.licenseNumber }}</p>
    </div>
</div>
<div class="modal-footer">
    <button type="button" class="btn btn-default" (click)="bsModalRef.hide()">ปิด</button>
</div>
