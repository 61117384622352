import { CATEGORY_TRANSPORT_REFERENCES } from './../constants/category-transport.constant';
import { IDeserializable } from './interfaces/deserializable';

export interface IOrderFilter {
    orderStatus?: number;
    created?: IOrderPeriodFilter;
    started?: IOrderPeriodFilter;
}

export interface IOrderPeriodFilter {
    startDate?: string;
    endDate?: string;
}

export class OrderInfo implements IOrderInfo, IDeserializable {
    id: number;
    orderCode: string;
    totalAmount: number;
    inserted: string;

    deserialize(source: any) {
        this.id = source.id;
        this.orderCode = source.order_code;
        this.totalAmount = source.sum_total;
        this.inserted = source.created_at;
        return this;
    }
}

export interface IOrderInfo {
    id: number;
    orderCode: string;
    totalAmount: number;
    inserted: string;
}

export class OrderDetail implements IOrderDetail, IDeserializable {
    id: number;
    startDate: string;
    endDate: string;
    productName: string;
    commissionCompany?: number;
    commissionSeller?: number;
    contactName: string;
    phoneNumber: string;
    emailAddress: string;
    customerRemark: string;
    orderItems: OrderItem[];
    orderInstallments: OrderInstallment[];
    transportName: string;
    locationString: string;
    sellerId: number;
    ownerId: number;
    ownerChannel: 'ws' | 'ag';
    leadTypeSlug: string;
    poolKey: string;
    customProductName: string;
    ownerNameEn: string;
    ownerNameTh: string;
    tourCode: string;
    bannerPreviewUrl: string;
    categoryTransportSlug: string;
    durationDay: number;
    durationNight: number;
    categorySubProductsId: number;
    countries: any;
    provinces: any;

    deserialize(source: any) {
        this.id = source.id;
        this.startDate = source.start_at;
        this.endDate = source.end_at;
        this.productName = source.product_info?.name || source.custom_product_name;
        this.commissionCompany = source.commission_company;
        this.commissionSeller = source.commission_seller;
        this.contactName = source.contact_name;
        this.phoneNumber = source.phone_number;
        this.emailAddress = source.email;
        this.customerRemark = source.customer_remark;
        this.orderItems = source.order_items.map((x) => new OrderItem().deserialize(x));
        this.orderInstallments = source.order_installments.map((x) => new OrderInstallment().deserialize(x));
        this.transportName = this.getTransportName(source.product_info);
        this.locationString = this.getLocationString(source.product_info);
        this.sellerId = source.seller_agency_member_id;
        this.ownerId = source.product_info?.owner.id || null;
        this.ownerChannel = source.product_info?.owner.channel || null;
        this.leadTypeSlug = source.lead_type_slug;
        this.poolKey = source.product_info?.pool_key || null;
        this.customProductName = source.custom_product_name;
        this.ownerNameEn = source.product_info?.owner.name_en || null;
        this.ownerNameTh = source.product_info?.owner.name_th || null;
        this.tourCode = source.tour_code;
        this.bannerPreviewUrl = source.product_info?.banner_preview_url || null;
        this.categoryTransportSlug = this.getCategoryTransportSlug(source.productInfo);
        this.durationDay = source.product_info?.duration_day || null;
        this.durationNight = source.product_info?.duration_night || null;
        this.categorySubProductsId = source.pro_category_sub_products_id;
        this.countries = source.product_info?.countries || null;
        this.provinces = source.product_info?.provinces || null;
        return this;
    }

    private getTransportName(productInfo: any): string {
        if (!productInfo || !productInfo.transport) {
            return '';
        }

        let transportName = productInfo.transport.name;
        if (productInfo.transport.code) {
            transportName += ' (' + productInfo.transport.code + ')';
        }

        return transportName;
    }

    private getLocationString(productInfo: any): string {
        if (!productInfo) {
            return '';
        }

        let locationString = '';

        if (productInfo.provinces.length) {
            locationString += 'ประเทศไทย / จังหวัด';
            productInfo.provinces.map((province) => {
                locationString += province.name_th + '-';
            });
        } else {
            locationString += 'ประเทศ';
            productInfo.countries.map((country) => {
                locationString += country.name_th + '-';
            });
        }

        locationString = locationString.slice(0, -1);
        return locationString;
    }

    private getCategoryTransportSlug(productInfo: any): string {
        if (!productInfo || !productInfo.transport?.category_transport_id) {
            return '';
        }

        const transportSlug: string[] = CATEGORY_TRANSPORT_REFERENCES.filter(
            (transport) => transport.id === productInfo.transport.category_transport_id
        ).map((transport) => {
            return transport.slug;
        });

        return transportSlug[0];
    }
}

export class OrderItem implements IOrderItem, IDeserializable {
    id: number;
    description: string;
    price: number;
    quantity: number;
    total: number;

    deserialize(source: any) {
        this.id = source.id;
        this.description = source.description;
        this.price = source.price;
        this.quantity = source.quantity;
        this.total = source.total;
        return this;
    }
}

export class OrderInstallment implements IOrderInstallment, IDeserializable {
    id: number;
    paymentDueDate: string;
    totalPaid: number;

    deserialize(source: any) {
        this.id = source.id;
        this.paymentDueDate = source.payment_duedate_at;
        this.totalPaid = source.total_paid;
        return this;
    }
}

export interface IOrderDetail {
    id: number;
    startDate: string;
    endDate: string;
    productName: string;
    commissionCompany?: number;
    commissionSeller?: number;
    contactName: string;
    phoneNumber: string;
    emailAddress: string;
    customerRemark: string;
    orderItems: IOrderItem[];
    orderInstallments: IOrderInstallment[];
    transportName: string;
    locationString: string;
    sellerId: number;
    ownerId: number;
    ownerChannel: 'ws' | 'ag';
    leadTypeSlug: string;
    poolKey: string;
    customProductName: string;
    ownerNameEn: string;
    ownerNameTh: string;
    tourCode: string;
    bannerPreviewUrl: string;
    categoryTransportSlug: string;
    durationDay: number;
    durationNight: number;
    categorySubProductsId: number;
    countries: any;
    provinces: any;
}

export interface IOrderItem {
    id: number;
    description: string;
    price: number;
    quantity: number;
    total: number;
}

export interface IOrderInstallment {
    id: number;
    paymentDueDate: string;
    totalPaid: number;
}
