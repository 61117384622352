import { Injectable } from '@angular/core';
import { CONFIG_TAX } from '../constants/tax.constant';

@Injectable({
    providedIn: 'root',
})
export class TaxService {
    public taxOptionDefault = 1;
    protected percentVat = 7;
    protected percentWithholdingTax = 3;

    private totalbeForCalculate = undefined;
    private totalVat = undefined;
    private totalWithHolding = undefined;
    private totalRealPaid = undefined;
    private displayTotalWithholdingTax = undefined;
    private displayTotalRealPaid = undefined;

    public getTaxOptions() {
        return CONFIG_TAX.filter((item) => item.id > 1).sort((a, b) => (a.id < b.id ? 1 : -1));
    }

    public getConfigTax(taxOption) {
        const config = CONFIG_TAX.filter((item) => item.id == +taxOption);
        return config ? config[0] : undefined;
    }

    public calulateTax(requestTax: number, totalPrice: number, isInvoiceDisplayWithholdingTax: boolean) {
        this.totalRealPaid = totalPrice;
        this.totalbeForCalculate = totalPrice;
        const config = this.getConfigTax(requestTax);

        this.setTotalBeforeCalulateTax(config);
        this.totalRealPaid = this.totalbeForCalculate;

        /** คำนวณ vat7% */
        if (config.isVat) {
            this.includeVatToTotalRealPaid();
        }

        /** คำนวณ withholding_tax3% */
        if (config.isWithholdingTax) {
            this.excludeWithholdingTaxFromTotalRealPaid();
        }

        /** สำหรับ display บน Invoice | คำนวณ withholding_tax3% */
        if (config.canDisplayWithholdingTaxToInvoive && isInvoiceDisplayWithholdingTax) {
            this.excludeWithholdingTaxFromInvoiceTotalRealPaid();
        }

        return {
            totalbeForCalculate: this.totalbeForCalculate,
            totalVat: this.totalVat,
            totalWithHolding: this.totalWithHolding,
            totalRealPaid: this.totalRealPaid,
            displayTotalWithholdingTax: this.displayTotalWithholdingTax,
            displayTotalRealPaid: this.displayTotalRealPaid,
        };
    }

    private setTotalBeforeCalulateTax(config) {
        /** ถอด vat 7% ก่อนเอาไปคำนวณ Tax */
        if (config.is_total_exclude_vat) {
            this.excludeVatFromTotalForCalulate();
        }

        /** เพิ่ม withholding_tax 3% ก่อนเอาไปคำนวณ Tax */
        if (config.is_total_include_withholding_tax) {
            this.includeWithholdingTaxToTotalForCalulate();
        }
    }

    private excludeVatFromTotalForCalulate(): void {
        const setVat = (100 + this.percentVat) / 100; // 1.07
        const result = this.totalbeForCalculate / setVat;
        this.totalbeForCalculate = result; // this.roundTwoDigit(result)
    }

    private includeWithholdingTaxToTotalForCalulate(): void {
        const setWithholdingTax = (100 - this.percentWithholdingTax) / 100; // 0.97
        const result = this.totalbeForCalculate / setWithholdingTax;
        this.totalbeForCalculate = this.roundTwoDigit(result);
    }

    private includeVatToTotalRealPaid(): void {
        const setVat = this.percentVat / 100; // 0.07
        const result = this.totalbeForCalculate * setVat;
        this.totalVat = this.roundTwoDigit(result);

        this.totalRealPaid = this.totalRealPaid + this.totalVat;
    }

    private excludeWithholdingTaxFromTotalRealPaid(): void {
        const setWithholdingTax = this.percentWithholdingTax / 100; // 0.03
        const result = this.totalbeForCalculate * setWithholdingTax;
        this.totalWithHolding = this.roundTwoDigit(result);

        this.totalRealPaid = this.totalRealPaid - this.totalWithHolding;
    }

    private excludeWithholdingTaxFromInvoiceTotalRealPaid() {
        const setWithholdingTax = this.percentWithholdingTax / 100; // 0.03
        const result = this.totalbeForCalculate * setWithholdingTax;
        this.displayTotalWithholdingTax = this.roundTwoDigit(result);

        this.displayTotalRealPaid = this.totalRealPaid - this.displayTotalWithholdingTax;
    }

    private roundTwoDigit(value): number {
        return Math.round(value * 100) / 100;
    }
}
