import {
    selectReportBookingRequestLeadList,
    selectBookingRequestUpdatedLeadStatusIdAndStatus,
} from '../../../../stores/report-store/report.selectors';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { ISearchFilter, SearchFilter } from '../../models/search-filter.model';
import { Subject } from 'rxjs';
import { IListingPageDetails } from '../../models/listing-page-details.model';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { Store, select } from '@ngrx/store';
import { State } from 'src/app/stores/reducers';
import { filter, takeUntil } from 'rxjs/operators';
import { selectGetSearchFilter, selectPageDetails } from 'src/app/stores/report-store/report.selectors';
import * as ReportActions from 'src/app/stores/report-store/report.actions';
import { ILeadBookingRequestDetails } from '../../models/booking-request-lead-details.model';
import { SuccessModalComponent } from 'src/app/modules/shared/modals/success-modal/success-modal.component';
import { ActivatedRoute } from '@angular/router';

@Component({
    selector: 'app-lead-booking-request-listing',
    templateUrl: './lead-booking-request-listing.component.html',
    styleUrls: ['./lead-booking-request-listing.component.scss'],
})
export class LeadBookingRequestListingComponent implements OnInit, OnDestroy {
    private searchFilter: ISearchFilter;
    private unsubscribe$: Subject<void> = new Subject<void>();

    listTotal: number = 456;
    pageDetails: IListingPageDetails;
    leadList: ILeadBookingRequestDetails[];

    bsModalRef: BsModalRef;

    constructor(private modalService: BsModalService, private store: Store<State>, private route: ActivatedRoute) {}

    ngOnInit() {
        this.checkQueryParams();
        this.setInitialPageDetails();
        this.dispatchAndSelectGetLeadList();
        this.callInitialiseSelectors();
    }

    ngOnDestroy(): void {
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
    }

    private checkQueryParams(): void {
        if (this.route.snapshot.queryParams) {
            this.searchFilter = new SearchFilter().initialiseSearchFilter();
            this.searchFilter.tour_code = this.route.snapshot.queryParams.tourCode || null;
        }
    }

    private dispatchAndSelectGetLeadList(nextPage?: boolean): void {
        if (!nextPage) {
            this.pageDetails.currentPage = 1;
        }
        this.store.dispatch(
            ReportActions.getBookingRequestLeadList({
                searchFilter: this.searchFilter,
                criteria: {
                    page: this.pageDetails.currentPage,
                    pageEntries: this.pageDetails.pageEntries,
                },
                nextPage: nextPage || null,
            })
        );

        this.store
            .pipe(
                select(selectReportBookingRequestLeadList),
                filter((m) => !!m),
                takeUntil(this.unsubscribe$)
            )
            .subscribe((leadList) => {
                this.leadList = leadList;
            });
    }

    private callInitialiseSelectors(): void {
        this.store.pipe(select(selectPageDetails), takeUntil(this.unsubscribe$)).subscribe((pageDetails) => {
            const totalResults = pageDetails ? pageDetails.totalResults : 0;
            this.listTotal = totalResults || 0;
            this.pageDetails.maxPage = totalResults / this.pageDetails.pageEntries;
            this.pageDetails.currentPage = pageDetails ? pageDetails.page : 1;
        });

        this.store.pipe(select(selectGetSearchFilter), takeUntil(this.unsubscribe$)).subscribe((searchFilter) => {
            this.searchFilter = searchFilter;
        });

        this.store
            .pipe(
                select(selectBookingRequestUpdatedLeadStatusIdAndStatus),
                filter((m) => !!m),
                takeUntil(this.unsubscribe$)
            )
            .subscribe((result) => {
                const status = result.split('-')[1];
                let message: string;
                switch (status) {
                    case 'confirm':
                        message = 'รับจองเรียบร้อยแล้ว';
                        break;
                    case 'cancel':
                        message = 'ยกเลิกใบจองเรียบร้อยแล้ว';
                        break;
                    default:
                        break;
                }
                const initialState = {
                    doNotRefresh: true,
                    message,
                };
                if (message) {
                    this.bsModalRef = this.modalService.show(SuccessModalComponent, {
                        initialState,
                        class: 'success-modal',
                    });
                }
                if (status !== 'null') {
                    this.store.dispatch(
                        ReportActions.getLeadBookingRequestCount({
                            statusSlug: 'pending',
                        })
                    );
                }
                this.store.dispatch(
                    ReportActions.updateBookingRequestLeadStatusSuccess({
                        leadId: null,
                        statusSlug: null,
                    })
                );
            });
    }

    setInitialPageDetails(): void {
        this.pageDetails = {
            currentPage: 1,
            maxPage: null,
            pageEntries: 20,
        };
    }

    nextPage(): void {
        this.pageDetails.currentPage++;

        this.dispatchAndSelectGetLeadList(true);
    }

    showNextResultsValue(): number {
        const nextSearchBracket: number = (this.pageDetails.currentPage + 1) * this.pageDetails.pageEntries;
        if (nextSearchBracket < this.listTotal) {
            return nextSearchBracket;
        }
        return this.listTotal;
    }
}
