<div class="lead-list-component">
    <app-breadcrumb [items]="breadcrumb"></app-breadcrumb>

    <h2 class="text-primary mb-4"><i class="icon icon-dashboard"></i>ใบจองจากลูกค้า</h2>
    <div class="filter-area">
        <div class="shortcut-filters" *ngIf="!hideShortcutFilters">
            <div class="dropdown-container">
                <label class="col-auto col-form-label label-margin large-label">ลูกค้าแจ้งจองเมื่อ</label>
                <div class="col">
                    <app-searchable-dropdown
                        #dateRangeDropdown
                        [settings]="dateRangeDropdownSettings"
                        [items]="dateRangeDropdownItems"
                        (selected)="onSelectDateRange($event, true)"
                    >
                    </app-searchable-dropdown>
                </div>
            </div>
            <div
                class="communication-method-box"
                [class.active]="activeCommunicationMethod === communicationMethods[0].id"
                (click)="communicationMethodSelect(communicationMethods[0].id)"
            >
                <p class="label icon-notification">{{ communicationMethods[0].text }}</p>
                <p class="value">{{ leadCountByType.total }}</p>
            </div>
            <div
                class="communication-method-box"
                [class.active]="activeCommunicationMethod === communicationMethods[1].id"
                (click)="communicationMethodSelect(communicationMethods[1].id)"
            >
                <p class="label icon-website">จองผ่าน{{ communicationMethods[1].text }}</p>
                <p class="value">{{ leadCountByType.web }}</p>
            </div>
            <div
                class="communication-method-box"
                [class.active]="activeCommunicationMethod === communicationMethods[3].id"
                (click)="communicationMethodSelect(communicationMethods[3].id)"
            >
                <p class="label icon-line">{{ communicationMethods[3].text }}</p>
                <p class="value">{{ leadCountByType.line }}</p>
            </div>
            <div
                class="communication-method-box"
                [class.active]="activeCommunicationMethod === communicationMethods[2].id"
                (click)="communicationMethodSelect(communicationMethods[2].id)"
            >
                <p class="label icon-phone">{{ communicationMethods[2].text }}</p>
                <p class="value">{{ leadCountByType.phone }}</p>
            </div>
            <div class="dropdown-container">
                <label class="col-auto col-form-label label-margin large-label">สถานะ</label>
                <div class="col">
                    <app-searchable-dropdown
                        #leadStatusDropdown
                        [settings]="leadStatusDropdownSettings"
                        [items]="leadStatusDropdownItems"
                        (selected)="onSelectLeadStatus($event)"
                    >
                    </app-searchable-dropdown>
                </div>
            </div>
        </div>
        <div class="toggle-filter-link">
            <a [routerLink]="" (click)="toggleFilter()">กดค้นหาข้อมูลเพิ่มเติม</a>
            <i class="icon" [ngClass]="showFilter ? 'icon-arrow-up' : 'icon-arrow-down'"></i>
        </div>
        <app-search-filter
            [class.d-none]="!showFilter"
            [activeList]="'LEAD_LIST'"
            [fieldStatusCheckbox]="advancedSearchFilterProgressCheckbox"
            [preselectedCommunicationMethod]="activeCommunicationMethod"
            [fieldStartDate]="advancedSearchFilterStartDate"
            [fieldEndDate]="advancedSearchFilterEndDate"
        ></app-search-filter>
    </div>
    <div class="listing-area">
        <app-lead-listing></app-lead-listing>
    </div>
</div>
