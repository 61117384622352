import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DropdownModalComponent } from './dropdown-modal.component';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';

@NgModule({
    declarations: [DropdownModalComponent],
    imports: [CommonModule, BsDropdownModule.forRoot()],
})
export class DropdownModalModule {}
