import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

// Services
import { AuthGuardService as AuthGuard } from './services/auth-guard.service';

// Themes
import { MainThemeComponent } from './themes/main-theme/main-theme.component';

// Pages
import { LoginPageComponent } from './modules/public/pages/login-page/login-page.component';
import { LoadingPageComponent } from './modules/public/pages/loading-page/loading-page.component';
import { DashboardPageComponent } from './modules/report/pages/dashboard-page/dashboard-page.component';

const routes: Routes = [
    // { path: '401' },
    // { path: '404' },
    // { path: '500' },
    {
        path: '',
        canActivate: [AuthGuard],
        component: MainThemeComponent,
        children: [
            { path: '', redirectTo: 'report/lead-list', pathMatch: 'full' },
            { path: 'dashboard', component: DashboardPageComponent },
            // { path: 'example', component: ExampleInputComponent },
            {
                path: 'product',
                loadChildren: () => import('./modules/product/product.module').then((m) => m.ProductModule),
            },
            {
                path: 'report',
                loadChildren: () => import('./modules/report/report.module').then((m) => m.ReportModule),
            },
            {
                path: 'order',
                loadChildren: () => import('./modules/order/order.module').then((m) => m.OrderModule),
            },
            {
                path: 'settings',
                loadChildren: () => import('./modules/settings/settings.module').then((m) => m.SettingsModule),
            },
            { path: 'dashboard', component: DashboardPageComponent },
        ],
    },
    { path: 'login', component: LoginPageComponent },
    { path: 'loading', component: LoadingPageComponent },
    { path: '**', redirectTo: 'login' }, // Wildcard route for a 404 page
];

@NgModule({
    imports: [
        RouterModule.forRoot(routes, {
            scrollPositionRestoration: 'top',
            relativeLinkResolution: 'legacy',
            initialNavigation: 'enabledBlocking',
        }),
    ],
    exports: [RouterModule],
})
export class AppRoutingModule {}
