import { Component } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';

@Component({
    selector: 'app-program-period-not-found-modal',
    templateUrl: './program-period-not-found-modal.component.html',
    styleUrls: ['./program-period-not-found-modal.component.scss'],
})
export class ProgramPeriodNotFoundModalComponent {
    constructor(public bsModalRef: BsModalRef) {}

    onClickCloseButton(): void {
        this.bsModalRef.hide();
    }
}
