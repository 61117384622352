import { Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
import { getCampaignPaymentCount } from 'src/app/stores/order-store/order.actions';
import { selectCampaignPaymentCount } from 'src/app/stores/order-store/order.selectors';
import { ICampaignPaymentPaginatedFilter } from '../../models/campaign-payment.model';

@Component({
    selector: 'app-search-order-box',
    templateUrl: './search-order-box.component.html',
    styleUrls: ['./search-order-box.component.scss'],
})
export class SearchOrderBoxComponent implements OnInit, OnDestroy {
    @Output() search: EventEmitter<any> = new EventEmitter<any>();

    paymentCount = {
        all: 0,
        available: 0,
    };

    filter: ICampaignPaymentPaginatedFilter;

    searchTerm = '';
    isLocalSearch = false;
    activedTab = null;
    available = 'available';
    all = 'all';

    private unsubscribe$ = new Subject<void>();

    constructor(private store: Store) {}

    ngOnInit(): void {
        this.subscribeToCampaignPaymentCount();
        this.changeActivedTab(this.available);
    }

    ngOnDestroy(): void {
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
    }

    changeActivedTab(tabId): void {
        this.activedTab = tabId;
        this.isLocalSearch = false;
        this.execute();
    }

    onTypeSearchTerm(e): void {
        this.isLocalSearch = true;
        this.execute();
    }

    onClickSearchButton(): void {
        this.store.dispatch(getCampaignPaymentCount());
        this.isLocalSearch = false;
        this.execute();
    }

    private execute(): void {
        this.filter = {
            page: 1,
            pageEntries: 100,
            clientNamePhoneOrVoucherCode: this.searchTerm,
            hasUsableVoucherTickets: this.activedTab === this.all ? null : true,
            isLocalSearch: this.isLocalSearch,
        };

        this.search.emit(this.filter);
    }

    private subscribeToCampaignPaymentCount(): void {
        this.store.dispatch(getCampaignPaymentCount());
        this.store
            .pipe(
                select(selectCampaignPaymentCount),
                filter((count) => !!count),
                takeUntil(this.unsubscribe$)
            )
            .subscribe((count) => {
                this.paymentCount = count;
            });
    }
}
