<div class="modal-header">
    <button type="button" class="close pull-right" aria-label="Close" (click)="bsModalRef.hide()">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body">
    <div class="phone"></div>
    <h3>หากต้องการเปิดใช้งานรายงานค่าคอม<br />
        กรุณาติดต่อทีมผู้ให้บริการ</h3>
</div>
<div class="modal-footer">
    <div class="button-container">
        <button type="button" class="btn btn-default" (click)="bsModalRef.hide()">ปิด</button>
    </div>
</div>