import { Component } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';

@Component({
    selector: 'app-commission-disabled',
    templateUrl: './commission-disabled.component.html',
    styleUrls: ['./commission-disabled.component.scss'],
})
export class CommissionDisabledComponent {
    constructor(public bsModalRef: BsModalRef, public bsModalService: BsModalService) {}
}
