export class Country {
    public id: number;
    public countryCode: string;
    public nameTh: string;
    public nameEn: string;
    public name_th: string;
    public name_en: string;
    public iconUrl: string;

    public deserialize(obj: any): Country | null {
        if (!obj) {
            return null;
        }

        this.id = obj.id;
        this.countryCode = obj.country_code;
        this.nameTh = this.name_th = obj.name_th;
        this.nameEn = this.name_en = obj.name_en;
        this.iconUrl = obj.icon_url;
        return this;
    }
}
