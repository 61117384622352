<div class="quantity-textbox" *ngIf="!hidden" [ngClass]="{ disabled: disabled }">
    <span [ngClass]="{ disabled: quantity <= min || disabled }" (click)="subtract()" class="control left">-</span>
    <input
        [(ngModel)]="quantity"
        (ngModelChange)="keydown($event)"
        class="input"
        type="text"
        maxlength="4"
        max="{{ max }}"
        min="{{ min }}"
        maxlength="{{ maxlength }}"
        [attr.disabled]="disabled ? true : null"
        readonly
    />
    <span [ngClass]="{ disabled: quantity >= max || disabled }" (click)="add()" class="control right">+</span>
</div>
