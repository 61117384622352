<app-breadcrumb [items]="breadcrumb"></app-breadcrumb>
<h2 class="text-primary mb-4">สรุปรายการรับค่าคอม</h2>
<ng-container *ngIf="orderBalance$ | async as orderBalance">
  <ng-container *ngIf="!orderBalance.notTransfered && !orderBalance.transfered.length else reportTemplate">
    <div class="data-not-found-container">
      <img src="assets/img/icon/return-on-investment.png" alt="">
      ไม่พบรายการ
    </div>
  </ng-container>
  <ng-template #reportTemplate>
    <div class="commission-container" [ngClass]="{'show-less': !showFull}">

      <div class="order-not-transfered-box mb-4" *ngIf="orderBalance.notTransfered">
        <div class="title">
          รอบโอนถัดไป วัน{{orderBalance.notTransfered.transferDueDate.getDay() | thaidayOfWeek}}ที่
          {{orderBalance?.notTransfered?.transferDueDate | thaiDate:'DD MM YYYY'}}
          <span>
            (Order ที่ซื้อระหว่างวันที่
            {{orderBalance?.notTransfered?.orderFirstDate | thaidaterange :
            orderBalance?.notTransfered?.orderLastDate}})
          </span>
        </div>
        <div class="detail">
          <div class="row-1">
            <div class="left">
              จำนวน Order
              <span class="order-qty">{{orderBalance?.notTransfered?.receiptItemQuantity | number}}</span>
              รวม
              <span class="total">{{orderBalance?.notTransfered?.sumAmount | number}}</span>
            </div>
            <div class="right">ค่าคอมที่ได้รับ
              <span class="total-commission">{{orderBalance?.notTransfered?.sumNetCommissionAgency |
                number:'1.2-2'}}</span>
            </div>
          </div>
          <div class="row-2">
            <div class="left"
              (click)="linkToOrderList('/report/order-balance-report/' + orderBalance.notTransfered.id, orderBalance?.notTransfered)">
              <img class="img-icon" src="assets/img/icon/icon-information.svg">
              ดูรายการ Order ของรอบโอนนี้
            </div>
            <div class="right"><span class="label">{{orderBalance?.notTransfered?.moneyComming}}</span></div>
          </div>
        </div>
        <div class="footer">
          บัญชีรับเงิน :
          <span class="bank-account-number">
            {{orderBalance?.notTransfered?.bankAccount?.bank?.name}}
            {{orderBalance?.notTransfered?.bankAccount?.accountNumber}}
          </span>
          ชื่อบัญชี :
          <span class="bank-account-name"> {{orderBalance?.notTransfered?.bankAccount?.accountName}}</span>
        </div>
      </div>
      <div class="order-transfered-box-header" *ngIf="orderBalance.transfered.length">
        โอนแล้วทั้งหมด {{orderBalance.transfered.length}} รอบโอน
      </div>
      <div class="order-transfered-box mb-2" *ngFor="let order of orderBalance.transfered;let i = index">
        <div class="detail">
          <div class="row-1">
            <div class="left">
              {{i+1}}. รอบโอน
              <span class="transfer-date">
                วัน{{order.transferDueDate.getDay() | thaidayOfWeek}}
                ที่
                {{order.transferDueDate | thaiDate:'DD MM YYYY'}}
              </span>
              <span class="order-date">
                (Order ที่ซื้อระหว่างวันที่
                {{order.orderFirstDate | thaidaterange :
                order.orderLastDate}})
              </span>
            </div>
            <div class="right">
              ค่าคอมที่ได้รับ
              <span class="total-commission">{{order.sumNetCommissionAgency | number:'1.2-2'}}</span>
            </div>
          </div>
          <div class="row-2 mb-2">
            <div class="left d-flex flex-column mt-2">
              <div class="order">
                จำนวน Order
                <span class="order-qty"> {{order.receiptItemQuantity | number}}</span>
                รวม
                <span class="total">{{order.sumAmount | number}}</span>
              </div>
              <div class="link">
                <span class="mr-2" (click)="linkToOrderList('/report/order-balance-report/' + order.id, order)">
                  <img class="img-icon" src="assets/img/icon/icon-information.svg">
                  ดูรายการ Order ของรอบโอนนี้
                </span>
                <span (click)="downloadDocument(order)" *ngIf="order.transferDocFileUrl">
                  <img class="img-icon" src="assets/img/icon/icon-file-download.svg">
                  ดาวน์โหลดเอกสารการโอนเงิน
                </span>
              </div>
            </div>
            <div class="right d-flex flex-column">
              <ng-container *ngIf="!order.confirmed">
                <div class="status">รอยืนยันรับเงิน</div>
              </ng-container>
              <ng-container *ngIf="order.confirmed">
                <div class="status">ยืนยันรับเงินแล้ว</div>
                <div class="confirm-date">{{order.confirmedAt | thaiMonthDateTime:true }}</div>
                <div class="confirm-by">โดย {{order.confirmedByAgencyMember.firstName}}
                  {{order.confirmedByAgencyMember.lastName}}</div>
              </ng-container>
            </div>
          </div>

        </div>
        <div class="footer">
          บัญชีรับเงิน :
          <span class="bank-account-number">
            {{order.bankAccount.bank.name}}
            {{order.bankAccount.accountNumber}}
          </span>
          ชื่อบัญชี :
          <span class="bank-account-name">{{order.bankAccount.accountName}}</span>
        </div>
      </div>


    </div>
    <div class="show-more-button mt-4" *ngIf="!showFull && orderBalance.transfered.length > 5">
      <span (click)="showFull = true">ดูประวัติการรับเงินทั้งหมด</span>
    </div>
  </ng-template>

</ng-container>