import { Agency } from 'src/app/models/agency';
import { EditBookingB2cComponent } from '../../../modals/edit-booking-b2c/edit-booking-b2c.component';
import { CancelBookingComponent } from '../../../modals/cancel-booking/cancel-booking.component';
import { FileOverLimitComponent } from '../../../../shared/modals/file-over-limit/file-over-limit.component';
import { ChangeBookingStatusComponent } from '../../../modals/change-booking-status/change-booking-status.component';
import { IInvoiceConfigs } from '../../../../../models/invoice-config.model';
import { ExportInvoiceExcelService } from 'src/app/services/export-invoice-excel.service';
import { LeadBookingInfoComponent } from '../../../modals/lead-booking-info/lead-booking-info.component';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { CATEGORY_SUB_PRODUCTS_TO_STRING } from 'src/app/constants/category-sub-product.constant';
import { LeadContactInfoComponent } from 'src/app/modules/report/modals/b2b-booking/lead-contact-info/lead-contact-info.component';
import { IBooking, IBookingListQueries } from 'src/app/modules/order/models/booking-list.model';
import { BOOKING_STATUS_CODES, BOOKING_STATUS_KEYWORDS } from 'src/app/constants/booking-status-codes.constant';
import { select, Store } from '@ngrx/store';
import { State } from 'src/app/stores/reducers';
import * as OrderActions from 'src/app/stores/order-store/order.actions';
import {
    selectOrderDetail,
    selectBookingNewFile,
    selectModifiedBookingId,
    selectBookingListQueries,
} from 'src/app/stores/order-store/order.selectors';
import { filter, take, takeUntil } from 'rxjs/operators';
import { selectInvoiceConfigs } from 'src/app/stores/setting-store/setting.selectors';
import { SuccessModalComponent } from 'src/app/modules/shared/modals/success-modal/success-modal.component';
import { FileUtil } from 'src/app/utils/file.util';
import { selectAgencyInfo } from 'src/app/stores/user-store/user.selectors';
import * as _ from 'lodash-es';
import { Subject } from 'rxjs';
import { environment } from 'src/environments/environment';

@Component({
    selector: 'app-booking-list-details',
    templateUrl: './booking-list-details.component.html',
    styleUrls: ['./booking-list-details.component.scss'],
})
export class BookingListDetailsComponent implements OnInit, OnDestroy {
    @Input() public index: number;
    @Input() public booking: IBooking;
    private invoiceConfigs: IInvoiceConfigs;

    public agencyInfo: Agency;
    public readonly STATUS_CODES = BOOKING_STATUS_CODES;
    public readonly CATEGORY_SUB_PRODUCTS_TO_STRING = CATEGORY_SUB_PRODUCTS_TO_STRING;
    public readonly STATUS_KEYWORDS = BOOKING_STATUS_KEYWORDS;
    public bsModalRef: BsModalRef;
    public showBottomPart: boolean = false;

    private downloadExcel: boolean = false;
    private searchFilter: IBookingListQueries;

    private unsubscribe$: Subject<void> = new Subject<void>();

    constructor(
        private modalService: BsModalService,
        private store: Store<State>,
        private invoiceExcelService: ExportInvoiceExcelService
    ) {}

    ngOnInit(): void {
        this.store
            .pipe(
                select(selectAgencyInfo),
                filter((agencyInfo) => !!agencyInfo),
                take(1)
            )
            .subscribe((agencyInfo) => (this.agencyInfo = agencyInfo));
        this.subscribeToInvoiceConfigs();
        this.subscribeToNewFileUploads();
    }

    ngOnDestroy(): void {
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
    }

    private subscribeToInvoiceConfigs(): void {
        this.store
            .pipe(
                select(selectInvoiceConfigs),
                filter((resp) => !!resp),
                take(1)
            )
            .subscribe((invoiceConfigs) => {
                this.invoiceConfigs = invoiceConfigs;

                this.store
                    .pipe(
                        select(selectOrderDetail),
                        filter((val) => val !== null),
                        takeUntil(this.unsubscribe$)
                    )
                    .subscribe((orderDetail) => {
                        if (orderDetail.id === this.booking.id) {
                            if (this.downloadExcel) {
                                this.downloadExcel = false;
                                this.invoiceExcelService.exportInvoiceReport(
                                    orderDetail,
                                    this.invoiceConfigs,
                                    this.booking,
                                    this.agencyInfo.productCode
                                );
                            } else {
                                const initialState = {
                                    id: this.booking.id,
                                    orderDetail,
                                };
                                this.bsModalRef = this.modalService.show(EditBookingB2cComponent, {
                                    initialState,
                                    class: 'add-booking-b2c-modal',
                                });
                            }
                            this.store.dispatch(OrderActions.getOrderByIdSuccess({ result: null }));
                        }
                    });
            });
    }

    private subscribeToNewFileUploads(): void {
        this.store
            .pipe(
                select(selectBookingNewFile),
                filter((val) => val !== null && val.bookingId === this.booking.id)
            )
            .subscribe((newFile) => {
                this.store.dispatch(OrderActions.bookingUploadFileSuccess({ bookingNewFile: null }));
                this.booking.orderFiles.unshift({
                    id: newFile.id,
                    fileUrl: newFile.url,
                });
            });
    }

    public openContactInfoModal(): void {
        const initialState = {
            customerName: this.booking.contact.contactName,
            phone: this.booking.contact.phoneNumber,
            email: this.booking.contact.email,
            more_info: this.booking.contact.customerRemark || '-',
        };
        this.bsModalRef = this.modalService.show(LeadContactInfoComponent, { initialState, class: 'contact-modal' });
    }

    public openBusinessContactInfoModal(): void {
        const initialState = {
            id: this.booking.productOwner.id,
            ownerChannel: this.booking.productOwner.channel,
        };
        this.bsModalRef = this.modalService.show(LeadBookingInfoComponent, {
            initialState,
            class: 'company-contact-modal',
        });
    }

    public downloadReport(downloadExcel: boolean): void {
        if (downloadExcel) {
            this.downloadExcel = true;
        }
        this.store.dispatch(OrderActions.getOrderById({ orderId: this.booking.id }));
    }

    public isExpired(date: Date): boolean {
        const dateYesterday = new Date();
        dateYesterday.setDate(new Date().getDate() - 1);
        if (date < dateYesterday) {
            return true;
        }
        return false;
    }

    public getStatus(): string {
        switch (this.booking.order.orderStatus) {
            case this.STATUS_CODES.WAITING_FOR_FIRST_PAYMENT:
                return this.STATUS_KEYWORDS.WAITING_FOR_FIRST_PAYMENT;
            case this.STATUS_CODES.WAITING_FOR_FULL_PAYMENT:
                return this.STATUS_KEYWORDS.WAITING_FOR_FULL_PAYMENT;
            case this.STATUS_CODES.COMPLETE:
                const dateYesterday = new Date();
                dateYesterday.setDate(new Date().getDate() - 1);
                if (this.booking.product.startAt > dateYesterday) {
                    return this.STATUS_KEYWORDS.WAITING_TO_TRAVEL;
                } else {
                    return this.STATUS_KEYWORDS.TRAVELLED;
                }
            case this.STATUS_CODES.CANCEL:
                return this.STATUS_KEYWORDS.CANCELLED;
            default:
                return '';
        }
    }

    public toggleBottomExpand(): void {
        this.showBottomPart = !this.showBottomPart;
    }

    public deleteFile(fileId: number) {
        this.booking.orderFiles = this.booking.orderFiles.filter((file) => file.id !== fileId);

        this.store.dispatch(
            OrderActions.deleteBookingFile({
                bookingOrderId: this.booking.id,
                fileId,
            })
        );
    }

    public openEditBookingModal(): void {
        if (this.booking.order.orderStatus === BOOKING_STATUS_CODES.CANCEL) return;
        this.downloadReport(false);
    }

    public openStatusDropdown(): void {
        this.modalService.onHide.pipe(take(1)).subscribe((status: string) => {
            if (status && status.startsWith('success')) {
                const bookingStatus: number = parseInt(status.split('-')[1]);
                this.booking.order.orderStatus = bookingStatus;
                this.successModalPopup();
                this.getBookingStatusCount();
            }
        });

        const initialState = {
            booking: this.booking,
        };
        this.bsModalRef = this.modalService.show(ChangeBookingStatusComponent, {
            initialState,
            class: 'change-booking-modal',
        });
    }

    public onFileUpload(files: FileList, event: any): void {
        const file: File = files[0];
        const fileSize = FileUtil.getFilesizeAsMB(file);

        const maxFileSizeInMb = 15;

        if (fileSize > maxFileSizeInMb) {
            // Cleaning file input
            event.target.value = '';

            this.bsModalRef = this.modalService.show(FileOverLimitComponent, {
                class: 'over-limit-modal',
            });
            return;
        }

        const formData = new FormData();
        formData.append('file', file, file.name);

        this.store.dispatch(
            OrderActions.bookingUploadFile({
                bookingOrderId: this.booking.id,
                formData,
            })
        );
    }

    public onCancelBooking(): void {
        const initialState = {
            booking: this.booking,
        };
        this.bsModalRef = this.modalService.show(CancelBookingComponent, {
            initialState,
            class: 'cancel-modal',
        });

        this.modalService.onHide
            .pipe(
                take(1),
                filter((reason) => reason === 'confirm-cancel-' + this.booking.id)
            )
            .subscribe(() => {
                this.cancelBookingId(this.booking.id);
            });
    }

    public getFileDisplayUrl(url: string): string {
        return decodeURIComponent(url.split('/').pop());
    }

    private successModalPopup(message?: string): void {
        const initialState = {
            doNotRefresh: true,
            message: message,
        };
        this.bsModalRef = this.modalService.show(SuccessModalComponent, {
            initialState,
            class: 'success-modal',
        });
    }

    private cancelBookingId(bookingId: number): void {
        this.store.dispatch(
            OrderActions.modifyBookingStatus({
                bookingOrderId: bookingId,
                status: BOOKING_STATUS_CODES.CANCEL,
            })
        );

        this.store
            .pipe(
                select(selectModifiedBookingId),
                filter((resp) => !!resp),
                take(1)
            )
            .subscribe(() => {
                this.booking.order.orderStatus = BOOKING_STATUS_CODES.CANCEL;
                this.store.dispatch(
                    OrderActions.modifyBookingStatusSuccess({
                        modifiedBookingId: null,
                    })
                );
                this.successModalPopup('ยกเลิก Booking เรียบร้อยแล้ว');
            });
    }

    private getBookingStatusCount(): void {
        this.store
            .pipe(
                select(selectBookingListQueries),
                filter((resp) => resp !== null)
            )
            .subscribe((queries: IBookingListQueries) => {
                this.searchFilter = _.cloneDeep(queries);
                this.store.dispatch(
                    OrderActions.getBookingStatusCount({
                        dates: Object.assign({}, this.searchFilter.filters.created_at_between),
                    })
                );
            });
    }

    public getOnlineBookingLink(booking: IBooking): string {
        if (booking.productOwner.wsSlug === null) {
            return '#';
        }

        return environment.onlineBookingUrl + booking.productOwner.wsSlug;
    }
}
