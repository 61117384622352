import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';

import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { CKEditorModule } from '@ckeditor/ckeditor5-angular';

// Directives
import { OnlyNumberDirective, MycurrencyDirective } from 'src/app/directives/input-keys/only-number.directive';
import { UnallowedCharactersDirective } from 'src/app/directives/input-keys/unallowed-characters.directive';
import { InputOnlyThaiCharactorDirective } from 'src/app/directives/input-keys/input-only-thai-charactor.directive';
import { InputOnlyEnglishCharactorDirective } from 'src/app/directives/input-keys/input-only-english-charactor.directive';

// Components
import { CalendarInputComponent } from './components/calendar-input/calendar-input.component';
import { BreadcrumbComponent } from './components/breadcrumb/breadcrumb.component';
import { RichTextInputComponent } from './components/rich-text-input/rich-text-input.component';
import { SearchableDropdownComponent } from './components/searchable-dropdown/searchable-dropdown.component';
import { ModalContentComponent } from './components/modal/modal.component';
import { TagsInputComponent } from 'src/app/shared/components/tags-input/tags-input.component';
import { SocialLinksComponent } from './components/social-links/social-links.component';
import { UploadImgComponent } from './components/upload-img/upload-img.component';
import { DaterangeComponent } from './components/daterange/daterange.component';
import { ToggleSwitchComponent } from './components/toggle-switch/toggle-switch.component';
import { QuantityTextboxComponent } from './components/quantity-textbox-v2/quantity-textbox-v2.component';

// Modals
import { SuccessModalComponent } from './modals/success-modal/success-modal.component';
import { ConfirmDeleteModalComponent } from './modals/confirm-delete-modal/confirm-delete-modal.component';
import { ProductOwnerModalComponent } from 'src/app/shared/modals/product-owner-modal/product-owner-modal.component';
import { CustomerInfoModalComponent } from 'src/app/shared/modals/customer-info-modal/customer-info-modal.component';
import { FileOverLimitComponent } from './modals/file-over-limit/file-over-limit.component';

// Services
import { CaterogyTransportService } from 'src/app/services/category-transport.service';
import { CountryService } from 'src/app/services/country.service';
import { TransportationService } from 'src/app/services/transportation.service';

// Pipes
import { CatalogTitleENPipe, CatalogTitlePipe } from 'src/app/pipes/product.pipe';
import { DisplayProvincesPipe } from 'src/app/pipes/province.pipe';
import { DisplayProvincesViewPipe } from 'src/app/pipes/provinceView.pipe';
import { DisplayCountriesPipe } from 'src/app/pipes/country.pipe';
import { DisplayCountriesViewPipe } from 'src/app/pipes/countryView.pipe';
import { DisplayImageDefaultPipe, GetImagePreviewPipe } from 'src/app/pipes/imageDefault.pipe';
import { DescriptionPipe } from 'src/app/pipes/description.pipe';
import { OrderPeriodByDealPipe } from 'src/app/pipes/periods.pipe';
import { DisplayTransportPipe } from 'src/app/pipes/transport.pipe';
import { MycurrencyPipe } from 'src/app/pipes/money.pipe';
import { ProductCategoryPipe, ProductCategoryTwBookingPipe } from 'src/app/pipes/product-category.pipe';
import { PhoneNumberPipe } from './../../pipes/phone-number.pipe';
import {
    ThaiDateRangePipe,
    ThaiMonthDatetimePipe,
    ThaiMonthRangePipe,
    ThaiMonthDatePipe,
    ThaiDatePipe,
    ThaiMonthPipe,
    DayOfWeekInThaiPipe,
    ThaiDateWithoutYearPipe,
} from 'src/app/pipes/thaidate.pipe';
import { SanitizeHtmlPipe } from 'src/app/pipes/sanitize-html.pipe';
import { ProductDiscountPipe } from 'src/app/pipes/product-price.pipe';
import { CampaignCategoryPipe } from 'src/app/pipes/campaign-category.pipe';

import { ModalModule } from 'ngx-bootstrap/modal';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { CollapseModule } from 'ngx-bootstrap/collapse';
import { TabsModule } from 'ngx-bootstrap/tabs';
import { SlickCarouselModule } from 'ngx-slick-carousel';
import { NgxCurrencyModule } from 'ngx-currency';

import { DiscountPercentagePipe } from 'src/app/pipes/discount-percentage.pipe';
import { PartnerContactModalComponent } from './modals/partner-contact-modal/partner-contact-modal.component';

import { defineLocale, thBeLocale } from 'ngx-bootstrap/chronos';
import { StarComponent } from './components/star/star.component';
import { ToggleButtonComponent } from './components/toggle-button/toggle-button.component';
import { OrderStatusPipe } from 'src/app/pipes/order-status.pipe';
import { FileTypePipe } from 'src/app/pipes/tw-order-file-type.pipe';

defineLocale('th-be', thBeLocale);

const PIPES = [
    CatalogTitlePipe,
    CatalogTitleENPipe,
    DisplayProvincesPipe,
    DisplayProvincesViewPipe,
    DisplayCountriesPipe,
    DisplayCountriesViewPipe,
    ThaiMonthRangePipe,
    ThaiMonthDatePipe,
    ThaiMonthDatetimePipe,
    ThaiMonthPipe,
    DayOfWeekInThaiPipe,
    ThaiDateWithoutYearPipe,
    DisplayImageDefaultPipe,
    GetImagePreviewPipe,
    DescriptionPipe,
    OrderPeriodByDealPipe,
    DisplayTransportPipe,
    MycurrencyPipe,
    PhoneNumberPipe,
    ProductCategoryPipe,
    ProductCategoryTwBookingPipe,
    SanitizeHtmlPipe,
    DiscountPercentagePipe,
    ProductDiscountPipe,
    CampaignCategoryPipe,
    OrderStatusPipe,
    FileTypePipe,
];

const DIRECTIVES = [
    OnlyNumberDirective,
    MycurrencyDirective,
    UnallowedCharactersDirective,
    InputOnlyThaiCharactorDirective,
    InputOnlyEnglishCharactorDirective,
];

const COMPONENTS = [
    CalendarInputComponent,
    BreadcrumbComponent,
    RichTextInputComponent,
    SearchableDropdownComponent,
    TagsInputComponent,
    SocialLinksComponent,
    UploadImgComponent,
    DaterangeComponent,
    ModalContentComponent,
    FileOverLimitComponent,
    StarComponent,
    ToggleButtonComponent,
    ToggleSwitchComponent,
    QuantityTextboxComponent,
];

const MODALS = [
    SuccessModalComponent,
    ConfirmDeleteModalComponent,
    ProductOwnerModalComponent,
    PartnerContactModalComponent,
    CustomerInfoModalComponent,
];

const STANDALONE = [ThaiDatePipe, ThaiDateRangePipe];

@NgModule({
    imports: [
        CommonModule,
        ...STANDALONE,
        RouterModule,
        BsDatepickerModule.forRoot(),
        CKEditorModule,
        BsDropdownModule.forRoot(),
        ModalModule.forRoot(),
        ReactiveFormsModule,
        FormsModule,
        CollapseModule,
        TabsModule.forRoot(),
        SlickCarouselModule,
        NgxCurrencyModule,
    ],
    declarations: [...PIPES, ...DIRECTIVES, ...COMPONENTS, ...MODALS],
    exports: [
        ...PIPES,
        ...DIRECTIVES,
        ...COMPONENTS,
        ...MODALS,
        ...STANDALONE,
        BsDropdownModule,
        ReactiveFormsModule,
        FormsModule,
        CollapseModule,
        TabsModule,
        SlickCarouselModule,
        BsDatepickerModule,
        NgxCurrencyModule,
    ],
    providers: [
        CountryService,
        CaterogyTransportService,
        TransportationService,
        ThaiMonthDatePipe,
        ThaiDateWithoutYearPipe,
        ThaiMonthPipe,
        PhoneNumberPipe,
        DayOfWeekInThaiPipe,
    ],
})
export class SharedModule {}
