import {
    Component,
    OnInit,
    EventEmitter,
    Output,
    Input,
    Inject,
    PLATFORM_ID,
    ViewChild,
    SimpleChanges,
    OnChanges,
} from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { Store } from '@ngrx/store';
import { State } from 'src/app/stores/reducers';
import { GALLERY_FILE_TYPE } from 'src/app/constants/gallery.constant';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { UploadAdapter } from './upload-adapter';

// https://ckeditor.com/docs/ckeditor5/latest/builds/guides/integration/frameworks/angular.html
// import Editor from 'src/assets/scripts/ckeditor';
import { ChangeEvent, CKEditor5, CKEditorComponent } from '@ckeditor/ckeditor5-angular';
// import * as CKEditor from 'src/assets/scripts/ckeditor';
import { interval } from 'rxjs';

@Component({
    selector: 'app-rich-text-input',
    templateUrl: './rich-text-input.component.html',
    styleUrls: ['./rich-text-input.component.scss'],
})
export class RichTextInputComponent implements OnInit, OnChanges {
    @ViewChild('editor') editorComponent: CKEditorComponent;

    @Input() placeholder: string;
    @Input() value: string = null;
    @Input() optionalFields?: RichTextInputOptinalFields;
    @Output() valueChange = new EventEmitter<string>();

    public Editor;
    public isBrowser = false;
    public toolbar: string[];
    public config: any;

    public model = {
        editorData: '',
    };

    private loadDataLoop: any;
    private loadDataSuccess = false;

    constructor(
        @Inject(PLATFORM_ID) protected platformId: Object,
        private store: Store<State>,
        private ngxService: NgxUiLoaderService
    ) {
        this.isBrowser = isPlatformBrowser(platformId);
        if (this.isBrowser) {
            this.Editor = require('src/assets/scripts/ckeditor');
        }
    }

    ngOnInit(): void {
        this.setToolbar();
        this.setConfig();

        this.loadDataLoop = interval(500).subscribe((x) => {
            if (this.loadDataSuccess) {
                this.loadDataLoop.unsubscribe();
            }

            this.getEditor();
        });
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.value && changes.value.currentValue) {
            this.model.editorData = changes.value.currentValue;
        }
    }

    public getEditor() {
        if (this.editorComponent && this.editorComponent.editorInstance) {
            this.editorComponent.editorInstance.setData(this.model.editorData);
            this.loadDataSuccess = true;
        }
    }

    private setConfig(): void {
        this.config = {
            toolbar: this.toolbar,
            placeholder: this.placeholder,
            fillEmptyBlocks: true,
            image: {
                styles: ['alignLeft', 'alignCenter', 'alignRight'],
                toolbar: [
                    'imageStyle:alignLeft',
                    'imageStyle:alignCenter',
                    'imageStyle:alignRight',
                    '|',
                    'imageTextAlternative',
                    'linkImage',
                ],
            },
            table: {
                contentToolbar: [
                    'tableColumn',
                    'tableRow',
                    'mergeTableCells',
                    'tableProperties',
                    'tableCellProperties',
                ],
            },
            mediaEmbed: {
                previewsInData: true,
                removeProviders: ['instagram', 'twitter', 'googleMaps', 'flickr', 'facebook'],
            },
            link: {
                addTargetToExternalLinks: true,
            },
            heading: {
                options: [
                    { model: 'heading2', view: 'h2', title: 'Heading 2', class: 'ck-heading_heading2' },
                    { model: 'heading3', view: 'h3', title: 'Heading 3', class: 'ck-heading_heading3' },
                    { model: 'heading4', view: 'h4', title: 'Heading 4', class: 'ck-heading_heading4' },
                    { model: 'heading5', view: 'h5', title: 'Heading 5', class: 'ck-heading_heading5' },
                    { model: 'heading6', view: 'h6', title: 'Heading 6', class: 'ck-heading_heading6' },
                    { model: 'paragraph', title: 'Paragraph', class: 'ck-heading_paragraph' },
                ],
            },
        };
    }

    public uploadAdapterPlugin(editor: CKEditor5.Editor): void {
        editor.plugins.get('FileRepository').createUploadAdapter = (loader: any) => {
            return new UploadAdapter(loader, this.store, this.optionalFields.imageSettings, this.ngxService);
        };
    }

    public onChange({ editor }: ChangeEvent) {
        if (editor) {
            const data = editor.getData();
            this.valueChange.emit(data);
        }
    }

    private setToolbar(): void {
        const toolbar = ['bold', 'italic'];

        if (this.optionalFields) {
            if (this.optionalFields.heading) {
                toolbar.splice(0, 0, 'heading');
            }
            if (this.optionalFields.underline) {
                toolbar.push('Underline');
            }
            if (this.optionalFields.fontSize) {
                toolbar.push('FontSize');
            }
            if (this.optionalFields.textColour) {
                toolbar.push('FontColor');
            }
            if (this.optionalFields.imageSettings) {
                toolbar.push('|', 'imageUpload');
            }
            if (this.optionalFields.link) {
                toolbar.push('link');
            }
            if (this.optionalFields.mediaEmbed) {
                toolbar.push('mediaEmbed');
            }
        }

        toolbar.push(
            'insertTable',
            '|',
            'NumberedList',
            'BulletedList',
            '|',
            'alignment:left',
            'alignment:right',
            'alignment:center',
            'alignment:justify'
        );

        if (this.optionalFields) {
            if (this.optionalFields.sourceEditor) {
                toolbar.push('|');
                toolbar.push('sourceEditing');
            }
        }

        this.toolbar = toolbar;
    }
}

export class RichTextInputOptinalFields {
    heading?: any;
    underline?: boolean;
    fontSize?: boolean;
    textColour?: boolean;
    imageSettings?: {
        galleryId: number;
        galleryFileType: GALLERY_FILE_TYPE;
    };
    link?: boolean;
    mediaEmbed?: boolean;
    sourceEditor?: boolean;
}
