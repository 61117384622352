import { SEO_ARTICLE_TYPES } from 'src/app/constants/seo-article-types.enum';

export class SeoArticleType {
    id: number;
    slug: SEO_ARTICLE_TYPES;
    name: string;

    public deserialize(data: any): this {
        this.id = +data.id;
        this.slug = data.slug;
        this.name = data.name;

        return this;
    }
}
