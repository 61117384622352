import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'displayProvinces',
})
export class DisplayProvincesPipe implements PipeTransform {
    transform(provinces: any[], delimiter = ','): any {
        if (!provinces.length) {
            return '';
        }

        let value = '';
        provinces.forEach((province) => {
            const nameTh: string = province.nameTh || province.name_th;

            if (!value) {
                value += nameTh;
            } else {
                value += delimiter + nameTh;
            }
        });
        return value;
    }
}
