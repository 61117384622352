import { IBooking } from 'src/app/modules/order/models/booking-list.model';
import { IInvoiceConfigs } from './../models/invoice-config.model';
import { Injectable } from '@angular/core';
import { Borders, CellValue, FillPattern, Font, Worksheet } from 'exceljs';
import * as moment from 'moment';
import { IInvoiceReport } from '../models/invoice-report.model';
import { OrderDetail } from '../models/order.model';
import { ExcelServiceBase } from './base/excel.service';
import { StringUtil } from '../utils/string.util';

@Injectable({
    providedIn: 'root',
})
export class ExportInvoiceExcelService extends ExcelServiceBase {
    private rowIndexProductHeader: number = 12;
    private rowIndexTotalAmount: number;
    private rowIndexProductRemark: number;
    private rowIndexInstallments: number;
    private rowIndexBankHeader: number;
    private rowIndexPaymentTerms: number;

    public exportInvoiceReport(
        orderDetail: OrderDetail,
        invoiceConfigs: IInvoiceConfigs,
        booking: IBooking,
        agencyProductCode: string
    ) {
        const report: IInvoiceReport = this.getReportBody(orderDetail, invoiceConfigs, booking, agencyProductCode);
        try {
            //Declare variables
            const yearMonth = moment().format('YYMM');
            const filename = `${report.fileName}`;
            const worksheet = this.createWorksheet(filename);

            //Static info
            this.bindCompanyInfo(worksheet, report);
            this.bindClientInfo(worksheet, report);
            this.bindProductInfo(worksheet, report);
            //Static info

            //Dynamic info
            this.rowIndex = 18;
            this.bindPassengersInfo(worksheet, report);
            this.bindProductRemark(worksheet, report);
            this.bindTotalAmount(worksheet, report);
            this.bindInstallmentsInfo(worksheet, report);
            this.bindBanksInfo(worksheet, report);
            this.bindPaymentTermsInfo(worksheet, report);
            //Dynamic info

            this.adjustLayoutAndColor(worksheet, report);

            // Export to excel file
            this.generateFile(filename);
        } catch (error) {
            throw new Error(error);
        }
    }

    private getReportBody(
        orderDetail: OrderDetail,
        invoiceConfigs: IInvoiceConfigs,
        booking: IBooking,
        agencyProductCode: string
    ): IInvoiceReport {
        const report: IInvoiceReport = {
            fileName: booking.order.orderCode,
            documentName: invoiceConfigs.documentName,
            companyLogo: invoiceConfigs.logoBase64,
            companyNameEnglish: invoiceConfigs.companyNameEnglish,
            companyNameThai: invoiceConfigs.companyNameThai,
            companyAddress: invoiceConfigs.address,
            companyPhoneNumber: invoiceConfigs.phoneNumber,
            companyEmailAddress: invoiceConfigs.emailAddress,
            companyLicenseNumber: invoiceConfigs.licenseNumber,
            clientName: orderDetail.contactName,
            clientPhoneNumber: orderDetail.phoneNumber,
            clientEmailAddress: orderDetail.emailAddress,
            invoiceNumber: booking.order.orderCode,
            bookingDate: moment(booking.createdAt).format('DD/MM/YYYY'),
            SalesName:
                booking.sellerAgencyMember.firstName +
                ' ' +
                booking.sellerAgencyMember.lastName +
                ' (' +
                booking.sellerAgencyMember.nickName +
                ')',
            period: `${moment(orderDetail.startDate).format('DD/MM/YYYY')} - ${moment(orderDetail.endDate).format(
                'DD/MM/YYYY'
            )}`,
            invoiceProducts: {
                productInfo:
                    booking?.product?.categoryProductsId === 1
                        ? booking?.product?.tourCode
                            ? `${agencyProductCode}-${booking.product.tourCode} ${booking.product.productName}`
                            : `${agencyProductCode} ${booking.product.productName}`
                        : booking.product.productName,
                countryInfo: orderDetail.locationString,
                travelBy: orderDetail.transportName ? `เดินทางโดย ${orderDetail.transportName}` : '',
                remark: orderDetail.customerRemark,
                passengers: orderDetail.orderItems.map((x) => {
                    return {
                        type: x.description,
                        unit: x.quantity,
                        price: x.price,
                        amount: x.total,
                    };
                }),
                totalAmount: booking.order.sumTotal,
                totalNetAmount: booking.order.sumTotal,
            },
            installments: orderDetail.orderInstallments.map((x) => {
                return {
                    installments: x.id,
                    date: moment(x.paymentDueDate).format('DD/MM/YYYY'),
                    amount: x.totalPaid,
                };
            }),
            banks: invoiceConfigs.bankAccounts.map((x) => {
                return {
                    name: x.name,
                    accountName: x.accountName,
                    branchName: x.branch,
                    accountType: x.accountType,
                    accountNumber: x.accountNumber,
                };
            }),
            paymentTerms: StringUtil.removeHtml(invoiceConfigs.paymentCondition),
            authorizedBy: invoiceConfigs.approverName,
            invoiceDate: moment().format('DD/MM/YYYY'),
        };
        return report;
    }

    private adjustLayoutAndColor(worksheet: Worksheet, report: IInvoiceReport) {
        //Adjust column width
        worksheet.getColumn(5).width = 7;
        worksheet.getColumn(7).width = 6;
        worksheet.getColumn(8).width = 10;

        //Set font to cells
        const genericFont: Partial<Font> = {
            name: 'Tahoma',
            size: 9,
        };
        worksheet.getRows(1, this.rowIndex).forEach((row) => {
            row.font = genericFont;
        });

        //Set font to title
        ['I1', 'C1', 'C2'].forEach((cellKey) => {
            worksheet.getCell(cellKey).font = {
                ...genericFont,
                size: 12,
                bold: true,
            };
        });

        //Style product header
        worksheet.getRow(this.rowIndexProductHeader).eachCell((cell) => {
            (<FillPattern>cell.fill) = {
                type: 'pattern',
                pattern: 'solid',
                fgColor: { argb: '757171' },
                bgColor: { argb: '757171' },
            };
            cell.font = {
                ...genericFont,
                color: { argb: 'FFFFFF' },
            };
            cell.alignment = { horizontal: 'center' };
        });

        //Style product remark
        worksheet.getCell(`A${this.rowIndexProductRemark}`).font = {
            ...genericFont,
            color: { argb: 'aeaaaa' },
        };

        //Style total amount
        worksheet.getCell(`E${this.rowIndexTotalAmount}`).font = { ...genericFont, bold: true };
        worksheet.getCell(`J${this.rowIndexTotalAmount}`).font = { ...genericFont, bold: true, size: 10 };
        worksheet.getRow(this.rowIndexTotalAmount).eachCell((cell) => {
            (<FillPattern>cell.fill) = {
                type: 'pattern',
                pattern: 'lightGray',
                fgColor: { argb: 'd9d9d9' },
                bgColor: { argb: 'd9d9d9' },
            };
            const borders: Partial<Borders> = { top: { style: 'thin' }, bottom: { style: 'thin' } };
            if (cell.address === `J${this.rowIndexTotalAmount}`) {
                borders.right = { style: 'thin' };
            }
            cell.border = borders;
        });

        //Style total net amount
        worksheet.getCell(`E${this.rowIndexTotalAmount + 1}`).font = { ...genericFont, bold: true };
        worksheet.getCell(`J${this.rowIndexTotalAmount + 1}`).font = { ...genericFont, bold: true, size: 10 };
        worksheet.getRow(this.rowIndexTotalAmount + 1).eachCell((cell) => {
            (<FillPattern>cell.fill) = {
                type: 'pattern',
                pattern: 'lightGray',
                fgColor: { argb: 'd9d9d9' },
                bgColor: { argb: 'd9d9d9' },
            };
            const borders: Partial<Borders> = { top: { style: 'thin' }, bottom: { style: 'thin' } };
            if (cell.address === `J${this.rowIndexTotalAmount + 1}`) {
                borders.right = { style: 'thin' };
            }
            cell.border = borders;
        });

        //Style installments
        worksheet.getRows(this.rowIndexInstallments, report.installments.length).forEach((row) => {
            row.eachCell((cell) => {
                cell.font = { ...genericFont, size: 8 };
            });
        });

        //Style bank header
        worksheet.getRow(this.rowIndexBankHeader).eachCell((cell) => {
            (<FillPattern>cell.fill) = {
                type: 'pattern',
                pattern: 'solid',
                fgColor: { argb: 'f2f2f2' },
                bgColor: { argb: 'f2f2f2' },
            };
            const borders: Partial<Borders> = { top: { style: 'thin' } };
            if (cell.address === `J${this.rowIndexBankHeader}`) {
                borders.right = { style: 'thin' };
            }
            cell.border = borders;
            cell.font = { ...genericFont, bold: true };
        });

        //Style bank content
        const bankRows = worksheet.getRows(this.rowIndexBankHeader + 1, report.banks.length);
        if (bankRows !== undefined) {
            bankRows.forEach((row) => {
                row.eachCell((cell) => {
                    cell.font = { ...genericFont, size: 8 };
                });
            });
        }

        //Style payment terms
        worksheet.getCell(`A${this.rowIndexPaymentTerms}`).border = {
            top: { style: 'thin' },
            right: { style: 'thin' },
        };
        worksheet.getCell(`A${this.rowIndexPaymentTerms}`).font = { ...genericFont, size: 8 };

        //Set border to sheet
        worksheet.getRow(1).eachCell((cell) => {
            cell.border = { top: { style: 'thin' } };
        });
        worksheet.getRow(this.rowIndex).eachCell((cell) => {
            cell.border = { bottom: { style: 'thin' } };
        });

        for (let index = 1; index <= this.rowIndex; index++) {
            const rightBorders: Partial<Borders> = { right: { style: 'thin' } };
            const leftBorders: Partial<Borders> = { left: { style: 'thin' } };
            worksheet.getCell(`A${index}`).border = { ...worksheet.getCell(`A${index}`).border, ...leftBorders };
            worksheet.getCell(`J${index}`).border = { ...worksheet.getCell(`J${index}`).border, ...rightBorders };
        }
    }

    private bindCompanyInfo(worksheet: Worksheet, report: IInvoiceReport) {
        const companyLogoId = this.workbook.addImage({
            base64: report.companyLogo,
            extension: 'jpeg',
        });

        worksheet.addImage(companyLogoId, 'A1:B6');

        worksheet.mergeCells('I1:J1');
        worksheet.getCell('I1').value = report.documentName;

        worksheet.mergeCells('C1:H1');
        worksheet.getCell('C1').value = report.companyNameEnglish;

        worksheet.mergeCells('C2:H2');
        worksheet.getCell('C2').value = report.companyNameThai;

        worksheet.mergeCells('C3:H4');
        worksheet.getCell('C3').value = report.companyAddress;
        worksheet.getCell('C3').alignment = { wrapText: true, vertical: 'top' };

        worksheet.mergeCells('C5:H5');
        worksheet.getCell('C5').value = `โทร ${report.companyPhoneNumber}`;

        worksheet.mergeCells('C6:H6');
        worksheet.getCell('C6').value = `อีเมล ${report.companyEmailAddress}`;

        worksheet.mergeCells('C7:H7');
        worksheet.getCell('C7').value = `เลขใบอนุญาตนำเที่ยว ${report.companyLicenseNumber}`;
    }

    private bindClientInfo(worksheet: Worksheet, report: IInvoiceReport) {
        worksheet.mergeCells('A8:D9');
        worksheet.getCell('A8').value = `ชื่อผู้ติดต่อ (ลูกค้า)/Contact Name : ${report.clientName}`;
        worksheet.getCell('A8').alignment = { wrapText: true, vertical: 'top' };

        worksheet.mergeCells('A10:D10');
        worksheet.getCell('A10').value = `เบอร์ติดต่อ : ${report.clientPhoneNumber}`;

        worksheet.mergeCells('A11:D11');
        worksheet.getCell('A11').value = `อีเมล/Email : ${report.clientEmailAddress}`;

        worksheet.getCell('E8').value = 'ใบเรียกเก็บเงินเลขที่ :';
        worksheet.mergeCells('G8:J8');
        worksheet.getCell('G8').value = report.invoiceNumber;

        worksheet.getCell('E9').value = 'วันที่จอง/Booking Date :';
        worksheet.mergeCells('G9:J9');
        worksheet.getCell('G9').value = report.bookingDate;

        worksheet.getCell('E10').value = 'พนักงานขาย/Sale : ';
        worksheet.mergeCells('G10:J10');
        worksheet.getCell('G10').value = report.SalesName;

        worksheet.getCell('E11').value = 'วันเดินทาง/Period :';
        worksheet.mergeCells('G11:J11');
        worksheet.getCell('G11').value = report.period;
    }

    private bindProductInfo(worksheet, report: IInvoiceReport) {
        worksheet.mergeCells('A12:E12');
        worksheet.getCell('A12').value = 'Description';

        worksheet.getCell('F12').value = 'Unit';

        worksheet.mergeCells('G12:H12');
        worksheet.getCell('G12').value = 'Price';

        worksheet.mergeCells('I12:J12');
        worksheet.getCell('I12').value = 'Amount';

        worksheet.mergeCells('A13:E15');
        worksheet.getCell('A13').value = report.invoiceProducts.productInfo;
        worksheet.getCell('A13').alignment = { wrapText: true, vertical: 'top' };

        worksheet.mergeCells('A16:E16');
        worksheet.getCell('A16').value = report.invoiceProducts.countryInfo;

        worksheet.mergeCells('A17:E17');
        worksheet.getCell('A17').value = report.invoiceProducts.travelBy;
    }

    private bindPassengersInfo(worksheet: Worksheet, report: IInvoiceReport) {
        const totalPassenger = report.invoiceProducts.passengers.length;
        const rows = worksheet.getRows(this.rowIndex, totalPassenger);
        rows.forEach((passengerRow, index) => {
            const passengerInfo = report.invoiceProducts.passengers[index];
            worksheet.mergeCells(`B${this.rowIndex + index}:E${this.rowIndex + index}`);
            passengerRow.getCell('B').value = passengerInfo.type;

            passengerRow.getCell('F').value = passengerInfo.unit;
            passengerRow.getCell('F').alignment = { horizontal: 'center' };

            worksheet.mergeCells(`G${this.rowIndex + index}:H${this.rowIndex + index}`);
            passengerRow.getCell('G').value = this.parseFloat(passengerInfo.price);
            passengerRow.getCell('G').numFmt = this.NumberFormat;
            passengerRow.getCell('G').alignment = { horizontal: 'right' };

            worksheet.mergeCells(`I${this.rowIndex + index}:J${this.rowIndex + index}`);
            passengerRow.getCell('I').value = this.parseFloat(passengerInfo.amount);
            passengerRow.getCell('I').numFmt = this.NumberFormat;
            passengerRow.getCell('I').alignment = { horizontal: 'right' };
        });

        this.rowIndex += totalPassenger;
    }

    private bindProductRemark(worksheet, report: IInvoiceReport) {
        this.getCell(worksheet, 'A').value = 'หมายเหตุ (Remark)';
        this.rowIndex++;

        const remarkRows = 2;
        this.rowIndexProductRemark = this.rowIndex;
        worksheet.mergeCells(`A${this.rowIndex}:E${this.rowIndex + remarkRows}`);
        this.rowIndex += remarkRows;
        this.getCell(worksheet, 'A').value = report.invoiceProducts.remark;
        this.getCell(worksheet, 'A').alignment = { wrapText: true, vertical: 'top' };
        this.rowIndex++;
    }

    private bindTotalAmount(worksheet: Worksheet, report: IInvoiceReport) {
        this.rowIndexTotalAmount = this.rowIndex;
        worksheet.mergeCells(`A${this.rowIndex}:D${this.rowIndex}`);
        worksheet.mergeCells(`E${this.rowIndex}:G${this.rowIndex}`);
        this.getCell(worksheet, 'E').value = 'จำนวนเงินรวม';

        worksheet.mergeCells(`H${this.rowIndex}:J${this.rowIndex}`);
        this.getCell(worksheet, 'H').value = this.parseFloat(report.invoiceProducts.totalAmount);
        this.getCell(worksheet, 'H').numFmt = this.NumberFormat;
        this.getCell(worksheet, 'H').alignment = { horizontal: 'right' };
        this.rowIndex++;

        worksheet.mergeCells(`A${this.rowIndex}:D${this.rowIndex}`);
        (<Partial<CellValue>>this.getCell(worksheet, 'A').value) = {
            formula: `CONCATENATE("-", BAHTTEXT(H${this.rowIndex}), "-")`,
            result: '',
        };
        this.getCell(worksheet, 'A').alignment = { horizontal: 'center' };

        worksheet.mergeCells(`E${this.rowIndex}:G${this.rowIndex}`);
        this.getCell(worksheet, 'E').value = 'จำนวนเงินจ่ายสุทธิ/Total';

        worksheet.mergeCells(`H${this.rowIndex}:J${this.rowIndex}`);
        this.getCell(worksheet, 'H').value = this.parseFloat(report.invoiceProducts.totalNetAmount);
        this.getCell(worksheet, 'H').numFmt = this.NumberFormat;
        this.getCell(worksheet, 'H').alignment = { horizontal: 'right' };
        this.rowIndex++;
    }

    private bindInstallmentsInfo(worksheet: Worksheet, report: IInvoiceReport) {
        this.rowIndexInstallments = this.rowIndex;
        const totalItems = report.installments.length;
        const rows = worksheet.getRows(this.rowIndex, totalItems);
        rows.forEach((row, index) => {
            const info = report.installments[index];
            row.getCell('A').value = `ชำระเงินงวดที่ ${index + 1} ภายในวันที่`;
            row.getCell('C').value = info.date;
            row.getCell('D').value = 'จำนวน';
            worksheet.mergeCells(`E${this.rowIndex + index}:F${this.rowIndex + index}`);
            row.getCell('F').value = this.parseFloat(info.amount);
            row.getCell('F').numFmt = this.NumberFormat;
            row.getCell('F').alignment = { horizontal: 'right' };
            row.getCell('G').value = 'บาท';
        });

        this.rowIndex += totalItems;
    }

    private bindBanksInfo(worksheet, report: IInvoiceReport) {
        this.rowIndexBankHeader = this.rowIndex;

        worksheet.mergeCells(`A${this.rowIndex}:B${this.rowIndex}`);
        this.getCell(worksheet, 'A').value = 'ธนาคาร';

        worksheet.mergeCells(`C${this.rowIndex}:E${this.rowIndex}`);
        this.getCell(worksheet, 'C').value = 'ชื่อบัญชี';

        worksheet.mergeCells(`F${this.rowIndex}:G${this.rowIndex}`);
        this.getCell(worksheet, 'F').value = 'สาขา';

        this.getCell(worksheet, 'H').value = 'ประเภทบัญชี';

        worksheet.mergeCells(`I${this.rowIndex}:J${this.rowIndex}`);
        this.getCell(worksheet, 'J').value = 'บัญชี';

        this.rowIndex++;

        const totalItems = report.banks.length;
        const rows = worksheet.getRows(this.rowIndex, totalItems);
        if (rows !== undefined) {
            rows.forEach((row, index) => {
                const info = report.banks[index];
                worksheet.mergeCells(`A${this.rowIndex + index}:B${this.rowIndex + index}`);
                row.getCell('A').value = info.name;
                worksheet.mergeCells(`C${this.rowIndex + index}:E${this.rowIndex + index}`);
                row.getCell('C').value = info.accountName;
                worksheet.mergeCells(`F${this.rowIndex + index}:G${this.rowIndex + index}`);
                row.getCell('F').value = info.branchName;
                row.getCell('H').value = info.accountType;
                worksheet.mergeCells(`I${this.rowIndex + index}:J${this.rowIndex + index}`);
                row.getCell('I').value = info.accountNumber;
            });
        }

        this.rowIndex += totalItems;
    }

    private bindPaymentTermsInfo(worksheet, report: IInvoiceReport) {
        this.rowIndexPaymentTerms = this.rowIndex;

        const paymentTermLines = report.paymentTerms ? report.paymentTerms.split('\n') : [];
        paymentTermLines.forEach((line) => {
            if (line.trim() !== '') {
                worksheet.mergeCells(`A${this.rowIndex}:J${this.rowIndex}`);
                this.getCell(worksheet, 'A').value = line;
                this.getCell(worksheet, 'A').alignment = { wrapText: true };
                this.rowIndex++;
            }
        });

        this.getCell(worksheet, 'A').value = 'ผู้รับอนุมัติ';
        this.getCell(worksheet, 'B').value = report.authorizedBy;
        this.getCell(worksheet, 'H').value = 'วันที่/Date';
        this.getCell(worksheet, 'I').value = report.invoiceDate;
        this.rowIndex++;

        worksheet.mergeCells(`A${this.rowIndex}:J${this.rowIndex}`);
        this.getCell(worksheet, 'A').value = 'Authorized By';
    }

    private parseFloat(value: any): number {
        if (typeof value == 'string') {
            return parseFloat(value);
        }
        if (typeof value == 'number') {
            return value;
        }
        return 0;
    }
}
