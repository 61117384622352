import { Component, OnInit } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
import { IBreadcrumb } from 'src/app/models/breadcrumb.model';
import * as OrderActions from 'src/app/stores/order-store/order.actions';
import { selectProductOrderListPaginated } from 'src/app/stores/order-store/order.selectors';
import { ProductOrderListPaginated, ProductOrderListSearchCriteria } from '../../models/product-order-list.model';

@Component({
    selector: 'app-program-order-list-page',
    templateUrl: './program-order-list-page.component.html',
    styleUrls: ['./program-order-list-page.component.scss'],
})
export class ProgramOrderListPageComponent implements OnInit {
    public breadcrumb: IBreadcrumb[];
    public productOrders: ProductOrderListPaginated;
    public backupProductOrders: ProductOrderListPaginated;
    public searchCriteria: ProductOrderListSearchCriteria;
    private page = 1;
    private unsubscribe$: Subject<void> = new Subject<void>();

    constructor(private store: Store) {}

    ngOnInit(): void {
        this.setBreadcrumb();

        this.store
            .pipe(
                select(selectProductOrderListPaginated),
                filter((x) => !!x),
                takeUntil(this.unsubscribe$)
            )
            .subscribe((r) => {
                this.productOrders = r;
            });
    }

    private dispatchOrderListPaginated() {
        this.store.dispatch(
            OrderActions.getProductOrderListPaginated({
                productSubCategorySlug: this.searchCriteria.productSubCategorySlug,
                orderStatusSlug: this.searchCriteria.orderStatusSlug,
                searchString: this.searchCriteria.searchString,
                page: this.page,
                pageEntries: 100,
                sortBy: 'created_at_desc',
            })
        );
    }

    public searchProductOrder(searchCriteria: ProductOrderListSearchCriteria): void {
        this.page = 1;
        this.searchCriteria = searchCriteria;

        if (this.backupProductOrders) {
            this.productOrders.result = JSON.parse(JSON.stringify(this.backupProductOrders));
        }

        if (searchCriteria.isLocalSearch) {
            this.backupProductOrders = JSON.parse(JSON.stringify(this.productOrders.result));
            const searchTerm = this.searchCriteria.searchString.toLowerCase();
            if (searchTerm.length >= 3) {
                this.productOrders.result = this.productOrders.result.filter(
                    (p) =>
                        p.customerName?.toLowerCase()?.indexOf(searchTerm) !== -1 ||
                        p.customerPhoneNumber?.toLowerCase()?.indexOf(searchTerm) !== -1 ||
                        p.productTourCode?.toLowerCase()?.indexOf(searchTerm) !== -1 ||
                        p.orderCode?.toLowerCase()?.indexOf(searchTerm) !== -1
                );
            }
        } else {
            this.dispatchOrderListPaginated();
        }
    }

    public loadMore(): void {
        this.page++;
        this.dispatchOrderListPaginated();
    }

    private setBreadcrumb(): void {
        this.breadcrumb = [
            { name: 'หน้าแรก', link: '/dashboard' },
            { name: 'ข้อมูลการซื้อโปรแกรมทัวร์', link: '/order/program-list' },
        ];
    }
}
