import { Directive, ElementRef, HostListener } from '@angular/core';

@Directive({
    selector: '[appInputOnlyEnglishCharactor]',
})
export class InputOnlyEnglishCharactorDirective {
    private regexForEnglishChars = /[a-zA-z]/;
    private regexForNumbers = /[0-9]/;
    private regexForSpecialChars = /[-_!@#$%^&*(),.?"':{}|<>]/;
    private regexForWhiteSpaceChars = /\s/;

    constructor(private el: ElementRef) {}

    @HostListener('keydown', ['$event']) onKeyDown(e: KeyboardEvent) {
        let isKeyAllowed = true;
        if (e.key.length == 1) {
            if (e.metaKey || e.ctrlKey) {
                /** detecting cmd or ctrl */
                isKeyAllowed = true;
            } else if (
                new RegExp(
                    '^(' +
                        this.regexForEnglishChars.source +
                        '|' +
                        this.regexForSpecialChars.source +
                        '|' +
                        this.regexForNumbers.source +
                        '|' +
                        this.regexForWhiteSpaceChars.source +
                        ')$'
                ).test(e.key) === false
            ) {
                isKeyAllowed = false;
            }
        }
        if (!isKeyAllowed) {
            e.preventDefault();
        }
    }

    @HostListener('paste', ['$event']) onPaste(e: ClipboardEvent) {
        const text = e.clipboardData.getData('text');
        if (
            new RegExp(
                '^(' +
                    this.regexForEnglishChars.source +
                    '|' +
                    this.regexForSpecialChars.source +
                    '|' +
                    this.regexForNumbers.source +
                    '|' +
                    this.regexForWhiteSpaceChars.source +
                    ')*$'
            ).test(text) === false
        ) {
            e.preventDefault();
        }
    }
}
