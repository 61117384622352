<div class="main container-fluid">
    <section id="filters">
        <ng-container *ngIf="mode === MODE.VIEW || mode === MODE.EDIT">
            <div class="text-right mb-1">
                <a (click)="toggleEditMode()" class="change-mode-link">
                    <ng-container *ngIf="mode === MODE.VIEW">แก้ไข Order</ng-container>
                    <ng-container *ngIf="mode === MODE.EDIT">ยกเลิกการแก้ไข</ng-container>
                </a>
            </div>
        </ng-container>
        <div class="row">
            <div class="col-4">
                <div class="form-group row mb-0">
                    <label class="col-auto col-form-label" for="">ประเทศ</label>
                    <div class="col col-form-dropdown">
                        <app-searchable-dropdown
                            id="countryDropdown"
                            #countryDropdown
                            [isBorderRed]="countryDropdownRequired"
                            [settings]="countryDropdownSettings"
                            [items]="countryDropdownItems"
                            [disabled]="mode === MODE.VIEW"
                            (selected)="onCountrySelected($event)"
                        >
                        </app-searchable-dropdown>
                    </div>
                </div>
            </div>
            <div class="col-8">
                <div class="form-group row mb-0">
                    <label class="col-1 col-form-label" for="">โปรแกรม</label>
                    <div class="col-11 col-form-dropdown">
                        <app-searchable-dropdown
                            id="programDropdown"
                            #programDropdown
                            [isBorderRed]="programDropdownRequired"
                            [settings]="programDropdownSettings"
                            [items]="programDropdownItems"
                            [disabled]="mode === MODE.VIEW"
                            (selected)="onProgramSelected($event)"
                        >
                        </app-searchable-dropdown>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <section class="program-detail" class="mt-3">
        <div *ngIf="program" class="d-flex">
            <div class="program-cover">
                <img [src]="program.bannerUrl" alt="{{ program.name }}" />
            </div>
            <div class="program-info">
                <div>
                    <div class="title">{{ program.name }}</div>
                    <div class="mb-2">
                        <span class="info"> <i class="mr-10px icon icon-location"></i>{{ program.countryList }} </span>
                        <span class="info"
                            ><i class="mr-10px icon icon-calendar"></i>{{ program.durationDay }} วัน
                            {{ program.durationNight }} คืน</span
                        >
                        <span *ngIf="period && period.goTransportation" class="info">
                            <img class="mr-10px" src="assets/img/icon/icon-flight-go.svg" />
                            {{ period.goTransportation.flight_number || '-' }}
                        </span>
                        <span *ngIf="period && period.backTransportation" class="info">
                            <img class="mr-10px" src="assets/img/icon/icon-flight-arrival.svg" />
                            {{ period.backTransportation.flight_number || '-' }}
                        </span>
                    </div>
                    <div>
                        <a
                            *ngIf="program.productPdfUrl"
                            target="_blank"
                            [href]="program.productPdfUrl"
                            class="btn btn-file"
                        >
                            <img src="assets/img/icon/icon-pdf.svg" />
                            pdf
                        </a>
                        <a
                            *ngIf="program.productDocumentUrl"
                            target="_blank"
                            [href]="program.productDocumentUrl"
                            class="btn btn-file ml-1"
                        >
                            <img src="assets/img/icon/icon-word.svg" />
                            word
                        </a>
                        <a
                            *ngIf="program.bannerUrl"
                            target="_blank"
                            [href]="program.bannerUrl"
                            class="btn btn-file ml-1"
                        >
                            <img src="assets/img/icon/icon-image.svg" />
                            image
                        </a>
                    </div>
                </div>
                <div>
                    <div class="form-group row">
                        <label id="period-selector" class="col-auto col-form-label" for="">ช่วงเวลาเดินทาง</label>
                        <div class="col-4 col-form-dropdown">
                            <div
                                #periodDropdown
                                class="period-dropdown"
                                [ngClass]="{ disabled: mode === MODE.VIEW }"
                                (click)="togglePeriodDropdownlist()"
                            >
                                <div class="d-flex" *ngIf="!period">
                                    <span>เลือกช่วงเวลาเดินทาง</span>
                                    <i
                                        style="margin-top: 4px; margin-right: 7px"
                                        class="icon icon-arrow-down ml-auto cursor-pointer"
                                    ></i>
                                </div>
                                <div class="d-flex" *ngIf="period">
                                    <div>
                                        <span class="period-selected">
                                            {{ getPeriodTime(period.startAt, period.endAt) }}
                                        </span>
                                        <span
                                            class="period-available"
                                            *ngIf="period.sellStatusCode === 1 && period.quantityRemaining > 0"
                                        >
                                            ว่าง ({{ period.quantityRemaining }})
                                        </span>
                                        <span *ngIf="period.sellStatusCode === 1 && period.quantityRemaining <= 0">
                                            เต็ม
                                        </span>
                                        <span *ngIf="period.sellStatusCode !== 1">
                                            ({{ period.sellStatusReason }})
                                        </span>
                                    </div>
                                    <i
                                        style="margin-top: 4px; margin-right: 7px"
                                        class="icon icon-arrow-down ml-auto cursor-pointer"
                                    ></i>
                                </div>
                                <div *ngIf="!hidePeriodDropdownlist" class="item-list">
                                    <div (click)="onPeriodSelected(item)" *ngFor="let item of periodList">
                                        <span class="period-selected">
                                            {{ getPeriodTime(item.startAt, item.endAt) }}
                                        </span>
                                        <span
                                            class="period-available"
                                            *ngIf="item.sellStatusCode === 1 && item.quantityRemaining > 0"
                                        >
                                            ว่าง ({{ item.quantityRemaining }})
                                        </span>
                                        <span *ngIf="item.sellStatusCode === 1 && item.quantityRemaining <= 0">
                                            เต็ม
                                        </span>
                                        <span *ngIf="item.sellStatusCode !== 1"> ({{ item.sellStatusReason }}) </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <ng-container *ngIf="period">
                            <label for="" class="col-auto col-form-label">ค่าคอม</label>
                            <div class="col align-items-center">
                                <span class="form-control-plaintext">
                                    {{ period.commissionCompany | number: '1.0-0' }}
                                    <ng-container *ngIf="period.commissionSeller">
                                        + {{ period.commissionSeller | number: '1.0-0' }}
                                    </ng-container>
                                </span>
                            </div>
                        </ng-container>
                    </div>
                </div>
            </div>
        </div>
    </section>
</div>
