<div class="modal-header">
    <button type="button" class="close pull-right" aria-label="Close" (click)="bsModalRef.hide()">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body">
    <div class="exclamation-mark"></div>
    <h3>กรุณาอัปโหลดไฟล์ขนาดไม่เกิน 15 MB</h3>
</div>
<div class="modal-footer">
    <button type="button" class="btn btn-default" (click)="bsModalRef.hide()">ปิด</button>
</div>
