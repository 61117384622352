<ng-container *ngIf="isBrowser">
    <ckeditor
        *ngIf="isBrowser"
        #editor
        [config]="config"
        [(ngModel)]="model.editorData"
        [editor]="Editor"
        (ready)="uploadAdapterPlugin($event)"
        (change)="onChange($event)"
    >
    </ckeditor>
</ng-container>
