import { PortfolioService } from './../../services/portfolio.service';
import { Injectable } from '@angular/core';
import { createEffect, Actions, ofType } from '@ngrx/effects';
import { switchMap, map, catchError } from 'rxjs/operators';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { of } from 'rxjs';
import { ConfigBannersService } from 'src/app/services/config-banners.service';
import { BannerConfigs } from 'src/app/modules/settings/models/config-banners.model';
import * as SettingActions from './setting.actions';
import { CustomContentService } from 'src/app/services/custom-content.service';
import { ICustomContent } from 'src/app/models/custom-content.model';
import { CustomContentUpdaterResponse } from './models/custom-content-updater-response.model';
import { BlogService } from 'src/app/services/blog.service';
import { InvoiceService } from 'src/app/services/invoice.service';
import { InvoiceConfigs } from 'src/app/models/invoice-config.model';
import { SeoArticleService } from 'src/app/services/seo-article.service';
import { SeoArticleListItem } from 'src/app/modules/settings/models/seo-article/seo-article-list-item.model';
import { SeoTargetPageTypeService } from 'src/app/services/seo-target-page-type.service';
import { TargetPageType } from 'src/app/modules/settings/models/seo-article/target-page-type.model';
import { SeoSpecialPageService } from 'src/app/services/seo-special-page.service';
import { SpecialPage } from 'src/app/modules/settings/models/seo-article/special-page.model';
import { SeoArticleOptionItem } from 'src/app/modules/settings/models/seo-article/seo-article-option-item.model';
import { ISeoArticle, SeoArticle } from 'src/app/modules/settings/models/seo-article/seo-article.model';

@Injectable()
export class SettingEffects {
    constructor(
        private actions$: Actions,
        private ngxService: NgxUiLoaderService,
        private configBannersService: ConfigBannersService,
        private customContentService: CustomContentService,
        private portfolioService: PortfolioService,
        private blogService: BlogService,
        private invoiceService: InvoiceService,
        private seoArticleService: SeoArticleService,
        private seoTargetPageTypeService: SeoTargetPageTypeService,
        private seoSpecialPageService: SeoSpecialPageService
    ) {}

    loadConfigBanners = createEffect(() => () =>
        this.actions$.pipe(
            ofType(SettingActions.loadConfigBanners),
            switchMap(() => {
                this.ngxService.start();
                return this.configBannersService.loadConfigBanners().pipe(
                    map((resp) => {
                        this.ngxService.stop();
                        if (resp.status === 'success') {
                            const configBanners = new BannerConfigs().deserialize(resp.data);
                            return SettingActions.loadConfigBannersSuccess({
                                configBanners,
                            });
                        } else {
                            return SettingActions.loadConfigBannersError({
                                error: JSON.stringify(resp.error),
                            });
                        }
                    }),
                    catchError((error) => {
                        this.ngxService.stop();
                        return of(
                            SettingActions.loadConfigBannersError({
                                error,
                            })
                        );
                    })
                );
            })
        )
    );

    updateConfigBanners = createEffect(() => () =>
        this.actions$.pipe(
            ofType(SettingActions.updateConfigBanners),
            switchMap((action) => {
                this.ngxService.start();
                return this.configBannersService.updateConfigBanners(action.requestBody).pipe(
                    map((resp) => {
                        this.ngxService.stop();
                        if (resp.status === 'success') {
                            return SettingActions.updateConfigBannersSuccess();
                        } else {
                            return SettingActions.updateConfigBannersError({
                                error: resp.error,
                            });
                        }
                    }),
                    catchError((error) => {
                        this.ngxService.stop();
                        return of(
                            SettingActions.updateConfigBannersError({
                                error,
                            })
                        );
                    })
                );
            })
        )
    );

    loadCustomContent = createEffect(() => () =>
        this.actions$.pipe(
            ofType(SettingActions.loadCustomContent),
            switchMap((action) => {
                this.ngxService.start();
                return this.customContentService.getCustomContent(action.contentId).pipe(
                    map((resp) => {
                        this.ngxService.stop();
                        if (resp.status === 'success') {
                            const content: ICustomContent = {
                                id: resp.data.id,
                                menuNameTH: resp.data.menu_name_th,
                                content: resp.data.content,
                                contentGalleriesId: resp.data.content_galleries_id,
                            };

                            return SettingActions.loadCustomContentSuccess({
                                content,
                            });
                        } else {
                            return SettingActions.loadCustomContentFailure({
                                error: JSON.stringify(resp.error),
                            });
                        }
                    }),
                    catchError((error) => {
                        this.ngxService.stop();
                        return of(
                            SettingActions.loadCustomContentFailure({
                                error,
                            })
                        );
                    })
                );
            })
        )
    );

    updateCustomContent = createEffect(() => () =>
        this.actions$.pipe(
            ofType(SettingActions.updateCustomContent),
            switchMap((action) => {
                this.ngxService.start();
                return this.customContentService.editCustomContent(action.content.id, action.content).pipe(
                    map((resp) => {
                        this.ngxService.stop();
                        const customContentUpdaterResponse = new CustomContentUpdaterResponse().deserialize(resp);
                        if (customContentUpdaterResponse.status === 'success') {
                            return SettingActions.updateCustomContentSuccess({
                                response: customContentUpdaterResponse,
                            });
                        } else {
                            return SettingActions.updateCustomContentFailure({
                                error: customContentUpdaterResponse,
                            });
                        }
                    }),
                    catchError((error) => {
                        this.ngxService.stop();
                        return of(
                            SettingActions.updateCustomContentFailure({
                                error,
                            })
                        );
                    })
                );
            })
        )
    );

    getPortfolioListing = createEffect(() => () =>
        this.actions$.pipe(
            ofType(SettingActions.getPortfolioListing),
            switchMap((action) => {
                this.ngxService.start();
                return this.portfolioService.getPortfolioListing(action.criteria).pipe(
                    map((resp) => {
                        this.ngxService.stop();
                        if (resp.status === 'success') {
                            return SettingActions.getPortfolioListingSuccess({
                                response: resp.data,
                            });
                        } else {
                            return SettingActions.getPortfolioListingFailure({
                                error: resp.data,
                            });
                        }
                    }),
                    catchError((error) => {
                        this.ngxService.stop();
                        return of(
                            SettingActions.getPortfolioListingFailure({
                                error,
                            })
                        );
                    })
                );
            })
        )
    );

    getPortfolioSingle = createEffect(() => () =>
        this.actions$.pipe(
            ofType(SettingActions.getPortfolioSingle),
            switchMap((action) => {
                this.ngxService.start();
                return this.portfolioService.getPortfolioSingle(action.id).pipe(
                    map((resp) => {
                        this.ngxService.stop();
                        if (resp.status === 'success') {
                            return SettingActions.getPortfolioSingleSuccess({
                                response: resp.data,
                            });
                        } else {
                            return SettingActions.getPortfolioSingleFailure({
                                error: resp.data,
                            });
                        }
                    }),
                    catchError((error) => {
                        this.ngxService.stop();
                        return of(
                            SettingActions.getPortfolioSingleFailure({
                                error,
                            })
                        );
                    })
                );
            })
        )
    );

    addPortfolioItem = createEffect(() => () =>
        this.actions$.pipe(
            ofType(SettingActions.addPortfolioItem),
            switchMap((action) => {
                this.ngxService.start();
                return this.portfolioService.addPortfolioItem(action.requestBody).pipe(
                    map((resp) => {
                        this.ngxService.stop();
                        if (resp.status === 'success') {
                            return SettingActions.addPortfolioItemSuccess({
                                portfolioNewId: +resp.data.agcy_portfolios_id,
                            });
                        } else {
                            return SettingActions.addPortfolioItemError({
                                error: resp.data,
                            });
                        }
                    }),
                    catchError((error) => {
                        this.ngxService.stop();
                        return of(
                            SettingActions.addPortfolioItemError({
                                error,
                            })
                        );
                    })
                );
            })
        )
    );

    editPortfolioItem = createEffect(() => () =>
        this.actions$.pipe(
            ofType(SettingActions.editPortfolioItem),
            switchMap((action) => {
                this.ngxService.start();
                return this.portfolioService.editPortfolioItem(action.id, action.requestBody).pipe(
                    map((resp) => {
                        this.ngxService.stop();
                        if (resp.status === 'success') {
                            return SettingActions.editPortfolioItemSuccess({
                                portfolioEditedId: +resp.data.agcy_portfolios_id,
                            });
                        } else {
                            return SettingActions.editPortfolioItemError({
                                error: resp.data,
                            });
                        }
                    }),
                    catchError((error) => {
                        this.ngxService.stop();
                        return of(
                            SettingActions.editPortfolioItemError({
                                error,
                            })
                        );
                    })
                );
            })
        )
    );

    deletePortfolioItem = createEffect(() => () =>
        this.actions$.pipe(
            ofType(SettingActions.deletePortfolioItem),
            switchMap((action) => {
                this.ngxService.start();
                return this.portfolioService.deletePortfolioItem(action.id).pipe(
                    map((resp) => {
                        this.ngxService.stop();
                        if (resp.status === 'success') {
                            return SettingActions.deletePortfolioItemSuccess({
                                portfolioDeletedId: +resp.data.agcy_portfolios_id,
                            });
                        } else {
                            return SettingActions.deletePortfolioItemError({
                                error: resp.data,
                            });
                        }
                    }),
                    catchError((error) => {
                        this.ngxService.stop();
                        return of(
                            SettingActions.deletePortfolioItemError({
                                error,
                            })
                        );
                    })
                );
            })
        )
    );

    updateIncentiveImg = createEffect(() => () =>
        this.actions$.pipe(
            ofType(SettingActions.updateIncentiveImg),
            switchMap((action) => {
                this.ngxService.start();
                return this.portfolioService.updateIncentiveImg(action.form).pipe(
                    map((resp) => {
                        this.ngxService.stop();
                        if (resp.status === 'success') {
                            return SettingActions.updateIncentiveImgSuccess({
                                response: resp.data,
                            });
                        } else {
                            return SettingActions.updateIncentiveImgError({
                                error: resp.data,
                            });
                        }
                    }),
                    catchError((error) => {
                        this.ngxService.stop();
                        return of(
                            SettingActions.updateIncentiveImgError({
                                error,
                            })
                        );
                    })
                );
            })
        )
    );

    getIncentiveImg = createEffect(() => () =>
        this.actions$.pipe(
            ofType(SettingActions.getIncentiveImg),
            switchMap((action) => {
                this.ngxService.start();
                return this.portfolioService.getIncentiveImg().pipe(
                    map((resp) => {
                        this.ngxService.stop();
                        if (resp.status === 'success') {
                            return SettingActions.getIncentiveImgSuccess({
                                url: resp.data.incentive_url || null,
                            });
                        } else {
                            return SettingActions.getIncentiveImgError({
                                error: resp.data,
                            });
                        }
                    }),
                    catchError((error) => {
                        this.ngxService.stop();
                        return of(
                            SettingActions.getIncentiveImgError({
                                error,
                            })
                        );
                    })
                );
            })
        )
    );

    getBlogListing = createEffect(() => () =>
        this.actions$.pipe(
            ofType(SettingActions.getBlogListing),
            switchMap((action) => {
                this.ngxService.start();
                return this.blogService.getBlogListing(action.criteria).pipe(
                    map((resp) => {
                        this.ngxService.stop();
                        if (resp.status === 'success') {
                            return SettingActions.getBlogListingSuccess({
                                response: resp.data,
                            });
                        } else {
                            return SettingActions.getBlogListingFailure({
                                error: resp.data,
                            });
                        }
                    }),
                    catchError((error) => {
                        this.ngxService.stop();
                        return of(
                            SettingActions.getBlogListingFailure({
                                error,
                            })
                        );
                    })
                );
            })
        )
    );

    getBlogSingle = createEffect(() => () =>
        this.actions$.pipe(
            ofType(SettingActions.getBlogSingle),
            switchMap((action) => {
                this.ngxService.start();
                return this.blogService.getBlogSingle(action.id).pipe(
                    map((resp) => {
                        this.ngxService.stop();
                        if (resp.status === 'success') {
                            return SettingActions.getBlogSingleSuccess({
                                response: resp.data,
                            });
                        } else {
                            return SettingActions.getBlogSingleFailure({
                                error: resp.data,
                            });
                        }
                    }),
                    catchError((error) => {
                        this.ngxService.stop();
                        return of(
                            SettingActions.getBlogSingleFailure({
                                error,
                            })
                        );
                    })
                );
            })
        )
    );

    addnewBlog = createEffect(() => () =>
        this.actions$.pipe(
            ofType(SettingActions.addBlog),
            switchMap((action) => {
                this.ngxService.start();
                return this.blogService.addBlogItem(action.blogDetails).pipe(
                    map((resp) => {
                        this.ngxService.stop();
                        if (resp.status === 'success') {
                            return SettingActions.addBlogSuccess({
                                newBlogId: resp.data.agcy_blogs_id,
                            });
                        } else {
                            return SettingActions.addBlogFailure({
                                error: resp.data,
                            });
                        }
                    }),
                    catchError((error) => {
                        this.ngxService.stop();
                        return of(
                            SettingActions.addBlogFailure({
                                error,
                            })
                        );
                    })
                );
            })
        )
    );

    editExistingBlog = createEffect(() => () =>
        this.actions$.pipe(
            ofType(SettingActions.editBlog),
            switchMap((action) => {
                this.ngxService.start();
                return this.blogService.editBlogItem(action.id, action.blogDetails).pipe(
                    map((resp) => {
                        this.ngxService.stop();
                        if (resp.status === 'success') {
                            return SettingActions.editBlogSuccess({
                                editedBlogId: resp.data.agcy_blogs_id,
                            });
                        } else {
                            return SettingActions.editBlogFailure({
                                error: resp.data,
                            });
                        }
                    }),
                    catchError((error) => {
                        this.ngxService.stop();
                        return of(
                            SettingActions.editBlogFailure({
                                error,
                            })
                        );
                    })
                );
            })
        )
    );

    deleteExistingBlog = createEffect(() => () =>
        this.actions$.pipe(
            ofType(SettingActions.deleteBlog),
            switchMap((action) => {
                this.ngxService.start();
                return this.blogService.deleteBlogItem(action.id).pipe(
                    map((resp) => {
                        this.ngxService.stop();
                        if (resp.status === 'success') {
                            return SettingActions.deleteBlogSuccess({
                                deletedBlogId: resp.data.agcy_blogs_id,
                            });
                        } else {
                            return SettingActions.deleteBlogFailure({
                                error: resp.data,
                            });
                        }
                    }),
                    catchError((error) => {
                        this.ngxService.stop();
                        return of(
                            SettingActions.deleteBlogFailure({
                                error,
                            })
                        );
                    })
                );
            })
        )
    );

    loadInvoiceConfigs = createEffect(() => () =>
        this.actions$.pipe(
            ofType(SettingActions.loadInvoiceConfigs),
            switchMap((action) => {
                this.ngxService.start();
                return this.invoiceService.getInvoiceConfigs().pipe(
                    map((resp) => {
                        this.ngxService.stop();
                        if (resp.status === 'success') {
                            const result = new InvoiceConfigs().deserialize(resp.data);
                            return SettingActions.loadInvoiceConfigsSuccess({ invoiceConfigs: result });
                        } else {
                            throw new Error('Invoice configs loaded unsuccessfully');
                        }
                    }),
                    catchError((error) => {
                        this.ngxService.stop();
                        return of(
                            SettingActions.loadInvoiceConfigsFailure({
                                error,
                            })
                        );
                    })
                );
            })
        )
    );

    updateInvoiceConfigs = createEffect(() => () =>
        this.actions$.pipe(
            ofType(SettingActions.updateInvoiceConfigs),
            switchMap((action) => {
                this.ngxService.start();
                return this.invoiceService.putInvoiceConfigs(action.invoiceConfigs).pipe(
                    map((resp) => {
                        this.ngxService.stop();
                        if (resp.status === 'success') {
                            return SettingActions.updateInvoiceConfigsSuccess();
                        } else {
                            throw new Error('Invoice configs update unsuccessfully');
                        }
                    }),
                    catchError((error) => {
                        this.ngxService.stop();
                        return of(
                            SettingActions.updateInvoiceConfigsFailure({
                                error,
                            })
                        );
                    })
                );
            })
        )
    );

    getSeoArticleListing = createEffect(() => () =>
        this.actions$.pipe(
            ofType(SettingActions.getSeoArticleListing),
            switchMap((action) => {
                this.ngxService.start();
                return this.seoArticleService.getAllSeoArticles(action.filter).pipe(
                    map((resp) => {
                        this.ngxService.stop();
                        if (resp.status === 'success') {
                            return SettingActions.getSeoArticleListingSuccess({
                                articles: SeoArticleListItem.deserializeMany(resp.data, {
                                    maxNesting: action.maxNesting,
                                    parentNodeInfo: null,
                                }),
                            });
                        } else {
                            return SettingActions.getSeoArticleListingFailure({ error: resp });
                        }
                    }),
                    catchError((error) => {
                        this.ngxService.stop();
                        console.error(error);
                        return of(
                            SettingActions.getSeoArticleListingFailure({
                                error: error,
                            })
                        );
                    })
                );
            })
        )
    );

    getSeoArticleAsOptions = createEffect(() => () =>
        this.actions$.pipe(
            ofType(SettingActions.getSeoArticleAsOptions),
            switchMap((action) => {
                this.ngxService.start();
                return this.seoArticleService.getAllSeoArticlesAsOptions(action.filter).pipe(
                    map((resp) => {
                        this.ngxService.stop();
                        if (resp.status === 'success') {
                            return SettingActions.getSeoArticleAsOptionsSuccess({
                                articles: (resp.data as any[]).map((data) =>
                                    new SeoArticleOptionItem().deserialize(data)
                                ),
                            });
                        } else {
                            return SettingActions.getSeoArticleAsOptionsFailure({ error: resp });
                        }
                    }),
                    catchError((error) => {
                        this.ngxService.stop();
                        return of(SettingActions.getSeoArticleAsOptionsFailure({ error: error }));
                    })
                );
            })
        )
    );

    updateSeoArticleStatus = createEffect(() => () =>
        this.actions$.pipe(
            ofType(SettingActions.updateSeoArticleStatus),
            switchMap((action) => {
                this.ngxService.start();
                return this.seoArticleService.updateSeoArticleStatus(action.articleId, action.isActive).pipe(
                    map((resp) => {
                        this.ngxService.stop();
                        if (resp.status === 'success') {
                            return SettingActions.updateSeoArticleStatusSuccess({
                                articleId: resp.data.id,
                                isActive: resp.data.is_active,
                            });
                        } else {
                            return SettingActions.updateSeoArticleStatusFailure({ error: resp });
                        }
                    }),
                    catchError((error) => {
                        this.ngxService.stop();
                        return of(SettingActions.updateSeoArticleStatusFailure({ error: error }));
                    })
                );
            })
        )
    );

    getSeoTargetPageTypes = createEffect(() => () =>
        this.actions$.pipe(
            ofType(SettingActions.getSeoTargetPageTypes),
            switchMap(() => {
                this.ngxService.start();
                return this.seoTargetPageTypeService.getAllTargetPageTypes().pipe(
                    map((resp) => {
                        this.ngxService.stop();
                        if (resp.status === 'success') {
                            return SettingActions.getSeoTargetPageTypesSuccess({
                                targetPageTypes: (resp.data as any[]).map((data) =>
                                    new TargetPageType().deserialize(data)
                                ),
                            });
                        } else {
                            return SettingActions.getSeoTargetPageTypesFailure({ error: resp });
                        }
                    }),
                    catchError((error) => {
                        this.ngxService.stop();
                        return of(
                            SettingActions.getSeoTargetPageTypesFailure({
                                error: error,
                            })
                        );
                    })
                );
            })
        )
    );

    getSeoSpecialPages = createEffect(() => () =>
        this.actions$.pipe(
            ofType(SettingActions.getSeoSpecialPages),
            switchMap(() => {
                this.ngxService.start();
                return this.seoSpecialPageService.getAllSpecialPages().pipe(
                    map((resp) => {
                        this.ngxService.stop();
                        if (resp.status === 'success') {
                            return SettingActions.getSeoSpecialPagesSuccess({
                                seoSpecialPages: (resp.data as any[]).map((data) =>
                                    new SpecialPage().deserialize(data)
                                ),
                            });
                        } else {
                            return SettingActions.getSeoSpecialPagesFailure({ error: resp });
                        }
                    }),
                    catchError((error) => {
                        this.ngxService.stop();
                        return of(
                            SettingActions.getSeoSpecialPagesFailure({
                                error: error,
                            })
                        );
                    })
                );
            })
        )
    );

    addNewSeoArticle = createEffect(() => () =>
        this.actions$.pipe(
            ofType(SettingActions.addNewSeoArticle),
            switchMap((action) => {
                this.ngxService.start();
                return this.seoArticleService.newSeoArticle(action.param).pipe(
                    map((resp) => {
                        this.ngxService.stop();
                        if (resp.status === 'success') {
                            return SettingActions.addNewSeoArticleSuccess({ articleId: resp.data.id });
                        } else {
                            return SettingActions.addNewSeoArticleFailure({ error: resp });
                        }
                    }),
                    catchError((error) => {
                        this.ngxService.stop();
                        return of(
                            SettingActions.addNewSeoArticleFailure({
                                error: error,
                            })
                        );
                    })
                );
            })
        )
    );

    getSeoArticle = createEffect(() => () =>
        this.actions$.pipe(
            ofType(SettingActions.getSeoArticle),
            switchMap((action) => {
                this.ngxService.start();
                return this.seoArticleService.getSeoArticle(action.id).pipe(
                    map((resp) => {
                        this.ngxService.stop();
                        if (resp.status === 'success') {
                            const seoArticle: SeoArticle = new SeoArticle().deserialize(resp);
                            return SettingActions.getSeoArticleSuccess({ data: seoArticle });
                        } else {
                            return SettingActions.getSeoArticleFailure({ error: resp });
                        }
                    }),
                    catchError((error) => {
                        this.ngxService.stop();
                        return of(
                            SettingActions.getSeoArticleFailure({
                                error: error,
                            })
                        );
                    })
                );
            })
        )
    );

    updateSeoArticleContent = createEffect(() => () =>
        this.actions$.pipe(
            ofType(SettingActions.updateSeoArticleContent),
            switchMap((action) => {
                this.ngxService.start();
                return this.seoArticleService.updateSeoArticleContent(action.articleId, action.content).pipe(
                    map((resp) => {
                        this.ngxService.stop();
                        if (resp.status === 'success') {
                            return SettingActions.updateSeoArticleContentSuccess({ articleId: resp.data.id });
                        } else {
                            return SettingActions.updateSeoArticleContentFailure({ error: resp });
                        }
                    }),
                    catchError((error) => {
                        this.ngxService.stop();
                        return of(
                            SettingActions.updateSeoArticleContentFailure({
                                error: error,
                            })
                        );
                    })
                );
            })
        )
    );

    updateSeoArticle = createEffect(() => () =>
        this.actions$.pipe(
            ofType(SettingActions.updateSeoArticle),
            switchMap((action) => {
                this.ngxService.start();
                return this.seoArticleService.updateSeoArticle(action.articleId, action.payload).pipe(
                    map((resp) => {
                        this.ngxService.stop();
                        if (resp.status === 'success') {
                            return SettingActions.updateSeoArticleSuccess({ articleId: resp.data.id });
                        } else {
                            return SettingActions.updateSeoArticleFailure({ error: resp });
                        }
                    }),
                    catchError((error) => {
                        this.ngxService.stop();
                        return of(
                            SettingActions.updateSeoArticleFailure({
                                error: error,
                            })
                        );
                    })
                );
            })
        )
    );
}
