import { Voucher } from 'src/app/modules/product/models/campaign-detail.model';
import { BannerImage } from '../banner-image.model';

export class CampaignHilight {
    id: number;
    name: string;
    hilightDescription: string;
    twmDetailPagePath: string;

    mainBannerImageFile: BannerImage;
    bannerImageFile: BannerImage[];

    experienceImageFiles: BannerImage[];

    recommendedVoucher: Voucher;
    lowestVoucherPrice: number;

    public deserialize(data: any): this {
        this.id = data.id;
        this.name = data.name;
        this.hilightDescription = data.hilight_description;
        this.twmDetailPagePath = data.twm_detail_page_path;
        this.mainBannerImageFile = new BannerImage().deserialize(data.main_banner_image_file);
        this.bannerImageFile = data.banner_image_files.map((banner) => new BannerImage().deserialize(banner));
        this.experienceImageFiles = data.experience_image_files.map((banner) => new BannerImage().deserialize(banner));
        this.recommendedVoucher = new Voucher().deserialize(data.recommended_voucher);
        this.lowestVoucherPrice = +data.voucher_min_price;
        return this;
    }
}
