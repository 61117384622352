import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { ENDPOINTS } from '../constants/endpoint.constant';
import { HttpService } from './base/http.service';

@Injectable({
    providedIn: 'root',
})
export class SeoTargetPageTypeService {
    constructor(private httpService: HttpService) {}

    public getAllTargetPageTypes(): Observable<any> {
        const url = environment.apiProUrl + ENDPOINTS.SEO_TARGET_PAGE_TYPE.GET_LIST;
        return this.httpService.get(url);
    }
}
