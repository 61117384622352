import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { Subject } from 'rxjs';
import { first, take, takeUntil, tap } from 'rxjs/operators';
import { ITwOrderSortOption, TW_ORDER_SORT_OPTION } from 'src/app/constants/tw-order-sort-list.constant';
import { IBreadcrumb } from 'src/app/models/breadcrumb.model';
import { PaginationResponse } from 'src/app/models/pagination-response.model';
import { TourwowOrderService } from 'src/app/services/tourwow-order.service';
import { environment } from 'src/environments/environment';
import { ITwBookingPaginatedFilter } from '../../models/interfaces/tw-booking-interface/tw-booking-paginated-filter.interface';
import { TwBooking } from '../../models/tw-booking-model/tw-booking.model';

@Component({
    selector: 'app-tw-booking-list-page',
    templateUrl: './tw-booking-list-page.component.html',
    styleUrls: ['./tw-booking-list-page.component.scss'],
})
export class TwBookingListPageComponent implements OnInit, OnDestroy {
    breadcrumb: IBreadcrumb[];
    twBooking: PaginationResponse<TwBooking>;
    page: number = 1;
    pageEntries: number = 50;
    filters: ITwBookingPaginatedFilter;

    sortSelected: ITwOrderSortOption = TW_ORDER_SORT_OPTION[0];

    dropdownSortList: ITwOrderSortOption[] = TW_ORDER_SORT_OPTION;

    unsubcribe$: Subject<void> = new Subject<void>();
    financeBackofficeUrl: string = environment.financeBackofficeUrl;
    constructor(
        private tourwowOrderService: TourwowOrderService,
        private ngxService: NgxUiLoaderService,
        private route: ActivatedRoute
    ) {}

    ngOnInit(): void {
        this.setDefaultFilter();
        this.setBreadcrumb();
        this.loadTwBooking();
    }

    ngOnDestroy(): void {
        this.unsubcribe$.next();
        this.unsubcribe$.complete();
    }

    private setBreadcrumb(): void {
        this.breadcrumb = [
            { name: 'หน้าแรก', link: '/dashboard' },
            { name: 'ข้อมูลการจองทัวร์(TW)', link: '/order/tw-booking/list' },
        ];
    }

    private loadTwBooking(): void {
        this.ngxService.start();
        this.page = 1;
        this.tourwowOrderService
            .getTwBookingListPaginated({
                page: this.page,
                pageEntries: 50,
                filters: this.filters,
                sortBy: this.sortSelected.value,
            })
            .pipe(take(1), takeUntil(this.unsubcribe$))
            .subscribe((resp) => {
                if (resp) {
                    this.twBooking = resp;
                }
                this.ngxService.stop();
            });
    }

    onSelectSort(item: ITwOrderSortOption): void {
        if (this.sortSelected === item) {
            return;
        }

        this.sortSelected = item;
        this.loadTwBooking();
    }

    loadMore(): void {
        this.page++;
        this.ngxService.start();
        this.tourwowOrderService
            .getTwBookingListPaginated({
                page: this.page,
                pageEntries: this.pageEntries,
                filters: this.filters,
                sortBy: this.sortSelected.value,
            })
            .pipe(take(1), takeUntil(this.unsubcribe$))
            .subscribe((resp) => {
                this.twBooking.page = resp.page;
                this.twBooking.pageEntries = resp.pageEntries;
                this.twBooking.result.push(...resp.result);
                this.twBooking.total = resp.total;
                this.ngxService.stop();
            });
    }

    onFilterChange(filter: ITwBookingPaginatedFilter): void {
        this.filters = filter;
        this.loadTwBooking();
    }

    onChangePin(order: TwBooking): void {
        this.tourwowOrderService
            .updateTwBookingPin(order.orderCode, order.isPinned)
            .pipe(
                first(),
                tap(() => this.loadTwBooking())
            )
            .subscribe();
    }

    onChangeOrderStatus(order: TwBooking): void {
        this.tourwowOrderService
            .updateTwBookingOrderStatus(order.orderCode, order.orderStatus)
            .pipe(first())
            .subscribe();
    }

    onCancelOrderStatus(order: TwBooking): void {
        this.tourwowOrderService
            .updateTwBookingOrderStatus(order.orderCode, order.orderStatus)
            .pipe(first())
            .subscribe();
    }

    onChangeNote(order: TwBooking): void {
        this.tourwowOrderService.updateTwBookingNote(order.orderCode, order.note).pipe(first()).subscribe();
    }

    reload(): void {
        this.loadTwBooking();
    }

    private setDefaultFilter(): void {
        this.route.queryParams.pipe(first()).subscribe((params: Params) => {
            if (!params.hasOwnProperty('search_string')) return;
            this.filters = {
                search_string: params['search_string'],
                created_at_between: {
                    min_date: null,
                    max_date: null,
                },
                due_date_at_between: {
                    min_date: null,
                    max_date: null,
                },
                order_statuses: [],
            };
        });
    }
}
