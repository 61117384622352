export enum TW_ORDER_FILE_TYPE {
    invoice = 'Invoice',
    supplier_payment = 'หลักฐานชำระเงินให้ WS',
    supplier_payment_image = 'รูปการแจ้งชำระเงินให้ WS',
    other = 'เอกสารอื่น ๆ',
}
export const TW_ORDER_FILE_TYPE_OPTION: IFileTypeOption[] = [
    {
        value: 'invoice',
        label: 'Invoice',
    },
    {
        value: 'supplier_payment',
        label: 'หลักฐานชำระเงินให้ WS',
    },
    {
        value: 'supplier_payment_image',
        label: 'รูปการแจ้งชำระเงินให้ WS',
    },
    {
        value: 'other',
        label: 'เอกสารอื่น ๆ',
    },
];

export interface IFileTypeOption {
    value: string;
    label: string;
}
