import { Component, OnInit } from '@angular/core';
import { IBreadcrumb } from 'src/app/models/breadcrumb.model';

@Component({
    selector: 'app-lead-booking-request-list',
    templateUrl: './lead-booking-request-list.component.html',
    styleUrls: ['./lead-booking-request-list.component.scss'],
})
export class LeadBookingRequestListComponent implements OnInit {
    breadcrumb: IBreadcrumb[];
    showFilter: boolean = false;

    constructor() {}

    ngOnInit() {
        this.setBreadcrumb();
    }

    setBreadcrumb(): void {
        this.breadcrumb = [
            { name: 'หน้าแรก', link: '/dashboard' },
            { name: 'ใบจองจาก Agency', link: '/report/lead-booking-request' },
        ];
    }

    toggleFilter(): void {
        if (this.showFilter) {
            this.showFilter = false;
        } else {
            this.showFilter = true;
        }
    }
}
