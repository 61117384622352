import { HttpService } from './base/http.service';

import { ENDPOINTS } from '../constants/endpoint.constant';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { IAirport } from '../models/interfaces/airport.interface';
import { IResponse } from '../models/interfaces/response.interface';
import { IAirportResponse } from '../models/interfaces/airport-response.interface';
import { map } from 'rxjs/operators';

@Injectable()
export class TransportationService {
    constructor(private httpService: HttpService) {}

    getAllTransportations(): Observable<any> {
        const url = environment.apiProUrl + ENDPOINTS.TRANSPORTATION.GET_ALL;
        return this.httpService.get(url, null);
    }

    getAirport(): Observable<IAirport[]> {
        const url = environment.apiProUrl + ENDPOINTS.AIRPORT.GET;
        return this.httpService.get(url, null).pipe(
            map((response: IResponse<IAirportResponse[]>) => {
                if (response.status === 'success') {
                    return response.data.map((airport: IAirportResponse) => {
                        return {
                            id: airport.id,
                            nameTh: airport.name_th,
                            code: airport.code,
                        };
                    });
                }
            })
        );
    }
}
