<div class="p-3 text-sm">
    <div class="modal-header">
        <div class="text-center w-100">
            <strong class="text-lg"><i class="mr-1 icon icon-ticket"></i> ข้อมูลการจองที่นั่งเรือ</strong>
            <button type="button" aria-label="Close" class="close pull-right" (click)="closeModal()">
                <span aria-hidden="true">×</span>
            </button>
        </div>
    </div>
    <div class="modal-body">
        <div class="booking-order-information mb-4">
            <div class="mb-1">
                <strong class="mr-1 text-md">ข้อมูล Order</strong>
                <span class="text-xxs">(Order No. {{ campaign.order.orderCode }})</span>
            </div>
            <div class="mb-1">
                <span>Campaign : {{ campaign.campaign.name }}</span>
            </div>
            <div class="mb-1">
                <span>Voucher : {{ campaign.voucher.name }}</span>
            </div>
            <div class="mb-1">
                <span
                    >จำนวน : {{ campaign.voucherTicketQuantity.total }} ที่ (เหลือ
                    {{ campaign.voucherTicketQuantity.unused }}), ชื่อผู้จอง {{ campaign.contactName }}, เบอร์โทร
                    {{ campaign.phoneNumber | phoneNumber }}</span
                >
            </div>
        </div>
        <div class="booking-information">
            <div class="mb-1">
                <strong class="text-md">ข้อมูลการจอง</strong>
            </div>
            <div class="mb-1">
                <div class="booking-voucher-codes">
                    <label>E-Voucher Code : ที่ต้องการเข้าใช้บริการ</label>
                    <div class="d-flex row">
                        <ng-container *ngFor="let n of numberOfTickets">
                            <div class="col-sm-4">
                                <input
                                    appOnlyNumber
                                    class="form-control ml-1 mr-1 mb-1"
                                    type="text"
                                    placeholder="E-voucher code"
                                    maxlength="6"
                                    [ngClass]="{ 'is-invalid': isSubmitted && !validateTicketCode(ticketCodes[n]) }"
                                    [(ngModel)]="ticketCodes[n]"
                                />
                            </div>
                        </ng-container>
                    </div>
                </div>
            </div>
            <div class="mb-1">
                <div class="d-flex extra-info">
                    <div class="d-flex flex-column mr-1">
                        <label>เลือกวันที่เข้าใช้บริการ</label>
                        <div class="datepicker pt-0">
                            <input
                                type="text"
                                placeholder="วันที่เข้าใช้บริการ"
                                [bsValue]="usageDate"
                                (bsValueChange)="selectUsageDate($event)"
                                class="form-control"
                                bsDatepicker
                            />
                            <img class="icon" src="assets/img/icon/icon-calendar.svg" />
                        </div>
                    </div>
                    <div class="d-flex flex-column ml-1 w-75">
                        <label>ข้อมูลเพิ่มเติม เช่น โซนที่นั่ง</label>
                        <textarea
                            [(ngModel)]="remark"
                            class="form-control"
                            placeholder="กรอกข้อมูลเพิ่มเติม (ถ้ามี)"
                            maxlength="500"
                        ></textarea>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="modal-footer">
        <div class="d-flex flex-column">
            <div class="mb-2 text-center" *ngIf="isSubmitted && !isFormValid()">
                <span class="color-red">กรุณากรอกข้อมูลให้ครบถ้วน</span>
            </div>
            <div>
                <button class="btn btn-primary" (click)="submitForm()">ยืนยันการส่งข้อมูลการจอง</button>
                <button class="btn btn-cancel" (click)="closeModal()">ยกเลิก</button>
            </div>
        </div>
    </div>
</div>
