import { SORT_BY } from './../../constants/product-sorting.constant';
import { createAction, props } from '@ngrx/store';
import { ProductList } from 'src/app/models/product-list';
import { ProductView } from 'src/app/models/product-view';
import {
    IProduct,
    ICreateProductResponse,
    IProductDelete,
    IProductDeleteResponse,
    IUpdateProductResponse,
    IUpdateProductPoolRecommendationResponse,
} from 'src/app/modules/product/models/product.model';
import { ProductListFilter, ProductListQuery } from 'src/app/models/request/queries/product-list-query';
import { ProductNameList } from 'src/app/models/product-name-list';
import { IProductPdfDetailFile } from 'src/app/models/product-pdf-detail-file.model';
import { Owner } from 'src/app/models/owner';
import { CampaignDetail } from 'src/app/modules/product/models/campaign-detail.model';

export const loadProductCountOfAgency = createAction('[Product] Load product count of agency');
export const loadProductCountOfAgencySuccess = createAction(
    '[Product] Load product count of agency success',
    props<{ data: any }>()
);
export const loadProductCountOfAgencyFailure = createAction(
    '[Product] Load product count of agency failure',
    props<{ error: any }>()
);

export const loadProductMonth = createAction(
    '[Product] Load product month',
    props<{
        categorySubProductIds: number[];
        fromProductPool: boolean;
    }>()
);

export const loadProductMonthSuccess = createAction(
    '[Product] Load product month success',
    props<{ data: string[] }>()
);

export const loadProductMonthFailure = createAction('[Product] Load product month failure', props<{ error: any }>());

export const loadNewProductListing = createAction(
    '[Product] Load New product listing',
    props<{ query: ProductListQuery }>()
);

export const loadProductListing = createAction('[Product] Load product listing', props<{ query: ProductListQuery }>());

export const loadProductListingSuccess = createAction(
    '[Product] Load product listing success',
    props<{ productList: ProductList[]; total: number; page: number; refresh: boolean }>()
);
export const loadProductListingFailure = createAction(
    '[Product] Load product listing failure',
    props<{ error: any }>()
);
export const clearProductListing = createAction('[Product] Clear product listing');

export const loadProduct = createAction('[Product] load product', props<{ productId: number }>());
export const loadProductSuccess = createAction('[Product] load product success', props<{ product: IProduct }>());
export const loadProductFailure = createAction('[Product] load product failure', props<{ error: any }>());
export const resetProduct = createAction('[Product] reset product state');

//#region Join Tour
export const createOutBoundJoinProduct = createAction(
    '[Product] create outbound join product',
    props<{ product: IProduct }>()
);
export const createOutBoundJoinProductSuccess = createAction(
    '[Product] create outbound join product success',
    props<{ response: ICreateProductResponse }>()
);
export const createOutBoundJoinProductFailure = createAction(
    '[Product] create outbound join product failure',
    props<{ response: ICreateProductResponse }>()
);
export const resetCreateOutBoundJoinProduct = createAction('[Product] reset createProductResponse state ');

export const updateOutBoundJoinProduct = createAction(
    '[Product] update outbound join product',
    props<{ product: IProduct }>()
);
export const updateOutBoundJoinProductSuccess = createAction(
    '[Product] update outbound join product success',
    props<{ response: IUpdateProductResponse }>()
);
export const updateOutBoundJoinProductFailure = createAction(
    '[Product] update outbound join product failure',
    props<{ response: IUpdateProductResponse }>()
);
export const resetUpdateOutBoundJoinProduct = createAction('[Product] reset updateProductResponse state ');
//#endregion

//#region Package
export const createOutBoundPackageProduct = createAction(
    '[Product] create outbound package product',
    props<{ product: IProduct }>()
);
export const createOutBoundPackageProductSuccess = createAction(
    '[Product] create outbound package product success',
    props<{ response: ICreateProductResponse }>()
);
export const createOutBoundPackageProductFailure = createAction(
    '[Product] create outbound package product failure',
    props<{ response: ICreateProductResponse }>()
);
export const resetCreateOutBoundPackageProduct = createAction('[Product] reset createPackageProductResponse state ');

export const createDomesticProduct = createAction('[Product] create domestic product', props<{ product: IProduct }>());
export const createDomesticProductSuccess = createAction(
    '[Product] create domestic product success',
    props<{ response: ICreateProductResponse }>()
);
export const createDomesticProductFailure = createAction(
    '[Product] create domestic product failure',
    props<{ response: ICreateProductResponse }>()
);
export const resetCreateDomesticProduct = createAction('[Product] reset createProductResponse state ');

export const updateDomesticProduct = createAction('[Product] update domestic product', props<{ product: IProduct }>());
export const updateDomesticProductSuccess = createAction(
    '[Product] update domestic product success',
    props<{ response: IUpdateProductResponse }>()
);
export const updateDomesticProductFailure = createAction(
    '[Product] update domestic product failure',
    props<{ response: IUpdateProductResponse }>()
);
export const resetUpdateDomesticProduct = createAction('[Product] reset updateProductResponse state ');

export const updateOutBoundPackageProduct = createAction(
    '[Product] update outbound package product',
    props<{ product: IProduct }>()
);
export const updateOutBoundPackageProductSuccess = createAction(
    '[Product] update outbound package product success',
    props<{ response: IUpdateProductResponse }>()
);
export const updateOutBoundPackageProductFailure = createAction(
    '[Product] update outbound package product failure',
    props<{ response: IUpdateProductResponse }>()
);
export const resetUpdateOutBoundPackageProduct = createAction('[Product] reset updatePackageProductResponse state ');
//#endregion

export const deleteProduct = createAction('[Product] delete product', props<{ id: IProductDelete }>());
export const deleteProductSuccess = createAction(
    '[Product] delete product success',
    props<{ response: IProductDeleteResponse }>()
);
export const deleteProductFailure = createAction('[Product] delete product failure', props<{ error: any }>());

export const loadProductView = createAction(
    '[Product] Load product view',
    props<{ criteria?: { poolKey?: string; poolProduct?: boolean; product_id?: number } }>()
);
export const loadProductViewSuccess = createAction(
    '[Product] Load product view success',
    props<{ productView: ProductView[]; total: number }>()
);
export const loadProductViewFailure = createAction('[Product] Load product view failure', props<{ error: any }>());
export const resetProductView = createAction('[Product] reset product view');

export const updateProductPoolRecommendation = createAction(
    '[Product] Update product pool recommendation',
    props<{ productPoolId: number; isRecommended: boolean }>()
);
export const updateProductPoolRecommendationSuccess = createAction(
    '[Product] Update product pool recommendation success',
    props<{
        response: IUpdateProductPoolRecommendationResponse;
        productPoolId: number;
        isRecommended: boolean;
    }>()
);
export const updateProductPoolRecommendationFailure = createAction(
    '[Product] Update product pool recommendation failure',
    props<{ response: IUpdateProductPoolRecommendationResponse }>()
);
export const updateProductPoolRecommendationResetResponse = createAction(
    '[Product] Update product pool recommendation reset'
);

export const loadProductWithSearchString = createAction(
    '[Report] User looks for product from tour code',
    props<{ searchString: string }>()
);

export const loadProductWithSearchStringSuccess = createAction(
    '[Report] Product with tour code found successfully',
    props<{ productDetails: any }>()
);

export const loadProductWithSearchStringFailure = createAction(
    '[Report] Product with tour code found unsuccessfully',
    props<{ error: any }>()
);

export const loadProductDocumentFileBase64 = createAction(
    '[Product] Load product document file base64',
    props<{ productId: number; isFromPool: boolean }>()
);
export const loadProductDocumentFileBase64Succcess = createAction(
    '[Product] Load product document file base64 success',
    props<{ data: any }>()
);
export const loadProductDocumentFileBase64Failure = createAction(
    '[Product] Load product document file base64 failure',
    props<{ error: any }>()
);
export const loadProductDocumentFileBase64Reset = createAction('[Product] Load product document file base64 reset');

export const loadProductPdfFileBase64 = createAction(
    '[Product] Load product pdf file base64',
    props<{ productId: number; isFromPool: boolean }>()
);
export const loadProductPdfFileBase64Succcess = createAction(
    '[Product] Load product pdf file base64 success',
    props<{ data: any }>()
);
export const loadProductPdfFileBase64Failure = createAction(
    '[Product] Load product pdf file base64 failure',
    props<{ error: any }>()
);
export const loadProductPdfFileBase64Reset = createAction('[Product] Load product pdf file base64 reset');

export const loadProductNameListListing = createAction(
    '[Product] Load product name list listing',
    props<{ filter: ProductListFilter; fromProductPool: boolean }>()
);
export const loadProductNameListListingSuccess = createAction(
    '[Product] Load product name list listing success',
    props<{ productNameList: ProductNameList[] }>()
);
export const loadProductNameListListingFailure = createAction(
    '[Product] Load product name list listing failure',
    props<{ error: any }>()
);

export const setProductSortBy = createAction('[Product] Set product sort by', props<{ sortBy: SORT_BY }>());

export const loadProductPdfDetailFile = createAction(
    '[Product] Load product Pdf detail file',
    props<{
        productId: number;
        categorySubProductId: number;
        ownerChannel: 'ag' | 'ws';
        isFromPool: boolean;
        loadedAsBase64: boolean;
    }>()
);
export const loadProductPdfDetailFileSuccess = createAction(
    '[Product] Load product Pdf detail file success',
    props<{ productPdfDetailFile: IProductPdfDetailFile }>()
);
export const loadProductPdfDetailFileFailure = createAction(
    '[Product] Load product Pdf detail file failure',
    props<{ error: any }>()
);
export const resetProductPdfDetailFile = createAction('[Product] Reset product Pdf detail file');

export const loadProductOwner = createAction(
    '[Product] Load product owner',
    props<{ ownerChannel: string; ownerId: number }>()
);
export const loadProductOwnerSuccess = createAction(
    '[Product] Load product owner success',
    props<{ productOwner: Owner }>()
);
export const loadProductOwnerFailure = createAction('[Product] Load product owner failure', props<{ error: any }>());

export const loadCampaignDetail = createAction('[Product] Load campaign detail', props<{ campaignId: any }>());
export const loadCampaignDetailSuccess = createAction(
    '[Product] Load campaign detail success',
    props<{ campaignDetail: CampaignDetail }>()
);
export const loadCampaignDetailFailure = createAction(
    '[Product] Load campaign detail failure',
    props<{ error: any }>()
);

export const resetProductOwner = createAction('[Product] Reset product owner');
