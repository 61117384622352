import { Province } from './province';
import { Country } from './country';
import { CATEGORY_TRANSPORT_REFERENCES } from '../constants/category-transport.constant';

export class ProductView {
    public id: number;
    public product_pool_id: number;
    public gallery: {
        gallery_item: IProductViewGalleryItem[];
    };
    public name: string;
    public countries: Country[];
    public provinces: Province[];
    public duration_day: number;
    public duration_night: number;
    public product_periods: any[];
    public category_transport: {
        id: number;
        slug: string;
        name: string;
    };
    public go_transportation: {
        id: number;
        code: string;
        name: string;
        name_en: string;
        airline_thumbnail_url: string;
    };
    public travel_period_start_at_first: Date;
    public travel_period_start_at_last: Date;
    public start_at_first: Date;
    public start_at_last: Date;
    public comparePrice: number;
    public price: number;
    public commission_company: number;
    public commission_seller: number;
    public hilight_description: string;
    public tour_code: string;
    public document_url: string;
    public showcaseimage_preview_url: string;
    public showcaseimage_thumbnail_url: string;
    public product_descriptions: string;
    public tour_condition: string;
    public updated_at: Date;
    public owner_id: number;
    public owner_name_en: string;
    public owner_name_th: string;
    public owner_channel: 'ag' | 'ws' = null;
    public category_sub_product_id: number;
    public has_pdf_detail_file: boolean;
    public isExpired: boolean = null;

    public isFromPool: boolean;

    public deserialize(obj: any): ProductView {
        this.id = obj.id;
        this.product_pool_id = obj.product_pool_id || null;
        this.gallery = {
            gallery_item: [
                {
                    is_active: true,
                    file_url: obj.banner_url,
                    preview_url: obj.banner_url,
                },
            ],
        };
        this.name = obj.name;
        this.countries = obj.destinations;
        this.duration_day = obj.duration_day;
        this.duration_night = obj.duration_night;
        this.product_periods = obj.periods;
        this.category_transport = obj.category_transport;
        this.go_transportation = obj.go_transport;

        this.travel_period_start_at_first = obj.travel_period_start_at_first
            ? new Date(obj.travel_period_start_at_first)
            : null;
        this.travel_period_start_at_last = obj.travel_period_start_at_last
            ? new Date(obj.travel_period_start_at_last)
            : null;

        this.start_at_first = this.travel_period_start_at_first;
        this.start_at_last = this.travel_period_start_at_last;

        this.comparePrice = Number(obj.compare_price);
        this.price = Number(obj.price);
        this.commission_company = obj.commission_company;
        this.commission_seller = obj.commission_seller;
        this.hilight_description = obj.hilight_description;
        this.tour_code = obj.tour_code;

        this.document_url = obj.program_doc_url;
        this.showcaseimage_preview_url = obj.showcase_banner_preview_url;
        this.showcaseimage_thumbnail_url = obj.showcase_banner_thumbnail_url;
        this.product_descriptions = obj.product_descriptions;
        this.tour_condition = obj.tour_condition;
        this.updated_at = obj.updated_at ? new Date(obj.updated_at) : null;

        if (obj.owner) {
            this.owner_id = obj.owner.id;
            this.owner_name_en = obj.owner.name_en;
            this.owner_name_th = obj.owner.name_th;
        }
        this.owner_channel = obj.owner_channel;
        this.category_sub_product_id = obj.category_sub_products_id;
        this.has_pdf_detail_file = 'has_pdf_detail_file' in obj ? obj.has_pdf_detail_file : null;
        this.isExpired = obj.is_expired == 1 ? true : false;

        this.isFromPool = true;

        return this;
    }

    public deserializeFromAgencyProduct(obj: any): ProductView {
        this.id = obj.id;
        this.product_pool_id = null;
        if (obj.gallery && obj.gallery.gallery_item) {
            this.gallery = {
                gallery_item: (obj.gallery.gallery_item as any[]).map((galleryItemData) => ({
                    is_active: galleryItemData.is_active,
                    file_url: galleryItemData.file_url,
                    preview_url: galleryItemData.file_url,
                })),
            };
        }
        this.name = obj.name;
        this.countries = obj.countries;
        this.provinces = obj.provinces;
        this.duration_day = obj.duration_day;
        this.duration_night = obj.duration_night;
        this.product_periods = obj.product_periods;
        this.category_transport = obj.category_transport;
        this.go_transportation = obj.go_transportation;

        this.travel_period_start_at_first = obj.travel_period_start_at_first
            ? new Date(obj.travel_period_start_at_first)
            : null;
        this.travel_period_start_at_last = obj.travel_period_start_at_last
            ? new Date(obj.travel_period_start_at_last)
            : null;

        this.start_at_first = obj.start_at_first ? new Date(obj.start_at_first) : null;
        this.start_at_last = obj.start_at_last ? new Date(obj.start_at_last) : null;

        this.comparePrice = null;
        this.price = Number(obj.price);
        this.commission_company = obj.commission_company;
        this.commission_seller = obj.commission_seller;
        this.hilight_description = obj.hilight_description;
        this.tour_code = obj.tour_code;

        this.document_url = obj.document_url;
        this.showcaseimage_preview_url = obj.showcase_banner_preview_url;
        this.showcaseimage_thumbnail_url = obj.showcase_banner_thumbnail_url;
        this.product_descriptions = obj.product_descriptions;
        this.tour_condition = obj.tour_condition;
        this.updated_at = obj.updated_at ? new Date(obj.updated_at) : null;
        this.owner_channel = 'ag';
        this.category_sub_product_id = obj.category_sub_product.id;
        this.has_pdf_detail_file = 'has_pdf_detail_file' in obj ? obj.has_pdf_detail_file : null;

        this.isFromPool = false;

        return this;
    }

    public getCategoryTransportIconFilePath(): string {
        if (this.go_transportation) {
            return null;
        }
        if (this.category_transport) {
            const categoryTransportReference = CATEGORY_TRANSPORT_REFERENCES.find(
                (categoryTransportReference) => categoryTransportReference.slug == this.category_transport.slug
            );
            if (categoryTransportReference) {
                return categoryTransportReference.svgIconFilePath;
            }
            return null;
        }
        return null;
    }
}

export interface IProductViewProvince {
    id: number;
    name_en: string;
    name_th: string;
}

export interface IProductViewGalleryItem {
    is_active: boolean;
    file_url: string;
    preview_url: string;
}
