import * as dayjs from 'dayjs';

import { HttpClient } from '@angular/common/http';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { ENDPOINTS } from '../constants/endpoint.constant';
import { IResponse } from '../models/interfaces/response.interface';
import { IWorkListTaskPayload } from '../modules/order/models/interfaces/worklist-task-payload.interface';
import { IWorkListTaskResponse } from '../modules/order/models/interfaces/worklist-task-response.interface';
import { IWorkListTask } from '../modules/order/models/interfaces/worklist-task.interface';
import { HttpService } from './base/http.service';

@Injectable({
    providedIn: 'root',
})
export class WorkListService extends HttpService {
    constructor(
        @Inject(PLATFORM_ID) protected platformId: Object,
        protected http: HttpClient,
        protected router: Router
    ) {
        super(platformId, http, router);
    }

    getTasks(orderCode: string): Observable<IWorkListTask[]> {
        const url = environment.apiProUrl + ENDPOINTS.WORK_LIST.GET.replace('{order_code}', orderCode);
        return this.get(url).pipe(
            map((response: IResponse<IWorkListTaskResponse[]>) => {
                let tasks: IWorkListTask[] = [];

                if (response.status === 'success') {
                    response.data.forEach((task: IWorkListTaskResponse) => {
                        let time = task.time === null ? '00:00' : task.time;
                        let datetime = task.date ? dayjs(task.date + 'T' + time).toDate() : null;
                        let temp: IWorkListTask = {
                            taskType: {
                                id: task.task_type.id,
                                name: task.task_type.name,
                                forRole: task.task_type.for_roles,
                                isSupplement: task.task_type.is_supplement,
                                ordinal: task.task_type.ordinal,
                            },
                            requireTime: task.task_type.id === 18 ? true : false,
                            status: task.status,
                            dateTime: datetime,
                            meetingPoint: task.meeting_point,
                            airport: task.airport
                                ? {
                                      id: task.airport.id,
                                      nameTh: task.airport.name_th,
                                      code: task.airport.code,
                                  }
                                : null,
                        };

                        tasks.push(temp);
                    });
                }

                return tasks;
            })
        );
    }

    updateTasks(orderCode: string, payload: IWorkListTaskPayload[]): Observable<boolean> {
        const url = environment.apiProUrl + ENDPOINTS.WORK_LIST.PUT.replace('{order_code}', orderCode);
        return this.put(url, { tasks: payload }, true).pipe(
            map((response: IResponse<null>) => {
                if (response.status === 'success') {
                    return true;
                }

                return false;
            })
        );
    }
}
