export const CATEGORY_TRANSPORT_REFERENCES: ICategoryTransportReference[] = [
    {
        id: 1,
        slug: 'plane',
        name: 'เครื่องบิน',
        svgIconFilePath: 'assets/img/icon/icon-flight-go.svg',
    },
    {
        id: 3,
        slug: 'train',
        name: 'รถไฟ',
        svgIconFilePath: 'assets/img/icon/icon-train-01.svg',
    },
    {
        id: 6,
        slug: 'bus',
        name: 'รถบัส',
        svgIconFilePath: 'assets/img/icon/icon-bus-01.svg',
    },
    {
        id: 9,
        slug: 'van',
        name: 'รถตู้',
        svgIconFilePath: 'assets/img/icon/icon-van-01.svg',
    },
    {
        id: 10,
        slug: 'car',
        name: 'รถยนต์',
        svgIconFilePath: 'assets/img/icon/icon-car-01.svg',
    },
    {
        id: 14,
        slug: 'boat',
        name: 'เรือ',
        svgIconFilePath: 'assets/img/icon/icon-cruise.svg',
    },
];

export interface ICategoryTransportReference {
    id: number;
    slug: string;
    name: string;
    svgIconFilePath: string;
}
