export class Content implements IContent {
    id: number;
    type: EnumContentType;
    ordinal: number;
    text: string;
    imageId?: number;
    imageUrl?: string;
    galleryId: number;

    get isTextType(): boolean {
        return this.type === EnumContentType.Text;
    }

    get isImageAndTextType(): boolean {
        return this.type === EnumContentType.ImageAndText;
    }
}

export interface IContent {
    id: number;
    type: EnumContentType;
    ordinal: number;
    text: string;
    imageId?: number;
    imageUrl?: string;
    galleryId: number;
}

export enum EnumContentType {
    Unknown = 0,
    Text = 1,
    ImageAndText = 2,
}

export interface IAddBlogDescriptions {
    ordinal: number;
    type_slug: 'text' | 'image';
    description?: string;
    galleries_id?: number;
    image_description?: string;
}

export interface IEditBlogDescriptions {
    id?: number;
    ordinal: number;
    type_slug: 'text' | 'image';
    description?: string;
    galleries_id?: number;
    image_description?: string;
    imageUrl?: string;
    isUpdated?: boolean;
}
