import { IBookingOrderItem } from '../../../models/add-booking-b2c.model';
import { Component, ElementRef, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
    selector: 'app-booking-order-items',
    templateUrl: './booking-order-items.component.html',
    styleUrls: ['./booking-order-items.component.scss'],
})
export class BookingOrderItemsComponent implements OnInit {
    @Input() isEdit: boolean = false;
    @Input() public bookingOrderItems: IBookingOrderItem[];
    @Input() public validFields: { description: boolean; price: boolean; quantity: boolean; total: boolean } = {
        description: true,
        price: true,
        quantity: true,
        total: true,
    };
    @Output() private bookingOrderItemsEmitter = new EventEmitter<IBookingOrderItem[]>();

    constructor() {}

    ngOnInit(): void {
        if (!this.bookingOrderItems) {
            if (!this.isEdit) {
                this.bookingOrderItems = [
                    {
                        description: 'ผู้ใหญ่พักคู่',
                        price: null,
                        quantity: null,
                        total: null,
                    },
                ];
            } else {
                this.bookingOrderItems = [
                    {
                        id: null,
                        description: 'ผู้ใหญ่พักคู่',
                        price: null,
                        quantity: null,
                        total: null,
                        to_update: true,
                    },
                ];
            }
            this.emitToParent();
        }
    }

    public calculateTotal(index: number) {
        const item: IBookingOrderItem = this.bookingOrderItems[index];

        if (item.price && item.quantity) {
            item.total = item.price * item.quantity;
        }
    }

    public addItem(): void {
        if (!this.isEdit) {
            this.bookingOrderItems.push({
                description: null,
                price: null,
                quantity: null,
                total: null,
            });
        } else {
            this.bookingOrderItems.push({
                id: null,
                description: null,
                price: null,
                quantity: null,
                total: null,
                to_update: true,
            });
        }

        this.emitToParent();
    }

    public removeItem(index: number): void {
        this.bookingOrderItems.splice(index, 1);

        this.emitToParent();
    }

    public calculateGrandTotal(): number {
        let total = 0;

        if (this.bookingOrderItems) {
            this.bookingOrderItems.map((item: IBookingOrderItem) => {
                if (item.total) {
                    total += Number(item.total);
                }
            });
        }

        return total;
    }

    public emitToParent(): void {
        this.bookingOrderItemsEmitter.emit(this.bookingOrderItems);
    }

    public adjustHeight(inputEvent: any): void {
        inputEvent.currentTarget.style.height = '29px';
        inputEvent.currentTarget.style.minHeight = 29 + 'px';
        inputEvent.currentTarget.style.height = inputEvent.currentTarget.scrollHeight + 'px';
    }
}
