import { ILeadSummaryStatistics } from '../../../models/lead-summary-statistics.model';
import { ILeadSummaryDetails } from '../../../models/lead-summary.model';
import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';

@Component({
    selector: 'app-lead-summary-details',
    templateUrl: './lead-summary-details.component.html',
    styleUrls: ['./lead-summary-details.component.scss'],
})
export class LeadSummaryDetailsComponent {
    @Input() lead: ILeadSummaryDetails;
    @Input() leadStatistics: ILeadSummaryStatistics;

    constructor(private router: Router) {}

    redirectToBookingPage(): void {
        this.router.navigate(['/report/lead-booking-request'], { queryParams: { tourCode: this.lead.tourCode } });
    }
}
