import { Store, select } from '@ngrx/store';
import { State } from 'src/app/stores/reducers';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { uploadFileGallery } from 'src/app/stores/utility-store/utility.actions';
import { selectGalleryResponse } from 'src/app/stores/utility-store/utility.selectors';
import { filter } from 'rxjs/operators';

export class UploadAdapter {
    private loader: any;
    private store: Store<State>;
    private imageSettings: any;
    private ngxService: NgxUiLoaderService;

    constructor(loader: any, store: Store<State>, imageSettings: any, ngxService: NgxUiLoaderService) {
        this.loader = loader;
        this.store = store;
        this.imageSettings = imageSettings;
        this.ngxService = ngxService;
    }

    async upload() {
        const form: FormData = new FormData();
        form.append('galleries_id', this.imageSettings.galleryId);
        form.append('file', await this.loader.file);
        form.append('type', this.imageSettings.galleryFileType);

        this.store.dispatch(
            uploadFileGallery({
                form,
                identifier: this.imageSettings.galleryFileType + this.loader.id,
                showLoader: true,
            })
        );

        return new Promise((resolve, reject) => {
            this.store
                .pipe(
                    select(selectGalleryResponse, { identifier: this.imageSettings.galleryFileType + this.loader.id }),
                    filter((response) => !!response && !!response.fileUrl)
                )
                .subscribe((response) => {
                    if (Number.isInteger(response.fileId)) {
                        if (response.fileContentUrl && response.fileThumbnailUrl) {
                            resolve({
                                default: response.fileUrl,
                                '993': response.fileContentUrl,
                                '992': response.fileThumbnailUrl,
                            });
                        } else {
                            resolve({
                                default: response.fileUrl,
                            });
                        }
                    } else {
                        reject({ default: 'Error: Please try again.' });
                    }
                });
        });
    }

    abort() {}
}
