import { selectBookingRequestLeadId } from '../../../../stores/report-store/report.selectors';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { IListingPageDetails } from '../../models/listing-page-details.model';
import { ILeadDetails } from '../../models/lead-details.model';
import { AddBookingComponent } from '../../modals/lead-list/add-booking/add-booking.component';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { State } from 'src/app/stores/reducers';
import { Store, select } from '@ngrx/store';
import { Subject } from 'rxjs';
import * as ReportActions from 'src/app/stores/report-store/report.actions';
import * as OrderActions from 'src/app/stores/order-store/order.actions';
import { takeUntil, filter, take } from 'rxjs/operators';
import {
    selectReportLeadList,
    selectPageDetails,
    selectGetSearchFilter,
    selectLeadId,
    selectUpdatedLeadStatusIdAndStatus,
} from 'src/app/stores/report-store/report.selectors';
import { ISearchFilter, SearchFilter } from '../../models/search-filter.model';
import { SuccessModalComponent } from 'src/app/modules/shared/modals/success-modal/success-modal.component';
import { selectNewBookingOrderId } from 'src/app/stores/order-store/order.selectors';
import * as _ from 'lodash-es';

@Component({
    selector: 'app-lead-listing',
    templateUrl: './lead-listing.component.html',
    styleUrls: ['./lead-listing.component.scss'],
})
export class LeadListingComponent implements OnInit, OnDestroy {
    searchFilter: ISearchFilter = new SearchFilter().initialiseSearchFilter();

    listTotal: number = 456;
    pageDetails: IListingPageDetails;
    leadList: ILeadDetails[];

    bsModalRef: BsModalRef;
    private unsubscribe$: Subject<void> = new Subject<void>();

    constructor(private modalService: BsModalService, private store: Store<State>) {}

    ngOnInit() {
        this.setInitialPageDetails();
        this.subscribeToLeadListState();
        this.callInitialiseSelectors();
    }

    ngOnDestroy(): void {
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
    }

    private dispatchLeadList(nextPage?: boolean): void {
        if (!nextPage) {
            this.pageDetails.currentPage = 1;
        }
        this.store.dispatch(
            ReportActions.getLeadList({
                searchFilter: this.searchFilter,
                criteria: {
                    page: this.pageDetails.currentPage,
                    pageEntries: this.pageDetails.pageEntries,
                },
                nextPage: nextPage || null,
            })
        );
        if (!nextPage) {
            this.store.dispatch(
                ReportActions.getLeadCountByType({
                    searchFilter: _.cloneDeep(this.searchFilter),
                })
            );
        }
    }

    private subscribeToLeadListState(): void {
        this.store
            .pipe(
                select(selectReportLeadList),
                filter((m) => !!m),
                takeUntil(this.unsubscribe$)
            )
            .subscribe((leadList) => {
                this.leadList = leadList;
            });
    }

    private callInitialiseSelectors(): void {
        this.store.pipe(select(selectPageDetails), takeUntil(this.unsubscribe$)).subscribe((pageDetails) => {
            const totalResults = pageDetails ? pageDetails.totalResults : 0;
            this.listTotal = totalResults || 0;
            this.pageDetails.maxPage = totalResults / this.pageDetails.pageEntries;
            this.pageDetails.currentPage = pageDetails ? pageDetails.page : 1;
        });

        this.store.pipe(select(selectGetSearchFilter), takeUntil(this.unsubscribe$)).subscribe((searchFilter) => {
            this.searchFilter = searchFilter;
        });

        this.store
            .pipe(
                select(selectLeadId),
                filter((m) => !!m),
                takeUntil(this.unsubscribe$)
            )
            .subscribe(() => {
                this.bsModalRef = this.modalService.show(SuccessModalComponent, { class: 'success-modal' });
                this.modalService.onHide
                    .pipe(
                        filter((reason) => reason === 'refresh'),
                        take(1)
                    )
                    .subscribe(() => {
                        this.store.dispatch(
                            ReportActions.addLeadSuccess({
                                leadId: null,
                            })
                        );
                        this.dispatchLeadList();
                    });
            });

        this.store
            .pipe(
                select(selectUpdatedLeadStatusIdAndStatus),
                filter((m) => !!m),
                takeUntil(this.unsubscribe$)
            )
            .subscribe((result) => {
                this.store.dispatch(
                    ReportActions.getLeadCountByType({
                        searchFilter: _.cloneDeep(this.searchFilter),
                    })
                );

                const status = result.split('-')[1];
                if (status === 'cancel') {
                    const initialState = {
                        message: 'ยกเลิก Booking เรียบร้อยแล้ว',
                    };
                    this.bsModalRef = this.modalService.show(SuccessModalComponent, {
                        initialState,
                        class: 'success-modal',
                    });
                }
                this.modalService.onHide
                    .pipe(
                        filter((reason) => reason === 'refresh'),
                        take(1)
                    )
                    .subscribe(() => {
                        this.store.dispatch(
                            ReportActions.addLeadSuccess({
                                leadId: null,
                            })
                        );
                        this.dispatchLeadList();
                    });

                this.store.dispatch(
                    ReportActions.updateLeadStatusSuccess({
                        leadId: null,
                        statusSlug: null,
                    })
                );
                this.store.dispatch(
                    ReportActions.getLeadCount({
                        statusSlug: 'waiting_contact',
                    })
                );
            });

        this.store
            .pipe(
                select(selectNewBookingOrderId),
                filter((m) => !!m),
                takeUntil(this.unsubscribe$)
            )
            .subscribe(() => {
                const initialState = {
                    message: 'สร้าง Booking เรียบร้อยแล้ว',
                    description: {
                        text: 'ระบบทำการสร้าง Booking ให้ท่านเรียบร้อยแล้ว ต้องการดูข้อมูล',
                        linkText: 'คลิกที่นี่',
                        linkUrl: '/order/booking-list',
                    },
                };
                this.store.dispatch(OrderActions.createNewBookingOrderSuccess({ newOrderId: null }));
                this.bsModalRef = this.modalService.show(SuccessModalComponent, {
                    initialState,
                    class: 'success-modal',
                });

                this.modalService.onHide
                    .pipe(
                        filter((reason) => reason === 'refresh'),
                        take(1)
                    )
                    .subscribe(() => {
                        this.dispatchLeadList();
                    });
            });

        this.store
            .pipe(
                select(selectBookingRequestLeadId),
                filter((m) => !!m),
                takeUntil(this.unsubscribe$)
            )
            .subscribe(() => {
                const initialState = {
                    message: 'ส่งใบจองเรียบร้อยแล้ว',
                };
                this.store.dispatch(
                    ReportActions.addBookingRequestLeadSuccess({
                        leadBookingRequestId: null,
                    })
                );
                this.bsModalRef = this.modalService.show(SuccessModalComponent, {
                    initialState,
                    class: 'success-modal',
                });
                this.store.dispatch(
                    ReportActions.getLeadCount({
                        statusSlug: 'waiting_contact',
                    })
                );
            });
    }

    setInitialPageDetails(): void {
        this.pageDetails = {
            currentPage: 1,
            maxPage: null,
            pageEntries: 50,
        };
    }

    nextPage(): void {
        this.pageDetails.currentPage++;

        this.dispatchLeadList(true);
    }

    openAddBookingModal(): void {
        this.bsModalRef = this.modalService.show(AddBookingComponent, { class: 'add-booking-modal' });
    }

    showNextResultsValue(): number {
        const nextSearchBracket: number = (this.pageDetails.currentPage + 1) * this.pageDetails.pageEntries;
        if (nextSearchBracket < this.listTotal) {
            return nextSearchBracket;
        }
        return this.listTotal;
    }

    getDynamicTotalString(): string {
        switch (this.searchFilter.lead_type_slug) {
            case 'WEB':
                return 'จองผ่านเว็บ';
            case 'LINE':
                return 'ผ่านทางไลน์';
            case 'PHONE':
                return 'ผ่านทางโทร';
            default:
                return '';
        }
    }
}
