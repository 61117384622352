import { Component, EventEmitter, Input, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CustomerOrderInstallment } from 'src/app/modules/order/models/tw-booking-model/customer-order-installment';
import { IPaymentEvent } from 'src/app/modules/order/models/interfaces/tw-booking-interface/payment-event.interface';
import { ThaiDatePipe } from 'src/app/pipes/thaidate.pipe';
import { PaymentMethodPipeModule } from 'src/app/directives/texts/payment-method-pipe.module';

@Component({
    standalone: true,
    imports: [CommonModule, ThaiDatePipe, PaymentMethodPipeModule],
    selector: 'app-view-manual-payment',
    templateUrl: './view-manual-payment.component.html',
    styleUrls: ['./view-manual-payment.component.scss'],
})
export class ViewManualPaymentComponent {
    @Input() installment!: CustomerOrderInstallment;
    @Output() cancelPaymentRequest = new EventEmitter<number>();

    constructor() {}

    get latestPaymentEvent(): IPaymentEvent | null {
        if (this.installment.twPaymentEvents.length > 0) {
            let latestEvent = this.installment.twPaymentEvents[this.installment.twPaymentEvents.length - 1];
            return latestEvent;
        }

        return null;
    }

    getPaymentEventById(id: number | undefined): IPaymentEvent | undefined {
        if (!id) {
            return undefined;
        }

        if (this.installment.twPaymentEvents) {
            return this.installment.twPaymentEvents.find((f) => f.id === id);
        }

        return undefined;
    }

    openImage(url: string) {
        window.open(url, '_blank');
    }

    cancelRequestPayment(): void {
        this.cancelPaymentRequest.next(this.latestPaymentEvent.id);
    }
}
