import { IOrderDetail } from '../../../../models/order.model';
import { CategoryTransportIconService } from '../../../../services/category-transport-icon.service';
import { Component, EventEmitter, Inject, Input, OnDestroy, OnInit, Output, PLATFORM_ID } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { CATEGORY_SUB_PRODUCTS } from 'src/app/constants/category-sub-product.constant';
import { ProductView } from 'src/app/models/product-view';
import { selectProductView } from 'src/app/stores/product-store/product.selectors';
import { State } from 'src/app/stores/reducers';
import { Router } from '@angular/router';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { isPlatformBrowser } from '@angular/common';

@Component({
    selector: 'app-product-box-booking-modal',
    templateUrl: './product-box-booking-modal.component.html',
    styleUrls: ['./product-box-booking-modal.component.scss'],
})
export class ProductBoxBookingModalComponent implements OnInit, OnDestroy {
    @Input() orderDetail: IOrderDetail;
    @Output() isRedirecting = new EventEmitter<void>();
    productResult: ProductView;
    private defaultCategoryTransportIconFilePath: string = null;
    private readonly defaultCategoryTransportSlug = 'plane';
    countries: any;
    provinces: any;

    private unsubscribe$: Subject<void> = new Subject<void>();

    constructor(
        @Inject(PLATFORM_ID) protected platformId: Object,
        private router: Router,
        private store: Store<State>,
        private bsModelRef: BsModalRef,
        private categoryTransportIconService: CategoryTransportIconService
    ) {}

    ngOnInit(): void {
        if (!this.orderDetail) {
            this.subscribeToProductDetails();
        }
        this.defaultCategoryTransportIconFilePath = this.categoryTransportIconService.getSvgIconFilePathFromSlug(
            this.defaultCategoryTransportSlug
        );
    }

    ngOnDestroy(): void {
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
    }

    private subscribeToProductDetails(): void {
        this.store.pipe(select(selectProductView), takeUntil(this.unsubscribe$)).subscribe((product: ProductView) => {
            if (product?.id) {
                this.productResult = new ProductView().deserialize(product);
                this.countries = this.productResult.countries;
                this.provinces = this.productResult.provinces;
            } else {
                this.productResult = null;
            }
        });
    }

    getDurationString(): string {
        let categorySubProductsId: number;
        let durationDay: number;
        let durationNight: number;

        if (this.orderDetail) {
            categorySubProductsId = this.orderDetail.categorySubProductsId;
            durationDay = this.orderDetail.durationDay;
            durationNight = this.orderDetail.durationNight;
        } else {
            categorySubProductsId = this.productResult.category_sub_product_id;
            durationDay = this.productResult.duration_day;
            durationNight = this.productResult.duration_night;
        }

        if (categorySubProductsId === CATEGORY_SUB_PRODUCTS.PACKAGE_DO && durationDay === 1) {
            return 'One Day Trip';
        }
        if (durationDay !== null && durationNight !== null) {
            return '' + durationDay + ' วัน ' + durationNight + ' คืน';
        }
        return 'ไม่ระบุ';
    }

    getCategoryTransportIconFilePath(): string {
        let categoryTransportSlug: string;
        if (this.orderDetail) {
            categoryTransportSlug = this.orderDetail.categoryTransportSlug;
        } else {
            categoryTransportSlug = this.productResult.category_transport?.slug;
        }
        if (categoryTransportSlug) {
            return this.categoryTransportIconService.getSvgIconFilePathFromSlug(categoryTransportSlug);
        } else {
            return this.defaultCategoryTransportIconFilePath;
        }
    }

    getGoTransportationString(): string {
        if (!this.orderDetail) {
            if (this.productResult.category_transport) {
                if (this.productResult.category_transport.slug == 'plane') {
                    if (this.productResult.go_transportation) {
                        return this.productResult.go_transportation.name;
                    }
                    return 'เครื่องบิน';
                }
                const categoryTransportReference = this.categoryTransportIconService.getReferenceFromSlug(
                    this.productResult.category_transport.slug
                );
                return categoryTransportReference ? categoryTransportReference.name : 'ไม่ระบุ';
            }
            return this.productResult.go_transportation?.name || 'ไม่ระบุ';
        } else {
            return this.orderDetail.transportName || 'ไม่ระบุ';
        }
    }

    getCommissionString(): string {
        let commissionCompanyInput: number;
        let commissionSellerInput: number;

        if (this.orderDetail) {
            commissionCompanyInput = this.orderDetail.commissionCompany;
            commissionSellerInput = this.orderDetail.commissionSeller;
        } else {
            commissionCompanyInput = this.productResult.commission_company;
            commissionSellerInput = this.productResult.commission_seller;
        }
        const commissionCompany: string = commissionCompanyInput
            ? Math.round(commissionCompanyInput)
                  .toString()
                  .replace(/\B(?=(\d{3})+(?!\d))/g, ',')
            : null;
        const commissionSeller: string = commissionSellerInput
            ? Math.round(commissionSellerInput)
                  .toString()
                  .replace(/\B(?=(\d{3})+(?!\d))/g, ',')
            : null;

        if (!!commissionCompany || !!commissionSeller) {
            if (!commissionSeller) {
                return 'คอมบริษัท ' + commissionCompany;
            }
            if (!commissionCompany) {
                return 'คอมเซลล์ ' + commissionSeller;
            }
            return 'คอม ' + commissionCompany + ' + ' + commissionSeller;
        }
        return 'ไม่ระบุ';
    }

    redirectToProductViewPage(): void {
        let url: string = null;
        if (this.productResult) {
            let productTypeSegment: string = null;
            switch (this.productResult.category_sub_product_id) {
                case CATEGORY_SUB_PRODUCTS.JOIN:
                    productTypeSegment = 'join-tour';
                    break;
                case CATEGORY_SUB_PRODUCTS.PACKAGE:
                    productTypeSegment = 'package-tour';
                    break;
                case CATEGORY_SUB_PRODUCTS.PACKAGE_DO:
                    productTypeSegment = 'package-domestic-tour';
                    break;
                case CATEGORY_SUB_PRODUCTS.JOIN_DO:
                    productTypeSegment = 'package-domestic-tour';
                    break;
            }
            url = this.router.serializeUrl(
                this.router.createUrlTree(['product', productTypeSegment, 'view', this.productResult.product_pool_id], {
                    queryParams: { pool: true },
                })
            );
        } else if (this.orderDetail) {
            if (this.orderDetail.poolKey) {
                const categorySubProduct = parseInt(this.orderDetail.poolKey.substring(2, 3));
                let productTypeSegment: string = null;
                switch (categorySubProduct) {
                    case CATEGORY_SUB_PRODUCTS.JOIN:
                        productTypeSegment = 'join-tour';
                        break;
                    case CATEGORY_SUB_PRODUCTS.PACKAGE:
                        productTypeSegment = 'package-tour';
                        break;
                    case CATEGORY_SUB_PRODUCTS.PACKAGE_DO:
                        productTypeSegment = 'package-domestic-tour';
                        break;
                }
                url = this.router.serializeUrl(
                    this.router.createUrlTree(['product', productTypeSegment, 'view', 0], {
                        queryParams: { pool: true, pool_key: this.orderDetail.poolKey },
                    })
                );
            }
        }
        if (url && isPlatformBrowser(this.platformId)) {
            window.open(url, '_blank');
        }
    }
}
