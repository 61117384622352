import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { AuthenticationService } from 'src/app/services/authentication.service';

@Component({
    selector: 'app-loading-page',
    templateUrl: './loading-page.component.html',
    styleUrls: ['./loading-page.component.scss'],
})
export class LoadingPageComponent implements OnInit {
    private redirectUrl: string;

    constructor(
        private router: Router,
        private authenService: AuthenticationService,
        private activatedRoute: ActivatedRoute,
        private ngxService: NgxUiLoaderService
    ) {
        this.activatedRoute.queryParams.subscribe((params) => {
            this.redirectUrl = params.r;
        });
    }

    ngOnInit() {
        const reloadCount = this.authenService.getReload();
        if (reloadCount > 0) {
            location.reload();
            this.authenService.setReload(0);
        } else {
            this.ngxService.start();
            this.router.navigate([this.redirectUrl]);
            this.ngxService.stop();
        }
    }
}
