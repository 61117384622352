import { PartnersList } from './../../models/partners-list.model';
import { UsersList } from './../../models/users-list';
import { AgencyDetailsService } from './../../services/agency-details.service';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, concatMap, switchMap, tap } from 'rxjs/operators';
import { EMPTY, of, Observable, pipe } from 'rxjs';

import * as UserActions from './user.actions';

import { AuthenticationService } from 'src/app/services/authentication.service';
import { NgxUiLoaderService } from 'ngx-ui-loader';

@Injectable()
export class UserEffects {
    constructor(
        private actions$: Actions,
        private authen: AuthenticationService,
        private router: Router,
        private ngxService: NgxUiLoaderService,
        private agencyDetailsService: AgencyDetailsService
    ) {}

    logoutSuccess$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(UserActions.logoutSuccess),
                tap(() => {
                    this.router.navigate(['/login']);
                })
            ),
        { dispatch: false }
    );

    login = createEffect(
        () => () =>
            this.actions$.pipe(
                ofType(UserActions.login),
                concatMap((action) => {
                    this.ngxService.start();
                    return this.authen.login(action.username, action.password).pipe(
                        map((resp) => {
                            this.ngxService.stop();
                            if (resp.status == 'error') {
                                throw Error(resp.message);
                            }

                            const token = resp.data.token;
                            this.authen.setToken(token);
                            return UserActions.loginSuccess(resp);
                        }),
                        catchError((error) => {
                            this.ngxService.stop();
                            return of(UserActions.loginFailure({ error }));
                        })
                    );
                })
            )
    );

    logout = createEffect(
        () => () =>
            this.actions$.pipe(
                ofType(UserActions.logout),
                switchMap(() => {
                    return this.authen.logout().pipe(map((resp) => UserActions.logoutSuccess()));
                })
            )
    );

    checkAuthen = createEffect(
        () => () =>
            this.actions$.pipe(
                ofType(UserActions.checkAuthentication),
                concatMap((action) => {
                    const token = this.authen.getToken();

                    if (token) {
                        return of(UserActions.checkAuthenticationSuccess({ data: token }));
                    }

                    return of(UserActions.checkAuthenticationFailure({ error: 'Token not found.' }));
                })
            )
    );

    getLeadCount = createEffect(
        () => () =>
            this.actions$.pipe(
                ofType(UserActions.getAgencyInfo),
                switchMap((action) => {
                    return this.agencyDetailsService.getAgencyInfo().pipe(
                        map((resp) => {
                            if (resp.status == 'success') {
                                return UserActions.getAgencyInfoSuccess({
                                    data: resp.data,
                                });
                            } else {
                                return UserActions.getAgencyInfoFailure({
                                    error: resp,
                                });
                            }
                        }),
                        catchError((error) => {
                            return of(
                                UserActions.getAgencyInfoFailure({
                                    error,
                                })
                            );
                        })
                    );
                })
            )
    );

    getUsersList = createEffect(
        () => () =>
            this.actions$.pipe(
                ofType(UserActions.getUsersList),
                switchMap((action) => {
                    if (!action.hideLoadingIcon) {
                        this.ngxService.start();
                    }
                    return this.agencyDetailsService.getUsersList(action.getAll, action.showInactive).pipe(
                        map((resp) => {
                            this.ngxService.stop();
                            if (resp.status == 'success') {
                                return UserActions.getUsersListSuccess({
                                    usersList: new UsersList().deserialize(resp.data),
                                });
                            } else {
                                return UserActions.getUsersListFailure({
                                    error: resp.message,
                                });
                            }
                        }),
                        catchError((error) => {
                            this.ngxService.stop();
                            return of(
                                UserActions.getUsersListFailure({
                                    error,
                                })
                            );
                        })
                    );
                })
            )
    );

    getPartnersList = createEffect(
        () => () =>
            this.actions$.pipe(
                ofType(UserActions.getPartnersList),
                switchMap((action) => {
                    this.ngxService.start();
                    return this.agencyDetailsService.getPartnersList().pipe(
                        map((resp) => {
                            this.ngxService.stop();
                            if (resp.status == 'success') {
                                return UserActions.getPartnersListSuccess({
                                    partnersList: new PartnersList().deserialize(resp.data),
                                });
                            } else {
                                return UserActions.getPartnersListFailure({
                                    error: resp.message,
                                });
                            }
                        }),
                        catchError((error) => {
                            this.ngxService.stop();
                            return of(
                                UserActions.getPartnersListFailure({
                                    error,
                                })
                            );
                        })
                    );
                })
            )
    );
}
