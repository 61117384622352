<div class="header">PREVIEW</div>
<div class="program-detail">
    <div class="title">{{ program.name }}</div>
    <div class="row">
        <div class="col-7">
            <div class="d-flex">
                <div class="program-cover">
                    <img [src]="program.bannerUrl" alt="{{ program.name }}" />
                </div>
                <div class="program-info pt-3 pb-1">
                    <div>
                        <div class="mb-1">
                            <span class="font-weight-bold">จำนวนวัน : </span> {{ program.durationDay }} วัน
                            {{ program.durationNight }} คืน
                        </div>
                        <div class="mb-1">
                            <span class="font-weight-bold">ช่วงเวลาเดินทาง : </span>
                            {{ getPeriodTime(period.startAt, period.endAt) }}
                        </div>
                        <div class="mb-1"><span class="font-weight-bold">ผู้เดินทาง : </span> {{ quantity }} ท่าน</div>
                        <div class="mb-1">
                            <span class="font-weight-bold">ยอดที่ต้องชำระ : </span>
                            {{ skuList.totalRealPaid | currency: 'THB':'' }} บาท
                        </div>
                    </div>
                    <div>
                        <a class="link-program">ดูข้อมูลโปรแกรมทัวร์</a>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-5">
            <div class="payment-installment">
                <div class="installment">
                    กรุณาชำระเงินงวดที่ 1
                    <span class="price">
                        {{ skuList.customerInstallment.installments[0].price | currency: 'THB':'' }}
                    </span>
                </div>
                <div class="installment-date">
                    (ชำระภายใน {{ skuList.customerInstallment.installments[0].date | thaiDate }})
                </div>
                <button class="btn btn-payment">คลิกที่นี่เพื่อชำระเงิน</button>
            </div>
        </div>
    </div>
</div>
<div class="order-info row">
    <div class="col-6">
        <div class="box">
            <div class="title">ข้อมูลการชำระเงิน</div>
            <ng-container *ngFor="let item of skuList.customerInstallment.installments; let i = index">
                <hr *ngIf="i > 0 && item.price > 0" style="width: 95%; margin: 0 auto" />
                <div *ngIf="!item.hide" class="info d-flex justify-content-between">
                    <div>
                        <div>
                            <span
                                *ngIf="!skuList.customerInstallment.installments[1].hide"
                                class="badge badge-light mr-1"
                                >งวดที่ {{ i + 1 }}</span
                            >
                            <img class="align-baseline" style="width: 16px" src="assets/img/icon/icon-give-money.svg" />
                            รอชำระเงิน
                        </div>
                        <div class="font-12 font-weight-300 blue-grey">(ชำระภายใน {{ item.date | thaiDate }})</div>
                    </div>
                    <div>
                        <div class="text-right">{{ item.price | currency: 'THB':'' }} บาท</div>
                        <a *ngIf="i === 0" class="link font-12 font-weight-300">คลิกที่นี่เพื่อชำระเงิน</a>
                    </div>
                </div>
            </ng-container>
        </div>
    </div>
    <div class="col-6">
        <div class="box">
            <div class="title">ข้อมูล Order</div>
            <div class="info">
                <div class="mb-1">{{ program.name }}</div>
                <div
                    *ngIf="adultDoubleBed && skuList.skuQuantity.adultDoubleBed > 0"
                    class="d-flex justify-content-between"
                >
                    <span class="sku">ผู้ใหญ่พักคู่</span>
                    <span class="main-font"
                        >{{ adultDoubleBed.price }} x {{ skuList.skuQuantity.adultDoubleBed }} ที่</span
                    >
                </div>
                <div
                    *ngIf="adultSingleBed && skuList.skuQuantity.adultSingleBed > 0"
                    class="d-flex justify-content-between"
                >
                    <span class="sku">ผู้ใหญ่พักเดี่ยว</span>
                    <span class="main-font"
                        >{{ adultSingleBed.price }} x {{ skuList.skuQuantity.adultSingleBed }} ที่</span
                    >
                </div>
                <div *ngIf="childNoBed && skuList.skuQuantity.childNoBed > 0" class="d-flex justify-content-between">
                    <span class="sku">เด็กไม่มีเตียง</span>
                    <span class="main-font">{{ childNoBed.price }} x {{ skuList.skuQuantity.childNoBed }} ที่</span>
                </div>
                <div
                    *ngIf="adultTripleBed && skuList.skuQuantity.adultTripleBed > 0"
                    class="d-flex justify-content-between"
                >
                    <span class="sku">ผู้ใหญ่พักสาม</span>
                    <span class="main-font"
                        >{{ adultTripleBed.price }} x {{ skuList.skuQuantity.adultTripleBed }} ที่</span
                    >
                </div>
                <div
                    *ngIf="childWithBed && skuList.skuQuantity.childWithBed > 0"
                    class="d-flex justify-content-between"
                >
                    <span class="sku">เด็กมีเตียง</span>
                    <span class="main-font">{{ childWithBed.price }} x {{ skuList.skuQuantity.childWithBed }} ที่</span>
                </div>
                <div *ngIf="infant && skuList.skuQuantity.infant > 0" class="d-flex justify-content-between">
                    <span class="sku">ทารก</span>
                    <span class="main-font">{{ infant.price }} x {{ skuList.skuQuantity.infant }} ที่</span>
                </div>
                <div *ngIf="joinLand && skuList.skuQuantity.joinLand > 0" class="d-flex justify-content-between">
                    <span class="sku">จอยแลนด์</span>
                    <span class="main-font">{{ joinLand.price }} x {{ skuList.skuQuantity.joinLand }} ที่</span>
                </div>
            </div>
            <div class="info grey py-2">
                <div class="d-flex justify-content-between">
                    <span class="font-weight-300">จำนวนเงินรวม</span>
                    <span class="main-font">{{ skuList.total | currency: 'THB':'' }}</span>
                </div>
                <div *ngIf="skuList.discount" class="d-flex justify-content-between">
                    <span class="font-weight-300">ส่วนลด</span>
                    <span class="main-font color-red">-{{ skuList.discount | currency: 'THB':'' }}</span>
                </div>
                <div *ngIf="skuList.requestVat" class="d-flex justify-content-between">
                    <span class="font-weight-300">ภาษีมูลค่าเพิ่ม {{ skuList.vatPercentage }}%</span>
                    <span class="main-font">{{ skuList.totalIncludeVat | currency: 'THB':'' }}</span>
                </div>
            </div>
            <div class="info dark-grey py-2">
                <div class="d-flex justify-content-between">
                    <span class="font-weight-300">จำนวนเงินจ่ายสุทธิ (Total)</span>
                    <span class="main-font">{{ skuList.totalRealPaid | currency: 'THB':'' }} บาท</span>
                </div>
            </div>
        </div>
    </div>
    <div class="col-6">
        <div class="box">
            <div class="title">ข้อมูลการชำระเงิน</div>
            <div class="info">
                <div>คุณ {{ customerInfo?.name }}</div>
                <div>
                    โทร. <span class="main-font">{{ customerInfo?.phoneNumber }}</span>
                </div>
                <div>อีเมล {{ customerInfo?.email }}</div>
                <div class="font-weight-300 pre-line">{{ customerInfo?.remark }}</div>
            </div>
        </div>
    </div>
</div>
