import { Injectable } from '@angular/core';
import { FillPattern } from 'exceljs';
import { ExcelServiceBase } from './base/excel.service';
import { IOrderBalanceCommissionReport } from '../models/order-balance-comission-report.model';

@Injectable({
    providedIn: 'root',
})
export class ExportOrderBalanceCommissionExcelService extends ExcelServiceBase {
    public exportOrderBalanceCommissionReport(
        fileName: string,
        dateRange: string,
        count: number,
        total: string,
        commissionTotal: string,
        reportModels: IOrderBalanceCommissionReport[]
    ): void {
        try {
            //Declare variables
            const worksheetName = fileName;
            const worksheet = this.createWorksheet(worksheetName);

            //Adding title row
            const titleRow = worksheet.getCell('A1');
            titleRow.value = `รอบการโอนเงินวันที่ ${dateRange} จำนวน Order ${count} รวม ${total} ค่าคอมที่จะได้รับ ${commissionTotal}`;

            //Set font style
            titleRow.font = {
                bold: true,
            };

            //Adding Header Row
            const headerRow = worksheet.addRow([
                'รอบโอนเงิน',
                'Receipt No.',
                'Vat \r\n(7% ของคอมรวม)',
                'หัก ณ ที่จ่าย',
                'ค่าคอมสุทธิที่ได้รับ',
                'วันที่ออกใบเสร็จ',
                'เวลาที่ออกใบเสร็จ',
                'สถานะการโอนเงิน',
                'วันที่โอนเงิน',
                'เวลาที่โอนเงิน',
                'สถานะการยืนยันรับเงิน',
                'วันที่ยืนยันรับเงิน',
                'เวลาที่ยืนยันรับเงิน',
                'ยืนยันรับเงินโดย \r\n(ชื่อ-สกุล)',
                'ชื่อเล่น',

                'ชื่อ Seller',
                'เบอร์โทร Seller',

                'Order No.',
                'รหัส Campaign',
                'ชื่อ Campaign',
                'ชื่อเวาเชอร์',
                'ราคา / เวาเชอร์',
                'คอม / เวาเชอร์',
                'จำนวนเวาเชอร์',
                'ยอดรวมค่าเวาเชอร์',
                'คอมรวม \r\n(ก่อนหัก ณ ที่จ่าย)',
                'ชื่อลูกค้า',
                'เบอร์โทรลูกค้า',
                'วันที่ซื้อเวาเชอร์',
                'เวลาที่ซื้อเวาเชอร์',
            ]);

            headerRow.eachCell((cell, colNumber) => {
                const columnInfo = cell.worksheet.getColumn(colNumber);
                columnInfo.width = 30;

                //Set font style
                cell.font = {
                    bold: true,
                };

                //Set border style
                cell.border = {
                    top: { style: 'thin' },
                    left: { style: 'thin' },
                    bottom: { style: 'thin' },
                    right: { style: 'thin' },
                };

                cell.alignment = { horizontal: 'center' };

                //Set bg style
                this.setHeaderBgStyle(colNumber, cell);
                if (colNumber === 1) {
                    columnInfo.width = 15;
                }
            });

            //Converting model to data rows
            const dataRows = [];
            reportModels.map((data) => {
                dataRows.push(Object.values(data));
            });

            // Adding Data
            dataRows.forEach((d) => {
                const row = worksheet.addRow(d);
                row.eachCell({ includeEmpty: true }, (cell, colNumber) => {
                    //Set border style
                    cell.border = {
                        top: { style: 'thin' },
                        left: { style: 'thin' },
                        bottom: { style: 'thin' },
                        right: { style: 'thin' },
                    };

                    if (colNumber === 1) {
                        cell.alignment = { horizontal: 'left' };
                    }

                    if ([3, 4, 5, 22, 23, 25, 26].includes(colNumber)) {
                        cell.numFmt = '#,##0.00';
                    }
                });
            });

            // Export to excel file
            this.generateFile(fileName);
        } catch (error) {
            throw new Error(error);
        }
    }

    private setHeaderBgStyle(number: number, cell) {
        const greenColor = 'E2EFDA';
        const blueColor = 'D9E1F2';
        const yellowColor = 'FFE699';

        //Constant variables
        let bgColor = greenColor;

        if (number >= 1 && number <= 15) {
            bgColor = greenColor;
        } else if (number >= 16 && number <= 17) {
            bgColor = blueColor;
        } else {
            bgColor = yellowColor;
        }

        (<FillPattern>cell.fill) = {
            type: 'pattern',
            pattern: 'solid',
            bgColor: { argb: bgColor },
            fgColor: { argb: bgColor },
        };
    }
}
