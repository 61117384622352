export class BannerImage {
    id: number;
    pathURL: string;

    public deserialize(data: any): this {
        this.id = data.id;
        this.pathURL = data.url;

        return this;
    }
}
