import { ICanceledByAgencyMember } from '../interfaces/tw-booking-interface/canceled-by-agency-member.interface';

export class CanceledByAgencyMember {
    id: number;
    firstName: string;
    nickName: string;
    constructor() {}
    public deserialize(data: ICanceledByAgencyMember): CanceledByAgencyMember {
        this.id = data.id;
        this.firstName = data.first_name;
        this.nickName = data.nick_name;

        return this;
    }
}
