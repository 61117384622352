import { Component, EventEmitter, OnInit } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { IModalResponse } from '../../models/filters/product/interfaces/modal-response.interface';

@Component({
    selector: 'app-dropdown-modal',
    templateUrl: './dropdown-modal.component.html',
    styleUrls: ['./dropdown-modal.component.scss'],
})
export class DropdownModalComponent implements OnInit {
    title: string;
    dropdownList: IDropdownItem[] = [];

    dataSelected: IDropdownItem = {
        value: null,
        label: 'กรุณาเลือก',
    };

    invalidData: boolean = false;
    dropdownModalResponse: EventEmitter<IModalResponse<IDropdownItem>> = new EventEmitter();

    constructor(public bsModalRef: BsModalRef, public bsModalService: BsModalService) {}

    ngOnInit() {}

    onSelected(item: IDropdownItem) {
        if (this.invalidData && item.value) {
            this.invalidData = false;
        }
        this.dataSelected = item;
    }

    onSubmit() {
        if (this.validate()) {
            this.dropdownModalResponse.emit({ status: true, data: this.dataSelected });
            this.bsModalRef.hide();
        }
    }
    onCancle() {
        this.dropdownModalResponse.emit({ status: false });
        this.bsModalRef.hide();
    }

    validate(): boolean {
        if (this.dataSelected.value) {
            this.invalidData = false;
            return true;
        } else {
            this.invalidData = true;
            return false;
        }
    }
}

interface IDropdownItem {
    value: string;
    label: string;
}
