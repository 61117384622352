import { Injectable, Inject, PLATFORM_ID } from '@angular/core';
import { HttpClient, HttpEventType } from '@angular/common/http';
import { Router } from '@angular/router';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';

import { HttpService } from './base/http.service';

import { ENDPOINTS } from '../constants/endpoint.constant';
import { environment } from 'src/environments/environment';

@Injectable({
    providedIn: 'root',
})
export class GalleryService extends HttpService {
    constructor(
        @Inject(PLATFORM_ID) protected platformId: Object,
        protected http: HttpClient,
        protected router: Router
    ) {
        super(platformId, http, router);
    }

    public getFiles(galleryId: number) {
        let url = environment.apiProUrl + ENDPOINTS.GALLERY.GET_FILES;
        url = url.replace('{galleries_id}', galleryId.toString());

        return this.http.get(url, {
            headers: {
                Authorization: this.getToken(),
                'Content-Type': 'multipart/form-data',
                Accept: 'application/json',
            },
        });
    }

    public uploadNewFile(payload: FormData) {
        const uploadURL = environment.apiProUrl + ENDPOINTS.GALLERY.UPLOAD;

        return this.http
            .post<any>(uploadURL, payload, {
                reportProgress: true,
                observe: 'events',
                headers: {
                    Authorization: this.getToken(),
                },
            })
            .pipe(
                map((event) => {
                    switch (event.type) {
                        case HttpEventType.UploadProgress:
                            const progress = Math.round((100 * event.loaded) / event.total);
                            return { status: 'progress', message: progress };

                        case HttpEventType.Response:
                            return event.body;
                        default:
                            return `Unhandled event: ${event.type}`;
                    }
                })
            );
    }

    public deleteFile(galleryId: number, fileId: number): Observable<any> {
        const url =
            environment.apiProUrl +
            ENDPOINTS.GALLERY.DELETE.replace('{galleries_id}', galleryId).replace('{file_id}', fileId);
        return this.http.delete(url, {
            headers: {
                Authorization: this.getToken(),
            },
        });
    }
}
