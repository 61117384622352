import { IValidate } from 'src/app/models/validate';
import { Content, EnumContentType, IAddBlogDescriptions, IEditBlogDescriptions } from 'src/app/models/content';

// for listing part
export interface IBlog {
    id: number;
    title: string;
    imageUrl: string;
    mainDescription: string;
}

export interface IBlogListing {
    page: number;
    pageEntries: number;
    total: number;
    blogList: IBlog[];
}

export interface ICreateBlog {
    title: string;
    blog_descriptions: IAddBlogDescriptions[];
    tags: string[];
    validate: IValidate;
}

export interface IBlogModifier {
    id: number;
    title: string;
    blog_descriptions: IEditBlogDescriptions[];
    tags: string[];
    validate: IValidate;
    deletedDescriptions: number[];
}

export interface IEditBlogSubmit {
    title: string;
    tags: string[];
    blog_descriptions_to_create: IAddBlogDescriptions[];
    blog_descriptions_to_update: IEditBlogDescriptions[];
    blog_description_ids_to_delete: number[];
}

// for viewing
export interface IBlogDetail {
    id: number;
    title: string;
    contents: Content[];
    tags: string[];
}

export class Blog {
    private blogListing: IBlogListing = {
        page: null,
        pageEntries: null,
        total: null,
        blogList: [],
    };

    public deserialize(obj: any): IBlogListing {
        this.blogListing.page = obj.page;
        this.blogListing.pageEntries = obj.page_entries;
        this.blogListing.total = obj.total;

        obj.result.map((singleObj: any) => {
            this.blogListing.blogList.push(this.deserializeSingle(singleObj));
        });
        return this.blogListing;
    }

    private deserializeSingle(obj: any): IBlog {
        const blog: IBlog = {
            id: obj.id,
            title: obj.title,
            imageUrl: obj.main_image_preview_url,
            mainDescription: obj.main_description ? obj.main_description.substring(0, 200) + '... ' : null,
        };
        return blog;
    }

    public deserializeDetail(obj: any): IBlogDetail {
        const blogDetail: IBlogDetail = {
            id: obj.id,
            title: obj.title,
            tags: obj.tags,
            contents: [],
        };
        obj.blog_descriptions.map((description: any) => {
            const content: Content = {
                id: description.id,
                type: this.getDescriptionType(description.type_slug),
                ordinal: description.ordinal,
                text: description.description || description.image_description,
                imageUrl: description.image_url,
                isTextType: this.getDescriptionType(description.type_slug) === EnumContentType.Text,
                isImageAndTextType: this.getDescriptionType(description.type_slug) === EnumContentType.ImageAndText,
                galleryId: description.agcy_galleries_id,
            };
            blogDetail.contents.push(content);
        });

        return blogDetail;
    }

    private getDescriptionType(typeSlug: string): EnumContentType {
        if (typeSlug === 'text') {
            return EnumContentType.Text;
        }
        if (typeSlug == 'image') {
            return EnumContentType.ImageAndText;
        }
        return EnumContentType.Unknown;
    }
}
