import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'displayImageDefault',
})
export class DisplayImageDefaultPipe implements PipeTransform {
    transform(value: any, ...args: any[]): any {
        let array;
        $.each(value.gallery_item, (index, valueItem) => {
            if (valueItem.is_active) {
                array = valueItem.file_url;
            }
        });
        return array;
    }
}

@Pipe({
    name: 'getImagePreview',
})
export class GetImagePreviewPipe implements PipeTransform {
    transform(value: any, ...args: any[]): any {
        let array;
        $.each(value.gallery_item, (index, valueItem) => {
            if (valueItem.is_active) {
                array = valueItem.preview_url;
            }
        });
        return array;
    }
}
