import { IVoucher, Voucher } from './voucher.model';

export class CampaignPaginated implements ICampaignPaginated {
    currentPage: number;
    itemPerPage: number;
    totalItems: number;
    campaigns: ICampaignPaginatedItem[];

    public deserialize(data: any): this {
        this.currentPage = data.page;
        this.itemPerPage = data.page_entries;
        this.totalItems = data.total;
        this.campaigns = data.result ? data.result.map((r) => new CampaignPaginatedItem().deserialize(r)) : [];

        return this;
    }
}

export class CampaignPaginatedItem implements ICampaignPaginatedItem {
    typeSlug: string;
    sellStartDate: Date;
    sellEndDate: Date;
    voucherStartDate: Date;
    voucherEndDate: Date;
    voucherMaxQuantity: number;
    voucherQuantitySold: number;
    voucherQuantityRemaining: number;
    voucherMaxCommissionAgency: number;

    locationName: string;
    price: number;
    priceCompare: number;
    mainBannerUrl: string;
    isActived: boolean;
    isExpired: boolean;
    updated: Date;
    vouchers: IVoucher[];
    partner: IPartner;
    campaignId: number;
    campaignCode: string;
    name: string;

    hilightDescription: string;
    twmDetailPagePath: string;

    public deserialize(data: any): this {
        this.campaignCode = data.campaign_code;
        this.campaignId = data.campaign_id;
        this.isActived = data.is_active;
        this.isExpired = data.is_expired;
        this.locationName = data.location_name;
        this.mainBannerUrl = data.main_banner_image_file_url;
        this.name = data.name;
        this.price = data.price;
        this.priceCompare = data.price_compare;
        this.sellEndDate = data.sell_end_date ? new Date(data.sell_end_date) : null;
        this.sellStartDate = data.sell_start_date ? new Date(data.sell_start_date) : null;
        this.typeSlug = data.type_slug;
        this.updated = data.updated_at ? new Date(data.updated_at) : null;
        this.voucherEndDate = data.voucher_end_date ? new Date(data.voucher_end_date) : null;
        this.voucherMaxQuantity = data.voucher_max_quantity;
        this.voucherQuantitySold = data.voucher_quantity_sold;
        this.voucherQuantityRemaining = data.voucher_quantity_remaining;
        this.voucherMaxCommissionAgency = data.voucher_max_commission_agency;

        this.voucherStartDate = data.voucher_start_date ? new Date(data.voucher_start_date) : null;
        this.vouchers = data.vouchers ? data.vouchers.map((v) => new Voucher().deserialize(v)) : [];
        this.partner = new Partner().deserialize(data.partner);
        this.hilightDescription = data.hilight_description;
        this.twmDetailPagePath = data.twm_detail_page_path;

        return this;
    }
}

export class CampaignItem implements ICampaignItem {
    typeSlug: string;
    sellStartDate: Date;
    sellEndDate: Date;
    voucherStartDate: Date;
    voucherEndDate: Date;
    voucherMaxQuantity: number;
    voucherQuantitySold: number;
    voucherQuantityRemaining: number;
    voucherMaxCommissionAgency: number;

    locationName: string;
    price: number;
    priceCompare: number;
    mainBannerUrl: string;
    isActived: boolean;
    isExpired: boolean;
    updated: Date;
    partner: IPartner;
    campaignId: number;
    campaignCode: string;
    name: string;
    hilightDescription: string;
    twmDetailPagePath: string;

    public deserialize(data: any): this {
        this.campaignCode = data.campaign_code;
        this.campaignId = data.campaign_id;
        this.isActived = data.is_active;
        this.isExpired = data.is_expired;
        this.locationName = data.location_name;
        this.mainBannerUrl = data.main_banner_image_file_url;
        this.name = data.name;
        this.price = data.price;
        this.priceCompare = data.price_compare;
        this.sellEndDate = data.sell_end_date ? new Date(data.sell_end_date) : null;
        this.sellStartDate = data.sell_start_date ? new Date(data.sell_start_date) : null;
        this.typeSlug = data.type_slug;
        this.updated = data.updated_at ? new Date(data.updated_at) : null;
        this.voucherEndDate = data.voucher_end_date ? new Date(data.voucher_end_date) : null;
        this.voucherMaxQuantity = data.voucher_max_quantity;
        this.voucherQuantitySold = data.voucher_quantity_sold;
        this.voucherStartDate = data.voucher_start_date ? new Date(data.voucher_start_date) : null;
        this.partner = new Partner().deserialize(data.partner);

        return this;
    }
}

export class Campaign implements ICampaign {
    campaignId: number;
    campaignCode: string;
    name: string;

    public deserialize(data: any): this {
        this.campaignCode = data.campaign_code;
        this.campaignId = data.campaign_id;
        this.name = data.name;

        return this;
    }
}

export class CampaignSearchBoxFilter implements ICampaignSearchBoxFilter {
    campaignTypes: string[];
    campaignCode: string;
    campaignIds: number[];
}

export class Partner implements IPartner {
    id: number;
    nameTh: string;
    nameEn: string;
    emailAddress: string;
    officePhoneNumber: string;
    additionalPhoneNumber: string;
    address: string;

    public deserialize(data: any): this {
        this.id = data.id;
        this.nameEn = data.name_en;
        this.nameTh = data.name_th;
        this.emailAddress = data.email;
        this.officePhoneNumber = data.office_telephone_number;
        this.additionalPhoneNumber = data.additional_phone_number;
        this.address = data.address;

        return this;
    }
}

export interface ICampaign {
    campaignId: number;
    campaignCode: string;
    name: string;
}

export interface IPartner {
    id: number;
    nameTh: string;
    nameEn: string;
    emailAddress: string;
    officePhoneNumber: string;
    additionalPhoneNumber: string;
    address: string;
}

export interface ICampaignPaginatedItem extends ICampaignItem {
    vouchers: IVoucher[];
    hilightDescription: string;
}

export interface ICampaignPaginated {
    currentPage: number;
    itemPerPage: number;
    totalItems: number;
    campaigns: ICampaignPaginatedItem[];
}

export interface ICampaignPaginatedFilter {
    typeSlugs: string[];
    campaignIds: number[];
    campaignCode: string;
}

export interface ICampaignItem extends ICampaign {
    typeSlug: string;
    sellStartDate: Date;
    sellEndDate: Date;
    voucherStartDate: Date;
    voucherEndDate: Date;
    voucherMaxQuantity: number;
    voucherQuantitySold: number;
    voucherQuantityRemaining: number;
    voucherMaxCommissionAgency: number;

    locationName: string;
    price: number;
    priceCompare: number;
    mainBannerUrl: string;
    isActived: boolean;
    isExpired: boolean;
    updated: Date;
    partner: IPartner;
    hilightDescription: string;
    twmDetailPagePath: string;
}

export interface ICampaignSearchBoxFilter {
    campaignTypes: string[];
    campaignCode: string;
    campaignIds: number[];
}
