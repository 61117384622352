import { NgxUiLoaderService } from 'ngx-ui-loader';
import { Injectable } from '@angular/core';
import { CountryService } from 'src/app/services/country.service';
import { CaterogyTransportService } from 'src/app/services/category-transport.service';
import { TransportationService } from 'src/app/services/transportation.service';
import { GalleryService } from 'src/app/services/gallery.service';
import { AboutUsService } from 'src/app/services/about-us.service';
import { ProvinceService } from 'src/app/services/province.service';
import { BankService } from 'src/app/services/bank.service';

import { Actions, ofType, createEffect } from '@ngrx/effects';
import { catchError, map, switchMap, tap, mergeMap } from 'rxjs/operators';
import { of } from 'rxjs';

import * as UtilityActions from './utility.actions';
import { ICountry } from 'src/app/models/country.model';
import { ICategoryTransport, CategoryTransport } from 'src/app/models/category-transport.model';
import { ITransportation, Transportation } from 'src/app/models/transportation.model';
import { IAboutUsDetails } from 'src/app/modules/settings/models/about-us.model';
import { Province } from 'src/app/models/province';
import { IBank } from 'src/app/models/bank.model';
import { EventLogService } from 'src/app/services/event-log.service';
import { CountrySubUnitService } from 'src/app/services/country-sub-unit.service';
import { CountrySubUnit } from 'src/app/models/country-sub-unit.model';
import { ProductSubCategoryService } from 'src/app/services/product-sub-category.service';
import { ProductSubCategory } from 'src/app/models/product-sub-category.model';

@Injectable()
export class UtilityEffects {
    constructor(
        private actions$: Actions,
        private countryService: CountryService,
        private provinceService: ProvinceService,
        private countrySubUnitService: CountrySubUnitService,
        private categoryTransportService: CaterogyTransportService,
        private transportationService: TransportationService,
        private galleryService: GalleryService,
        private aboutUsService: AboutUsService,
        private bankService: BankService,
        private productSubCategoryService: ProductSubCategoryService,
        private eventLogService: EventLogService,
        private ngxLoader: NgxUiLoaderService
    ) {}

    loadCountry = createEffect(() => () =>
        this.actions$.pipe(
            ofType(UtilityActions.loadCountries),
            mergeMap((action) => {
                return this.countryService.getAllCountries().pipe(
                    map((resp) => {
                        const iCountries: ICountry[] = resp.data.map((country) => {
                            const iCountry: ICountry = {
                                id: 0,
                                nameEN: null,
                                nameTH: null,
                                iconPathUrl: null,
                            };

                            iCountry.id = country.id;
                            iCountry.nameTH = country.name_th;
                            iCountry.nameEN = country.name_en;
                            iCountry.iconPathUrl = country.icon_url;
                            return iCountry;
                        });

                        return UtilityActions.loadCountriesSuccess({ countries: iCountries });
                    }),
                    catchError((error) => of(UtilityActions.loadCountriesFailure(error)))
                );
            })
        )
    );

    loadProvinces = createEffect(() => () =>
        this.actions$.pipe(
            ofType(UtilityActions.loadProvinces),
            mergeMap((action) => {
                return this.provinceService.getAllProvinces().pipe(
                    map((resp) => {
                        const data: any[] = resp.data;
                        const provinces: Province[] = data.map((provinceData) =>
                            new Province().deserialize(provinceData)
                        );
                        return UtilityActions.loadProvincesSuccess({ provinces });
                    }),
                    catchError((error) => of(UtilityActions.loadProvincesFailure(error)))
                );
            })
        )
    );

    loadCountrySubUnits = createEffect(() => () =>
        this.actions$.pipe(
            ofType(UtilityActions.loadCountrySubUnits),
            mergeMap((action) => {
                return this.countrySubUnitService.getAllCountrySubUnits(action.countryIds).pipe(
                    map((resp) => {
                        return UtilityActions.loadCountrySubUnitsSuccess({
                            countrySubUnits: (resp.data as any[]).map((data) => new CountrySubUnit().deserialize(data)),
                        });
                    }),
                    catchError((error) => of(UtilityActions.loadCountrySubUnitsFailure(error)))
                );
            })
        )
    );

    loadCategoryTranpsorts = createEffect(() => () =>
        this.actions$.pipe(
            ofType(UtilityActions.loadCategoryTransports),
            mergeMap((action) => {
                return this.categoryTransportService.getAllCategoryTransports(action.filters).pipe(
                    map((response) => {
                        const iCategoryTransports: ICategoryTransport[] = response.data.map(
                            (categoryTransportDataObject) => {
                                return new CategoryTransport().deserialize(categoryTransportDataObject);
                            }
                        );
                        return UtilityActions.loadCategoryTransportsSuccess({
                            categoryTransports: iCategoryTransports,
                        });
                    }),
                    catchError((error) => of(UtilityActions.loadCategoryTransportsFailure(error)))
                );
            })
        )
    );

    loadTransportations = createEffect(() => () =>
        this.actions$.pipe(
            ofType(UtilityActions.loadTransportations),
            mergeMap((action) => {
                return this.transportationService.getAllTransportations().pipe(
                    map((response) => {
                        const iTransportations: ITransportation[] = response.data.map((transportationDataObject) => {
                            return new Transportation().deserialize(transportationDataObject);
                        });
                        return UtilityActions.loadTransportationsSuccess({ transportations: iTransportations });
                    }),
                    catchError((error) => of(UtilityActions.loadTransportationsFailure(error)))
                );
            })
        )
    );

    uploadFileGallery = createEffect(() => () =>
        this.actions$.pipe(
            ofType(UtilityActions.uploadFileGallery),
            mergeMap((action) => {
                if (action.showLoader) {
                    this.ngxLoader.start();
                }
                return this.galleryService.uploadNewFile(action.form).pipe(
                    map((response) => {
                        if (action.showLoader) {
                            this.ngxLoader.stop();
                        }
                        if (response && response.status == 'success') {
                            return UtilityActions.uploadFileGallerySuccess({
                                response: {
                                    fileId: response.data.file_id,
                                    fileUrl: response.data.file_url,
                                    filePreviewUrl: response.data.file_preview_url,
                                    fileContentUrl: response.data.file_content_url,
                                    fileThumbnailUrl: response.data.file_thumbnail_url,
                                    galleryId: response.data.galleries_id,
                                    uploadProgress: 100,
                                },
                                identifier: action.identifier,
                            });
                        } else if (response && response.status == 'progress') {
                            return UtilityActions.uploadFileGalleryProgressUpdate({
                                response: {
                                    fileId: null,
                                    fileUrl: null,
                                    galleryId: null,
                                    uploadProgress: response.message,
                                },
                                identifier: action.identifier,
                            });
                        }

                        return UtilityActions.uploadFileGalleryUnhandle({ identifier: action.identifier });
                    }),
                    catchError((error) =>
                        of(
                            UtilityActions.uploadFileGalleryFailure({
                                error,
                                identifier: action.identifier,
                            })
                        )
                    )
                );
            })
        )
    );

    deleteFileGallery = createEffect(() => () =>
        this.actions$.pipe(
            ofType(UtilityActions.deleteFileGallery),
            mergeMap((action) => {
                return this.galleryService.deleteFile(action.galleryId, action.fileId).pipe(
                    map((response) =>
                        UtilityActions.deleteFileGallerySuccess({
                            response,
                            identifier: action.identifier,
                        })
                    ),
                    catchError((error) =>
                        of(
                            UtilityActions.uploadFileGalleryFailure({
                                error,
                                identifier: action.identifier,
                            })
                        )
                    )
                );
            })
        )
    );

    getAboutUsDetails = createEffect(() => () =>
        this.actions$.pipe(
            ofType(UtilityActions.loadAboutUsDetails),
            switchMap(() => {
                return this.aboutUsService.getAboutUsInfo().pipe(
                    map((resp: any) => {
                        if (resp.status == 'success') {
                            const response: IAboutUsDetails = {
                                agenciesId: parseInt(resp.data.agencies_id),
                                galleriesId: parseInt(resp.data.galleries_id),
                                aboutUs: resp.data.about_us,
                                additionalContactInfo: resp.data.additional_contact_info,
                            };
                            return UtilityActions.loadAboutUsDetailsSuccess({
                                response,
                            });
                        } else {
                            return UtilityActions.loadAboutUsDetailsFailure({
                                error: resp,
                            });
                        }
                    }),
                    catchError((error) => {
                        return of(
                            UtilityActions.loadAboutUsDetailsFailure({
                                error,
                            })
                        );
                    })
                );
            })
        )
    );

    editAboutUsDetails = createEffect(() => () =>
        this.actions$.pipe(
            ofType(UtilityActions.editAboutUsDetails),
            switchMap((action) => {
                return this.aboutUsService.editAboutUsInfo(action.payload).pipe(
                    map((resp: any) => {
                        if (resp.status == 'success') {
                            const response: IAboutUsDetails = {
                                agenciesId: parseInt(resp.data.agencies_id),
                                galleriesId: parseInt(resp.data.galleries_id),
                            };
                            return UtilityActions.editAboutUsDetailsSuccess({
                                response,
                            });
                        } else {
                            return UtilityActions.editAboutUsDetailsFailure({
                                error: resp,
                            });
                        }
                    }),
                    catchError((error) => {
                        return of(
                            UtilityActions.editAboutUsDetailsFailure({
                                error,
                            })
                        );
                    })
                );
            })
        )
    );

    loadBank = createEffect(() => () =>
        this.actions$.pipe(
            ofType(UtilityActions.loadBanks),
            mergeMap((action) => {
                return this.bankService.getBanks().pipe(
                    map((resp) => {
                        const iBanks: IBank[] = resp.data.map((bank) => {
                            const iBank: IBank = {
                                id: bank.id,
                                name: bank.name,
                                code: bank.code,
                                number: bank.number,
                                shortName: bank.short_name,
                                imagePath: bank.image_path,
                            };

                            return iBank;
                        });

                        return UtilityActions.loadBanksSuccess({ response: iBanks });
                    }),
                    catchError((error) => of(UtilityActions.loadBanksFailure(error)))
                );
            })
        )
    );

    loadProductSubCategories = createEffect(() => () =>
        this.actions$.pipe(
            ofType(UtilityActions.loadProductSubCategories),
            mergeMap((action) => {
                return this.productSubCategoryService.getAllProductSubCategories(action.productCatgeoryIds).pipe(
                    map((resp) => {
                        return UtilityActions.loadProductSubCategoriesSuccess({
                            productSubCategories: (resp.data as any[]).map((data) =>
                                new ProductSubCategory().deserialize(data)
                            ),
                        });
                    }),
                    catchError((error) => of(UtilityActions.loadProductSubCategoriesFailure(error)))
                );
            })
        )
    );

    eventLog = createEffect(() => () =>
        this.actions$.pipe(
            ofType(UtilityActions.eventLog),
            mergeMap((action) => {
                return this.eventLogService.log(action.entityName, action.actionType, action.content).pipe(
                    map(() => UtilityActions.eventLogSuccess()),
                    catchError(() => of(UtilityActions.eventLogFailure()))
                );
            })
        )
    );
}
