import { Router } from '@angular/router';
import { ILeadBookingRequestSingleDetails } from '../../../models/booking-request-lead-details.model';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { Store, select } from '@ngrx/store';
import { State } from 'src/app/stores/reducers';
import { selectReportBookingRequestDetails } from 'src/app/stores/report-store/report.selectors';
import { Observable } from 'rxjs';
import {
    getBookingRequestLeadDetails,
    getBookingRequestLeadDetailsSuccess,
} from 'src/app/stores/report-store/report.actions';
import { NavigationEnd } from '@angular/router';

@Component({
    selector: 'app-booking-request-info',
    templateUrl: './booking-request-info.component.html',
    styleUrls: ['./booking-request-info.component.scss'],
})
export class BookingRequestInfoComponent implements OnInit, OnDestroy {
    private leadId: number;
    lead$: Observable<ILeadBookingRequestSingleDetails>;
    period: string;
    dateAndTime: string;
    commissionLabel: string;
    commissionString: string;

    form: UntypedFormGroup;

    constructor(
        public bsModalRef: BsModalRef,
        public bsModalService: BsModalService,
        private store: Store<State>,
        private router: Router
    ) {}

    ngOnInit() {
        this.store.dispatch(getBookingRequestLeadDetails({ leadId: this.leadId }));
        this.lead$ = this.store.pipe(select(selectReportBookingRequestDetails));
        this.closeOnRouteChange();
    }

    ngOnDestroy() {
        this.store.dispatch(getBookingRequestLeadDetailsSuccess({ responseBody: null }));
    }

    closeOnRouteChange(): void {
        this.router.events.subscribe((evt) => {
            if (!(evt instanceof NavigationEnd)) {
                return;
            }
            this.bsModalRef.hide();
        });
    }
}
