import { HttpClient } from '@angular/common/http';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { HttpService } from './base/http.service';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { ENDPOINTS } from '../constants/endpoint.constant';
import { switchMap } from 'rxjs/operators';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class ProductPdfDetailFileService extends HttpService {
    constructor(
        @Inject(PLATFORM_ID) protected platformId: Object,
        protected http: HttpClient,
        protected router: Router
    ) {
        super(platformId, http, router);
    }

    public getProductPdfDetailFile(
        productId: number,
        categorySubProductId: number,
        ownerChannel: 'ag' | 'ws',
        isFromPool: boolean,
        loadedAsBase64: boolean = false
    ): Observable<any> {
        if (isFromPool) {
            return this.getProductPoolPdfDetailFileAsUrl(ownerChannel.toUpperCase() + categorySubProductId + productId);
        } else {
            if (loadedAsBase64) {
                return this.getAgencyProductPdfDetailFileAsBase64Data(productId);
            } else {
                return this.getAgencyProductPdfDetailFileAsUrl(productId);
            }
        }
    }

    public getProductPoolPdfDetailFileAsUrl(poolKey: string): Observable<any> {
        const url =
            environment.apiProUrl + (ENDPOINTS.PRODUCT_POOL.PDF_DETAIL_FILE as string).replace('{pool_key}', poolKey);
        return this.getRequestOptions(true).pipe(switchMap((headers) => this.http.get(url, headers)));
    }

    public getAgencyProductPdfDetailFileAsUrl(productId: number): Observable<any> {
        const url =
            environment.apiProUrl +
            (ENDPOINTS.PRODUCT.PDF_DETAIL_FILE.URL as string).replace('{product_id}', productId.toString());
        return this.getRequestOptions(true).pipe(switchMap((headers) => this.http.get(url, headers)));
    }

    public getAgencyProductPdfDetailFileAsBase64Data(productId: number): Observable<any> {
        const url =
            environment.apiProUrl +
            (ENDPOINTS.PRODUCT.PDF_DETAIL_FILE.BASE64 as string).replace('{product_id}', productId.toString());
        return this.getRequestOptions(true).pipe(switchMap((headers) => this.http.get(url, headers)));
    }
}
