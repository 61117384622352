import { Component, OnInit } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder } from '@angular/forms';

// Services
import { GalleryService } from 'src/app/services/gallery.service';

// Models
import { GALLERY_FILE_TYPE } from 'src/app/constants/gallery.constant';
import {
    SearchableDropdownSettings,
    SearchableDropdownItem,
} from 'src/app/modules/shared/components/searchable-dropdown/searchable-dropdown.component';

@Component({
    selector: 'app-example-input',
    templateUrl: './example-input.component.html',
    styleUrls: ['./example-input.component.scss'],
})
export class ExampleInputComponent implements OnInit {
    public imageUploadingPercent: number = 0;
    private form: UntypedFormGroup;
    public dropdownSettings: SearchableDropdownSettings = new SearchableDropdownSettings();
    public dropdownItems: SearchableDropdownItem[] = [];

    constructor(private galleryService: GalleryService, private formBuilder: UntypedFormBuilder) {}

    ngOnInit() {
        this.form = this.formBuilder.group({
            avatar: [''],
        });

        this.loadDropdownlist();
    }

    public loadDropdownlist(): void {
        this.dropdownSettings.dropdownListPlaceholder = 'เลือกประเทศ';
        this.dropdownSettings.searchBoxPlaceholder = 'กรอกชื่อประเทศ';
        this.dropdownSettings.dropdownListAllItemsLabel = 'ทุกประเทศ';
        this.dropdownItems.push(
            new SearchableDropdownItem({
                id: 1,
                name: 'ทัวร์กัมพูชา (Cambodia)',
                imageUrl: 'assets/img/avatars/photo-logo.png',
            })
        );
        this.dropdownItems.push(new SearchableDropdownItem({ id: 2, name: 'ทัวร์เกาหลีใต้ (South-Korea)' }));
        this.dropdownItems.push(new SearchableDropdownItem({ id: 3, name: 'ทัวร์จอร์เจีย (Georgia)' }));
    }

    public selectDropdown(result: number[]): void {}

    public onSelectFileUpload(files: FileList): void {
        const file: File = files[0];
        const formData = new FormData();
        formData.append('file', file, file.name);
        formData.append('galleries_id', '15');
        formData.append('type', GALLERY_FILE_TYPE.BANNER);

        this.galleryService.uploadNewFile(formData).subscribe(
            (res) => console.log(res),
            (err) => console.log(err)
        );
    }
}
