import { CommistionReportList } from '../../modules/order/models/commission-report-list.model';
import { OrderCommissionSummary, OrderSummary } from './../../models/order-summary.model';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { BookingMonthlyReport } from 'src/app/models/booking-monthly-report.model';
import { OrderDetail } from 'src/app/models/order.model';
import { ProductListOptional } from 'src/app/modules/order/models/product-list.model';
import { ProductOwnerListOptional } from 'src/app/modules/order/models/product-owner-list.model';
import { BookingList } from 'src/app/modules/order/models/booking-list.model';
import { BookingStatusCount } from 'src/app/modules/order/models/booking-status-count.model';
import { OrderService } from 'src/app/services/order.service';
import * as OrderActions from './order.actions';
import { SaleReportListPaginated, SaleReportSummary } from 'src/app/modules/order/models/sale-report.model';
import { GroupPlanList } from 'src/app/modules/order/models/group-plan-list.model';
import { CampaignPaymentPaginated } from 'src/app/modules/order/models/campaign-payment.model';
import { ProductOrderService } from 'src/app/services/product-order.service';
import {
    CountOrderStatus,
    CountProductSubCategory,
    ProductOrderListPaginated,
} from 'src/app/modules/order/models/product-order-list.model';

@Injectable()
export class OrderEffects {
    constructor(
        private actions$: Actions,
        private ngxService: NgxUiLoaderService,
        private orderService: OrderService,
        private productOrderService: ProductOrderService
    ) {}

    loadOrderById = createEffect(
        () => () =>
            this.actions$.pipe(
                ofType(OrderActions.getOrderById),
                switchMap((action) => {
                    this.ngxService.start();
                    return this.orderService.getOrderById(action.orderId).pipe(
                        map((resp: any) => {
                            this.ngxService.stop();
                            if (resp.status == 'success') {
                                const result = new OrderDetail().deserialize(resp.data);
                                return OrderActions.getOrderByIdSuccess({ result: result });
                            } else {
                                throw new Error('order by id loaded unsuccessfully');
                            }
                        }),
                        catchError((error) => {
                            this.ngxService.stop();
                            return of(OrderActions.getOrderByIdError(error));
                        })
                    );
                })
            )
    );

    createNewBookingOrder = createEffect(
        () => () =>
            this.actions$.pipe(
                ofType(OrderActions.createNewBookingOrder),
                switchMap((action) => {
                    this.ngxService.start();
                    return this.orderService.createNewBookingOrder(action.postBody).pipe(
                        map((resp: any) => {
                            this.ngxService.stop();
                            if (resp.status == 'success') {
                                return OrderActions.createNewBookingOrderSuccess({
                                    newOrderId: parseInt(resp.data.order_id),
                                });
                            } else {
                                throw new Error();
                            }
                        }),
                        catchError((error) => {
                            return of(OrderActions.createNewBookingOrderFailure({ error }));
                        })
                    );
                })
            )
    );

    loadBookingStatMonthly = createEffect(
        () => () =>
            this.actions$.pipe(
                ofType(OrderActions.getBookingStatMonthly),
                switchMap((action) => {
                    this.ngxService.start();
                    return this.orderService.getBookingStatMonthly(action.criteria).pipe(
                        map((resp: any) => {
                            this.ngxService.stop();
                            if (resp.status == 'success') {
                                return OrderActions.getBookingStatMonthlySuccess({
                                    bookingStatMonthly: resp.data,
                                });
                            } else {
                                throw new Error();
                            }
                        }),
                        catchError((error) => {
                            return of(OrderActions.getBookingStatMonthlyError({ error }));
                        })
                    );
                })
            )
    );

    loadBookingStatMonthlyDetails = createEffect(
        () => () =>
            this.actions$.pipe(
                ofType(OrderActions.getBookingStatMonthlyDetails),
                switchMap((action) => {
                    this.ngxService.start();
                    return this.orderService.getBookingStatMonthlyDetails(action.criteria).pipe(
                        map((resp: any) => {
                            this.ngxService.stop();
                            if (resp.status == 'success') {
                                return OrderActions.getBookingStatMonthlyDetailsSuccess({
                                    bookingStatMonthlyDetails: new BookingMonthlyReport().deserialize(resp.data),
                                });
                            } else {
                                throw new Error();
                            }
                        }),
                        catchError((error) => {
                            return of(OrderActions.getBookingStatMonthlyDetailsError({ error }));
                        })
                    );
                })
            )
    );

    getBookingListPaginated = createEffect(
        () => () =>
            this.actions$.pipe(
                ofType(OrderActions.getBookingListPaginated),
                switchMap((action) => {
                    this.ngxService.start();
                    return this.orderService.getBookingListPaginated(action.queries).pipe(
                        map((resp: any) => {
                            this.ngxService.stop();
                            if (resp.status == 'success') {
                                return OrderActions.getBookingListPaginatedSuccess({
                                    bookingList: BookingList.deserialize(resp.data),
                                });
                            } else {
                                throw new Error();
                            }
                        }),
                        catchError((error) => {
                            return of(OrderActions.getBookingListPaginatedError({ error: error.toString() }));
                        })
                    );
                })
            )
    );

    getBookingStatusCount = createEffect(
        () => () =>
            this.actions$.pipe(
                ofType(OrderActions.getBookingStatusCount),
                switchMap((action) => {
                    this.ngxService.start();
                    return this.orderService.getBookingStatusCount(action.dates).pipe(
                        map((resp: any) => {
                            this.ngxService.stop();
                            if (resp.status == 'success') {
                                return OrderActions.getBookingStatusCountSuccess({
                                    bookingStatusCount: BookingStatusCount.deserialize(resp.data),
                                });
                            } else {
                                throw new Error();
                            }
                        }),
                        catchError((error) => {
                            return of(OrderActions.getBookingStatusCountError({ error: error.toString() }));
                        })
                    );
                })
            )
    );

    deleteBookingFile = createEffect(
        () => () =>
            this.actions$.pipe(
                ofType(OrderActions.deleteBookingFile),
                switchMap((action) => {
                    this.ngxService.start();
                    return this.orderService.removeBookingFile(action.bookingOrderId, action.fileId).pipe(
                        map((resp: any) => {
                            this.ngxService.stop();
                            if (resp.status == 'success') {
                                return OrderActions.deleteBookingFileSuccess({
                                    deletedBookingFile: parseInt(resp.data.order_file_id),
                                });
                            } else {
                                throw new Error();
                            }
                        }),
                        catchError((error) => {
                            return of(OrderActions.deleteBookingFileError({ error: error.toString() }));
                        })
                    );
                })
            )
    );

    modifyBookingStatus = createEffect(
        () => () =>
            this.actions$.pipe(
                ofType(OrderActions.modifyBookingStatus),
                switchMap((action) => {
                    this.ngxService.start();
                    return this.orderService.modifyBookingStatus(action.bookingOrderId, action.status).pipe(
                        map((resp: any) => {
                            this.ngxService.stop();
                            if (resp.status == 'success') {
                                return OrderActions.modifyBookingStatusSuccess({
                                    modifiedBookingId: parseInt(resp.data.order_id),
                                });
                            } else {
                                throw new Error();
                            }
                        }),
                        catchError((error) => {
                            return of(OrderActions.modifyBookingStatusError({ error: error.toString() }));
                        })
                    );
                })
            )
    );

    bookingUploadNewFile = createEffect(
        () => () =>
            this.actions$.pipe(
                ofType(OrderActions.bookingUploadFile),
                switchMap((action) => {
                    this.ngxService.start();
                    return this.orderService.bookingUploadFile(action.bookingOrderId, action.formData).pipe(
                        map((resp: any) => {
                            this.ngxService.stop();
                            if (resp.status == 'success') {
                                return OrderActions.bookingUploadFileSuccess({
                                    bookingNewFile: {
                                        bookingId: action.bookingOrderId,
                                        id: resp.data.order_file.id,
                                        url: resp.data.order_file.file_url,
                                    },
                                });
                            } else {
                                throw new Error();
                            }
                        }),
                        catchError((error) => {
                            return of(OrderActions.bookingUploadFileError({ error: error.toString() }));
                        })
                    );
                })
            )
    );

    editExistingBooking = createEffect(
        () => () =>
            this.actions$.pipe(
                ofType(OrderActions.editExistingBooking),
                switchMap((action) => {
                    this.ngxService.start();
                    return this.orderService.editExistingBooking(action.id, action.postBody).pipe(
                        map((resp: any) => {
                            this.ngxService.stop();
                            if (resp.status == 'success') {
                                return OrderActions.editExistingBookingSuccess({
                                    editedOrderId: resp.data.order_id,
                                });
                            } else {
                                throw new Error();
                            }
                        }),
                        catchError((error) => {
                            return of(OrderActions.editExistingBookingFailure({ error: error.toString() }));
                        })
                    );
                })
            )
    );

    loadProductList = createEffect(
        () => () =>
            this.actions$.pipe(
                ofType(OrderActions.getProductList),
                switchMap((action) => {
                    if (!action.hideLoadingIcon) {
                        this.ngxService.start();
                    }
                    return this.orderService.getProductList(action.owner).pipe(
                        map((resp: any) => {
                            this.ngxService.stop();
                            if (resp.status == 'success') {
                                const result = resp.data.map((x) => new ProductListOptional().deserialize(x));
                                return OrderActions.getProductListSuccess({ result: result });
                            } else {
                                throw new Error('product list loaded unsuccessfully');
                            }
                        }),
                        catchError((error) => {
                            this.ngxService.stop();
                            return of(OrderActions.getProductListError(error));
                        })
                    );
                })
            )
    );

    getOrderSummaries = createEffect(
        () => () =>
            this.actions$.pipe(
                ofType(OrderActions.getOrderSummaries),
                switchMap((action) => {
                    this.ngxService.start();
                    return this.orderService.getOrderSummaries(action.criteria).pipe(
                        map((resp: any) => {
                            this.ngxService.stop();
                            if (resp.status == 'success') {
                                return OrderActions.getOrderSummariesSuccess({
                                    orderSummaries: resp.data.map((item) => new OrderSummary().deserialize(item)),
                                });
                            } else {
                                throw new Error();
                            }
                        }),
                        catchError((error) => {
                            return of(OrderActions.getOrderSummariesFailure({ error: error.toString() }));
                        })
                    );
                })
            )
    );

    loadProductOwnerList = createEffect(
        () => () =>
            this.actions$.pipe(
                ofType(OrderActions.getProductOwnerList),
                switchMap((action) => {
                    if (!action.hideLoadingIcon) {
                        this.ngxService.start();
                    }
                    return this.orderService.getProductOwnerList().pipe(
                        map((resp: any) => {
                            this.ngxService.stop();
                            if (resp.status == 'success') {
                                const result = resp.data.map((x) => new ProductOwnerListOptional().deserialize(x));
                                return OrderActions.getProductOwnerListSuccess({ result: result });
                            } else {
                                throw new Error('product owner list loaded unsuccessfully');
                            }
                        }),
                        catchError((error) => {
                            this.ngxService.stop();
                            return of(OrderActions.getProductOwnerListError(error));
                        })
                    );
                })
            )
    );

    loadSaleReportSummary = createEffect(
        () => () =>
            this.actions$.pipe(
                ofType(OrderActions.getSaleReportSummary),
                switchMap((action) => {
                    this.ngxService.start();
                    return this.orderService.getSaleReportSummary(action.query).pipe(
                        map((resp: any) => {
                            this.ngxService.stop();
                            if (resp.status == 'success') {
                                const result = resp.data.map((x) => new SaleReportSummary().deserialize(x));
                                return OrderActions.getSaleReportSummarySuccess({ result: result });
                            } else {
                                throw new Error('sale report summary loaded unsuccessfully');
                            }
                        }),
                        catchError((error) => {
                            this.ngxService.stop();
                            return of(OrderActions.getSaleReportSummaryError(error));
                        })
                    );
                })
            )
    );

    getGroupPlanListPaginated = createEffect(
        () => () =>
            this.actions$.pipe(
                ofType(OrderActions.getGroupPlanListPaginated),
                switchMap((action) => {
                    this.ngxService.start();
                    return this.orderService.getGroupPlanListPaginated(action.groupPlanListQueries).pipe(
                        map((resp: any) => {
                            this.ngxService.stop();
                            if (resp.status == 'success') {
                                return OrderActions.getGroupPlanListPaginatedSuccess({
                                    groupPlanList: new GroupPlanList().deserialize(resp),
                                    isNextPage: action.isNextPage,
                                });
                            } else {
                                throw new Error();
                            }
                        }),
                        catchError((error) => {
                            return of(OrderActions.getGroupPlanListPaginatedError({ error: error.toString() }));
                        })
                    );
                })
            )
    );

    getOrderCommissonSummaries = createEffect(
        () => () =>
            this.actions$.pipe(
                ofType(OrderActions.getOrderCommissionSummaries),
                switchMap((action) => {
                    this.ngxService.start();
                    return this.orderService.getOrderCommissionSummaries(action.criteria).pipe(
                        map((resp: any) => {
                            this.ngxService.stop();
                            if (resp.status == 'success') {
                                return OrderActions.getOrderCommissionSummariesSuccess({
                                    orderCommissionSummaries: resp.data.map((item) =>
                                        new OrderCommissionSummary().deserialize(item)
                                    ),
                                });
                            } else {
                                throw new Error();
                            }
                        }),
                        catchError((error) => {
                            return of(OrderActions.getOrderCommissionSummariesFailure({ error: error.toString() }));
                        })
                    );
                })
            )
    );

    loadSaleReportListPaginated = createEffect(
        () => () =>
            this.actions$.pipe(
                ofType(OrderActions.getSaleReportListPaginated),
                switchMap((action) => {
                    this.ngxService.start();
                    return this.orderService.getSaleReportListPaginated(action.query).pipe(
                        map((resp: any) => {
                            this.ngxService.stop();
                            if (resp.status == 'success') {
                                const result = new SaleReportListPaginated().deserialize(resp.data);
                                return OrderActions.getSaleReportListPaginatedSuccess({ result: result });
                            } else {
                                throw new Error('sale report list paginated loaded unsuccessfully');
                            }
                        }),
                        catchError((error) => {
                            this.ngxService.stop();
                            return of(OrderActions.getSaleReportListPaginatedError(error));
                        })
                    );
                })
            )
    );

    getCommissionReportListPaginated = createEffect(
        () => () =>
            this.actions$.pipe(
                ofType(OrderActions.getCommissionReportListPaginated),
                switchMap((action) => {
                    this.ngxService.start();
                    return this.orderService.getCommissionPortListPaginated(action.commissionReportListQueries).pipe(
                        map((resp: any) => {
                            this.ngxService.stop();
                            if (resp.status == 'success') {
                                return OrderActions.getCommissionReportListPaginatedSuccess({
                                    commissionReportList: new CommistionReportList().deserialize(resp),
                                    isNextPage: action.isNextPage,
                                });
                            } else {
                                throw new Error();
                            }
                        }),
                        catchError((error) => {
                            return of(OrderActions.getCommissionReportListPaginatedError({ error: error.toString() }));
                        })
                    );
                })
            )
    );

    getCampaignPaymentPaginated = createEffect(
        () => () =>
            this.actions$.pipe(
                ofType(OrderActions.getCampaignPaymentPaginated),
                switchMap((action) => {
                    this.ngxService.start();
                    return this.orderService.getCampaignPaymentPaginated(action.filter).pipe(
                        map((resp: any) => {
                            this.ngxService.stop();
                            if (resp.status == 'success') {
                                return OrderActions.getCampaignPaymentPaginatedSuccess({
                                    paginated: new CampaignPaymentPaginated().deserialize(resp),
                                });
                            } else {
                                throw new Error();
                            }
                        }),
                        catchError((error) => {
                            return of(OrderActions.getCampaignPaymentPaginatedError({ error: error.toString() }));
                        })
                    );
                })
            )
    );

    getCampaignPaymentCount = createEffect(
        () => () =>
            this.actions$.pipe(
                ofType(OrderActions.getCampaignPaymentCount),
                switchMap((action) => {
                    this.ngxService.start();
                    return this.orderService.getCampaignPaymentCount().pipe(
                        map((resp: any) => {
                            this.ngxService.stop();
                            if (resp.status == 'success') {
                                return OrderActions.getCampaignPaymentCountSuccess({
                                    all: resp.data.all,
                                    available: resp.data.has_usable_voucher_tickets,
                                });
                            } else {
                                throw new Error();
                            }
                        }),
                        catchError((error) => {
                            return of(OrderActions.getCampaignPaymentCountError({ error: error.toString() }));
                        })
                    );
                })
            )
    );

    getCountProductSubCategory = createEffect(
        () => () =>
            this.actions$.pipe(
                ofType(OrderActions.getCountProductSubCategory),
                switchMap((action) => {
                    this.ngxService.start();
                    return this.productOrderService.getCountProductSubCategory(action.searchString).pipe(
                        map((resp: any) => {
                            this.ngxService.stop();
                            if (resp.status == 'success') {
                                return OrderActions.getCountProductSubCategorySuccess({
                                    model: new CountProductSubCategory().deserialize(resp.data),
                                });
                            } else {
                                throw new Error();
                            }
                        }),
                        catchError((error) => {
                            return of(OrderActions.getCountProductSubCategoryError({ error: error.toString() }));
                        })
                    );
                })
            )
    );

    getCountOrderStatus = createEffect(
        () => () =>
            this.actions$.pipe(
                ofType(OrderActions.getCountOrderStatus),
                switchMap((action) => {
                    this.ngxService.start();
                    return this.productOrderService
                        .getCountOrderStatus(action.productSubCategorySlug, action.searchString)
                        .pipe(
                            map((resp: any) => {
                                this.ngxService.stop();
                                if (resp.status == 'success') {
                                    return OrderActions.getCountOrderStatusSuccess({
                                        model: new CountOrderStatus().deserialize(resp.data),
                                    });
                                } else {
                                    throw new Error();
                                }
                            }),
                            catchError((error) => {
                                return of(OrderActions.getCountOrderStatusError({ error: error.toString() }));
                            })
                        );
                })
            )
    );

    getOrderListPaginated = createEffect(
        () => () =>
            this.actions$.pipe(
                ofType(OrderActions.getProductOrderListPaginated),
                switchMap((action) => {
                    this.ngxService.start();
                    return this.productOrderService
                        .getProductOrderListPaginated(
                            action.orderStatusSlug,
                            action.page,
                            action.pageEntries,
                            action.productSubCategorySlug,
                            action.searchString,
                            action.sortBy
                        )
                        .pipe(
                            map((resp: any) => {
                                this.ngxService.stop();
                                if (resp.status == 'success') {
                                    return OrderActions.getProductOrderListPaginatedSuccess({
                                        model: new ProductOrderListPaginated().deserialize(resp.data),
                                    });
                                } else {
                                    throw new Error();
                                }
                            }),
                            catchError((error) => {
                                return of(OrderActions.getProductOrderListPaginatedError({ error: error.toString() }));
                            })
                        );
                })
            )
    );
}
