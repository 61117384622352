import * as moment from 'moment';

export class OrderBalance {
    notTransfered: Order;
    transfered: Order[];

    public deserialize(source: any) {
        this.notTransfered = source.not_transfered ? new Order().deserialize(source.not_transfered) : null;
        this.transfered = source.transfered.map((m) => new Order().deserialize(m));
        return this;
    }
}

export class Order {
    id: number;
    orderFirstDate: Date;
    orderLastDate: Date;
    transferDueDate: Date;
    moneyComming: string;
    receiptItemQuantity: number;
    sumAmount: number;
    sumNetCommissionAgency: number;
    bankAccount: BankAccount;
    transferDocFileUrl: string;
    confirmed: boolean;
    confirmedAt: string;
    confirmedByAgencyMember: ConfirmedByAgencyMember;

    public deserialize(source: any) {
        this.id = source.id;
        this.orderFirstDate = new Date(source.order_first_date);
        this.orderLastDate = new Date(source.order_last_date);
        this.transferDueDate = new Date(source.transfer_due_date);
        this.transferDueDate = moment(this.transferDueDate).toDate();

        if (moment(this.transferDueDate).isBefore(moment()) || moment(this.transferDueDate).isSame(moment())) {
            this.moneyComming = 'เงินเข้าเร็ว ๆ นี้';
        } else {
            const duration = moment.duration(moment(this.transferDueDate).diff(moment()));
            const days = Math.ceil(duration.asDays());

            this.moneyComming = `เงินเข้าในอีก ${days} วัน`;
        }

        this.receiptItemQuantity = source.receipt_item_quantity;
        this.sumAmount = source.sum_amount;
        this.sumNetCommissionAgency = source.sum_net_commission_agency;
        this.bankAccount = new BankAccount().deserialize(source.bank_account);
        this.transferDocFileUrl = source.transfer_doc_file_url;
        this.confirmed = source.confirmed;
        this.confirmedAt = source.confirmed_at;
        this.confirmedByAgencyMember = source.confirmedByAgencyMember
            ? new ConfirmedByAgencyMember().deserialize(source.confirmedByAgencyMember)
            : null;
        return this;
    }
}

export class BankAccount {
    id: number;
    branch: string;
    accountName: string;
    accountNumber: string;
    accountTypeId: number;
    bank: Bank;

    public deserialize(source: any) {
        this.id = source.id;
        this.branch = source.branch;
        this.accountName = source.account_name;
        this.accountNumber = source.account_number;
        this.bank = new Bank().deserialize(source.bank);

        return this;
    }
}

export class Bank {
    id: number;
    name: string;

    public deserialize(source: any) {
        this.id = source.id;
        this.name = source.name;
        return this;
    }
}

export class ConfirmedByAgencyMember {
    id: number;
    firstName: string;
    lastName: string;

    public deserialize(source: any) {
        this.id = source.id;
        this.firstName = source.first_name;
        this.lastName = source.last_name;
        return this;
    }
}
