<span class="searchable-dropdown d-flex flex-column" [class.unclickable]="unclickable">
    <div
        #mainInput
        class="searchable-dropdown-view d-flex align-items-center p-2"
        [ngStyle]="{ height: settings?.styleSetting.height || undefined }"
        [ngClass]="{ disabled: disabled, 'border-red': isBorderRed }"
        [class.two-rows]="settings?.have2Rows"
        (click)="openDropdown()"
    >
        <div class="searchable-dropdown-view-items d-inline-flex mr-2">
            <span
                *ngIf="!(selectedItems$ | async)?.length"
                [ngStyle]="{ 'font-size': settings?.styleSetting.fontSize || undefined }"
                class="searchable-dropdown-view-placeholder"
                >{{ settings?.dropdownListPlaceholder }}</span
            >
            <span
                *ngIf="(selectedItems$ | async)?.length"
                [ngClass]="settings.have2Rows ? 'd-inline-block w-100' : 'd-inline-flex'"
            >
                <ng-template ngFor let-item [ngForOf]="selectedItems$ | async">
                    <span
                        *ngIf="settings.multipleSelection; else valueAsPlainText"
                        class="searchable-dropdown-view-selected pl-1 pr-1 ml-1 mr-1 d-inline-flex align-items-center"
                    >
                        <span class="text-truncate">{{ item.name }}</span>
                        <i class="icon icon-close-circle ml-2 cursor-pointer" (click)="removeItem(item, $event)"></i>
                    </span>
                    <ng-template #valueAsPlainText>
                        <span
                            class="searchable-dropdown-view-selected-plain-text"
                            [ngClass]="{ disabled: disabled }"
                            [ngStyle]="{ 'font-size': settings?.styleSetting.fontSize || undefined }"
                            >{{ item.name }}</span
                        >
                    </ng-template>
                </ng-template>
            </span>
        </div>
        <i class="icon icon-arrow-down ml-auto cursor-pointer"></i>
        <p class="overflow-counter" *ngIf="settings?.have2Rows && countOverflow() > 0">+{{ countOverflow() }}</p>
    </div>
    <div
        class="searchable-dropdown-selection py-2"
        [hidden]="!isExpand"
        [ngStyle]="{ 'width.px': mainInput.offsetWidth }"
    >
        <div class="searchable-dropdown-selection-input p-1 d-flex align-items-center" *ngIf="!settings?.hideSearchBox">
            <i class="icon mr-2 {{ settings?.customSearchIcon }}"></i>
            <span class="w-100 d-inline-flex">
                <input
                    class="w-100"
                    type="text"
                    [(ngModel)]="filterText"
                    [placeholder]="settings?.searchBoxPlaceholder"
                    (keyup)="filterItems($event.target.value)"
                />
            </span>
        </div>
        <div
            class="searchable-dropdown-selection-items"
            [class.underline-first]="settings?.underlineAfterFirstItem"
            [class.is-x-scrollable]="settings?.isXScrollable"
        >
            <div [hidden]="isFiltering">
                <div
                    class="d-flex align-items-center searchable-dropdown-list-items-view cursor-pointer"
                    *ngIf="settings?.dropdownListAllItemsLabel"
                    (click)="selectAllItems(!isAllChecked)"
                >
                    <div class="custom-checkbox" *ngIf="settings.multipleSelection">
                        <input type="checkbox" class="custom-control-input" [checked]="isAllChecked" />
                        <label class="custom-control-label"></label>
                    </div>
                    <span class="ml-1" [class.text-truncate]="!settings.isXScrollable">{{
                        settings.dropdownListAllItemsLabel
                    }}</span>
                </div>
                <ng-container *ngIf="items$ | async as items">
                    <ng-container *ngIf="items.length > 0; else noItemsLabel">
                        <ng-container *ngFor="let item of items">
                            <div
                                class="d-flex align-items-center searchable-dropdown-list-items-view cursor-pointer"
                                *ngIf="!item.preselectedFixed"
                                [class.checked]="settings.unableToUncheck && item.isChecked"
                                (click)="selectItem(item)"
                            >
                                <div class="custom-checkbox" *ngIf="settings.multipleSelection">
                                    <input type="checkbox" class="custom-control-input" [checked]="item.isChecked" />
                                    <label class="custom-control-label"></label>
                                </div>
                                <img *ngIf="item.imageUrl" [src]="item.imageUrl" class="ml-1" />
                                <span class="ml-1" [class.text-truncate]="!settings.isXScrollable">
                                    {{ item.name }}
                                </span>
                            </div>
                        </ng-container>
                    </ng-container>
                    <ng-template #noItemsLabel>
                        <div class="d-flex align-items-center searchable-dropdown-list-items-view">ไม่พบข้อมูล</div>
                    </ng-template>
                </ng-container>
            </div>
            <div [hidden]="!isFiltering">Loading...</div>
        </div>
    </div>
</span>
