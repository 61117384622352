<p>example-input works!</p>
<div class="form-group">
    <label for="file">Choose File</label><br>
    <input type="file"
           id="file"
           (change)="onSelectFileUpload($event.target.files)">

    Uploading: {{imageUploadingPercent}}%
</div>

<app-calendar-input></app-calendar-input>
<app-rich-text-input></app-rich-text-input>
<app-searchable-dropdown 
    [settings]="dropdownSettings" 
    [items]="dropdownItems"
    (selected)="selectDropdown($event)">
</app-searchable-dropdown>
