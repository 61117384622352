export class SeoArticleOptionItem {
    id: number;
    code: string;
    name: string;

    public deserialize(data: any): this {
        this.id = data.id;
        this.code = data.code;
        this.name = data.name;
        return this;
    }
}
