import { ICustomerOrderInstallment } from '../interfaces/tw-booking-interface/customer-order-installment.interface';
import { IPaymentEvent } from '../interfaces/tw-booking-interface/payment-event.interface';
import { InstallmentFile } from './Installment-file.model';

export class CustomerOrderInstallment {
    id: number;
    ordinal: number;
    amount: number;
    status: string;
    dueDate: Date;
    paidAt: Date;
    paymentChannel: string;
    paymentMethod: string;
    files: InstallmentFile[];
    filesForDisplay!: InstallmentFile[];
    twPaymentEvents: IPaymentEvent[];
    lastTwPayment: {
        id: number;
        channel: string;
        method: string;
        status: string;
    };

    deserialize(data: ICustomerOrderInstallment): CustomerOrderInstallment {
        this.id = data.id;
        this.ordinal = data.ordinal;
        this.amount = data.amount;
        this.status = data.status;
        this.dueDate = new Date(data.due_date);
        this.paidAt = new Date(data.paid_at);
        this.paymentChannel = data.payment_channel;
        this.paymentMethod = data.payment_method;
        this.files = data.files.map((file) => new InstallmentFile().deserialize(file, data.id, true));
        this.filesForDisplay = [];
        this.twPaymentEvents = data.tourwow_payment_events;
        this.lastTwPayment = data.last_tw_payment
            ? {
                  id: data.last_tw_payment.id,
                  channel: data.last_tw_payment.payment_channel,
                  method: data.last_tw_payment.payment_method,
                  status: data.last_tw_payment.payment_status,
              }
            : null;

        return this;
    }
}
