export const CAMPAIGN_TYPES = {
    food: 'อาหารและเครื่องดื่ม',
    hotel: 'โรงแรมและที่พัก',
    spa: 'สปา',
    cruise: 'ล่องเรือ',
    activity: 'กิจกรรม',
    'car-rent': 'รถเช่า',
    cafe: 'คาเฟ่',
    beauty: 'ความสวยความงาม',
    'travel-package': 'แพคเกจท่องเที่ยว',
};
