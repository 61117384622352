<div class="lead-list-component">
    <app-breadcrumb [items]="breadcrumb"></app-breadcrumb>

    <h2 class="text-primary mb-4"><i class="icon icon-report-booking"></i>รายงานสรุปข้อมูลใบจอง</h2>
    <div class="filter-area">
        <!-- <div class="toggle-filter-link">
            <a [routerLink]="" (click)="toggleFilter()">กดค้นหาข้อมูลเพิ่มเติม</a>
            <i class="icon" [ngClass]="showFilter ? 'icon-arrow-up' : 'icon-arrow-down'"></i>
        </div> -->
        <app-search-filter [class.d-none]="!showFilter" [activeList]="'LEAD_BOOKING_SUMMARY'"> </app-search-filter>
    </div>
    <div class="listing-area">
        <app-lead-summary-listing></app-lead-summary-listing>
    </div>
</div>
