import { Owner } from './../../../models/owner';
export interface ILeadDetails {
    customerDetails: {
        id: number;
        contact_name: string;
        phone_number: string;
        email: string;
        more_info: string;
        remark: string;
    };
    number_of_passengers: number;
    price: string;
    created_at: string;
    lead_status_slug: string;
    lead_type_slug: string;
    start_period: string;
    end_period: string;
    sales_person?: string;
    agency_code?: string;
    agency_name_th?: string;
    agency_name_en?: string;
    wholesale_slug?: string;
    wholesale_open_onlinebooking?: boolean;
    tour_code?: string;
    pool_code?: string;
    product_name?: string;
    owner?: {
        id: number;
        name_en: string;
        name_th: string;
        phone_number: string;
        addition_phone_number: string;
        email: string;
        address: string;
        license_number: string;
    };
    owner_channel: 'ag' | 'ws';
    product_period_id: number;
    more_info_for_ws?: string;
    commissionLabel: string;
    commissionString: string;
}
export type StatusTypes = 'รอติดต่อกลับ' | 'รอตัดสินใจ' | 'ส่งใบจอง' | 'จองแล้ว' | 'ยกเลิก';

export class LeadDetails {
    private leadDetails: ILeadDetails[] = [];
    private startDate: string;
    private endDate: string;

    public deserialize(objList: any): ILeadDetails[] {
        objList.forEach((obj: any) => {
            this.leadDetails.push(this.deserializeCurrentLead(obj));
        });
        return this.leadDetails;
    }

    private deserializeCurrentLead(obj: any): ILeadDetails {
        this.getPeriodDate(obj.product_detail_period_id, obj);
        const sales_person = this.getSalesPersonName(obj.updated_by_agency_member);

        const currentLead: ILeadDetails = {
            customerDetails: {
                id: obj.id,
                contact_name: obj.contact_name,
                phone_number: obj.phone_number,
                email: obj.email,
                more_info: obj.more_info,
                remark: obj.remark,
            },
            number_of_passengers: obj.number_of_passengers,
            price: obj.price,
            created_at: obj.created_at,
            lead_status_slug: obj.lead_status_slug,
            lead_type_slug: obj.lead_type_slug,
            start_period: this.startDate,
            end_period: this.endDate,
            agency_code: obj.owner_agency_product_code,
            agency_name_th: obj.owner_agency_name_th,
            agency_name_en: obj.owner_agency_name_en,
            wholesale_slug: obj.owner_wholesale_slug,
            wholesale_open_onlinebooking: !!obj.owner_wholesale_is_channel_ob,
            tour_code: obj.product_detail ? obj.product_detail.tour_code : null,
            pool_code: obj.product_detail
                ? obj.product_detail.owner_channel + obj.product_detail.category_sub_products_id + obj.product_detail.id
                : null,
            product_name: obj.product_detail ? obj.product_detail.name : null,
            owner: obj.product_detail ? obj.product_detail.owner : null,
            owner_channel: obj.product_detail ? obj.product_detail.owner_channel : null,
            sales_person,
            product_period_id: obj.product_detail_period_id,
            more_info_for_ws: obj.more_info_for_ws,
            commissionLabel: this.getCommissionLabel(obj),
            commissionString: this.getCommission(obj),
        };

        return currentLead;
    }

    private getPeriodDate(id: number, obj: any): void {
        let startDate: string = null;
        let endDate: string = null;

        if (id && obj.product_detail) {
            const periods: any = obj.product_detail.periods;
            periods.forEach((period: any) => {
                if (period.id === id) {
                    startDate = period.start_at;
                    endDate = period.end_at;
                }
            });
        }
        this.startDate = startDate;
        this.endDate = endDate;
    }

    private getCommissionLabel(obj: any): string {
        const hasCommissionCompany: boolean = obj.product_detail && obj.product_detail.commission_company;
        const hasCommissionSeller: boolean = obj.product_detail && obj.product_detail.commission_seller;

        let label = 'คอม';

        if (hasCommissionCompany && !hasCommissionSeller) {
            label += 'บริษัท';
        } else if (!hasCommissionCompany && hasCommissionSeller) {
            label += 'เซลล์';
        }

        return label;
    }

    private getCommission(obj: any): string {
        const commissionCompany: string =
            obj.product_detail && obj.product_detail.commission_company
                ? Math.round(obj.product_detail.commission_company)
                      .toString()
                      .replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                : null;
        const commissionSeller: string =
            obj.product_detail && obj.product_detail.commission_seller
                ? Math.round(obj.product_detail.commission_seller)
                      .toString()
                      .replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                : null;

        let commission = '';

        if (commissionCompany) {
            commission += commissionCompany;
        }
        if (commissionCompany && commissionSeller) {
            commission += ' + ';
        }
        if (commissionSeller) {
            commission += commissionSeller;
        }

        if (commission) {
            return commission;
        }
        return null;
    }

    private getSalesPersonName(obj: any) {
        let name = '';

        if (obj) {
            name = obj.first_name
                ? name + obj.first_name + ' '
                : name + obj.last_name
                ? name + obj.last_name + ' '
                : name;
            +obj.nick_name ? name + '(' + obj.nick_name + ')' : name;
        }

        return name;
    }
}
