<div class="tw-booking-list" *ngIf="twBooking">
    <div class="header">
        <app-breadcrumb [items]="breadcrumb"></app-breadcrumb>
        <div class="d-flex justify-content-between">
            <h2 class="text-primary mb-4"><i class="icon icon-status-orange"></i>ข้อมูลการจองทัวร์</h2>
        </div>
    </div>
    <div class="search-box">
        <app-search-tw-booking-box
            (search)="onFilterChange($event)"
            [defaultFilters]="filters"
        ></app-search-tw-booking-box>
    </div>
    <div class="sort-dropdown">
        <a class="btn btn-default mr-2" target="_blank" href="{{ financeBackofficeUrl }}sale-support/work-list">
            <img width="15px" src="/assets/img/icon/icon-bell-active.svg" />
            สิ่งที่ต้องทำ</a
        >
        <div class="btn-group" dropdown>
            <button
                dropdownToggle
                type="button"
                class="btn btn-secondary dropdown-btn dropdown-toggle"
                aria-controls="dropdown-basic"
            >
                {{ sortSelected.label }}<span class="caret"></span>
            </button>
            <ul *dropdownMenu class="dropdown-menu" role="menu" aria-labelledby="button-basic">
                <li
                    *ngFor="let item of dropdownSortList"
                    role="menuitem"
                    class="dropdown-item"
                    [ngClass]="{ active: sortSelected === item }"
                    value="item.value"
                    (click)="onSelectSort(item)"
                >
                    {{ item.label }}
                </li>
            </ul>
        </div>
    </div>
    <div class="order-result">
        <app-tw-booking-list-result
            [twBooking]="twBooking"
            (changePinEmitter)="onChangePin($event)"
            (changeOrderStatusEmitter)="onChangeOrderStatus($event)"
            (changeNoteEmitter)="onChangeNote($event)"
            (reload)="reload()"
        ></app-tw-booking-list-result>
    </div>
    <div class="text-center show-more-results" *ngIf="twBooking?.result?.length < twBooking?.total">
        <button class="btn btn-show-more" (click)="loadMore()">
            แสดงรายการที่ {{ twBooking?.result?.length + 1 }} -
            {{
                twBooking?.result?.length + twBooking?.pageEntries < twBooking.total
                    ? twBooking?.result?.length + twBooking?.pageEntries
                    : twBooking.total
            }}
        </button>
    </div>
</div>
