import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { CampaignHilight } from 'src/app/models/campaigns/campaign-hilight.model';
import { ChaophrayaCruiseBookingView } from 'src/app/models/chaophraya-cruise-booking.model';
import { Campaign, CampaignPaginated } from 'src/app/modules/product/models/campaign-model';
import { CampaignService } from 'src/app/services/campaign.service';
import { ChaophrayaCruiseBookingService } from 'src/app/services/chaophraya-cruise-booking.service';
import * as CampaignActions from './campaign.actions';

@Injectable()
export class CampaignEffects {
    constructor(
        private actions$: Actions,
        private ngxService: NgxUiLoaderService,
        private campaignService: CampaignService,
        private chaophrayaCruiseBookingService: ChaophrayaCruiseBookingService
    ) {}

    loadCampaigns = createEffect(() => () =>
        this.actions$.pipe(
            ofType(CampaignActions.loadCampaigns),
            switchMap(() => {
                this.ngxService.start();
                return this.campaignService.getCampaigns().pipe(
                    map((resp) => {
                        this.ngxService.stop();
                        if (resp.status !== 'success') {
                            return CampaignActions.loadCampaignsError();
                        }
                        return CampaignActions.loadCampaignsSuccess({
                            campaigns: resp.data.map((d) => new Campaign().deserialize(d)),
                        });
                    }),
                    catchError((error) => {
                        this.ngxService.stop();
                        return of(CampaignActions.loadCampaignsError());
                    })
                );
            })
        )
    );

    loadCampaignsPaginated = createEffect(() => () =>
        this.actions$.pipe(
            ofType(CampaignActions.loadCampaignPaginated),
            switchMap((action) => {
                this.ngxService.start();
                return this.campaignService
                    .getCampaignPaginated(action.currentPage, action.itemPerPage, action.filter)
                    .pipe(
                        map((resp) => {
                            this.ngxService.stop();
                            if (resp.status !== 'success') {
                                return CampaignActions.loadCampaignPaginatedError();
                            }
                            return CampaignActions.loadCampaignPaginatedSuccess({
                                campaignPaginated: new CampaignPaginated().deserialize(resp.data),
                            });
                        }),
                        catchError((error) => {
                            this.ngxService.stop();
                            return of(CampaignActions.loadCampaignPaginatedError());
                        })
                    );
            })
        )
    );

    sendEmailContact = createEffect(() => () =>
        this.actions$.pipe(
            ofType(CampaignActions.sendEmailCampaignToContact),
            switchMap((action) => {
                this.ngxService.start();
                return this.campaignService.sendEmailContact(action.paymentId).pipe(
                    map((resp) => {
                        this.ngxService.stop();
                        if (resp.status === 'success') {
                            return CampaignActions.sendEmailCampaignToContactSuccess();
                        }
                    })
                );
            })
        )
    );

    loadCampaignHilight = createEffect(() => () =>
        this.actions$.pipe(
            ofType(CampaignActions.loadCampaignHilight),
            switchMap((action) => {
                this.ngxService.start();
                return this.campaignService.getCampaignHilight(action.campaignId).pipe(
                    map((resp) => {
                        this.ngxService.stop();
                        if (resp.status !== 'success') {
                            return CampaignActions.loadCampaignHilightError();
                        }
                        return CampaignActions.loadCampaignHilightSuccess({
                            hilight: new CampaignHilight().deserialize(resp.data.campaign),
                        });
                    }),
                    catchError((error) => {
                        this.ngxService.stop();
                        return of(CampaignActions.loadCampaignHilightError());
                    })
                );
            })
        )
    );

    postChaophrayaCruiseBooking = createEffect(() => () =>
        this.actions$.pipe(
            ofType(CampaignActions.postChaophrayaCruiseBooking),
            switchMap((action) => {
                this.ngxService.start();
                return this.chaophrayaCruiseBookingService.post(action.model).pipe(
                    map((resp) => {
                        this.ngxService.stop();
                        if (resp.status === 'success') {
                            return CampaignActions.postChaophrayaCruiseBookingSuccess();
                        }
                    })
                );
            })
        )
    );

    loadChaophrayaCruiseBooking = createEffect(() => () =>
        this.actions$.pipe(
            ofType(CampaignActions.loadChaophrayaCruiseBooking),
            switchMap((action) => {
                this.ngxService.start();
                return this.chaophrayaCruiseBookingService.get(action.bookingId).pipe(
                    map((resp) => {
                        this.ngxService.stop();
                        if (resp.status !== 'success') {
                            return CampaignActions.loadChaophrayaCruiseBookingError();
                        }
                        return CampaignActions.loadChaophrayaCruiseBookingSuccess({
                            model: new ChaophrayaCruiseBookingView().deserialize(resp.data),
                        });
                    }),
                    catchError((error) => {
                        this.ngxService.stop();
                        return of(CampaignActions.loadChaophrayaCruiseBookingError());
                    })
                );
            })
        )
    );
}
