import { Component, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Subject } from 'rxjs';
import { DateHelper } from 'src/app/helpers/date.helper';
import { IUpdateWholesaleInstallment } from 'src/app/models/interfaces/tourwow-order/update-wholesale-installment.interface';

// Models
import { RoomType } from 'src/app/models/room-type.model';
import { TourwowOrderService } from 'src/app/services/tourwow-order.service';
import { InstallmentList } from '../../models/installment.model';

enum MODE {
    ADD = 'add',
    EDIT = 'edit',
    VIEW = 'view',
}

@Component({
    selector: 'app-wholesale-payment-detail-modal',
    templateUrl: './wholesale-payment-detail-modal.component.html',
    styleUrls: ['./wholesale-payment-detail-modal.component.scss'],
})
export class WholesalePaymentDetailModalComponent implements OnInit {
    MODE = MODE;
    mode: MODE = MODE.ADD;
    roomTypes: RoomType[];
    sku = {
        adultSingleBed: 0,
        adultDoubleBed: 0,
        adultTripleBed: 0,
        childWithBed: 0,
        childNoBed: 0,
        infant: 0,
        joinLand: 0,
    };
    totalPrice: number;
    allSKUQuantity: number;
    companyCommission: number = null;
    saleCommission: number = null;
    installmentSelector: number;
    installmentList: InstallmentList;
    deposit: number = null;
    orderCode: string;

    updateInstallmentList = new Subject<InstallmentList>();
    btnAction: string;

    constructor(public bsModalRef: BsModalRef, private tourwowOrder: TourwowOrderService) {}

    ngOnInit(): void {
        this.installmentList.setInstallmentAmount(this.installmentSelector);
        this.installmentList.totalRealPaid =
            this.totalPrice - this.companyCommission * this.allSKUQuantity - this.saleCommission * this.allSKUQuantity;

        if (this.deposit && this.mode !== MODE.VIEW) {
            this.installmentList.resetFirstInstallmentWithDeposit(this.deposit);
        }
    }

    get adultSingleBed(): RoomType {
        return this.getRoomType('adult_single');
    }

    get adultDoubleBed(): RoomType {
        return this.getRoomType('adult_double');
    }

    get adultTripleBed(): RoomType {
        return this.getRoomType('adult_triple');
    }

    get childWithBed(): RoomType {
        return this.getRoomType('child_bed');
    }

    get childNoBed(): RoomType {
        return this.getRoomType('child_no_bed');
    }

    get infant(): RoomType {
        return this.getRoomType('infant');
    }

    get joinLand(): RoomType {
        return this.getRoomType('join_land');
    }

    private getRoomType(slug: string): RoomType {
        if (!this.roomTypes) {
            return null;
        }

        const room = this.roomTypes.find((room) => room.slug === slug);
        if (!room) {
            return null;
        }

        if (room.price === null) {
            return null;
        }

        return room;
    }

    private updateWholesaleInstallment(): void {
        let wholesaleInstallment = [];
        wholesaleInstallment = this.installmentList.installments
            .filter((i) => i.hide === false)
            .map((i) => {
                return {
                    ordinal: i.ordinal,
                    due_date: DateHelper.convertDateToHttpPlayload(i.date),
                    amount: i.price,
                };
            });

        const payload: IUpdateWholesaleInstallment = {
            supplier_order_installments: wholesaleInstallment,
            sum_supplier_order_installment_amount: this.installmentList.totalRealPaid,
        };

        this.tourwowOrder.updateOrderWholesaleInstallment(this.orderCode, payload).subscribe((response) => {
            if (response) {
                this.updateInstallmentList.next(this.installmentList);
            }

            this.bsModalRef.hide();
        });
    }

    // Events Listenner
    calculateInstallments(): void {
        this.installmentList.reCalculateInstallment();
    }

    onChangeInstallment(): void {
        this.installmentList.setInstallmentAmount(this.installmentSelector);
    }

    onClickCloseButton(): void {
        this.updateInstallmentList.next(this.installmentList);
        this.bsModalRef.hide();
    }

    onClickUpdateButton(): void {
        this.btnAction = 'submit';
        this.updateWholesaleInstallment();
    }

    onClickCancelButton(): void {
        this.btnAction = 'cancel';
        this.bsModalRef.hide();
    }
}
