export const BOOKING_STATUS_CODES = {
    WAITING_FOR_CONFIRMATION: 11,
    WAITING_FOR_FIRST_PAYMENT: 21,
    WAITING_FOR_FULL_PAYMENT: 22,
    COMPLETE: 31,
    CANCEL: 41,
};

export const BOOKING_STATUS_KEYWORDS = {
    WAITING_FOR_FIRST_PAYMENT: 'รอชำระเงินงวดแรก',
    WAITING_FOR_FULL_PAYMENT: 'รอชำระเงินครบ',
    WAITING_TO_TRAVEL: 'รอเดินทาง',
    TRAVELLED: 'เดินทางแล้ว',
    CANCELLED: 'ยกเลิก',
};
