import { IBookingOrderInstallment } from 'src/app/modules/order/models/add-booking-b2c.model';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { DateHelper } from 'src/app/helpers/date.helper';

@Component({
    selector: 'app-booking-order-installments',
    templateUrl: './booking-order-installments.component.html',
    styleUrls: ['./booking-order-installments.component.scss'],
})
export class BookingOrderInstallmentsComponent implements OnInit {
    @Input() isEdit: boolean = false;
    @Input() public bookingOrderInstallments: IBookingOrderInstallment[];
    @Input() public validFields: { paymentDuedateAt: boolean; totalPaid: boolean } = {
        paymentDuedateAt: true,
        totalPaid: true,
    };
    @Output() private bookingOrderInstallmentsEmitter = new EventEmitter<IBookingOrderInstallment[]>();

    public fieldDate: Date[] = [];

    constructor() {}

    ngOnInit(): void {
        if (!this.bookingOrderInstallments) {
            if (!this.isEdit) {
                this.bookingOrderInstallments = [
                    {
                        payment_duedate_at: null,
                        total_paid: null,
                    },
                ];
            } else {
                this.bookingOrderInstallments = [
                    {
                        id: null,
                        payment_duedate_at: null,
                        total_paid: null,
                        to_update: true,
                    },
                ];
            }
            this.fieldDate = [null];
            this.emitToParent();
        } else {
            this.bookingOrderInstallments.map((installment: IBookingOrderInstallment) => {
                this.fieldDate.push(DateHelper.convertStringToDate(installment.payment_duedate_at));
            });
        }
    }

    public setDate(index: number, date: Date) {
        if (date) {
            this.fieldDate[index] = date;
            this.bookingOrderInstallments[index].payment_duedate_at = DateHelper.getYMDString(date);
            if (this.isEdit) {
                this.bookingOrderInstallments[index].to_update = true;
            }
        }

        this.emitToParent();
    }

    public addItem(): void {
        if (!this.isEdit) {
            this.bookingOrderInstallments.push({
                payment_duedate_at: null,
                total_paid: null,
            });
        } else {
            this.bookingOrderInstallments.push({
                id: null,
                payment_duedate_at: null,
                total_paid: null,
                to_update: true,
            });
        }
        this.fieldDate.push(null);

        this.emitToParent();
    }

    public removeItem(index: number): void {
        this.bookingOrderInstallments.splice(index, 1);
        this.fieldDate.splice(index, 1);

        this.emitToParent();
    }

    public emitToParent(): void {
        this.bookingOrderInstallmentsEmitter.emit(this.bookingOrderInstallments);
    }

    public calculateGrandTotal(): number {
        let total = 0;

        if (this.bookingOrderInstallments) {
            this.bookingOrderInstallments.map((item: IBookingOrderInstallment) => {
                total += Number(item.total_paid);
            });
        }

        return total;
    }
}
