import { ProductList } from 'src/app/models/product-list';

export interface ILeadBookingRequestDetails {
    id: number;
    bookingNumberCode: string;
    leadStatusSlug: string;
    agencyProductCode: string;
    numberOfPassengers: string;
    productDetail: ProductList;
    productDetailPeriodId: number;
    leadCreatedByAgency: {
        id: number;
        nameEn: string;
    };
    createdAt: string;
    startDate: string;
    endDate: string;
    periodPrice: number;
    commissionLabel: string;
    commissionString: string;
}

export type StatusTypes = 'รอดำเนินการ' | 'รับจองแล้ว' | 'ยกเลิกใบจอง';

export class BookingRequestLeadList {
    private leadDetails: ILeadBookingRequestDetails[] = [];
    private startDate: string;
    private endDate: string;
    private periodPrice: number;

    public deserialize(objList: any): ILeadBookingRequestDetails[] {
        objList.forEach((obj: any) => {
            this.leadDetails.push(this.deserializeCurrentLead(obj));
        });
        return this.leadDetails;
    }

    private deserializeCurrentLead(obj: any): ILeadBookingRequestDetails {
        this.getPeriodDetails(obj.product_detail_period_id, obj);

        const currentLead: ILeadBookingRequestDetails = {
            id: obj.id,
            bookingNumberCode: obj.booking_number_code,
            leadStatusSlug: obj.lead_status_slug,
            agencyProductCode: obj.owner_agency_product_code,
            numberOfPassengers: obj.number_of_passengers,
            productDetail: obj.product_detail ? new ProductList().deserializePoolData(obj.product_detail) : null,
            productDetailPeriodId: obj.product_detail_period_id,
            leadCreatedByAgency: {
                id: obj.b2c_lead_created_by_agency.id,
                nameEn: obj.b2c_lead_created_by_agency.name_en,
            },
            createdAt: obj.created_at,
            startDate: this.startDate,
            endDate: this.endDate,
            periodPrice: this.periodPrice,
            commissionLabel: this.getCommissionLabel(obj),
            commissionString: this.getCommission(obj),
        };

        return currentLead;
    }

    private getPeriodDetails(id: number, obj: any): void {
        let startDate: string = null;
        let endDate: string = null;
        let price: number = null;

        if (id && obj.product_detail) {
            const periods: any = obj.product_detail.periods;
            periods.forEach((period: any) => {
                if (period.id === id) {
                    startDate = period.start_at;
                    endDate = period.end_at;
                    price = period.price;
                }
            });
        }
        this.startDate = startDate;
        this.endDate = endDate;
        this.periodPrice = price;
    }

    public getAgencyList(objList: any[]): any {
        const flags = [],
            output = [];

        for (let i = 0; i < objList.length; i++) {
            if (flags[objList[i].b2c_lead_created_by_agency.id]) {
                continue;
            }
            flags[objList[i].b2c_lead_created_by_agency.id] = true;
            output.push(objList[i].b2c_lead_created_by_agency);
        }
        return output;
    }

    private getCommissionLabel(obj: any): string {
        const hasCommissionCompany: boolean = obj.product_detail && obj.product_detail.commission_company;
        const hasCommissionSeller: boolean = obj.product_detail && obj.product_detail.commission_seller;

        let label = 'คอม';

        if (hasCommissionCompany && !hasCommissionSeller) {
            label += 'บริษัท';
        } else if (!hasCommissionCompany && hasCommissionSeller) {
            label += 'เซลล์';
        }

        return label;
    }

    private getCommission(obj: any): string {
        const commissionCompany: string =
            obj.product_detail && obj.product_detail.commission_company
                ? Math.round(obj.product_detail.commission_company)
                      .toString()
                      .replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                : null;
        const commissionSeller: string =
            obj.product_detail && obj.product_detail.commission_seller
                ? Math.round(obj.product_detail.commission_seller)
                      .toString()
                      .replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                : null;

        let commission = '';

        if (commissionCompany) {
            commission += commissionCompany;
        }
        if (commissionCompany && commissionSeller) {
            commission += ' + ';
        }
        if (commissionSeller) {
            commission += commissionSeller;
        }

        if (commission) {
            return commission;
        }
        return null;
    }
}

export interface ILeadBookingRequestSingleDetails {
    id: number;
    bookingNumberCode: string;
    agencyProductCode: string;
    numberOfPassengers: number;
    moreInfoForWs: string;
    productDetail: ProductList;
    productDetailPeriodId: number;
    createdAt: string;
    leadCreatedBy: {
        id: number;
        firstName: string;
        lastName: string;
        nickName: string;
        mobileNumber: string;
        agency: {
            id: number;
            nameTh: string;
            nameEn: string;
            licenseNumber: string;
            phoneNo: string;
            email: string;
            address: string;
        };
    };
    startDate: string;
    endDate: string;
    periodPrice: number;
}

export class BookingRequestSingleDetails {
    private startDate: string;
    private endDate: string;
    private periodPrice: number;

    public deserialize(obj: any): ILeadBookingRequestSingleDetails {
        this.getPeriodDetails(obj.product_detail_period_id, obj);

        const currentLead: ILeadBookingRequestSingleDetails = {
            id: obj.id,
            bookingNumberCode: obj.booking_number_code,
            agencyProductCode: obj.owner_agency_product_code,
            numberOfPassengers: obj.number_of_passengers,
            moreInfoForWs: obj.more_info_for_ws,
            productDetail: obj.product_detail ? new ProductList().deserializePoolData(obj.product_detail) : null,
            productDetailPeriodId: obj.product_detail_period_id,
            createdAt: obj.created_at,
            leadCreatedBy: {
                id: obj.created_by_agency_member.id,
                firstName: obj.created_by_agency_member.first_name,
                lastName: obj.created_by_agency_member.last_name,
                nickName: obj.created_by_agency_member.nick_name,
                mobileNumber: obj.created_by_agency_member.mobile_number,
                agency: {
                    id: obj.created_by_agency_member.agency.id,
                    nameTh: obj.created_by_agency_member.agency.name_th,
                    nameEn: obj.created_by_agency_member.agency.name_en,
                    licenseNumber: obj.created_by_agency_member.agency.license_number,
                    phoneNo: obj.created_by_agency_member.agency.office_telephone_number,
                    email: obj.created_by_agency_member.agency.email,
                    address: obj.created_by_agency_member.agency.address,
                },
            },
            startDate: this.startDate,
            endDate: this.endDate,
            periodPrice: this.periodPrice,
        };

        return currentLead;
    }

    private getPeriodDetails(id: number, obj: any): void {
        let startDate: string = null;
        let endDate: string = null;
        let price: number = null;

        if (id && obj.product_detail) {
            const periods: any = obj.product_detail.periods;
            periods.forEach((period: any) => {
                if (period.id === id) {
                    startDate = period.start_at;
                    endDate = period.end_at;
                    price = period.price;
                }
            });
        }
        this.startDate = startDate;
        this.endDate = endDate;
        this.periodPrice = price;
    }
}
