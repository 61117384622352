import { Action, createReducer, on } from '@ngrx/store';
import * as SettingActions from './setting.actions';
import { BannerConfigs, IBannerUpdaterRequest } from 'src/app/modules/settings/models/config-banners.model';
import { ICustomContent } from 'src/app/models/custom-content.model';
import { CustomContentUpdaterResponse } from './models/custom-content-updater-response.model';
import { InvoiceConfigs } from 'src/app/models/invoice-config.model';
import { ISeoArticle } from 'src/app/modules/settings/models/seo-article/seo-article.model';
import { SeoArticleListItem } from 'src/app/modules/settings/models/seo-article/seo-article-list-item.model';
import { TargetPageType } from 'src/app/modules/settings/models/seo-article/target-page-type.model';
import { SpecialPage } from 'src/app/modules/settings/models/seo-article/special-page.model';
import { SeoArticleOptionItem } from 'src/app/modules/settings/models/seo-article/seo-article-option-item.model';

export const reportFeatureKey = 'setting';

export interface SettingState {
    configBannersResult: BannerConfigs;
    configBannersResultErrorMessage: string;
    configBannersUpdaterRequest: IBannerUpdaterRequest;
    configBannerUpdaterStatus: boolean;
    configBannerUpdaterErrorMessage: string;

    customContent: ICustomContent;
    customContentUpdaterResponse: CustomContentUpdaterResponse;
    customContentUpdaterError: any;

    portfolioListingResponse: any;
    portfolioListingError: any;
    portfolioSingleResponse: any;
    portfolioSingleError: any;
    portfolioNewId: number;
    addPortfolioItemError: any;
    portfolioEditedId: number;
    editPortfolioItemError: any;
    portfolioDeletedId: number;
    deletePortfolioItemError: any;
    updateIncentiveImgResponse: any;
    updateIncentiveImgError: any;
    incentiveImgUrl: string;
    getIncentiveImgError: any;

    blogList: any;
    blogListingError: any;
    blogSingleResponse: any;
    blogSingleError: any;
    newBlogId: number;
    newBlogError: string;
    editedBlogId: number;
    editedBlogError: string;
    deletedBlogId: number;
    deletedBlogError: string;

    invoiceConfigs: InvoiceConfigs;
    invoiceConfigsSuccess: boolean;
    invoiceConfigsError: any;

    seoArticle: ISeoArticle;
    seoArticleList: SeoArticleListItem[];
    seoArticleOptions: SeoArticleOptionItem[];

    seoTargetPageTypes: TargetPageType[];
    seoSpecialPages: SpecialPage[];
}

export const initialState: SettingState = {
    configBannersResult: null,
    configBannersResultErrorMessage: '',
    configBannersUpdaterRequest: null,
    configBannerUpdaterStatus: true,
    configBannerUpdaterErrorMessage: '',

    customContent: null,
    customContentUpdaterResponse: null,
    customContentUpdaterError: null,

    portfolioListingResponse: null,
    portfolioListingError: null,
    portfolioSingleResponse: null,
    portfolioSingleError: null,
    portfolioNewId: null,
    addPortfolioItemError: null,
    portfolioEditedId: null,
    editPortfolioItemError: null,
    portfolioDeletedId: null,
    deletePortfolioItemError: null,
    updateIncentiveImgResponse: null,
    updateIncentiveImgError: null,
    incentiveImgUrl: null,
    getIncentiveImgError: null,

    blogList: null,
    blogListingError: null,
    blogSingleResponse: null,
    blogSingleError: null,
    newBlogId: null,
    newBlogError: null,
    editedBlogId: null,
    editedBlogError: null,
    deletedBlogId: null,
    deletedBlogError: null,

    invoiceConfigs: null,
    invoiceConfigsSuccess: null,
    invoiceConfigsError: null,

    seoArticle: null,
    seoArticleList: null,
    seoArticleOptions: null,

    seoTargetPageTypes: null,
    seoSpecialPages: null,
};

export const settingReducer = createReducer(
    initialState,

    on(SettingActions.loadConfigBanners, (state) => state),
    on(SettingActions.loadConfigBannersSuccess, (state, action) => {
        return {
            ...state,
            configBannersResult: action.configBanners,
        };
    }),

    on(SettingActions.loadConfigBannersError, (state, action) => {
        return {
            ...state,
            configBannersResultErrorMessage: action.error,
        };
    }),

    on(SettingActions.updateConfigBanners, (state, action) => {
        return {
            ...state,
            configBannersUpdaterRequest: action.requestBody,
        };
    }),

    on(SettingActions.updateConfigBannersSuccess, (state) => {
        return {
            ...state,
            configBannerUpdaterStatus: true,
        };
    }),
    on(SettingActions.updateConfigBannersError, (state, action) => {
        return {
            ...state,
            configBannerUpdaterStatus: false,
            configBannerUpdaterErrorMessage: action.error,
        };
    }),

    on(SettingActions.loadCustomContent, (state) => ({ ...state, customContent: null })),
    on(SettingActions.loadCustomContentSuccess, (state, action) => ({ ...state, customContent: action.content })),
    on(SettingActions.loadCustomContentFailure, (state, action) => ({ ...state })),
    on(SettingActions.loadCustomContentReset, (state) => ({ ...state, customContent: null })),

    on(SettingActions.updateCustomContent, (state, action) => {
        return {
            ...state,
            customContentUpdaterResponse: null,
            customContentUpdaterError: null,
        };
    }),
    on(SettingActions.updateCustomContentSuccess, (state, action) => {
        return {
            ...state,
            customContentUpdaterResponse: action.response,
            customContentUpdaterError: null,
        };
    }),
    on(SettingActions.updateCustomContentFailure, (state, action) => {
        if (action.error instanceof CustomContentUpdaterResponse) {
            return {
                ...state,
                customContentUpdaterResponse: action.error,
                customContentUpdaterError: null,
            };
        } else {
            return {
                ...state,
                customContentUpdaterError: action.error,
            };
        }
    }),
    on(SettingActions.updateCustomContentReset, (state) => {
        return {
            ...state,
            customContentUpdaterResponse: null,
            customContentUpdaterError: null,
        };
    }),

    on(SettingActions.getPortfolioListing, (state, action) => {
        return {
            ...state,
        };
    }),
    on(SettingActions.getPortfolioListingSuccess, (state, action) => {
        return {
            ...state,
            portfolioListingResponse: action.response,
        };
    }),
    on(SettingActions.getPortfolioListingFailure, (state, action) => {
        return {
            ...state,
            portfolioListingError: action.error,
        };
    }),

    on(SettingActions.getPortfolioSingle, (state, action) => {
        return {
            ...state,
        };
    }),
    on(SettingActions.getPortfolioSingleSuccess, (state, action) => {
        return {
            ...state,
            portfolioSingleResponse: action.response,
        };
    }),
    on(SettingActions.getPortfolioSingleFailure, (state, action) => {
        return {
            ...state,
            portfolioSingleError: action.error,
        };
    }),

    on(SettingActions.addPortfolioItem, (state, action) => {
        return {
            ...state,
        };
    }),
    on(SettingActions.addPortfolioItemSuccess, (state, action) => {
        return {
            ...state,
            portfolioNewId: action.portfolioNewId,
        };
    }),
    on(SettingActions.addPortfolioItemError, (state, action) => {
        return {
            ...state,
            addPortfolioItemError: action.error,
        };
    }),

    on(SettingActions.editPortfolioItem, (state, action) => {
        return {
            ...state,
        };
    }),
    on(SettingActions.editPortfolioItemSuccess, (state, action) => {
        return {
            ...state,
            portfolioEditedId: action.portfolioEditedId,
        };
    }),
    on(SettingActions.editPortfolioItemError, (state, action) => {
        return {
            ...state,
            editPortfolioItemError: action.error,
        };
    }),

    on(SettingActions.deletePortfolioItem, (state, action) => {
        return {
            ...state,
        };
    }),
    on(SettingActions.deletePortfolioItemSuccess, (state, action) => {
        return {
            ...state,
            portfolioDeletedId: action.portfolioDeletedId,
        };
    }),
    on(SettingActions.deletePortfolioItemError, (state, action) => {
        return {
            ...state,
            deletePortfolioItemError: action.error,
        };
    }),

    on(SettingActions.updateIncentiveImg, (state, action) => {
        return {
            ...state,
        };
    }),
    on(SettingActions.updateIncentiveImgSuccess, (state, action) => {
        return {
            ...state,
            updateIncentiveImgResponse: action.response,
        };
    }),
    on(SettingActions.updateIncentiveImgError, (state, action) => {
        return {
            ...state,
            updateIncentiveImgError: action.error,
        };
    }),

    on(SettingActions.getIncentiveImg, (state, action) => {
        return {
            ...state,
        };
    }),
    on(SettingActions.getIncentiveImgSuccess, (state, action) => {
        return {
            ...state,
            incentiveImgUrl: action.url,
        };
    }),
    on(SettingActions.getIncentiveImgError, (state, action) => {
        return {
            ...state,
            getIncentiveImgError: action.error,
        };
    }),

    on(SettingActions.getBlogListing, (state, action) => {
        return {
            ...state,
        };
    }),
    on(SettingActions.getBlogListingSuccess, (state, action) => {
        return {
            ...state,
            blogList: action.response,
        };
    }),
    on(SettingActions.getBlogListingFailure, (state, action) => {
        return {
            ...state,
            blogListError: action.error,
        };
    }),

    on(SettingActions.getBlogSingle, (state, action) => {
        return {
            ...state,
        };
    }),
    on(SettingActions.getBlogSingleSuccess, (state, action) => {
        return {
            ...state,
            blogSingleResponse: action.response,
        };
    }),
    on(SettingActions.getBlogSingleFailure, (state, action) => {
        return {
            ...state,
            blogSingleError: action.error,
        };
    }),

    on(SettingActions.addBlog, (state, action) => {
        return {
            ...state,
        };
    }),
    on(SettingActions.addBlogSuccess, (state, action) => {
        return {
            ...state,
            newBlogId: action.newBlogId,
        };
    }),
    on(SettingActions.addBlogFailure, (state, action) => {
        return {
            ...state,
            newBlogError: action.error,
        };
    }),

    on(SettingActions.editBlog, (state, action) => {
        return {
            ...state,
        };
    }),
    on(SettingActions.editBlogSuccess, (state, action) => {
        return {
            ...state,
            editedBlogId: action.editedBlogId,
        };
    }),
    on(SettingActions.editBlogFailure, (state, action) => {
        return {
            ...state,
            editedBlogError: action.error,
        };
    }),

    on(SettingActions.deleteBlog, (state, action) => {
        return {
            ...state,
        };
    }),
    on(SettingActions.deleteBlogSuccess, (state, action) => {
        return {
            ...state,
            deletedBlogId: action.deletedBlogId,
        };
    }),
    on(SettingActions.deleteBlogFailure, (state, action) => {
        return {
            ...state,
            deletedBlogError: action.error,
        };
    }),

    on(SettingActions.loadInvoiceConfigsSuccess, (state, action) => {
        return {
            ...state,
            invoiceConfigs: action.invoiceConfigs,
        };
    }),
    on(SettingActions.loadInvoiceConfigsFailure, (state, action) => {
        return {
            ...state,
            invoiceConfigsError: action.error,
        };
    }),

    on(SettingActions.updateInvoiceConfigs, (state, action) => {
        return {
            ...state,
            invoiceConfigsError: null,
            invoiceConfigsSuccess: null,
        };
    }),
    on(SettingActions.updateInvoiceConfigsSuccess, (state, action) => {
        return {
            ...state,
            invoiceConfigsSuccess: true,
        };
    }),
    on(SettingActions.updateInvoiceConfigsFailure, (state, action) => {
        return {
            ...state,
            invoiceConfigsError: action.error,
            invoiceConfigsSuccess: false,
        };
    }),
    on(SettingActions.resetInvoiceConfigsResult, (state, action) => {
        return {
            ...state,
            invoiceConfigsError: null,
            invoiceConfigsSuccess: null,
        };
    }),

    on(SettingActions.getSeoArticleListingSuccess, (state, action) => {
        return {
            ...state,
            seoArticleList: action.articles,
        };
    }),

    on(SettingActions.getSeoArticleAsOptionsSuccess, (state, action) => {
        return {
            ...state,
            seoArticleOptions: action.articles,
        };
    }),

    on(SettingActions.getSeoTargetPageTypesSuccess, (state, action) => {
        return {
            ...state,
            seoTargetPageTypes: action.targetPageTypes,
        };
    }),

    on(SettingActions.getSeoSpecialPagesSuccess, (state, action) => {
        return {
            ...state,
            seoSpecialPages: action.seoSpecialPages,
        };
    }),

    on(SettingActions.addNewSeoArticle, (state, action) => {
        return {
            ...state,
        };
    }),
    on(SettingActions.addNewSeoArticleSuccess, (state, action) => {
        return {
            ...state,
        };
    }),
    on(SettingActions.addNewSeoArticleFailure, (state, action) => {
        return {
            ...state,
        };
    }),

    on(SettingActions.getSeoArticle, (state, action) => {
        return {
            ...state,
        };
    }),
    on(SettingActions.getSeoArticleSuccess, (state, action) => {
        return {
            ...state,
        };
    }),
    on(SettingActions.getSeoArticleFailure, (state, action) => {
        return {
            ...state,
        };
    }),

    on(SettingActions.updateSeoArticleContent, (state, action) => {
        return {
            ...state,
        };
    }),
    on(SettingActions.updateSeoArticleContentSuccess, (state, action) => {
        return {
            ...state,
        };
    }),
    on(SettingActions.updateSeoArticleContentFailure, (state, action) => {
        return {
            ...state,
        };
    }),

    on(SettingActions.updateSeoArticle, (state, action) => {
        return {
            ...state,
        };
    }),
    on(SettingActions.updateSeoArticleSuccess, (state, action) => {
        return {
            ...state,
        };
    }),
    on(SettingActions.updateSeoArticleFailure, (state, action) => {
        return {
            ...state,
        };
    })
);

export function reducer(state: SettingState | undefined, action: Action) {
    return settingReducer(state, action);
}
