import { IPartner, PartnersList } from './../../models/partners-list.model';
import { Action, createReducer, on, State } from '@ngrx/store';
import * as UserActions from './user.actions';

import { User } from 'src/app/models/user';
import { Agency, AgencyProductCategory } from 'src/app/models/agency';
import { ICustomContent } from 'src/app/models/custom-content.model';
import { IUser } from 'src/app/models/users-list';

export const userFeatureKey = 'user';

export interface UserState {
    isAuthenticate: boolean;
    user: User | null;
    agency: Agency | null;
    errorMessage: string | null;
    menuCustomContent: ICustomContent;
    twmDomain: string;
    usersList: IUser[];
    partnersList: IPartner[];
}

export const initialState: UserState = {
    isAuthenticate: false,
    user: null,
    agency: null,
    errorMessage: null,
    menuCustomContent: null,
    twmDomain: null,
    usersList: null,
    partnersList: null,
};

export const userReducer = createReducer(
    initialState,

    on(UserActions.login, (state) => state),
    on(UserActions.loginSuccess, (state, action) => {
        const token = action.data.token;
        const data = JSON.parse(atob(token.split('.')[1]));
        const user = JSON.parse(atob(data.agcy));

        return {
            ...state,
            isAuthenticate: true,
            user: {
                id: user.user.id,
                firstName: user.user.first_name,
                lastName: user.user.last_name,
                nickName: user.user.nick_name,
                mobileNumber: user.user.mobile_number,
                roleSlug: user.user.roles_slug || null,
            },
            agency: {
                id: user.agency.id,
                nameEN: user.agency.name_en,
                nameTH: user.agency.name_th,
                licenseNumber: user.agency.license_number,
                phoneNumber: user.agency.phone_number,
                email: user.agency.email,
                facebookName: user.agency.facebook_name,
                lineId: user.agency.line_id,
                lineQrUrl: user.agency.line_qr_url,
                address: user.agency.address,
                slug: user.agency.slug,
                productCode: user.agency.product_code,
                productDescription: user.agency.product_description,
                isActiveTourwowMall: !!+user.agency.is_active_tourwowmall,
                useSeoArticle: false,
                displayName: null,
                productCategories: [],
            },
        };
    }),
    on(UserActions.loginFailure, (state, action) => {
        return {
            ...state,
            errorMessage: action.error.message,
        };
    }),

    on(UserActions.logout, (state) => state),
    on(UserActions.logoutSuccess, (state) => ({ ...state, isAuthenticate: false })),

    on(UserActions.checkAuthentication, (state) => state),
    on(UserActions.checkAuthenticationSuccess, (state, action) => {
        const token = action.data;
        const data = JSON.parse(atob(token.split('.')[1]));
        const user = JSON.parse(atob(data.agcy));

        return {
            ...state,
            isAuthenticate: true,
            user: {
                id: user.user.id,
                firstName: user.user.first_name,
                lastName: user.user.last_name,
                nickName: user.user.nick_name,
                mobileNumber: user.user.mobile_number,
                roleSlug: user.user.roles_slug || null,
            },
            agency: {
                ...state.agency,
                id: user.agency.id,
                nameEN: user.agency.name_en,
                nameTH: user.agency.name_th,
                licenseNumber: user.agency.license_number,
                phoneNumber: user.agency.phone_number,
                email: user.agency.email,
                facebookName: user.agency.facebook_name,
                lineId: user.agency.line_id,
                lineQrUrl: user.agency.line_qr_url,
                address: user.agency.address,
                slug: user.agency.slug,
                productCode: user.agency.product_code,
                productDescription: user.agency.product_description,
                isActiveTourwowMall: !!+user.agency.is_active_tourwowmall,
            },
        };
    }),
    on(UserActions.checkAuthenticationFailure, (state, action) => ({
        ...state,
        isAuthenticate: false,
        errorMessage: action.error,
    })),

    on(UserActions.getAgencyInfoSuccess, (state, { data }) => {
        const agency: Agency = {
            ...state.agency,
            useSeoArticle: !!+data.use_seo_article,
            allowPartnersToSellProduct: !!+data.allow_partners_sell_product,
            allowSellTourwow: !!+data.allow_sell_tourwow,
            displayCommission: !!+data.display_commission,
            displayName: data.display_name,
            lineUrl: data.line_url,
            productCategories: (data.product_categories as any[]).map((category) =>
                new AgencyProductCategory().deserialize(category)
            ),
        };

        return {
            ...state,
            agency,
            menuCustomContent: data.custom_content.map((menu) => {
                const content: ICustomContent = {
                    id: +menu.id,
                    menuOrdinal: +menu.menu_ordinal,
                    isActive: !!+menu.is_active,
                };
                return content;
            }),
            twmDomain: data.twm_domain,
        };
    }),

    on(UserActions.getAgencyInfoFailure, (state, { error }) => {
        return {
            ...state,
            errorMessage: error,
        };
    }),

    on(UserActions.getUsersList, (state) => {
        return {
            ...state,
        };
    }),

    on(UserActions.getUsersListSuccess, (state, { usersList }) => {
        return {
            ...state,
            usersList: usersList,
        };
    }),

    on(UserActions.getUsersListFailure, (state, { error }) => {
        return {
            ...state,
            errorMessage: error,
        };
    }),

    on(UserActions.getPartnersList, (state) => {
        return {
            ...state,
        };
    }),

    on(UserActions.getPartnersListSuccess, (state, { partnersList }) => {
        return {
            ...state,
            partnersList: partnersList,
        };
    }),

    on(UserActions.getPartnersListFailure, (state, { error }) => {
        return {
            ...state,
            errorMessage: error,
        };
    })
);

export function reducer(state: UserState | undefined, action: Action) {
    return userReducer(state, action);
}
