import { Injectable, Inject, PLATFORM_ID } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { Observable, of } from 'rxjs';
import { switchMap, tap, map } from 'rxjs/operators';

import { HttpService } from './base/http.service';
import { ENDPOINTS } from '../constants/endpoint.constant';
import { environment } from 'src/environments/environment';

@Injectable({
    providedIn: 'root',
})
export class AuthenticationService extends HttpService {
    public token: string | null = null;
    public redirectUrl: string | null = null;

    constructor(
        @Inject(PLATFORM_ID) protected platformId: Object,
        protected http: HttpClient,
        protected router: Router
    ) {
        super(platformId, http, router);
    }

    public login(username: string, password: string): Observable<any> {
        const url = environment.apiProUrl + ENDPOINTS.AUTHEN.LOGIN;
        return this.getRequestOptions(false).pipe(
            switchMap((headers) => this.post(url, { username, password }, false))
        );
    }

    public logout(): Observable<any> {
        const url = environment.apiProUrl + ENDPOINTS.AUTHEN.LOGOUT;
        return this.getRequestOptions(true).pipe(
            switchMap((headers) => this.get(url, true)),
            map((rep) => this.removeToken())
        );
    }

    public setToken(token: string): void {
        if (isPlatformBrowser(this.platformId)) {
            localStorage.setItem(this.localStorageTokenKey, token);
            this.token = token;
        }
    }

    public removeToken(): void {
        if (isPlatformBrowser(this.platformId)) {
            localStorage.removeItem(this.localStorageTokenKey);
            this.token = null;
        }
    }

    public getToken(): string {
        if (isPlatformBrowser(this.platformId)) {
            this.token = localStorage.getItem(this.localStorageTokenKey);
        }

        return this.token;
    }

    public isAuthentication(): boolean {
        if (this.getToken()) {
            return true;
        }

        return false;
    }

    public setReload(count: number): void {
        if (isPlatformBrowser(this.platformId)) {
            if (count == 0) {
                localStorage.removeItem('reload');
            } else {
                localStorage.setItem('reload', count.toString());
            }
        }
    }

    public getReload(): number {
        let count = 0;
        if (isPlatformBrowser(this.platformId)) {
            count = +localStorage.getItem('reload');
        }

        return count;
    }
}
