import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'description',
})
export class DescriptionPipe implements PipeTransform {
    transform(value: any, ...args: any[]): any {
        let descriptionMsg = '';
        $.each(value, (index, valueItem) => {
            // debugger;
            if (valueItem.type_slug === 'description') {
                descriptionMsg += `${valueItem.description}<br/>`;
            } else if (valueItem.type_slug === 'image') {
                descriptionMsg += `<img src="${valueItem.image_url}"/><br/>`;
            }
        });
        return descriptionMsg;
    }
}
