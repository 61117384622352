import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';

import { RoomType } from 'src/app/models/room-type.model';
import { WholesaleProductPeriod } from 'src/app/models/wholesale-products/wholesale-product-period.model';
import { WholesaleProduct } from 'src/app/models/wholesale-products/wholesale-product.model';
import { ISkuListForm } from '../../interfaces/sku-list-form.interface';

import { ThaiDateRangePipe } from 'src/app/pipes/thaidate.pipe';
import { ICustomerInfoForm } from '../../interfaces/customer-info-form.interface';

@Component({
    selector: 'app-booking-detail-preview',
    templateUrl: './booking-detail-preview.component.html',
    styleUrls: ['./booking-detail-preview.component.scss'],
})
export class BookingDetailPreviewComponent {
    @Input() program: WholesaleProduct = null;
    @Input() period: WholesaleProductPeriod = null;
    @Input() skuList: ISkuListForm = null;
    @Input() quantity: number = null;
    @Input() customerInfo: ICustomerInfoForm = null;
    constructor(private thaidateRangePipe: ThaiDateRangePipe) {}

    get adultSingleBed(): RoomType {
        return this.getRoomType('adult_single');
    }

    get adultDoubleBed(): RoomType {
        return this.getRoomType('adult_double');
    }

    get adultTripleBed(): RoomType {
        return this.getRoomType('adult_triple');
    }

    get childWithBed(): RoomType {
        return this.getRoomType('child_bed');
    }

    get childNoBed(): RoomType {
        return this.getRoomType('child_no_bed');
    }

    get infant(): RoomType {
        return this.getRoomType('infant');
    }

    get joinLand(): RoomType {
        return this.getRoomType('join_land');
    }

    private getRoomType(slug: string): RoomType {
        if (!this.period.roomTypes) {
            return null;
        }

        const room = this.period.roomTypes.find((room) => room.slug === slug);
        if (room.price === null) {
            return null;
        }

        return room;
    }

    getPeriodTime(startDate: Date, endDate: Date): string {
        return this.thaidateRangePipe.transform(startDate, endDate);
    }
}
