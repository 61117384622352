export class CustomContentUpdaterResponse {
    public status: 'success' | 'error';
    public message: string;
    public error: {
        code: string;
        type: string;
        notices: any;
    };
    public data: any;

    public deserialize(obj: any): this {
        this.status = obj.status;
        this.message = obj.message || null;
        this.error = obj.error || null;
        this.data = obj.data || null;
        return this;
    }

    public getValidationError(): CustomContentUpdaterValidationErrorTypes {
        if (this.error && this.error.type == 'Validation Error') {
            if ('menuNameTH' in this.error.notices) {
                const menuNameThErrors: string[] = this.error.notices.menuNameTH;
                if (menuNameThErrors.includes('The menu name t h has already been taken.')) {
                    return CustomContentUpdaterValidationErrorTypes.menuNameThIsAlreadyTaken;
                }
                if (menuNameThErrors.includes('The selected menu name t h is invalid.')) {
                    return CustomContentUpdaterValidationErrorTypes.menuNameThIsReservedWord;
                }
            }
            return CustomContentUpdaterValidationErrorTypes.other;
        }
        return null;
    }
}

export enum CustomContentUpdaterValidationErrorTypes {
    menuNameThIsReservedWord = 'menuNameThIsReservedWord',
    menuNameThIsAlreadyTaken = 'menuNameThIsAlreadyTaken',
    other = 'other',
}
