import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { reducer } from './utility.reducer';
import { UtilityEffects } from './utility.effects';

export const FEATURE_NAME = 'utility';

@NgModule({
    imports: [CommonModule, StoreModule.forFeature(FEATURE_NAME, reducer), EffectsModule.forFeature([UtilityEffects])],
    providers: [UtilityEffects],
})
export class UtilityStoreModule {}
