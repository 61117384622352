<div class="modal-header">
    <button type="button" class="close pull-right" aria-label="Close" (click)="bsModalRef.hide()">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body">
    <div class="modal-detail">
        <div class="modal-detail-title">{{ title }}</div>
        <div class="modal-detail-content">
            <div class="btn-group" dropdown>
                <button
                    id="button-basic"
                    dropdownToggle
                    type="button"
                    class="btn btn-secondary dropdown-btn dropdown-toggle"
                    aria-controls="dropdown-basic"
                    [ngClass]="{ invalidData: invalidData }"
                >
                    {{ dataSelected.label }}<span class="caret"></span>
                </button>
                <ul id="dropdown-basic" *dropdownMenu class="dropdown-menu" role="menu" aria-labelledby="button-basic">
                    <li
                        *ngFor="let item of dropdownList"
                        role="menuitem"
                        class="dropdown-item"
                        (click)="onSelected(item)"
                    >
                        {{ item.label }}
                    </li>
                </ul>
            </div>
        </div>
    </div>
</div>
<div class="modal-footer">
    <div class="confirm-button-container">
        <button type="button" class="btn btn-secondary confirm-btn" (click)="onSubmit()">ยืนยัน</button>
        <button type="button" class="btn btn-outline-secondary cancle-btn" (click)="onCancle()">ยกเลิก</button>
    </div>
</div>
