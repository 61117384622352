import { Component, OnDestroy, OnInit } from '@angular/core';
import { select, Store } from '@ngrx/store';
import {
    SearchableDropdownItem,
    SearchableDropdownSettings,
} from 'src/app/modules/shared/components/searchable-dropdown/searchable-dropdown.component';
import { State } from 'src/app/stores/reducers';
import * as OrderActions from 'src/app/stores/order-store/order.actions';
import { selectOrderSummaries } from 'src/app/stores/order-store/order.selectors';
import { OrderSummary } from 'src/app/models/order-summary.model';
import { IGroupPlanListQueries } from '../../models/group-plan-list-queries';
import { filter, takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

@Component({
    selector: 'app-group-plan-filter',
    templateUrl: './group-plan-filter.component.html',
    styleUrls: ['./group-plan-filter.component.scss'],
})
export class GroupPlanFilterComponent implements OnInit, OnDestroy {
    public dropdownSettings: SearchableDropdownSettings = new SearchableDropdownSettings();
    public dropdownItems: SearchableDropdownItem[];
    public orderSummaries: any[];
    public filterType = 'month';
    private unsubscribe$: Subject<void> = new Subject<void>();

    constructor(private store: Store<State>) {}

    ngOnInit(): void {
        this.loadDropdown();

        this.dispatchOrderSummaries();
        this.store
            .pipe(
                select(selectOrderSummaries),
                filter((data) => data !== null),
                takeUntil(this.unsubscribe$)
            )
            .subscribe((orderSummaries: OrderSummary[]) => {
                this.orderSummaries = orderSummaries.map((item: OrderSummary, index: number) => {
                    return {
                        active: false,
                        ...item,
                    };
                });

                this.onSelectGroupPlan(this.orderSummaries[0]);
            });
    }

    public onSelectDateRange(item: any): void {
        this.filterType = item[0];
        this.dispatchOrderSummaries();
    }

    public onSelectGroupPlan(item) {
        this.orderSummaries = this.orderSummaries.map((m) => {
            m.active = false;
            return m;
        });
        item.active = true;

        this.store.dispatch(
            OrderActions.selectOrderSummaries({
                orderSummarySelect: {
                    filterType: this.filterType,
                    dateRange: item.dateRange,
                },
            })
        );

        const groupPlanListQueries: IGroupPlanListQueries = {
            filters: {
                start_at_between: {
                    min_date: item.dateRange.minDate,
                    max_date: item.dateRange.maxDate,
                },
                order_statuses: [
                    { status_code: 11, already_started: null },
                    { status_code: 21, already_started: null },
                    { status_code: 22, already_started: null },
                    { status_code: 31, already_started: false },
                    { status_code: 31, already_started: true },
                ],
            },
            criteria: {
                page: 1,
                pageEntries: 50,
            },
        };

        this.store.dispatch(
            OrderActions.getGroupPlanListPaginated({
                groupPlanListQueries: groupPlanListQueries,
                isNextPage: false,
            })
        );
    }

    private dispatchOrderSummaries(): void {
        this.store.dispatch(
            OrderActions.getOrderSummaries({
                criteria: {
                    interval_direction: 'forward',
                    interval_limit: 4,
                    interval_type: this.filterType,
                },
            })
        );
    }

    private loadDropdown(): void {
        this.dropdownItems = [
            {
                id: 'month',
                name: 'เลือกดูรายเดือน',
                isChecked: true,
            },
            {
                id: 'week',
                name: 'เลือกดูรายสัปดาห์',
            },
        ];
        this.dropdownSettings.hideSearchBox = true;
        this.dropdownSettings.multipleSelection = false;
        this.dropdownSettings.closeAfterSelect = true;
        this.dropdownSettings.unableToUncheck = true;
        this.dropdownSettings.styleSetting.fontSize = '16px';
        this.dropdownSettings.styleSetting.height = '30px';
    }

    ngOnDestroy(): void {
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
    }
}
