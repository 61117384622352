import { IProductPeriod } from '../interfaces/tw-booking-interface/product-period.interface';

export class ProductPeriod {
    id: number;
    startAt: string;
    endAt: string;

    public deserialize(data: IProductPeriod): ProductPeriod {
        this.id = data.id;
        this.startAt = data.start_at;
        this.endAt = data.end_at;
        return this;
    }
}
