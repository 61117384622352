import { Component, OnDestroy } from '@angular/core';
import { Actions, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { SuccessModalComponent } from 'src/app/modules/shared/modals/success-modal/success-modal.component';
import {
    sendEmailCampaignToContact,
    sendEmailCampaignToContactSuccess,
} from 'src/app/stores/campaign-store/campaign.actions';
import { State } from 'src/app/stores/reducers';

@Component({
    selector: 'app-send-email-order-detail-modal',
    templateUrl: './send-email-order-detail-modal.component.html',
    styleUrls: ['./send-email-order-detail-modal.component.scss'],
})
export class SendEmailOrderDetailModalComponent implements OnDestroy {
    paymentId: number;
    contactName: string;
    email: string;

    private unsubscribe$: Subject<void> = new Subject<void>();

    constructor(
        public bsModalRef: BsModalRef,
        public bsModalService: BsModalService,
        private store: Store<State>,
        private action: Actions
    ) {}

    ngOnDestroy(): void {
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
    }

    confirm(): void {
        this.store.dispatch(sendEmailCampaignToContact({ paymentId: this.paymentId }));
        this.action.pipe(ofType(sendEmailCampaignToContactSuccess), takeUntil(this.unsubscribe$)).subscribe(() => {
            this.bsModalRef.hide();
            const initialState = {
                message: 'ส่งอีเมลเรียบร้อยแล้ว',
            };
            this.bsModalRef = this.bsModalService.show(SuccessModalComponent, {
                initialState,
                class: 'success-modal',
            });
        });
    }

    hide(): void {
        this.bsModalRef.hide();
    }
}
