<div class="confirm-payment-container">
  <div class="title">
    <img src="assets/img/icon/icon-give-money.svg"> กรุณาคลิกเพื่อยืนยันรับเงิน
  </div>
  <div class="detail">
    <div class="row">
      <div class="col-6 mb-1 transfer-date">
        รอบโอน <span>วันที่ {{order.transferDueDate | thaiDate:'DD MM YYYY'}}</span>
      </div>
      <div class="col-6 mb-1 bank-account">
        บัญชีรับเงิน : {{order.bankAccount.bank.name}} {{order.bankAccount.accountNumber}}
      </div>
      <div class="col-6 commission">
        ค่าคอมที่ได้รับ <span>{{order.sumNetCommissionAgency | number}}</span>
      </div>
      <div class="col-6 bank-account">ชื่อบัญชี : {{order.bankAccount.accountName}}</div>
    </div>
    <button class="btn btn-confirm" [disabled]="isSeller" (click)="confirm()">ยืนยันรับเงิน</button>
    <div class="sale-remark" *ngIf="isSeller">
      กรุณาแจ้งผู้มีสิทธิ์ยืนยันรับเงิน (เจ้าของ, ผู้จัดการ)
    </div>
  </div>
  <div class="footer">
    <div class="order-history">
      <span (click)="linkToOrderList('/report/order-balance-report' +order.id)" [routerLink]="[]">
        <img class="img-icon" src="assets/img/icon/icon-information.svg">
        ดูรายการ Order ของรอบโอนนี้
      </span>
    </div>
    <div class="download-document">
      <span (click)="downloadDocument()" *ngIf="order.transferDocFileUrl">
        <img class="img-icon" src="assets/img/icon/icon-file-download.svg">
        ดาวน์โหลดเอกสารการโอนเงิน
      </span>
    </div>
    <div class="order-date">(ซื้อระหว่างวันที่ {{order.orderFirstDate | thaidaterange : order.orderLastDate}})</div>
  </div>
</div>