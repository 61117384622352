export interface ILeadSummaryStatistics {
    id: number;
    lastCreatedAt: string;
    totalCount: number;
    pendingCount: number;
    confirmCount: number;
    cancelCount: number;
}

export class LeadSummaryStatistics {
    private leadStatistics: ILeadSummaryStatistics[] = [];

    public deserialize(objList: any): ILeadSummaryStatistics[] {
        objList.forEach((obj: any) => {
            this.leadStatistics.push(this.deserializeCurrentLead(obj));
        });
        return this.leadStatistics;
    }

    private deserializeCurrentLead(obj: any): ILeadSummaryStatistics {
        const currentLead: ILeadSummaryStatistics = {
            id: obj.agcy_product_id,
            lastCreatedAt: obj.b2b_lead_statistic.latest_created_at,
            totalCount: obj.b2b_lead_statistic.total_count,
            pendingCount: obj.b2b_lead_statistic.pending_count,
            confirmCount: obj.b2b_lead_statistic.confirm_count,
            cancelCount: obj.b2b_lead_statistic.cancel_count,
        };

        return currentLead;
    }
}
