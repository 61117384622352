import { ProductOwner } from 'src/app/models/product-owner';
import { NavigationEnd, Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { select, Store } from '@ngrx/store';
import { State } from 'src/app/stores/reducers';
import * as ProductActions from 'src/app/stores/product-store/product.actions';
import { filter, take } from 'rxjs/operators';
import { selectProductOwner } from 'src/app/stores/product-store/product.selectors';

@Component({
    selector: 'app-lead-booking-info',
    templateUrl: './lead-booking-info.component.html',
    styleUrls: ['./lead-booking-info.component.scss'],
})
export class LeadBookingInfoComponent implements OnInit {
    public id: number;
    public ownerChannel: string;
    public productOwner: ProductOwner;

    constructor(public bsModalRef: BsModalRef, private router: Router, private store: Store<State>) {}

    ngOnInit() {
        this.dispatchAndSubscribe();
        this.closeOnRouteChange();
    }

    private dispatchAndSubscribe(): void {
        this.store.dispatch(
            ProductActions.loadProductOwner({
                ownerChannel: this.ownerChannel,
                ownerId: this.id,
            })
        );

        this.store
            .pipe(
                select(selectProductOwner),
                filter((productOwner) => !!productOwner),
                take(1)
            )
            .subscribe((productOwner) => {
                this.productOwner = {
                    name: productOwner.nameTh,
                    email: productOwner.email,
                    address: productOwner.address,
                    licenseNumber: productOwner.licenseNumber,
                    telephoneNumber: productOwner.phoneNumber,
                    additionTelephoneNumber: productOwner.additionPhoneNumber,
                };
                this.store.dispatch(
                    ProductActions.loadProductOwnerSuccess({
                        productOwner: null,
                    })
                );
            });
    }

    closeOnRouteChange(): void {
        this.router.events.subscribe((evt) => {
            if (!(evt instanceof NavigationEnd)) {
                return;
            }
            this.bsModalRef.hide();
        });
    }
}
