import { Pipe, PipeTransform } from '@angular/core';
import { IPaymentEvent } from '../../../models/interfaces/tw-booking-interface/payment-event.interface';

@Pipe({
    name: 'paymentFilterFailure',
})
export class PaymentFilterFailurePipe implements PipeTransform {
    transform(value: IPaymentEvent[], ...args: any[]) {
        if (value && value.length > 0) {
            return value.filter((f) => f.event_type === 'failure');
        }

        return [];
    }
}
