import { createFeatureSelector, createSelector } from '@ngrx/store';
import * as fromUtility from './utility.state';
import { FEATURE_NAME } from '../utility-store/utility-store.module';

export const selectUtilityState = createFeatureSelector<fromUtility.UtilityState>(FEATURE_NAME);

export const selectCountries = createSelector(selectUtilityState, (state: fromUtility.UtilityState) => state.countries);

export const selectProvinces = createSelector(selectUtilityState, (state: fromUtility.UtilityState) => state.provinces);

export const selectCountrySubUnits = createSelector(
    selectUtilityState,
    (state: fromUtility.UtilityState) => state.countrySubUnits
);

export const selectTransportations = createSelector(
    selectUtilityState,
    (state: fromUtility.UtilityState) => state.transportations
);

export const selectCategoryTransports = createSelector(
    selectUtilityState,
    (state: fromUtility.UtilityState) => state.categoryTransports
);

export const selectGalleryResponse = createSelector(
    selectUtilityState,
    (state: fromUtility.UtilityState, props: { identifier: string }) => {
        if (state.galleryResponses.has(props.identifier)) {
            return state.galleryResponses.get(props.identifier);
        }
        return null;
    }
);

export const selectAboutUsDetails = createSelector(
    selectUtilityState,
    (state: fromUtility.UtilityState) => state.aboutUsResponse
);

export const selectAboutUsEditedResponse = createSelector(
    selectUtilityState,
    (state: fromUtility.UtilityState) => state.aboutUsEditedResponse
);

export const selectBanks = createSelector(selectUtilityState, (state: fromUtility.UtilityState) => state.banks);

export const selectProductSubCategories = createSelector(
    selectUtilityState,
    (state: fromUtility.UtilityState) => state.productSubCategories
);
