import { Country } from '../../../models/country';
import { Province } from '../../../models/province';

export class CampaignDetail {
    campaignId: number;
    typeSlug: string;
    campaignCode: string;
    name: string;
    sellStartDate: string;
    sellEndDate: string;
    voucherStartDate: string;
    voucherEndDate: string;
    voucherMaxQuantity: number;
    voucherQuantitySold: number;
    voucherQuantityRemaining: number;
    voucherMaxCommissionAgency: number;

    price: number;
    priceCompare: number;
    shopDescription: string;
    experienceDescription: string;
    voucherCondition: string;
    shopName: string;
    locationName: string;
    address: string;
    email?: any;
    openingTime?: any;
    googleMapLocationLatitude: string;
    googleMapLocationLongitude: string;
    officeTelephoneNumber?: any;
    additionalPhoneNumber?: any;
    mainBannerImageFileUrl: string;
    isActive: boolean;
    isExpired: boolean;
    updatedAt: string;
    shopImageFileUrl: string;
    shopBackgroundImageFileUrl: string;
    country: Country;
    province: Province;
    bannerImageFiles: BannerImageFile[];
    experienceImageFiles: BannerImageFile[];
    attachmentFiles: AttachmentFile[];
    vouchers: Voucher[];
    partner: Partner;
    hilightDescription: string;

    public deserialize(data: any): this {
        this.campaignId = data.campaign_id;
        this.typeSlug = data.type_slug;
        this.campaignCode = data.campaign_code;
        this.name = data.name;
        this.sellStartDate = data.sell_start_date;
        this.sellEndDate = data.sell_end_date;
        this.voucherStartDate = data.voucher_start_date;
        this.voucherEndDate = data.voucher_end_date;
        this.voucherMaxQuantity = data.voucher_max_quantity;
        this.voucherQuantitySold = data.voucher_quantity_sold;

        this.price = data.price;
        this.priceCompare = data.price_compare;
        this.shopDescription = data.shop_description;
        this.experienceDescription = data.experience_description;
        this.voucherCondition = data.voucher_condition;
        this.shopName = data.shop_name;
        this.locationName = data.location_name;
        this.address = data.address;
        this.email = data.email;
        this.openingTime = data.opening_time;
        this.googleMapLocationLatitude = data.google_map_location_latitude;
        this.googleMapLocationLongitude = data.google_map_location_longitude;
        this.officeTelephoneNumber = data.office_telephone_number;
        this.additionalPhoneNumber = data.additional_phone_number;
        this.mainBannerImageFileUrl = data.main_banner_image_file_url;
        this.isActive = data.is_active;
        this.isExpired = data.is_expired;
        this.updatedAt = data.updated_at;
        this.shopImageFileUrl = data.shop_image_file_url;
        this.shopBackgroundImageFileUrl = data.shop_background_image_file_url;
        this.country = data.country ? new Country().deserialize(data.country) : null;
        this.province = data.province ? new Province().deserialize(data.province) : null;
        this.bannerImageFiles = data.banner_image_files.map((m) => new BannerImageFile().deserialize(m));
        this.experienceImageFiles = data.experience_image_files.map((m) => new BannerImageFile().deserialize(m));
        this.attachmentFiles = data.attachment_files.map((m) => new AttachmentFile().deserialize(m));
        this.vouchers = data.vouchers.map((m) => new Voucher().deserialize(m));
        this.partner = new Partner().deserialize(data.partner);
        this.hilightDescription = data.hilight_description;
        return this;
    }
}

export class Voucher {
    id: number;
    name: string;
    sellStartDate: string;
    sellEndDate: string;
    voucherStartDate: string;
    voucherEndDate: string;
    price: number;
    priceCompare: number;
    commission?: number;
    maxQuantity?: number;
    quantityRemaining?: number;
    isActive: boolean;
    isExpired: boolean;
    mainBannerImageFileUrl: string;
    bannerImageFiles: BannerImageFile[];
    selected: boolean;
    discountPercentage: number;
    commissionAgency: number;
    description: string;

    public deserialize(data: any): this {
        this.id = data.id;
        this.name = data.name;
        this.sellStartDate = data.sell_start_date;
        this.sellEndDate = data.sell_end_date;
        this.voucherStartDate = data.voucher_start_date;
        this.voucherEndDate = data.voucher_end_date;
        this.price = +data.price;
        this.priceCompare = +data.price_compare;
        this.commission = data.commission;
        this.maxQuantity = data.max_quantity;
        this.quantityRemaining = data.quantity_remaining;
        this.isActive = data.is_active;
        this.isExpired = data.is_expired;
        this.mainBannerImageFileUrl = data.main_banner_image_file_url;
        this.bannerImageFiles = data.banner_image_files
            ? data.banner_image_files.map((m) => new BannerImageFile().deserialize(m))
            : [];
        this.bannerImageFiles = this.bannerImageFiles.filter((file) => !file.isMain);
        this.selected = false;
        this.discountPercentage = +data.discount_percentage;
        this.commissionAgency = +data.commission_agency;
        this.description = data.description;
        return this;
    }
}

export class BannerImageFile {
    id: number;
    ordinal: number;
    isMain: boolean;
    downloadUrl: string;
    previewUrl: string;
    thumbnailUrl: string;

    public deserialize(data: any): this {
        this.id = data.id;
        this.ordinal = data.ordinal;
        this.isMain = data.is_main;
        this.downloadUrl = data.download_url;
        this.previewUrl = data.preview_url;
        this.thumbnailUrl = data.thumbnail_url;
        return this;
    }
}

export class AttachmentFile {
    id: number;
    fileName: string;
    url: string;

    public deserialize(data: any): this {
        this.id = data.id;
        this.fileName = data.file_name;
        this.url = data.url;
        return this;
    }
}

export class Partner {
    id: number;
    nameTH: string;
    nameEN: string;
    email: string;
    address: string;
    officeTelephoneNumber: string;
    additionalPhoneNumber: string;

    public deserialize(data: any): this {
        this.id = data.id;
        this.nameTH = data.name_th;
        this.nameEN = data.name_en;
        this.email = data.email;
        this.address = data.address;
        this.officeTelephoneNumber = data.office_telephone_number;
        this.additionalPhoneNumber = data.additional_phone_number;
        return this;
    }
}
