import { Pipe, PipeTransform } from '@angular/core';
import {
    CATEGORY_SUB_PRODUCTS_FOR_TW_BOOKING,
    CATEGORY_SUB_PRODUCTS_TO_STRING,
} from '../constants/category-sub-product.constant';

@Pipe({
    name: 'productCategory',
})
export class ProductCategoryPipe implements PipeTransform {
    transform(value: any, ...args: any[]): any {
        const categoryName = CATEGORY_SUB_PRODUCTS_TO_STRING[value];
        return categoryName ? categoryName : '';
    }
}

@Pipe({
    name: 'productCategoryTwBooking',
})
export class ProductCategoryTwBookingPipe implements PipeTransform {
    transform(value: any, ...args: any[]): any {
        const categoryName = CATEGORY_SUB_PRODUCTS_FOR_TW_BOOKING[value];
        return categoryName ? categoryName : '';
    }
}
