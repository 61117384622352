import { OrderRoutingModule } from './order-routing.module';
import { SharedModule } from './../shared/shared.module';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

// pages
import { BookingListComponent } from './pages/booking-list/booking-list.component';
import { CampaignOrderListPageComponent } from './pages/campaign-order-list-page/campaign-order-list-page.component';
import { TwBookingListPageComponent } from './pages/tw-booking-list-page/tw-booking-list-page.component';
import { TwAddEditBookingPageComponent } from './pages/tw-add-edit-booking-page/tw-add-edit-booking-page.component';

// Components
import { BookingListDetailsComponent } from './components/booking-list-listing/booking-list-details/booking-list-details.component';
import { BookingListListingComponent } from './components/booking-list-listing/booking-list-listing.component';
import { BookingListTabsComponent } from './components/booking-list-tabs/booking-list-tabs.component';
import { ProductBoxBookingModalComponent } from './components/product-box-booking-modal/product-box-booking-modal.component';
import { BookingAdvanceSearchComponent } from './components/booking-advance-search/booking-advance-search.component';
import { SaleReportComponent } from './components/sale-report/sale-report.component';
import { SaleReportListingComponent } from './components/sale-report-listing/sale-report-listing.component';
import { SaleReportListDetailsComponent } from './components/sale-report-listing/sale-report-list-details/sale-report-list-details.component';
import { GroupPlanComponent } from './components/group-plan/group-plan.component';
import { GroupPlanFilterComponent } from './components/group-plan-filter/group-plan-filter.component';
import { CommissionReportFilterComponent } from './components/commission-report-filter/commission-report-filter.component';
import { CommissionReportComponent } from './components/commission-report/commission-report.component';
import { SearchOrderBoxComponent } from './components/search-order-box/search-order-box.component';
import { OrderListTableComponent } from './components/order-list-table/order-list-table.component';
import { ProgramOrderListResultComponent } from './components/program-order-list-result/program-order-list-result.component';
import { ProgramOrderListSearchComponent } from './components/program-order-list-search/program-order-list-search.component';
import { InstallmentItemComponent } from './pages/tw-add-edit-booking-page/partials/installment-item/installment-item.component';
import { SkuRowComponent } from './pages/tw-add-edit-booking-page/partials/sku-row/sku-row.component';
import { TwBookingListResultComponent } from './components/tw-booking-list-result/tw-booking-list-result.component';

// Modals
import { LeadBookingInfoComponent } from './modals/lead-booking-info/lead-booking-info.component';
import { AddBookingB2cComponent } from './modals/add-booking-b2c/add-booking-b2c.component';
import { BookingOrderInstallmentsComponent } from './modals/add-booking-b2c/booking-order-installments/booking-order-installments.component';
import { BookingOrderItemsComponent } from './modals/add-booking-b2c/booking-order-items/booking-order-items.component';
import { CancelBookingComponent } from './modals/cancel-booking/cancel-booking.component';
import { ChangeBookingStatusComponent } from './modals/change-booking-status/change-booking-status.component';
import { CloseAddBookingB2cWarningComponent } from './modals/close-add-booking-b2c-warning/close-add-booking-b2c-warning.component';
import { EditBookingB2cComponent } from './modals/edit-booking-b2c/edit-booking-b2c.component';
import { CloseAdvancedSearchWarningComponent } from './modals/close-advanced-search-warning/close-advanced-search-warning.component';
import { CommissionDisabledComponent } from './modals/commission-disabled/commission-disabled.component';
import { ProgramOrderListPageComponent } from './pages/program-order-list-page/program-order-list-page.component';
import { ProgramToursFilterSelectComponent } from './pages/tw-add-edit-booking-page/partials/program-tours-filter-select/program-tours-filter-select.component';
import { SkuListFormComponent } from './pages/tw-add-edit-booking-page/partials/sku-list-form/sku-list-form.component';
import { CustomerInfoFormComponent } from './pages/tw-add-edit-booking-page/partials/customer-info-form/customer-info-form.component';
import { BookingDetailPreviewComponent } from './pages/tw-add-edit-booking-page/partials/booking-detail-preview/booking-detail-preview.component';
import { WholesalePaymentDetailModalComponent } from './pages/tw-add-edit-booking-page/modals/wholesale-payment-detail-modal/wholesale-payment-detail-modal.component';
import { CancelTwBookingComponent } from './modals/cancel-tw-booking/cancel-tw-booking.component';
import { ProgramPeriodNotFoundModalComponent } from './pages/tw-add-edit-booking-page/modals/program-period-not-found-modal/program-period-not-found-modal.component';
import { TextAreaModalModule } from 'src/app/shared/modals/text-area-modal/text-area-modal.module';
import { DropdownModalModule } from 'src/app/shared/modals/dropdown-modal/dropdown-modal.module';
import { SearchTwBookingBoxComponent } from './components/search-tw-booking-box/search-tw-booking-box.component';
import { WorkListModalComponent } from './modals/work-list-modal/work-list-modal.component';
import { WorklistPipe } from './pipes/worklist-pipe.pipe';
import { TimepickerModule } from 'ngx-bootstrap/timepicker';
import { PaymentMethodPipeModule } from 'src/app/directives/texts/payment-method-pipe.module';

@NgModule({
    declarations: [
        // Pages
        BookingListComponent,
        CampaignOrderListPageComponent,
        ProgramOrderListPageComponent,
        TwBookingListPageComponent,
        TwAddEditBookingPageComponent,
        // Components
        ProductBoxBookingModalComponent,
        BookingListTabsComponent,
        BookingListListingComponent,
        BookingListDetailsComponent,
        BookingAdvanceSearchComponent,
        SaleReportComponent,
        SaleReportListingComponent,
        SaleReportListDetailsComponent,
        GroupPlanComponent,
        GroupPlanFilterComponent,
        CommissionReportFilterComponent,
        CommissionReportComponent,
        SearchOrderBoxComponent,
        OrderListTableComponent,
        ProgramOrderListSearchComponent,
        ProgramOrderListResultComponent,
        ProgramToursFilterSelectComponent,
        SkuListFormComponent,
        CustomerInfoFormComponent,
        BookingDetailPreviewComponent,
        InstallmentItemComponent,
        SkuRowComponent,
        TwBookingListResultComponent,
        SearchTwBookingBoxComponent,
        // Modals
        AddBookingB2cComponent,
        CloseAddBookingB2cWarningComponent,
        BookingOrderItemsComponent,
        BookingOrderInstallmentsComponent,
        LeadBookingInfoComponent,
        ChangeBookingStatusComponent,
        CancelBookingComponent,
        CancelTwBookingComponent,
        EditBookingB2cComponent,
        CloseAdvancedSearchWarningComponent,
        CommissionDisabledComponent,
        WholesalePaymentDetailModalComponent,
        ProgramPeriodNotFoundModalComponent,
        WorkListModalComponent,
        // Pipes
        WorklistPipe,
    ],
    imports: [
        CommonModule,
        SharedModule,
        OrderRoutingModule,
        TextAreaModalModule,
        DropdownModalModule,
        TimepickerModule,
        PaymentMethodPipeModule,
    ],
    entryComponents: [
        AddBookingB2cComponent,
        CloseAddBookingB2cWarningComponent,
        LeadBookingInfoComponent,
        ChangeBookingStatusComponent,
        EditBookingB2cComponent,
        CloseAddBookingB2cWarningComponent,
        CommissionDisabledComponent,
    ],
})
export class OrderModule {}
