export interface IGroupPlanList {
    page: number;
    pageEntries: number;
    total: number;
    groupPlanStats: IGroupPlanStats;
    groupPlans: IGroupPlan[];
}

export interface IGroupPlanStats {
    orderCountAlreadyStarted: number;
    orderCountNotStarted: number;
    allOrderSumQuantity: number;
}

export interface IGroupPlan {
    orderIds: number[];
    proCategoryProductsId: number;
    proCategorySubProductsId: number;
    productsId: number;
    productOwnerChannel: string;
    tourCode: string;
    productName: string;
    startAt: Date;
    orderSumTotalPrice: number;
    orderSumQuantity: number;
    orderCount: number;
    productOwner: IProductOwner;
}

export interface IProductOwner {
    id: number;
    channel: 'ws' | 'ag';
    nameTH: string;
    nameEN: string;
    phoneNumber: string;
    productCode: string;
}

export class GroupPlanList implements IGroupPlanList {
    page: number;
    pageEntries: number;
    total: number;
    groupPlanStats: IGroupPlanStats;
    groupPlans: IGroupPlan[];

    deserialize(source: any) {
        this.page = source.data.page;
        this.pageEntries = source.data.page_entries;
        this.total = source.data.total;
        this.groupPlanStats = new GroupPlanStats().deserialize(source.data.stats);
        this.groupPlans = source.data.result.map((item) => new GroupPlan().deserialize(item));
        return this;
    }
}
export class GroupPlanStats implements IGroupPlanStats {
    orderCountAlreadyStarted: number;
    orderCountNotStarted: number;
    allOrderSumQuantity: number;

    deserialize(source: any) {
        this.orderCountAlreadyStarted = source.order_count_already_started;
        this.orderCountNotStarted = source.order_count_not_started;
        this.allOrderSumQuantity = source.all_order_sum_quantity;
        return this;
    }
}
export class GroupPlan implements IGroupPlan {
    orderIds: number[];
    proCategoryProductsId: number;
    proCategorySubProductsId: number;
    productsId: number;
    productOwnerChannel: string;
    tourCode: string;
    productName: string;
    startAt: Date;
    orderSumTotalPrice: number;
    orderSumQuantity: number;
    orderCount: number;
    productOwner: IProductOwner;

    deserialize(source: any) {
        this.orderIds = source.order_ids;
        this.proCategoryProductsId = source.pro_category_products_id;
        this.proCategorySubProductsId = source.pro_category_sub_products_id;
        this.productsId = source.products_id;
        this.productOwnerChannel = source.product_owner_channel;
        this.tourCode = source.tour_code;
        this.productName = source.product_name;
        this.startAt = new Date(source.start_at);
        this.orderSumTotalPrice = source.order_sum_total_price;
        this.orderSumQuantity = source.order_sum_quantity;
        this.orderCount = source.order_count;
        this.productOwner = new ProductOwner().deserialize(source.product_owner);
        return this;
    }
}

export class ProductOwner implements IProductOwner {
    id: number;
    channel: 'ws' | 'ag';
    nameTH: string;
    nameEN: string;
    phoneNumber: string;
    productCode: string;

    deserialize(source: any) {
        this.id = source.id;
        this.channel = source.channel;
        this.nameTH = source.name_th;
        this.nameEN = source.name_en;
        this.phoneNumber = source.phone_number;
        this.productCode = source.product_code;
        return this;
    }
}
