<div class="modal-header">
    <button type="button" class="close pull-right" aria-label="Close" (click)="bsModalRef.hide()">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body">
    <div class="top-grey-part">
        <div class="title-tab">
            <h3 class="icon-status">แก้ไข Booking</h3>
            <div class="sales-dropdown">
                <label>เซลล์ผู้ขาย</label>
                <app-searchable-dropdown
                    #salesDropdown
                    [settings]="salesDropdownSettings"
                    [items]="salesDropdownItems"
                    (selected)="onSelectedSales($event)"
                ></app-searchable-dropdown>
            </div>
        </div>
        <div class="tour-information">
            <div class="row mt-2">
                <div class="left-side col-8">
                    <div class="row">
                        <div class="col-6">
                            <label>โปรแกรมจาก <span class="red">*</span></label>
                            <app-searchable-dropdown
                                #partnersDropdown
                                [settings]="partnersDropdownSettings"
                                [items]="partnersDropdownItems"
                                [unclickable]="true"
                            ></app-searchable-dropdown>
                        </div>
                        <div class="col-6">
                            <label>ช่วงเวลาเดินทาง <span class="red">*</span></label>
                            <div class="d-flex align-items-center">
                                <div class="calendar-container">
                                    <app-calendar-input
                                        #startDateCalendarInput
                                        [bsValue]="fieldStartDate"
                                        [forAddBookingModalStyling]="true"
                                        (bsValueChange)="setStartAt($event)"
                                        [maxDate]="fieldEndDate"
                                        [valid]="validFields.startDate"
                                        [showIcon]="false"
                                        placeholder="เลือกวันไป"
                                    ></app-calendar-input>
                                </div>
                                <label class="mx-3">ถึง</label>
                                <div class="calendar-container">
                                    <app-calendar-input
                                        #endDateCalendarInput
                                        [bsValue]="fieldEndDate"
                                        [forAddBookingModalStyling]="true"
                                        (bsValueChange)="setEndAt($event)"
                                        [minDate]="fieldStartDate"
                                        [valid]="validFields.endDate"
                                        [showIcon]="false"
                                        placeholder="เลือกวันกลับ"
                                    ></app-calendar-input>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row mt-2 position-relative">
                        <div [ngClass]="isCustomProductSelected ? 'col-6' : 'col-12'">
                            <label>โปรแกรมทัวร์ <span class="red">*</span></label>
                            <app-searchable-dropdown
                                #productsDropdown
                                [settings]="productsDropdownSettings"
                                [items]="productsDropdownItems"
                                [unclickable]="true"
                            ></app-searchable-dropdown>
                        </div>
                        <ng-container *ngIf="isCustomProductSelected">
                            <div class="col-3">
                                <label>คอมบริษัท</label>
                                <input
                                    [(ngModel)]="editBookingB2c.product.commission_company"
                                    (change)="validate()"
                                    name="commission-company"
                                    type="text"
                                    class="form-control"
                                    placeholder="คอมบริษัท"
                                    maxlength="6"
                                    appOnlyNumber
                                    [appMycurrency]="{fractionSize: 0}"
                                    [class.red-border]="!validFields.commissionCompany"
                                />
                            </div>
                            <div class="col-3">
                                <label>คอมเซลล์</label>
                                <input
                                    [(ngModel)]="editBookingB2c.product.commission_seller"
                                    (change)="validate()"
                                    name="commission-seller"
                                    type="text"
                                    class="form-control"
                                    placeholder="คอมเซลล์"
                                    maxlength="6"
                                    appOnlyNumber
                                    [appMycurrency]="{fractionSize: 0}"
                                    [class.red-border]="!validFields.commissionSeller"
                                />
                            </div>
                            <p
                                class="commission-error error-message"
                                *ngIf="!validFields.commissionCompany || !validFields.commissionSeller"
                            >
                                หากท่านต้องการกรอกค่าคอม กรุณากรอกมากกว่า 0
                            </p>
                        </ng-container>
                    </div>
                </div>
                <div class="right-side col-4">
                    <app-product-box-booking-modal
                        *ngIf="!isCustomProductSelected"
                        (isRedirecting)="disableWarningModal()"
                        [orderDetail]="orderDetail"
                    >
                    </app-product-box-booking-modal>
                    <ng-container *ngIf="isCustomProductSelected">
                        <label>รายละเอียด <span class="red">*</span></label>
                        <textarea
                            [(ngModel)]="editBookingB2c.product.custom_product_name"
                            (change)="validate()"
                            name="custom-product-name"
                            type="text"
                            class="form-control"
                            placeholder="กรุณากรอกข้อมูลสินค้า"
                            maxlength="200"
                            [class.red-border]="!validFields.customName"
                        >
                        </textarea>
                    </ng-container>
                </div>
            </div>
        </div>
        <div class="contact-information">
            <label>ข้อมูลผู้ติดต่อ</label>
            <div class="row">
                <div class="col-7">
                    <div class="row">
                        <div class="col-6">
                            <label>ชื่อผู้ติดต่อ (ผู้จอง) <span class="red">*</span></label>
                            <input
                                [(ngModel)]="editBookingB2c.contact.contact_name"
                                (change)="validate()"
                                name="contact-name"
                                type="text"
                                class="form-control"
                                placeholder="ชื่อผู้ติดต่อ (ผู้จอง)"
                                maxlength="200"
                                [class.red-border]="!validFields.customerName"
                            />
                            <label>ช่องทางการติดต่อ</label>
                            <app-searchable-dropdown
                                #communicationDropdown
                                [settings]="communicationDropdownSettings"
                                [items]="communicationDropdownItems"
                                (selected)="onSelectCommunicationMethod($event)"
                            ></app-searchable-dropdown>
                        </div>
                        <div class="col-6">
                            <label>เบอร์โทร</label>
                            <input
                                [(ngModel)]="editBookingB2c.contact.phone_number"
                                appOnlyNumber
                                name="phone-number"
                                type="tel"
                                class="form-control"
                                placeholder="กรอกเบอร์โทร"
                                maxlength="10"
                            />
                            <label>อีเมล</label>
                            <input
                                [(ngModel)]="editBookingB2c.contact.email"
                                name="email"
                                type="email"
                                class="form-control"
                                placeholder="กรอกอีเมล"
                                maxlength="100"
                            />
                        </div>
                    </div>
                </div>
                <div class="col-5">
                    <label>ข้อมูลเพิ่มเติมจากลูกค้า (Remark)</label>
                    <textarea
                        [(ngModel)]="editBookingB2c.contact.customer_remark"
                        name="remark"
                        type="text"
                        class="form-control"
                        placeholder="กรอกข้อมูลเพิ่มเติม"
                        maxlength="500"
                    >
                    </textarea>
                </div>
            </div>
        </div>
    </div>
    <div class="bottom-part row">
        <div class="items-list col-7 pl-1">
            <app-booking-order-items
                *ngIf="orderDetail"
                [isEdit]="true"
                [bookingOrderItems]="editBookingB2c.order_items"
                [validFields]="validFieldsOrderItems"
                (bookingOrderItemsEmitter)="setOrderItemsFromChild($event)"
            ></app-booking-order-items>
        </div>
        <div class="installments-list col-5">
            <app-booking-order-installments
                *ngIf="orderDetail"
                [isEdit]="true"
                [bookingOrderInstallments]="editBookingB2c.order_installments"
                [validFields]="validFieldsOrderInstallments"
                (bookingOrderInstallmentsEmitter)="setOrderInstallmentsFromChild($event)"
            ></app-booking-order-installments>
        </div>
    </div>
</div>
<div class="modal-footer position-relative">
    <p *ngIf="showFormErrorMessage" class="error-message general-error">กรุณากรอกข้อมูลให้ครบถ้วน</p>
    <div class="button-container">
        <button type="button" class="btn btn-primary" (click)="submit()">บันทึก</button>
        <button type="button" class="btn btn-default" (click)="bsModalRef.hide()">ยกเลิก</button>
    </div>
</div>
