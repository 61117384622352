import { Country } from '../country';
import { IWholesaleProduct } from '../interfaces/tourwow-order/wholesale-product.interface';

export class WholesaleProduct {
    id: number;
    tourCode: string;
    name: string;
    durationDay: number;
    durationNight: number;

    bannerUrl: string;
    productDocumentUrl: string;
    productPdfUrl: string;
    countries: Country[];

    ownerSupplier: {
        id: number;
        productTwpInvoiceInstallmentFirst: number; // กำหนดวันชำระเงินงวดแรก หลังจาก Create Order
        productTwpInvoiceInstallmentSecond: number; // กำหนดวันชำระเงินงวดที่สอง ก่อนเดินทาง
    };

    constructor() {
        this.ownerSupplier = {
            id: null,
            productTwpInvoiceInstallmentFirst: null,
            productTwpInvoiceInstallmentSecond: null,
        };
    }

    deserialize(data: IWholesaleProduct): WholesaleProduct | null {
        if (!data) {
            return null;
        }

        this.id = data.id;
        this.tourCode = data.tour_code;
        this.name = data.name;
        this.durationDay = data.duration_day;
        this.durationNight = data.duration_night;

        this.bannerUrl = data.banner_file_url;
        this.productDocumentUrl = data.product_doc_file_url;
        this.productPdfUrl = data.product_pdf_file_url;
        this.countries = data.countries.map((country) => new Country().deserialize(country));

        this.ownerSupplier.id = data.owner_supplier ? data.owner_supplier.id : null;
        this.ownerSupplier.productTwpInvoiceInstallmentFirst = data.owner_supplier
            ? data.owner_supplier.product_twp_invoice_installment_first
            : null;
        this.ownerSupplier.productTwpInvoiceInstallmentSecond = data.owner_supplier
            ? data.owner_supplier.product_twp_invoice_installment_second
            : null;

        return this;
    }

    get countryList(): string {
        let ret = '';
        this.countries.forEach((element, index) => {
            if (index == 0) {
                ret = element.nameTh;
            } else {
                ret += ' - ' + element.nameTh;
            }
        });

        return ret;
    }
}
