<div class="modal-header">
    <button type="button" class="close pull-right" aria-label="Close" (click)="bsModalRef.hide()">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="top-wrapper">
        <h4 class="modal-title">ส่งใบจอง</h4>
        <small class="title-description">(ข้อมูลนี้จะถูกส่งไปให้ Wholesale)</small>
</div>
<div class="modal-body">
    <div class="row">
        <div class="col-8">
            <div class="tour-details">
                <p class="title">Tour code :
                    <span class="tourcode">
                        <ng-container *ngIf="lead.tour_code; else noTourCodeTemplate">
                            <span>{{ (agencyInfo$ | async).productCode | uppercase }}</span>
                            <span>-</span>
                            <span>{{lead.tour_code | uppercase}}</span>
                        </ng-container>
                        <ng-template #noTourCodeTemplate>
                            -
                        </ng-template>
                    </span>
                </p>
                <p class="product-name">{{lead.product_name}}</p>
            </div>
            <div class="booking-details" [formGroup]="form">
                <table>
                    <tr>
                        <th>พีเรียด</th>
                        <th>ราคาเริ่มต้น/คน</th>
                        <th>{{lead.commissionLabel}}</th>
                        <th>จำนวนผู้เดินทาง<span class="color-red">*</span></th>
                    </tr>
                    <tr>
                        <td>{{period || '-'}}</td>
                        <td>{{(lead.price | number) || '-'}}</td>
                        <td>{{lead.commissionString || '-'}}</td>
                        <td>
                            <input appOnlyNumber type="number" min="0" step="1" class="form-control" [class.error]="requiredPassengerField"
                            placeholder="กรุณากรอกจำนวน" formControlName="passengerNo" maxlength="10">
                            <small *ngIf="requiredPassengerField" class="error-message">กรุณากรอกจำนวนผู้เดินทาง</small>
                        </td>
                    </tr>
                </table>
                <div class="textarea-container">
                    <label class="col-form-label">
                        ข้อมูลเพิ่มเติมที่ต้องการแจ้ง Wholesale
                    </label>
                    <textarea type="text" class="form-control" placeholder="กรุณากรอกข้อมูลเพิ่มเติม"
                    formControlName="additionalInfo"></textarea>
                </div>
            </div>
        </div>
        <div class="col-4">
            <div class="owner-info">
                <p class="title">แจ้งการจองไปยัง :</p>
                <ng-container *ngIf="lead.owner; else noWsOwner">
                    <p class="name name-en">{{lead.owner?.name_en}}</p>
                    <p class="name name-th">({{lead.owner?.name_th}})</p>
                </ng-container>
                <ng-template #noWsOwner>
                    <p class="name name-en">{{lead.agency_name_en}}</p>
                    <p class="name name-th">({{lead.agency_name_th}})</p>
                </ng-template>
            </div>
            <div class="agency-info">
                <p class="agency-name"><span>ข้อมูลผู้แจ้ง : </span><span>{{agencyInfo.nameEN}} ({{agencyInfo.nameTH}})</span></p>
                <p>เลขที่ใบอนุญาตนำเที่ยว {{agencyInfo.licenseNumber}}</p>
                <p>เซลล์ : {{user.firstName}} {{user.lastName}} {{user.nickName ? '(' + user.nickName + ")" : ''}}</p>
                <p>
                    <span>โทร : {{agencyInfo.phoneNumber | phoneNumber}}</span>
                    <span *ngIf="user.mobileNumber">, {{ user.mobileNumber | phoneNumber }}</span>
                </p>
                <p class="agency-email">อีเมล : {{agencyInfo.email}}</p>
                <p class="agency-address"><span>ที่อยู่ : </span><span>{{agencyInfo.address}}</span></p>
            </div>
            <div class="row button-container">
                <div class="col-6">
                    <button type="button" class="btn btn-primary" (click)="submitButton()">ส่งข้อมูล</button>
                </div>
                <div class="col-6">
                    <button type="button" class="btn btn-default" (click)="bsModalRef.hide()">ยกเลิก</button>
                </div>
            </div>
        </div>
    </div>
</div>