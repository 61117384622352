import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';

@Component({
    selector: 'app-tags-input',
    templateUrl: './tags-input.component.html',
    styleUrls: ['./tags-input.component.scss'],
})
export class TagsInputComponent {
    @Input() tags: string[] = [];
    @Input() placeholder: string;
    @Output() tagChange = new EventEmitter<string[]>();
    @ViewChild('inputTag') inputTag: ElementRef;

    tag: string = null;

    constructor() {}

    onEnter(event: any) {
        let value: string = event.target.value;
        value = value.trim();
        value = value.replace(/,/g, '');
        if (!value) {
            return;
        }
        if (!this.tags.some((t) => t === value)) {
            this.tags.push(value);
            this.tagChange.emit(this.tags);
        }
        this.tag = null;
    }

    removeTag(tag) {
        this.tags = this.tags.filter((t) => t !== tag);
        this.tagChange.emit(this.tags);
    }

    focusInputTag() {
        this.inputTag.nativeElement.focus();
    }

    getInputTagSize(): number {
        const minSize = this.placeholder.length;
        let size = 0;
        if (this.inputTag) {
            size = this.inputTag.nativeElement.value.length * 1.1;
        }
        return Math.max(size, minSize);
    }
}
