import { HttpService } from './base/http.service';

import { ENDPOINTS } from '../constants/endpoint.constant';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable()
export class CountryService {
    constructor(private httpService: HttpService) {}

    getAllCountries(queries?: any): Observable<any> {
        const queryString = this.httpService.getQueryString(queries);
        const url = environment.apiProUrl + ENDPOINTS.COUNTRY.GET_ALL + '?' + queryString;
        return this.httpService.get(url, null);
    }
}
