import { IDeserializable } from 'src/app/models/interfaces/deserializable';
import { UuIdUtil } from 'src/app/utils/uuid.util';
import { IBookingListQueries } from './booking-list.model';

export interface ISaleReportSummaryQueries {
    intervalDirection?: 'backward' | 'forward';
    intervalLimit?: number;
    intervalType?: 'month';
}

export type ISaleReportListPaginatedQueries = IBookingListQueries;

export class SaleReportSummary implements IDeserializable {
    deserialize(source: any) {
        this.id = UuIdUtil.generate();
        if (source.date_range) {
            this.minDate = source.date_range.min_date;
            this.maxDate = source.date_range.max_date;
        }
        this.totalPrice = source.sum_total_price;
        this.totalOrder = source.order_count;
        return this;
    }
    id: string;
    minDate: string;
    maxDate: string;
    totalPrice: number;
    totalOrder: number;
}

export class SaleReportListPaginated implements IDeserializable {
    deserialize(source: any) {
        this.currentPage = source.page;
        this.itemPerPage = source.page_entries;
        this.totalReports = source.total;
        this.totalSumPrice = source.stats?.all_order_sum_total_price;
        this.items = source.result?.map((x) => new SaleReportItem().deserialize(x));

        return this;
    }
    currentPage: number;
    itemPerPage: number;
    totalReports: number;
    totalSumPrice: number;
    items: SaleReportItem[];
}

export class SaleReportItem implements IDeserializable {
    deserialize(source: any) {
        this.id = source.id;
        this.orderCode = source.order_code;
        this.orderStatus = source.order_status;
        this.categoryId = source.pro_category_products_id;
        this.categorySubId = source.pro_category_sub_products_id;
        this.productId = source.products_id;
        this.tourCode = source.tour_code;
        this.productName = source.product_name;
        this.startDate = source.start_at;
        this.endDate = source.end_at;
        this.commissionCompany = source.commission_company;
        this.commissionSeller = source.commission_seller;
        this.contactName = source.contact_name;
        this.phoneNumber = source.phone_number;
        this.emailAddress = source.email;
        this.leadType = source.lead_type_slug;
        this.remark = source.customer_remark;
        this.totalSumQuantity = source.sum_quantity;
        this.totalSumPrice = source.sum_total_price;
        this.created = source.created_at;
        this.productOwnerId = source.product_owner?.id;
        this.productOwnerNameTh = source.product_owner?.name_th;
        this.productOwnerNameEn = source.product_owner?.name_en;
        this.productOwnerPhoneNumber = source.product_owner?.phonenumber;
        this.productOwnerChannelType = source.product_owner?.channel;
        this.productOwnerProductCode = source.product_owner?.product_code;
        this.sellerId = source.seller_agency_member?.id;
        this.sellerFirstName = source.seller_agency_member?.first_name;
        this.sellerLastName = source.seller_agency_member?.last_name;
        this.sellerNickName = source.seller_agency_member?.nick_name;

        this.productOwner = new ProductOwner().deserialize(source.product_owner);

        this.sellerFullName = this.sellerFirstName;
        if (this.sellerLastName) {
            this.sellerFullName += ` ${this.sellerLastName}`;
        }
        if (this.sellerNickName) {
            this.sellerFullName += ` (${this.sellerNickName})`;
        }

        return this;
    }
    id: number;
    orderCode: string;
    orderStatus: number;
    categoryId: number;
    categorySubId: number;
    productId: number;
    tourCode: string;
    productName: string;
    startDate: string;
    endDate: string;
    commissionCompany: number;
    commissionSeller: number;
    contactName: string;
    phoneNumber: string;
    emailAddress: string;
    leadType: string;
    remark: string;
    totalSumQuantity: number;
    totalSumPrice: number;
    created: string;
    productOwnerId: number;
    productOwnerNameTh: string;
    productOwnerNameEn: string;
    productOwnerPhoneNumber: string;
    productOwnerChannelType: string;
    productOwnerProductCode: string;
    sellerId: number;
    sellerFirstName: string;
    sellerLastName: string;
    sellerNickName: string;

    productOwner: IProductOwner;
    sellerFullName: string;
}

export interface IProductOwner {
    id: number;
    channel: 'ws' | 'ag';
    nameTH: string;
    nameEN: string;
    phoneNumber: string;
    productCode: string;
}

export class ProductOwner implements IProductOwner {
    id: number;
    channel: 'ws' | 'ag';
    nameTH: string;
    nameEN: string;
    phoneNumber: string;
    productCode: string;

    deserialize(source: any) {
        this.id = source.id;
        this.channel = source.channel;
        this.nameTH = source.name_th;
        this.nameEN = source.name_en;
        this.phoneNumber = source.phone_number;
        this.productCode = source.product_code;
        return this;
    }
}
