import { ProductNameList } from 'src/app/models/product-name-list';
import { Injectable } from '@angular/core';

import { Actions, ofType, createEffect } from '@ngrx/effects';
import { catchError, map, concatMap, switchMap, tap, mergeMap } from 'rxjs/operators';
import { of } from 'rxjs';

import * as ProductActions from './product.actions';

import { ProductCatalogService as ProductService } from 'src/app/services/product.service';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { ProductList } from 'src/app/models/product-list';
import { JoinProduct } from 'src/app/modules/product/models/join-product.model';
import { DomesticProduct } from 'src/app/modules/product/models/domestic-product.model';
import { PackageProduct } from 'src/app/modules/product/models/package-product.model';
import { ProductDataFromSearchString } from 'src/app/modules/report/models/product-data-from-search-string.model';
import { ProductStoreModalService } from 'src/app/services/modal/product-store-modal.service';
import { ProductPdfDetailFileService } from 'src/app/services/product-pdf-detail-file.service';
import { IProductPdfDetailFile } from 'src/app/models/product-pdf-detail-file.model';
import { ProductOwnerService } from 'src/app/services/product-owner.service';
import { Owner } from 'src/app/models/owner';
import { CampaignService } from 'src/app/services/campaign.service';
import { CampaignDetail } from 'src/app/modules/product/models/campaign-detail.model';

@Injectable()
export class ProductEffects {
    constructor(
        private actions$: Actions,
        private productService: ProductService,
        private productOwnerService: ProductOwnerService,
        private productPdfDetailFileService: ProductPdfDetailFileService,
        private ngxService: NgxUiLoaderService,
        private productStoreModalService: ProductStoreModalService,
        private campaignService: CampaignService
    ) {}

    loadProductMonth = createEffect(
        () => () =>
            this.actions$.pipe(
                ofType(ProductActions.loadProductMonth),
                switchMap((action) => {
                    return this.productService
                        .getProductMonth(action.categorySubProductIds, action.fromProductPool)
                        .pipe(
                            map((resp) => {
                                if (resp.status !== 'success') {
                                    return ProductActions.loadProductMonthFailure(resp);
                                }
                                return ProductActions.loadProductMonthSuccess({ data: resp.data });
                            }),
                            catchError((error) => {
                                return of(ProductActions.loadProductMonthFailure(error));
                            })
                        );
                })
            )
    );

    loadProductCountOfAgency = createEffect(
        () => () =>
            this.actions$.pipe(
                ofType(ProductActions.loadProductCountOfAgency),
                concatMap(() => {
                    this.ngxService.start();
                    return this.productService.getProductCountOfAgency().pipe(
                        map((resp) => {
                            this.ngxService.stop();
                            if (resp.status !== 'success') {
                                return ProductActions.loadProductCountOfAgencyFailure(resp);
                            }
                            return ProductActions.loadProductCountOfAgencySuccess({ data: resp.data });
                        }),
                        catchError((error) => {
                            this.ngxService.stop();
                            return of(ProductActions.loadProductCountOfAgencyFailure(error));
                        })
                    );
                })
            )
    );

    loadNewProductListing = createEffect(
        () => () =>
            this.actions$.pipe(
                ofType(ProductActions.loadNewProductListing),
                switchMap((action) => {
                    this.ngxService.start();
                    return this.productService.getProductList(action.query).pipe(
                        map((resp) => {
                            this.ngxService.stop();
                            const productList = resp.result.data.map((m) => {
                                if (action.query.fromProductPool) {
                                    return new ProductList().deserializePoolData(m);
                                }

                                return new ProductList().deserialize(m);
                            });

                            return ProductActions.loadProductListingSuccess({
                                productList,
                                total: resp.total,
                                page: resp.page,
                                refresh: true,
                            });
                        }),
                        catchError((error) => {
                            this.ngxService.stop();
                            return of(ProductActions.loadProductListingFailure(error));
                        })
                    );
                })
            )
    );

    loadProductListing = createEffect(
        () => () =>
            this.actions$.pipe(
                ofType(ProductActions.loadProductListing),
                switchMap((action) => {
                    this.ngxService.start();
                    return this.productService.getProductList(action.query).pipe(
                        map((resp) => {
                            this.ngxService.stop();
                            const productList = resp.result.data.map((m) => {
                                if (action.query.fromProductPool) {
                                    return new ProductList().deserializePoolData(m);
                                }

                                return new ProductList().deserialize(m);
                            });

                            return ProductActions.loadProductListingSuccess({
                                productList,
                                total: resp.total,
                                page: resp.page,
                                refresh: false,
                            });
                        }),
                        catchError((error) => {
                            this.ngxService.stop();
                            return of(ProductActions.loadProductListingFailure(error));
                        })
                    );
                })
            )
    );

    loadProduct = createEffect(
        () => () =>
            this.actions$.pipe(
                ofType(ProductActions.loadProduct),
                switchMap((action) => {
                    this.ngxService.start();
                    return this.productService.getProduct(action.productId).pipe(
                        map((response) => {
                            if (response.status == 'success') {
                                this.ngxService.stop();
                                return ProductActions.loadProductSuccess({
                                    product: new JoinProduct().deserialize(response.data),
                                });
                            } else if (response.status == 'error') {
                                this.ngxService.stop();
                                return ProductActions.loadProductFailure({
                                    error: response.error,
                                });
                            }
                        }),
                        catchError((error) => {
                            this.ngxService.stop();
                            return of(ProductActions.loadProductFailure(error));
                        })
                    );
                })
            )
    );

    deleteProduct = createEffect(
        () => () =>
            this.actions$.pipe(
                ofType(ProductActions.deleteProduct),
                mergeMap((action) => {
                    this.ngxService.start();
                    return this.productService.deleteProduct(action).pipe(
                        map((response) => {
                            this.ngxService.stop();
                            return ProductActions.deleteProductSuccess({
                                response,
                            });
                        }),
                        catchError((error) => {
                            this.ngxService.stop();
                            return of(ProductActions.deleteProductFailure(error));
                        })
                    );
                })
            )
    );

    loadProductView = createEffect(
        () => () =>
            this.actions$.pipe(
                ofType(ProductActions.loadProductView),
                switchMap((action) => {
                    this.ngxService.start();
                    return this.productService.getProductView(action.criteria).pipe(
                        map((resp) => {
                            this.ngxService.stop();
                            if (resp.status !== 'success') {
                                return ProductActions.loadProductViewFailure(resp);
                            }

                            return ProductActions.loadProductViewSuccess(resp.data);
                        }),
                        catchError((error) => {
                            this.ngxService.stop();
                            return of(ProductActions.loadProductViewFailure(error));
                        })
                    );
                })
            )
    );

    updateProductPoolRecommendation = createEffect(
        () => () =>
            this.actions$.pipe(
                ofType(ProductActions.updateProductPoolRecommendation),
                switchMap((action) => {
                    this.ngxService.start();
                    return this.productService
                        .updateProductPoolRecommendation(action.productPoolId, action.isRecommended)
                        .pipe(
                            map((resp) => {
                                this.ngxService.stop();
                                if (resp.status == 'success') {
                                    return ProductActions.updateProductPoolRecommendationSuccess({
                                        response: resp,
                                        productPoolId: action.productPoolId,
                                        isRecommended: action.isRecommended,
                                    });
                                } else if (resp.status == 'error') {
                                    return ProductActions.updateProductPoolRecommendationFailure({
                                        response: resp,
                                    });
                                }
                            })
                        );
                })
            )
    );

    /** Outbound join product */

    createOutBoundJoinProduct = createEffect(
        () => () =>
            this.actions$.pipe(
                ofType(ProductActions.createOutBoundJoinProduct),
                switchMap((action) => {
                    this.ngxService.start();
                    const body = new JoinProduct(action.product).serialize();
                    return this.productService.createJoinProduct(body).pipe(
                        map((response) => {
                            this.ngxService.stop();
                            if (response.status == 'success') {
                                return ProductActions.createOutBoundJoinProductSuccess({
                                    response,
                                });
                            } else if (response.status == 'error') {
                                this.ngxService.stop();
                                return ProductActions.createOutBoundJoinProductFailure({
                                    response,
                                });
                            }
                        })
                    );
                })
            )
    );

    createOutBoundJoinProductSuccess = createEffect(
        () => () =>
            this.actions$.pipe(
                ofType(ProductActions.createOutBoundJoinProductSuccess),
                tap((action) => {
                    this.openOutBoundProductSuccessModal(action.response.data.product_id);
                })
            ),
        { dispatch: false }
    );

    updateOutBoundJoinProduct = createEffect(
        () => () =>
            this.actions$.pipe(
                ofType(ProductActions.updateOutBoundJoinProduct),
                switchMap((action) => {
                    this.ngxService.start();
                    const body = new JoinProduct(action.product).serialize();
                    return this.productService.updateJoinProduct(action.product.id, body).pipe(
                        map((response) => {
                            this.ngxService.stop();
                            if (response.status == 'success') {
                                return ProductActions.updateOutBoundJoinProductSuccess({
                                    response,
                                });
                            } else if (response.status == 'error') {
                                this.ngxService.stop();
                                return ProductActions.updateOutBoundJoinProductFailure({
                                    response,
                                });
                            }
                        })
                    );
                })
            )
    );

    updateOutBoundJoinProductSuccess = createEffect(
        () => () =>
            this.actions$.pipe(
                ofType(ProductActions.updateOutBoundJoinProductSuccess),
                tap((action) => {
                    this.openOutBoundProductSuccessModal(action.response.data.product_id);
                })
            ),
        { dispatch: false }
    );
    /** End - Outbound join product */

    /** Domestic product */

    createDomesticProduct = createEffect(
        () => () =>
            this.actions$.pipe(
                ofType(ProductActions.createDomesticProduct),
                switchMap((action) => {
                    this.ngxService.start();
                    const body = new DomesticProduct(action.product).serialize();
                    return this.productService.createDomesticProduct(body).pipe(
                        map((response) => {
                            this.ngxService.stop();
                            if (response.status == 'success') {
                                return ProductActions.createDomesticProductSuccess({
                                    response,
                                });
                            } else if (response.status == 'error') {
                                this.ngxService.stop();
                                return ProductActions.createDomesticProductFailure({
                                    response,
                                });
                            }
                        })
                    );
                })
            )
    );

    createDomesticProductSuccess = createEffect(
        () => () =>
            this.actions$.pipe(
                ofType(ProductActions.createDomesticProductSuccess),
                tap((action) => {
                    this.openDomesticProductSuccessModal(action.response.data.product_id);
                })
            ),
        { dispatch: false }
    );

    updateDomesticProduct = createEffect(
        () => () =>
            this.actions$.pipe(
                ofType(ProductActions.updateDomesticProduct),
                switchMap((action) => {
                    this.ngxService.start();
                    const body = new DomesticProduct(action.product).serialize();
                    return this.productService.updateDomesticProduct(action.product.id, body).pipe(
                        map((response) => {
                            this.ngxService.stop();
                            if (response.status == 'success') {
                                return ProductActions.updateDomesticProductSuccess({
                                    response,
                                });
                            } else if (response.status == 'error') {
                                this.ngxService.stop();
                                return ProductActions.updateDomesticProductFailure({
                                    response,
                                });
                            }
                        })
                    );
                })
            )
    );

    updateDomesticProductSuccess = createEffect(
        () => () =>
            this.actions$.pipe(
                ofType(ProductActions.updateDomesticProductSuccess),
                tap((action) => {
                    this.openDomesticProductSuccessModal(action.response.data.product_id);
                })
            ),
        { dispatch: false }
    );
    /** End - Domestic product */

    /** Outbound package product */

    createOutBoundPackgeProduct = createEffect(
        () => () =>
            this.actions$.pipe(
                ofType(ProductActions.createOutBoundPackageProduct),
                switchMap((action) => {
                    this.ngxService.start();
                    const body = new PackageProduct(action.product).serialize();
                    return this.productService.createPackageProduct(body).pipe(
                        map((response) => {
                            this.ngxService.stop();
                            if (response.status == 'success') {
                                return ProductActions.createOutBoundPackageProductSuccess({
                                    response,
                                });
                            } else if (response.status == 'error') {
                                this.ngxService.stop();
                                return ProductActions.createOutBoundPackageProductFailure({
                                    response,
                                });
                            }
                        })
                    );
                })
            )
    );

    createOutBoundPackageProductSuccess = createEffect(
        () => () =>
            this.actions$.pipe(
                ofType(ProductActions.createOutBoundPackageProductSuccess),
                tap((action) => {
                    this.openOutBoundPackageProductSuccessModal(action.response.data.product_id);
                })
            ),
        { dispatch: false }
    );

    updateOutBoundPackgeProduct = createEffect(
        () => () =>
            this.actions$.pipe(
                ofType(ProductActions.updateOutBoundPackageProduct),
                switchMap((action) => {
                    this.ngxService.start();
                    const body = new PackageProduct(action.product).serialize();
                    return this.productService.updatePackageProduct(action.product.id, body).pipe(
                        map((response) => {
                            this.ngxService.stop();
                            if (response.status == 'success') {
                                return ProductActions.updateOutBoundPackageProductSuccess({
                                    response,
                                });
                            } else if (response.status == 'error') {
                                this.ngxService.stop();
                                return ProductActions.updateOutBoundPackageProductFailure({
                                    response,
                                });
                            }
                        })
                    );
                })
            )
    );

    updateOutBoundPackageProductSuccess = createEffect(
        () => () =>
            this.actions$.pipe(
                ofType(ProductActions.updateOutBoundPackageProductSuccess),
                tap((action) => {
                    this.openOutBoundPackageProductSuccessModal(action.response.data.product_id);
                })
            ),
        { dispatch: false }
    );
    /** End - Outbound package product */

    loadProductDocumentFileBase64 = createEffect(
        () => () =>
            this.actions$.pipe(
                ofType(ProductActions.loadProductDocumentFileBase64),
                switchMap((action) => {
                    this.ngxService.start();
                    return this.productService.getProductDocumentFileBase64(action.productId, action.isFromPool).pipe(
                        map((response) => {
                            this.ngxService.stop();
                            if (response && response.status == 'success') {
                                return ProductActions.loadProductDocumentFileBase64Succcess({
                                    data: response.data,
                                });
                            } else if (response && response.status == 'error') {
                                return ProductActions.loadProductDocumentFileBase64Failure({
                                    error: response,
                                });
                            }
                        })
                    );
                })
            )
    );

    loadProductPdfFileBase64 = createEffect(
        () => () =>
            this.actions$.pipe(
                ofType(ProductActions.loadProductPdfFileBase64),
                switchMap((action) => {
                    this.ngxService.start();
                    return this.productService.getProductPdfFileBase64(action.productId, action.isFromPool).pipe(
                        map((response) => {
                            this.ngxService.stop();
                            if (response && response.status == 'success') {
                                return ProductActions.loadProductPdfFileBase64Succcess({
                                    data: response.data,
                                });
                            } else if (response && response.status == 'error') {
                                return ProductActions.loadProductPdfFileBase64Failure({
                                    error: response,
                                });
                            }
                        })
                    );
                })
            )
    );

    private openOutBoundProductSuccessModal(productId: number): void {
        this.productStoreModalService.openSuccessModal(['product', 'join-tour', 'view', productId]);
    }

    private openDomesticProductSuccessModal(productId: number): void {
        this.productStoreModalService.openSuccessModal(['product', 'package-domestic-tour', 'view', productId]);
    }

    private openOutBoundPackageProductSuccessModal(productId: number): void {
        this.productStoreModalService.openSuccessModal(['product', 'package-tour', 'view', productId]);
    }

    addLead = createEffect(
        () => () =>
            this.actions$.pipe(
                ofType(ProductActions.loadProductWithSearchString),
                switchMap((action) => {
                    this.ngxService.start();
                    return this.productService.getProductPoolDataFromSearchString(action.searchString).pipe(
                        map((resp: any) => {
                            this.ngxService.stop();
                            if (resp.status == 'success') {
                                this.ngxService.stop();
                                const productData = new ProductDataFromSearchString().deserializeProductData(resp.data);
                                return ProductActions.loadProductWithSearchStringSuccess({
                                    productDetails: productData,
                                });
                            } else {
                                this.ngxService.stop();
                                return ProductActions.loadProductWithSearchStringFailure({
                                    error: resp,
                                });
                            }
                        }),
                        catchError((error) => {
                            this.ngxService.stop();
                            return of(
                                ProductActions.loadProductWithSearchStringFailure({
                                    error,
                                })
                            );
                        })
                    );
                })
            )
    );

    loadProductNameList = createEffect(
        () => () =>
            this.actions$.pipe(
                ofType(ProductActions.loadProductNameListListing),
                switchMap((action) => {
                    return this.productService.getProductListNameList(action.filter, action.fromProductPool).pipe(
                        map((resp) => {
                            const productNameList = resp.map((m) => {
                                return new ProductNameList().deserialize(m);
                            });

                            return ProductActions.loadProductNameListListingSuccess({
                                productNameList,
                            });
                        }),
                        catchError((error) => {
                            return of(ProductActions.loadProductNameListListingFailure(error));
                        })
                    );
                })
            )
    );

    loadProductPdfDetailFile = createEffect(
        () => () =>
            this.actions$.pipe(
                ofType(ProductActions.loadProductPdfDetailFile),
                switchMap((action) => {
                    this.ngxService.start();
                    return this.productPdfDetailFileService
                        .getProductPdfDetailFile(
                            action.productId,
                            action.categorySubProductId,
                            action.ownerChannel,
                            action.isFromPool,
                            action.loadedAsBase64
                        )
                        .pipe(
                            map((resp) => {
                                this.ngxService.stop();
                                if (resp.status === 'success') {
                                    const productPdfDetailFile: IProductPdfDetailFile = {
                                        fileUrl: resp.data.url || null,
                                        fileName: resp.data.file_name || null,
                                        fileBase64Data: resp.data.base64_data || null,
                                    };
                                    return ProductActions.loadProductPdfDetailFileSuccess({
                                        productPdfDetailFile,
                                    });
                                } else {
                                    return ProductActions.loadProductPdfDetailFileFailure({
                                        error: resp,
                                    });
                                }
                            }),
                            catchError((error) => {
                                return of(ProductActions.loadProductPdfDetailFileFailure(error));
                            })
                        );
                })
            )
    );

    loadProductOwner = createEffect(
        () => () =>
            this.actions$.pipe(
                ofType(ProductActions.loadProductOwner),
                switchMap((action) => {
                    return this.productOwnerService.getProductOwnerDetail(action.ownerChannel, action.ownerId).pipe(
                        map((resp) => {
                            if (resp.status === 'success') {
                                return ProductActions.loadProductOwnerSuccess({
                                    productOwner: new Owner().deserialize(resp.data),
                                });
                            } else {
                                return ProductActions.loadProductOwnerFailure({ error: resp });
                            }
                        }),
                        catchError((error) => {
                            return of(ProductActions.loadProductOwnerFailure({ error: error }));
                        })
                    );
                })
            )
    );

    loadCampaignDetail = createEffect(
        () => () =>
            this.actions$.pipe(
                ofType(ProductActions.loadCampaignDetail),
                switchMap((action) => {
                    return this.campaignService.getCampaignDetail(action.campaignId).pipe(
                        map((resp) => {
                            if (resp.status === 'success') {
                                return ProductActions.loadCampaignDetailSuccess({
                                    campaignDetail: new CampaignDetail().deserialize(resp.data),
                                });
                            } else {
                                return ProductActions.loadCampaignDetailFailure({ error: resp });
                            }
                        }),
                        catchError((error) => {
                            return of(ProductActions.loadCampaignDetailFailure({ error: error }));
                        })
                    );
                })
            )
    );
}
