import { ActionReducerMap, MetaReducer } from '@ngrx/store';
import { environment } from '../../environments/environment';

//
import { UserState } from './user-store/user.reducer';
import { ProductState } from './product-store/product.reducer';
import { UtilityState } from './utility-store/utility.state';
import { ReportState } from './report-store/report.reducer';
import { SettingState } from './setting-store/setting.reducer';
import { OrderState } from './order-store/order.reducer';
import { CampaignState } from './campaign-store/campaign.reducer';

export interface State {
    user: UserState;
    product: ProductState;
    utility: UtilityState;
    report: ReportState;
    setting: SettingState;
    order: OrderState;
    campaign: CampaignState;
}

export const reducers: ActionReducerMap<State> = {
    user: null,
    product: null,
    utility: null,
    report: null,
    setting: null,
    order: null,
    campaign: null,
};

export const metaReducers: MetaReducer<State>[] = !environment.production ? [] : [];
