import { Country } from './country';
import { Owner } from './owner';
import { Province } from './province';
import { Deal } from './deal';
import { CategoryTransport } from './category-transport.model';

export class ProductList {
    public id: number;
    public productId: number;
    public categorySubProductId: number;
    public image: string;
    public name: string;
    public countries: Country[];
    public provinces: Province[];
    public durationDay: number;
    public durationNight: number;
    public categoryTransport: CategoryTransport = null;
    public goTransportation: string;
    public startAtFirst: Date;
    public startAtLast: Date;
    public price: number;
    public priceStarts: number;
    public commissionCompany: number;
    public commissionSeller: number;
    public shareUrl: string;
    public isExpired: boolean = null;

    public hilightDescription: string;
    public tourCode: string;
    public documentFileName: string;
    public documentUrl: string;
    public showcaseImagePreviewUrl: string;
    public showcaseImageThumbnailUrl: string;
    public deal: Deal;
    public description: string;
    public updatedAt: Date;
    public owner: Owner;
    public ownerChannel: 'ag' | 'ws' = null;
    public isRecommended: boolean;
    public hasPdfDetailFile: boolean;

    public poolProductType: boolean;
    public productPoolId: number = null;

    constructor() {
        this.poolProductType = false;
    }

    public deserialize(obj: any): ProductList {
        this.id = obj.id;
        this.productId = obj.id;
        this.categorySubProductId = obj.pro_category_sub_products_id;
        this.image = obj.image;
        this.name = obj.name;
        if (obj.countries && obj.countries.length) {
            this.countries = obj.countries.map((m) => new Country().deserialize(m));
        } else {
            this.countries = [];
        }

        if (obj.provinces && obj.provinces.length) {
            this.provinces = obj.provinces.map((m) => new Province().deserialize(m));
        } else {
            this.provinces = [];
        }

        this.durationDay = obj.duration_day;
        this.durationNight = obj.duration_night;

        if (obj.go_transportation) {
            this.goTransportation = `${obj.go_transportation.name} (${obj.go_transportation.code})`;
        }
        if (obj.category_transport) {
            this.categoryTransport = new CategoryTransport().deserialize(obj.category_transport);
        }

        this.shareUrl = obj.share_url;

        this.startAtFirst = obj.start_at_first;
        this.startAtLast = obj.start_at_last;

        this.price = obj.price;
        this.priceStarts = obj.price_starts;
        this.commissionCompany = obj.commission_company;
        this.commissionSeller = obj.commission_seller;
        this.hilightDescription = obj.hilight_description;
        this.tourCode = obj.tour_code;
        this.documentFileName = obj.document_file_name;
        this.documentUrl = obj.document_url;
        this.showcaseImagePreviewUrl = obj.showcase_banner_preview_url;
        this.showcaseImageThumbnailUrl = obj.showcase_banner_thumbnail_url;
        this.description = obj.description;
        this.updatedAt = obj.updated_at || new Date();
        if (obj.owner) {
            this.owner = new Owner().deserialize(obj.owner);
        }
        this.ownerChannel = 'ag';
        this.hasPdfDetailFile = 'has_pdf_detail_file' in obj ? obj.has_pdf_detail_file : null;
        return this;
    }

    public deserializePoolData(obj: any): ProductList {
        this.id = obj.product_pool_id;
        this.productId = obj.id;
        this.categorySubProductId = obj.category_sub_products_id;
        this.image = obj.banner_url;
        this.name = obj.name;
        if (obj.destinations && obj.destinations.length) {
            this.countries = obj.destinations.map((m) => new Country().deserialize(m));
        } else {
            this.countries = [];
        }

        this.durationDay = obj.duration_day;
        this.durationNight = obj.duration_night;

        if (obj.go_transport) {
            this.goTransportation = obj.go_transport.name_en + ' (' + obj.go_transport.code + ')';
        }
        if (obj.category_transport) {
            this.categoryTransport = new CategoryTransport().deserialize(obj.category_transport);
        }

        this.shareUrl = obj.share_url;

        this.startAtFirst = obj.travel_period_start_at_first;
        this.startAtLast = obj.travel_period_start_at_last;

        this.price = obj.price;
        this.priceStarts = obj.compare_price;
        this.commissionCompany = obj.commission_company;
        this.commissionSeller = obj.commission_seller;
        this.hilightDescription = obj.hilight_description;
        this.tourCode = obj.tour_code;
        this.documentFileName = obj.program_doc_url ? this.getFileNameFromUrl(obj.program_doc_url) : null;
        this.documentUrl = obj.program_doc_url;
        this.deal = obj.deal ? new Deal().deserialize(obj.deal) : null;
        this.description = obj.description;
        this.updatedAt = obj.updated_at || new Date();
        if (obj.owner) {
            this.owner = new Owner().deserialize(obj.owner);
        }
        this.ownerChannel = obj.owner_channel;
        this.poolProductType = true;
        this.productPoolId = obj.product_pool_id;
        this.isRecommended = obj.is_recommended ? obj.is_recommended : null;
        this.hasPdfDetailFile = 'has_pdf_detail_file' in obj ? obj.has_pdf_detail_file : null;
        this.isExpired = obj.is_expired == 1 ? true : false;
        return this;
    }

    public setIsRecommended(isRecommended: boolean): void {
        this.isRecommended = isRecommended;
    }

    private getFileNameFromUrl(url: string): string {
        const segments = url.split('/');
        return segments[segments.length - 1];
    }
}
