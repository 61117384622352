import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Subject } from 'rxjs';
import { pairwise, takeUntil } from 'rxjs/operators';

import {
    SearchableDropdownSettings,
    SearchableDropdownItem,
} from 'src/app/modules/shared/components/searchable-dropdown/searchable-dropdown.component';
import { AgencyDetailsService } from 'src/app/services/agency-details.service';

import { WholesaleProductPeriod } from 'src/app/models/wholesale-products/wholesale-product-period.model';
import { IResponseGetOrder } from 'src/app/models/interfaces/tourwow-order/response-get-order.interface';
import { ICustomerInfoForm } from '../../interfaces/customer-info-form.interface';
import { Store } from '@ngrx/store';
import { State } from 'src/app/stores/reducers';
import { User } from 'src/app/models/user';
import { selectUser } from 'src/app/stores/user-store/user.selectors';

@Component({
    selector: 'app-customer-info-form',
    templateUrl: './customer-info-form.component.html',
    styleUrls: ['./customer-info-form.component.scss'],
})
export class CustomerInfoFormComponent implements OnInit, OnDestroy, OnChanges {
    isSubmitted = false;

    @Input() period: WholesaleProductPeriod = null;
    @Input() order: IResponseGetOrder;
    @Input() submitButton: boolean;

    @Output() formValid = new EventEmitter<boolean>();
    @Output() infoChange = new EventEmitter<ICustomerInfoForm>();

    agencyListSettings: SearchableDropdownSettings = new SearchableDropdownSettings();
    agencyListItems: SearchableDropdownItem[] = [];

    customerForm: UntypedFormGroup = this.fb.group({
        name: [null, [Validators.required]],
        phoneNumber: [null, [Validators.required]],
        email: [null],
        facebook: [null],
        line: [null],
        instagram: [null],
        remark: [null],
        sellerId: [null],
    });

    private unsubscribe$: Subject<void> = new Subject<void>();

    constructor(
        private store: Store<State>,
        private fb: UntypedFormBuilder,
        private agencyService: AgencyDetailsService
    ) {}

    ngOnInit(): void {
        this.initialAgencyListDropdownItems();
        this.getUser();

        this.customerForm.valueChanges
            .pipe(pairwise(), takeUntil(this.unsubscribe$))
            .subscribe(([prev, cur]: [ICustomerInfoForm, ICustomerInfoForm]) => {
                this.infoChange.emit(cur);
            });
    }

    ngOnDestroy(): void {
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.order && changes.order.currentValue) {
            this.fillOrderData(changes.order.currentValue);
        }
    }

    private getUser(): void {
        this.store.select(selectUser).subscribe((user: User) => {
            this.customerForm.patchValue({ sellerId: user.id });
        });
    }

    private initialAgencyListDropdownItems(): void {
        // Initial settings
        this.agencyListSettings.closeAfterSelect = true;
        this.agencyListSettings.multipleSelection = false;
        this.agencyListSettings.dropdownListPlaceholder = 'เลือกเซลล์';
        this.agencyListSettings.searchBoxPlaceholder = 'กรอกชื่อเซลล์';

        this.agencyListSettings.styleSetting.fontSize = '14px';

        // Initial data
        this.agencyService
            .getUsersList(true, false)
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe((response) => {
                if (response.status === 'success') {
                    this.agencyListItems = response.data.users.map((agency) => {
                        return new SearchableDropdownItem({
                            id: agency.id,
                            name: this.getFullName(agency.first_name, agency.last_name, agency.nick_name),
                            value: agency.id,
                            isChecked: this.customerForm.controls['sellerId'].value === agency.id ? true : false,
                        });
                    });
                }
            });
    }

    private getFullName(firstName: string, lastName: string, nickname: string): string {
        let fullname: string = firstName;
        if (lastName) {
            fullname += ` ${lastName}`;
        }

        if (nickname) {
            fullname += ` (${nickname})`;
        }

        return fullname;
    }

    private fillOrderData(order: IResponseGetOrder): void {
        this.customerForm.patchValue({
            name: order.customer_name,
            phoneNumber: order.customer_phone_number,
            email: order.customer_email,
            facebook: order.customer_facebook,
            line: order.customer_line,
            instagram: order.customer_instagram,
            remark: order.customer_remark,
            sellerId: order.seller_agency_member.id,
        });

        this.onAgencyListSelected([this.customerForm.controls['sellerId'].value]);
    }

    // Events Listener
    onAgencyListSelected(agencyIds: number[]): void {
        const agencyId = agencyIds[0];
        this.customerForm.patchValue({ sellerId: agencyId });
    }

    submitForm(): void {
        this.isSubmitted = true;
        this.formValid.emit(this.customerForm.valid);
    }
}
