<div id="email-modal" class="text-center">
    <h1><img class="mr-1" src="assets/img/icon/icon-email-thin.svg"> ส่งอีเมลคำสั่งซื้อ</h1>
    <button type="button" class="close pull-right" aria-label="Close" (click)="hide()">
        <span aria-hidden="true">&times;</span>
    </button>
    <div class="detail-text">
        ส่งอีเมลคำสั่งซื้อไปยัง <strong>{{contactName}}</strong><br>
        ที่อีเมล <strong>{{email}}</strong>
    </div>
    <button class="btn btn-primary" (click)="confirm()">ยืนยันการส่งอีเมล</button>
    <button class="btn btn-cancel" (click)="hide()">ยกเลิก</button>
</div>