<div class="search-result">
    <div class="d-flex search-result-title">
        <strong class="text-xxl w-100 pr-4">คุณกำลังดู Booking ทั้งหมด {{ productOrders?.total }} Booking</strong>
        <select class="form-control w-auto ml-auto">
            <option>เรียงตาม Booking ล่าสุด</option>
        </select>
    </div>
    <div class="search-list pb-4">
        <div class="d-flex search-list-head">
            <span style="width: 50%">Booking</span>
            <span class="text-center" style="width: 12.5%">ยอดจอง</span>
            <span class="text-center" style="width: 12.5%">งวดแรก</span>
            <span class="text-center" style="width: 12.5%">คอมรวม</span>
            <span class="text-center" style="width: 12.5%">สถานะ</span>
        </div>
        <ng-container *ngFor="let p of productOrders?.result; let index = index">
            <div class="d-flex search-list-content">
                <span class="text-center" style="width: 3%">{{ index + 1 }}. </span>
                <span class="d-flex flex-column line-right" style="width: 47%">
                    <span class="d-flex mb-1">
                        <strong
                            >ลูกค้า : {{ p.customerName }} <i class="icon icon-info cursor-pointer mr-1"></i
                        ></strong>
                        <span>จองวันที่ {{ p.createdAt | thaiDate: 'DD MM YY HH:mm' }} ({{ p.orderCode }})</span>
                    </span>
                    <span class="text-xs mb-1">
                        {{ p.productSubCategoryId | productCategory }} เดินทาง
                        {{ p.productPeriodStartAt | thaidaterange: p.productPeriodEndAt }} {{ p.productTourCode }}
                        {{ p.productName }}</span
                    >
                    <span class="text-xs mb-1"
                        >คอมบริษัท {{ p.productCommissionCompany }}/ที่นั่ง<span *ngIf="p.productCommissionSeller"
                            >, คอมเซลล์ {{ p.productCommissionSeller }}/ที่นั่ง</span
                        ></span
                    >
                    <span class="text-xs"
                        ><u
                            ><span class="text-dark-light mr-1"
                                >{{ p.productOwnerNameEn }} ({{ p.productOwnerNameTh }})</span
                            >
                            <span class="cursor-pointer"
                                ><strong class="mr-1">ติดต่อคลิกที่นี่</strong> <i class="icon icon-info"></i></span></u
                    ></span>
                </span>
                <span class="d-flex flex-column line-right" style="width: 12.5%">
                    <span class="mb-1" *ngIf="p.fullyPaid"
                        >ชำระเงินครบแล้ว <i class="icon icon-check-mark-bg-green"></i
                    ></span>
                    <span class="mb-1"
                        >{{ p.quantity }} คน <span class="text-xs">(รวม {{ p.totalInstallment }} งวด)</span></span
                    >
                    <span>{{ p.sumAmount | mycurrency }}</span>
                </span>
                <span class="d-flex flex-column line-right" style="width: 12.5%">
                    <span class="mb-1">{{ p.firstInstallment?.amount | mycurrency }}</span>
                    <span class="text-xs"
                        >ชำระ {{ p.firstInstallment?.paidAt | thaiDate: 'DD/MM/YY' }}
                        {{ p.firstInstallment?.paidAt | thaiDate: 'HH:mm' }}</span
                    >
                </span>
                <span class="d-flex flex-column line-right" style="width: 12.5%">
                    <span class="mb-1">{{ p.sumCommission | mycurrency }}</span>
                    <span class="text-xs"
                        >(บริษัท {{ p.sumCommissionCompany | mycurrency }} + เซลล์
                        {{ p.sumCommissionSeller | mycurrency }})</span
                    >
                </span>
                <span class="text-center" style="width: 12.5%">
                    <span *ngIf="!p.isOrderCompleted">รอเดินทาง</span>
                    <span *ngIf="p.isOrderCompleted">Bon Voyage (Completed)</span>
                </span>
            </div>
            <div class="line-separetor"></div>
        </ng-container>
    </div>
</div>
