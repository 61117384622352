import { Component, Input, forwardRef, Output, EventEmitter } from '@angular/core';
import { NG_VALUE_ACCESSOR, ControlValueAccessor } from '@angular/forms';

import { defineLocale } from 'ngx-bootstrap/chronos';
import { thBeLocale } from 'ngx-bootstrap/locale';
import { BsLocaleService, BsDatepickerConfig } from 'ngx-bootstrap/datepicker';
defineLocale('th', thBeLocale);

export const DATEPICKER_VALUE_ACCESSOR: any = {
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => CalendarInputComponent),
    multi: true,
};

@Component({
    selector: 'app-calendar-input',
    templateUrl: './calendar-input.component.html',
    styleUrls: ['./calendar-input.component.scss'],
    providers: [DATEPICKER_VALUE_ACCESSOR],
})
export class CalendarInputComponent implements ControlValueAccessor {
    @Input() model: Date;
    @Input() placeholder: string;
    @Input() min: Date;
    @Input() max: Date;
    @Input() minDate: Date = null;
    @Input() maxDate: Date = null;
    @Input() valid: boolean = true;
    @Input() bsValue: Date = null;
    @Input() showIcon: boolean = true;
    @Input() showDateInValue?: boolean;
    @Input() showOnlyMonth: boolean = false;
    @Input() forAddBookingModalStyling: boolean = false;
    @Input() disabled: boolean = false;
    @Input() placement: string = 'bottom';

    @Input() bsConfig: Partial<BsDatepickerConfig> = null;
    @Output() bsValueChange = new EventEmitter<Date>();
    @Output() bsShown = new EventEmitter();
    @Output() bsHidden = new EventEmitter();

    constructor(private localeService: BsLocaleService) {
        // change language date picker
        this.localeService.use('th-be');
    }

    writeValue(date: any): void {
        throw new Error('Method not implemented.');
    }

    registerOnChange(fn: any): void {
        throw new Error('Method not implemented.');
    }

    registerOnTouched(fn: any): void {
        throw new Error('Method not implemented.');
    }

    setDisabledState?(isDisabled: boolean): void {
        throw new Error('Method not implemented.');
    }

    public setDateValue(): string {
        if (this.bsValue && this.showDateInValue) {
            return this.bsValue.getDate() + ' ';
        }
        return '';
    }

    public onChangeBsValue(value: Date): void {
        this.bsValueChange.emit(value);
    }

    public onShown(): void {
        this.bsShown.next();
    }

    public onHidden(): void {
        this.bsHidden.next();
    }

    public onKeydown(event: any): void {
        event.preventDefault();
    }
}
