import { CanceledByAgencyMember } from './canceled-by-agency-member.model';
import { AgencyMember } from './agency-member.model';
import { Customer } from './customer.model';
import { ProductPeriod } from './product-period.model';
import { Product } from './product.model';
import { Supplier } from './supplier.model';
import { ITwBooking } from '../interfaces/tw-booking-interface/tw-booking.interface';
import { OrderFile } from './order-file.model';
import { CustomerOrderInstallment } from './customer-order-installment';
import { SupplierOrderInstallment } from './supplier-order-installment';

export class TwBooking {
    id: number;
    createdAt: Date;
    orderCode: string;
    orderDetailPageUrl: string;
    discount: number;
    canceledAt: string;
    orderStatus: string;
    quantity: number;
    isPinned: boolean;
    note: string;
    useVat: boolean;
    net_amount: number;
    hasTask: boolean;

    orderFiles: OrderFile[];
    customerOrderInstallments: CustomerOrderInstallment[];
    supplierOrderInstallments: SupplierOrderInstallment[];
    product: Product;
    productPeriod: ProductPeriod;
    customer: Customer;
    supplier: Supplier;
    agencyMember: AgencyMember;
    canceledByAgencyMember: CanceledByAgencyMember;

    public deserialize(data: ITwBooking): TwBooking {
        this.id = data.id;
        this.createdAt = new Date(data.created_at);
        this.orderCode = data.order_code;
        this.orderDetailPageUrl = data.order_detail_page_url;
        this.discount = data.discount;
        this.canceledAt = data.canceled_at;
        this.orderStatus = data.order_status;
        this.quantity = data.quantity;
        this.isPinned = data.is_pinned;
        this.note = data.note;
        this.useVat = data.use_vat;
        this.net_amount = data.net_amount;
        this.hasTask = data.has_task;

        this.orderFiles = data.order_files.map((orderFile) => new OrderFile().deserialize(orderFile));
        this.customerOrderInstallments = data.customer_order_installments.map((installment) =>
            new CustomerOrderInstallment().deserialize(installment)
        );
        this.supplierOrderInstallments = data.supplier_order_installments.map((installment) =>
            new SupplierOrderInstallment().deserialize(installment)
        );
        this.product = data.product ? new Product().deserialize(data.product) : null;
        this.productPeriod = data.product_period ? new ProductPeriod().deserialize(data.product_period) : null;
        this.customer = data.customer ? new Customer().deserialize(data.customer) : null;
        this.supplier = data.supplier ? new Supplier().deserialize(data.supplier) : null;
        this.agencyMember = data.agency_member ? new AgencyMember().deserialize(data.agency_member) : null;

        this.canceledByAgencyMember = data.canceled_by_agency_member
            ? new CanceledByAgencyMember().deserialize(data.canceled_by_agency_member)
            : null;
        this.prepareInstallmentFileForDisplay(this.customerOrderInstallments, this.supplierOrderInstallments);
        return this;
    }

    private prepareInstallmentFileForDisplay(
        customerInstallment: CustomerOrderInstallment[],
        supplierInstallment: SupplierOrderInstallment[]
    ) {
        const customerInstallmentLength = customerInstallment.length;
        const supplierInstallmentLength = supplierInstallment.length;

        if (supplierInstallmentLength <= customerInstallmentLength) {
            for (let i = 0; i < customerInstallmentLength; i++) {
                customerInstallment[i].filesForDisplay.push(...customerInstallment[i].files);
                if (i < supplierInstallmentLength) {
                    customerInstallment[i].filesForDisplay.push(...supplierInstallment[i].files);
                }
            }
        } else {
            for (let i = 0; i < supplierInstallmentLength; i++) {
                if (i > customerInstallmentLength - 1) {
                    customerInstallment[customerInstallmentLength - 1].filesForDisplay.push(
                        ...supplierInstallment[i].files
                    );
                } else {
                    customerInstallment[i].filesForDisplay.push(
                        ...customerInstallment[i].files,
                        ...supplierInstallment[i].files
                    );
                }
            }
        }
        customerInstallment.forEach((installment) => {
            installment.filesForDisplay.sort((a, b) => a.createdAt.getTime() - b.createdAt.getTime());
        });
    }
}
