export interface ICommissionReportList {
    page: number;
    pageEntries: number;
    total: number;
    stats: ICommissionReportStats;
    commissionReports: ICommissionReport[];
}

export interface ICommissionReportStats {
    allOrderSumCommission: number;
}

export interface ICommissionReport {
    id: number;
    orderCode: string;
    orderStatus: number;
    proCategoryProductsId: number;
    proCategorySubProductsId: number;
    productsId: number;
    productOwnerChannel: string;
    tourCode: string;
    productName: string;
    startAt: string;
    endAt: string;
    commissionCompany: number;
    commissionSeller: number;
    contactName: string;
    phoneNumber: string;
    email: string;
    leadTypeSlug: string;
    customerRemark: string;
    sumQuantity: number;
    createdAt: string;
    productOwner: IProductOwner;
    sellerAgencyMember: ISellerAgencyMember;
}

export interface IProductOwner {
    id: number;
    channel: 'ws' | 'ag';
    nameTH: string;
    nameEN: string;
    phoneNumber: string;
    productCode: string;
}

export interface ISellerAgencyMember {
    id: number;
    firstName: string;
    lastName: string;
    nickName: string;
}

export class CommistionReportList implements ICommissionReportList {
    page: number;
    pageEntries: number;
    total: number;
    stats: ICommissionReportStats;
    commissionReports: ICommissionReport[];

    deserialize(source: any) {
        this.page = source.data.page;
        this.pageEntries = source.data.page_entries;
        this.total = source.data.total;
        this.stats = new CommissionReportStats().deserialize(source.data.stats);
        this.commissionReports = source.data.result.map((item) => new CommissionReport().deserialize(item));
        return this;
    }
}

export class CommissionReportStats implements ICommissionReportStats {
    allOrderSumCommission: number;
    deserialize(source: any) {
        this.allOrderSumCommission = source.all_order_sum_commission;
        return this;
    }
}

export class CommissionReport implements ICommissionReport {
    id: number;
    orderCode: string;
    orderStatus: number;
    proCategoryProductsId: number;
    proCategorySubProductsId: number;
    productsId: number;
    productOwnerChannel: string;
    tourCode: string;
    productName: string;
    startAt: string;
    endAt: string;
    commissionCompany: number;
    commissionSeller: number;
    contactName: string;
    phoneNumber: string;
    email: string;
    leadTypeSlug: string;
    customerRemark: string;
    sumQuantity: number;
    createdAt: string;
    productOwner: ProductOwner;
    sellerAgencyMember: SellerAgencyMember;

    deserialize(source: any) {
        this.id = source.id;
        this.orderCode = source.order_code;
        this.orderStatus = source.order_status;
        this.proCategoryProductsId = source.pro_category_products_id;
        this.proCategorySubProductsId = source.pro_category_sub_products_id;
        this.productsId = source.products_id;
        this.productOwnerChannel = source.product_owner_channel;
        this.tourCode = source.tour_code;
        this.productName = source.product_name;
        this.startAt = source.start_at;
        this.endAt = source.end_at;
        this.commissionCompany = source.commission_company;
        this.commissionSeller = source.commission_seller;
        this.contactName = source.contact_name;
        this.phoneNumber = source.phone_number;
        this.email = source.email;
        this.leadTypeSlug = source.lead_type_slug;
        this.customerRemark = source.customer_remark;
        this.sumQuantity = source.sum_quantity;
        this.createdAt = source.created_at;
        this.productOwner = new ProductOwner().deserialize(source.product_owner);
        this.sellerAgencyMember = new SellerAgencyMember().deserialize(source.seller_agency_member);
        return this;
    }
}

export class ProductOwner implements IProductOwner {
    id: number;
    channel: 'ws' | 'ag';
    nameTH: string;
    nameEN: string;
    phoneNumber: string;
    productCode: string;

    deserialize(source: any) {
        this.id = source.id;
        this.channel = source.channel;
        this.nameTH = source.name_th;
        this.nameEN = source.name_en;
        this.phoneNumber = source.phone_number;
        this.productCode = source.product_code;
        return this;
    }
}

export class SellerAgencyMember implements ISellerAgencyMember {
    id: number;
    firstName: string;
    lastName: string;
    nickName: string;

    deserialize(source: any) {
        this.id = source.id;
        this.firstName = source.first_name;
        this.lastName = source.last_name;
        this.nickName = source.nick_name;
        return this;
    }
}
