import { ThaiDatePipe, ThaiDateRangePipe } from './../../../pipes/thaidate.pipe';
import { IBookingListQueries } from '../models/booking-list.model';

export class SearchResultLabelHelper {
    public static getDynamicTotalString(
        searchFilter: Partial<IBookingListQueries>,
        thaiDatePipe: ThaiDatePipe,
        thaiDateRangePipe: ThaiDateRangePipe,
        productOwner: {
            nameEn: string;
            nameTh: string;
        },
        sellerAgencyMember: {
            firstName: string;
            lastName: string;
            nickName: string;
        },
        agencyProductCode: string
    ): string {
        if (!searchFilter || !searchFilter.filters) {
            return '';
        }
        let string = '';

        if (searchFilter.filters.contact_name_or_phone_number) {
            string += 'ชื่อลูกค้า/เบอร์โทร ' + searchFilter.filters.contact_name_or_phone_number + ', ';
        }

        if (searchFilter.filters.product_owner?.id && productOwner?.nameEn && productOwner?.nameTh) {
            string += 'โปรแกรมจาก ' + productOwner.nameEn + ' (' + productOwner.nameTh + '), ';
        }

        string += this.getBookingDateString(searchFilter.filters.created_at_between, thaiDatePipe, thaiDateRangePipe);

        string += this.getTravelDateString(
            searchFilter.filters.start_at_between,
            searchFilter?.filters?.order_status,
            thaiDatePipe,
            thaiDateRangePipe
        );

        if (searchFilter.filters.seller_agency_member_id && sellerAgencyMember) {
            string +=
                'เซลล์ ' +
                sellerAgencyMember.firstName +
                ' ' +
                sellerAgencyMember.lastName +
                ' (' +
                sellerAgencyMember.nickName +
                '), ';
        }

        if (searchFilter.filters.product_name) {
            string +=
                'โปรแกรมชื่อ ' +
                (searchFilter.filters?.product_tour_code
                    ? agencyProductCode + '-' + searchFilter.filters?.product_tour_code + ' '
                    : '') +
                searchFilter.filters.product_name +
                ', ';
        }

        if (!searchFilter.filters.order_statuses) {
            let orderStatusString: string = this.getOrderStatusString(
                searchFilter.filters.order_status,
                searchFilter.filters.start_at_between?.max_date
            );

            // Force display order status 31
            if (searchFilter.filters.order_status === 31 && searchFilter.filters.start_at_between?.max_date) {
                orderStatusString = 'เดินทางแล้ว';
            }

            string += orderStatusString ? 'สถานะ ' + orderStatusString : 'ทั้งหมด';
        }

        if (searchFilter.filters.order_statuses) {
            const orderStatusString: string = this.getMultipleOrderStatusString(searchFilter.filters.order_statuses);
            string += orderStatusString ? 'สถานะ ' + orderStatusString : 'ทั้งหมด';
        }

        return string;
    }

    private static getBookingDateString(
        created_at_between: any,
        thaiDatePipe: ThaiDatePipe,
        thaiDateRangePipe: ThaiDateRangePipe
    ): string {
        const minDate: string = created_at_between?.min_date;
        const maxDate: string = created_at_between?.max_date;

        let bookingDateString = '';

        const sevenDaysDate = new Date();
        sevenDaysDate.setDate(sevenDaysDate.getUTCDate() - 6);
        const sevenDaysDateString = sevenDaysDate.toISOString().substring(0, 10);
        if (sevenDaysDateString === minDate) {
            bookingDateString += ' 7 วันล่าสุด, ';
        }

        const thirtyDaysDate = new Date();
        thirtyDaysDate.setDate(thirtyDaysDate.getUTCDate() - 29);
        const thirtyDaysDateString = thirtyDaysDate.toISOString().substring(0, 10);
        if (thirtyDaysDateString === minDate) {
            bookingDateString += ' 30 วันล่าสุด, ';
        }

        const threeMonthsDate = new Date();
        threeMonthsDate.setMonth(threeMonthsDate.getMonth() - 3);
        const threeMonthsDateString = threeMonthsDate.toISOString().substring(0, 10);
        if (threeMonthsDateString === minDate) {
            bookingDateString += ' 3 เดือนล่าสุด, ';
        }

        if (!bookingDateString && minDate && maxDate) {
            bookingDateString += ' ' + thaiDateRangePipe.transform(minDate, maxDate) + ', ';
        }

        if (!bookingDateString && minDate) {
            bookingDateString += ' ตั้งแต่ ' + thaiDatePipe.transform(minDate) + ', ';
        }

        if (!bookingDateString && maxDate) {
            bookingDateString += ' ถึง ' + thaiDatePipe.transform(maxDate) + ', ';
        }

        return bookingDateString;
    }

    private static getTravelDateString(
        start_at_between: any,
        order_status: number = null,
        thaiDatePipe: ThaiDatePipe,
        thaiDateRangePipe: ThaiDateRangePipe
    ): string {
        const minDate: string = start_at_between?.min_date;
        const maxDate: string = start_at_between?.max_date;

        if (order_status === 31) {
            return '';
        }

        let travelDateString = '';

        if (!travelDateString && minDate && maxDate) {
            travelDateString += ' เดินทาง ' + thaiDateRangePipe.transform(minDate, maxDate) + ', ';
        }

        if (!travelDateString && minDate) {
            travelDateString += ' เดินทางตั้งแต่ ' + thaiDatePipe.transform(minDate) + ', ';
        }

        if (!travelDateString && maxDate) {
            travelDateString += ' เดินทางถึง ' + thaiDatePipe.transform(maxDate) + ', ';
        }

        return travelDateString;
    }

    private static getOrderStatusString(orderStatus: number, maxDate: string): string {
        switch (orderStatus) {
            case 21:
                return 'รอชำระเงินงวดแรก';
            case 22:
                return 'รอชำระเงินครบ';
            case 31:
                if (maxDate && new Date(maxDate) > new Date()) {
                    return 'เดินทางแล้ว';
                } else {
                    return 'รอเดินทาง';
                }
            case 41:
                return 'ยกเลิก';
            default:
                return null;
        }
    }

    private static getMultipleOrderStatusString(orderStatuses: any[]): string {
        if (!orderStatuses?.length) {
            return null;
        }

        let statusString = '';
        orderStatuses.forEach((status) => {
            if (statusString) {
                statusString += ', ';
            }
            statusString += this.getMultipleOrderStatusStringGenerator(status);
        });

        return statusString;
    }

    private static getMultipleOrderStatusStringGenerator(orderStatus: any): string {
        switch (orderStatus.status_code) {
            case 21:
                return 'รอชำระเงินงวดแรก';
            case 22:
                return 'รอชำระเงินครบ';
            case 31:
                if (!orderStatus.already_started) {
                    return 'รอเดินทาง';
                } else {
                    return 'เดินทางแล้ว';
                }
            case 41:
                return 'ยกเลิก';
            default:
                return 'ทั้งหมด';
        }
    }
}
