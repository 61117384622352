import { HttpClient, HttpParams } from '@angular/common/http';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { ENDPOINTS } from '../constants/endpoint.constant';
import { HttpService } from './base/http.service';

@Injectable({
    providedIn: 'root',
})
export class AgencyDetailsService extends HttpService {
    constructor(
        @Inject(PLATFORM_ID) protected platformId: Object,
        protected http: HttpClient,
        protected router: Router
    ) {
        super(platformId, http, router);
    }

    public getAgencyInfo(): Observable<any> {
        const url = environment.apiProUrl + ENDPOINTS.AGENCY_DETAILS.GET_INFO;

        return this.getRequestOptions(true).pipe(
            switchMap((headers) => {
                const options = {
                    headers: headers.headers,
                };
                return this.http.get(url, options);
            })
        );
    }

    public getUsersList(getAll: boolean, showInactive: boolean): Observable<any> {
        let params = new HttpParams();

        if (getAll) {
            params = params.append('getAll', 'true');
        }

        if (showInactive) {
            params = params.append('showInactive', 'true');
        }

        const url = environment.apiProUrl + ENDPOINTS.AGENCY_DETAILS.GET_USERS_LIST;

        return this.getRequestOptions(true).pipe(
            switchMap((headers) => {
                const options = {
                    headers: headers.headers,
                    params,
                };
                return this.http.get(url, options);
            })
        );
    }

    public getPartnersList(): Observable<any> {
        const url = environment.apiProUrl + ENDPOINTS.AGENCY_DETAILS.GET_PARTNERS_LIST;

        return this.getRequestOptions(true).pipe(
            switchMap((headers) => {
                const options = {
                    headers: headers.headers,
                };
                return this.http.get(url, options);
            })
        );
    }
}
