import { createFeatureSelector, createSelector } from '@ngrx/store';
import { ICustomContent } from 'src/app/models/custom-content.model';
import * as fromUser from './user.reducer';

export const selectUserState = createFeatureSelector<fromUser.UserState>(fromUser.userFeatureKey);

export const selectUserIsAuthenticate = createSelector(
    selectUserState,
    (state: fromUser.UserState) => state.isAuthenticate
);

export const selectErrorMessage = createSelector(selectUserState, (state: fromUser.UserState) => state.errorMessage);

export const selectUser = createSelector(selectUserState, (state: fromUser.UserState) => state.user);

export const selectAgencySlug = createSelector(selectUserState, (state: fromUser.UserState) => state.agency.slug);

export const selectAgencyProductCode = createSelector(
    selectUserState,
    (state: fromUser.UserState) => state.agency.productCode
);

export const selectAgencyInfo = createSelector(selectUserState, (state: fromUser.UserState) => state.agency);

export const selectPermissionIsActiveTourwowMall = createSelector(
    selectUserState,
    (state: fromUser.UserState) => state.agency.isActiveTourwowMall
);

export const selectPermissionAllowPartnersToSellProduct = createSelector(
    selectUserState,
    (state: fromUser.UserState) => state.agency.allowPartnersToSellProduct
);

export const selectMenuCustomContentByOrdinal = createSelector(selectUserState, (state, prop) => {
    const menu: ICustomContent[] = state.menuCustomContent
        ? state.menuCustomContent.filter((value) => value.menuOrdinal == prop.menuOrdinal)
        : null;
    return menu && menu.length > 0 ? menu[0] : null;
});

export const selectUsersList = createSelector(selectUserState, (state: fromUser.UserState) => state.usersList);

export const selectPartnersList = createSelector(selectUserState, (state: fromUser.UserState) => state.partnersList);

export const selectTwmDomain = createSelector(selectUserState, (state: fromUser.UserState) => state.twmDomain);
