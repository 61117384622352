export interface IPortfolioResponse {
    page: number;
    pageEntries: number;
    total: number;
    result: IPortfolio[];
}

export interface IPortfolio {
    id: number;
    title: string;
    mainImagePreviewUrl: string;
}

export class PortfolioListing {
    public deserializePortfolio(obj: any): any {
        const portfolioList: IPortfolioResponse = {
            page: obj.page,
            pageEntries: obj.page_entries,
            total: obj.total,
            result: [],
        };

        if (obj.result) {
            obj.result.forEach((item: any) => {
                const tempItem: IPortfolio = {
                    id: item.id,
                    title: item.title,
                    mainImagePreviewUrl: item.main_image_preview_url,
                };
                portfolioList.result.push(tempItem);
            });
        }

        return portfolioList;
    }
}
