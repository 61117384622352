import { AfterViewInit, Component, EventEmitter, OnInit, ViewChild } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { TabsetComponent } from 'ngx-bootstrap/tabs';
import { Observable } from 'rxjs';
import { DateHelper } from 'src/app/helpers/date.helper';
import { IAirport } from 'src/app/models/interfaces/airport.interface';
import { TransportationService } from 'src/app/services/transportation.service';
import { WorkListService } from 'src/app/services/work-list.service';
import { IWorkListTaskPayload } from '../../models/interfaces/worklist-task-payload.interface';
import { IWorkListTask } from '../../models/interfaces/worklist-task.interface';

@Component({
    selector: 'app-work-list-modal',
    templateUrl: './work-list-modal.component.html',
    styleUrls: ['./work-list-modal.component.scss'],
})
export class WorkListModalComponent implements OnInit, AfterViewInit {
    @ViewChild('staticTabs', { static: false }) staticTabs?: TabsetComponent;
    orderCode: string = null;
    userRole: string = null;
    airports$: Observable<IAirport[]>;
    tasks: IWorkListTask[] = [];
    alreadySave: EventEmitter<string> = new EventEmitter();

    constructor(
        private worklistService: WorkListService,
        private transportService: TransportationService,
        public bsModalRef: BsModalRef
    ) {}

    ngOnInit(): void {
        this.airports$ = this.transportService.getAirport();
        this.worklistService.getTasks(this.orderCode).subscribe((data) => (this.tasks = data));
    }

    ngAfterViewInit(): void {
        if (this.staticTabs?.tabs[0]) {
            this.staticTabs.tabs[0].active = true;
        }
    }

    save(): void {
        this.worklistService
            .updateTasks(this.orderCode, this.convertTaskToPayload(this.tasks))
            .subscribe((response: boolean) => {
                if (response) {
                    this.alreadySave.next(this.orderCode);
                    this.bsModalRef.hide();
                } else {
                    // Something Wrong.
                }
            });
    }

    cancel(): void {
        this.bsModalRef.hide();
    }

    selectAirport(task: IWorkListTask, airportId: string): void {
        task.airport = {
            id: +airportId,
            nameTh: '',
            code: '',
        };
    }

    private convertTaskToPayload(tasks: IWorkListTask[]): IWorkListTaskPayload[] {
        return tasks.map((task: IWorkListTask) => {
            return {
                task_type_id: task.taskType.id,
                date: task.dateTime ? DateHelper.convertDateToHttpPlayload(task.dateTime) : null,
                time:
                    task.dateTime && task.requireTime ? DateHelper.convertDateToHttpPlayloadTime(task.dateTime) : null,
                airport_id: task.airport ? task.airport.id : null,
                meeting_point: task.meetingPoint ? task.meetingPoint : null,
            };
        });
    }
}
