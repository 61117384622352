<div class="modal-header">
    <button type="button" class="close pull-right" aria-label="Close" (click)="bsModalRef.hide()">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body">
    <div class="modal-detail">
        <div class="modal-detail-title">
            {{ title }}
        </div>
        <div class="modal-detail-content">
            {{ detail }}
        </div>
        <div class="modal-detail-footer">
            <div class="confirm-button-container">
                <button type="button" class="btn btn-secondary confirm-btn" (click)="onSubmit()">ยืนยัน</button>
                <button type="button" class="btn btn-outline-secondary cancle-btn" (click)="onCancel()">ยกเลิก</button>
            </div>
        </div>
    </div>
</div>
