import { ILeadCountByType } from './../../modules/report/models/lead-count-by-type.model';
import { IAddLeadBookingRequest } from './../../modules/report/models/add-lead-request.model';
import { Action, createReducer, on } from '@ngrx/store';
import * as ReportActions from './report.actions';
import { ISearchFilter } from 'src/app/modules/report/models/search-filter.model';
import { ILeadDetails } from 'src/app/modules/report/models/lead-details.model';
import { LeadStatMonthly } from 'src/app/models/lead-stat-monthly.model';
import { ILeadMonthlyReport } from 'src/app/models/lead-monthly-report.model';
import { IOrderBalancePeriod } from 'src/app/models/order-balance-period.model';
import { IOrderBalanceReport, IOrderBalanceReportOverView } from 'src/app/models/order-balance-report.model';
import { OrderBalance } from 'src/app/modules/report/models/order-balance-report.model';

export const reportFeatureKey = 'report';

export interface ReportState {
    searchFilter: ISearchFilter;
    criteria: any;
    leadList: ILeadDetails[];
    loadLeadListError: any;
    pageDetails: any;
    leadId: number;
    addLeadRequestBody: any;
    editLeadRequestBody: any;
    statusSlug: string;
    leadCount: number;
    leadCountByType: ILeadCountByType;
    updatedLeadStatusId: number;
    updatedLeadStatusSlug: string;
    updatedLeadStatusError: any;
    bookingRequestLeadList: any[];
    bookingRequestLoadLeadListError: any;
    leadBookingRequestCount: number;
    updatedBookingRequestLeadStatusId: number;
    updatedBookingRequestLeadStatusSlug: string;
    updatedBookingRequestLeadStatusError: any;
    leadBookingRequestId: number;
    addLeadBookingRequestBody: IAddLeadBookingRequest;
    leadBookingRequestDetailsId: number;
    leadBookingRequestDetailsResponseBody: string;
    leadBookingRequestDetailsError: string;
    summaryLeadList: any[];
    summaryLeadListError: any;
    activeCountData: any;
    activeCountDataError: any;
    productSummaryStatisticsIdList: number[];
    productSummaryStatisticsData: any[];
    productSummaryStatisticsError: any;
    leadStatMonthly: LeadStatMonthly;
    leadStatMonthlyError: any;
    leadStatMonthlyDetails: ILeadMonthlyReport[];
    leadStatMonthlyDetailsError: any;
    orderBalancePeriod: IOrderBalancePeriod;
    orderBalancePeriodError: any;
    orderBalanceReport: IOrderBalanceReportOverView;
    orderBalanceReportError: any;
    orderBalanceReportItems: IOrderBalanceReport;
    orderBalanceReportItemsError: any;
    orderBalance: OrderBalance;
    orderBalanceError: any;
    confirmOrderBalanceSuccessful: any;
    confirmOrderBalanceError: any;
    orderBalanceReceiptUri: string;
}

export const initialState: ReportState = {
    searchFilter: null,
    criteria: null,
    leadList: [],
    loadLeadListError: null,
    pageDetails: null,
    leadId: null,
    addLeadRequestBody: null,
    editLeadRequestBody: null,
    statusSlug: null,
    leadCount: null,
    leadCountByType: null,
    updatedLeadStatusId: null,
    updatedLeadStatusSlug: null,
    updatedLeadStatusError: null,
    bookingRequestLeadList: [],
    bookingRequestLoadLeadListError: null,
    leadBookingRequestCount: null,
    updatedBookingRequestLeadStatusId: null,
    updatedBookingRequestLeadStatusSlug: null,
    updatedBookingRequestLeadStatusError: null,
    leadBookingRequestId: null,
    addLeadBookingRequestBody: null,
    leadBookingRequestDetailsId: null,
    leadBookingRequestDetailsResponseBody: null,
    leadBookingRequestDetailsError: null,
    summaryLeadList: [],
    summaryLeadListError: null,
    activeCountData: null,
    activeCountDataError: null,
    productSummaryStatisticsIdList: [],
    productSummaryStatisticsData: [],
    productSummaryStatisticsError: null,
    leadStatMonthly: null,
    leadStatMonthlyError: null,
    leadStatMonthlyDetails: null,
    leadStatMonthlyDetailsError: null,
    orderBalancePeriod: null,
    orderBalancePeriodError: null,
    orderBalanceReport: null,
    orderBalanceReportError: null,
    orderBalanceReportItems: null,
    orderBalanceReportItemsError: null,
    orderBalance: null,
    orderBalanceError: null,
    confirmOrderBalanceSuccessful: null,
    confirmOrderBalanceError: null,
    orderBalanceReceiptUri: null,
};

export const reportReducer = createReducer(
    initialState,

    on(ReportActions.getLeadList, (state, { searchFilter, criteria }) => {
        return {
            ...state,
            searchFilter,
            criteria,
        };
    }),

    on(ReportActions.loadLeadListSuccess, (state, { leadList, pageDetails, nextPage }) => {
        return {
            ...state,
            leadList: nextPage ? [...state.leadList, ...leadList] : leadList,
            pageDetails,
        };
    }),

    on(ReportActions.loadLeadListError, (state, { error }) => {
        return {
            ...state,
            loadLeadListError: error,
        };
    }),

    on(ReportActions.addNewLead, (state, { requestBody }) => {
        return {
            ...state,
            addLeadRequestBody: requestBody,
        };
    }),

    on(ReportActions.addLeadSuccess, (state, { leadId }) => {
        return {
            ...state,
            leadId,
        };
    }),

    on(ReportActions.addLeadError, (state, { error }) => {
        return {
            ...state,
            error,
        };
    }),

    on(ReportActions.editExistingLead, (state, { requestBody }) => {
        return {
            ...state,
            editLeadRequestBody: requestBody,
        };
    }),

    on(ReportActions.editLeadSuccess, (state, { leadId }) => {
        return {
            ...state,
            leadId,
        };
    }),

    on(ReportActions.editLeadError, (state, { error }) => {
        return {
            ...state,
            error,
        };
    }),

    on(ReportActions.getLeadCount, (state, { statusSlug }) => {
        return {
            ...state,
            statusSlug,
        };
    }),

    on(ReportActions.getLeadCountSuccess, (state, { leadCount }) => {
        return {
            ...state,
            leadCount,
        };
    }),

    on(ReportActions.getLeadCountError, (state, { error }) => {
        return {
            ...state,
            error,
        };
    }),

    on(ReportActions.getLeadCountByType, (state) => {
        return {
            ...state,
        };
    }),

    on(ReportActions.getLeadCountByTypeSuccess, (state, { leadCountByType }) => {
        return {
            ...state,
            leadCountByType,
        };
    }),

    on(ReportActions.getLeadCountByTypeError, (state, { error }) => {
        return {
            ...state,
            error,
        };
    }),

    on(ReportActions.updateLeadStatusSuccess, (state, { leadId, statusSlug }) => {
        return {
            ...state,
            updatedLeadStatusId: leadId,
            updatedLeadStatusSlug: statusSlug,
        };
    }),

    on(ReportActions.updateLeadStatusFailure, (state, { error }) => {
        return {
            ...state,
            updatedLeadStatusError: error,
        };
    }),

    on(ReportActions.getBookingRequestLeadList, (state, { searchFilter, criteria }) => {
        return {
            ...state,
            searchFilter,
            criteria,
        };
    }),

    on(ReportActions.loadBookingRequestLeadListSuccess, (state, { bookingRequestLeadList, pageDetails, nextPage }) => {
        return {
            ...state,
            bookingRequestLeadList: nextPage
                ? [...state.bookingRequestLeadList, ...bookingRequestLeadList]
                : bookingRequestLeadList,
            pageDetails,
        };
    }),

    on(ReportActions.loadBookingRequestLeadListError, (state, { error }) => {
        return {
            ...state,
            bookingRequestLoadLeadListError: error,
        };
    }),

    on(ReportActions.getLeadBookingRequestCount, (state) => {
        return {
            ...state,
        };
    }),

    on(ReportActions.getLeadBookingRequestCountSuccess, (state, { leadBookingRequestCount }) => {
        return {
            ...state,
            leadBookingRequestCount,
        };
    }),

    on(ReportActions.getLeadBookingRequestCountError, (state, { error }) => {
        return {
            ...state,
            error,
        };
    }),

    on(ReportActions.updateBookingRequestLeadStatusSuccess, (state, { leadId, statusSlug }) => {
        return {
            ...state,
            updatedBookingRequestLeadStatusId: leadId,
            updatedBookingRequestLeadStatusSlug: statusSlug,
        };
    }),

    on(ReportActions.updateBookingRequestLeadStatusFailure, (state, { error }) => {
        return {
            ...state,
            updatedBookingRequestLeadStatusError: error,
        };
    }),

    on(ReportActions.addBookingRequestLead, (state, { requestBody }) => {
        return {
            ...state,
            addLeadBookingRequestBody: requestBody,
        };
    }),

    on(ReportActions.addBookingRequestLeadSuccess, (state, { leadBookingRequestId }) => {
        return {
            ...state,
            leadBookingRequestId,
        };
    }),

    on(ReportActions.addBookingRequestLeadError, (state, { error }) => {
        return {
            ...state,
            error,
        };
    }),

    on(ReportActions.getBookingRequestLeadDetails, (state, { leadId }) => {
        return {
            ...state,
            leadBookingRequestDetailsId: leadId,
        };
    }),

    on(ReportActions.getBookingRequestLeadDetailsSuccess, (state, { responseBody }) => {
        return {
            ...state,
            leadBookingRequestDetailsResponseBody: responseBody,
        };
    }),

    on(ReportActions.getBookingRequestLeadDetailsError, (state, { error }) => {
        return {
            ...state,
            leadBookingRequestDetailsError: error,
        };
    }),

    on(ReportActions.getLeadSummaryList, (state, { searchFilter, criteria }) => {
        return {
            ...state,
            searchFilter,
            criteria,
        };
    }),

    on(ReportActions.getLeadSummaryListSuccess, (state, { leadSummaryList, pageDetails, nextPage }) => {
        return {
            ...state,
            summaryLeadList: nextPage ? [...state.summaryLeadList, ...leadSummaryList] : leadSummaryList,
            pageDetails,
        };
    }),

    on(ReportActions.getLeadSummaryListError, (state, { error }) => {
        return {
            ...state,
            summaryLeadListError: error,
        };
    }),

    on(ReportActions.getProductCountForSummary, (state, { searchFilter }) => {
        return {
            ...state,
            searchFilter,
        };
    }),

    on(ReportActions.getProductCountForSummarySuccess, (state, { countData }) => {
        return {
            ...state,
            activeCountData: countData,
        };
    }),

    on(ReportActions.getProductCountForSummaryError, (state, { error }) => {
        return {
            ...state,
            activeCountDataError: error,
        };
    }),

    on(ReportActions.getStatisticsForProductSummary, (state, { idList }) => {
        return {
            ...state,
            productSummaryStatisticsIdList: idList,
        };
    }),

    on(ReportActions.getStatisticsForProductSummarySuccess, (state, { statisticsData }) => {
        return {
            ...state,
            productSummaryStatisticsData: statisticsData,
        };
    }),

    on(ReportActions.getStatisticsForProductSummaryError, (state, { error }) => {
        return {
            ...state,
            productSummaryStatisticsError: error,
        };
    }),

    on(ReportActions.getLeadStatMonthly, (state) => {
        return {
            ...state,
        };
    }),

    on(ReportActions.getLeadStatMonthlySuccess, (state, { leadStatMonthly }) => {
        return {
            ...state,
            leadStatMonthly: leadStatMonthly,
        };
    }),

    on(ReportActions.getLeadStatMonthlyError, (state, { error }) => {
        return {
            ...state,
            leadStatMonthlyError: error,
        };
    }),

    on(ReportActions.getLeadStatMonthlyDetails, (state) => {
        return {
            ...state,
        };
    }),

    on(ReportActions.getLeadStatMonthlyDetailsSuccess, (state, { leadStatMonthlyDetails }) => {
        return {
            ...state,
            leadStatMonthlyDetails: leadStatMonthlyDetails,
        };
    }),

    on(ReportActions.getLeadStatMonthlyDetailsError, (state, { error }) => {
        return {
            ...state,
            leadStatMonthlyDetailsError: error,
        };
    }),

    on(ReportActions.getOrderBalancePeriod, (state) => {
        return {
            ...state,
        };
    }),

    on(ReportActions.getOrderBalance, (state) => {
        return {
            ...state,
        };
    }),

    on(ReportActions.getOrderBalancePeriodSuccess, (state, { data }) => {
        return {
            ...state,
            orderBalancePeriod: data,
        };
    }),

    on(ReportActions.getOrderBalancePeriodError, (state, { error }) => {
        return {
            ...state,
            orderBalancePeriodError: error,
        };
    }),

    on(ReportActions.getOrderBalanceReport, (state) => {
        return {
            ...state,
        };
    }),

    on(ReportActions.getOrderBalanceSuccess, (state, { orderBalance }) => {
        return {
            ...state,
            orderBalance: orderBalance,
        };
    }),

    on(ReportActions.getLeadStatMonthlyDetailsError, (state, { error }) => {
        return {
            ...state,
            orderBalanceError: error,
        };
    }),

    on(ReportActions.confirmOrderBalance, (state) => {
        return {
            ...state,
        };
    }),

    on(ReportActions.getOrderBalanceReportSuccess, (state, { data, nextPage }) => {
        const concatedData = nextPage ? [...state.orderBalanceReport.data, ...data.data] : data.data;
        data.data = concatedData;
        return {
            ...state,
            orderBalanceReport: data,
        };
    }),

    on(ReportActions.getOrderBalanceReportError, (state, { error }) => {
        return {
            ...state,
            orderBalanceReportError: error,
        };
    }),

    on(ReportActions.getOrderBalanceReportItems, (state) => {
        return {
            ...state,
        };
    }),

    on(ReportActions.getOrderBalanceReportItemsSuccess, (state, { data, nextPage }) => {
        const concatedData = nextPage ? [...state.orderBalanceReportItems.data, ...data.data] : data.data;
        data.data = concatedData;
        return {
            ...state,
            orderBalanceReportItems: data,
        };
    }),

    on(ReportActions.getOrderBalanceReportItemsError, (state, { error }) => {
        return {
            ...state,
            orderBalanceReportItemsError: error,
        };
    }),

    on(ReportActions.confirmOrderBalanceSuccess, (state, { result }) => {
        return {
            ...state,
            confirmOrderBalanceSuccessful: result,
        };
    }),

    on(ReportActions.getLeadStatMonthlyDetailsError, (state, { error }) => {
        return {
            ...state,
            confirmOrderBalanceError: error,
        };
    }),

    on(ReportActions.getBalanceReceiptUriSuccessful, (state, { receiptUri }) => {
        return {
            ...state,
            orderBalanceReceiptUri: receiptUri,
        };
    })
);

export function reducer(state: ReportState | undefined, action: Action) {
    return reportReducer(state, action);
}
