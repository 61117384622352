import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '../shared/shared.module';
import { ReportRoutingModule } from './report-routing.module';
import { CategoryTransportIconService } from 'src/app/services/category-transport-icon.service';

// Pages
import { DashboardPageComponent } from './pages/dashboard-page/dashboard-page.component';
import { ExampleInputComponent } from './pages/example-input/example-input.component';
import { LeadListComponent } from './pages/lead-list/lead-list.component';
import { LeadBookingRequestListComponent } from './pages/lead-booking-request-list/lead-booking-request-list.component';
import { LeadBookingSummaryComponent } from './pages/lead-booking-summary/lead-booking-summary.component';
import { LeadReportDownloadComponent } from './pages/lead-report-download/lead-report-download.component';
import { BookingReportDownloadComponent } from './pages/booking-report-download/booking-report-download.component';
import { OrderBalanceTransactionPageComponent } from './pages/order-balance-transaction/order-balance-transaction.component';

// Components
import { SearchFilterComponent } from './components/search-filter/search-filter.component';
import { LeadListingComponent } from './components/lead-listing/lead-listing.component';
import { LeadDetailsComponent } from './components/lead-listing/lead-details/lead-details.component';
import { LeadBookingRequestListingComponent } from './components/lead-booking-request-listing/lead-booking-request-listing.component';
import { LeadBookingRequestDetailsComponent } from './components/lead-booking-request-details/lead-booking-request-details.component';
import { LeadSummaryListingComponent } from './components/lead-summary-listing/lead-summary-listing.component';
import { LeadSummaryDetailsComponent } from './components/lead-summary-listing/lead-summary-details/lead-summary-details.component';
import { OrderBalanceReportFilterComponent } from './components/order-balance-report-filter/order-balance-report-filter.component';
import { OrderBalanceReportTabComponent } from './components/order-balance-report-tab/order-balance-report-tab.component';
import { OrderBalanceReportListingComponent } from './components/order-balance-report-listing/order-balance-report-listing.component';
import { OrderBalanceReportReceiptComponent } from './components/order-balance-report-receipt/order-balance-report-receipt.component';
import { OrderBalanceReportBoxComponent } from './components/order-balance-report-box/order-balance-report-box.component';

// Modals
import { LeadContactInfoComponent } from './modals/b2b-booking/lead-contact-info/lead-contact-info.component';
import { AddBookingComponent } from './modals/lead-list/add-booking/add-booking.component';
import { CancelLeadComponent } from './modals/lead-list/cancel-lead/cancel-lead.component';
import { PassBookingToOwnerComponent } from './modals/b2b-booking/pass-booking-to-owner/pass-booking-to-owner.component';
import { BookingRequestInfoComponent } from './modals/b2b-booking/booking-request-info/booking-request-info.component';
import { OrderBalanceReportPageComponent } from './pages/order-balance-report-page/order-balance-report-page.component';
import { ConfirmPaymentComponent } from './modals/order-balance/confirm-payment/confirm-payment.component';
import { OrderBalanceReportDownloadComponent } from './pages/order-balance-report-download/order-balance-report-download.component';

@NgModule({
    declarations: [
        // Pages
        DashboardPageComponent,
        ExampleInputComponent,
        LeadListComponent,
        LeadBookingRequestListComponent,
        LeadBookingSummaryComponent,
        LeadReportDownloadComponent,
        BookingReportDownloadComponent,
        OrderBalanceTransactionPageComponent,
        // Components
        SearchFilterComponent,
        LeadListingComponent,
        LeadDetailsComponent,
        LeadBookingRequestListingComponent,
        LeadBookingRequestDetailsComponent,
        LeadSummaryListingComponent,
        LeadSummaryDetailsComponent,
        OrderBalanceReportFilterComponent,
        OrderBalanceReportTabComponent,
        OrderBalanceReportListingComponent,
        OrderBalanceReportReceiptComponent,
        OrderBalanceReportBoxComponent,
        // Modals
        LeadContactInfoComponent,
        AddBookingComponent,
        CancelLeadComponent,
        PassBookingToOwnerComponent,
        BookingRequestInfoComponent,
        OrderBalanceReportPageComponent,
        ConfirmPaymentComponent,
        OrderBalanceReportDownloadComponent,
    ],
    imports: [CommonModule, SharedModule, ReportRoutingModule],
    providers: [CategoryTransportIconService],
})
export class ReportModule {}
