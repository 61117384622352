import { ICreateBlog, IEditBlogSubmit } from './../modules/settings/models/blog.model';
import { Injectable, Inject, PLATFORM_ID } from '@angular/core';
import { HttpService } from './base/http.service';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Router } from '@angular/router';
import { Observable, throwError } from 'rxjs';
import { environment } from 'src/environments/environment';
import { switchMap, map } from 'rxjs/operators';
import { ENDPOINTS } from '../constants/endpoint.constant';

@Injectable({
    providedIn: 'root',
})
export class BlogService extends HttpService {
    constructor(
        @Inject(PLATFORM_ID) protected platformId: Object,
        protected http: HttpClient,
        protected router: Router
    ) {
        super(platformId, http, router);
    }

    public getBlogListing(criteria: any): Observable<any> {
        let params = new HttpParams();
        params = params.append('page', criteria.page);
        params = params.append('page_entries', criteria.pageEntries);

        const url = environment.apiProUrl + ENDPOINTS.BLOG.LIST;

        return this.getRequestOptions(true).pipe(
            switchMap((headers) => {
                const options = {
                    headers: headers.headers,
                    params,
                };

                return this.http.get(url, options).pipe(
                    map((resp: any) => {
                        if (resp.status !== 'success') {
                            throwError(resp);
                        }
                        return resp;
                    })
                );
            })
        );
    }

    public getBlogSingle(id: number): Observable<any> {
        const url = environment.apiProUrl + ENDPOINTS.BLOG.SINGLE_ITEM.replace('{blog_id}', id);

        return this.getRequestOptions(true).pipe(
            switchMap((headers) => {
                const options = {
                    headers: headers.headers,
                };

                return this.http.get(url, options).pipe(
                    map((resp: any) => {
                        if (resp.status !== 'success') {
                            throwError(resp);
                        }
                        return resp;
                    })
                );
            })
        );
    }

    public addBlogItem(requestBody: ICreateBlog): Observable<any> {
        const url = environment.apiProUrl + ENDPOINTS.BLOG.ADD_ITEM;

        return this.getRequestOptions(true).pipe(
            switchMap((headers) => {
                const options = {
                    headers: headers.headers,
                };
                return this.http.post(url, requestBody, options);
            })
        );
    }

    public editBlogItem(id: number, requestBody: IEditBlogSubmit): Observable<any> {
        const url = environment.apiProUrl + ENDPOINTS.BLOG.SINGLE_ITEM.replace('{blog_id}', id);

        return this.getRequestOptions(true).pipe(
            switchMap((headers) => {
                const options = {
                    headers: headers.headers,
                };
                return this.http.put(url, requestBody, options);
            })
        );
    }

    public deleteBlogItem(id: number): Observable<any> {
        const url = environment.apiProUrl + ENDPOINTS.BLOG.SINGLE_ITEM.replace('{blog_id}', id);

        return this.getRequestOptions(true).pipe(
            switchMap((headers) => {
                const options = {
                    headers: headers.headers,
                };
                return this.http.delete(url, options);
            })
        );
    }
}
