import { Pipe, PipeTransform } from '@angular/core';
@Pipe({
    name: 'orderByDeal',
})
export class OrderPeriodByDealPipe implements PipeTransform {
    transform(value: any[], ...args: any[]): any {
        const arrayDeal: any[] = [];
        const arrayNotHaveDeal: any[] = [];
        const loop = value.length;
        for (let i = 0; i < value.length; i++) {
            if (value[i].deal) {
                arrayDeal.push(value[i]);
            } else {
                arrayNotHaveDeal.push(value[i]);
            }
        }

        return [...arrayDeal, ...arrayNotHaveDeal];
    }
}
