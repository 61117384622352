export class OrderBalanceReportCriteria implements IOrderBalanceReportCriteria {
    balanceId: string;
    page?: number;
    pageEntries?: number;
    orderBy?: 'order_created_at_desc' | 'partner_name_asc';

    constructor() {
        this.page = 1;
        this.pageEntries = 50;
        this.orderBy = 'order_created_at_desc';
    }
}

export interface IOrderBalanceReportCriteria {
    balanceId: string;
    page?: number;
    pageEntries?: number;
    orderBy?: 'order_created_at_desc' | 'partner_name_asc';
}
