import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { IOrderBalancePeriodItem } from 'src/app/models/order-balance-period.model';
import {
    SearchableDropdownItem,
    SearchableDropdownSettings,
} from 'src/app/modules/shared/components/searchable-dropdown/searchable-dropdown.component';
import { ThaiDateWithoutYearPipe } from 'src/app/pipes/thaidate.pipe';

@Component({
    selector: 'app-order-balance-report-filter',
    templateUrl: './order-balance-report-filter.component.html',
    styleUrls: ['./order-balance-report-filter.component.scss'],
})
export class OrderBalanceReportFilterComponent implements OnInit, OnChanges {
    @Input() yearNumbersView: Array<number>;
    @Input() yearlyPeriodsView: Map<number, IOrderBalancePeriodItem[]>;
    @Output() selectedPeriod = new EventEmitter<IOrderBalancePeriodItem>();
    @Output() applySearch = new EventEmitter<void>();

    public selectedYearNumber: number;
    public selectedPeriodId: number;
    public periodItems: IOrderBalancePeriodItem[];

    public yearDropdownSettings: SearchableDropdownSettings;
    public yearDropdownItems: SearchableDropdownItem[] = [];

    public periodDropdownSettings: SearchableDropdownSettings;
    public periodDropdownItems: SearchableDropdownItem[] = [];

    constructor(private thaiDateWithoutYearPipe: ThaiDateWithoutYearPipe) {}

    ngOnInit(): void {
        this.initDropdownSettings();
    }

    ngOnChanges(changes: SimpleChanges): void {
        if ('yearNumbersView' in changes) {
            if (this.yearNumbersView) {
                this.selectedYearNumber = this.yearNumbersView.length ? this.yearNumbersView[0] : null;
                this.yearDropdownItems = this.yearNumbersView.map((year) => {
                    return new SearchableDropdownItem({
                        id: year,
                        name: (year + 543).toString(),
                        value: year,
                        isChecked: year === this.selectedYearNumber,
                    });
                });
                this.updatePeriodsView();
            }
        }
    }

    private initDropdownSettings(): void {
        const yearDropdownSettings = new SearchableDropdownSettings();
        yearDropdownSettings.dropdownListPlaceholder = 'เลือกปี';
        yearDropdownSettings.hideSearchBox = true;
        yearDropdownSettings.customSearchIcon = '';
        yearDropdownSettings.multipleSelection = false;
        yearDropdownSettings.closeAfterSelect = true;
        yearDropdownSettings.styleSetting.fontSize = '14px';
        yearDropdownSettings.unableToUncheck = true;
        this.yearDropdownSettings = yearDropdownSettings;

        const periodDropdownSettings = new SearchableDropdownSettings();
        periodDropdownSettings.dropdownListPlaceholder = 'เลือกรอบการโอน';
        periodDropdownSettings.hideSearchBox = true;
        periodDropdownSettings.customSearchIcon = '';
        periodDropdownSettings.multipleSelection = false;
        periodDropdownSettings.closeAfterSelect = true;
        periodDropdownSettings.styleSetting.fontSize = '14px';
        periodDropdownSettings.unableToUncheck = true;
        this.periodDropdownSettings = periodDropdownSettings;
    }

    updatePeriodsView(): void {
        this.periodItems = this.yearlyPeriodsView.get(this.selectedYearNumber);
        this.periodDropdownItems = this.periodItems.map((period) => {
            return new SearchableDropdownItem({
                id: period.id,
                name: this.thaiDateWithoutYearPipe.transform(period.transferDueDate),
            });
        });
        this.selectedPeriodId = null;
        this.emitEventSelectedPeriod();
    }

    emitEventSelectedPeriod(): void {
        const periodInfo = this.periodItems.find((i) => i.id === this.selectedPeriodId);
        this.selectedPeriod.emit(periodInfo);
    }

    searchResults(): void {
        this.applySearch.emit();
    }
}
