export interface ILeadCountByType {
    total: number;
    line: number;
    phone: number;
    web: number;
}

export class LeadCountByType {
    public deserialize(responseData: any): ILeadCountByType {
        const leadCountByType: ILeadCountByType = {
            total: 0,
            line: 0,
            phone: 0,
            web: 0,
        };

        if (responseData) {
            responseData.map((leadType: any) => {
                switch (leadType.lead_type_slug) {
                    case 'line':
                        leadCountByType.line = leadType.total;
                        break;
                    case 'phone':
                        leadCountByType.phone = leadType.total;
                        break;
                    case 'web':
                        leadCountByType.web = leadType.total;
                        break;
                    default:
                        break;
                }
                leadCountByType.total += leadType.total;
            });
        }

        return leadCountByType;
    }
}
