import { ICreateBlog, IEditBlogSubmit } from '../modules/settings/models/blog.model';
import { Injectable, Inject, PLATFORM_ID } from '@angular/core';
import { HttpService } from './base/http.service';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Router } from '@angular/router';
import { Observable, throwError } from 'rxjs';
import { environment } from 'src/environments/environment';
import { switchMap, map } from 'rxjs/operators';
import { ENDPOINTS } from '../constants/endpoint.constant';

@Injectable({
    providedIn: 'root',
})
export class BankService extends HttpService {
    constructor(
        @Inject(PLATFORM_ID) protected platformId: Object,
        protected http: HttpClient,
        protected router: Router
    ) {
        super(platformId, http, router);
    }

    public getBanks(): Observable<any> {
        const url = environment.apiProUrl + ENDPOINTS.BANKS.GET;

        return this.getRequestOptions(true).pipe(
            switchMap((headers) => {
                return this.http.get(url).pipe(
                    map((resp: any) => {
                        if (resp.status !== 'success') {
                            throwError(resp);
                        }
                        return resp;
                    })
                );
            })
        );
    }
}
