<div class="payment-info" [formGroup]="paymentForm">
    <div class="payment-info-title">
        <span>ข้อมูลการชำระเงิน</span>
    </div>
    <div class="w-100 mb-3">
        <p class="payment-info-label required w-100">ช่องทางการชำระเงิน</p>
        <div class="form-check form-check-inline">
            <input
                class="form-check-input"
                type="radio"
                name="method"
                id="bank_transfer"
                formControlName="method"
                value="bank_transfer"
            />
            <label class="form-check-label" for="bank_transfer"
                >โอนเงิน<span class="text-xs"> (กสิกรไทย 0358891543)</span></label
            >
        </div>
        <div class="form-check form-check-inline">
            <input
                class="form-check-input"
                type="radio"
                name="method"
                id="cash"
                formControlName="method"
                value="cash"
            />
            <label class="form-check-label" for="cash">เงินสด</label>
        </div>
        <div class="form-check form-check-inline">
            <input
                class="form-check-input"
                type="radio"
                name="method"
                id="cheque"
                formControlName="method"
                value="cheque"
            />
            <label class="form-check-label" for="cheque">เช็ก</label>
        </div>
        <div class="form-check form-check-inline">
            <input
                class="form-check-input"
                type="radio"
                name="method"
                id="other"
                formControlName="method"
                value="other"
            />
            <label class="form-check-label" for="other">อื่นๆ</label>
        </div>
    </div>
    <div class="row mb-3">
        <div class="col-4">
            <label for="exampleFormControlInput1" class="form-label required">ยอดที่ชำระ</label>
            <input
                [ngClass]="{
                    'border-red': isSubmit && f['amount'].invalid
                }"
                type="text"
                class="form-control form-control-sm"
                id="exampleFormControlInput1"
                placeholder="กรอกยอดที่ชำระ"
                formControlName="amount"
                currencyMask
                [options]="{ prefix: '', min: 0, align: 'left' }"
            />
            <small
                *ngIf="
                    (f['amount'].invalid || +f['amount'].value !== +installment.amount) &&
                    (f['amount'].dirty || f['amount'].touched)
                "
                class="alert-text"
                >ยอดกรอกไม่ตรงกับยอดที่ต้องชำระของงวดนี้</small
            >
        </div>
        <div class="col-4">
            <label for="exampleFormControlInput1" class="form-label required">วันที่ชำระ</label>
            <app-calendar-input
                placeholder="เลือกวันที่"
                readonly
                [bsValue]="f['paid_at'].value"
                (bsValueChange)="f['paid_at'].setValue($event)"
                [valid]="!(isSubmit && f['paid_at'].invalid)"
                [ngStyle]="{ 'background-color': '#fff' }"
            ></app-calendar-input>
        </div>
        <div class="col-4">
            <label for="exampleFormControlInput1" class="form-label required">เวลาที่ชำระ</label>
            <timepicker
                [showMeridian]="false"
                [mousewheel]="false"
                [showSpinners]="false"
                formControlName="paid_at"
            ></timepicker>
        </div>
    </div>
    <div class="payment-info-img mb-3">
        <div class="d-flex align-items-baseline">
            <p class="payment-info-label mb-1 mr-2">หลักฐานการชำระเงิน</p>
            <label class="link-blue cursor-pointer text-xs btn mr-2 btn-outline-primary btn-sm">
                <input
                    #fileInput
                    type="file"
                    multiple
                    accept=".jpg,.jpeg,.png,.pdf,.xlxs,.pptx,.xls"
                    (change)="insertImage($event)"
                />
                แนบไฟล์สลิป
            </label>
            <span
                *ngIf="isSubmit && f['method'].value === 'bank_transfer' && f['installments'].value.length === 0"
                class="text-xs color-red"
                >กรณีโอนเงินต้องแนบอย่างน้อย 1 รูป</span
            >
        </div>

        <div class="row img-panel">
            <div *ngFor="let item of f['installments'].value; let i = index" class="col-4">
                <div class="img-cover">
                    <img width="100%" [src]="item.url | sanitizerUrl" alt="" />
                    <fa-icon class="fa-trash" (click)="deleteImage(item)" [icon]="faTrash"></fa-icon>
                </div>
            </div>
        </div>
    </div>
    <div class="payment-info-note mb-3">
        <label for="note" class="form-label">ข้อมูลเพิ่มเติม</label>
        <textarea
            class="form-control form-control-sm"
            id="note"
            placeholder="กรอกข้อมูลเพิ่มเติม"
            rows="3"
            maxlength="500"
            formControlName="note"
        ></textarea>
    </div>

    <ng-container *ngIf="installment.twPaymentEvents | paymentFilterFailure as paymentEvents">
        <div *ngIf="paymentEvents.length > 0" class="mb-3">
            <p class="payment-info-label mb-1">ประวัติการชำระเงินของงวดนี้</p>
            <ul>
                <li *ngFor="let item of paymentEvents">
                    <span *ngIf="getTwPaymentEventById(item.parent_payment_event_id) as event">
                        {{ item.payment_method | paymentMethod }} จำนวน
                        {{ item.amount | currency: '':'':'1.2-2' }} วันที่
                        {{ item.issued_at | thaiDate: 'DD MM YY HH:mm' }}
                        <ng-container *ngFor="let file of event.files">
                            <a [href]="file.url" target="_blank">{{ file.name }}</a
                            >&nbsp;
                        </ng-container>
                        (โดย {{ item.created_by_agency_member.nick_name }}
                        {{ item.created_at | thaiDate: 'DD MM YY HH:mm' }}) <span class="color-red">ยกเลิกแล้ว</span>
                    </span>
                </li>
            </ul>
        </div>
    </ng-container>
</div>
