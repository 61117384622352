<div class="sale-report-list-details d-flex flex-rows border">
    <div class="booking-info p-2 border-right w-75 d-flex flex-rows">
        <div class="runnumber pr-2">
            <span>{{index+1}}</span>
        </div>
        <div class="infos d-flex flex-column">
            <strong class="client-info pb-1 cursor-pointer" (click)="openContactInfoModal()">
                <u><strong>ลูกค้า : {{report.contactName}}</strong></u>
                 <i class="icon icon-info pl-1"></i> จองวันที่ {{report.created | thaiMonthDateTime: true}} ({{report.orderCode}})
            </strong>
            <span class="product-info pb-1">{{report.categoryId | productCategory}} เดินทาง {{report.startDate | thaidaterange: report.endDate}} {{report.tourCode}} {{report.productName}}  </span>
            <span class="agent-info cursor-pointer" (click)="openBusinessContactInfoModal()">
                <u>{{report.productOwner.nameEN}} ({{report.productOwner.nameTH}}) &nbsp;โทร.{{report.productOwner.phoneNumber | phoneNumber}}</u> 
                <i class="icon icon-info pl-1"></i>
            </span>
            <span class="seller-info">
                เซลล์ผู้ขาย : {{report.sellerFullName}}
            </span>
        </div>
    </div>
    <div class="total-bookings d-flex justify-content-center p-2 border-right  w-15">
        <span>{{report.totalSumPrice | mycurrency}}</span>
    </div>
    <div class="total-passengers d-flex justify-content-center  p-2 border-right  w-15">
        <span>{{report.totalSumQuantity}} ท่าน</span>
    </div>
    <div class="booking-link d-flex justify-content-center  p-2  w-15">
        <button class="btn btn-sm-blue" (click)="viewMoreDetail()">ดูข้อมูล</button>
    </div>
</div>
