export interface IBookingMonthlyReport {
    runNumber: number;
    bookingCode: string;
    createdDate: string;
    createdTime: string;
    leadTypeSlug: string;
    orderStatusKey: string;
    tourCode: string;
    programName: string;
    startAt: string;
    endAt: string;
    commissionCompany: number;
    commissionSeller: number;
    commissionSumTotal: number;
    programType: string;
    country: string;
    province: string;
    categoryTransport?: any;
    airline?: any;
    price: number;
    qty: number;
    sumTotal: number;
    installmentQty: number;
    installmentDate1: string;
    installmentAmount1: number;
    installmentDate2: string;
    installmentAmount2: number;
    installmentDate3: string;
    installmentAmount3: number;
    installmentDate4: string;
    installmentAmount4: number;
    sellerName: string;
    sellerNickName: string;
    contactName: string;
    contactNumber: string;
    contactEmail: string;
    contactRemarks: string;
    programOwnerName: string;
    programOwnerPhoneNumber: string;
    cancelDate: string;
    cancelTime: string;
    cancelBy: string;
}

export class BookingMonthlyReport implements IBookingMonthlyReport {
    runNumber: number;
    bookingCode: string;
    createdDate: string;
    createdTime: string;
    leadTypeSlug: string;
    orderStatusKey: string;
    tourCode: string;
    programName: string;
    startAt: string;
    endAt: string;
    commissionCompany: number;
    commissionSeller: number;
    commissionSumTotal: number;
    programType: string;
    country: string;
    province: string;
    categoryTransport?: any;
    airline?: any;
    price: number;
    qty: number;
    sumTotal: number;
    installmentQty: number;
    installmentDate1: string;
    installmentAmount1: number;
    installmentDate2: string;
    installmentAmount2: number;
    installmentDate3: string;
    installmentAmount3: number;
    installmentDate4: string;
    installmentAmount4: number;
    sellerName: string;
    sellerNickName: string;
    contactName: string;
    contactNumber: string;
    contactEmail: string;
    contactRemarks: string;
    programOwnerName: string;
    programOwnerPhoneNumber: string;
    cancelDate: string;
    cancelTime: string;
    cancelBy: string;

    public deserialize(obj: any[]): IBookingMonthlyReport[] {
        return obj.map((data, index) => {
            const commissionSumTotal =
                (Number(data.commission_company) + Number(data.commission_seller)) * Number(data.quantity);
            const country = data.countries.length ? data.countries.map((m) => m.name_th).join(',') : '';
            const province = data.provinces.length ? data.provinces.map((m) => m.name_th).join(',') : '';
            let orderStatus = '';
            switch (data.order_status_key) {
                case 'waiting_first_paid':
                    orderStatus = 'รอชำระเงินงวดแรก';
                    break;
                case 'waiting_full_paid':
                    orderStatus = 'รอชำระเงินครบ';
                    break;
                case 'complete':
                    orderStatus = new Date(data.start_date).getTime() > Date.now() ? 'เดินทางแล้ว' : 'รอเดินทาง';
                    break;
                case 'cancel':
                    orderStatus = 'ยกเลิก';
                    break;
            }

            let programType = '';
            switch (data.program_type) {
                case 'join_tour':
                    programType = 'ทัวร์ต่างประเทศ (Outbound Join Tour)';
                    break;
                case 'package_tour':
                    programType = 'แพ็กเกจทัวร์ต่างประเทศ (Outbound Package Tour)';
                    break;
                case 'package_domestic':
                    programType = 'ทัวร์ในประเทศ (Domestic Tour)';
                    break;
                case 'other':
                    programType = 'สินค้านอกระบบ (Other)';
                    break;
            }

            let cancelBy = '';
            if (data.cancel_by) {
                cancelBy += data.cancel_by.first_name || '';
                cancelBy += data.cancel_by.last_name ? ` ${data.cancel_by.last_name}` : '';
                cancelBy += data.cancel_by.nick_name ? ` (${data.cancel_by.nick_name})` : '';
            }

            return {
                runNumber: index + 1,
                bookingCode: data.booking_code,
                createdDate: data.created_date,
                createdTime: data.created_time,
                leadTypeSlug: data.lead_type_slug,
                orderStatusKey: orderStatus,
                tourCode: data.tour_code,
                programName: data.program_name,
                startAt: data.start_at,
                endAt: data.end_at,
                commissionCompany: Number(data.commission_company),
                commissionSeller: Number(data.commission_seller),
                commissionSumTotal: commissionSumTotal,
                programType: programType,
                country: data.program_type === 'package_domestic' ? 'ไทย' : country,
                province: province,
                categoryTransport: data.category_transport ? data.category_transport.name : '',
                airline: data.airline ? `${data.airline.name} (${data.airline.code})` : '',
                price: data.price ? Number(data.price) : undefined,
                qty: Number(data.quantity),
                sumTotal: Number(data.sum_total),
                installmentQty: data.installments.length,
                installmentDate1: data.installments.length >= 1 ? data.installments[0].payment_duedate_at : '',
                installmentAmount1: data.installments.length >= 1 ? Number(data.installments[0].total_paid) : null,
                installmentDate2: data.installments.length >= 2 ? data.installments[1].payment_duedate_at : '',
                installmentAmount2: data.installments.length >= 2 ? Number(data.installments[1].total_paid) : null,
                installmentDate3: data.installments.length >= 3 ? data.installments[2].payment_duedate_at : '',
                installmentAmount3: data.installments.length >= 3 ? Number(data.installments[2].total_paid) : null,
                installmentDate4: data.installments.length >= 4 ? data.installments[3].payment_duedate_at : '',
                installmentAmount4: data.installments.length >= 4 ? Number(data.installments[3].total_paid) : null,
                sellerName: data.seller ? `${data.seller.first_name || ''} ${data.seller.last_name || ''}` : '',
                sellerNickName: data.seller ? data.seller.nick_name : '',
                contactName: data.contact_name,
                contactNumber: data.contact_number,
                contactEmail: data.contact_email,
                contactRemarks: data.contact_remarks,
                programOwnerName: data.program_owner
                    ? `${data.program_owner.name_en} (${data.program_owner.name_th})`
                    : '',
                programOwnerPhoneNumber: data.program_owner ? data.program_owner.phone_number : '',
                cancelDate: data.cancel_date,
                cancelTime: data.cancel_time,
                cancelBy: cancelBy,
            };
        });
    }
}
