<div class="booking-result" *ngIf="twBooking">
    <div class="d-flex booking-result-title">
        <strong class="text-xxl w-100 pr-4">คุณกำลังดู Booking ทั้งหมด {{ twBooking?.total }} Booking</strong>
        <button class="btn btn-default" routerLink="/order/tw-booking/add">
            <i class="icon icon-plus-circle mr-1"></i>สร้างOrder
        </button>
    </div>
    <div class="booking-list pb-4" *ngIf="twBooking?.result?.length">
        <div class="d-flex booking-list-head">
            <span class="pl-4" style="width: 40%">Order</span>
            <span class="text-start" style="width: 20%">ยอดจอง</span>
            <span class="text-start" style="width: 20%">งวดแรก</span>
            <span class="text-start" style="width: 20%">งวดที่เหลือ (ถ้ามี)</span>
        </div>
        <ng-container *ngFor="let booking of twBooking?.result; let index = index">
            <div class="d-flex booking-list-content">
                <span class="number-col text-center line-right" style="width: 3%">
                    <div>
                        <span class="mb-1"> {{ index + 1 }}. </span>
                    </div>
                    <div class="icon-pin">
                        <figure *ngIf="!booking.isPinned" class="cursor-pointer" (click)="onChangePin(booking, true)">
                            <img width="18px" src="/assets/img/icon/unpin.png" />
                        </figure>
                        <figure *ngIf="booking.isPinned" class="cursor-pointer" (click)="onChangePin(booking, false)">
                            <img width="18px" src="/assets/img/icon/pin.png" />
                        </figure>
                    </div>
                    <div>
                        <a [routerLink]="['/order/tw-booking/edit/', booking?.orderCode]">
                            <i class="icon icon-edit mt-1"></i>
                        </a>
                    </div>
                    <div>
                        <figure *ngIf="!booking.hasTask" class="cursor-pointer" (click)="openWorkListModal(booking)">
                            <img width="18px" src="/assets/img/icon/icon-bell-inactive.svg" />
                        </figure>
                        <figure *ngIf="booking.hasTask" class="cursor-pointer" (click)="openWorkListModal(booking)">
                            <img width="18px" src="/assets/img/icon/icon-bell-active.svg" />
                        </figure>
                    </div>
                </span>
                <span class="d-flex flex-column line-right" style="width: 37%">
                    <span class="mb-1">
                        <strong class="cursor-pointer" (click)="openCustomerInfoModal(booking?.customer)"
                            >ลูกค้า : {{ booking?.customer?.name }}</strong
                        >

                        จองวันที่ {{ booking?.createdAt | thaiDate: 'DD MM YY HH:mm' }}
                        <a class="cursor-pointer" [href]="booking.orderDetailPageUrl" target="_blank">
                            ({{ booking?.orderCode }})
                        </a>
                    </span>
                    <span class="text-xs mb-1">
                        {{ booking?.product?.categorySubProductId | productCategoryTwBooking }} เดินทาง
                        {{ booking?.productPeriod?.startAt | thaidaterange: booking?.productPeriod?.endAt }}
                        {{ booking?.product?.tourCode }} {{ booking?.product?.name }}</span
                    >
                    <span class="text-xs mb-1">
                        <span class="text-dark-light cursor-pointer" (click)="openSupplierInfoModal(booking?.supplier)">
                            {{ booking?.supplier?.nameEn }} ({{ booking?.supplier?.nameTh }})
                        </span>

                        <span
                            *ngIf="booking?.supplier?.config?.onlineBookingUrl"
                            class="cursor-pointer"
                            (click)="goToOnlineBooking(booking?.supplier?.config?.onlineBookingUrl)"
                            >ลิงก์จอง Online Booking <i class="icon icon-info"></i
                        ></span>
                    </span>
                    <span class="text-xs"
                        ><span class="text-dark-light mr-1" *ngIf="booking?.agencyMember?.firstName"
                            >เซลล์ Tourwow : {{ booking?.agencyMember?.firstName }} ({{
                                booking?.agencyMember?.nickName
                            }})
                        </span>
                        <span class="text-dark-light mr-1" *ngIf="!booking?.agencyMember?.firstName"
                            >เซลล์ Tourwow : -
                        </span>
                    </span>
                    <span class="text-xs d-flex mb-1"
                        ><span
                            class="edit-note-link text-dark-light mr-1 cursor-pointer"
                            (click)="openNoteModal(booking)"
                            >Note
                            <i class="icon icon-edit icon-edit-note mt-1"></i>
                        </span>
                        <span class="note mt-1" [innerText]="booking?.note"></span>
                    </span>
                    <span class="text-xs" *ngIf="booking.useVat">
                        <span class="text-dark-light mr-1">ขอใบกำกับภาษี </span>
                    </span>
                    <span *ngIf="booking?.discount" class="red-text discount-font">
                        ส่วนลด {{ booking?.discount | number: '1.0-0' }} บาท
                    </span>
                    <span class="text-xs mb-1">
                        <span
                            class="text-dark-light cursor-pointer"
                            *ngIf="booking?.orderStatus !== 'canceled'"
                            (click)="onCancelOrder(booking)"
                        >
                            คลิกที่นี่เพื่อยกเลิก
                        </span>
                        <span class="red-text" *ngIf="booking?.orderStatus === 'canceled'">
                            ยกเลิกแล้ว โดย : {{ booking?.canceledByAgencyMember?.firstName }} ({{
                                booking?.canceledByAgencyMember?.nickName
                            }}) เมื่อวันที่ {{ booking?.canceledAt | thaiDate: 'DD MM YY HH:mm' }}
                        </span>
                    </span>
                </span>
                <span class="d-flex flex-column line-right" style="width: 20%">
                    <span class="mb-1">
                        สถานะ : {{ booking.orderStatus | orderStatus
                        }}<a class="link-note cursor-pointer" (click)="onChangeOrderStatus(booking)">
                            <i class="icon icon-edit icon-edit-status mt-1"></i>
                        </a>
                    </span>
                    <span class="mb-1">
                        {{ booking?.net_amount | number: '1.2-2' }}
                    </span>
                    <span class="mb-1"> ผู้เดินทาง {{ booking?.quantity }} ท่าน </span>
                    <a class="cursor-pointer text-xs mb-2 link-blue" [href]="booking.orderDetailPageUrl" target="_blank"
                        >ลิงก์ Order Detail (ลูกค้า)</a
                    >
                    <span
                        *ngIf="booking?.customerOrderInstallments[0].status === 'paid'"
                        class="cursor-pointer text-xs mb-2 link-blue"
                        (click)="openPdfPreviewModal(booking.orderCode, booking.product.tourCode)"
                        >ดูใบยืนยันรับเงิน (สำหรับลูกค้า)</span
                    >
                    <span class="mb-1">
                        <span class="text-sm text-bold">แนบ Invoice จาก WS </span>
                        <label class="link-blue cursor-pointer text-xs">
                            <input
                                type="file"
                                (change)="onUploadInvoiceFiles($event, booking)"
                                multiple
                                accept=".jpg,.jpeg,.png,.pdf,.xlxs,.pptx,.xls"
                            />
                            แนบไฟล์
                        </label>
                    </span>
                    <span class="text-xs" *ngFor="let file of booking.orderFiles">
                        <span>{{ file.fileType | fileType }} </span>
                        <span class="cursor-pointer" (click)="downloadFile(file)">{{ file.fileName }}</span
                        ><i class="icon icon-trash cursor-pointer" (click)="onDeleteInvoiceFile(file, booking)"></i>
                    </span>
                </span>
                <span class="d-flex flex-column line-right" style="width: 20%">
                    <span> งวดที่ 1 : {{ booking?.customerOrderInstallments[0]?.amount | number: '1.2-2' }} </span>
                    <span class="mb-2">
                        <!--  -->
                        <ng-container *ngIf="booking?.customerOrderInstallments[0]?.status === 'pending'">
                            <div
                                *ngIf="
                                    booking?.customerOrderInstallments[0]?.twPaymentEvents &&
                                        booking?.customerOrderInstallments[0]?.twPaymentEvents.length > 0 &&
                                        booking?.customerOrderInstallments[0]?.twPaymentEvents[
                                            booking?.customerOrderInstallments[0]?.twPaymentEvents.length - 1
                                        ].event_type === 'pending';
                                    else waitPayment
                                "
                            >
                                <span class="color-orange">รออนุมัติ</span>
                                <i class="pending-status"></i>
                                <span
                                    class="cursor-pointer text-xs"
                                    (click)="
                                        openManualPaymentModal('view', booking, booking?.customerOrderInstallments[0])
                                    "
                                    >คลิกดูหลักฐาน</span
                                >
                            </div>
                            <ng-template #waitPayment>
                                <div class="display-block">
                                    <span>รอชำระเงิน</span>
                                    <i class="pending-status"></i>
                                    <span
                                        class="cursor-pointer text-xs"
                                        (click)="
                                            openManualPaymentModal(
                                                'form',
                                                booking,
                                                booking?.customerOrderInstallments[0]
                                            )
                                        "
                                        >แจ้งชำระเงิน</span
                                    >
                                </div>
                            </ng-template>
                        </ng-container>

                        <ng-container *ngIf="booking?.customerOrderInstallments[0]?.status === 'paid'">
                            <div class="display-block">
                                <span>ชำระเงินแล้ว</span>
                                <i class="paid-status"></i>
                                <span
                                    *ngIf="booking?.customerOrderInstallments[0]?.paymentChannel === 'tourwow'"
                                    class="cursor-pointer text-xs"
                                    (click)="
                                        openManualPaymentModal('view', booking, booking?.customerOrderInstallments[0])
                                    "
                                    >คลิกดูหลักฐาน</span
                                >
                            </div>
                        </ng-container>
                        <!--  -->

                        <span class="text-xs display-block"
                            >(ครบกำหนดวันที่ {{ booking?.customerOrderInstallments[0]?.dueDate | thaiDate }})</span
                        >
                        <span
                            *ngIf="booking?.customerOrderInstallments[0].status === 'paid'"
                            class="text-xs display-block"
                        >
                            {{
                                booking?.customerOrderInstallments[0]?.paymentChannel === 'tourwow'
                                    ? 'Manual'
                                    : booking?.customerOrderInstallments[0]?.paymentChannel
                            }},
                            {{
                                booking?.customerOrderInstallments[0]?.paymentMethod === 'bank_transfer'
                                    ? 'โอนเงิน'
                                    : (booking?.customerOrderInstallments[0]?.paymentMethod | paymentMethod)
                            }}
                            (ชำระเงินแล้ว
                            {{ booking?.customerOrderInstallments[0]?.paidAt | thaiDate: 'DD MM YY HH:mm' }})
                        </span>
                    </span>
                    <div class="mb-1">
                        <span class="text-xs mr-1">แนบ/ดูหลักฐาน</span>
                        <label class="link-blue cursor-pointer text-xs">
                            <input
                                type="file"
                                (change)="
                                    onUploadCustomerInstallmentFiles(
                                        $event,
                                        booking,
                                        booking.customerOrderInstallments[0]
                                    )
                                "
                                multiple
                                accept=".jpg,.jpeg,.png,.pdf,.xlxs,.pptx,.xls"
                            />
                            แนบไฟล์
                        </label>
                    </div>
                    <div
                        class="text-xs d-block"
                        *ngFor="let file of booking.customerOrderInstallments[0].filesForDisplay"
                    >
                        <span>{{ file.fileType | fileType }} </span>
                        <span class="cursor-pointer" (click)="downloadFile(file)">{{ file.fileName }}</span
                        ><i
                            class="icon icon-trash cursor-pointer"
                            (click)="onDeleteInstallmentFile(booking, booking.customerOrderInstallments[0], file)"
                        ></i>
                    </div>
                </span>
                <span class="d-flex flex-column line-right" style="width: 20%">
                    <div
                        *ngFor="let installment of booking.customerOrderInstallments | slice: 1"
                        class="installment-items mb-2"
                    >
                        <span> งวดที่ {{ installment?.ordinal }} : {{ installment?.amount | number: '1.2-2' }} </span>
                        <div class="mb-2">
                            <!--  -->
                            <ng-container *ngIf="installment.status === 'pending'">
                                <div
                                    *ngIf="
                                        installment.twPaymentEvents &&
                                            installment.twPaymentEvents.length > 0 &&
                                            installment.twPaymentEvents[installment.twPaymentEvents.length - 1]
                                                .event_type === 'pending';
                                        else waitPayment
                                    "
                                >
                                    <span class="color-orange">รออนุมัติ</span>
                                    <i class="pending-status"></i>
                                    <span
                                        class="cursor-pointer text-xs"
                                        (click)="openManualPaymentModal('view', booking, installment)"
                                        >คลิกดูหลักฐาน</span
                                    >
                                </div>
                                <ng-template #waitPayment>
                                    <div class="display-block">
                                        <span>รอชำระเงิน</span>
                                        <i class="pending-status"></i>
                                        <span
                                            class="cursor-pointer text-xs"
                                            (click)="openManualPaymentModal('form', booking, installment)"
                                            >แจ้งชำระเงิน</span
                                        >
                                    </div>
                                </ng-template>
                            </ng-container>

                            <ng-container *ngIf="installment.status === 'paid'">
                                <div class="display-block">
                                    <span>ชำระเงินแล้ว</span>
                                    <i class="paid-status"></i>
                                    <span
                                        *ngIf="installment.paymentChannel === 'tourwow'"
                                        class="cursor-pointer text-xs"
                                        (click)="openManualPaymentModal('view', booking, installment)"
                                        >คลิกดูหลักฐาน</span
                                    >
                                </div>
                            </ng-container>
                            <!--  -->
                            <span class="text-xs display-block"
                                >(ครบกำหนดวันที่ {{ installment?.dueDate | thaiDate }})</span
                            >
                            <span *ngIf="installment.status === 'paid'" class="text-xs display-block"
                                >{{ installment.paymentChannel === 'tourwow' ? 'Manual' : installment.paymentChannel }},
                                {{
                                    installment.paymentMethod === 'bank_transfer'
                                        ? 'โอนเงิน'
                                        : (installment.paymentMethod | paymentMethod)
                                }}
                                (ชำระเงินแล้ว {{ installment.paidAt | thaiDate: 'DD MM YY HH:mm' }})</span
                            >
                        </div>
                        <div class="mb-1">
                            <span class="text-xs mr-1">แนบ/ดูหลักฐาน</span>
                            <label class="link-blue cursor-pointer text-xs">
                                <input
                                    type="file"
                                    (change)="onUploadCustomerInstallmentFiles($event, booking, installment)"
                                    multiple
                                    accept=".jpg,.jpeg,.png,.pdf,.xlxs,.pptx,.xls"
                                />
                                แนบไฟล์
                            </label>
                        </div>
                        <div class="text-xs d-block" *ngFor="let file of installment.filesForDisplay">
                            <span>{{ file.fileType | fileType }} </span>
                            <span class="cursor-pointer" (click)="downloadFile(file)">{{ file.fileName }}</span
                            ><i
                                class="icon icon-trash cursor-pointer"
                                (click)="onDeleteInstallmentFile(booking, installment, file)"
                            ></i>
                        </div>
                    </div>
                </span>
            </div>
            <div class="line-separetor"></div>
        </ng-container>
    </div>
</div>
