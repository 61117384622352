<div class="input-tag" (click)="focusInputTag()">
    <div class="tags">
        <ul>
            <li *ngFor="let tag of tags">
                {{ tag }} <span (click)="removeTag(tag)"><i class="icon icon-close-circle"></i></span>
            </li>
            <li class="input">
                <input
                    #inputTag
                    type="text"
                    placeholder="{{ placeholder }}"
                    [(ngModel)]="tag"
                    (keyup.enter)="onEnter($event)"
                    [size]="getInputTagSize()"
                    [appUnallowedCharacters]="[',']"
                />
            </li>
        </ul>
    </div>
</div>
