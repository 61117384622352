import { IOrderCommissionSummary, IOrderSummary } from './../../models/order-summary.model';
import { createAction, props } from '@ngrx/store';
import { IBookingMonthlyReport } from 'src/app/models/booking-monthly-report.model';
import { BookingStatMonthly } from 'src/app/models/booking-stat-monthly.model';
import { OrderDetail } from 'src/app/models/order.model';
import { IAddBookingB2c } from 'src/app/modules/order/models/add-booking-b2c.model';
import { IBookingListQueries, IBookingList } from 'src/app/modules/order/models/booking-list.model';
import { IBookingReportDownloadSearch } from 'src/app/modules/report/models/booking-report-download-search.model';
import { IBookingStatusCount } from 'src/app/modules/order/models/booking-status-count.model';
import { IEditBookingB2c } from 'src/app/modules/order/models/edit-booking-b2c.model';
import { ProductOwnerListOptional } from 'src/app/modules/order/models/product-owner-list.model';
import { ProductListOptional } from 'src/app/modules/order/models/product-list.model';
import {
    ISaleReportListPaginatedQueries,
    ISaleReportSummaryQueries,
    SaleReportListPaginated,
    SaleReportSummary,
} from 'src/app/modules/order/models/sale-report.model';
import { IOrderSummarySearch } from 'src/app/modules/report/models/order-summary-search.model';
import { IGroupPlanListQueries } from 'src/app/modules/order/models/group-plan-list-queries';
import { IGroupPlanList } from 'src/app/modules/order/models/group-plan-list.model';
import { ICommissionReportListQueries } from 'src/app/modules/order/models/commission-report-list-queries';
import { ICommissionReportList } from 'src/app/modules/order/models/commission-report-list.model';
import {
    ICampaignPaymentPaginated,
    ICampaignPaymentPaginatedFilter,
} from 'src/app/modules/order/models/campaign-payment.model';
import {
    CountOrderStatus,
    CountProductSubCategory,
    ProductOrderListPaginated,
} from 'src/app/modules/order/models/product-order-list.model';

export const getOrderById = createAction('[Order] Fetching order by id', props<{ orderId: number }>());

export const getOrderByIdSuccess = createAction(
    '[Order] Order by id loaded successfully',
    props<{ result: OrderDetail }>()
);

export const getOrderByIdError = createAction('[Order] Order by id loaded unsuccessfully', props<{ error: any }>());

export const createNewBookingOrder = createAction(
    '[Report] User submits a new booking order',
    props<{ postBody: IAddBookingB2c }>()
);

export const createNewBookingOrderSuccess = createAction(
    '[Report] User submits a new booking order successfully',
    props<{ newOrderId: number }>()
);

export const createNewBookingOrderFailure = createAction(
    '[Report] User submits a new booking order unsuccessfully',
    props<{ error: any }>()
);

export const getBookingStatMonthly = createAction(
    '[Report] User tries to get booking stat monthly',
    props<{ criteria?: IBookingReportDownloadSearch }>()
);

export const getBookingStatMonthlySuccess = createAction(
    '[Report] Booking stat monthly fetched successfully',
    props<{ bookingStatMonthly: BookingStatMonthly }>()
);

export const getBookingStatMonthlyError = createAction(
    '[Report] Booking stat monthly fetched unsuccessfully',
    props<{ error: any }>()
);

export const getBookingStatMonthlyDetails = createAction(
    '[Report] User tries to get booking stat monthly details',
    props<{ criteria?: IBookingReportDownloadSearch }>()
);

export const getBookingStatMonthlyDetailsSuccess = createAction(
    '[Report] Booking stat monthly details fetched successfully',
    props<{ bookingStatMonthlyDetails: IBookingMonthlyReport[] }>()
);

export const getBookingStatMonthlyDetailsError = createAction(
    '[Report] Booking stat monthly details fetched unsuccessfully',
    props<{ error: any }>()
);

export const getBookingListPaginated = createAction(
    '[Report] User tries to get the booking list paginated',
    props<{ queries: IBookingListQueries }>()
);

export const getBookingListPaginatedSuccess = createAction(
    '[Report] Booking list paginated fetched successfully',
    props<{ bookingList: IBookingList }>()
);

export const getBookingListPaginatedError = createAction(
    '[Report] Booking list paginated fetched unsuccessfully',
    props<{ error: any }>()
);

export const getBookingStatusCount = createAction(
    '[Report] User tries to get the booking status count',
    props<{ dates: { min_date: string; max_date: string } }>()
);

export const getBookingStatusCountSuccess = createAction(
    '[Report] Booking status count fetched successfully',
    props<{ bookingStatusCount: IBookingStatusCount }>()
);

export const getBookingStatusCountError = createAction(
    '[Report] Booking status count fetched unsuccessfully',
    props<{ error: any }>()
);

export const deleteBookingFile = createAction(
    '[Report] User tries to delete a booking file',
    props<{ bookingOrderId: number; fileId: number }>()
);

export const deleteBookingFileSuccess = createAction(
    '[Report] User deletes a booking file successfully',
    props<{ deletedBookingFile: number }>()
);

export const deleteBookingFileError = createAction(
    '[Report] User deletes a booking file unsuccessfully',
    props<{ error: any }>()
);

export const modifyBookingStatus = createAction(
    '[Report] User tries to modify a booking status',
    props<{ bookingOrderId: number; status: number }>()
);

export const modifyBookingStatusSuccess = createAction(
    '[Report] User modifies a booking status successfully',
    props<{ modifiedBookingId: number }>()
);

export const modifyBookingStatusError = createAction(
    '[Report] User modifies a booking status unsuccessfully',
    props<{ error: any }>()
);

export const bookingUploadFile = createAction(
    '[Report] User tries to upload a file to their booking',
    props<{ bookingOrderId: number; formData: FormData }>()
);

export const bookingUploadFileSuccess = createAction(
    '[Report] User uploads a file to their booking successfully',
    props<{ bookingNewFile: { bookingId: number; id: number; url: string } }>()
);

export const bookingUploadFileError = createAction(
    '[Report] User uploads a file to their booking unsuccessfully',
    props<{ error: any }>()
);

export const editExistingBooking = createAction(
    '[Report] User edits a booking order',
    props<{ id: number; postBody: IEditBookingB2c }>()
);

export const editExistingBookingSuccess = createAction(
    '[Report] User edits a booking order successfully',
    props<{ editedOrderId: number }>()
);

export const editExistingBookingFailure = createAction(
    '[Report] User edits a booking order unsuccessfully',
    props<{ error: any }>()
);

export const getProductList = createAction(
    '[Order] Fetching product list',
    props<{ owner: { id: number; channel: 'ws' | 'ag' }; hideLoadingIcon: boolean }>()
);

export const getProductListSuccess = createAction(
    '[Order] Product list loaded successfully',
    props<{ result: ProductListOptional[] }>()
);

export const getProductListError = createAction('[Order] Product list loaded unsuccessfully', props<{ error: any }>());

export const getProductOwnerList = createAction(
    '[Order] Fetching product owner list',
    props<{ hideLoadingIcon: boolean }>()
);

export const getProductOwnerListSuccess = createAction(
    '[Order] Product owner list loaded successfully',
    props<{ result: ProductOwnerListOptional[] }>()
);

export const getProductOwnerListError = createAction(
    '[Order] Product owner list loaded unsuccessfully',
    props<{ error: any }>()
);

export const getSaleReportSummary = createAction(
    '[Order] Fetching sale report summary',
    props<{ query: ISaleReportSummaryQueries }>()
);

export const getSaleReportSummarySuccess = createAction(
    '[Order] Sale report summary loaded successfully',
    props<{ result: SaleReportSummary[] }>()
);

export const getSaleReportSummaryError = createAction(
    '[Order] Sale report summary loaded unsuccessfully',
    props<{ error: any }>()
);

export const getSaleReportListPaginated = createAction(
    '[Order] Fetching sale report list paginated',
    props<{ query: ISaleReportListPaginatedQueries }>()
);

export const getSaleReportListPaginatedSuccess = createAction(
    '[Order] Sale report list paginated loaded successfully',
    props<{ result: SaleReportListPaginated }>()
);

export const getSaleReportListPaginatedError = createAction(
    '[Order] Sale report list paginated loaded unsuccessfully',
    props<{ error: any }>()
);
export const getOrderSummaries = createAction(
    '[Report] User tries to get order summaries',
    props<{ criteria?: IOrderSummarySearch }>()
);

export const getOrderSummariesSuccess = createAction(
    '[Report] Order summaries fetched successfully',
    props<{ orderSummaries: IOrderSummary[] }>()
);

export const getOrderSummariesFailure = createAction(
    '[Report] Order summaries fetched unsuccessfully',
    props<{ error: any }>()
);

export const selectOrderSummaries = createAction(
    '[Report] User select order summaries',
    props<{ orderSummarySelect: any }>()
);

export const getGroupPlanListPaginated = createAction(
    '[Report] User try to get the group plan list paginated',
    props<{ groupPlanListQueries: IGroupPlanListQueries; isNextPage: boolean }>()
);

export const getGroupPlanListPaginatedSuccess = createAction(
    '[Report] Group plan list paginated fetched successfully',
    props<{ groupPlanList: IGroupPlanList; isNextPage: boolean }>()
);

export const getGroupPlanListPaginatedError = createAction(
    '[Report] Group plan list paginated fetched unsuccessfully',
    props<{ error: any }>()
);

export const getOrderCommissionSummaries = createAction(
    '[Report] User tries to get order commission summaries',
    props<{ criteria?: IOrderSummarySearch }>()
);

export const getOrderCommissionSummariesSuccess = createAction(
    '[Report] Order commission summaries fetched successfully',
    props<{ orderCommissionSummaries: IOrderCommissionSummary[] }>()
);

export const getOrderCommissionSummariesFailure = createAction(
    '[Report] Order commission summaries fetched unsuccessfully',
    props<{ error: any }>()
);

export const selectOrderCommissionSummaries = createAction(
    '[Report] User select order commission summaries',
    props<{ orderCommissionSummarySelect: any }>()
);

export const getCommissionReportListPaginated = createAction(
    '[Report] User try to get the commission port list paginated',
    props<{ commissionReportListQueries: ICommissionReportListQueries; isNextPage: boolean }>()
);

export const getCommissionReportListPaginatedSuccess = createAction(
    '[Report] Commission port list paginated fetched successfully',
    props<{ commissionReportList: ICommissionReportList; isNextPage: boolean }>()
);

export const getCommissionReportListPaginatedError = createAction(
    '[Report] Commission port list paginated fetched unsuccessfully',
    props<{ error: any }>()
);

export const getCampaignPaymentPaginated = createAction(
    '[Order] User try to get the campaign payment list paginated',
    props<{ filter: ICampaignPaymentPaginatedFilter }>()
);

export const getCampaignPaymentPaginatedSuccess = createAction(
    '[Order] Campaign payment list paginated fetched successfully',
    props<{ paginated: ICampaignPaymentPaginated }>()
);

export const getCampaignPaymentPaginatedError = createAction(
    '[Order] Campaign payment list paginated fetched unsuccessfully',
    props<{ error: any }>()
);

export const getCampaignPaymentCount = createAction('[Order] User try to get the campaign payment count');

export const getCampaignPaymentCountSuccess = createAction(
    '[Order] Campaign payment count fetched successfully',
    props<{ all: number; available: number }>()
);

export const getCampaignPaymentCountError = createAction(
    '[Order] Campaign payment count fetched unsuccessfully',
    props<{ error: any }>()
);

export const getCountProductSubCategory = createAction(
    '[Order] User try to get the count product sub category',
    props<{ searchString?: string }>()
);
export const getCountProductSubCategorySuccess = createAction(
    '[Order] Count product sub category fetched successfully',
    props<{ model: CountProductSubCategory }>()
);
export const getCountProductSubCategoryError = createAction(
    '[Order] Count product sub category fetched unsuccessfully',
    props<{ error: any }>()
);

export const getCountOrderStatus = createAction(
    '[Order] User try to get the count order status',
    props<{ productSubCategorySlug: 'all' | 'outbound' | 'domestic'; searchString?: string }>()
);
export const getCountOrderStatusSuccess = createAction(
    '[Order] Count order status fetched successfully',
    props<{ model: CountOrderStatus }>()
);
export const getCountOrderStatusError = createAction(
    '[Order] Count order status fetched unsuccessfully',
    props<{ error: any }>()
);

export const getProductOrderListPaginated = createAction(
    '[Order] User try to get the product order list paginated',
    props<{
        orderStatusSlug:
            | 'waiting_confirm'
            | 'waiting_full_paid'
            | 'complete_started'
            | 'complete_not_started'
            | 'cancel';
        page?: number;
        pageEntries?: number;
        productSubCategorySlug?: 'all' | 'outbound' | 'domestic';
        searchString?: string;
        sortBy?: 'created_at_desc';
    }>()
);
export const getProductOrderListPaginatedSuccess = createAction(
    '[Order] Product order list paginated fetched successfully',
    props<{ model: ProductOrderListPaginated }>()
);
export const getProductOrderListPaginatedError = createAction(
    '[Order] Product order list paginated fetched unsuccessfully',
    props<{ error: any }>()
);
