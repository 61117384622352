<div class="modal-header">
    <button type="button" class="close pull-right" aria-label="Close" (click)="bsModalRef.hide()">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<div>
    <h4 class="modal-title text-center">อัปเดตสถานะการรับเงิน</h4>
</div>
<div class="modal-body d-flex">
    <div class="custom-checkbox">
        <input
            type="checkbox"
            class="custom-control-input"
            id="first-payment"
            [(ngModel)]="firstPayment"
            (change)="fullPayment = false"
        />
        <label class="custom-control-label" for="first-payment"> ยืนยันรับเงินมัดจำ&nbsp; </label>
    </div>
    <div class="custom-checkbox">
        <input
            type="checkbox"
            class="custom-control-input"
            id="full-payment"
            disabled="{{ !firstPayment }}"
            [(ngModel)]="fullPayment"
        />
        <label class="custom-control-label" for="full-payment"> ยืนยันรับเงินครบ&nbsp; </label>
    </div>
</div>
<div class="modal-footer">
    <div class="d-flex w-100 justify-content-center">
        <button class="my-submit-btn mr-2 border-0" (click)="onSubmit()">บันทึก</button>
        <button class="my-cancel-btn" (click)="onCancel()">ยกเลิก</button>
    </div>
</div>
