import { Pipe, PipeTransform } from '@angular/core';
import { Country } from '../models/country';

@Pipe({
    name: 'displayCountriesView',
})
export class DisplayCountriesViewPipe implements PipeTransform {
    transform(countries: Country[], delimiter = ','): any {
        if (!countries.length) {
            return '';
        }
        let value = '';
        countries.forEach((country) => {
            if (!value) {
                value += country.name_th;
            } else {
                value += delimiter + country.name_th;
            }
        });
        return value;
    }
}
