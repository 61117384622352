import { Component, OnDestroy, OnInit } from '@angular/core';
import { Actions, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { BsLocaleService } from 'ngx-bootstrap/datepicker';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ICampaignPaymentItem } from 'src/app/modules/order/models/campaign-payment.model';
import { SuccessModalComponent } from 'src/app/modules/shared/modals/success-modal/success-modal.component';
import {
    postChaophrayaCruiseBooking,
    postChaophrayaCruiseBookingSuccess,
} from 'src/app/stores/campaign-store/campaign.actions';
import { State } from 'src/app/stores/reducers';

@Component({
    selector: 'app-campaign-cruise-booking-modal',
    templateUrl: './campaign-cruise-booking-modal.component.html',
    styleUrls: ['./campaign-cruise-booking-modal.component.scss'],
})
export class CampaignCruiseBookingModalComponent implements OnInit, OnDestroy {
    public campaign: ICampaignPaymentItem;
    public numberOfTickets: number[];

    // form value
    public ticketCodes: string[];
    public usageDate: Date;
    public remark: string;
    public isSubmitted: boolean;

    public bookingSuccess = new Subject<void>();

    private unsubscribe$: Subject<void> = new Subject<void>();

    constructor(
        private modalRef: BsModalRef,
        private modalService: BsModalService,
        private localeService: BsLocaleService,
        private store: Store<State>,
        private action: Actions
    ) {}

    ngOnInit(): void {
        this.localeService.use('th-be');
        this.numberOfTickets = Array(this.campaign.voucherTicketQuantity.unused)
            .fill(0)
            .map((x, i) => i);
        this.ticketCodes = [];
    }

    ngOnDestroy(): void {
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
    }

    public selectUsageDate(selectedDate: Date) {
        this.usageDate = selectedDate;
    }

    public submitForm(): void {
        this.isSubmitted = true;
        if (this.isFormValid()) {
            this.store.dispatch(
                postChaophrayaCruiseBooking({
                    model: {
                        paymentId: this.campaign.id,
                        usageDate: this.usageDate,
                        remark: this.remark,
                        tickets: this.ticketCodes,
                    },
                })
            );

            this.action.pipe(ofType(postChaophrayaCruiseBookingSuccess), takeUntil(this.unsubscribe$)).subscribe(() => {
                this.bookingSuccess.next();
                this.closeModal();
                this.modalService.show(SuccessModalComponent, {
                    class: 'success-modal',
                    initialState: {
                        message: 'ส่งข้อมูลการจองให้เรือเรียบร้อยแล้ว',
                    },
                });
            });
        }
    }

    public closeModal(): void {
        this.modalRef.hide();
    }

    public validateTicketCode(ticketCode: string): boolean {
        if (ticketCode) {
            return ticketCode.length === 6;
        }
        return true;
    }

    public isFormValid(): boolean {
        const ticketCodesValid = this.ticketCodes.every((x) => this.validateTicketCode(x));
        return ticketCodesValid;
    }
}
