import { Component, Input } from '@angular/core';
import { BsModalService } from 'ngx-bootstrap/modal';
import { IOrderBalanceReportItem } from 'src/app/models/order-balance-report.model';
import { IPartner } from 'src/app/modules/product/models/campaign-model';
import { PartnerContactModalComponent } from 'src/app/modules/shared/modals/partner-contact-modal/partner-contact-modal.component';

@Component({
    selector: 'app-order-balance-report-box',
    templateUrl: './order-balance-report-box.component.html',
    styleUrls: ['./order-balance-report-box.component.scss'],
})
export class OrderBalanceReportBoxComponent {
    @Input() runNumber: number;
    @Input() item: IOrderBalanceReportItem;

    constructor(private modalService: BsModalService) {}

    showPartnerModal(): void {
        const model: IPartner = {
            id: this.item.productOwnerPartner.id,
            nameEn: this.item.campaign.shopName,
            nameTh: null,
            emailAddress: this.item.productOwnerPartner.email,
            officePhoneNumber: this.item.campaign.officeTelephoneNumber,
            additionalPhoneNumber: this.item.productOwnerPartner.additionalPhoneNumber,
            address: this.item.productOwnerPartner.address,
        };

        this.modalService.show(PartnerContactModalComponent, {
            class: 'modal-sm',
            initialState: {
                partner: model,
            },
        });
    }
}
