import { selectNewBookingOrderId } from '../../../../stores/order-store/order.selectors';
import { IBookingOrderInstallment } from 'src/app/modules/order/models/add-booking-b2c.model';
import { ProductNameList } from '../../../../models/product-name-list';
import { IPartner } from '../../../../models/partners-list.model';
import { Agency } from '../../../../models/agency';
import {
    selectPartnersList,
    selectAgencyInfo,
    selectAgencyProductCode,
} from '../../../../stores/user-store/user.selectors';
import { AddBookingB2c, IBookingOrderItem } from '../../models/add-booking-b2c.model';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { ILeadDetails } from '../../../report/models/lead-details.model';
import { IAddBookingB2c } from '../../models/add-booking-b2c.model';
import {
    SearchableDropdownSettings,
    SearchableDropdownItem,
} from 'src/app/modules/shared/components/searchable-dropdown/searchable-dropdown.component';
import { State } from 'src/app/stores/reducers';
import { select, Store } from '@ngrx/store';
import * as UserActions from 'src/app/stores/user-store/user.actions';
import * as ProductActions from 'src/app/stores/product-store/product.actions';
import * as OrderActions from 'src/app/stores/order-store/order.actions';
import { selectUser, selectUsersList } from 'src/app/stores/user-store/user.selectors';
import { filter, map, take } from 'rxjs/operators';
import { User } from 'src/app/models/user';
import { COMMUNICATION_METHODS } from 'src/app/constants/communication-method.constant';
import { ProductListFilter } from 'src/app/models/request/queries/product-list-query';
import { selectProductNameList } from 'src/app/stores/product-store/product.selectors';
import { CloseAddBookingB2cWarningComponent } from '../close-add-booking-b2c-warning/close-add-booking-b2c-warning.component';
import { DateHelper } from 'src/app/helpers/date.helper';
import { NavigationEnd, Router } from '@angular/router';

@Component({
    selector: 'app-add-booking-b2c',
    templateUrl: './add-booking-b2c.component.html',
    styleUrls: ['./add-booking-b2c.component.scss'],
})
export class AddBookingB2cComponent implements OnInit, OnDestroy {
    id: number;
    lead: ILeadDetails;
    addBookingB2c: IAddBookingB2c;
    modalTitle: string = 'สร้าง Booking';

    isCustomProductSelected: boolean = false;
    showFormErrorMessage: boolean = false;
    validFields: any;
    validFieldsOrderItems: { description: boolean; price: boolean; quantity: boolean; total: boolean };
    validFieldsOrderInstallments: { paymentDuedateAt: boolean; totalPaid: boolean };

    fieldStartDate: Date;
    fieldEndDate: Date;

    salesDropdownSettings: SearchableDropdownSettings = new SearchableDropdownSettings();
    salesDropdownItems: SearchableDropdownItem[] = [];
    partnersDropdownSettings: SearchableDropdownSettings = new SearchableDropdownSettings();
    partnersDropdownItems: SearchableDropdownItem[] = [];
    productsDropdownSettings: SearchableDropdownSettings = new SearchableDropdownSettings();
    productsDropdownItems: SearchableDropdownItem[] = [];
    communicationDropdownSettings: SearchableDropdownSettings = new SearchableDropdownSettings();
    communicationDropdownItems: SearchableDropdownItem[] = [];

    isSubmitted: boolean = false;

    private isWarningModalEnabled = true;
    private completed: boolean = false;
    private partnersList: IPartner[];
    private productNameList: ProductNameList[];
    private ownerAgencyProductCode: string;
    private ownerAgencyId: number;
    private isOwnerAgencySelected: boolean = false;

    constructor(
        public bsModalRef: BsModalRef,
        public bsModalService: BsModalService,
        private store: Store<State>,
        private router: Router
    ) {}

    ngOnInit(): void {
        if (!this.addBookingB2c) {
            this.addBookingB2c = AddBookingB2c.initNewBooking();
        }
        this.loadOwnerAgencyProductCode();

        this.retrieveValues();
        this.setSalesDropdown();
        this.setPartnersDropdown();
        this.subscribeToProductsList();
        this.setCommunicationMethodsDropdown();
        this.bsModalService.setDismissReason(this.id + '-booking-b2c-cancel');
        this.initValidFields();

        this.closeOnRouteChange();
    }

    private loadOwnerAgencyProductCode(): void {
        this.store
            .pipe(select(selectAgencyProductCode), take(1))
            .subscribe((productCode) => (this.ownerAgencyProductCode = productCode));
    }

    private retrieveValues(): void {
        if (this.lead) {
            this.addBookingB2c.b2c_lead_id = this.lead.customerDetails.id;
            this.addBookingB2c.contact = {
                contact_name: this.lead.customerDetails.contact_name,
                phone_number: this.lead.customerDetails.phone_number,
                email: this.lead.customerDetails.email,
                lead_type_slug: this.lead.lead_type_slug,
                customer_remark: this.lead.customerDetails.remark,
            };
            this.addBookingB2c.product = {
                pool_key: this.lead.pool_code,
                owner_id: this.lead.owner ? this.lead.owner.id : null,
                owner_channel: this.lead.owner_channel,
                custom_product_name: null,
                start_at: this.lead.start_period,
                end_at: this.lead.end_period,
                commission_company: null,
                commission_seller: null,
            };
            if (this.addBookingB2c.product.start_at) {
                this.fieldStartDate = new Date(this.addBookingB2c.product.start_at);
            }
            if (this.addBookingB2c.product.end_at) {
                this.fieldEndDate = new Date(this.addBookingB2c.product.end_at);
            }
        }
    }

    private setSalesDropdown(): void {
        this.store.select(selectUser).subscribe((user: User) => {
            this.addBookingB2c.seller_agency_member_id = user.id;
            this.store.dispatch(UserActions.getUsersList(true));
            this.store
                .pipe(
                    select(selectUsersList),
                    filter((resp) => !!resp),
                    take(1),
                    map((usersList) => {
                        return usersList.map((user) => {
                            const dropDownItem = new SearchableDropdownItem({
                                id: user.id,
                                name: user.nameString,
                                isChecked: user.id === this.addBookingB2c.seller_agency_member_id,
                            });
                            this.salesDropdownItems.push(dropDownItem);
                        });
                    })
                )
                .subscribe();
        });

        this.salesDropdownSettings.dropdownListPlaceholder = 'เลือกเซลล์บริษัท (ผู้ขาย)';
        this.salesDropdownSettings.hideSearchBox = true;
        this.salesDropdownSettings.multipleSelection = false;
        this.salesDropdownSettings.closeAfterSelect = true;
        this.salesDropdownSettings.unableToUncheck = true;
        this.salesDropdownSettings.styleSetting.fontSize = '12px';
        this.salesDropdownSettings.styleSetting.height = '30px';
    }

    private setPartnersDropdown(): void {
        this.store.select(selectAgencyInfo).subscribe((agency: Agency) => {
            const ddl: SearchableDropdownItem[] = [];
            this.ownerAgencyId = agency.id;
            const dropDownItem = new SearchableDropdownItem({
                id: agency.id + '-' + 'ag',
                name: 'Owner (' + agency.nameEN + ' (' + agency.nameTH + '))',
                isChecked:
                    agency.id === this.addBookingB2c.product.owner_id &&
                    this.addBookingB2c.product.owner_channel === 'ag',
            });
            if (dropDownItem.isChecked) {
                this.isOwnerAgencySelected = true;
            }
            ddl.push(dropDownItem);

            this.store.dispatch(UserActions.getPartnersList());
            this.store
                .pipe(
                    select(selectPartnersList),
                    filter((resp) => !!resp),
                    take(1),
                    map((partnersList) => {
                        this.partnersList = partnersList;

                        partnersList.map((partner) => {
                            const dropDownItem = new SearchableDropdownItem({
                                id: partner.id + '-' + partner.channel,
                                name: partner.nameEn + ' (' + partner.nameTh + ')',
                                isChecked:
                                    partner.id === this.addBookingB2c.product.owner_id &&
                                    partner.channel === this.addBookingB2c.product.owner_channel,
                            });
                            ddl.push(dropDownItem);
                        });

                        return (this.partnersDropdownItems = ddl);
                    })
                )
                .subscribe(() => {
                    this.setProductsDropdown();
                });
        });

        this.partnersDropdownSettings.dropdownListPlaceholder = 'เลือกเจ้าของโปรแกรม';
        this.partnersDropdownSettings.searchBoxIgnoreCase = true;
        this.partnersDropdownSettings.searchBoxPlaceholder = 'ค้นหาเจ้าของโปรแกรม';
        this.partnersDropdownSettings.customSearchIcon = '';
        this.partnersDropdownSettings.multipleSelection = false;
        this.partnersDropdownSettings.closeAfterSelect = true;
        this.partnersDropdownSettings.unableToUncheck = true;
        this.partnersDropdownSettings.styleSetting.fontSize = '12px';
        this.partnersDropdownSettings.styleSetting.height = '30px';
        this.partnersDropdownSettings.underlineAfterFirstItem = true;
        this.partnersDropdownSettings.isXScrollable = true;
    }

    private setProductsDropdown(): void {
        this.store.dispatch(ProductActions.loadProductNameListListingSuccess({ productNameList: null }));
        this.productsDropdownItems = [];

        const productListFilter: ProductListFilter = {
            owner_id: this.addBookingB2c.product.owner_id,
            owner_channel: this.addBookingB2c.product.owner_channel,
        };
        this.store.dispatch(
            ProductActions.loadProductNameListListing({
                filter: productListFilter,
                fromProductPool: true,
            })
        );
    }

    private subscribeToProductsList(): void {
        this.store
            .pipe(
                select(selectProductNameList),
                filter((resp) => !!resp)
            )
            .subscribe((productNameList: ProductNameList[]) => {
                this.store.dispatch(ProductActions.loadProductViewSuccess({ productView: null, total: null }));
                this.productsDropdownSettings.underlineAfterFirstItem = false;

                const ddl: SearchableDropdownItem[] = [];

                if (this.isOwnerAgencySelected) {
                    const dropDownItem = new SearchableDropdownItem({
                        id: null,
                        name: 'สร้าง Booking (จากสินค้านอกระบบ)',
                        isChecked: false,
                    });

                    ddl.push(dropDownItem);
                    this.productsDropdownSettings.underlineAfterFirstItem = true;
                }
                this.productNameList = productNameList;

                productNameList.map((productName) => {
                    const dropDownItem = new SearchableDropdownItem({
                        id: productName.product_pool_id,
                        name:
                            this.ownerAgencyProductCode +
                            (productName.tourCode ? '-' + productName.tourCode : '') +
                            ' ' +
                            productName.name,
                        isChecked: productName.productPoolKey === this.addBookingB2c.product.pool_key?.toUpperCase(),
                    });

                    ddl.push(dropDownItem);
                    if (dropDownItem.isChecked) {
                        this.onSelectedProduct([productName.product_pool_id]);
                    }
                });

                this.productsDropdownItems = ddl;
            });

        this.productsDropdownSettings.dropdownListPlaceholder = 'เลือกโปรแกรมทัวร์';
        this.productsDropdownSettings.hideSearchBox = false;
        this.productsDropdownSettings.searchBoxIgnoreCase = true;
        this.productsDropdownSettings.searchBoxPlaceholder = 'ค้นหาโปรแกรมทัวร์';
        this.productsDropdownSettings.customSearchIcon = '';
        this.productsDropdownSettings.multipleSelection = false;
        this.productsDropdownSettings.closeAfterSelect = true;
        this.productsDropdownSettings.unableToUncheck = true;
        this.productsDropdownSettings.styleSetting.fontSize = '12px';
        this.productsDropdownSettings.styleSetting.height = '30px';
        this.productsDropdownSettings.isXScrollable = true;
    }

    private setCommunicationMethodsDropdown(): void {
        this.communicationDropdownItems = [];
        COMMUNICATION_METHODS.map((option) => {
            if (option.id !== '') {
                option.id = option.id.toLowerCase();
                this.communicationDropdownItems.push(
                    new SearchableDropdownItem({
                        id: option.id,
                        name: option.text,
                        isChecked: option.id === this.addBookingB2c.contact.lead_type_slug,
                    })
                );
            }
        });

        this.communicationDropdownSettings.dropdownListPlaceholder = 'ช่องทางการติดต่อ';
        this.communicationDropdownSettings.hideSearchBox = true;
        this.communicationDropdownSettings.multipleSelection = false;
        this.communicationDropdownSettings.closeAfterSelect = true;
        this.communicationDropdownSettings.unableToUncheck = true;
        this.communicationDropdownSettings.styleSetting.fontSize = '12px';
        this.communicationDropdownSettings.styleSetting.height = '30px';
    }

    loadProgramDetails(poolId: number): void {
        this.store.dispatch(ProductActions.loadProductViewSuccess({ productView: null, total: null }));

        this.store.dispatch(
            ProductActions.loadProductView({
                criteria: {
                    product_id: poolId,
                    poolProduct: true,
                },
            })
        );
    }

    onSelectedSales(salesId: number[]): void {
        this.addBookingB2c.seller_agency_member_id = salesId[0];
    }

    onSelectedPartner(id: string[]): void {
        this.isCustomProductSelected = false;
        const partnerId: number = parseInt(id[0].split('-')[0]);
        const ownerChannel: string = id[0].split('-')[1];

        this.partnersList
            .filter((partner) => partner.id === partnerId && partner.channel === ownerChannel)
            .map((partner) => {
                (this.addBookingB2c.product.owner_id = partner.id),
                    (this.addBookingB2c.product.owner_channel = partner.channel);
                this.isOwnerAgencySelected = false;
            });

        if (partnerId === this.ownerAgencyId && ownerChannel === 'ag') {
            this.addBookingB2c.product.owner_id = this.ownerAgencyId;
            this.addBookingB2c.product.owner_channel = 'ag';
            this.isOwnerAgencySelected = true;
        }

        this.addBookingB2c.product.pool_key = null;
        this.addBookingB2c.product.custom_product_name = null;
        this.addBookingB2c.product.commission_company = null;
        this.addBookingB2c.product.commission_seller = null;

        this.setProductsDropdown();
    }

    setStartAt(date: Date) {
        if (date) {
            this.fieldStartDate = date;
            this.addBookingB2c.product.start_at = DateHelper.getYMDString(date);
        }
        this.validate();
    }

    setEndAt(date: Date) {
        if (date) {
            this.fieldEndDate = date;
            this.addBookingB2c.product.end_at = DateHelper.getYMDString(date);
        }
        this.validate();
    }

    onSelectCommunicationMethod(communicationMethod: string[]): void {
        if (communicationMethod.length) {
            this.addBookingB2c.contact.lead_type_slug = communicationMethod[0];
        } else {
            this.addBookingB2c.contact.lead_type_slug = null;
        }
    }

    onSelectedProduct(poolIdArray: number[]): void {
        const poolId: number = poolIdArray[0];
        if (poolId) {
            this.isCustomProductSelected = false;
            this.loadProgramDetails(poolId);

            this.addBookingB2c.product.custom_product_name = null;
            this.addBookingB2c.product.commission_company = null;
            this.addBookingB2c.product.commission_seller = null;
            this.productNameList
                .filter((product) => product.product_pool_id === poolId)
                .map((product) => {
                    this.addBookingB2c.product.pool_key = product.productPoolKey;
                });
        } else {
            this.isCustomProductSelected = true;
            this.addBookingB2c.product.pool_key = null;
        }
        this.validate();
    }

    setOrderItemsFromChild(orderItems: IBookingOrderItem[]) {
        this.addBookingB2c.order_items = orderItems;
        this.validate();
    }
    setOrderInstallmentsFromChild(orderInstallments: IBookingOrderInstallment[]) {
        this.addBookingB2c.order_installments = orderInstallments;
        this.validate();
    }

    private initValidFields(): void {
        this.showFormErrorMessage = false;

        this.validFields = {
            customerName: true,
            startDate: true,
            endDate: true,
            productDropdown: true,
            customName: true,
            commissionCompany: true,
            commissionSeller: true,
        };

        this.validFieldsOrderItems = {
            description: true,
            price: true,
            quantity: true,
            total: true,
        };

        this.validFieldsOrderInstallments = {
            paymentDuedateAt: true,
            totalPaid: true,
        };
    }

    validate(): boolean {
        if (!this.isSubmitted) {
            return true;
        }
        let validity = true;
        this.initValidFields();

        if (!this.addBookingB2c.contact.contact_name || this.addBookingB2c.contact.contact_name.trim() === '') {
            this.validFields.customerName = false;
            validity = false;
        }
        if (!this.addBookingB2c.product.start_at) {
            this.validFields.startDate = false;
            validity = false;
        }
        if (!this.addBookingB2c.product.end_at) {
            this.validFields.endDate = false;
            validity = false;
        }
        if (!this.isCustomProductSelected) {
            if (!this.addBookingB2c.product.pool_key) {
                this.validFields.productDropdown = false;
                validity = false;
            }
        } else {
            if (!this.addBookingB2c.product.custom_product_name) {
                this.validFields.customName = false;
                validity = false;
            }
            if (this.addBookingB2c.product.commission_company && this.addBookingB2c.product.commission_company < 1) {
                this.validFields.commissionCompany = false;
                validity = false;
            }
            if (this.addBookingB2c.product.commission_seller && this.addBookingB2c.product.commission_seller < 1) {
                this.validFields.commissionSeller = false;
                validity = false;
            }
        }

        if (!this.addBookingB2c.order_items) {
            this.validFieldsOrderItems = {
                description: false,
                price: false,
                quantity: false,
                total: false,
            };
        } else {
            if (!this.addBookingB2c.order_items[0].description) {
                this.validFieldsOrderItems.description = false;
                validity = false;
            }
            if (!this.addBookingB2c.order_items[0].price) {
                this.validFieldsOrderItems.price = false;
                validity = false;
            }
            if (!this.addBookingB2c.order_items[0].quantity) {
                this.validFieldsOrderItems.quantity = false;
                validity = false;
            }
            if (!this.addBookingB2c.order_items[0].total) {
                this.validFieldsOrderItems.total = false;
                validity = false;
            }
        }

        if (!this.addBookingB2c.order_installments) {
            this.validFieldsOrderInstallments = {
                paymentDuedateAt: false,
                totalPaid: false,
            };
        } else {
            if (!this.addBookingB2c.order_installments[0].payment_duedate_at) {
                this.validFieldsOrderInstallments.paymentDuedateAt = false;
                validity = false;
            }
            if (!this.addBookingB2c.order_installments[0].total_paid) {
                this.validFieldsOrderInstallments.totalPaid = false;
                validity = false;
            }
        }

        this.showFormErrorMessage = !validity;
        return validity;
    }

    submit(): void {
        this.isSubmitted = true;
        if (this.validate()) {
            this.store.dispatch(OrderActions.createNewBookingOrder({ postBody: this.addBookingB2c }));

            this.store
                .pipe(
                    select(selectNewBookingOrderId),
                    filter((resp) => !!resp),
                    take(1)
                )
                .subscribe(() => {
                    this.store.dispatch(OrderActions.createNewBookingOrderSuccess({ newOrderId: null }));
                    this.completed = true;
                    this.bsModalService.setDismissReason(
                        this.id ? this.id + '-submit-booking-b2c' : 'submit-booking-b2c'
                    );
                    this.bsModalRef.hide();
                });
        }
    }

    disableWarningModal(): void {
        this.isWarningModalEnabled = false;
    }

    private closeOnRouteChange(): void {
        this.router.events.subscribe((evt) => {
            if (!(evt instanceof NavigationEnd)) {
                return;
            }
            this.disableWarningModal();
            this.bsModalRef.hide();
        });
    }

    ngOnDestroy(): void {
        this.store.dispatch(ProductActions.loadProductNameListListingSuccess({ productNameList: null }));
        this.store.dispatch(ProductActions.resetProductView());

        if (!this.completed && this.isWarningModalEnabled) {
            const initialState = {
                id: this.id,
                addBookingB2c: this.addBookingB2c,
            };
            this.bsModalRef = this.bsModalService.show(CloseAddBookingB2cWarningComponent, {
                initialState,
                class: 'close-add-booking-b2c-warning-modal',
            });
        }
    }
}
