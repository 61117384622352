import { HttpClient, HttpParams } from '@angular/common/http';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { Router } from '@angular/router';

import { Observable, throwError } from 'rxjs';
import { map } from 'rxjs/operators';
import { HttpService } from './base/http.service';

import { environment } from 'src/environments/environment';
import { ENDPOINTS } from '../constants/endpoint.constant';
import { IApiResponse } from '../models/interfaces/api-response.interface';
import { IPaginationResponse } from '../models/interfaces/pagination-response.interface';
import { IResponse } from '../models/interfaces/response.interface';
import { INewOrder } from '../models/interfaces/tourwow-order/new-order.interface';
import { IResponseGetOrder } from '../models/interfaces/tourwow-order/response-get-order.interface';
import { IReponseOrder } from '../models/interfaces/tourwow-order/response-new-order.interface';
import { IUpdateCustomerInfo } from '../models/interfaces/tourwow-order/update-customer-info.interface';
import { IUpdateWholesaleInstallment } from '../models/interfaces/tourwow-order/update-wholesale-installment.interface';
import { PaginationResponse } from '../models/pagination-response.model';
import { ITwBookingListQueries } from '../modules/order/models/interfaces/tw-booking-interface/tw-booking-list-queries.interface';
import { ITwBooking } from '../modules/order/models/interfaces/tw-booking-interface/tw-booking.interface';
import { ITwOrderFileResponse } from '../modules/order/models/interfaces/tw-booking-interface/tw-order-file-response.interface';
import { ITwOrderInstallmentFileResponse } from '../modules/order/models/interfaces/tw-booking-interface/tw-order-installment-file-response.interface';
import { ITwOrderNoteResponse } from '../modules/order/models/interfaces/tw-booking-interface/tw-order-note-response.interface';
import { ITwOrderPinResponse } from '../modules/order/models/interfaces/tw-booking-interface/tw-order-pin-response.interface';
import { ITwOrderStatusResponse } from '../modules/order/models/interfaces/tw-booking-interface/tw-order-status-response.interface';
import { TwBooking } from '../modules/order/models/tw-booking-model/tw-booking.model';

@Injectable({
    providedIn: 'root',
})
export class TourwowOrderService extends HttpService {
    constructor(
        @Inject(PLATFORM_ID) protected platformId: Object,
        protected http: HttpClient,
        protected router: Router
    ) {
        super(platformId, http, router);
    }

    newOrder(payload: INewOrder): Observable<IReponseOrder | null> {
        const url = environment.apiProUrl + ENDPOINTS.TOURWOW_ORDER.NEW_ORDER;
        return this.post(url, payload, true).pipe(
            map((response: IResponse<IReponseOrder>) => {
                if (response.status !== 'success') return null;

                return response.data;
            })
        );
    }

    getOrder(orderCode: string): Observable<IResponseGetOrder | null> {
        const url = environment.apiProUrl + ENDPOINTS.TOURWOW_ORDER.GET_ORDER.replace('{order_code}', orderCode);
        return this.get(url, true).pipe(
            map((response: IResponse<IResponseGetOrder>) => {
                if (response.status !== 'success') return null;

                return response.data;
            })
        );
    }

    updateOrder(orderCode: string, payload: INewOrder): Observable<IReponseOrder | null> {
        const url = environment.apiProUrl + ENDPOINTS.TOURWOW_ORDER.UPDATE_ORDER.replace('{order_code}', orderCode);
        return this.put(url, payload, true).pipe(
            map((response: IResponse<IReponseOrder>) => {
                if (response.status !== 'success') return null;

                return response.data;
            })
        );
    }

    updateOrderCustomerInfo(orderCode: string, payload: IUpdateCustomerInfo): Observable<IReponseOrder | null> {
        const url =
            environment.apiProUrl +
            ENDPOINTS.TOURWOW_ORDER.UPDATE_ORDER_CUSTOMER_INFO.replace('{order_code}', orderCode);
        return this.put(url, payload, true).pipe(
            map((response: IResponse<IReponseOrder>) => {
                if (response.status !== 'success') return null;

                return response.data;
            })
        );
    }

    updateOrderWholesaleInstallment(
        orderCode: string,
        payload: IUpdateWholesaleInstallment
    ): Observable<IReponseOrder | null> {
        const url =
            environment.apiProUrl +
            ENDPOINTS.TOURWOW_ORDER.UPDATE_ORDER_SUPPLIER_INSTALLMENT.replace('{order_code}', orderCode);
        return this.put(url, payload, true).pipe(
            map((response: IResponse<IReponseOrder>) => {
                if (response.status !== 'success') return null;

                return response.data;
            })
        );
    }

    getTwBookingListPaginated(queries?: ITwBookingListQueries): Observable<PaginationResponse<TwBooking>> {
        const url = environment.apiProUrl + ENDPOINTS.TOURWOW_ORDER.GET_LIST_PAGINATED;

        let params = new HttpParams();
        if (queries) {
            if (queries.page) {
                params = params.append('page', '' + queries.page);
            }
            if (queries.pageEntries) {
                params = params.append('page_entries', '' + queries.pageEntries);
            }
            if (queries.filters) {
                params = params.append('filters', JSON.stringify(queries.filters));
            }
            if (queries.sortBy) {
                params = params.append('sort_by', '' + queries.sortBy);
            }

            return this.get(url + '?' + params.toString(), true).pipe(
                map((resp: IApiResponse<IPaginationResponse<ITwBooking>>) => {
                    if (resp.status !== 'success') {
                        throwError(resp);
                    }
                    let paginationDataList = new PaginationResponse<TwBooking>().deserialize(resp.data);
                    paginationDataList.result = resp.data.result.map((twBooking: ITwBooking) =>
                        new TwBooking().deserialize(twBooking)
                    );
                    return paginationDataList;
                })
            );
        }
    }

    updateTwBookingOrderStatus(orderCode: string, status: string): Observable<IApiResponse<ITwOrderStatusResponse>> {
        const url =
            environment.apiProUrl + ENDPOINTS.TOURWOW_ORDER.UPDATE_STATUS.replace('{order_code}', orderCode + '');
        return this.put(url, { order_status: status }, true).pipe(
            map((resp: IApiResponse<ITwOrderStatusResponse>) => {
                if (resp.status !== 'success') {
                    throwError(resp);
                }
                return resp;
            })
        );
    }

    updateTwBookingPin(orderCode: string, isPinned: boolean): Observable<IApiResponse<ITwOrderPinResponse>> {
        const url = environment.apiProUrl + ENDPOINTS.TOURWOW_ORDER.UPDATE_PIN.replace('{order_code}', orderCode + '');
        return this.put(url, { is_pinned: isPinned }, true).pipe(
            map((resp) => {
                if (resp.status !== 'success') {
                    throwError(resp);
                }
                return resp;
            })
        );
    }

    getTwBookingNote(orderCode: string): Observable<IApiResponse<ITwOrderNoteResponse>> {
        const url = environment.apiProUrl + ENDPOINTS.TOURWOW_ORDER.GET_NOTE.replace('{order_code}', orderCode + '');
        return this.get(url, true).pipe(
            map((resp) => {
                if (resp.status !== 'success') {
                    throwError(resp);
                }
                return resp;
            })
        );
    }

    updateTwBookingNote(orderCode: string, note: string): Observable<IApiResponse<ITwOrderNoteResponse>> {
        const url = environment.apiProUrl + ENDPOINTS.TOURWOW_ORDER.UPDATE_NOTE.replace('{order_code}', orderCode + '');
        return this.put(url, { note: note }, true).pipe(
            map((resp) => {
                if (resp.status !== 'success') {
                    throwError(resp);
                }
                return resp;
            })
        );
    }

    uploadTwBookingInvoiceFile(orderCode: string, file: FormData): Observable<IApiResponse<ITwOrderFileResponse>> {
        const url =
            environment.apiProUrl +
            ENDPOINTS.TOURWOW_ORDER.UPLOAD_INVOICE_FILE.replace('{order_code}', orderCode.toString());

        return this.http
            .post(url, file, {
                headers: {
                    Authorization: this.getToken(),
                },
            })
            .pipe(
                map((resp: IApiResponse<ITwOrderFileResponse>) => {
                    if (!resp) {
                        throwError(resp);
                    }
                    return resp;
                })
            );
    }

    deleteTwBookingInvoiceFile(orderCode: string, fileId: number): Observable<IApiResponse<ITwOrderFileResponse>> {
        const url =
            environment.apiProUrl +
            ENDPOINTS.TOURWOW_ORDER.DELETE_INVOICE_FILE.replace('{order_code}', orderCode.toString()).replace(
                '{order_file_id}',
                fileId
            );
        return this.delete(url, true).pipe(
            map((resp) => {
                if (resp.status !== 'success') {
                    throwError(resp);
                }
                return resp;
            })
        );
    }

    uploadTwBookingCustomerInstallmentFile(
        orderCode: string,
        customerOrderInstallmentId: number,
        file: FormData
    ): Observable<IApiResponse<ITwOrderInstallmentFileResponse>> {
        const url =
            environment.apiProUrl +
            ENDPOINTS.TOURWOW_ORDER.UPLOAD_CUSTOMER_INSTALLMENT_FILE.replace(
                '{order_code}',
                orderCode.toString()
            ).replace('{customer_order_installment_id}', customerOrderInstallmentId.toString());

        return this.http
            .post(url, file, {
                headers: {
                    Authorization: this.getToken(),
                },
            })
            .pipe(
                map((resp: IApiResponse<ITwOrderInstallmentFileResponse>) => {
                    if (!resp) {
                        throwError(resp);
                    }
                    return resp;
                })
            );
    }

    deleteTwBookingSupplierInstallmentFile(
        orderCode: string,
        supplierOrderInstallmentId: number,
        fileId: number
    ): Observable<IApiResponse<ITwOrderInstallmentFileResponse>> {
        const url =
            environment.apiProUrl +
            ENDPOINTS.TOURWOW_ORDER.DELETE_SUPPLIER_INSTALLMENT_FILE.replace('{order_code}', orderCode.toString())
                .replace('{supplier_order_installment_id}', supplierOrderInstallmentId.toString())
                .replace('{supplier_order_installment_file_id}', fileId.toString());
        return this.delete(url, true).pipe(
            map((resp) => {
                if (resp.status !== 'success') {
                    throwError(resp);
                }
                return resp;
            })
        );
    }

    deleteTwBookingCustomerInstallmentFile(
        orderCode: string,
        customerOrderInstallmentId: number,
        fileId: number
    ): Observable<IApiResponse<ITwOrderInstallmentFileResponse>> {
        const url =
            environment.apiProUrl +
            ENDPOINTS.TOURWOW_ORDER.DELETE_CUSTOMER_INSTALLMENT_FILE.replace('{order_code}', orderCode.toString())
                .replace('{customer_order_installment_id}', customerOrderInstallmentId.toString())
                .replace('{customer_order_installment_file_id}', fileId.toString());
        return this.delete(url, true).pipe(
            map((resp) => {
                if (resp.status !== 'success') {
                    throwError(resp);
                }
                return resp;
            })
        );
    }

    getTwOrderReceiptPdf(orderCode: string): Observable<Blob> {
        let url = environment.apiProUrl + ENDPOINTS.TOURWOW_ORDER.GET_PDF.replace('{order_code}', orderCode);
        return this.get(url).pipe(
            map((resp) => {
                const byteArray = new Uint8Array(
                    window
                        .atob(resp.data.file)
                        .split('')
                        .map((char) => char.charCodeAt(0))
                );
                return new Blob([byteArray], { type: 'application/pdf' });
            })
        );
    }
}
