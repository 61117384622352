import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { filter, take } from 'rxjs/operators';
import { CATEGORY_SUB_PRODUCTS } from 'src/app/constants/category-sub-product.constant';
import { CampaignCruiseBookingInfoModalComponent } from 'src/app/modules/product/modals/campaign-cruise-booking-info-modal/campaign-cruise-booking-info-modal.component';
import { CampaignCruiseBookingModalComponent } from 'src/app/modules/product/modals/campaign-cruise-booking-modal/campaign-cruise-booking-modal.component';
import { IPartner } from 'src/app/modules/product/models/campaign-model';
import { PartnerContactModalComponent } from 'src/app/modules/shared/modals/partner-contact-modal/partner-contact-modal.component';
import { SendEmailOrderDetailModalComponent } from '../../modals/send-email-order-detail-modal/send-email-order-detail-modal.component';
import { ICampaignPaymentItem, ICampaignPaymentPaginated } from '../../models/campaign-payment.model';

@Component({
    selector: 'app-order-list-table',
    templateUrl: './order-list-table.component.html',
    styleUrls: ['./order-list-table.component.scss'],
})
export class OrderListTableComponent implements OnChanges {
    @Input() paginated: ICampaignPaymentPaginated;
    @Output() dataChanged = new EventEmitter();

    paramPaymentId: number;
    private modalRef: BsModalRef;

    constructor(private modalService: BsModalService, private activatedRoute: ActivatedRoute) {
        this.activatedRoute.queryParams.subscribe((params) => {
            this.paramPaymentId = +params.pid;
        });
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (this.paginated && this.paramPaymentId) {
            this.popupModalCampaignCruiseBookingModalViaParam();
        }
    }

    onPopupSendEmail(paymentId: number, contactName: string, email: string): void {
        const initialState = {
            paymentId,
            contactName,
            email,
        };
        this.modalRef = this.modalService.show(SendEmailOrderDetailModalComponent, { initialState });
        this.modalRef.setClass('send-email-order-detail-modal');
    }

    openPartnerContactModal(payment: ICampaignPaymentItem): void {
        const partner = payment.campaign.partner;
        const modalPartner: IPartner = {
            id: partner.id,
            nameTh: null,
            nameEn: payment.campaign.shopName,
            emailAddress: partner.email,
            officePhoneNumber: payment.campaign.officeTelephoneNumber,
            additionalPhoneNumber: partner.additionalPhoneNumber,
            address: partner.address,
        };
        this.modalService.show(PartnerContactModalComponent, {
            class: 'modal-sm',
            initialState: {
                partner: modalPartner,
            },
        });
    }

    openCampaignCruiseBookingModal(item: ICampaignPaymentItem): void {
        const modal = this.modalService.show(CampaignCruiseBookingModalComponent, {
            class: 'modal-sm',
            initialState: {
                campaign: item,
            },
        });

        modal.content.bookingSuccess.subscribe(() => {
            this.dataChanged.emit();
        });
    }

    openCampaignCruiseBookingInfoModal(item: ICampaignPaymentItem): void {
        const modal = this.modalService.show(CampaignCruiseBookingInfoModalComponent, {
            class: 'modal-sm',
            initialState: {
                campaign: item,
            },
        });

        modal.content.bookingSuccess.subscribe(() => {
            this.dataChanged.emit();
        });
    }

    displayBookingDetailLink(item: ICampaignPaymentItem): boolean {
        const displayLink =
            item.productSubCategory.id === CATEGORY_SUB_PRODUCTS.CHAOPHRAYA_CRUISE && !item.chaophrayaCruiseBooking;
        return displayLink;
    }

    displayConfirmBookingLink(item: ICampaignPaymentItem): boolean {
        const displayLink =
            item.productSubCategory.id === CATEGORY_SUB_PRODUCTS.CHAOPHRAYA_CRUISE &&
            item.chaophrayaCruiseBooking &&
            !item.chaophrayaCruiseBooking.confirmed;
        return displayLink;
    }

    displayBookingSuccessLink(item: ICampaignPaymentItem): boolean {
        const displayLink =
            item.productSubCategory.id === CATEGORY_SUB_PRODUCTS.CHAOPHRAYA_CRUISE &&
            item.chaophrayaCruiseBooking &&
            item.chaophrayaCruiseBooking.confirmed;
        return displayLink;
    }

    private popupModalCampaignCruiseBookingModalViaParam(): void {
        const payment = this.paginated.result.find((payment) => payment.id === this.paramPaymentId);
        if (payment) {
            this.openCampaignCruiseBookingInfoModal(payment);
        }
    }
}
