import { Component, Inject, OnDestroy, OnInit, PLATFORM_ID } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';

import { Observable, Subject } from 'rxjs';
import { takeUntil, tap } from 'rxjs/operators';

import { Store, select } from '@ngrx/store';
import { State } from 'src/app/stores/reducers';

import { login } from 'src/app/stores/user-store/user.actions';
import { selectUserIsAuthenticate, selectErrorMessage } from 'src/app/stores/user-store/user.selectors';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { isPlatformBrowser } from '@angular/common';

@Component({
    selector: 'app-login-page',
    templateUrl: './login-page.component.html',
    styleUrls: ['./login-page.component.scss'],
})
export class LoginPageComponent implements OnInit, OnDestroy {
    private unsubscribe$: Subject<void> = new Subject<void>();
    private redirectUrl: string = 'product/catalog';
    selectErrorMessage$: Observable<string>;

    isSubmit: boolean = false;
    username: string;
    password: string;

    private previousUrl: string = undefined;
    private currentUrl: string = undefined;

    constructor(
        @Inject(PLATFORM_ID) protected platformId: Object,
        private store: Store<State>,
        private router: Router,
        private authenService: AuthenticationService
    ) {
        this.currentUrl = this.router.url;
        router.events.subscribe((event) => {
            if (event instanceof NavigationEnd) {
                this.previousUrl = this.currentUrl;
                this.currentUrl = event.url;

                if (this.previousUrl !== this.currentUrl) {
                    this.redirectUrl = this.currentUrl;
                }
            }
        });

        this.isLoggedIn();
    }

    ngOnInit(): void {
        this.subscribeErrorMessage();
    }

    ngOnDestroy(): void {
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
    }

    private subscribeIsAuthen(): void {
        this.store
            .pipe(
                select(selectUserIsAuthenticate),
                tap((isAuthen) => {
                    if (isAuthen && isPlatformBrowser(this.platformId)) {
                        window.location.replace(this.redirectUrl);
                    }
                }),
                takeUntil(this.unsubscribe$)
            )
            .subscribe();
    }

    private subscribeErrorMessage(): void {
        this.selectErrorMessage$ = this.store.pipe(select(selectErrorMessage), takeUntil(this.unsubscribe$));
    }

    onLoginSubmit(): void {
        this.isSubmit = true;
        this.store.dispatch(login({ username: this.username, password: this.password }));
        this.subscribeIsAuthen();
    }

    private isLoggedIn(): void {
        if (this.authenService.isAuthentication()) {
            this.router.navigate([this.redirectUrl]);
        }
    }
}
