import { Component } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { IPartner } from 'src/app/modules/product/models/campaign-model';

@Component({
    selector: 'app-partner-contact-modal',
    templateUrl: './partner-contact-modal.component.html',
    styleUrls: ['./partner-contact-modal.component.scss'],
})
export class PartnerContactModalComponent {
    public partner: IPartner;

    constructor(private bsModalRef: BsModalRef) {}

    closeModal(): void {
        this.bsModalRef.hide();
    }
}
