<div class="program-order-list">
    <div class="header">
        <app-breadcrumb [items]="breadcrumb"></app-breadcrumb>

        <div class="d-flex justify-content-between">
            <h2 class="text-primary mb-4"><i class="icon icon-status-orange"></i>ข้อมูลการซื้อโปรแกรมทัวร์</h2>
        </div>
    </div>
    <app-program-order-list-search
        [totalProductOrders]="productOrders?.total"
        (search)="searchProductOrder($event)"
    ></app-program-order-list-search>
    <app-program-order-list-result [productOrders]="productOrders"></app-program-order-list-result>
    <div
        class="text-center show-more-results"
        *ngIf="productOrders?.result?.length < productOrders?.total && !searchCriteria.isLocalSearch"
    >
        <button class="btn btn-show-more" (click)="loadMore()">
            แสดงรายการที่ {{ productOrders?.result?.length + 1 }} -
            {{ productOrders?.result?.length + productOrders?.pageEntries }}
        </button>
    </div>
</div>
