export class CountrySubUnit {
    id: number;
    nameTh: string;
    nameEn: string;
    countryId: number;

    public deserialize(data: any): CountrySubUnit | null {
        if (!data) {
            return null;
        }

        this.id = data.id;
        this.nameTh = data.name_th;
        this.nameEn = data.name_en;
        this.countryId = data.country_id;
        return this;
    }
}
