<div class="advance-search-component card-search">
    <strong class="card-title mb-3">ค้นหาข้อมูลเพิ่มเติม</strong>
    <div class="row">
        <div class="col-md-6 mb-3">
            <div class="row">
                <label class="label-column">ชื่อลูกค้า/เบอร์โทรติดต่อ</label>
                <div class="col input-column">
                    <input
                        class="form-control"
                        type="text"
                        placeholder="กรอกชื่อลูกค้า/เบอร์โทรติดต่อ"
                        [(ngModel)]="contactNameOrPhoneNumber"
                    />
                </div>
            </div>
        </div>
        <div class="col-md-6 mb-3">
            <div class="row">
                <label class="label-column">โปรแกรมจาก</label>
                <div class="col input-column">
                    <app-searchable-dropdown
                        #productOwnerDropdownComponent
                        [settings]="productOwnerDropdownSettings"
                        [items]="productOwnerDropdownItems"
                        (selected)="productOwnerSelected($event)"
                    ></app-searchable-dropdown>
                </div>
            </div>
        </div>
        <div class="col-md-6 mb-3">
            <div class="row">
                <label class="label-column">วันที่จอง</label>
                <div class="col">
                    <app-daterange
                        #bookingDateRangeComponent
                        [startDateInput]="bookingDate?.startDate"
                        [endDateInput]="bookingDate?.endDate"
                        (dateChange="bookingDateChange($event)"
                    ></app-daterange>
                </div>
            </div>
        </div>
        <div class="col-md-6 mb-3">
            <div class="row">
                <label class="label-column">วันเดินทาง</label>
                <div class="col">
                    <app-daterange
                        #outboundDateRangeComponent
                        [startDateInput]="outboundDate?.startDate"
                        [endDateInput]="outboundDate?.endDate"
                        (dateChange)="outboundDateChange($event)"
                    ></app-daterange>
                </div>
            </div>
        </div>
        <div class="col-md-6 mb-3">
            <div class="row">
                <label class="label-column">ชื่อเซลล์ในบริษัท</label>
                <div class="col input-column">
                    <app-searchable-dropdown
                        #sellerDropdownComponent
                        [settings]="sellerDropdownSettings"
                        [items]="sellerOwnerDropdownItems"
                        (selected)="sellerSelected($event)"
                    ></app-searchable-dropdown>
                </div>
            </div>
        </div>
        <div class="col-md-6 mb-3">
            <div class="row">
                <label class="label-column">ชื่อโปรแกรม</label>
                <div class="col input-column">
                    <app-searchable-dropdown
                        #productDropdownComponent
                        [settings]="productDropdownSettings"
                        [items]="productDropdownItems"
                        (selected)="productSelected($event)"
                    ></app-searchable-dropdown>
                </div>
            </div>
        </div>
        <div class="col-12 mb-3">
            <div class="row">
                <label class="status-label-column">สถานะ</label>
                <div class="col">
                    <div class="row border p-2 ml-0 mr-1">
                        <label
                            class="checkbox custom-control col-md-2"
                            *ngFor="let status of bookingStatuses | keyvalue"
                        >
                            <input
                                class="custom-control-input"
                                type="checkbox"
                                [checked]="status.value.checked"
                                (change)="bookingStatusChange($event.target.checked, status.key)"
                            />
                            <span class="ml-1 custom-control-label">{{ status.value.label }}</span>
                        </label>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-12 text-right">
            <button class="btn btn-primary mr-2" (click)="doSearch()">ค้นหา</button>
            <button class="btn" (click)="resetSearchFilter()">เริ่มใหม่</button>
        </div>
    </div>
</div>
