<div class="modal-header p-3">
    <div class="d-flex justify-content-center w-100">
        <span class="modal-title font-weight-bold">PREVIEW</span>
    </div>
    <div class="close-btn-top">
        <span class="cursor-pointer" (click)="bsModalRef.hide()">&times;</span>
    </div>
</div>
<div class="modal-body py-0">
    <div class="pdf-panel" *ngIf="pdfSrc">
        <pdf-viewer
            [src]="pdfSrc"
            [render-text]="true"
            [original-size]="false"
            style="width: 720px; height: 1030px"
        ></pdf-viewer>
    </div>
</div>
<div class="modal-footer justify-content-center">
    <button class="btn btn-download" (click)="onDownloadPdf()">
        <fa-icon [icon]="icons.faDownload"></fa-icon> ดาวน์โหลด
    </button>
</div>
