<div class="main">
    <div class="header">ข้อมูลลูกค้า</div>
    <form class="customer-info-form" [ngClass]="{ submitted: isSubmitted }" [formGroup]="customerForm">
        <!-- Row1 -->
        <div class="row">
            <div class="col-4">
                <div class="form-group row">
                    <label for="name" class="col-sm-4 col-form-label required">ชื่อลูกค้า</label>
                    <div class="col-sm-8">
                        <input id="name" type="text" class="form-control" formControlName="name" />
                    </div>
                </div>
            </div>
            <div class="col-4">
                <div class="form-group row">
                    <label for="phone-number" class="col-sm-4 col-form-label required">เบอร์ติดต่อ</label>
                    <div class="col-sm-8">
                        <input id="phone-number" type="tel" class="form-control" formControlName="phoneNumber" />
                    </div>
                </div>
            </div>
            <div class="col-4">
                <div class="form-group row">
                    <label for="email" class="col-sm-4 col-form-label">อีเมลลูกค้า</label>
                    <div class="col-sm-8">
                        <input id="email" type="email" class="form-control" formControlName="email" />
                    </div>
                </div>
            </div>
        </div>
        <!-- Row2 -->
        <div class="row">
            <div class="col-4">
                <div class="form-group row">
                    <label for="facebook" class="col-sm-4 col-form-label">Facebook</label>
                    <div class="col-sm-8">
                        <input type="text" class="form-control" id="facebook" formControlName="facebook" />
                    </div>
                </div>
            </div>
            <div class="col-4">
                <div class="form-group row">
                    <label for="line" class="col-sm-4 col-form-label">Line</label>
                    <div class="col-sm-8">
                        <input type="text" class="form-control" id="line" formControlName="line" />
                    </div>
                </div>
            </div>
            <div class="col-4">
                <div class="form-group row">
                    <label for="instagram" class="col-sm-4 col-form-label">Instagram</label>
                    <div class="col-sm-8">
                        <input type="text" class="form-control" id="instagram" formControlName="instagram" />
                    </div>
                </div>
            </div>
        </div>
        <!-- Row3 -->
        <div class="row">
            <div class="col-8">
                <div class="form-group row">
                    <label for="remark" class="col-sm-2 col-form-label pr-0">ความต้องการอื่นๆ</label>
                    <div class="col-sm-10">
                        <textarea class="form-control" rows="5" formControlName="remark"></textarea>
                    </div>
                </div>
            </div>
            <div class="col-4">
                <div class="form-group row">
                    <label for="staticEmail" class="col-sm-4 col-form-label">เซลล์ที่ติดต่อ</label>
                    <div class="col-sm-8">
                        <app-searchable-dropdown
                            [settings]="agencyListSettings"
                            [items]="agencyListItems"
                            (selected)="onAgencyListSelected($event)"
                        >
                        </app-searchable-dropdown>
                    </div>
                </div>
                <div class="form-group row">
                    <label for="staticEmail" class="col-sm-4 col-form-label">หมายเหตุ</label>
                    <div class="col-sm-8">
                        <span *ngIf="period" id="period-remark">{{ period.remark || '-' }}</span>
                    </div>
                </div>
            </div>
        </div>
    </form>
</div>
