<div class="modal-header">
    <button type="button" class="close pull-right" aria-label="Close" (click)="bsModalRef.hide()">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<div>
        <h4 class="modal-title text-center">รายละเอียดผู้ติดต่อ</h4>
</div>
<div class="modal-body">
    <div class="contact-detail">
        <span class="contact-key font-weight-bold">ชื่อผู้ติดต่อ :</span><p class="value">{{customerName}}</p>
    </div>
    <div class="contact-detail">
        <span class="contact-key font-weight-bold">เบอร์โทร :</span><p class="value">{{phone | phoneNumber}}</p>
    </div>
    <div class="contact-detail">
        <span class="contact-key font-weight-bold">Email :</span><p class="value">{{email}}</p>
    </div>
    <div class="contact-detail">
        <span class="contact-key font-weight-bold">ข้อมูลเพิ่มเติม :</span><p class="value">{{more_info}}</p>
    </div>
</div>
<div class="modal-footer">
    <button type="button" class="btn btn-default" (click)="bsModalRef.hide()">ปิด</button>
</div>