import { Pipe, PipeTransform } from '@angular/core';
import { TW_ORDER_STATUS } from '../constants/tw-order-status.constant';

@Pipe({
    name: 'orderStatus',
})
export class OrderStatusPipe implements PipeTransform {
    transform(status: string): string {
        if (status) {
            return TW_ORDER_STATUS[status];
        } else {
            return status;
        }
    }
}
