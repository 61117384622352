import { Injectable, Inject, PLATFORM_ID } from '@angular/core';
import { HttpService } from './base/http.service';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { IRequestPayloadPaymentNotification } from 'src/app/models/interfaces/request-payload-payment-notification.interfaace';

@Injectable({
    providedIn: 'root',
})
export class PaymentService extends HttpService {
    constructor(
        @Inject(PLATFORM_ID) protected platformId: Object,
        protected http: HttpClient,
        protected router: Router,
        private httpService: HttpService
    ) {
        super(platformId, http, router);
    }

    endpoint = {
        POST_PAYMENT_NOTIFICATION: '/customerorderinstallments/{id}/payments/tw',
        CANCEL_PAYMENT_REQUEST: '/payments/tw/events/{id}/status',
    };

    postPaymentNotification(orderInstlalmentId: number, payload: IRequestPayloadPaymentNotification): Observable<any> {
        let url =
            environment.apiProUrl +
            this.endpoint.POST_PAYMENT_NOTIFICATION.replace('{id}', orderInstlalmentId.toString());
        return this.httpService.post(url, payload);
    }

    cancelPaymentRequest(paymentEventId: number): Observable<any> {
        let url =
            environment.apiProUrl + this.endpoint.CANCEL_PAYMENT_REQUEST.replace('{id}', paymentEventId.toString());

        return this.httpService.put(url, { status: 'failure' });
    }
}
