<div class="modal-header">
    <button type="button" class="close pull-right" aria-label="Close" (click)="bsModalRef.hide()">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="top-wrapper">
        <h4 class="modal-title text-center">
            <i class="icon icon-garbage text-danger mr-2"></i>{{title}}
        </h4>
        <small class="are-you-sure">{{areYouSureText}}</small>
        <p class="item-title">{{itemTitle}}</p>
        <div class="d-flex buttons">
            <button class="btn btn-primary" (click)="confirmButton()">ยืนยันลบข้อมูล</button>
            <button class="btn btn-secondary" (click)="bsModalRef.hide()">ยกเลิก</button>
        </div>
</div>
