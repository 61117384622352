import { createAction, props } from '@ngrx/store';
import { IPartner } from 'src/app/models/partners-list.model';
import { IUser } from 'src/app/models/users-list';

export const login = createAction('[User] Login', props<{ username: string; password: string }>());
export const loginSuccess = createAction('[User] Login Success', props<{ data: any }>());
export const loginFailure = createAction('[User] Login Failure', props<{ error: any }>());

export const logout = createAction('[User] Logout');
export const logoutSuccess = createAction('[User] Logout Success');

export const checkAuthentication = createAction('[User] Check Authentication');
export const checkAuthenticationSuccess = createAction('[User] Check Authentication Success', props<{ data: any }>());
export const checkAuthenticationFailure = createAction('[User] Check Authentication Failure', props<{ error: any }>());

export const getAgencyInfo = createAction('[User] Get agency info');
export const getAgencyInfoSuccess = createAction('[User] Get agency info success', props<{ data: any }>());
export const getAgencyInfoFailure = createAction('[User] Get agency info Failure', props<{ error: any }>());

export const getUsersList = createAction(
    '[User] Get users list',
    (getAll: boolean = false, showInactive: boolean = false, hideLoadingIcon: boolean = false) => ({
        getAll,
        showInactive,
        hideLoadingIcon,
    })
);
export const getUsersListSuccess = createAction('[User] Get users list success', props<{ usersList: IUser[] }>());
export const getUsersListFailure = createAction('[User] Get users list Failure', props<{ error: any }>());

export const getPartnersList = createAction('[User] Get partners list');
export const getPartnersListSuccess = createAction(
    '[User] Get partners list success',
    props<{ partnersList: IPartner[] }>()
);
export const getPartnersListFailure = createAction('[User] Get partners list Failure', props<{ error: any }>());
