import { IPaginationResponse } from './interfaces/pagination-response.interface';

export class PaginationResponse<T> {
    page: number;
    pageEntries: number;
    total: number;
    result: T[];

    public deserialize(data: IPaginationResponse<any>): PaginationResponse<T> {
        this.page = data.page;
        this.pageEntries = data.page_entries;
        this.total = data.total;
        this.result = [];
        return this;
    }
}
