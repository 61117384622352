import { ThaiMonthDatePipe } from 'src/app/pipes/thaidate.pipe';
import { ThaiDateRangePipe } from './../../pipes/thaidate.pipe';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

// Pages
import { LeadListComponent } from './pages/lead-list/lead-list.component';
import { LeadReportDownloadComponent } from './pages/lead-report-download/lead-report-download.component';
import { BookingReportDownloadComponent } from './pages/booking-report-download/booking-report-download.component';
import { OrderBalanceTransactionPageComponent } from './pages/order-balance-transaction/order-balance-transaction.component';
import { OrderBalanceReportPageComponent } from './pages/order-balance-report-page/order-balance-report-page.component';
import { OrderBalanceReportDownloadComponent } from './pages/order-balance-report-download/order-balance-report-download.component';

const routes: Routes = [
    { path: 'lead-list', component: LeadListComponent },
    // { path: 'lead-booking-request', component: LeadBookingRequestListComponent },
    // { path: 'lead-booking-summary', component: LeadBookingSummaryComponent },
    { path: 'lead-report-download', component: LeadReportDownloadComponent },
    { path: 'booking-report-download', component: BookingReportDownloadComponent },
    { path: 'order-balance-report/:id', component: OrderBalanceTransactionPageComponent },
    { path: 'order-balance-report', component: OrderBalanceReportPageComponent },
    { path: 'order-balance-report-download', component: OrderBalanceReportDownloadComponent },
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule],
    providers: [ThaiDateRangePipe, ThaiMonthDatePipe],
})
export class ReportRoutingModule {}
