<div class="list-container">
    <div class="list-row">
        <div class="first-row">
            <label>งวดชำระเงิน</label>
        </div>
        <div class="second-row">
        </div>
        <div class="third-row">
        </div>
        <div class="fourth-row">
        </div>
        <div class="fifth-row">
        </div>
    </div>

    <ng-container *ngFor="let installment of bookingOrderInstallments; let i=index">
        <div class="list-row">
            <div class="first-row">
                <p>งวดที่ {{i+1}} วันที่</p>
            </div>
            <div class="second-row">
                <app-calendar-input
                    #endDateCalendarInput
                    [bsValue]="fieldDate[i]"
                    [bsConfig]="{
                        adaptivePosition: true
                    }"
                    [forAddBookingModalStyling]=true
                    [showIcon]="false"
                    (bsValueChange)="setDate(i, $event)"
                    [valid]="validFields.paymentDuedateAt"
                    placeholder="เลือกวันที่ชำระ"
                ></app-calendar-input>
            </div>
            <div class="third-row">
                <p>จำนวน</p>
            </div>
            <div class="fourth-row">
                <input [(ngModel)]="installment.total_paid"
                [class.red-border]="!validFields.totalPaid"
                (change)="isEdit ? installment.to_update = true : null"
                name="price" type="text" class="form-control" appMycurrency
                placeholder="" maxlength=10 (change)="emitToParent()">
            </div>
            <div class="fifth-row">
                <ng-container *ngIf="i > 0">
                    <i class="icon icon-garbage" (click)="removeItem(i)"
                    style="color: #ff010a; cursor: pointer; font-size: 20px;"></i>
                </ng-container>
            </div>
        </div>
    </ng-container>

    <div class="list-row mt-1">
        <div class="add-button">
            <a (click)="addItem()" class="btn btn-add"
            [class.disabled]="bookingOrderInstallments.length >= 10">
                <i class="icon icon-plus-circle mr-1"></i> เพิ่มงวด
            </a>
        </div>
        <div class="total-label">
            <label>ยอดรวมทุกงวด</label>
        </div>
        <div class="total-amount">
            <p>{{ calculateGrandTotal() | number: '1.2' }}</p>
        </div>
    </div>
</div>
