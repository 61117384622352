import { Component, EventEmitter, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { IModalResponse } from '../../models/filters/product/interfaces/modal-response.interface';

@Component({
    selector: 'app-text-area-modal',
    templateUrl: './text-area-modal.component.html',
    styleUrls: ['./text-area-modal.component.scss'],
})
export class TextAreaModalComponent implements OnInit {
    title: string;
    initData: string = '';

    dataForm: FormGroup;

    textAreaModalResponse: EventEmitter<IModalResponse<string>> = new EventEmitter();

    constructor(public bsModalRef: BsModalRef, public bsModalService: BsModalService, private fb: FormBuilder) {
        this.dataForm = fb.group({
            data: '',
        });
    }

    ngOnInit() {
        this.initialDataForm();
    }

    initialDataForm() {
        this.dataForm.controls['data'].setValue(this.initData);
    }

    onSubmit() {
        this.textAreaModalResponse.emit({ status: true, data: this.dataForm.controls['data'].value });
        this.bsModalRef.hide();
    }
    onCancle() {
        this.textAreaModalResponse.emit({ status: false });
        this.bsModalRef.hide();
    }
}
