<div class="booking-listing head-group">
    <div class="row">
        <div class="col-8">
            <strong class="title mb-0">
                คุณกำลังดู Booking {{ getDynamicTotalString() }} {{ bookingList?.total }} Booking
            </strong>
        </div>
        <div class="col-4 text-left d-flex justify-content-end">
            <div style="width: 240px">
                <app-searchable-dropdown
                    #sortingDropdown
                    [settings]="sortingDropdownSettings"
                    [items]="sortingDropdownItems"
                    (selected)="onSort($event)"
                >
                </app-searchable-dropdown>
            </div>
        </div>
    </div>
    <div class="booking-list-head">
        <p>Booking</p>
        <p>ยอดจอง</p>
        <p>งวดแรก</p>
        <p>งวดที่เหลือ</p>
        <p>สถานะ</p>
    </div>
    <div class="booking-list-table" *ngIf="bookingList">
        <ng-container *ngFor="let booking of bookingList.bookings; let i = index">
            <app-booking-list-details [index]="i" [booking]="booking"></app-booking-list-details>
        </ng-container>
    </div>
</div>

<ng-container *ngIf="bookingList">
    <div class="text-center show-more-results" *ngIf="bookingList.page < bookingList.total / bookingList.pageEntries">
        <button class="btn btn-show-more" (click)="nextPage()">
            แสดงรายการที่ {{ bookingList.page * bookingList.pageEntries + 1 }} -
            {{ showNextResultsValue() }}
        </button>
    </div>
</ng-container>
