import { IRoomType } from './interfaces/room-type.interface';

export class RoomType {
    id: number;
    slug: string;
    price: number;

    deserialize(data: IRoomType): RoomType {
        this.id = data.id;
        this.slug = data.slug;
        this.price = data.price;

        return this;
    }
}
