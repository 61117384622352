import { Component, OnInit, OnDestroy, EventEmitter, Output } from '@angular/core';

import { filter, takeUntil, take } from 'rxjs/operators';

// State
import { select, Store } from '@ngrx/store';
import { State } from 'src/app/stores/reducers';
import {
    selectOrderSummarySelect,
    selectGroupPlanList,
    selectGroupPlanListQueries,
} from 'src/app/stores/order-store/order.selectors';
import * as OrderActions from 'src/app/stores/order-store/order.actions';
import { selectAgencyInfo, selectAgencyProductCode } from 'src/app/stores/user-store/user.selectors';

// Models
import { IGroupPlan, IGroupPlanList, IProductOwner } from 'src/app/modules/order/models/group-plan-list.model';
import { IGroupPlanListQueries } from 'src/app/modules/order/models/group-plan-list-queries';

// Etc...
import { LeadBookingInfoComponent } from '../../modals/lead-booking-info/lead-booking-info.component';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { SearchResultLabelHelper } from '../../helpers/search-result-label.helper';
import { ThaiDatePipe, ThaiDateRangePipe } from 'src/app/pipes/thaidate.pipe';
import { DateHelper } from 'src/app/helpers/date.helper';
import { Agency } from 'src/app/models/agency';
import { Subject } from 'rxjs';
@Component({
    selector: 'app-group-plan',
    templateUrl: './group-plan.component.html',
    styleUrls: ['./group-plan.component.scss'],
})
export class GroupPlanComponent implements OnInit, OnDestroy {
    agencyProductCode: string;
    orderSummary: any;
    groupPlanList: IGroupPlanList;
    private bsModalRef: BsModalRef;
    groupPlanListQueries: IGroupPlanListQueries;

    @Output() clickViewMoreDetail = new EventEmitter<IGroupPlan>();

    private agency: Agency;
    private unsubscribe$: Subject<void> = new Subject<void>();

    constructor(
        private store: Store<State>,
        private modalService: BsModalService,
        private thaiDatePipe: ThaiDatePipe,
        private thaiDateRangePipe: ThaiDateRangePipe
    ) {}

    ngOnInit(): void {
        this.store
            .pipe(
                select(selectAgencyInfo),
                filter((agency) => !!agency),
                takeUntil(this.unsubscribe$)
            )
            .subscribe((agency) => (this.agency = agency));

        this.store
            .pipe(
                select(selectOrderSummarySelect),
                filter((data) => data !== null),
                takeUntil(this.unsubscribe$)
            )
            .subscribe((orderSummary) => {
                this.orderSummary = orderSummary;
            });

        this.store
            .pipe(
                select(selectGroupPlanList),
                filter((data) => data !== null),
                takeUntil(this.unsubscribe$)
            )
            .subscribe((groupPlanList: IGroupPlanList) => {
                this.groupPlanList = groupPlanList;
            });

        this.store
            .pipe(
                select(selectGroupPlanListQueries),
                filter((data) => data !== null),
                takeUntil(this.unsubscribe$)
            )
            .subscribe((groupPlanListQueries: IGroupPlanListQueries) => {
                this.groupPlanListQueries = groupPlanListQueries;
            });

        this.store
            .pipe(
                select(selectAgencyProductCode),
                filter((productCode) => !!productCode),
                take(1)
            )
            .subscribe((productCode) => (this.agencyProductCode = productCode));
    }

    ngOnDestroy(): void {
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
    }

    viewMoreDetail(commissionReport: IGroupPlan): void {
        this.clickViewMoreDetail.emit(commissionReport);
    }

    openBusinessContactInfoModal(productOwner: IProductOwner): void {
        const initialState = {
            id: productOwner.id,
            ownerChannel: productOwner.channel,
        };
        this.bsModalRef = this.modalService.show(LeadBookingInfoComponent, {
            initialState,
            class: 'company-contact-modal',
        });
    }

    disabledRow(startDate: Date) {
        return startDate < DateHelper.getCurrentDateAtMidnight();
    }

    async getMoreGroupPlan(total: number) {
        const groupPlanListQueries: IGroupPlanListQueries = {
            filters: {
                start_at_between: {
                    min_date: this.orderSummary.dateRange.minDate,
                    max_date: this.orderSummary.dateRange.maxDate,
                },
                order_statuses: [
                    { status_code: 11, already_started: null },
                    { status_code: 21, already_started: null },
                    { status_code: 22, already_started: null },
                    { status_code: 31, already_started: false },
                    { status_code: 31, already_started: true },
                ],
            },
            criteria: {
                page: total / 50 + 1,
                pageEntries: 50,
            },
        };
        this.store.dispatch(
            OrderActions.getGroupPlanListPaginated({
                groupPlanListQueries: groupPlanListQueries,
                isNextPage: true,
            })
        );
    }

    getDynamicTotalString(): string {
        const productOwner = {
            nameEn: this.groupPlanList?.groupPlans[0]?.productOwner.nameEN,
            nameTh: this.groupPlanList?.groupPlans[0]?.productOwner.nameTH,
        };

        return SearchResultLabelHelper.getDynamicTotalString(
            this.groupPlanListQueries,
            this.thaiDatePipe,
            this.thaiDateRangePipe,
            productOwner,
            null,
            this.agency?.productCode
        );
    }
}
