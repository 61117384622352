import { Injectable, Inject, PLATFORM_ID } from '@angular/core';
import { HttpService } from './base/http.service';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Router } from '@angular/router';
import { Observable, throwError } from 'rxjs';
import { environment } from 'src/environments/environment';
import { switchMap, map } from 'rxjs/operators';
import { ENDPOINTS } from '../constants/endpoint.constant';
import { ICampaignPaginatedFilter } from '../modules/product/models/campaign-model';
import { isPlatformBrowser } from '@angular/common';

@Injectable({
    providedIn: 'root',
})
export class CampaignService extends HttpService {
    constructor(
        @Inject(PLATFORM_ID) protected platformId: Object,
        protected http: HttpClient,
        protected router: Router
    ) {
        super(platformId, http, router);
    }

    public getCampaignDetail(campaignId: any): Observable<any> {
        let url = environment.apiProUrl + ENDPOINTS.CAMPAIGN.GET;
        url = url.replace('{campaign_id}', campaignId);
        return this.getRequestOptions(true).pipe(switchMap((headers) => this.http.get(url, headers)));
    }

    public getCampaigns(): Observable<any> {
        const url = environment.apiProUrl + ENDPOINTS.CAMPAIGN.GET_LIST;

        return this.getRequestOptions(true).pipe(
            switchMap((headers) => {
                const options = {
                    headers: headers.headers,
                };

                return this.http.get(url, options).pipe(
                    map((resp: any) => {
                        if (resp.status !== 'success') {
                            throwError(resp);
                        }
                        return resp;
                    })
                );
            })
        );
    }

    public getCampaignPaginated(
        currentPage: number = 1,
        itemPerPage: number = 50,
        filter: ICampaignPaginatedFilter = null
    ): Observable<any> {
        let params = new HttpParams();
        params = params.append('filters', this.serializeCampaignPaginatedFilter(filter));
        params = params.append('page', currentPage?.toString());
        params = params.append('page_entries', itemPerPage?.toString());

        const url = environment.apiProUrl + ENDPOINTS.CAMPAIGN.GET_LIST_PAGINATED;

        return this.getRequestOptions(true).pipe(
            switchMap((headers) => {
                const options = {
                    headers: headers.headers,
                    params,
                };

                return this.http.get(url, options).pipe(
                    map((resp: any) => {
                        if (resp.status !== 'success') {
                            throwError(resp);
                        }
                        return resp;
                    })
                );
            })
        );
    }

    private serializeCampaignPaginatedFilter(filter: ICampaignPaginatedFilter): string {
        const webServicefilter = {
            type_slugs: filter?.typeSlugs,
            campaign_ids: filter?.campaignIds,
            campaign_code: filter?.campaignCode,
        };

        return JSON.stringify(webServicefilter);
    }

    public sendEmailContact(paymentId: number): Observable<any> {
        let url = environment.apiProUrl + ENDPOINTS.CAMPAIGN_PAYMENT.SEND_EMAIL;
        url = url.replace('{payment_id}', paymentId.toString());
        return this.getRequestOptions(true).pipe(switchMap((headers) => this.http.post(url, null, headers)));
    }

    public getCampaignHilight(campaignId: number): Observable<any> {
        let url = environment.apiProUrl + ENDPOINTS.CAMPAIGN.GET_HILIGHT;
        url = url.replace('{campaign_id}', campaignId.toString());
        return this.getRequestOptions(true).pipe(switchMap((headers) => this.http.get(url, headers)));
    }

    public downloadFileHilight(fileIds: number[]): void {
        const url = environment.apiProUrl + ENDPOINTS.CAMPAIGN.DOWNLOAD_BANNER_IMAGE;
        this.getRequestOptions(true).subscribe((options) => {
            this.http
                .post(
                    url,
                    { image_file_ids: fileIds },
                    {
                        headers: options.headers,
                        observe: 'response',
                        responseType: 'blob',
                    }
                )
                .subscribe((resp) => {
                    if (isPlatformBrowser(this.platformId)) {
                        const link = document.createElement('a');
                        link.href = window.URL.createObjectURL(resp.body);
                        link.download = resp.headers.get('content-disposition').split('"').slice(-2, -1)[0];
                        link.click();
                    }
                });
        });
    }
}
