import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Observable, Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
import * as OrderActions from 'src/app/stores/order-store/order.actions';
import { selectCountOrderStatus, selectCountProductSubCategory } from 'src/app/stores/order-store/order.selectors';
import {
    CountOrderStatus,
    CountProductSubCategory,
    ProductOrderListSearchCriteria,
} from '../../models/product-order-list.model';

@Component({
    selector: 'app-program-order-list-search',
    templateUrl: './program-order-list-search.component.html',
    styleUrls: ['./program-order-list-search.component.scss'],
})
export class ProgramOrderListSearchComponent implements OnInit {
    public countProductSubCategory$ = new Observable<CountProductSubCategory>();
    public countOrderStatus$ = new Observable<CountOrderStatus>();

    public searchCriteria = new ProductOrderListSearchCriteria();
    private unsubscribe$: Subject<void> = new Subject<void>();

    @Input() totalProductOrders: number;

    @Output()
    search = new EventEmitter<ProductOrderListSearchCriteria>();

    constructor(private store: Store) {}

    ngOnInit(): void {
        this.searchCriteria.productSubCategorySlug = 'all';

        this.countProductSubCategory$ = this.store.pipe(
            select(selectCountProductSubCategory),
            filter((x) => !!x),
            takeUntil(this.unsubscribe$)
        );

        this.countOrderStatus$ = this.store.pipe(
            select(selectCountOrderStatus),
            filter((x) => !!x),
            takeUntil(this.unsubscribe$)
        );

        this.applySearch();
    }

    public selectProductSubCategory(slug: 'all' | 'outbound' | 'domestic'): void {
        this.searchCriteria.productSubCategorySlug = slug;
        this.dispatchCountOrderStatus();
    }

    public selectOrderStatus(
        slug: 'waiting_confirm' | 'waiting_full_paid' | 'complete_started' | 'complete_not_started' | 'cancel'
    ): void {
        this.searchCriteria.orderStatusSlug = slug;
    }

    public applySearch(): void {
        this.searchCriteria.isLocalSearch = false;
        this.search.emit(this.searchCriteria);
        this.dispatchCountProductSubCategory();
        this.dispatchCountOrderStatus();
    }

    public typeSearchString(): void {
        this.searchCriteria.isLocalSearch = true;
        this.search.emit(this.searchCriteria);
    }

    private dispatchCountOrderStatus() {
        this.store.dispatch(
            OrderActions.getCountOrderStatus({
                productSubCategorySlug: this.searchCriteria.productSubCategorySlug,
                searchString: this.searchCriteria.searchString,
            })
        );
    }

    private dispatchCountProductSubCategory() {
        this.store.dispatch(
            OrderActions.getCountProductSubCategory({ searchString: this.searchCriteria.searchString })
        );
    }
}
