
<div class="container text-center">
    <img class="d-none d-none-sm d-md-inline" src="assets/img/image-login.png" alt="Tourwow Pro Agency">
    <div class="login-box">
        <form>
            <div class="form row">
                <div class="col-md-3 col-xs-12">
                    <img src="assets/img/logo-tourwowMall-w.png" id="logo-twm" alt="Tourwow Mall Agency">
                </div>
                <div class="col-md-3 col-xs-12">
                    <div class="form-group">
                        <label for="inputUsername">Username ที่ใช้ Login</label>
                        <input [(ngModel)]="username" name="username" type="text" class="form-control" id="inputUsername"placeholder="กรอก Username ที่ใช้ Log in">
                    </div>
                </div>
                <div class="col-md-3 col-xs-12">                
                    <div class="form-group">
                        <label for="inputPassword">รหัสผ่าน</label>
                        <input [(ngModel)]="password" name="password" type="password" class="form-control" id="inputPassword" placeholder="กรอกรหัสผ่าน">
                    </div>
                </div>
                <div class="col-md-12 col-xs-12 text-center d-block d-sm-none">
                    <span *ngIf="isSubmit && (selectErrorMessage$ | async)" class="">
                        ไม่สามารถเข้าสู่ระบบได้ เนื่องจาก Username ที่ใช้ Login หรือรหัสผ่านไม่ถูกต้อง
                    </span>
                </div>  
                <div class="col-md-3 col-xs-12 m-auto">
                    <button (click)="onLoginSubmit()" type="submit" class="btn btn-primary w-100">เข้าสู่ระบบ Agency</button>
                </div>  
                <div class="col-md-12 col-xs-12 text-center">
                    <span *ngIf="isSubmit && (selectErrorMessage$ | async)" class="d-none d-sm-inline">
                        ไม่สามารถเข้าสู่ระบบได้ เนื่องจาก Username ที่ใช้ Login หรือรหัสผ่านไม่ถูกต้อง
                    </span>
                </div>  
            </div>      
        </form>
        <div class="address col-md-12">
            <div class="row">
                <div class="col-md-9">
                    <strong>บริษัท ทัวร์ว้าว จำกัด</strong>
                    <address>507/517 ถนนสาธุประดิษฐ์ แขวงช่องนนทรี เขตยานนาวา กทม. 10120 @2020 tourwow co.,ltd สงวนลิขสิทธิ์</address>
                </div>
                <div class="col-md-3 contact">
                    <div>สอบถามข้อมูลเพิ่มเติมทีม RE</div>
                    <strong>โทร. 02-674-1500</strong>
                </div>
            </div>            
        </div>
    </div>
</div>