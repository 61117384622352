<app-breadcrumb [items]="breadcrumb"></app-breadcrumb>
<h2 class="text-primary mb-4">
    <i class="icon icon-report-download"></i>
    ดาวน์โหลดข้อมูลใบเสร็จรับเงินค่าคอมของเวาเชอร์
</h2>

<div class="card mb-4">
    <div class="card-body d-flex justify-content-between">
        <div class="d-flex">
            <label class="font-weight-bold mr-3" for="">ปี</label>
            <div style="width: 172px;">
                <app-searchable-dropdown #yearsDropdown [settings]="yearsDropdownSettings" [items]="yearsDropdownItems"
                    (selected)="onSelectedYears($event)">
                </app-searchable-dropdown>
            </div>
            <label class="font-weight-bold ml-5 mr-3" for="">รอบการโอน</label>
            <div style="width: 232px;">
                <app-searchable-dropdown [settings]="periodDropdownSettings" [items]="periodDropdownItems"
                    (selected)="onSelectedPeriod($event)">
                </app-searchable-dropdown>
            </div>
        </div>
        <div class="d-flex">
            <button class="btn btn-primary mr-2" style="width: 131px" (click)="onSearch()">
                ค้นหา
            </button>
            <button class="btn btn-link" (click)="onReset()">เริ่มใหม่</button>
        </div>
    </div>
</div>

<div class="card mb-4">
    <div class="row m-0 lead-report-row" *ngFor="let period of periodRowDisplay">
        <div class=" col-12 col-sm-3 d-flex align-items-center px-3 pl-5 font-weight-bold">
            รอบโอน วันที่
            {{convertDateString(period.transferDueDate, { year: 'numeric', month: 'long', day: 'numeric' })}}
        </div>
        <div class="col-12 col-sm-3 d-flex align-items-center px-3">
            จำนวน {{+period.orderBalance.receiptItemQuantity}} Order
        </div>
        <div class="col-12 col-sm-2 d-flex align-items-center px-3">
            รวม {{+period.orderBalance.sumAmount | number:'1.2-2'}}
        </div>
        <div class="col-12 col-sm-2 d-flex align-items-center px-3">
            ค่าคอมที่จะได้รับ {{+period.orderBalance.sumNetCommissionAgency | number:'1.2-2'}}
        </div>
        <div class="col-12 col-sm-2 d-flex align-items-center justify-content-end px-3">
            <button class="btn btn-add" (click)="downloadReport(period)">
                <i class="icon icon-excel mr-1"></i>
                ดาวน์โหลด Excel
            </button>
        </div>
    </div>
</div>