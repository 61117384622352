<div class="modal-header">
    <button type="button" class="close pull-right" aria-label="Close" (click)="bsModalRef.hide()">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="top-wrapper">
        <h4 class="modal-title">ข้อมูลใบจองจาก Agency</h4>
</div>
<div *ngIf="(lead$ | async) as lead" class="modal-body">
    <div class="row">
        <div class="col-8">
            <div class="tour-details">
                <p class="title">Tour code :
                    <span class="tourcode">
                        {{lead.agencyProductCode}}{{lead.agencyProductCode ? '-' : ''}}{{lead.productDetail.tourCode | uppercase}}
                    </span>
                </p>
                <p class="product-name">{{lead.productDetail.name}}</p>
            </div>
            <div class="booking-details">
                <table>
                    <tr>
                        <th>พีเรียด</th>
                        <th>ราคาเริ่มต้น/คน</th>
                        <th>{{commissionLabel}}</th>
                        <th>จำนวนผู้เดินทาง</th>
                    </tr>
                    <tr>
                        <td>{{period || '-'}}</td>
                        <td>{{(lead.periodPrice | number) || '-'}}</td>
                        <td>{{commissionString || '-'}}</td>
                        <td>{{lead.numberOfPassengers || '-'}}</td>
                    </tr>
                </table>
                <div class="more-info-container">
                    <label class="col-form-label">
                        ข้อมูลเพิ่มเติมที่ต้องการแจ้ง Wholesale
                    </label>
                    <p class="description">{{lead.moreInfoForWs || '-'}}</p>
                </div>
            </div>
        </div>
        <div class="col-4">
            <div class="owner-info">
                <p class="title">Ref : <span class="font-weight-bold ref-code">{{lead.bookingNumberCode}}</span></p>
                <p class="title">วันที่ใบจอง : <span>{{dateAndTime}}</span></p>
            </div>
            <div class="agency-info">
                <p>ข้อมูลผู้แจ้ง : <span class="dark">{{lead.leadCreatedBy.agency.nameEn}} ({{lead.leadCreatedBy.agency.nameTh}})</span></p>
                <p>โทร : {{lead.leadCreatedBy.agency.phoneNo | phoneNumber}}, {{lead.leadCreatedBy.mobileNumber | phoneNumber}}</p>
                <p>เลขที่ใบอนุญาตนำเที่ยว <span class="dark">{{lead.leadCreatedBy.agency.licenseNumber}}</span></p>
                <p>เซลล์ : {{lead.leadCreatedBy.firstName}} {{lead.leadCreatedBy.lastName}} {{lead.leadCreatedBy.nickName ? '(' + lead.leadCreatedBy.nickName + ")" : ''}}</p>
                <p>อีเมล : {{lead.leadCreatedBy.agency.email}}</p>
                <p>ที่อยู่ : {{lead.leadCreatedBy.agency.address}}</p>
            </div>
            <div class="row button-container">
                <div class="col-6">
                    <button type="button" class="btn btn-default" (click)="bsModalRef.hide()">ปิด</button>
                </div>
            </div>
        </div>
    </div>
</div>
