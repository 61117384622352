<div class="card mb-4">
    <div class="card-body p-0 mt-3">
        <div class="">
            <div class="tabs">
                <button class="tab" (click)="onChangeTab(tabs.Listing)" [ngClass]="{ active: activeListingTab }">
                    รายการ Order
                </button>
                <button class="tab" (click)="onChangeTab(tabs.Receipt)" [ngClass]="{ active: activeReceiptTab }">
                    รายการใบเสร็จรับเงิน
                </button>
            </div>
        </div>
        <div class="p-4">
            <h5 class="mb-0" [hidden]="!periodInfo">
                รอบโอนวันที่ {{ periodInfo?.transferDueDate | thaiDate }} | จำนวน Order
                {{ periodInfo?.orderBalance?.receiptItemQuantity | number }} รวม
                {{ periodInfo?.orderBalance?.sumAmount | number }}
            </h5>
            <div class="d-flex align-items-end">
                <h4 class="mb-0 mr-2" [hidden]="!periodInfo">
                    ค่าคอมที่ได้รับ
                    <span class="text-blue">{{ periodInfo?.orderBalance?.sumNetCommissionAgency | number }}</span>
                </h4>
                <span [hidden]="this.currentStatus !== EnumOrderBalanceReportStatus.PendingTransfer || !periodInfo">
                    <span class="d-flex text-white bg-orange" *ngIf="daysUntilPayment !== null">
                        <i class="icon-clock pr-1"></i>
                        <span *ngIf="daysUntilPayment > 0">เงินเข้าในอีก {{ daysUntilPayment }} วัน</span>
                        <span *ngIf="daysUntilPayment <= 0">เงินเข้าเร็ว ๆ นี้</span>
                    </span>
                </span>
                <span [hidden]="this.currentStatus !== EnumOrderBalanceReportStatus.TransferCompleted || !periodInfo">
                    <span class="d-flex text-blue"><i class="icon-waiting-confirmation"></i>รอยืนยันรับเงิน</span>
                </span>
                <span [hidden]="this.currentStatus !== EnumOrderBalanceReportStatus.TransferConfirmed || !periodInfo">
                    <span class="d-flex text-lg">ยืนยันรับเงินแล้ว</span>
                </span>
                <div class="ml-auto w-auto" style="min-width: 180px">
                    <app-searchable-dropdown
                        [settings]="sortOptionDropdownSettings"
                        [items]="sortOptionDropdownItems"
                        (selected)="emitSortOrderChange()"
                    ></app-searchable-dropdown>
                </div>
            </div>
        </div>
    </div>
</div>
