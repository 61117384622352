import { Pipe, PipeTransform } from '@angular/core';
import { IProductViewProvince } from '../models/product-view';

@Pipe({
    name: 'displayProvincesView',
})
export class DisplayProvincesViewPipe implements PipeTransform {
    transform(provinces: IProductViewProvince[], delimiter = ','): any {
        if (!provinces.length) {
            return '';
        }

        let value = '';
        provinces.forEach((province) => {
            if (!value) {
                value += province.name_th;
            } else {
                value += delimiter + province.name_th;
            }
        });
        return value;
    }
}
