import { Component, EventEmitter, OnInit } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { take } from 'rxjs/operators';
import { SuccessModalComponent } from 'src/app/modules/shared/modals/success-modal/success-modal.component';
import { TourwowOrderService } from 'src/app/services/tourwow-order.service';
import { CanceledByAgencyMember } from '../../models/tw-booking-model/canceled-by-agency-member.model';
import { TwBooking } from '../../models/tw-booking-model/tw-booking.model';

@Component({
    selector: 'app-cancel-booking',
    templateUrl: './cancel-tw-booking.component.html',
    styleUrls: ['./cancel-tw-booking.component.scss'],
})
export class CancelTwBookingComponent implements OnInit {
    booking: TwBooking;

    canceledBookingResult: EventEmitter<any> = new EventEmitter();

    constructor(
        public bsModalRef: BsModalRef,
        public bsModalService: BsModalService,
        private tourwowOrderService: TourwowOrderService
    ) {}

    ngOnInit(): void {
        this.bsModalService.setDismissReason('undo');
    }

    public submitButton(): void {
        this.bsModalService.setDismissReason('confirm-cancel-' + this.booking.orderCode);

        this.tourwowOrderService
            .updateTwBookingOrderStatus(this.booking.orderCode, 'canceled')
            .pipe(take(1))
            .subscribe((resp) => {
                this.bsModalRef.hide();

                if (resp.status !== 'success') return;
                this.triggerCanceledBookingResult(resp.data);

                const initialState = {
                    message: 'ยกเลิก Order เรียบร้อยแล้ว',
                };

                this.bsModalRef = this.bsModalService.show(SuccessModalComponent, {
                    initialState,
                    class: 'success-modal',
                });
            });
    }

    triggerCanceledBookingResult(data): void {
        this.booking.orderStatus = data.order_status;
        this.booking.canceledAt = data.canceled_at;
        this.booking.canceledByAgencyMember = new CanceledByAgencyMember().deserialize(data.canceled_by_agency_member);

        this.canceledBookingResult.emit(this.booking);
    }
}
